import React, { useCallback } from "react";
import { connect } from "react-redux";
import moment from "moment";
import { useHistory } from "react-router-dom";
import { closeModal } from "redux/actions/global";
import { t } from "utils";

function VerifyProfile({ closeModal }) {
  const history = useHistory();

  const handleVerifyProfile = useCallback(() => {
    closeModal();
    history.push('/profile');
  }, [closeModal, history]);

  return (
    <div
      tabIndex="1"
      className="modal-content verify-profile-modal"
    >
      <div className="icon-container">
        <img alt="icon" src="/images/verify-profile-icon.svg" />
      </div>

      <div className="content">
        <h3>
          {t('verify_profile.title')}
        </h3>
        <p>
          {t('verify_profile.subtitle')}
        </p>
        <p className="expires">
          {t('verify_profile.expires')}{' '}
          <strong>
            {
              // workaround https://github.com/moment/moment/issues/3341
              String(moment.unix(1592190000).format('LL'))
                .toLowerCase()
                .replace(' de 2020', '')
                .replace(', 2020', '')
                .replace('june 15', 'june, 15th')
            }
          </strong>
        </p>
      </div>

      <div className="actions">
        <button onClick={handleVerifyProfile} className="btn">
          {t('verify_profile.verify_btn')}
        </button>
        <br />
        <button onClick={closeModal} className="btn transparent">
          {t('verify_profile.remind_later')}
        </button>
      </div>
    </div>
  );
}

export default connect(null, { closeModal })(VerifyProfile);