import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { closeModal } from "redux/actions/global";
import { t, getIdFromImageSrc } from "utils";
import Icon from "components/Icon";
import { faExclamation } from '@fortawesome/free-solid-svg-icons';

class PublicationImagesModified extends Component {
 
  constructor(props) {
    super(props);

    const { data } = props;
   
    this.state = {
      step: 1,
      siteId: null,
      data
    };
  }

  render() {

    const { closeModal } = this.props;

    const articleImages = this.state.data.articleImages;
    const photosDenounced = this.state.data.photosDenounced;
    const newPhotosIds = (this.state.data.photosNew || []).map(p => p.id);
 
    return (
      <div className="panel images-modified">
        <div className="container">
          <i className="modal-close" style={{marginRight: '24px'}} onClick={closeModal} />
          <div className="title-modal-updated-images">{t('images-updated')}</div>
          <div className="images-ctn">
            <div className="reported-images">
              <h4 className="subtitle-modal-updated-images">{t('reported-images')}</h4>
              <ul className="images__list">
                {
                  (
                    (
                      photosDenounced &&
                      photosDenounced.length > 0
                    )
                  ) &&
                  photosDenounced.map((photo) => (
                    <li
                      key={photo.id}
                      className="image-item__denounced"
                    >
                      <Icon icon={faExclamation} />
                      <img
                        alt={photo.id}
                        src={photo.src}
                      />
                    </li>
                  ))
                }
              </ul>
            </div>
            <div className="actual-images">
              <h4 className="subtitle-modal-updated-images">{t('actual-images')}</h4>
              <ul className="images__list">
                {
                  (
                    (
                      articleImages &&
                      articleImages.length > 0
                    )
                  ) &&
                  articleImages.map((photo) => {
                    const photoId = getIdFromImageSrc(photo.url);
                    const isNew = newPhotosIds.indexOf(photoId) > -1;

                    return (
                      <li 
                        key={photoId} 
                        className="image-item__new"
                      >
                        {
                          isNew &&
                            <div>
                              <span>
                                {t('case_management.case_description.photos_modified_new')}
                              </span>
                            </div>
                        }
                        <img
                          alt={photoId}
                          src={photo.url.replace('-O', '-N')}
                        />
                      </li>
                    );
                  })
                }
              </ul>
            </div>
          </div>
        </div>
        <button 
          className="btn msc-ok"
          style={{marginBottom: '23.5px'}}
          onClick={closeModal}
        >
          {t('go_back')}
        </button>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  profile: state.global.profile,
});

const actions = {
  closeModal,
};

export default withRouter(connect(mapStateToProps, actions)(PublicationImagesModified));