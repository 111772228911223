import { 
  articleFields, 
  filterProfileFields, 
} from "../fragments";


export default `
  ${articleFields}
  ${filterProfileFields}
  
  mutation (
    $id: String!,
    $keyword: String,
    $filterProfileId: String,
    $filterProfiles: [FilterProfileInputType]
  ) {
    updateLiveSearch(livesearch: {
      id: $id
      keyword: $keyword
      filterProfiles: $filterProfiles
      filterProfileId: $filterProfileId
    }) {
      id
      keyword
      articles {
        ...articleFields
      }
      filterProfileId
      filterProfiles {
        ...filterProfileFields
      }
    }
  }
`;