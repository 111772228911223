import { articleFields } from "../fragments";

export default `
  ${articleFields}

  query {
    profile {
      id
      givenName
      familyName
      lang
      currency
      settings
      groupId
      groupName
      email
      userIds
      isAcuerdo
      isVerified
      meliUser {
        id
        siteId
        nickname
      }
      hasSuggestions
      authorizedSiteIds
      extended {
        liveSearchReport 
      }
      allowedNotices {
        siteId
        notices {
          id
          type
        }
      }
      helps {
        dialog
        didReport
        onBoarding
        onBoardingPendingDenounced
        onBoardingPendingDetail
        suggestions
        didReportExcel
        redirectFlowInfo
        restrictionNewMember
        articlesPinned {
          ...articleFields
        }
        translateTooltipAlreadyShown
        newReasonTooltipAlreadyShown
        discardTooltipAlreadyShown
      }
      suggestedListingsCountry
      reportImportTooltipUntil
      noticesLimitDayCount
      noticesLimitUpdatedAt
    }
  }
`;