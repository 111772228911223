import { t, jsonParse, cleanString } from "utils";
import { alertError, alertSuccess } from "./index";
import moment from "moment";

/* eslint-disable no-console */
export const updateProfileSettingsOptimistic = (state, payload) => {
  const profile = state.profile.settings
    ? { ...state.profile }
    : { ...state.profile, settings: {} };

  profile.settings = {
    ...profile.settings,
    ...payload,
  };

  return {
    ...state,
    profile,
  };
};

export const profileFulfilled = (state, payload) => {
  let cleanSettings;

  if (payload.profile.settings) {
    cleanSettings = cleanString(payload.profile.settings);
  }

  return {
    ...state,
    fetchProfileError: 0,
    isFetchingProfile: false,
    profile: {
      ...payload.profile,
      settings: cleanSettings ? jsonParse(cleanSettings) : {},
    },
  };
};

export const fetchProfileError = (state) => {
  alertError(t("server_error"));

  return {
    ...state,
    isFetchingProfile: false,
    fetchProfileError: state.fetchProfileError + 1,
  };
};

export const fetchMeliProfileAccountError = (state) => {
  alertError(t("server_error"));

  return {
    ...state,
    isFetchingMeliProfileAccount: false,
  };
};

export const updateMeliProfileAccountSuccess = (state, payload) => {
  const currentMeliAccount = state.profile.meliAccount || {};

  alertSuccess(t("verify_profile.form.success.text"));

  return {
    ...state,
    isUpdatingMeliProfileAccount: false,
    updateMeliProfileAccountError: null,
    profile: {
      ...state.profile,
      ...payload,
      meliAccount: {
        ...currentMeliAccount,
        ...payload.meliAccount,
      },
    },
  };
};

export const updateMeliProfileAccountError = (state, payload) => {
  if (payload && payload.message !== "Request failed with status code 400") {
    alertError(t("server_error"));
  }

  return {
    ...state,
    isUpdatingMeliProfileAccount: false,
    updateMeliProfileAccountError: payload,
    profile: {
      ...state.profile,
      isVerified: false,
    },
  };
};

export const updateProfileSuccess = (state, payload) => {
  setTimeout(() => {
    alertSuccess(t("activerecord.attributes.user.alert_update_user"));
  }, 50);

  return {
    ...state,
    profile: {
      ...state.profile,
      ...payload.updateProfile,
      settings: JSON.parse(payload.updateProfile.settings || "{}"),
    },
    isUpdatingProfile: false,
  };
};

export const updateProfileError = (state, payload) => {
  alertError(t("activerecord.attributes.user.alert_not_update_user"));

  console.error(payload);

  return {
    ...state,
    isUpdatingProfile: false,
  };
};

export const updateArticlesPinnedSuccess = (state, payload) => {
  return {
    ...state,
    profile: {
      ...state.profile,
      helps: {
        ...(state.profile.helps || {}),
        ...payload.updateProfile.helps,
      },
    },
    isUpdatingArticlesPinned: false,
  };
};

export const updateArticlesPinnedError = (state, payload) => {
  alertError(t("activerecord.attributes.user.alert_not_update_user"));

  console.error(payload);

  return {
    ...state,
    isUpdatingArticlesPinned: false,
  };
};

export const updateTranslateTooltipAlreadyShownSuccess = (state, payload) => {
  return {
    ...state,
    profile: {
      ...state.profile,
      helps: {
        ...(state.profile.helps || {}),
        ...payload.updateProfile.helps,
      },
    },
    isUpdatingTranslateTooltipAlreadyShown: false,
  };
};

export const updateTranslateTooltipAlreadyShownError = (state, payload) => {
  alertError(t("activerecord.attributes.user.alert_not_update_user"));

  console.error(payload);

  return {
    ...state,
    isUpdatingTranslateTooltipAlreadyShown: false,
  };
};

export const fetchProfilesError = (state, payload) => {
  alertError(t("fetch_profiles_error"));

  console.error(payload);

  return {
    ...state,
    isFetchingProfiles: false,
  };
};

export const createProfileSuccess = (state, payload) => {
  alertSuccess(t("create_profile_success"));

  let profiles = state.profiles.splice(0);

  profiles.push(payload.createProfile);

  return {
    ...state,
    profiles,
    isCreatingProfile: false,
    switchToCreateProfile: false,
  };
};

export const createProfileError = (state, payload) => {
  alertError(t("create_profile_error"));
  console.error(payload);

  let profiles = state.profiles;

  profiles.filter((profile) => !profile.id);

  return {
    ...state,
    profiles,
    isCreatingProfile: false,
  };
};

export const getCredentialsError = (state, payload) => {
  alertError(t("change_profile_error"));
  console.error(payload);

  return {
    ...state,
    isChangingProfile: false,
  };
};

export const changeProfileSuccess = (state, payload) => {
  const profile = state.profiles.find((profile) => profile.id === payload);

  alertSuccess(t("change_profile_success"));

  delete state.profile.helps;

  return {
    ...state,
    modal: {},
    isModalOpen: false,
    profile: {
      ...state.profile,
      ...profile,
    },
    isChangingProfile: false,
  };
};

export const changeProfileError = (state, payload) => {
  alertError(t("change_profile_error"));
  console.error(payload);

  return {
    ...state,
    isChangingProfile: false,
  };
};

export const createSellersLiveSearchSuccess = (state) => {
  alertSuccess(t("seller_search_created"));

  return {
    ...state,
    creatingSellersLS: false,
  };
};

export const createSellersLiveSearchError = (state, payload) => {
  alertError(t("unable_to_create_live_search"));

  console.error(payload);

  return {
    ...state,
    creatingSellersLS: false,
  };
};

export const addToArticlesMap = (state, payload) => {
  const articlesMap = { ...state.articlesMap };
  articlesMap[payload.article.id] = payload.article;
  return { ...state, articlesMap };
};

export const addToCatalogoArticlesMap = (state, payload) => {
  const articlesMap = { ...state.articlesMap };
  articlesMap[payload.articleCatalogo.id] = payload.articleCatalogo;
  return { ...state, articlesMap };
};

export const addToCatalogosArticlesMap = (state, payload) => {
  const articlesCatalogoMap = { ...state.articlesCatalogoMap };

  console.log("payload =" + payload.articlesCatalogo.id);
  articlesCatalogoMap[payload.articlesCatalogo.id] = payload.articlesCatalogo;
  //articlesCatalogoMap = payload.articlesCatalogo;
  return { ...state, articlesCatalogoMap };
};

export const updateArticlesMapReported = (state, payload) => {
  const articlesMap = { ...state.articlesMap };
  const { articlesIds, ...rest } = payload;
  const data = { ...rest };

  articlesIds.forEach((articleId) => {
    articlesMap[articleId] = {
      ...articlesMap[articleId],
      reported: data,
    };
  });

  return {
    ...state,
    articlesMap,
  };
};

export const removeFromArticlesMap = (state, payload) => {
  const articlesMap = { ...state.articlesMap };
  payload.articlesIds.forEach((articleId) => {
    delete articlesMap[articleId];
  });
  return { ...state, articlesMap };
};

export const fetchSitesError = (state, payload) => {
  alertError(t("server_error"));
  console.error(payload);
  return {
    ...state,
    isFetchingSites: false,
  };
};

export const fetchCurrencyError = (state, payload) => {
  alertError(t("fetch_currency_error"));

  console.error(payload);

  return state;
};

export const reportArticleUndoOptimistic = (state, payload) => {
  const articlesMap = { ...state.articlesMap };
  if (articlesMap[payload.articleId]) {
    delete articlesMap[payload.articleId].reported;
  }
  return { ...state, articlesMap };
};

export const sendNoticeSuccessUtil = (state, { sendNotice }) => {
  const articlesMap = { ...state.articlesMap };
  sendNotice &&
    sendNotice.articlesIds &&
    sendNotice.articlesIds.forEach((articleId) => {
      if (articlesMap[articleId]) {
        delete articlesMap[articleId].reported;
      }
    });
  return { ...state, articlesMap };
};

export const approveArticleOptimistic = (state, payload) => {
  const articlesMap = { ...state.articlesMap };
  payload.articlesIds.forEach((articleId) => {
    if (articlesMap[articleId]) {
      articlesMap[articleId] = {
        ...articlesMap[articleId],
        approved: {
          author: payload.author,
        },
      };
    }
  });
  return { ...state, articlesMap };
};

export const approveArticleUndoOptimistic = (state, payload) => {
  const articlesMap = { ...state.articlesMap };
  if (articlesMap[payload.articleId]) {
    delete articlesMap[payload.articleId].approved;
  }
  return { ...state, articlesMap };
};

export const setBlackListSellerSuccess = (state, payload) => {
  const articlesMap = { ...state.articlesMap };

  Object.keys(articlesMap).forEach((articleId) => {
    if (articlesMap[articleId].seller.id === payload.id) {
      articlesMap[articleId].seller.blackSeller = true;
      articlesMap[articleId].seller.whiteSeller = false;
    }
  });

  return {
    ...state,
    articlesMap,
  };
};

export const unsetBlackSellerSuccess = (state, payload) => {
  const articlesMap = { ...state.articlesMap };

  Object.keys(articlesMap).forEach((articleId) => {
    const article = articlesMap[articleId];
    if (article.seller.id === payload.deleteSellerFromDB.deletedId) {
      articlesMap[articleId].seller.blackSeller = false;
    }
  });

  return {
    ...state,
    articlesMap,
  };
};

export const setWhiteListSellerSuccess = (state, payload) => {
  const articlesMap = { ...state.articlesMap };

  Object.keys(articlesMap).forEach((articleId) => {
    if (articlesMap[articleId].seller.id === payload.id) {
      articlesMap[articleId].seller.whiteSeller = true;
      articlesMap[articleId].seller.blackSeller = false;
    }
  });

  return {
    ...state,
    articlesMap,
  };
};

export const unsetWhiteSellerSuccess = (state, payload) => {
  const articlesMap = { ...state.articlesMap };

  Object.keys(articlesMap).forEach((articleId) => {
    const article = articlesMap[articleId];
    if (article.seller.id === payload.deleteSellerFromDB.deletedId) {
      articlesMap[articleId].seller.whiteSeller = false;
    }
  });

  return {
    ...state,
    articlesMap,
  };
};

export const closeHelpError = (state, payload) => {
  if (payload === "dialog") {
    alertError(t("close_help_dialog_error"));
  }

  return {
    ...state,
    profile: {
      ...state.profile,
      helps: {
        ...(state.profile.helps || {}),
        [payload]: false,
      },
    },
  };
};

export const sendMessageToSupportError = (state) => {
  alertError(t("support_form.error_message"));

  return {
    ...state,
    isSendingMessageToSupport: false,
  };
};

export const reportArticlesErrorUtil = (state, payload) => {
  const { articlesIds } = payload;
  const articlesMap = { ...state.articlesMap };

  articlesIds.forEach((articleId) => {
    if (articlesMap[articleId]) {
      delete articlesMap[articleId]["reported"];
    }
  });

  return {
    ...state,
    articlesMap,
  };
};

export const updateNewReasonTooltipAlreadyShownSuccess = (state, payload) => {
  return {
    ...state,
    profile: {
      ...state.profile,
      helps: {
        ...(state.profile.helps || {}),
        ...payload.updateProfile.helps,
      },
    },
    isUpdatingNewReasonTooltipAlreadyShown: false,
  };
};

export const updateNewReasonTooltipAlreadyShownError = (state, payload) => {
  alertError(t("activerecord.attributes.user.alert_not_update_user"));

  console.error(payload);

  return {
    ...state,
    isUpdatingNewReasonTooltipAlreadyShown: false,
  };
};

export const updateDiscardTooltipAlreadyShownSuccess = (state, payload) => {
  return {
    ...state,
    profile: {
      ...state.profile,
      helps: {
        ...(state.profile.helps || {}),
        ...payload.updateProfile.helps,
      },
    },
    isUpdatingDiscardTooltipAlreadyShown: false,
  };
};

export const updateDiscardTooltipAlreadyShownError = (state, payload) => {
  alertError(t("activerecord.attributes.user.alert_not_update_user"));

  console.error(payload);

  return {
    ...state,
    isUpdatingDiscardTooltipAlreadyShown: false,
  };
};

export const setNoticesLimitDayCountSuccessUtil = (state, payload) => {
  return {
    ...state,
    profile: {
      ...state.profile,
      noticesLimitDayCount: payload.updateProfile.noticesLimitDayCount,
      noticesLimitUpdatedAt: moment().valueOf(),
    },
  };
};

export const setArticlesBlindages = (state, payload) => {

  return {
    ...state,
    blindages: payload.blindages.blindages,
    isFetchingBlindages: false,
  };
};

export const errorArticleBlindages = (state, payload) => {
  alertError(t("Error get blindages"));

  console.error(payload);

  return {
    ...state,
    isFetchingBlindages: false,
  };
};
