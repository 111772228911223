import { articleFields } from "../fragments";


export default `
  ${articleFields}

  mutation(
    $undo: String!,
    $articleId: String!,
    $from: String,
    $filters: String,
  ) {
    articleUndo(
      from: $from,
      filters: $filters,
      article: {
        articleId: $articleId,
        undo: $undo
      }
    ) {
      from
      deletedId
      article { ...articleFields }
    }
  }
`;