export default `
  query(
    $itemId: String!
  ) {
      reviewsTotal(
        itemId: $itemId,
      ) {
          total
          positive
          negative
        }
    }    
`;