import { noticedReportFields } from "../fragments";


export default `
  ${noticedReportFields}

  query($size: Int, $from: Int) {
    noticedReports(size: $size, from: $from) {
      ...noticedReportFields
    }
  }
`;