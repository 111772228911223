import React, { useEffect } from "react";
import classnames from "classnames";
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import Icon from "components/Icon";

const ImageHub = (props) => {
  const { images, initialValue, onChange } = props;
  const [value, setValue] = React.useState(initialValue || {});

  useEffect(() => onChange(value), [value, onChange]);

  const handleChange = e => {
    const checked = e.target.checked;
    const imageId = e.target.value;
    setValue(oldValue => ({ ...oldValue, [imageId]: checked }));
  };

  return (
    <div
      className="image-hub"
      style={{ maxWidth: '420px' }}
    >
      <div className="image-hub-wrapper">
        <div className="image-hub-labels">
          {
            (images && images.length) &&
              images
                .map((image, i) =>
                  <React.Fragment key={`thumbnail_fragment_${i}`}>
                    <label
                      key={`thumbnail_${i}`}
                      htmlFor={`thumbnail-radio-denounce-${i}`}
                      className={classnames(
                        "label_thumbnail",
                        { 'checked': value[image.id] }
                      )}
                    >
                      <img
                        width="99"
                        height="99"
                        alt={`image_${i}`}
                        key={`image_${i}`}
                        src={image.src}
                      />
                      <div className="checkbox__container">
                        <Icon icon={faCheck} />
                        <input
                          name="image"
                          type="checkbox"
                          value={image.id}
                          onChange={handleChange}
                          checked={value[image.id]}
                          id={`thumbnail-radio-denounce-${i}`} 
                        />
                      </div>
                    </label>
                  </React.Fragment>
                )
          }
        </div>
      </div>
    </div> 
  );
};

export default ImageHub;
