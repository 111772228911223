import i18next from "i18next";
import locales from "./locales";

i18next
  .init({
    interpolation: {
      // React already does escaping
      escapeValue: false,
    },
    lng: 'en', // 'en' | 'es' | 'pt'
    // Using simple hardcoded resources for simple example
    resources: {
      en: {
        translation: locales['en']
      },
      es: {
        translation: locales['es']
      },
      pt: {
        translation: locales['pt']
      }
    },
  });

export default i18next;