import React, { Component } from "react";
import classnames from "classnames";
import { t } from "utils";

class SortFilterDropdown extends Component {
  constructor(props) {
    super(props);

    this.state = {
      open: false, 
      value: props.value || 'rec', 
    };
  }

  getOptions = () => {
    return [
      { 
        value: "rec",
        displayName: t(`filter_modal.filter_1_text_1`)
      },
      {
        value: "no_rec",
        displayName: t(`filter_modal.filter_1_text_2`)
      }
    ];
  }

  toggleDropdown = () => {
    if (this.props.disabled) {
      return;
    }

    this.setState({ open: !this.state.open });
  }

  handleBlur = () => {
    if (!this.state.open) {
      return;
    }

    this.setState({ open: false });
  }

  handleMouseDown = e => {
    if (!this.state.open) {
      return;
    }

    e.preventDefault();
    e.stopPropagation();
  }

  handleChange = value => e => {
    e.preventDefault();

    if (this.props.disabled) {
      return;
    }

    this.setState(
      { value },
      () => this.props.onChange && this.props.onChange(value)
    );
  }

  render() {
    let selected = t(`filter_modal.filter_1_text_1`);  
  
    if(this.state.value === 'no_rec') {
      selected = t(`filter_modal.filter_1_text_2`); 
    }
  
    return (    
      <ul
        tabIndex="1"
        onBlur={this.handleBlur}
        className={classnames(
          "dropdown-container",
          "sort-select",
          { disabled: this.props.disabled }
        )}
        onMouseDown={this.handleMouseDown}
      >
        <li
          onClick={this.toggleDropdown}
          className={classnames(
            "dropdown",
            { "open": this.state.open }
          )}
        >
          <div>
            <span className="dropdown-label">
              {selected}
            </span>
            <img 
              src="/images/icon-chevron-down.png"
              className="arrow-down"
            />
          </div>
          <ul
            className={classnames(
              "logout",
              "extended",
              "dropdown-menu",
              "nav-right-settings",
            )}>
            {
              this.getOptions().map(option =>
                <li
                  key={option.value}
                  onClick={this.handleChange(option.value)}>
                  <div>{t(option.displayName)}</div>
                </li>
              )
            }
          </ul>
        </li>
      </ul>
    );
  }
}

export default SortFilterDropdown;