import React, { useState, useCallback, useEffect } from "react";
import { connect } from "react-redux";
import {
  faCheckCircle,
  faTimes,
  faCircleNotch,
  faExclamation,
  faExclamationCircle,
} from "@fortawesome/free-solid-svg-icons";
import { faTrashAlt } from "@fortawesome/free-regular-svg-icons";
import { openModal, closeModal, fetchBlindages } from "redux/actions/global";
import FileUploader from "components/FileUploader";
import ImportedFileDetails from "components/ImportedFileDetails";
import ImportedFileExample from "components/ImportedFileExample";
import Icon from "components/Icon";
import { Select, Option } from "components/Select";
import { gqlQuery } from "libs/awsLib";
import { reportArticles } from "redux/actions/detail";
import { queryArticle, queryItemPendingDenouncesCount } from "gql/index";
import {
  t,
  isMeliUrl,
  readXLSXFile,
  isValidSiteId,
  getItemFromApi,
  getItemIdFromURL,
  isDenounceWithImages,
} from "utils";
import { BLINDAGE_REPORT_EXCEL } from "components/Modal";

function AddSuspiciousXlsx(props) {
  const { profile, closeModal, fetchBlindages } = props;

  const [errorMsg, setErrorMsg] = useState(null);
  const [articlesIds, setArticlesIds] = useState([]); // found
  const [invalidIds, setInvalidIds] = useState([]); // duplicated
  const [notFoundIds, setNotFoundIds] = useState([]); // error
  const [blindagesIds, setblindagesIds] = useState([]);
  const [processing, setProcessing] = useState(false);
  const [acceptedFiles, setAcceptedFiles] = useState([]);
  const [showWarning, setShowWarning] = useState();
  const [border, setBorder] = useState(false);
  const [showWarningMessage, setShowWarningMessage] = useState(false);
  const [comment, setComment] = useState();
  const [code, setCode] = useState("no-selected");

  const handleClose = useCallback(closeModal, [closeModal]);

  const mapItem = useCallback(
    (id) =>
      isMeliUrl(id)
        ? getItemIdFromURL(id)
        : id.trim().toUpperCase().replace(" ", "").replace("-", ""),
    []
  );

  useEffect(() => {
    fetchBlindages(articlesIds);
  }, [articlesIds]);

  const validateFiles = useCallback((acceptedFiles, exceedsMaxSize) => {
    if (!acceptedFiles.length || acceptedFiles.length > 1) {
      if (!acceptedFiles.length)
        if (exceedsMaxSize)
          setErrorMsg(t("add_suspicious_xlsx.file_exceeds_maximum_size"));
        else setErrorMsg(t("add_suspicious_xlsx.incorrect_file_format"));
      else setErrorMsg(t("add_suspicious_xlsx.only_one_file"));
      return false;
    }
    return true;
  }, []);

  const validateIds = useCallback((activeIds, notFoundIds, invalidIds) => {
    if (!activeIds.length) {
      let errorMessage = "";

      if (invalidIds.length && !notFoundIds.length)
        errorMessage =
          invalidIds.length > 1
            ? t("add_suspicious_xlsx.only_duplicated_listings_message")
            : t("add_suspicious_xlsx.only_duplicated_listing_message");
      else errorMessage = t("add_suspicious_xlsx.not_valid_found_message");

      setErrorMsg(errorMessage);
    }
  }, []);

  const checkBlindageMultiple = (code) => {
    const blindagesIds = [];
    const blindagesInfoExcel = [];

    props.blindages.forEach((item) => {
      if (item.reason === code) {
        blindagesIds.push(item.item);
        blindagesInfoExcel.push(item);
      }
    });

    const filteredArticles = articlesIds.filter(
      (elemento) => !blindagesIds.includes(elemento)
    );

    return [filteredArticles, blindagesIds, blindagesInfoExcel];
  };

  const handleOnDrop = useCallback(
    async (acceptedFiles, exceedsMaxSize) => {
      if (!validateFiles(acceptedFiles, exceedsMaxSize)) {
        return false;
      }

      setErrorMsg(null);
      setProcessing(true);
      setAcceptedFiles(acceptedFiles);

      let ids = [];
      let siteIds = [];
      let newActiveIds = [];
      let newInvalidIds = [];
      let newNotFoundIds = [];
      let notEnrolledRightsIds = [];

      const filterItem = (id) => {
        if (!id) return false;

        const itemId = isMeliUrl(id) ? getItemIdFromURL(id) : id;

        if (!itemId) return false;

        const siteId = itemId.trim().slice(0, 3).toUpperCase();
        const allowedSitesNotices = profile.allowedNotices.reduce((acc, site) => { 
          if(site.notices.length > 0){
            acc.push(site.siteId);
          } return acc;
        }, []);
        const isValid = allowedSitesNotices.indexOf(siteId) > -1;

        if (isValid) siteIds.push(siteId);
        else {
          notEnrolledRightsIds.push(siteId);
        }

        return isValid;
      };

      try {
        const file = acceptedFiles[0];
        const data = await readXLSXFile(file);
        const cleanData = data.flat().filter(filterItem).map(mapItem);

        ids.push(...new Set(cleanData)); // Set returns only unique values

        const uniqueSiteId = [...new Set(siteIds)];

        if (ids.length <= 1000 && uniqueSiteId.length === 1 && notEnrolledRightsIds.length === 0) {
          const reportedPromises = ids.map((articleId) =>
            gqlQuery(queryArticle, { articleId })
              .then((response) => response.article)
              .then((article) =>
                article && article.reported && !article.noticed ? 1 : 0
              )
          );

          const apiPromises = ids.map((id) =>
            getItemFromApi(id, "id,status").catch(() => ({ id }))
          );

          const pendingDenouncesPromises = ids.map((itemId) =>
            gqlQuery(queryItemPendingDenouncesCount, {
              itemId,
              flow: "import_excel",
            })
              .then((response) => response.itemPendingDenouncesCount)
              .then(
                (itemPendingDenouncesCount) => itemPendingDenouncesCount.count
              )
          );

          const [apiResponses, reportedResponses, pendingDenouncesResponses] =
            await Promise.all([
              Promise.all(apiPromises),
              Promise.all(reportedPromises),
              Promise.all(pendingDenouncesPromises),
            ]);

          apiResponses.forEach((item, i) => {
            if (
              reportedResponses[i] === 0 &&
              pendingDenouncesResponses[i] === 0 &&
              (item.status === "active" || item.status === "paused")
            ) {
              newActiveIds.push(item.id);
            } else if (
              reportedResponses[i] !== 0 ||
              pendingDenouncesResponses[i] !== 0
            ) {
              newInvalidIds.push(item.id);
            } else {
              newNotFoundIds.push(item.id);
            }
          });

          validateIds(newActiveIds, newNotFoundIds, newInvalidIds);
        } else {
          let errorMessage = "";

          if (uniqueSiteId.length > 1 && notEnrolledRightsIds.length === 0){
            errorMessage = t("add_suspicious_xlsx.only_one_site");
          }  
          else if (ids.length > 1000 && notEnrolledRightsIds.length === 0)
            errorMessage = t("add_suspicious_xlsx.too_many_message");
          else if (notEnrolledRightsIds.length)
            errorMessage =
              notEnrolledRightsIds.length > 1
                ? t("add_suspicious_xlsx.not_enrolled_rigths_listings_message")
                : t("add_suspicious_xlsx.not_enrolled_rigths_listing_message");
          else errorMessage = t("add_suspicious_xlsx.not_valid_found_message");

          setAcceptedFiles([]);
          setErrorMsg(errorMessage);
        }
      } catch (e) {
        // eslint-disable-next-line
        //console.log(e);
        //console.log(e.stack);
        setAcceptedFiles([]);
        setErrorMsg(t("add_suspicious_xlsx.error_reading_file"));
      }

      setProcessing(false);
      setArticlesIds(newActiveIds);
      setInvalidIds(newInvalidIds);
      setNotFoundIds(newNotFoundIds);
    },
    [profile, mapItem, validateFiles, validateIds]
  );

  const handleSubmit = (e) => {
    e.preventDefault();

    const string = comment.trim().replace(/\s+/g, "");
    if (string.length < 5) {
      setShowWarningMessage(true);
      return;
    } else {
      setProcessing(true);
      const [filteredArticles, blindagesIds, blindagesInfoExcel] =
        checkBlindageMultiple(code);

      const payload = {
        articlesIds: filteredArticles,
        code,
        code_description: t(`report_v2.report_code.${code}`),
        description: comment.trim(),
        author: {
          id: profile.id,
          givenName: profile.givenName,
          familyName: profile.familyName,
        },
        source: "type_article_xls",
      };

      props.reportArticles({
        ...payload,
        // usage of redux
        history: props.history,
        //isImport: true,
      });

      setTimeout(() => {
        props.openModal({
          type: BLINDAGE_REPORT_EXCEL,
          lang: profile.lang,
          blindagesIds: blindagesIds,
          articlesIds: filteredArticles,
          acceptedFiles: acceptedFiles,
          blindagesInfoExcel: blindagesInfoExcel,
        });
      }, 100);
    }
  };

  const handleReset = useCallback((e) => {
    e.preventDefault();
    setProcessing(true);

    setTimeout(() => {
      setArticlesIds([]);
      setInvalidIds([]);
      setNotFoundIds([]);
      setAcceptedFiles([]);
      setProcessing(false);
    }, 500);
  }, []);

  const handleChangeComment = (e) => {
    e.preventDefault();
    const string = e.target.value.trim().replace(/\s+/g, "");

    if (string.length >= 0 && string.length < 5) {
      setShowWarning(true);
      setBorder(false);
    } else {
      setBorder(true);
      setShowWarning(false);
      setShowWarningMessage(false);
    }
    setComment(e.target.value);
  };

  const handleReasonsChange = useCallback((code) => {
    setCode(code);
    setComment("");
    setBorder(false);
    setShowWarning(false);
    setShowWarningMessage(false);
  }, []);

  const getReasonsOptions = () => {
    const siteId = articlesIds[0].trim().slice(0, 3).toUpperCase();
    const notices = props.profile.allowedNotices
      .filter((site) => site.siteId === siteId)
      .map((site) => site.notices)[0]
      .filter((notice) => !isDenounceWithImages(notice.id));

    return [
      <Option value="no-selected" key="default">
        {t("add_suspicious_xlsx.reason_select")}
      </Option>,
      ...(notices || []).map((item) => (
        <Option key={item.id} value={item.id}>
          {t(`report_v2.report_code.${item.id}`)}
        </Option>
      )),
    ];
  };

  const results = articlesIds.length + notFoundIds.length + invalidIds.length;

  return (
    <div tabIndex="1" className="modal-content add-suspicious-xlsx-modal">
      <form onSubmit={handleSubmit} className="new_reported_article">
        <div className="top-container">
          <div className="title">{t("add_suspicious_xlsx.modal_title")} </div>

          <button onClick={handleClose} className="btn-modal-close">
            &times;
          </button>

          <div className="subtitle">
            {t("add_suspicious_xlsx.modal_subtitle_text1")}
            <ImportedFileExample profile={profile} />
          </div>
        </div>
        {processing && (
          <div className="spinner">
            <div>
              <Icon icon={faCircleNotch} spin />
            </div>
          </div>
        )}

        {processing || !articlesIds.length ? (
          <>
            <FileUploader
              accept=".xlsx, .xls"
              maxFilesize={10} //MB,
              onDrop={handleOnDrop}
              onDragText={t("add_suspicious_xlsx.on_drag_text")}
              emptyText={t("add_suspicious_xlsx.empty_text")}
              emptyDragText={t("add_suspicious_xlsx.empty_drag_text")}
              fileTooLargeText={t("add_suspicious_xlsx.drag_file_too_large")}
            />
            {errorMsg && (
              <div className="alert alert-danger" role="alert">
                <div>
                  <Icon icon={faExclamationCircle} />
                  {errorMsg}
                </div>
              </div>
            )}
          </>
        ) : (
          <>
            <div className="results-container">
              <div className="results">
                {acceptedFiles[0].name}&nbsp;
                <span>
                  {results}&nbsp;
                  {results === 1
                    ? t("add_suspicious_xlsx.element")
                    : t("add_suspicious_xlsx.elements")}
                </span>
                <Icon
                  icon={faTrashAlt}
                  iProps={{
                    onClick: handleReset,
                    className: "erase pull-right",
                  }}
                />
              </div>

              <div className="numbers">
                <p>
                  <Icon icon={faCheckCircle} iProps={{ className: "check" }} />
                  {articlesIds.length}&nbsp;
                  {articlesIds.length === 1
                    ? t("add_suspicious_xlsx.listing_found")
                    : t("add_suspicious_xlsx.listings_found")}
                </p>
                {notFoundIds.length > 0 && (
                  <p>
                    <Icon
                      icon={faExclamationCircle}
                      iProps={{ className: "errors" }}
                    />
                    {notFoundIds.length}&nbsp;
                    {notFoundIds.length === 1
                      ? t("add_suspicious_xlsx.error")
                      : t("add_suspicious_xlsx.errors")}
                  </p>
                )}
                {invalidIds.length > 0 && (
                  <p>
                    <Icon
                      icon={faExclamationCircle}
                      iProps={{ className: "invalids" }}
                    />
                    {invalidIds.length}&nbsp;
                    {invalidIds.length === 1
                      ? t("add_suspicious_xlsx.listing_duplicated")
                      : t("add_suspicious_xlsx.listings_duplicated")}
                  </p>
                )}
              </div>

              <ImportedFileDetails
                lang={profile.lang}
                invalidIds={invalidIds}
                articlesIds={articlesIds}
                notFoundIds={notFoundIds}
                blindagesIds={blindagesIds}
              />
              <div className="notices-reasons-container input-group">
                <Select
                  defaultValue="no-selected"
                  onChange={handleReasonsChange}
                >
                  {getReasonsOptions()}
                </Select>
              </div>

              <div className="description-container">
                <h3>
                  {t("add_suspicious_xlsx.comment_title")}
                  {/* <span>({t('optional')})</span> */}
                </h3>
                <textarea
                  rows="4"
                  cols="40"
                  value={comment}
                  name="comment"
                  className={`form_control comment__field ${
                    showWarning ? "comment__field_error" : ""
                  }${border ? "comment__field_success" : ""}`}
                  onChange={handleChangeComment}
                  placeholder={t("add_suspicious_xlsx.comment_description")}
                ></textarea>
                {showWarningMessage && (
                  <div style={{ color: "red" }}>
                    {t("add_suspicious_xlsx.comment_warning")}
                  </div>
                )}
              </div>
            </div>
          </>
        )}

        <div className="actions">
          <div className="content">
            <button
              className="btn btn-success"
              disabled={
                processing || !articlesIds.length || code === "no-selected"
              }
            >
              {t("add_suspicious_xlsx.btn_import")}
            </button>
            <button onClick={handleClose} className="btn transparent">
              {t("cancel")}
            </button>
          </div>
        </div>
      </form>
    </div>
  );
}

const mapStateToProps = (state) => ({
  profile: state.global.profile,
  blindages: state.global.blindages,
});

export default connect(mapStateToProps, {
  openModal,
  closeModal,
  reportArticles,
  fetchBlindages,
})(AddSuspiciousXlsx);
