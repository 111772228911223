import React from "react";
import classnames from "classnames";


export default function Dropdown(props) {
  const {
    icon,
    style,
    children,
    defaultOpen = false,
    ...rest
  } = props;

  const [isDropdrownOpen, setIsDropdownOpen] = React.useState(defaultOpen);

  const toggleDropdown = React.useCallback(() => {
    setIsDropdownOpen(prevVal => !prevVal);
  }, []);

  const handleBlur = React.useCallback(() => {
    if (!isDropdrownOpen) return;
    setIsDropdownOpen(false);
  }, [isDropdrownOpen]);

  const handleMouseDown = React.useCallback(e => {
    if (!isDropdrownOpen) return;
    e.preventDefault();
    e.stopPropagation();
  }, [isDropdrownOpen]);

  return (
    <ul
      {...rest}
      tabIndex="1"
      onBlur={handleBlur}
      style={{ outline: 'none', ...style }}
      onMouseDown={handleMouseDown}
    >
      <li
        onClick={toggleDropdown}
        className={classnames(
          "dropdown",
          { "open": isDropdrownOpen }
        )}
      >
        <button className="dropdown__icon-container">
          {icon}
        </button>
        <ul className="dropdown__menu">
          {children}
        </ul>
      </li>
    </ul>
  );
}