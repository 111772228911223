import React from "react";
import ImageGallery from "components/ImageGallery/catalogo";
import ImageGalleryModal from "components/ImageGalleryModal/Catalogo";

export default function ArticleImagesCatalogo(props) {
  const {productId,onReportOpen, alt, thumbnail, images: propsImages, openModalOnClick, modal, viewMore, closeModal, articleId, profileCanDenounceImages } = props; 
  const originalImages = propsImages;
//console.log("productIdproductIdproductId"+productId)
  const images = React.useMemo(() => ( 
    propsImages.length ? 
      propsImages.map((image) => ({
        id: image.id,
        alt: image.id,
        src: image.url,
        thumbnail: image.url.replace('-O.', '-R.')
      }))
      : thumbnail ?
        [{
          alt,
          src: thumbnail.replace('http', 'https').replace('-I.', '-O.'),
          thumbnail: thumbnail.replace('http', 'https').replace('-I.', '-R.'),
        }] :
        null
  ), [alt, thumbnail, propsImages]);

  
  if( !modal ){
    return (
      <ImageGallery
        images={images}
        onReportOpen={onReportOpen}
        articleId={articleId}
        productId={productId}
        viewMore={viewMore}
        originalImages={originalImages}
        openModalOnClick={openModalOnClick}
        closeModal={closeModal}
        profileCanDenounceImages={profileCanDenounceImages}
        fallbackImage={{
          alt,
          src: 'https://http2.mlstatic.com/resources/frontend/statics/img-not-available/1.0.0/V.jpg'
        }} 
      />
    );
  }

  return (
    <ImageGalleryModal
      images={images}
      onReportOpen={onReportOpen}
      articleId={articleId}
      productId={productId}
      openModalOnClick={openModalOnClick}
      closeModal={closeModal}
      profileCanDenounceImages={profileCanDenounceImages}
      fallbackImage={{
        alt,
        src: 'https://http2.mlstatic.com/resources/frontend/statics/img-not-available/1.0.0/V.jpg'
      }} 
    />
  );
}