import { from } from 'rxjs';
import { ofType } from 'redux-observable';
import { mergeMap } from 'rxjs/operators';
import { gqlQuery } from "libs/awsLib";
import { queryClosedCasesList } from "gql/index";
import {
  FETCH_CLOSED_CASES_LIST,
  fetchClosedCasesListFullFilled,
  fetchClosedCasesListError,
} from "redux/actions/closedCasesList";


/* EPICS */
export const fetchClosedCasesListEpic = action$ => action$.pipe(
  ofType(FETCH_CLOSED_CASES_LIST),
  mergeMap(action =>
    from(
      gqlQuery(queryClosedCasesList, action.payload)
        .then(response => fetchClosedCasesListFullFilled({
          closedCasesList: response.closedCasesList.cases,
          closedCasesListTotal: response.closedCasesList.paging.total,
        }))
        .catch(fetchClosedCasesListError)
    )
  )
);
