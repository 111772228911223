/* eslint-disable max-len */
/**
 * CONSTANTS
 */
export const FETCH_CASE_DESCRIPTION = "FETCH_CASE_DESCRIPTION";
export const FETCH_CASE_DESCRIPTION_FULLFILLED = "FETCH_CASE_DESCRIPTION_FULLFILLED";
export const FETCH_CASE_DESCRIPTION_ERROR = "FETCH_CASE_DESCRIPTION_ERROR";

export const RESPOND_COUNTER_NOTICE = "RESPOND_COUNTER_NOTICE";
export const RESPOND_COUNTER_NOTICE_SUCCESS = "RESPOND_COUNTER_NOTICE_SUCCESS";
export const RESPOND_COUNTER_NOTICE_ERROR = "RESPOND_COUNTER_NOTICE_ERROR";

export const FETCH_SELLER_COUNTER_NOTICES = "FETCH_SELLER_COUNTER_NOTICES";
export const FETCH_SELLER_COUNTER_NOTICES_SUCCESS = "FETCH_SELLER_COUNTER_NOTICES_SUCCESS";
export const FETCH_SELLER_COUNTER_NOTICES_ERROR = "FETCH_SELLER_COUNTER_NOTICES_ERROR";

export const FETCH_PROFILES_OPTIONS = "FETCH_PROFILES_OPTIONS";
export const FETCH_PROFILES_OPTIONS_SUCCESS = "FETCH_PROFILES_OPTIONS_SUCCESS";
export const FETCH_PROFILES_OPTIONS_ERROR = "FETCH_PROFILES_OPTIONS_ERROR";

export const ROLLBACK_CASE = "ROLLBACK_CASE";
export const ROLLBACK_CASE_SUCCESS = "ROLLBACK_CASE_SUCCESS";
export const ROLLBACK_CASE_ERROR = "ROLLBACK_CASE_ERROR";

/**
 * DISPATCHERS
 */
export const fetchCaseDescription = payload => ({ type: FETCH_CASE_DESCRIPTION, payload });
export const fetchCaseDescriptionFullFilled = payload => ({ type: FETCH_CASE_DESCRIPTION_FULLFILLED, payload });
export const fetchCaseDescriptionError = payload => ({ type: FETCH_CASE_DESCRIPTION_ERROR, payload });

export const respondCounterNotice = payload => ({ type: RESPOND_COUNTER_NOTICE, payload });
export const respondCounterNoticeSuccess = payload => ({ type: RESPOND_COUNTER_NOTICE_SUCCESS, payload });
export const respondCounterNoticeError = payload => ({ type: RESPOND_COUNTER_NOTICE_ERROR, payload });

export const fetchSellerCounterNotices = payload => ({ type: FETCH_SELLER_COUNTER_NOTICES, payload });
export const fetchSellerCounterNoticesSuccess = payload => ({ type: FETCH_SELLER_COUNTER_NOTICES_SUCCESS, payload });
export const fetchSellerCounterNoticesError = payload => ({ type: FETCH_SELLER_COUNTER_NOTICES_ERROR, payload });

export const fetchProfilesOptions = () => ({ type: FETCH_PROFILES_OPTIONS });
export const fetchProfilesOptionsSuccess = payload => ({ type: FETCH_PROFILES_OPTIONS_SUCCESS, payload });
export const fetchProfilesOptionsError = payload => ({ type: FETCH_PROFILES_OPTIONS_ERROR, payload });

export const rollbackCase = payload => ({ type: ROLLBACK_CASE, payload });
export const rollbackCaseSuccess = payload => ({ type: ROLLBACK_CASE_SUCCESS, payload });
export const rollbackCaseError = payload => ({ type: ROLLBACK_CASE_ERROR, payload });