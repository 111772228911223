export default `
  query(
    $size: Int!,
    $from: Int!,
    $sort: String,
    $order: String,
    $sellerId: String
  ) {
    counterNotices(
      size: $size,
      from: $from,
      sort: $sort,
      order: $order,
      sellerId: $sellerId
    ) {
      cases {
        articleId
        articleTitle
        denounceDate
        articleStatus
        denounceReasonText
        status
      }
      paging {
        total
      }
    }
  }
`;