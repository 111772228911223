import { from } from 'rxjs';
import { ofType } from 'redux-observable';
import { mergeMap } from 'rxjs/operators';
import { gqlQuery } from "libs/awsLib"; 
import { 
  querySeller,
  mutationBlackSeller,
  mutationWhiteSeller,
  mutationDeleteSellerFromDB,
} from "gql/index";
import {
  FETCH_SELLER,
  fetchSellerSuccess,
  fetchSellerError,

  SET_WHITE_SELLER,
  setWhiteSellerSuccess,
  setWhiteSellerError,

  SET_BLACK_SELLER,
  setBlackSellerSuccess,
  setBlackSellerError,

  UNSET_BLACK_SELLER,
  unsetBlackSellerSuccess,
  unsetBlackSellerError,

  UNSET_WHITE_SELLER,
  unsetWhiteSellerSuccess,
  unsetWhiteSellerError,
} from "redux/actions/sellers";


/* EPICS */
export const fetchSellerEpic = action$ => action$.pipe(
  ofType(FETCH_SELLER),
  mergeMap(action =>
    from(
      gqlQuery(querySeller, action.payload)
        .then(fetchSellerSuccess)
        .catch(fetchSellerError)
    )
  )
);

export const setWhiteSellerEpic = action$ => action$.pipe(
  ofType(SET_WHITE_SELLER),
  mergeMap(({ payload }) =>
    from(
      gqlQuery(
        mutationWhiteSeller, 
        { siteId: payload.siteId, sellerId: payload.sellerId }
      )
        .then(response => {
          payload.onSuccess && payload.onSuccess();
          return response.whiteListSeller;
        })
        .then(setWhiteSellerSuccess)
        .catch(setWhiteSellerError)
    )
  )
);

export const setBlackSellerEpic = action$ => action$.pipe(
  ofType(SET_BLACK_SELLER),
  mergeMap(({ payload }) =>
    from(
      gqlQuery(
        mutationBlackSeller, 
        { siteId: payload.siteId, sellerId: payload.sellerId }
      )
        .then(response => {
          payload.onSuccess && payload.onSuccess();
          return response.blackListSeller;
        })
        .then(setBlackSellerSuccess)
        .catch(setBlackSellerError)
    )
  )
);

export const unsetBlackSellerEpic = action$ => action$.pipe(
  ofType(UNSET_BLACK_SELLER),
  mergeMap(({ payload }) =>
    from(
      gqlQuery(mutationDeleteSellerFromDB, payload)
        .then(response => {
          payload && payload.onSuccess && payload.onSuccess();
          return response;
        })
        .then(unsetBlackSellerSuccess)
        .catch(unsetBlackSellerError)
    )
  )
);

export const unsetWhiteSellerEpic = action$ => action$.pipe(
  ofType(UNSET_WHITE_SELLER),
  mergeMap(({ payload }) =>
    from(
      gqlQuery(mutationDeleteSellerFromDB, payload)
        .then(response => {
          payload && payload.onSuccess && payload.onSuccess();
          return response;
        })
        .then(unsetWhiteSellerSuccess)
        .catch(unsetWhiteSellerError)
    )
  )
);
