export default `
  mutation(
    $siteId: String!,
    $sellerId: String! 
  ) {
    blackListSeller(
      siteId: $siteId,
      sellerId: $sellerId 
    ) {
      id
      siteId
    }
  }
`;