import React from "react";
import classnames from "classnames";
import './image-gallery-modal.scss';
import { t } from "utils";
import { ARTICLE_REPORT_DRAWER } from "components/Modal";
import { openModal } from "redux/actions/global";


function ImageGalleryModal(props) {
  const { images, fallbackImage, onReportOpen, closeModal, articleId, profileCanDenounceImages, productId} = props;
  const [currentImageIndex, setCurrentImageIndex] = React.useState(0);
  const [open, setOpen] = React.useState(false);

  return (
    <div
      className="image-gallery"
      style={{ minHeight: '479px', overflowY: 'hidden' }}
    >
      <div className="image-gallery-wrapper">
        <div className="image-gallery-figures">
          {
            (images && images.length) ?
              images
                .map((image, i) =>
                  <React.Fragment key={`thumbnail_fragment_figures_modal_${i}`}>
                    <input
                      key={`thumbnail_radio_modal_${i}`}
                      type="radio"
                      id={`thumbnail-radio-modal-${i}`}
                      name="thumbnail-radio-modal"
                      defaultChecked={i === currentImageIndex} 
                    />
                    <figure
                      key={`thumbnail_figure_modal_${i}`}
                      className="image-container"
                    >
                      <div className="image-trigger">
                        <img
                          alt={image.alt}
                          src={image.src}
                        />
                      </div>  
                    </figure>
                  </React.Fragment>
                ) :
              (
                <>
                  <input type="radio" name="thumbnail-radio" defaultChecked={true} />
                  <figure className="image-container">
                    <div className="image-trigger">
                      <img
                        alt={fallbackImage.alt}
                        src={fallbackImage.src}
                      />
                    </div>
                  </figure>
                </>
              )
          }
        </div>
        <div className="image-gallery-labels">
          {
            (images && images.length) &&
              images
                .map((image, i) =>
                  <React.Fragment key={`thumbnail_fragment_modal_${i}`}>
                    <label
                      key={`thumbnail_modal_${i}`}
                      htmlFor={`thumbnail-radio-modal-${i}`}
                      className={classnames(
                        "label_thumbnail",
                        { "selected": i === currentImageIndex }
                      )}
                    >
                      <img
                        width="70"
                        height="70"
                        key={`image_modal_${i}`}
                        alt={image.alt}
                        src={image.thumbnail}
                        onMouseOver={e => {
                          e.target.click();
                        }}
                        onClick={() => { 
                          setCurrentImageIndex(i);
                        }}
                      />
                    </label>
                  </React.Fragment>
                )
          }
          
          <div className="modalsuspicious">
         <button class="btn suspicious" 
                 onClick={() => { 
                  closeModal();
                  onReportOpen(articleId,productId,true);
                  //console.log('productId = '+productId);
                  //console.log('articleId = '+articleId);
                }}
                disabled={!profileCanDenounceImages()}
         
         >{t('article_catalogue_detail.report_images')}</button>
         </div>

        </div>
      </div>
    </div> 
  );
}

export default ImageGalleryModal;
