import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import Alert from "react-s-alert";
import ReactGA from "react-ga";
import classnames from "classnames";
import moment from "moment";
import "moment/locale/es";
import "moment/locale/pt-br";
import i18next from "i18next";

import { connect } from "react-redux";
import {
  logOut,
  setLang,
  openModal,
  closeHelp,
  fetchSites,
  fetchProfile,
  toggleLeftNav,
  fetchMemberRestriction,
  updateProfile,
  fetchCurrency,
} from "redux/actions/global";
import {
  fetchReportedArticlesCount,
} from "redux/actions/reportedArticles";
import { fetchPendingCasesToExpire } from "redux/actions/pendingCasesList";
import AppLoader from "containers/App/Loader";
import Routes from "Routes";
import Help from "components/Help";
import MessageBox from "components/MessageBox";
import Header from "components/Header";
import {
  Modal,
  VERIFY_PROFILE,
  ONBOARDING_SLIDE,
  SUGGESTIONS_SLIDE,
} from "components/Modal";
import MaintenancePage from "components/MaintenancePage";
import NoServerResponse from "components/NoServerResponse";
import SidebarNew from "components/SidebarNew";
import LeftNavigation from "components/LeftNavigation";
import {
  authUser,
  signOutUser,
  getCurrentUser,
} from "libs/awsLib";
import { get } from "utils";
import "styles/main.scss";
import "react-s-alert/dist/s-alert-default.css";
import "react-photoswipe/lib/photoswipe.css";
import { Footer } from "components/Footer";


class App extends Component {
  constructor(props) {
    super(props);

    const user = getCurrentUser();

    this.state = {
      versionError: false,
      isAuthenticating: true,
      isAuthenticated: !!user,
      fromLogin: false
    };
  }

  async componentDidMount() {
    try {
      this.initializeGa();
      await authUser() && this.handleLoginSuccess();
    } catch (e) {
      this.handleLogout();
      alert(e);
    }

    this.setState({ isAuthenticating: false });
  }

  componentDidUpdate(prevProps) {
    if (
      this.state.isAuthenticated &&
      prevProps.profile !== this.props.profile
    ) {
      this.handleProfileUpdated(prevProps);
    }

    if (this.props.location.pathname !== prevProps.location.pathname) {
      this.handleUpdateRoute();
    }

    if (
      (
        this.props.fetchProfileError > 2
      ) ||
      (
        !prevProps.allowedNoticesError &&
        this.props.allowedNoticesError &&
        this.props.allowedNoticesError.message &&
        this.props.allowedNoticesError.message !== "Failed to fetch"
      ) || (
        this.props.profile &&
        this.props.profile.allowedNotices &&
        !this.props.profile.allowedNotices.length
      ) || (
        this.props.profile &&
        this.props.profile.allowedNotices &&
        this.props.profile.authorizedSiteIds && (
          this.props.profile.allowedNotices.length <
          this.props.profile.authorizedSiteIds.length
        )
      )
    ) {
      this.handleLogout();
    }

    if (
      this.props.graphqlErrors
      && prevProps.graphqlErrors !== this.props.graphqlErrors
    ) {
      const invalidTokenError = this.props.graphqlErrors.reduce((prev, current) => {
        if (current.message.indexOf('has no valid token') > -1) {
          prev = true;
        }
        return prev;
      }, false);

      if (invalidTokenError) {
        this.handleLogout();
      } else {
        const versionError = this.props.graphqlErrors.reduce((prev, current) => {
          if (current.message.indexOf('Cannot query field') > -1) {
            prev = true;
          }
          return prev;
        }, false);

        if (versionError) this.setState({ versionError });
      }
    }
  }

  handleProfileUpdated = prevProps => {
    const profile = this.props.profile;
    const lang = get(profile, 'lang', null);

    if (get(prevProps, 'profile.lang', null) !== lang) {
      this.handleSetLang(lang.toLowerCase());
    }

    if (!Object.keys(prevProps.profile).length) {
      ReactGA.ga('set', 'dimension1', profile.groupId);
      ReactGA.ga('set', 'dimension2', profile.groupName);
    }

    if (get(prevProps, 'profile.id', null) !== profile.id) {
      const onBoardingModal = !get(profile, 'helps.onBoarding', false);
      const updateProfileModal = (
        !get(profile, 'isAcuerdo', false) &&
        !get(profile, 'isVerified', false) &&
        this.props.history.location.pathname.indexOf("/profile") === -1 &&
        !this.hasToVerifyProfile()
      );
      const suggestionsModal = profile.hasSuggestions && !get(profile, 'helps.suggestions', false);

      if (suggestionsModal) {
        this.props.openModal({
          type: SUGGESTIONS_SLIDE,
          history: this.props.history
        });
      } else if (onBoardingModal) {
        this.props.openModal({ type: ONBOARDING_SLIDE });
      }
      else if (updateProfileModal) {
        this.props.openModal({ type: VERIFY_PROFILE });
      }
    }
  }

  handleUpdateRoute = () => {
    Alert.closeAll();
  }

  initializeGa() {
    // eslint-disable-next-line no-undef
    ReactGA.initialize(process.env.REACT_APP_GA_KEY, {
      custom_map: { dimension1: 'AccountId', dimension2: 'AccountName' }
    });
    ReactGA.ga('js', new Date());
  }

  userHasAuthenticated = isAuthenticated => this.setState({ isAuthenticated });

  userComeFromLogin = fromLogin => this.setState({ fromLogin });

  hasToVerifyProfile = () => {
    return (
      !get(this.props.profile, 'isAcuerdo', false) &&
      !get(this.props.profile, 'isVerified', false) &&
      moment('2020-06-15').isBefore(new Date(), 'day')
    );
  }

  // redirectToSuggestions = () => {
  //   return (
  //     this.state.fromLogin === true &&
  //     this.props.profile.hasSuggestions && 
  //     get(this.props.profile, 'helps.suggestions', false)
  //   );
  // }

  handleSetLang = lang => {
    if (!lang) return;

    lang = lang.toLowerCase();

    i18next.changeLanguage(lang, (err) => {
      if (err) {
        /* eslint-disable-next-line */
        return console.log('something went wrong loading', err);
      }
    });

    this.props.setLang(lang);
    moment.locale(lang === 'pt' ? 'pt-br' : lang);

    if (this.state.isAuthenticated) {
      window.hj &&
        window.hj('trigger', `pppi-pulpou-launch-feedback-${lang}`) &&
        window.hj('trigger', `suggested_listings?${lang}`);
    }

  }

  handleToggleLeftNav = () => this.props.toggleLeftNav();

  destroySessions = () => {
    return new Promise((resolve, reject) => {
      signOutUser();
      this.props.logOut();
      this.userHasAuthenticated(false);
      resolve(true);
    })
  }

  handleLogout = async () => {
    const site = this.props.profile.meliUser.siteId.toLowerCase();
    await this.destroySessions();
    window.location.replace(`https://www.mercadolibre.com/jms/${site}/lgz/logout?go=https://pppi.mercadolibre.com`);
  }

  handleLoginSuccess = (fromLogin = false) => {
    this.props.fetchProfile();
    this.props.fetchSites();
    this.userHasAuthenticated(true);
    this.props.fetchMemberRestriction();
    this.props.fetchPendingCasesToExpire();
    this.props.fetchReportedArticlesCount();
    this.props.fetchCurrency();
    if (
      this.state.fromLogin === false &&
      fromLogin === true
    ) {
      this.userComeFromLogin(fromLogin);
    }
  }

  render() {
    const {
      profile,
      location,
      leftNavOpen,
      isModalOpen,
      isFetchingSites,
      isFetchingProfile,
      fetchProfileError,
      updateProfile
    } = this.props;

    const ready = (
      !isFetchingSites &&
      !isFetchingProfile &&
      !this.state.isAuthenticating
    );

    if (!ready) return <AppLoader />;

    const childProps = {
      leftNavOpen,
      setLang: this.handleSetLang,
      onLogout: this.handleLogout,
      isAuthenticated: this.state.isAuthenticated,
      handleLoginSuccess: this.handleLoginSuccess,
      handleToggleLeftNav: this.handleToggleLeftNav,
      isNotAcuerdo: !get(profile, 'isAcuerdo', false),
      hasToVerifyProfile: this.hasToVerifyProfile(),
      //redirectToSuggestions: this.redirectToSuggestions()
    };

    return (
      <section id="container">
        {
          this.state.isAuthenticated ?
            this.state.versionError ?
              // render version error
              <MaintenancePage
                onLogout={this.handleLogout}
                onRefresh={this.handleLoginSuccess}
              /> :
              fetchProfileError ?
                // render error getting user's info
                <NoServerResponse
                  onLogout={this.handleLogout}
                  onRefresh={this.handleLoginSuccess}
                /> :
                <>
                  <Header
                    location={location}
                    onLogout={this.handleLogout}
                    onToggleLeftNav={this.handleToggleLeftNav}
                  />
                  {/* <LeftNavigation
                    open={true}
                    location={location}
                  /> */}
                  <SidebarNew />
                  <Footer />
                  <section
                    id="main-content"
                    className={classnames(
                      { 'overflow--hidden': isModalOpen },

                    )}

                  >
                    <section
                      className={classnames(
                        'wrapper'

                      )}
                    >
                      <Help location={location} />
                      <MessageBox location={location} />
                      <Routes childProps={childProps} />
                    </section>
                  </section>
                </> :
            <section>
              <Routes childProps={childProps} />
            </section>
        }
        <Alert
          offset={32}
          timeout={5000}
          position="bottom"
          stack={{ limit: 3, spacing: 20 }}
        />
        <Modal />
      </section>
    );
  }
}

const mapStateToProps = state => state.global;

const actions = {
  logOut,
  setLang,
  closeHelp,
  openModal,
  fetchSites,
  fetchProfile,
  toggleLeftNav,
  fetchMemberRestriction,
  fetchPendingCasesToExpire,
  fetchReportedArticlesCount,
  fetchCurrency,
  updateProfile
};

export default withRouter(connect(mapStateToProps, actions)(App));