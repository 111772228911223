import React, { Component } from "react";
import { connect } from "react-redux";
import classnames from "classnames";
import { Link, withRouter } from "react-router-dom";
import {
  faCog,
  faUser,
  faBars,
  faSignOutAlt,
} from "@fortawesome/free-solid-svg-icons";
import { openModal, fetchProfiles } from "redux/actions/global";
import Tooltip from "components/Tooltip";
import Icon from "components/Icon";
import BreadCrumb from "components/BreadCrumb";
import LanguageDropdown from "components/LanguageDropdown";
import CurrencyDropdown from "components/CurrencyDropdown";
import { MANAGE_PROFILES } from "components/Modal";
import { t, get, getEnrollRightsSiteUrl } from "utils";
import Language from "components/LanguageDropdown/language";

class Header extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isDropdrownOpen: false,
    };
  }

  toggleDropdown = () => {
    this.setState({ isDropdrownOpen: !this.state.isDropdrownOpen });
  }

  handleBlur = () => {
    if (!this.state.isDropdrownOpen) {
      return;
    }

    this.setState({ isDropdrownOpen: false });
  }

  handleMouseDown = e => {
    if (!this.state.isDropdrownOpen) {
      return;
    }

    e.preventDefault();
    e.stopPropagation();
  }

  manageProfiles = () => {
    this.props.fetchProfiles();
    this.props.openModal({ type: MANAGE_PROFILES });
  }

  render() {
    const {
      profile,
      onLogout,
      location,
      noProfile,
      lang,
      onToggleLeftNav,
      isNavFromHeaderHidden,
    } = this.props;
    const siteId = get(profile, 'meliUser.siteId', false);

    return (
      <header className="header fixed-top clearfix">
        <div className="brand">
          <Link
            to="/"
            className={`logo ${lang}`}>
          </Link>
          {/* {
            (!noProfile && !isNavFromHeaderHidden) &&
              <div className="sidebar-toggle-box">
                <div className="bars" onClick={onToggleLeftNav}>
                  <Icon icon={faBars} />
                </div>
              </div>
          } */}
        </div>

        <div className="nav notify-row" id="top_menu">
          <ul className="nav top-menu"></ul>
        </div>

        <div className="top-nav clearfix">
          {
            !noProfile &&
            <>
              {/* <BreadCrumb location={location} /> */}
              <a
                target="_blank"
                rel="noreferrer noopener"
                className="btn enroll-rights"
                href={getEnrollRightsSiteUrl(
                  get(profile, 'meliUser.siteId', 'MLA'), lang
                )}
                onMouseOver={() => {
                  this.tooltip && this.tooltip.open();
                }}
                onMouseLeave={() => {
                  this.tooltip && this.tooltip.close();
                }}>
                <Tooltip
                  timeOutToOpen={0}
                  timeOutToClose={100}
                  ref={node => this.tooltip = node}>
                  {t('enroll_rights_cta_tooltip_header')}
                </Tooltip>
                {t('enroll_rights')}
              </a>
            </>
          }

          {/* TO DO: use the Dropdown component */}
          <Language />

          <CurrencyDropdown />

          {/* TO DO: use the Dropdown component */}
          <ul
            tabIndex="1"
            onBlur={this.handleBlur}
            onMouseDown={this.handleMouseDown}
            className="nav pull-right top-menu"
          >
            <li
              onClick={this.toggleDropdown}
              className={classnames(
                "dropdown",
                { "open": this.state.isDropdrownOpen }
              )}
            >
              <button>
                {
                  !noProfile &&
                  <>
                    <span className="nav-header-avatar-user">
                      <i className="nav-icon-user" />
                    </span>
                    <span className="username">
                      {profile.givenName}{' '}
                      {profile.groupName ? `(${profile.groupName.trim()})` : ''}
                    </span>
                  </>
                }
                <span className="icon-chevron"></span>
              </button>

              <ul
                className={classnames(
                  "logout",
                  "extended",
                  "dropdown-menu",
                  "nav-right-settings",
                )}>
                {
                  !noProfile &&
                  <>
                    <li onClick={this.manageProfiles}>
                      <button rel="nofollow">
                        <Icon icon={faUser} />  {t('change_profile')}
                      </button>
                    </li>
                    <li>
                      <Link
                        to="/profile"
                        className={classnames({ "hidden": profile.isAcuerdo })}
                      >
                        <Icon icon={faCog} />  {t('profile')}
                      </Link>
                    </li>
                  </>
                }
                {/* <li onClick={onLogout}>
                  <button rel="nofollow">
                    <Icon icon={faSignOutAlt} />  {t('logout')}
                  </button>
                </li> */}
                <li >


                  <Link to="/logout" >
                    <Icon icon={faSignOutAlt} />  {t('logout')}
                  </Link>


                </li>
              </ul>
            </li>
          </ul>
        </div>
      </header>
    );
  }
}

const mapStateToProps = state => ({
  lang: state.global.lang,
  sites: state.global.sites,
  profile: state.global.profile,
  isNavFromHeaderHidden: state.global.isNavFromHeaderHidden,
});

const actions = {
  openModal,
  fetchProfiles,
};

export default connect(mapStateToProps, actions)(withRouter(Header));