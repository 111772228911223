export default `
  query(
    $itemsIds: [String],
  ) {
    blindages(
      itemsIds: $itemsIds,
    ) {
      blindages {
        id
        item
        reason
        dueDate
      }      
    }
  }
`;