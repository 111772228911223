export default `
  mutation (
    $code: String!,
    $code_description: String!,
    $articlesIds: [String],
    $tags: [ReportedTagInputType],
    $description: String,
    $photosDenounced: [String],
    $originModel: String,
    $source: String,
  ) {
    editReportedArticles(reportedArticles: {
      articlesIds: $articlesIds
      tags: $tags
      code: $code
      description: $description
      code_description: $code_description
      photosDenounced: $photosDenounced
      originModel: $originModel
      source: $source,
    }) {
      articlesIds
    }
  }
`;