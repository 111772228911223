import { alertSuccess, alertError } from "./index";
import { t } from "utils";


/* eslint-disable no-console */
export const deleteBlackListSellersOptimistic = (state, payload) => {	
  const blackListSellers = state.blackListSellers.slice(0)
    .map(seller => {
      if (seller.id === payload.sellerId) { seller.removed = true; }
      return seller;
    });

  return {
    ...state,
    isLoading: true,
    blackListSellers,
  };
};

export const deleteBlackListSellersSuccess = (state, payload) => {
  const blackListSellers = [
    ...state.blackListSellers,
    ...(payload.blackListSellers || []),
  ].filter(seller => seller.id !== payload.id);

  alertSuccess(t('black_sellers.black_list_seller_deleted'));

  return {
    ...state,
    isLoading: false,
    blackListSellers,
    blackListSellersFrom: payload.blackListSellersFrom,
    blackListSellersTotal: state.blackListSellersTotal - 1,
  };
};

export const deleteBlackListSellersError = (state, payload) => {
  const blackListSellers = state.blackListSellers.slice(0)
    .map(seller => {
      if (seller.id === payload.id) { delete seller.removed; }
      return seller;
    });

  alertError(t('black_sellers.black_list_seller_delete_error'));

  return {
    ...state,
    isLoading: false,
    blackListSellers,
  };
};

export const fetchBlackListSellersError = (state, payload) => {
  alertError(t('black_sellers.fetch_black_list_seller_error'));
  
  console.error(payload);

  return { 
	  ...state,
	  isLoading: false,
  };
};
