import { alertError } from "./index";
import { t } from "utils";


/* eslint-disable no-console */
export const fetchSellerSuccess = (state, payload) => {
  const sellersMap = state.sellersMap;

  sellersMap[payload.seller.id] = payload.seller;

  return {
    ...state,
    sellersMap
  };
};

export const fetchSellerError = (state, payload) => {
  alertError(t('fetch_seller_error'));
  console.error(payload);
  return state;
};

export const setBlackSellerSuccess = (state, payload) => {
  const sellersMap = state.sellersMap;

  sellersMap[payload.id] = { 
    ...(sellersMap[payload.id] || []),
    blackSeller: true,
    whiteSeller: false, 
  };

  return {
    ...state,
    sellersMap
  };
};

export const setWhiteSellerSuccess = (state, payload) => {
  const sellersMap = state.sellersMap;

  sellersMap[payload.id] = {
    ...(sellersMap[payload.id] || {}),
    blackSeller: false,
    whiteSeller: true,
  };

  return {
    ...state,
    sellersMap
  };
};

export const unsetBlackSellerSuccess = (state, payload) => {
  const sellersMap = state.sellersMap;

  sellersMap[payload.deleteSellerFromDB.deletedId] = {
    ...(sellersMap[payload.deleteSellerFromDB.deletedId] || []),
    blackSeller: false,
  };

  return {
    ...state,
    sellersMap
  };
};

export const unsetWhiteSellerSuccess = (state, payload) => {
  const sellersMap = state.sellersMap;

  sellersMap[payload.deleteSellerFromDB.deletedId] = {
    ...(sellersMap[payload.deleteSellerFromDB.deletedId] || {}),
    whiteSeller: false,
  };

  return {
    ...state,
    sellersMap
  };
};

