import { LINKED_ITEMS } from 'components/Modal';
import React from 'react';
import ReactGA from 'react-ga';
import { connect } from 'react-redux';
import { openModal } from "redux/actions/global";
import { t } from 'utils';
import { LEGAL_LINKS, TERMS } from './constants';


const TermsConditions = props => {
    const termsLinks = LEGAL_LINKS[TERMS];

    const onClick = e => {
        ReactGA.ga('send', 'event', 'PRE_LOGIN', 'GO_TO_TERMS');

        props.openModal({
            type: LINKED_ITEMS,
            title: t('terms_and_conditions'),
            items: Object.keys(termsLinks).map(
                key => ({
                    name: t(`countries_legal.${key}`),
                    url: termsLinks[key]
                   })
            )
        });

        return false;
    }

    return (
        <a onClick={onClick} {...props}>
            {t('terms_and_conditions')}
        </a>
    )
}

const mapStateToProps = state => ({ lang: state.global.lang });

const actions = {
    openModal
};

export default connect(mapStateToProps, actions)(TermsConditions);
