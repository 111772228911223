export default `
  fragment noticedReportFields on NoticedReportType {
    id
    url
    title
    ready
    failed
    createdAt
    generating
    emply
  }
`;