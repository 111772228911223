export default `
query(
  $size: Int!,
  $from: Int!
)
{
    pendingDenouncesCount(
      size: $size,
      from: $from
    ){
      paging {
        total
        limit
        offset
      }
    }
  }
`;
