export default `
	query(
		$keyword: String,
		$source: String!,
		$sellerId: String,
		$status: String,
		$sites: [SiteInputType]
	) {
		search(
			keyword: $keyword,
			source: $source,
			sellerId: $sellerId,
			status: $status,
			sites: $sites,
		) {
			count
			keyword
			status
			sellerId
			sites {
				id 
				name
				logo 
				active   
				status
				countrySites {
					id
					status
					active
					count 
					country {
						code
						name
					}
				}
			}
		}
	}
`;