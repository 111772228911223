export default `{
  profile {
    allowedNotices {
      siteId
      notices {
        id
        type
      }
    }
  }
}`;