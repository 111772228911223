import { articleFields } from "../fragments";

export default `
  ${articleFields}

  mutation (
    $lang: String!,
    $currency: String!,
    $givenName: String!,
    $familyName: String,
    $settings: String,
    $dialog: Boolean,
    $didReport: Boolean,
    $onBoarding: Boolean,
    $onBoardingPendingDenounced: Boolean,
    $onBoardingPendingDetail: Boolean,
    $suggestions: Boolean,
    $didReportExcel: Boolean,
    $redirectFlowInfo: Boolean,
    $restrictionNewMember: Boolean,
    $suggestedListingsCountry: String,
    $blockDenounceReminderUntil: String,
    $reportImportTooltipUntil: Float,
    $translateTooltipAlreadyShown: Boolean,
    $newReasonTooltipAlreadyShown: Boolean,
    $discardTooltipAlreadyShown: Boolean,
    $noticesLimitDayCount: Int,
    $articlesPinned: [ArticlesPinnedInputType]
  ){
    updateProfile(profile: {
      lang: $lang
      currency: $currency
      givenName: $givenName
      familyName: $familyName
      settings: $settings
      suggestedListingsCountry: $suggestedListingsCountry
      blockDenounceReminderUntil: $blockDenounceReminderUntil
      reportImportTooltipUntil: $reportImportTooltipUntil
      noticesLimitDayCount: $noticesLimitDayCount
      helps: {
        dialog: $dialog
        didReport: $didReport
        onBoarding: $onBoarding
        onBoardingPendingDenounced: $onBoardingPendingDenounced
        onBoardingPendingDetail: $onBoardingPendingDetail
        suggestions: $suggestions
        didReportExcel: $didReportExcel
        redirectFlowInfo: $redirectFlowInfo
        restrictionNewMember: $restrictionNewMember
        articlesPinned: $articlesPinned
        discardTooltipAlreadyShown: $discardTooltipAlreadyShown
        translateTooltipAlreadyShown: $translateTooltipAlreadyShown
        newReasonTooltipAlreadyShown: $newReasonTooltipAlreadyShown
      }
    }) {
      id
      lang
      groupId
      currency
      settings
      givenName
      familyName
      helps {
        dialog
        didReport
        onBoarding
        suggestions
        didReportExcel
        redirectFlowInfo
        restrictionNewMember
        articlesPinned {
          ...articleFields
        }
        translateTooltipAlreadyShown
        newReasonTooltipAlreadyShown
        discardTooltipAlreadyShown
        onBoardingPendingDenounced
        onBoardingPendingDetail
      }
      suggestedListingsCountry
      noticesLimitDayCount
    }
  }
`;