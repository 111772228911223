import React from "react";
import Header from "components/Header";
import PoweredBy from "components/PoweredBy";
import { t } from "utils";

const MaintenancePage = (props) => {
  return (
    <>
      <Header {...props} noProfile />
      <div className="maintenance-container">
        <div>
          <img
            alt="maintenance"
            className="maintenance-img" 
            src="/images/maintenance.svg" 
          />
          <p>
            <strong>
              {t("maintenance.we_are_working")}  
            </strong>
            <br />
            <span>
              {t("maintenance.come_back_soon")}
            </span>
          </p>
        </div>
        <PoweredBy />
      </div>
    </>
  );
};

export default MaintenancePage;