export default `
  mutation(
    $caseId: Int!,
    $siteId: String!,
    $description: String,
    $rejectMemberId: Int,
    $documentationApproved: Boolean!,
  ) {
    respondCounterNotice(data: {
      caseId: $caseId,
      siteId: $siteId,
      description: $description,
      rejectMemberId: $rejectMemberId,
      documentationApproved: $documentationApproved,
    }) {
      caseId
      status
      articleId
      lastUpdated
      groupResponse
      rejectMember {
        id
        text_es
        sub_text_es
        text_en
        sub_text_en
        text_pt
        sub_text_pt
      }
    }
  }
`;