import { 
  alertError,
  alertSuccess, 
} from "./index";
import { t } from "utils";

/* eslint-disable */
export const fetchLiveSearchesError = (state, payload) => {
  alertError(t('server_error'));  
  console.error(payload);

  return { 
    ...state, 
    isLoading: false,
    getLLSSError: true,
  };
};

export const createLiveSearchError = (state, payload) => {
  if (payload === "User not found.") {
    alertError(t('user_not_found'));
  } else if (payload && payload.message === "Livesearch already exists.") {
    alertError(t('dont-repeat-livesearch'));
  } else {
    alertError(t('live_search_create_error')); 
  }
  
  console.error(payload);

  return { ...state, isCreating: false };
};

export const deleteLiveSearchOptimistic = (state, id) => {
  let liveSearches = state.liveSearches.slice(0);
  liveSearches = liveSearches.filter(ls => ls.id !== id);
  return { ...state, liveSearches };  
};

export const deleteLiveSearchError = (state, payload) => {
  alertError(t('live_search_delete_error'));
  console.error(payload);
  
  return { ...state, liveSearches: {} };
};

export const deleteReportOptimistic = (state, id) => {
  const reports = state.reports.slice(0);
  const optimisticErrorData = reports.filter(rp => rp.id === id)[0];
  const optimisticDataIndex = reports.indexOf(optimisticErrorData);

  reports.splice(optimisticDataIndex, 1);

  return {
    ...state,
    reports,
    optimisticErrorData,
    optimisticDataIndex,
    isLoadingReports: true,
  };
};

export const deleteReportError = state => {
  const { optimisticErrorData, optimisticDataIndex } = state;
  const reports = state.reports.slice(0);

  reports.splice(optimisticDataIndex, 0, optimisticErrorData);

  alertError(t('notice_reports.index.delete_report_error'));

  return {
    ...state,
    reports,
    isLoadingReports: false,
    optimisticErrorData: {},
    optimisticDataIndex: null,
  };
};

export const createdLiveSearchReportSuccess = state => {
  alertSuccess(t('create_livesearch_report_created'));

  return {
    ...state,
    isCreatingReport: false,
  };
};

export const createdLiveSearchReportError = (state, payload) => {
  alertError(t('create_livesearch_report_error'));
  console.log(payload);
  return {
    ...state,
    isCreatingReport: false,
  };
};

export const fetchFilterProfileSellersError = (state, payload) => {
  // TO DO: ERROR MESSAGE
  alertError(t('server_error'));  
  
  console.log(payload);
  return {
    ...state,
    isLoadingFPSellers: false,
  };
};


export const changeLiveSearchKeyword = (state, payload) => {
  let liveSearches = state.liveSearches && state.liveSearches.slice(0);

  liveSearches = liveSearches && 
    liveSearches.map(liveSearch => {
      if (liveSearch.id === payload.id) {
        liveSearch.keyword = payload.keyword;
      }
      return liveSearch;
    });

  return {
    ...state,
    liveSearches,
  };
};

export const fetchFilterProfileSellersSuccess = (state, payload) => ({
  ...state,
  filterProfileSellers: {
    ...state.filterProfileSellers,
    [payload.siteId]: {
      ...payload,
      sellers: [
        ...(
          (
            state.filterProfileSellers[payload.siteId] &&
            state.filterProfileSellers[payload.siteId].sellers
          ) ||
          []
        ),
        ...payload.sellers
      ]
    }
  },
  isLoadingFPSellers: false,
});

export const searchPreviewError = (state, payload) => {
  if (payload === "User not found.") {
    alertError(t('user_not_found'));
  } else {
    alertError(t('server_error'));
  }

  console.log({ payload });
  
  return {
    ...state,
    isSearching: false,
  };
};
