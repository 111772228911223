/* eslint-disable max-len */
import { 
  FETCH_CASE_DESCRIPTION,
  FETCH_CASE_DESCRIPTION_ERROR,
  FETCH_CASE_DESCRIPTION_FULLFILLED,

  RESPOND_COUNTER_NOTICE,
  RESPOND_COUNTER_NOTICE_ERROR,
  RESPOND_COUNTER_NOTICE_SUCCESS,

  FETCH_SELLER_COUNTER_NOTICES,
  FETCH_SELLER_COUNTER_NOTICES_SUCCESS,
  FETCH_SELLER_COUNTER_NOTICES_ERROR,

  FETCH_PROFILES_OPTIONS,
  FETCH_PROFILES_OPTIONS_SUCCESS,
  FETCH_PROFILES_OPTIONS_ERROR,

  ROLLBACK_CASE,
  ROLLBACK_CASE_SUCCESS,
  ROLLBACK_CASE_ERROR,

} from "redux/actions/caseManagement";

/* Bussines logic */
import {
  respondCounterNoticeError,
  fetchCaseDescriptionError,
  respondCounterNoticeSuccess,
  fetchSellerCounterNoticesSuccess,
  fetchSellerCounterNoticesError,
  rollbackCaseSuccess,
  rollbackCaseError,
} from "redux/reducers/utils/caseManagement";

const initialState = {
  isLoading: false,
  caseDescription: {},
  
  sellerCounterNotices: [],
  sellerCounterNoticesTotal: 0,
  isLoadingCounterNotices: false,
  
  sendingResponse: false,
  respondCounterNotice: null,

  profilesOptions: null,
  isLoadingProfiles: false,

  sendingRollback: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
  case FETCH_CASE_DESCRIPTION:    
    return {
      ...state,
      isLoading: true,
      caseDescription: {},
      sellerCounterNotices: [],
      sellerCounterNoticesTotal: 0,
    };

  case FETCH_CASE_DESCRIPTION_FULLFILLED:    
    return {
      ...state,
      isLoading: false,
      caseDescription: action.payload.caseDescription,
    };

  case FETCH_CASE_DESCRIPTION_ERROR:
    return fetchCaseDescriptionError(
      state,
      action.payload
    );

  case RESPOND_COUNTER_NOTICE: 
    return {
      ...state,
      sendingResponse: true,
    };

  case RESPOND_COUNTER_NOTICE_SUCCESS:
    return respondCounterNoticeSuccess(
      state,
      action.payload
    );

  case RESPOND_COUNTER_NOTICE_ERROR:
    return respondCounterNoticeError(
      state,
      action.payload
    );

  case FETCH_SELLER_COUNTER_NOTICES:
    return {
      ...state,
      sellerCounterNoticesTotal: 0,
      isLoadingCounterNotices: true,
      sellerCounterNotices: action.payload.from > 0 ?
        state.sellerCounterNotices :
        [],
    };

  case FETCH_SELLER_COUNTER_NOTICES_SUCCESS:
    return fetchSellerCounterNoticesSuccess(
      state,
      action.payload
    );

  case FETCH_SELLER_COUNTER_NOTICES_ERROR:
    return fetchSellerCounterNoticesError(
      state,
      action.payload
    );
  
  case FETCH_PROFILES_OPTIONS:
    return {
      ...state,
      isLoadingProfiles: true,
    };

  case FETCH_PROFILES_OPTIONS_SUCCESS:
    return {
      ...state,
      profilesOptions: action.payload,
      isLoadingProfiles: false,
    };

  case FETCH_PROFILES_OPTIONS_ERROR:
    return {
      ...state,
      isLoadingProfiles: false,
    };

  case ROLLBACK_CASE: 
    return {
      ...state,
      sendingRollback: true,
    };

  case ROLLBACK_CASE_SUCCESS:
    return rollbackCaseSuccess(
      state,
      action.payload
    );

  case ROLLBACK_CASE_ERROR:
    return rollbackCaseError(
      state,
      action.payload
    );    

  default:
    return state;
  }
}; 
