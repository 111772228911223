import { articlesFieldsCatalogo } from "../fragments";


export default `
  ${articlesFieldsCatalogo}
  
  query($articleId: String!) {
    articlesCatalogo(articleId: $articleId) {
      ...articlesFieldsCatalogo
    }
  }
`;