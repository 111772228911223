import caseListFieldsV2 from "../fragments/caseListFieldsV2";


export default `
  ${caseListFieldsV2}

  query(
    $size: Int!, 
    $from: Int!,
    $sort: String,
    $order: String,
    $itemid:String,
  ) {
    pendingCounterNoticeList(
      size: $size, 
      from: $from,
      sort: $sort,
      order: $order,
      itemid: $itemid
  ) {
      cases {
        ...caseListFieldsV2
      }
      paging {
        total
        limit
        offset
      }
    }
  }
`;
