import { from } from 'rxjs';
import { ofType } from 'redux-observable';
import { mergeMap } from 'rxjs/operators';
import { gqlQuery } from "libs/awsLib";
import { queryReviews, queryReviewsTotals } from "gql/index";
import {
  FETCH_ITEM_REVIEW,
  FETCH_ITEM_REVIEW_TOTALS,
  fetchItemReviewFulfilled,
  fetchItemReviewError,
  fetchItemReviewsTotalsFulfilled,
  fetchItemReviewsTotalsError,
} from "redux/actions/reviews";


/* EPICS */
export const fetchItemReviewsEpic = action$ => action$.pipe(
  ofType(FETCH_ITEM_REVIEW),
  mergeMap(({ payload }) => 
    from(
      gqlQuery(queryReviews, payload)
        .then(fetchItemReviewFulfilled)
        .catch(fetchItemReviewError)
    )
  )
);

export const fetchItemReviewsTotalsEpic = action$ => action$.pipe(
  ofType(FETCH_ITEM_REVIEW_TOTALS),
  mergeMap(({ payload }) => 
    from(
      gqlQuery(queryReviewsTotals, {...payload, from: 0, size: 1})
        .then(fetchItemReviewsTotalsFulfilled)
        .catch(fetchItemReviewsTotalsError)
    )
  )
);