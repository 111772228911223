import React, { useEffect, useCallback } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { faCheckCircle } from "@fortawesome/free-solid-svg-icons";
import { closeModal } from "redux/actions/global";
import { resetShowImportSucces } from "redux/actions/detail";
import Icon from "components/Icon";
import { t } from "utils";


const ImportSuccess = props => {
  const {
    closeModal,
    resetShowImportSucces,
    showImportSuccessCount,
  } = props;

  useEffect(() => resetShowImportSucces, [resetShowImportSucces]);

  const handleClose = useCallback(closeModal, [closeModal]);

  return (
    <div
      tabIndex="1"
      className="modal-content import-success-modal">
   
      <Icon icon={faCheckCircle} iProps={{ className: 'check-icon' }} />

      <h1>{t('xlsx_import_success.done')}</h1>

      <div className="marked">
        {showImportSuccessCount === 1 ?
          t('xlsx_import_success.marked_listing') :
          t('xlsx_import_success.marked_listings')
            .replace('{0}', showImportSuccessCount)}
      </div>

      <button className="btn">
        <Link
          onClick={handleClose}
          to="/reported_articles"
        >
          {t('xlsx_import_success.go_to_report')}
        </Link>
      </button>
    </div>
  );
};

const mapStateToProps = state => ({
  showImportSuccessCount: state.detail.showImportSuccessCount,
});

export default connect(
  mapStateToProps,
  { closeModal, resetShowImportSucces }
)(ImportSuccess);