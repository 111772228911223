export default `
  query($articleId: String!) {
    articleDescription(articleId: $articleId) {
      id
      dateCreated
      lastUpdated
      text
      plainText
    }
  }
`;