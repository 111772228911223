import { from } from 'rxjs';
import { ofType } from 'redux-observable';
import { mergeMap } from 'rxjs/operators';
import moment from "moment";
import { gqlQuery } from "libs/awsLib";
import { 
  queryPendingCasesList,
  queryPendingCasesToExpire, 
} from "gql/index";
import {
  FETCH_PENDING_CASES_LIST,
  fetchPendingCasesListFullFilled,
  fetchPendingCasesListError,

  FETCH_PENDING_CASES_TO_EXPIRE,
  fetchPendingCasesToExpireFullFilled,
  fetchPendingCasesToExpireError,
} from "redux/actions/pendingCasesList";


/* EPICS */
export const fetchPendingCasesListEpic = action$ => action$.pipe(
  ofType(FETCH_PENDING_CASES_LIST),
  mergeMap(action =>
    from(
      gqlQuery(queryPendingCasesList, {
        ...action.payload,
        sort: 'due_date',
        order: 'asc',
      }).then(response => 
        fetchPendingCasesListFullFilled({
          pendingCasesList: response.pendingCounterNoticeList.cases,
          pendingCasesListTotal: response.pendingCounterNoticeList.paging.total,
        }))
        .catch(fetchPendingCasesListError)
    )
  )
);

export const fetchPendingCasesToExpireEpic = action$ => action$.pipe(
  ofType(
    FETCH_PENDING_CASES_LIST, 
    FETCH_PENDING_CASES_TO_EXPIRE
  ),
  mergeMap(() =>
    from(
      gqlQuery(queryPendingCasesToExpire, {
        from: 0,
        size: 1,
        dueDate: moment().format("YYYYMMDD"), //change 1 to -1
      }).then(response => {return response.pendingCounterNoticeList.paging.total;})
        .then(response => ({ pendingCasesToExpire: response }))
        .then(fetchPendingCasesToExpireFullFilled)
        .catch(fetchPendingCasesToExpireError)
    )
  )
);
