import {
  FETCH_SUGGESTED_ARTICLES,
  FETCH_SUGGESTED_ARTICLES_FULFILLED,
  FETCH_SUGGESTED_ARTICLES_ERROR,

  FETCH_SUGGESTED_ARTICLES_STATS,
  FETCH_SUGGESTED_ARTICLES_STATS_FULFILLED,
  FETCH_SUGGESTED_ARTICLES_STATS_ERROR,

  FETCH_SUGGESTED_REPORTED_COUNT,
  FETCH_SUGGESTED_REPORTED_COUNT_FULFILLED,
  FETCH_SUGGESTED_REPORTED_COUNT_ERROR,

  SET_ACTIVE_PAGE_SUGGESTED,
} from "redux/actions/suggestedArticles";
import { 
  APPROVE_ARTICLE_SUCCESS,
  REPORT_ARTICLES_SUCCESS,
  DISCARD_ARTICLES_SUCCESS 
} from "redux/actions/detail";
import { 
  SET_ARTICLES_PINNED_SUCCESS,
} from "redux/actions/global";
import {
  unsetSellerError,
  setWhiteSellerSuccess,
  setBlackSellerSuccess,
  unsetWhiteSellerSuccess,
  unsetBlackSellerSuccess,
  reportArticlesSuccessUtil,
  discardArticlesSuccessUtil,
  approveArticlesSuccessUtil,
  fetchSuggestedArticlesError,
  fetchSuggestedArticlesFulfilledUtil,
  fetchSuggestedArticlesStatsError,
  fetchSuggestedArticlesWithPinnedFulfilledUtil,
} from "redux/reducers/utils/suggestedArticles";
import {
  SET_BLACK_SELLER,  
  SET_BLACK_SELLER_SUCCESS,
  SET_BLACK_SELLER_ERROR,

  UNSET_BLACK_SELLER,
  UNSET_BLACK_SELLER_SUCCESS,
  UNSET_BLACK_SELLER_ERROR,

  SET_WHITE_SELLER,
  SET_WHITE_SELLER_SUCCESS,
  SET_WHITE_SELLER_ERROR,
  
  UNSET_WHITE_SELLER,
  UNSET_WHITE_SELLER_ERROR,
  UNSET_WHITE_SELLER_SUCCESS,
} from "redux/actions/sellers";


const initialState = {
  error: null,
  activePage: 1,
  isLoadingArticles: false,
  isLoadingStats: false,
  suggestedStats: null,
  suggestedArticles: [],
  suggestedArticlesIds: [],
  suggestedArticlesFrom: null,
  suggestedReportedCount: null,
  updatingSellerStatus: false,
  isLoadingReportedCount: false,
  suggestedReportedCountError: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
  case FETCH_SUGGESTED_ARTICLES:
    return {
      ...state,
      suggestedArticles: action.payload.from ?
        state.suggestedArticles : [],
      isLoadingArticles: true,
      error: null,
    };

  case SET_ARTICLES_PINNED_SUCCESS:
    return fetchSuggestedArticlesWithPinnedFulfilledUtil(
      state, 
      action.payload
    );  

  case FETCH_SUGGESTED_ARTICLES_FULFILLED:
    return fetchSuggestedArticlesFulfilledUtil(
      state, 
      action.payload
    );

  case FETCH_SUGGESTED_ARTICLES_ERROR:
    return fetchSuggestedArticlesError(
      state,
      action.payload
    );

  case FETCH_SUGGESTED_ARTICLES_STATS:
    return {
      ...state,
      isLoadingStats: true,
      error: null,
    };

  case FETCH_SUGGESTED_ARTICLES_STATS_FULFILLED:
    return {
      ...state,
      suggestedStats: action.payload,
      isLoadingStats: false,
    };

  case FETCH_SUGGESTED_ARTICLES_STATS_ERROR:
    return fetchSuggestedArticlesStatsError(
      state,
      action.payload
    );

  case REPORT_ARTICLES_SUCCESS:
    return reportArticlesSuccessUtil(
      state,
      action.payload
    );

  case DISCARD_ARTICLES_SUCCESS:
    return discardArticlesSuccessUtil(
      state,
      action.payload
    );  

  case APPROVE_ARTICLE_SUCCESS: 
    return approveArticlesSuccessUtil(
      state,
      action.payload
    );

  case FETCH_SUGGESTED_REPORTED_COUNT:
    return {
      ...state,
      isLoadingReportedCount: true,
      suggestedReportedCountError: null,
    };

  case FETCH_SUGGESTED_REPORTED_COUNT_FULFILLED:
    return {
      ...state,
      isLoadingReportedCount: false,
      suggestedReportedCount: action.payload,
    };

  case FETCH_SUGGESTED_REPORTED_COUNT_ERROR:
    return {
      ...state,
      isLoadingReportedCount: false,
      suggestedReportedCountError: action.payload,
    };

  case SET_ACTIVE_PAGE_SUGGESTED:
    return {
      ...state,
      activePage: action.payload
    };

  case SET_BLACK_SELLER:
  case SET_WHITE_SELLER:
    return {
      ...state, 
      updatingSellerStatus: true,
    };

  case SET_BLACK_SELLER_SUCCESS:
    return setBlackSellerSuccess(
      state,
      action.payload
    );

  case SET_WHITE_SELLER_SUCCESS:
    return setWhiteSellerSuccess(
      state,
      action.payload
    );

  case SET_BLACK_SELLER_ERROR:
  case SET_WHITE_SELLER_ERROR:
    return {
      ...state,
      updatingSellerStatus: false,
    };

  case UNSET_BLACK_SELLER:
  case UNSET_WHITE_SELLER:
    return {
      ...state,
      updatingSellerStatus: true,
    };  
  
  case UNSET_BLACK_SELLER_SUCCESS:
    return unsetBlackSellerSuccess(
      state,
      action.payload,
    );
    
  case UNSET_WHITE_SELLER_SUCCESS:
    return unsetWhiteSellerSuccess(
      state,
      action.payload,
    );

  case UNSET_BLACK_SELLER_ERROR:
  case UNSET_WHITE_SELLER_ERROR:
    return unsetSellerError(state);  

  default:
    return state;
  }
}; 