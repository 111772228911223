import { get } from "utils";
import {
  CHANGE_KEYWORD,

  CHANGE_SELLER_NICKNAME,

  SEARCH_PREVIEW,
  SEARCH_PREVIEW_FULFILLED,
  SEARCH_PREVIEW_ERROR,
  CANCEL_SEARCH_PREVIEW,
  
  CHANGE_SEARCH_RESULT,

  CHANGE_SEARCH_TYPE,
  
  FETCH_LIVE_SEARCHES,
  LIVE_SEARCHES_FULFILLED,
  FETCH_LIVE_SEARCHES_ERROR,
  
  CREATE_LIVE_SEARCH,
  CREATE_LIVE_SEARCH_SUCCESS,
  CREATE_LIVE_SEARCH_ERROR,
  
  DELETE_LIVE_SEARCH,
  DELETE_LIVE_SEARCH_ERROR,

  CREATE_LIVE_SEARCH_REPORT,
  CREATE_LIVE_SEARCH_REPORT_SUCCESS,
  CREATE_LIVE_SEARCH_REPORT_ERROR,

  FETCH_LIVE_SEARCH_REPORTS,
  LIVE_SEARCH_REPORTS_FULFILLED,
  LIVE_SEARCH_REPORTS_ERROR,

  DELETE_LIVE_SEARCH_REPORT,
  DELETE_LIVE_SEARCH_REPORT_SUCCESS,
  DELETE_LIVE_SEARCH_REPORT_ERROR,

  RESET_CREATED_LIVESEARCH,

  CREATE_SELLER_LIVE_SEARCH,
  CREATE_SELLER_LIVE_SEARCH_SUCCESS,
  CREATE_SELLER_LIVE_SEARCH_ERROR,

  FETCH_FILTER_PROFILE_SELLERS,
  FETCH_FILTER_PROFILE_SELLERS_FULFILLED,
  FETCH_FILTER_PROFILE_SELLERS_ERROR,
} from "redux/actions/liveSearches";
import { 
  LOG_OUT, 
  CHANGE_PROFILE_SUCCESS,
  FETCH_ARTICLE_DETAIL_ERROR 
} from "redux/actions/global";
import {
  SET_BLACK_SELLER_SUCCESS,
} from "redux/actions/sellers";
import { CHANGE_LIVE_SEARCH_KEYWORD } from "redux/actions/detail"; 
// Business logic
import {
  deleteReportError,
  searchPreviewError,
  createLiveSearchError,
  deleteLiveSearchError,
  fetchLiveSearchesError,
  deleteReportOptimistic,
  changeLiveSearchKeyword,
  deleteLiveSearchOptimistic,
  createdLiveSearchReportError,
  fetchFilterProfileSellersError,
  createdLiveSearchReportSuccess,
  fetchFilterProfileSellersSuccess,
} from "redux/reducers/utils/liveSearches";


const initialState = {
  search: {},
  keyword: '',
  reports: [],
  refreshing: [],
  keywordError: '',
  isLoading: false,
  isCreating: false,
  isSearching: false,
  liveSearches: null,
  sellerNickname: '',
  getLLSSError: false,
  searchType: "keyword",
  isLoadingReports: false,
  isCreatingReport: false,
  createdLivesearch: null,  
  filterProfileSellers: {},
  isLoadingFPSellers: false,
  // review 
  optimisticErrorData: {},
  optimisticDataIndex: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
  case CHANGE_KEYWORD:
  case CHANGE_SEARCH_RESULT:
  case CHANGE_SELLER_NICKNAME:
    return {
      ...state,
      ...action.payload,
      keywordError: '',
    };

  case CHANGE_SEARCH_TYPE: 
    return {
      ...state,
      keyword: '',
      keywordError: '',
      sellerNickname: '',
      searchType: action.payload,
    };

  case SEARCH_PREVIEW:
    return {
      ...state,
      search: {},
      isSearching: true,
    };

  case SEARCH_PREVIEW_FULFILLED:
    return {
      ...state,
      ...action.payload,
      isSearching: false,
    };

  case SEARCH_PREVIEW_ERROR: 
    return searchPreviewError(
      state, 
      action.payload
    );

  case CANCEL_SEARCH_PREVIEW:
    return {
      ...state,
      search: {},
      isSearching: false,
    };
  
  case FETCH_LIVE_SEARCHES:
    return {
      ...state,
      isLoading: true,
      getLLSSError: false,
    };

  case LIVE_SEARCHES_FULFILLED:
    return {
      ...state,
      ...action.payload,
      isLoading: false,
    };
  
  case FETCH_LIVE_SEARCHES_ERROR:
    return fetchLiveSearchesError(
      state,
      action.payload  
    );
  
  case CREATE_LIVE_SEARCH:
    return {
      ...state,
      search: {}, // review this 
      isCreating: true,
      createdLivesearch: null,
    };

  case CREATE_LIVE_SEARCH_SUCCESS:
    return {
      ...state,
      keyword: '',
      isCreating: false,
      createdLivesearch: action.payload,
    };
  
  case CREATE_LIVE_SEARCH_ERROR:
    return createLiveSearchError(
      state, 
      action.payload
    );

  case RESET_CREATED_LIVESEARCH: 
    return {
      ...state,
      createdLivesearch: null,
    };

  case DELETE_LIVE_SEARCH:
    return deleteLiveSearchOptimistic(
      state,
      action.payload.id,
    );
  
  case DELETE_LIVE_SEARCH_ERROR:
    return deleteLiveSearchError(
      state,
      action.payload
    );

  case CREATE_LIVE_SEARCH_REPORT:
    return {
      ...state,
      isCreatingReport: true,
    };

  case CREATE_LIVE_SEARCH_REPORT_SUCCESS:
    return createdLiveSearchReportSuccess(
      state,
      action.payload
    );

  case CREATE_LIVE_SEARCH_REPORT_ERROR:
    return createdLiveSearchReportError(
      state,
      action.payload
    );

  case FETCH_LIVE_SEARCH_REPORTS:
    return {
      ...state,
      isLoadingReports: !action.payload.silent,
    };

  case LIVE_SEARCH_REPORTS_FULFILLED:
    return {
      ...state,
      reports: action.payload,
      isLoadingReports: false,
    };

  case LIVE_SEARCH_REPORTS_ERROR:
    return {
      ...state,
      isLoadingReports: false,
    };

  case DELETE_LIVE_SEARCH_REPORT:
    return deleteReportOptimistic(
      state, 
      action.payload
    );

  case DELETE_LIVE_SEARCH_REPORT_SUCCESS:
    return {
      ...state,
      isLoadingReports: false,
    };

  case DELETE_LIVE_SEARCH_REPORT_ERROR:
    return deleteReportError(state);

  case CREATE_SELLER_LIVE_SEARCH:
    return {
      ...state, 
      isCreating: true,
      createdLivesearch: null,
    }; 

  case CREATE_SELLER_LIVE_SEARCH_SUCCESS:
    return {
      ...state,
      keyword: '',
      isCreating: false,
      sellerNickname: '',
      createdLivesearch: action.payload,
    };

  case CREATE_SELLER_LIVE_SEARCH_ERROR:
    return createLiveSearchError(
      state,
      action.payload
    );

  case FETCH_FILTER_PROFILE_SELLERS:
    return {
      ...state,
      filterProfileSellers: {
        ...state.filterProfileSellers,
        [action.payload.siteId]: action.payload.from ?
          state.filterProfileSellers[action.payload.siteId] :
          []
      },
      isLoadingFPSellers: true,
    };

  case FETCH_FILTER_PROFILE_SELLERS_FULFILLED:
    return fetchFilterProfileSellersSuccess(
      state,
      action.payload
    ); 

  case FETCH_FILTER_PROFILE_SELLERS_ERROR:
    return fetchFilterProfileSellersError(
      state,
      action.payload
    );

  case CHANGE_LIVE_SEARCH_KEYWORD:
    return changeLiveSearchKeyword(
      state,
      action.payload
    );

  case FETCH_ARTICLE_DETAIL_ERROR: 
    return {
      ...state,
      ...get(action, 'payload.payloadError', {})
    };

  case SET_BLACK_SELLER_SUCCESS: 
    return {
      ...state,
      filterProfileSellers: {
        ...state.filterProfileSellers,
        [action.payload.siteId]: {}
      }
    };

  case CHANGE_PROFILE_SUCCESS:
  case LOG_OUT:
    return initialState;

  default:
    return state;
  }
};
