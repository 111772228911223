import React, { Component } from "react";
import classnames from "classnames";
import { connect } from "react-redux";
import { Scrollbars } from "react-custom-scrollbars";
import { faCircle, faDotCircle } from "@fortawesome/free-regular-svg-icons";
import { closeModal } from "redux/actions/global";
import Icon from "components/Icon";
import { Select, Option } from "components/Select";
import { t, get } from "utils";
import SortFilterDropdown from "components/SortFilterDropdown";


class FilterOrderReportedDrawer extends Component {
  constructor(props) {
    super(props);

    const reasonCategories = {
      BRAND: [
        'PPPI1',
        'PPPI2',
        'PPPI10',
      ],
      COPYRIGHT: [
        'PPPI3',
        'PPPI5',
        'PPPI6',
        'PPPI11',
        'PPPI12',
        'PPPI13',
        'PPPI14',
        'PPPI15',
        'PPPI16',
        'PPPI17',
      ],
    };

    this.state = {
      filterRec: props.filterRec || 'rec',
      sites: props.sites,
      authorizedSiteIds: props.authorizedSiteIds,
      allowedNotices: props.allowedNotices,
      reasonsSelected: props.reasonsSelected || [],
      countriesSelected: props.countriesSelected || [],
      reasonCategories
    };
  }

  componentDidUpdate(prevProps){
    if(
      prevProps.filterRec !== this.props.filterRec ||
      prevProps.reasonsSelected !== this.props.reasonsSelected ||
      prevProps.countriesSelected !== this.props.countriesSelected
    ){
      this.setState({
        filterRec: this.props.filterRec,
        reasonsSelected: this.props.reasonsSelected,
        countriesSelected: this.props.countriesSelected,
      });
    }
  }

  toggleReasons(e) {
    const checkboxes = document.querySelectorAll('input[name="reasons"]');
    for (var i = 0; i < checkboxes.length; i++) {
      checkboxes[i].checked = e.target.checked;
    }
    this.handleChange(!e.target.checked, 'reasons');
  }

  buildReasons = () => {
    let allChecked = false;
    let noticesIds = [];
    for(let i in this.state.allowedNotices){
      for(let j in this.state.allowedNotices[i].notices){
        if(noticesIds.indexOf(this.state.allowedNotices[i].notices[j].id) === -1){
          noticesIds.push(
            this.state.allowedNotices[i].notices[j].id
          );
        }
      }
    }
    let reasonsBrand = [];
    let reasonsBrandIds = [];
    for(let h in noticesIds){
      var checked = false;
      for(let i in this.state.reasonsSelected){
        if(this.state.reasonsSelected[i].id === noticesIds[h]){
          checked = true;
          break;
        }
      }
      if(this.state.reasonCategories['BRAND'].indexOf(noticesIds[h]) > -1){
        reasonsBrandIds.push(noticesIds[h]);
        reasonsBrand.push({
          id: noticesIds[h],
          name: t(`report_v2.report_code.${noticesIds[h]}`),
          checked
        });
      }
      
    }
    let reasonsCopyright = [];
    let reasonsCopyrightSorted = [];
    let reasonsCopyrightIds = [];
    for(let h in noticesIds){
      checked = false;
      for(let i in this.state.reasonsSelected){
        if(this.state.reasonsSelected[i].id === noticesIds[h]){
          checked = true;
          break;
        }
      }
      if(this.state.reasonCategories['COPYRIGHT'].indexOf(noticesIds[h]) > -1){
        reasonsCopyrightIds.push(noticesIds[h]);
        const name = noticesIds[h] === 'PPPI6' ? 
          t(`report_v2.report_code.${noticesIds[h]}`).split('/')[0] : 
          t(`report_v2.report_code.${noticesIds[h]}`);
        reasonsCopyright.push({
          id: noticesIds[h],
          name,
          checked
        });
      }
    }
    if(reasonsCopyright.length > 0){
      reasonsCopyright.sort(function(a,b) {
        if(
          a.id === 'PPPI17' ||
          b.id === 'PPPI17'
        ) return;
        var x = a.name.toLowerCase();
        var y = b.name.toLowerCase();
        return x < y ? -1 : x > y ? 0 : 1;
      });
      // Bug others in some cases
      let others = {};
      for(let i in reasonsCopyright){
        if(reasonsCopyright[i].id != 'PPPI17'){
          reasonsCopyrightSorted.push(reasonsCopyright[i]);
        } else {
          others = reasonsCopyright[i];
        }
      }
      reasonsCopyrightSorted.push(others);
    }
    let reasons = [];
    for(let h in noticesIds){
      checked = false;
      for(let i in this.state.reasonsSelected){
        if(this.state.reasonsSelected[i].id === noticesIds[h]){
          checked = true;
          break;
        }
      }
      if(
        reasonsBrandIds.indexOf(noticesIds[h]) === -1 &&
        reasonsCopyrightIds.indexOf(noticesIds[h]) === -1
      ){
        reasons.push({
          id: noticesIds[h],
          name: t(`report_v2.report_code.${noticesIds[h]}`),
          checked
        });
      }
    }
    if(
      (reasons.length + reasonsBrand.length + reasonsCopyright.length) === 
      this.state.reasonsSelected.length
    ){
      allChecked = true;
    }
    return(
      <>
        <label className="allCtn">
          <input
            type="checkbox"
            name="reasons-all"
            value="all"
            checked={allChecked}
            onChange={ e => this.toggleReasons(e) }
          />
          {t('filter_modal.all_reasons')}
        </label>
        <div className="reasonsCtn">
          {
            reasonsBrandIds &&
            reasonsBrandIds.length > 0 &&
            reasonsBrand.map((reason, key) => 
              <>
                {key === 0 ? <div className="reason-category-title">{
                  t(`report_v2.step_1.brand`)
                }</div> : ""}
                <label key={reason.id} className="reason-item">
                  <input
                    type="checkbox"
                    name="reasons"
                    value={reason.id}
                    checked={reason.checked}
                    onChange={this.handleChange}
                  />
                  {reason.name}
                </label>
                {key === (reasonsBrand.length - 1) ? <hr /> : ""}
              </>
            )
          }
          {
            reasonsCopyrightIds &&
            reasonsCopyrightIds.length > 0 &&
            reasonsCopyrightSorted.map((reason, key) => 
              <>
                {key === 0 ? <div className="reason-category-title">{
                  t(`report_v2.step_1.copyright`)
                }</div> : ""}
                <label key={reason.id} className="reason-item">
                  <input
                    type="checkbox"
                    name="reasons"
                    value={reason.id}
                    checked={reason.checked}
                    onChange={this.handleChange}
                  />
                  {reason.name}
                </label>
                {key === (reasonsCopyright.length - 1) ? <hr /> : ""}
              </>
            )
          }  
          {
            reasons.map(reason => 
              <label key={reason.id} className="reason-item">
                <input
                  type="checkbox"
                  name="reasons"
                  value={reason.id}
                  checked={reason.checked}
                  onChange={this.handleChange}
                />
                {reason.name}
              </label>
            )
          }
        </div>
      </>  
    );
  }

  toggleCountries(e) {
    const checkboxes = document.querySelectorAll('input[name="countries"]');
    for (var i = 0; i < checkboxes.length; i++) {
      checkboxes[i].checked = e.target.checked;
    }
    this.handleChange(!e.target.checked, 'countries');
  }

  buildCountries = () => {
    let allChecked = false;
 
    const countrySites = get(this.state.sites[0], 'countrySites', []);
    let sites = [];
    for(let i in this.state.authorizedSiteIds){
      for(let j in countrySites){
        if(this.state.authorizedSiteIds[i] === countrySites[j].id){
          var checked = false;
          for(let i in this.state.countriesSelected){
            if(this.state.countriesSelected[i].id === countrySites[j].id){
              checked = true;
              break;
            }
          }
          sites.push({
            id: countrySites[j].id,
            name: countrySites[j].country.name,
            checked
          });
        }
      }
    }
    if(sites.length > 0){
      sites.sort(function(a,b) {
        var x = a.name.toLowerCase();
        var y = b.name.toLowerCase();
        return x < y ? -1 : x > y ? 0 : 1;
      });
    }
    if(sites.length === this.state.countriesSelected.length){
      allChecked = true;
    }
    return(
      <>
        <label className="allCtn">
          <input
            type="checkbox"
            name="countries-all"
            value="all"
            checked={allChecked}
            onChange={ e => this.toggleCountries(e) }
          />
          {t('all_the_countries')}
        </label>
        <div className="countriesCtn">
          {
            sites.map(site => 
              <label key={site.id} className="country-item">
                <input
                  type="checkbox"
                  name="countries"
                  value={site.id}
                  checked={site.checked}
                  onChange={this.handleChange}
                />
                {site.name}
              </label>
            )
          }
        </div>
      </>  
    );
  }

  handleClear = e => {

    this.props.onClear &&
    this.props.onClear(); 

    this.props.closeModal();
  }

  handleChange = (get_checked = true, context) => {
    let reasons = [];
    let checkboxesReasons = document.querySelectorAll('input[name="reasons"]:checked');
    if(context === 'reasons'){
      if(!get_checked){
        checkboxesReasons = document.querySelectorAll('input[name="reasons"]');
      }
    }
    checkboxesReasons.forEach((checkbox) => {
      reasons.push({
        id: checkbox.value,
        name: checkbox.name,
        type: 'reasons'
      });
    });

    let countries = [];
    let checkboxesCountries = document.querySelectorAll('input[name="countries"]:checked');
    if(context === 'countries'){
      if(!get_checked){
        checkboxesCountries = document.querySelectorAll('input[name="countries"]'); 
      }
    }
    checkboxesCountries.forEach((checkbox) => {
      countries.push({
        id: checkbox.value,
        name: checkbox.name,
        type: 'countries'
      });
    });
  
    this.setState({ 
      reasonsSelected: reasons,
      countriesSelected: countries
    });
  }

  handleApply = () => {
    
    let filters = this.state.reasonsSelected.concat(
      this.state.countriesSelected
    ).concat(
      [{ id: this.state.filterRec, name: "order" }]
    );
 
    this.props.onApply &&
    this.props.onApply(filters);    
  
    this.props.closeModal();
  }

  handleChangeRecentsSelect = value => {

    this.setState({ filterRec: value });
  }

  render() {
 
    return (
      <div
        tabIndex="1"
        className="modal-content filter-selector">
        <div className="modal-filter-selector_container">
          <header>
            <h1>
              <span>{t('filter_text')}</span>
              <i className="modal-close" onClick={this.props.closeModal} />
            </h1>
          </header>
          <section>
            <form onSubmit={e => e.preventDefault()}>
              <div className="form-group options-filters">
                <div className="filter1">
                  <span className="title">
                    {t('filter_modal.filter_1')}
                  </span>
                  <div className="recentsFilterCtn">
                    <SortFilterDropdown 
                      value={this.state.filterRec}
                      onChange={this.handleChangeRecentsSelect}
                    />
                  </div>
                  <div className="clearboth"></div>
                </div>
                <div className="filter2">
                  <span className="title">
                    {t('filter_modal.filter_2')}
                  </span>
                  <div className="reasonsFilterCtn">
                    {this.buildReasons()}
                  </div>
                </div>
                <div className="filter3">
                  <span className="title">
                    {t('filter_modal.filter_3')}
                  </span>
                  <div className="countriesFilterCtn">
                    {this.buildCountries()}
                  </div>
                </div>
              </div>
            </form>
          </section>
          <div className="actions">
            <button 
              onClick={this.handleClear}
              className="btn transparent">
              {t('filter_modal.clean')}
            </button>
            <button 
              className="btn"
              onClick={this.handleApply}>
              {t('filter_modal.apply')}
            </button>
          </div>
        </div>
      </div>
    );
  }
}

export default connect(null, { closeModal })(FilterOrderReportedDrawer);