export default `
  fragment sellerFields on SellerType {
    id
    sold
    amount
    siteId
    nickname
    searched
    pulpouScore
    reputationPowerSellerStatus
    blackSeller
    whiteSeller
    countryName
    pulpouLastUpdateAt
    address {
      city
      state
      zipCode
      neighborhood
    }
  }
`;