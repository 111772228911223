export default `
  fragment ratesFields on RatesType {
    USD
    ARS
    BRL
    CLP
    VES
    MXN
    COP
    PEN
    BOB
    CRC
    DOP
    GTQ
    HNL
    NIO
    PAB
    UYU
    EUR
    PYG
  }
`;