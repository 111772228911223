import React, { useCallback } from "react";
import classnames from "classnames";
import { useDropzone } from "react-dropzone";
import { faFileUpload } from "@fortawesome/free-solid-svg-icons";
import Icon from "components/Icon";
import { t } from "utils";

function FileUploader(props) {
  const {
    emptyText,
    emptyDragText,
    onDrop: propsOnDrop,
    ...dropzoneProps
  } = props;

  const onDrop = useCallback((acceptedFiles, rejectedFiles, event) => {
    event.persist();

    // eslint-disable-next-line
    const validFormat = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";

    const exceedsMaxSize = Boolean(
      rejectedFiles.length > 0 &&
      rejectedFiles[0].type === validFormat &&
      rejectedFiles[0].size > dropzoneProps.maxSize
    );

    propsOnDrop && propsOnDrop(acceptedFiles, exceedsMaxSize, event);
  }, [propsOnDrop, dropzoneProps.maxSize]);

  const {
    isDragActive,
    getRootProps,
    getInputProps,
  } = useDropzone({ ...dropzoneProps, onDrop });

  return (
    <div className={classnames(
      "dropzone text-center mt-5",
      { "drag-active": isDragActive }
    )}>
      <div {...getRootProps()}>
        <input {...getInputProps()} multiple={false} />
        <p className="icon-container">
          <Icon icon={faFileUpload} />
        </p>
        <div className="empty">
          <strong>{emptyText}</strong>
          <br />
          <h4>{emptyDragText}</h4>
          <br />
          <span>
            {t('file_uploader.formats')}: XLSX 
            -&nbsp; 
            {t('file_uploader.maximum_size')}: 10 MB
          </span>
        </div>
      </div>
    </div>
  );
}

FileUploader.defaultProps = {
  maxSize: 10485760, // 10 MB
  accept: ".xlsx, .xls",
  onDragText: "Drop it like it's hot!",
  fileTooLargeText: "File is too large.",
  emptyText: "Click here or drop a file to upload!",
  emptyDragText: "",
};

export default FileUploader;