/* eslint-disable max-len */
/**
 * CONSTANTS
 */
export const FETCH_SUGGESTED_ARTICLES = "FETCH_SUGGESTED_ARTICLES";
export const FETCH_SUGGESTED_ARTICLES_FULFILLED = "FETCH_SUGGESTED_ARTICLES_FULFILLED";
export const FETCH_SUGGESTED_ARTICLES_ERROR = "FETCH_SUGGESTED_ARTICLES_ERROR";

export const FETCH_SUGGESTED_ARTICLES_STATS = "FETCH_SUGGESTED_ARTICLES_STATS";
export const FETCH_SUGGESTED_ARTICLES_STATS_FULFILLED = "FETCH_SUGGESTED_ARTICLES_STATS_FULFILLED";
export const FETCH_SUGGESTED_ARTICLES_STATS_ERROR = "FETCH_SUGGESTED_ARTICLES_STATS_ERROR";

export const FETCH_SUGGESTED_REPORTED_COUNT = "FETCH_SUGGESTED_REPORTED_COUNT";
export const FETCH_SUGGESTED_REPORTED_COUNT_FULFILLED = "FETCH_SUGGESTED_REPORTED_COUNT_FULFILLED";
export const FETCH_SUGGESTED_REPORTED_COUNT_ERROR = "FETCH_SUGGESTED_REPORTED_COUNT_ERROR"; 

export const SET_ACTIVE_PAGE_SUGGESTED = "SET_ACTIVE_PAGE_SUGGESTED";

/**
 * DISPATCHERS
 */
export const fetchSuggestedArticles = payload => ({ type: FETCH_SUGGESTED_ARTICLES, payload });
export const fetchSuggestedArticlesFulfilled = payload => ({ type: FETCH_SUGGESTED_ARTICLES_FULFILLED, payload });
export const fetchSuggestedArticlesError = payload => ({ type: FETCH_SUGGESTED_ARTICLES_ERROR, payload });

export const fetchSuggestedArticlesStats = payload => ({ type: FETCH_SUGGESTED_ARTICLES_STATS, payload });
export const fetchsuggestedArticlesStatsFulfilled = payload => ({ type: FETCH_SUGGESTED_ARTICLES_STATS_FULFILLED, payload });
export const fetchSuggestedArticlesStatsError = payload => ({ type: FETCH_SUGGESTED_ARTICLES_STATS_ERROR, payload });

export const fetchSuggestedReportedCount = () => ({ type: FETCH_SUGGESTED_REPORTED_COUNT });
export const fetchSuggestedReportedCountFulfilled = payload => ({ type: FETCH_SUGGESTED_REPORTED_COUNT_FULFILLED, payload });
export const fetchSuggestedReportedCountError = payload => ({ type: FETCH_SUGGESTED_REPORTED_COUNT_ERROR, payload });

export const setActivePageSuggested = payload => ({ type: SET_ACTIVE_PAGE_SUGGESTED, payload });
