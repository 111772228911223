import { articleDataTableFields } from "../fragments";

export default `
  ${articleDataTableFields}
  
  query(
    $size: Int!, 
    $from: Int!, 
    $status: String, 
    $sellerId: String, 
    $itemId: String
  ) {
    noticedArticles(
      size: $size, 
      from: $from,
      status: $status,
      sellerId: $sellerId,
      itemId: $itemId
    ) {
      paging {
        limit 
        offset
        total
      }
      noticedArticles {
        ...articleDataTableFields
      }
    }
  }
`;