import React from 'react'
import './newPill.scss'
import { t } from "utils";


export const NewPill = () => {
    return (
        <div className="pill">
            <div className="badge">
                <p className="badge-label"> {t('shared.side_navigation.new_pill')}</p>
            </div>
        </div>
    )
}
