/* eslint-disable max-len */
/**
 * CONSTANTS
 */
export const HANDLE_CHANGE_USERS = "HANDLE_CHANGE_USERS";

export const CREATE_ACCOUNT = "CREATE_ACCOUNT";
export const CREATE_ACCOUNT_SUCCESS = "CREATE_ACCOUNT_SUCCESS";
export const CREATE_ACCOUNT_ERROR = "CREATE_ACCOUNT_ERROR";


/**
 * DISPATCHERS
 */
export const handleChangeUsers = payload => ({ type: HANDLE_CHANGE_USERS, payload });

export const createAccount = () => ({ type: CREATE_ACCOUNT });
export const createAccountSuccess = () => ({ type: CREATE_ACCOUNT_SUCCESS });
export const createAccountError = payload => ({ type: CREATE_ACCOUNT_ERROR, payload });
