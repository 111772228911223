/* eslint-disable max-len */
/**
 * CONSTANTS
 */
export const FETCH_ITEM_REVIEW = "FETCH_ITEM_REVIEW";
export const FETCH_ITEM_REVIEW_FULFILLED = "FETCH_ITEM_REVIEW_FULFILLED";
export const FETCH_ITEM_REVIEW_ERROR = "FETCH_ITEM_REVIEW_ERROR";
export const FETCH_ITEM_REVIEW_TOTALS = "FETCH_ITEM_REVIEW_TOTALS";
export const FETCH_ITEM_REVIEW_TOTALS_FULFILLED = "FETCH_ITEM_REVIEW_TOTALS_FULFILLED";
export const FETCH_ITEM_REVIEW_TOTALS_ERROR = "FETCH_ITEM_REVIEW_TOTALS_ERROR";

/**
 * DISPATCHERS
 */
export const fetchItemReviews = payload => ({ type: FETCH_ITEM_REVIEW, payload });
export const fetchItemReviewFulfilled = payload => ({ type: FETCH_ITEM_REVIEW_FULFILLED, payload });
export const fetchItemReviewError = payload => ({ type: FETCH_ITEM_REVIEW_ERROR, payload });

export const fetchItemReviewsTotals = payload => ({ type: FETCH_ITEM_REVIEW_TOTALS, payload });
export const fetchItemReviewsTotalsFulfilled = payload => ({ type: FETCH_ITEM_REVIEW_TOTALS_FULFILLED, payload });
export const fetchItemReviewsTotalsError = payload => ({ type: FETCH_ITEM_REVIEW_TOTALS_ERROR, payload });