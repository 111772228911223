import { 
  siteFields,
  articleCardFields,
  filterProfileFields,
} from "../fragments";


export default `

  ${siteFields}
  ${articleCardFields}
  ${filterProfileFields}
  
  query(
    $id: String!, 
    $size: Int, 
    $from: Int
    $sortBy: String,
    $sortOrder: String
  ) {
    liveSearch(
      id: $id, 
      articles_size: $size, 
      articles_from: $from,
      articles_sort_by: $sortBy,
      articles_sort_order: $sortOrder
    ) {
      id 
      keyword
      sellers {
        id
        siteId
        nickname
      }
      failed
      filtered
      finished
      articles_from 
      articles_size
      articles_sort_by
      articles_sort_order
      priceRangeMin
      priceRangeMax
      totalQuantity
      articles {
        ...articleCardFields
      } 
      sites {
        ...siteFields
      }
      filterProfileId
      filterProfiles {
        ...filterProfileFields
      }
      availableFilters {
        id
        name
        type
        values {
          id 
          name
          results
        }
      }
      meliFilters {
        id
        name
        type
        values {
          id 
          name
          path_from_root {
            id
            name
          }
        }
      }
    } 
  }
`;