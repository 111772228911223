
/* eslint-disable max-len */
/**
 * CONSTANTS
 */
export const FETCH_COUNTER_NOTICES_V3 = "FETCH_COUNTER_NOTICES_V3";
export const FETCH_COUNTER_NOTICES_V3_ERROR = "FETCH_COUNTER_NOTICES_V3_ERROR";
export const COUNTER_NOTICES_V3_FULFILLED = "COUNTER_NOTICES_V3_FULFILLED";

export const FETCH_PENDING_DENOUNCES = "FETCH_PENDING_DENOUNCES ";
export const FETCH_PENDING_DENOUNCES_ERROR = "FETCH_PENDING_DENOUNCES _ERROR";
export const PENDING_DENOUNCES_FULFILLED = "PENDING_DENOUNCES _FULFILLED";


/**
 * DISPATCHERS
 */
export const fetchCounterNoticesV3 = payload => ({ type: FETCH_COUNTER_NOTICES_V3, payload });
export const fetchCounterNoticesErrorV3 = payload => ({ type: FETCH_COUNTER_NOTICES_V3_ERROR, payload });
export const counterNoticesFulfilledV3 = payload => ({ type: COUNTER_NOTICES_V3_FULFILLED, payload });

export const fetchPendingDenounces = payload => ({ type: FETCH_PENDING_DENOUNCES, payload });
export const fetchPendingDenouncesError = payload => ({ type: FETCH_PENDING_DENOUNCES_ERROR, payload });
export const pendingDenouncesFullfilled = payload => ({ type: PENDING_DENOUNCES_FULFILLED, payload });

