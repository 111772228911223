

import { applyMiddleware, createStore } from "redux";
import { createEpicMiddleware } from "redux-observable";
import { composeWithDevTools } from "redux-devtools-extension";
import monitorReducersEnhancer from "redux/enhancers/monitorReducers";
import loggerMiddleware from "redux/middleware/logger";
import rootEpic from "redux/middleware/epics";
import rootReducer from "redux/reducers";

const epicMiddleware = createEpicMiddleware();

export default function configureAppStore(preloadedState) {
  let middlewares = [epicMiddleware];
  /* eslint-disable no-undef */
  if (process.env.NODE_ENV !== 'production') {
    middlewares.push(loggerMiddleware);
  }   
  /* eslint-enable no-undef */
  const middlewareEnhancer = applyMiddleware(...middlewares);
  const enhancers = [middlewareEnhancer, monitorReducersEnhancer];
  const composedEnhancers = composeWithDevTools(...enhancers);

  const store = createStore(rootReducer, preloadedState, composedEnhancers);

  epicMiddleware.run(rootEpic);
  
  /* eslint-disable no-undef */
  if (process.env.NODE_ENV !== 'production' && module.hot) {
    module.hot.accept('./reducers', () => store.replaceReducer(rootReducer));
  }
  /* eslint-enable no-undef */

  return store;
}
