import {
  FETCH_REPORTED_ARTICLES,
  REPORTED_ARTICLES_FULFILLED,
  FETCH_REPORTED_ARTICLES_ERROR,
  REPORTED_ARTICLES_COUNT_FULFILLED,
  FETCH_REPORTED_ARTICLES_COUNT_ERROR,
  SEND_NOTICE,
  SEND_NOTICE_SUCCESS,
  SEND_NOTICE_ERROR,
  REMOVE_ARTICLE,
  REMOVE_ARTICLE_SUCCESS,
  REMOVE_ARTICLE_ERROR,
  DELETE_ARTICLES,
  DELETE_ARTICLES_SUCCESS,
  DELETE_ARTICLES_ERROR,
  FETCH_SENDING_NOTICE_STATUS_SUCCESS,
  FETCH_SHOW_TOOLTIP_STATUS_FULFILLED,
  FETCH_SHOW_TOOLTIP_STATUS_ERROR,
  FETCH_SHOW_TOOLTIP_IMPORT_STATUS_FULFILLED,
  FETCH_SHOW_TOOLTIP_IMPORT_STATUS_ERROR,
  SET_SHOW_TOOLTIP_IMPORT_UNTIL,
  SET_SHOW_TOOLTIP_IMPORT_UNTIL_ERROR,
  SET_NOT_SHOW_MORE,
  SET_NOT_SHOW_MORE_ERROR,
  SEND_BRAND_INFRINGEMENT,
  SEND_BRAND_INFRINGEMENT_SUCCESS,
  SEND_BRAND_INFRINGEMENT_ERROR,
} from "redux/actions/reportedArticles";
import {
  REPORT_ARTICLES,
  REPORT_ARTICLES_SUCCESS,
  REPORT_ARTICLES_ERROR,
  REPORT_ARTICLE_UNDO,
  EDIT_REPORTED_ARTICLES,
  EDIT_REPORTED_ARTICLES_SUCCESS,
  EDIT_REPORTED_ARTICLES_ERROR,
} from "redux/actions/detail";
// Business logic
import {
  sendNotice,
  sendNoticeError,
  sendNoticeSuccess,
  removeArticleOptimistic,
  removeArticleSuccess,
  removeArticleError,
  fetchReportedArticlesError,
  reportedArticlesOptimistic,
  reportedArticlesError,
  reportedArticlesUndoOptimistic,
  fetchSendingNoticeStatusSuccess,
  editReportedArticlesOptimistic,
  editReportedArticlesSuccess,
  editReportedArticlesError,
  deleteArticlesSuccess,
  deleteArticlesError,
  setNotShowMoreError,
  errorSendingBrandInfringement,
} from "redux/reducers/utils/reportedArticles";

const initialState = {
  count: 0,
  isLoading: false,
  sendingNotice: [],
  sentNotices: false,
  noticeInProgress: [],
  reportedArticles: [],
  isAddingArticles: false,
  showTooltipStatus: false,
  showTooltipImportStatus: false,
  reportedArticlesTotal: 0,
  reportedArticlesFrom: null,
  isDeletingArticles: false,
  sentBrandInfringement: null,
  sendingBrandInfringement: false,
  errorSendingBrandInfringement: false,
  caseId: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case FETCH_REPORTED_ARTICLES:
      return {
        ...state,
        reportedArticles: action.payload.from ? state.reportedArticles : [],
        isLoading: !action.payload.silent,
      };

    case REPORTED_ARTICLES_FULFILLED:
      return {
        ...state,
        ...action.payload,
        reportedArticles: [
          //...state.reportedArticles,
          ...action.payload.reportedArticles,
        ],
        isLoading: false,
      };

    case FETCH_REPORTED_ARTICLES_ERROR:
      return fetchReportedArticlesError(state, action.payload);

    case REPORTED_ARTICLES_COUNT_FULFILLED:
      return {
        ...state,
        count: action.payload,
      };

    case FETCH_REPORTED_ARTICLES_COUNT_ERROR:
      return {
        ...state,
        count: initialState.count,
      };

    case SEND_NOTICE:
      return sendNotice(state, action.payload);

    case SEND_NOTICE_SUCCESS:
      return sendNoticeSuccess(state, action.payload);

    case SEND_NOTICE_ERROR:
      return sendNoticeError(state, action.payload);

    case REMOVE_ARTICLE:
      return removeArticleOptimistic(state, action.payload);

    case REMOVE_ARTICLE_SUCCESS:
      return removeArticleSuccess(state, action.payload);

    case REMOVE_ARTICLE_ERROR:
      return removeArticleError(state, action.payload);

    case DELETE_ARTICLES:
      return {
        ...state,
        isDeletingArticles: true,
      };

    case DELETE_ARTICLES_SUCCESS:
      return deleteArticlesSuccess(state, action.payload);

    case DELETE_ARTICLES_ERROR:
      return deleteArticlesError(state);

    case REPORT_ARTICLES:
      return reportedArticlesOptimistic(state, action.payload);

    case REPORT_ARTICLES_SUCCESS:
      return {
        ...state,
        isAddingArticles: false,
      };

    case REPORT_ARTICLES_ERROR:
      return reportedArticlesError(state, action.payload);

    case REPORT_ARTICLE_UNDO:
      return reportedArticlesUndoOptimistic(state, action.payload);

    case FETCH_SENDING_NOTICE_STATUS_SUCCESS:
      console.log("GGGG", state, action.payload);
      return fetchSendingNoticeStatusSuccess(state, action.payload);

    case EDIT_REPORTED_ARTICLES:
      return editReportedArticlesOptimistic(state, action.payload);

    case EDIT_REPORTED_ARTICLES_SUCCESS:
      return editReportedArticlesSuccess(state, action.payload);

    case EDIT_REPORTED_ARTICLES_ERROR:
      return editReportedArticlesError(state, action.payload);

    case FETCH_SHOW_TOOLTIP_STATUS_FULFILLED:
      return {
        ...state,
        showTooltipStatus: action.payload,
      };

    case FETCH_SHOW_TOOLTIP_STATUS_ERROR:
      return fetchReportedArticlesError(state, action.payload);

    case FETCH_SHOW_TOOLTIP_IMPORT_STATUS_FULFILLED:
      return {
        ...state,
        showTooltipImportStatus: action.payload,
      };

    case FETCH_SHOW_TOOLTIP_IMPORT_STATUS_ERROR:
      return fetchReportedArticlesError(state, action.payload);

    case SET_SHOW_TOOLTIP_IMPORT_UNTIL:
      return {
        ...state,
        showTooltipImportStatus: action.payload,
      };

    case SET_SHOW_TOOLTIP_IMPORT_UNTIL_ERROR:
      return fetchReportedArticlesError(state, action.payload);

    case SET_NOT_SHOW_MORE:
      return {
        ...state,
        showTooltipStatus: action.payload,
      };

    case SET_NOT_SHOW_MORE_ERROR:
      return setNotShowMoreError(state, action.payload);

    case SEND_BRAND_INFRINGEMENT:
      return {
        ...state,
        sendingBrandInfringement: true,
        sentBrandInfringement: action.payload,
        errorSendingBrandInfringement: false,
        caseId: null,
      };

    case SEND_BRAND_INFRINGEMENT_SUCCESS:
      return {
        ...state,
        caseId: action.payload.sendBrandInfringement.caseId,
        sendingBrandInfringement: false,
        errorSendingBrandInfringement: false,
      };

    case SEND_BRAND_INFRINGEMENT_ERROR:
      return {
        ...state,
        sendingBrandInfringement: false,
        errorSendingBrandInfringement: true,
      };

    default:
      return state;
  }
};
