import { from } from 'rxjs';
import { ofType } from 'redux-observable';
import { mergeMap } from 'rxjs/operators';
import { gqlQuery } from "libs/awsLib";
import { 
  queryWhiteListSellers,
  mutationDeleteSellerFromDB,
} from "gql/index";
import {
  FETCH_WHITE_LIST_SELLERS,
  fetchWhiteListSellersFullFilled,
  fetchWhiteListSellersError,
  
  DELETE_WHITE_LIST_SELLERS,
  deleteWhiteListSellersError,
  deleteWhiteListSellersSuccess,
} from "redux/actions/whiteListSellers";


/* EPICS */
export const fetchWhiteListSellersEpic = action$ => action$.pipe(
  ofType(FETCH_WHITE_LIST_SELLERS),
  mergeMap(({ payload }) => {
    const variables = {
      ...payload,
      filters: payload.filters ?
        JSON.stringify(payload.filters) :
        ""
    };
  
    return from(
      gqlQuery(queryWhiteListSellers, variables)
        .then(response => 
          fetchWhiteListSellersFullFilled({
            whiteListSellers: response.whiteListSellers.sellers,
            whiteListSellersFrom: response.whiteListSellers.from,
            whiteListSellersTotal: response.whiteListSellers.total,
          })
        )
        .catch(fetchWhiteListSellersError)
    );
  })
);

export const deleteWhiteListSellersEpic = (action$, store) => action$.pipe(
  ofType(DELETE_WHITE_LIST_SELLERS),
  mergeMap(({ payload }) => {
    const state = store.value.whiteListSellers;
    return from(
      gqlQuery(
        mutationDeleteSellerFromDB, { 
          ...payload,
          from: state.whiteListSellersFrom,
        })
        .then(response => deleteWhiteListSellersSuccess({
          id: response.deleteSellerFromDB.deletedId,
          whiteListSellersFrom: response.deleteSellerFromDB.from,
          whiteListSellers: response.deleteSellerFromDB.seller ? 
            [response.deleteSellerFromDB.seller] : [],
        }))
        .catch(error => deleteWhiteListSellersError({
          error,
          id: payload.sellerId
        }))
    );
  })
);
