import React, { Component } from "react";
import classnames from "classnames";
import { connect } from "react-redux";
import { Carousel } from "react-bootstrap";
import {
  openModal,
  closeHelp,
  closeModal,
  updateModal,
} from "redux/actions/global";
import { t } from "utils";


class OnBoardingSlide extends Component {
  constructor(props) {
    super(props);

    this.state = { isLastSlide: false };
  }

  getImagePath(subPath) {
    return `/images/onboarding-slide/${subPath}`;
  }
  
  handleClick = () => {
    this.props.closeHelp('onBoarding');
    this.props.closeModal();
    if (typeof this.props.onClose === "function") {
      this.props.onClose();
    }
  }

  handleSelect = slide => {
    this.setState({ isLastSlide: slide === 5 });
  }

  render() {
    return (
      <div
        tabIndex="1"
        className={classnames(
          "modal-content",
          "onboarding-slide-wrapper",
        )}>
        <div className="onboarding-slide">
          <Carousel
            wrap={false}
            interval={null}
            onSelect={this.handleSelect}
            nextIcon={
              <div
                aria-hidden="true"
                className="onboarding-arrow" />
            }
            prevIcon={
              <div
                aria-hidden="true"
                className="onboarding-arrow" />
            }>
            <Carousel.Item>
              <div className="imgWrapper">
                <img
                  alt="First slide"
                  className="d-block w-100 firstSlide"
                  src={this.getImagePath("icon-before-start.png")} />
              </div>
              <Carousel.Caption>
                <h3>{t('onboarding_slide.slide1.title')}</h3>
                <p>{t('onboarding_slide.slide1.text')}</p>
              </Carousel.Caption>
            </Carousel.Item>

            <Carousel.Item>
              <div className="imgWrapper">
                <img
                  alt="Second slide"
                  className="d-block w-100"
                  src={this.getImagePath(t('onboarding_slide.slide2.image'))} />
              </div>
              <Carousel.Caption>
                <h3>{t('onboarding_slide.slide2.title')}</h3>
                <p>{t('onboarding_slide.slide2.text')} </p>
              </Carousel.Caption>
            </Carousel.Item>

            <Carousel.Item>
              <div className="imgWrapper">
                <img
                  alt="Third slide"
                  className="d-block w-100"
                  src={this.getImagePath(t('onboarding_slide.slide3.image'))} />
              </div>
              <Carousel.Caption>
                <h3>{t('onboarding_slide.slide3.title')}</h3>
                <p>{t('onboarding_slide.slide3.text')} </p>
              </Carousel.Caption>
            </Carousel.Item>

            <Carousel.Item>
              <div className="imgWrapper">
                <img
                  alt="Fourth slide"
                  className="d-block w-100"
                  src={this.getImagePath(t('onboarding_slide.slide4.image'))} />
              </div>
              <Carousel.Caption>
                <h3>{t('onboarding_slide.slide4.title')}</h3>
                <p>{t('onboarding_slide.slide4.text')} </p>
              </Carousel.Caption>
            </Carousel.Item>

            <Carousel.Item>
              <div className="imgWrapper">
                <img
                  alt="Fifth slide"
                  className="d-block w-100"
                  src={this.getImagePath(t('onboarding_slide.slide5.image'))} />
              </div>
              <Carousel.Caption>
                <h3>{t('onboarding_slide.slide5.title')}</h3>
                <p>{t('onboarding_slide.slide5.text')} </p>
              </Carousel.Caption>
            </Carousel.Item>

            <Carousel.Item>
              <div className="imgWrapper">
                <img
                  alt="Sixth slide"
                  className="d-block w-100"
                  style={{ 'marginTop': '5%' }}
                  src={this.getImagePath('icon-welcome.png')} />
              </div>
              <Carousel.Caption>
                <h3>{t('onboarding_slide.slide6.title')}</h3>
                <p>{t('onboarding_slide.slide6.text')} </p>
              </Carousel.Caption>
            </Carousel.Item>
          </Carousel>
          {this.state.isLastSlide ?
            <button
              className="btn"
              onClick={this.handleClick}>
              {t('onboarding_slide.start')}
            </button> :
            <button
              className="btn skip"
              onClick={this.handleClick}>
              {t('onboarding_slide.skip')}
            </button>}
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({ profile: state.global.profile });

const actions = {
  openModal,
  closeHelp,
  closeModal,
  updateModal,
};

export default connect(mapStateToProps, actions)(OnBoardingSlide);
