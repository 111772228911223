export default `
  query($articlesIds: [String]!) {
    articlesByIds(articlesIds: $articlesIds) { 
      articles {
        id
        noticed {
          sent
          failed
          sending
        } 
      }
    }
  }
`;