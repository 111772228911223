

export default `


  fragment articlesFieldsCatalogo on ArticlesTypeCatalogo {
    id
    results {
      item_id
      category_id
      seller_id
      price
      currency_id
      sold_quantity
      available_quantity
      official_store_id
      attributes{
        valueName
        name
        id
        
      }
      sellerData{
        nickname
        logos
        logo
      }


    } 


    
  }
`;