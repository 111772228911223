import React, { Component } from "react";
import classnames from "classnames";
import { t, get } from "utils";

export class Select extends Component {
  constructor(props) {
    super(props);

    const value = props.defaultValue || props.value || t('no_data');

    this.state = {
      value,
      open: false,
    };
  }

  toggleDropdown = () => this.setState({ open: !this.state.open })

  getValue() {
    return this.state.value;
  }

  handleBlur = () => {
    if (!this.state.open)
      return;

    this.setState({ open: false });
  }

  handleMouseDown = e => {
    if (!this.state.open)
      return;

    e.preventDefault();
    e.stopPropagation();
  }

  handleChange = value => this.setState(
    { value, open: false },
    () => this.props.onChange && this.props.onChange(value)
  )

  render() {
    const { open, value } = this.state;
    const { children } = this.props;

    const label = Array.isArray(children) ? 
      children
        .filter(child => child.props && child.props.value === value)
        .map(child => child.props.children)[0] :
      get(children, 'props.children', null);

    return (
      <div 
        className={classnames(
          "open",
          "btn-group", 
        )}
        onMouseDown={this.handleMouseDown}>
 
        <button 
          type="button"
          onClick={open ? this.toggleDropdown : null}
          className="btn btn-old btn-white">
          {label || value}
        </button>
        <button 
          type="button" 
          data-toggle="dropdown"
          onBlur={this.handleBlur}
          onClick={this.toggleDropdown}
          className="btn-old btn btn-white dropdown-toggle">
          <span className="caret"></span>
        </button>

        {
          open &&
            <ul
              role="menu"
              className={classnames(
                "dropdown-menu",
                "dropdown-menu-overflow"
              )}>
              {
                React.Children.map(
                  children,
                  child => React.cloneElement(child, {
                    handleChange: this.handleChange
                  })
                )
              }
            </ul>
        }

        <input
          type="hidden"
          value={value}
          id="currency"
          name="currency"
        />
      </div>
    ); 
  }
}

export function Option(props) {
  const handleClick = e => {
    e.stopPropagation();
    const value = props.value || props.children;
    props.handleChange(value);
  };

  return (
    <li onClick={handleClick}>
      <button className="option">
        {props.children}
      </button>
    </li>
  );
}