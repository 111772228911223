import { alertError } from "./index";
import { t } from "utils";

/* eslint-disable no-console */
export const addToQuestionsMap = (state, payload) => {
  const questionsMap = { ...state.questionsMap };

  questionsMap[payload.articleId] = payload.questions;

  return {
    ...state,
    questionsMap,
    isLoading: false,
  };
};

export const fetchArticleQuestionsFulfilled = (state, payload) => ({
  ...state,
  isLoading: false,
  articleQuestions: [
    ...state.articleQuestions,
    ...payload.articleQuestions.questions,
  ],
  articleQuestionsTotal: payload.articleQuestions.paging.total, 
});

export const fetchArticleQuestionsError = (state, payload) => {
  alertError(t('fetch_questions_and_answers_error'));

  console.log(payload);

  return {
    ...state,
    isLoading: false,
  };
};