import {
  FETCH_SELLER_SUCCESS,
  FETCH_SELLER_ERROR,

  SET_BLACK_SELLER_SUCCESS,
  SET_WHITE_SELLER_SUCCESS,

  UNSET_BLACK_SELLER_SUCCESS,
  UNSET_WHITE_SELLER_SUCCESS,
} from '../actions/sellers';
// Business logic
import {
  fetchSellerSuccess,
  fetchSellerError,
  setBlackSellerSuccess,
  setWhiteSellerSuccess,
  unsetBlackSellerSuccess,
  unsetWhiteSellerSuccess,
} from './utils/sellers';

const initialState = {
  loading: [],
  sellersMap: {},
};

export default (state = initialState, action) => {
  switch (action.type) {
  case FETCH_SELLER_SUCCESS:
    return fetchSellerSuccess(
      state,
      action.payload,
    );

  case FETCH_SELLER_ERROR:
    return fetchSellerError(
      state,
      action.payload,
    );

  case SET_BLACK_SELLER_SUCCESS:
    return setBlackSellerSuccess(
      state,
      action.payload
    );

  case SET_WHITE_SELLER_SUCCESS:
    return setWhiteSellerSuccess(
      state,
      action.payload
    );

  case UNSET_BLACK_SELLER_SUCCESS:
    return unsetBlackSellerSuccess(
      state,
      action.payload
    );

  case UNSET_WHITE_SELLER_SUCCESS:
    return unsetWhiteSellerSuccess(
      state,
      action.payload
    );

  default:
    return state;
  }
}; 