import { alertError } from "./index";
import { t } from "utils";


export const handleChangeUsers = (state, { index, name, value }) => {
  let users = state.users.splice(0);
  
  if (name !== "errors") {
    users[index][name] = value;
    users[index]["errors"] = [];
  } else {
    if (Array.isArray(users[index][name])) {
      users[index][name].push(value);
    } else {
      users[index][name] = [value]; 
    }
  }

  return { ...state, users };
};

export const createAccountSuccess = state => {
  return { 
    ...state,
    users: [{}],
    isCreatingAccount: false, 
  };
};

export const createAccountError = (state, payload) => {
  if (payload.code === "UsernameExistsException") {
    alertError(t('user_already_exist'));
  } else {
    alertError(payload.message || JSON.stringify(payload));
  }
  
  return {
    ...state,
    isCreatingAccount: false,
  };
};
