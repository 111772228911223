import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { closeModal } from "redux/actions/global";
import SellerDenouncesTable from "components/SellerDenouncesTable";
import { t } from "utils";

class PublicationSellerHistory extends Component {
 
  constructor(props) {
    super(props);

    const { data, seller, env = 'denounces' } = props;

    this.state = {
      step: 1,
      siteId: null,
      data,
      seller,
      env
    };
  }

  logicReceiveText(){
    if(!this.state.data.sellerDenounces  ||  this.state.data.sellerDenounces === 0){
      return t('sellers-history-modal.receive_n');
    }
    if(this.state.data.sellerDenounces === 1){
      return t('sellers-history-modal.receive_s');
    }
    if(this.state.data.sellerDenounces > 1){
      return t('sellers-history-modal.receive_p');
    }
  }

  logicSentText(){
    if(!this.state.data.sellerCounterNotices  ||  this.state.data.sellerCounterNotices === 0){
      return null;
    }
    if(this.state.data.sellerCounterNotices === 1){
      return t('sellers-history-modal.sent_s');
    }
    if(this.state.data.sellerCounterNotices > 1){
      return t('sellers-history-modal.sent_p');
    }
  }

  logicAcceptedText(){
    if(
      !this.state.data.sellerCounterNoticesAccepted || 
      this.state.data.sellerCounterNoticesAccepted === 0
    ){
      return null;
    }
    if(this.state.data.sellerCounterNoticesAccepted === 1){
      return t('sellers-history-modal.accepted_s');
    }
    if(this.state.data.sellerCounterNoticesAccepted > 1){
      return t('sellers-history-modal.accepted_p');
    }
  }

  render() {

    const { closeModal } = this.props;
    
    return (
      <div className="panel seller-history">
        <div className="container">
          <i className="modal-close" onClick={closeModal} />
          <h3 className="title" style={{marginLeft: '40px'}}>{t('sellers-history-modal.title')}</h3>
          <h4 className="subtitle" style={{marginLeft: '40px'}}>{t('sellers-history-modal.subtitle')}</h4>
          <div className="seller-history-ctn">
            {
              this.state.env === 'denounces' && 
              <div className="seller-stats" style={{marginLeft: '8px'}}>
                <strong>{this.state.data.sellerDenounces}  </strong>
                <span className="text-stat">{this.logicReceiveText()}</span>
                <strong>{this.logicSentText() && this.state.data.sellerCounterNotices}  </strong>
                <span className="text-stat">{this.logicSentText()}</span>
                <strong>{this.logicAcceptedText() && this.state.data.sellerCounterNoticesAccepted}  </strong>
                <span className="text-stat">{this.logicAcceptedText()}</span>
              </div>
            }
            <hr />
            <SellerDenouncesTable seller={this.state.seller} />
          </div>
        </div>
        <button 
          className="btn msc-ok"
          onClick={closeModal}
          style={{marginLeft:'53px', marginBottom: '30px'}}
        >
          {t('support_form.success_modal_understood')}
        </button>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  profile: state.global.profile,
});

const actions = {
  closeModal,
};

export default withRouter(connect(mapStateToProps, actions)(PublicationSellerHistory));