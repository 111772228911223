export default `
  query(
    $size: Int, 
    $from: String, 
    $filters: String
  ) {
    whiteListSellers(
      size: $size, 
      from: $from, 
      filters: $filters
    ) {
      size
      from
      total
      sellers {
        id 
        amount
        siteId
        nickname
        countryName
      }
    }
  }
`;
