/* eslint-disable max-len */
/**
 * CONSTANTS
 */
export const FETCH_SELLER = 'FETCH_SELLER'; 
export const FETCH_SELLER_SUCCESS = 'FETCH_SELLER_SUCCESS';
export const FETCH_SELLER_ERROR = 'FETCH_SELLER_ERROR';

export const SET_WHITE_SELLER = "SET_WHITE_SELLER";
export const SET_WHITE_SELLER_SUCCESS = "SET_WHITE_SELLER_SUCCESS";
export const SET_WHITE_SELLER_ERROR = "SET_WHITE_SELLER_ERROR";

export const SET_BLACK_SELLER = "SET_BLACK_SELLER";
export const SET_BLACK_SELLER_SUCCESS = "SET_BLACK_SELLER_SUCCESS";
export const SET_BLACK_SELLER_ERROR = "SET_BLACK_SELLER_ERROR";

export const UNSET_BLACK_SELLER = "UNSET_BLACK_SELLER";
export const UNSET_BLACK_SELLER_SUCCESS = "UNSET_BLACK_SELLER_SUCCESS";
export const UNSET_BLACK_SELLER_ERROR = "UNSET_BLACK_SELLER_ERROR";

export const UNSET_WHITE_SELLER = "UNSET_WHITE_SELLER";
export const UNSET_WHITE_SELLER_SUCCESS = "UNSET_WHITE_SELLER_SUCCESS";
export const UNSET_WHITE_SELLER_ERROR = "UNSET_WHITE_SELLER_ERROR";


/**
 * ACTIONS
 */
export const fetchSeller = payload => ({ type: FETCH_SELLER, payload });
export const fetchSellerSuccess = payload => ({ type: FETCH_SELLER_SUCCESS, payload });
export const fetchSellerError = payload => ({ type: FETCH_SELLER_ERROR, payload });

export const setWhiteSeller = payload => ({ type: SET_WHITE_SELLER, payload });
export const setWhiteSellerSuccess = payload => ({ type: SET_WHITE_SELLER_SUCCESS, payload });
export const setWhiteSellerError = payload => ({ type: SET_WHITE_SELLER_ERROR, payload });

export const setBlackSeller = payload => ({ type: SET_BLACK_SELLER, payload });
export const setBlackSellerSuccess = payload => ({ type: SET_BLACK_SELLER_SUCCESS, payload });
export const setBlackSellerError = payload => ({ type: SET_BLACK_SELLER_ERROR, payload });

export const unsetBlackSeller = payload => ({ type: UNSET_BLACK_SELLER, payload });
export const unsetBlackSellerSuccess = payload => ({ type: UNSET_BLACK_SELLER_SUCCESS, payload });
export const unsetBlackSellerError = payload => ({ type: UNSET_BLACK_SELLER_ERROR, payload });

export const unsetWhiteSeller = payload => ({ type: UNSET_WHITE_SELLER, payload });
export const unsetWhiteSellerSuccess = payload => ({ type: UNSET_WHITE_SELLER_SUCCESS, payload });
export const unsetWhiteSellerError = payload => ({ type: UNSET_WHITE_SELLER_ERROR, payload });