import Modal from "./Modal";

export { Modal };

// TYPES 
export const ARTICLE_REPORT_DRAWER = "ARTICLE_REPORT_DRAWER";

export const BLINDAGE_REPORT_EXCEL = "BLINDAGE_REPORT_EXCEL";

export const BLINDAGE_REPORT_MULTIPLE = "BLINDAGE_REPORT_MULTIPLE";

export const ARTICLE_DENOUNCE_CATALOG = "ARTICLE_DENOUNCE_CATALOG";

export const ARTICLE_DISCARD_DRAWER = "ARTICLE_DISCARD_DRAWER";

export const MANAGE_PROFILES = "MANAGE_PROFILES";

export const VERIFY_PROFILE = "VERIFY_PROFILE";

export const ARTICLE_IMAGES_CATALOGO = "ARTICLE_IMAGES_CATALOGO";

export const ONBOARDING_SLIDE = "ONBOARDING_SLIDE";

export const SUGGESTIONS_SLIDE = "SUGGESTIONS_SLIDE";

export const CATEGORY_SELECTION = "CATEGORY_SELECTION";

export const BLACK_SELLER_SELECTION = "BLACK_SELLER_SELECTION";

export const COUNTRY_SELECTION = "COUNTRY_SELECTION";

export const ADD_SUSPICIOUS_XLSX = "ADD_SUSPICIOUS_XLSX";

export const IMPORT_SUCCESS = "IMPORT_SUCCESS";

export const LINKED_ITEMS = "LINKED_ITEMS";

export const DENOUNCED_ARTICLE_DETAIL = "DENOUNCED_ARTICLE_DETAIL";

export const DENOUNCED_ARTICLE_DETAIL_CATALOGO = "DENOUNCED_ARTICLE_DETAIL_CATALOGO";

export const PUBLICATION_SELLER_HISTORY = "PUBLICATION_SELLER_HISTORY";

export const PUBLICATION_IMAGES_MODIFIED = "PUBLICATION_IMAGES_MODIFIED";

export const PUBLICATION_VIEW_IMAGE = "PUBLICATION_VIEW_IMAGE";

export const DENOUNCED_PENDING_ON_BOARDING = "DENOUNCED_PENDING_ON_BOARDING";

export const DENOUNCED_DETAIL_ON_BOARDING = "DENOUNCED_DETAIL_ON_BOARDING";

export const FILTER_ORDER_REPORTED = "FILTER_ORDER_REPORTED";

export const FILTER_REPORTED_DENOUNCES = "FILTER_REPORTED_DENOUNCES";

export const DENOUNCED_ON_BOARDING_SLIDE = "DENOUNCED_ON_BOARDING_SLIDE";

export const ARTICLE_IMAGES = "ARTICLE_IMAGES";

export const AUDITOR_COMMENT_MODAL = "AUDITOR_COMMENT_MODAL";
