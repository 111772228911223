import caseListFieldsV2 from "../fragments/caseListFieldsV2";


export default `
  ${caseListFieldsV2}
  
  query(
    $size: Int!,
    $from: Int!,
    $nickname: String,
    $flow: String
  ) {
    closedCasesList(
      size: $size, 
      from: $from, 
      nickname: $nickname,
      flow: $flow
    ) {
      cases {
        ...caseListFieldsV2
      }
      paging {
        total
        limit
        offset
      }
    }
  }
`;
