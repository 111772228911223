import React from 'react';
import ContentLoader from "react-content-loader";

const ProfileLoader = () => (
  <ContentLoader
    height={180}
    width={400}
    speed={2}
    primaryColor="#f3f3f3"
    secondaryColor="#ecebeb"
  >
    <rect x="35" y="10" rx="0" ry="0" width="68" height="65" /> 
    <rect x="120" y="10" rx="0" ry="0" width="68" height="65" /> 
    <rect x="205" y="10" rx="0" ry="0" width="68" height="65" /> 
    <rect x="290" y="10" rx="0" ry="0" width="68" height="65" /> 
    <rect x="35" y="85" rx="0" ry="0" width="68" height="65" /> 
    <rect x="120" y="85" rx="0" ry="0" width="68" height="65" /> 
    <rect x="205" y="85" rx="0" ry="0" width="68" height="65" /> 
    <rect x="290" y="85" rx="0" ry="0" width="68" height="65" />
  </ContentLoader>
);

export default ProfileLoader;