import React, { useState, useEffect } from 'react'
import { connect } from "react-redux";
import classnames from "classnames";
import { updateProfile } from "redux/actions/global";


export const Language = ({ lang, profile, updateProfile }) => {
    const [open, setOpen] = useState(false)

    const toggleDropdown = () => setOpen(!open);

    const handleBlur = () => {
        if (!open) {
            return;
        }
        setOpen(false);
    }
    const handleMouseDown = e => {
        if (!open) {
            return;
        }

        e.preventDefault();
        e.stopPropagation();
    }

    const handleChangeLanguage = (lang) => {

        if (profile.lang !== lang) {
            updateProfile({
                ...profile,
                lang,
            })
        }
    }


    useEffect(() => {
        const params = new URLSearchParams(window.location.search);
        let locale = params.get('force_locale');

        switch (locale) {
            case 'EN':
                locale = 'EN';
                break
            case 'ES':
                locale = 'ES';
                break
            case 'pt-BR':
                locale = 'PT';
                break
            default:
                locale = profile.lang;
                break
        }

        if (locale) {
            handleChangeLanguage(locale.toLowerCase())
        }
    }, []);




    return (
        <ul
            tabIndex="1"
            onBlur={handleBlur}
            onMouseDown={handleMouseDown}
            className="nav lang pull-right top-menu">
            <li
                onClick={toggleDropdown}
                className={classnames(
                    "dropdown",
                    { "open": open }
                )}>
                <button>
                    <span className="language">
                        {lang.toUpperCase()}
                    </span>
                    <span className="icon-chevron"></span>
                </button>
                <ul
                    className={classnames(
                        "logout",
                        "extended",
                        "dropdown-menu",
                        "nav-right-settings",
                    )}>
                    <li onClick={() => handleChangeLanguage('es')}>
                        <button rel="nofollow">Español</button>
                    </li>
                    <li onClick={() => handleChangeLanguage('en')}>
                        <button rel="nofollow">English</button>
                    </li>
                    <li onClick={() => handleChangeLanguage('pt')}>
                        <button rel="nofollow">Português</button>
                    </li>
                </ul>
            </li>
        </ul>
    );
}

const mapStateToProps = state => ({
    lang: state.global.lang,
    profile: state.global.profile,
});

export default connect(mapStateToProps, { updateProfile })(Language);