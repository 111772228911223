export default `
  mutation(
    $siteId: String!,
    $sellerId: String! 
  ) {
    whiteListSeller(
      siteId: $siteId,
      sellerId: $sellerId 
    ) {
      id
    }
  }
`;