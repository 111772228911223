export default `
  mutation (
    $code: String!,
    $code_description: String!,
    $originModel: String!,
    $articlesIds: [String],
    $description: String
  ) {
    discardArticles(discardedArticles: {
      articlesIds: $articlesIds
      code: $code
      description: $description
      code_description: $code_description
      originModel: $originModel
    }) {
      articlesIds
    }
  }
`;