import {
  FETCH_ARTICLE_DESCRIPTION,
  FETCH_ARTICLE_DESCRIPTION_FULFILLED,
  FETCH_ARTICLE_DESCRIPTION_ERROR,
} from "redux/actions/articleDescription";
/* Bussines logic */
import { addToDescriptionsMap } from "redux/reducers/utils/articlesDescription"; 


const initialState = {
  isLoading: false,
  descriptionsMap: [],
  //descriptionPlaintext: null,
};
    
export default (state = initialState, action) => {
  switch (action.type) {
  case FETCH_ARTICLE_DESCRIPTION:
    return {
      ...state,
      isLoading: true,
    };

  case FETCH_ARTICLE_DESCRIPTION_FULFILLED:
    return addToDescriptionsMap(
      state,
      action.payload
    );
  
  case FETCH_ARTICLE_DESCRIPTION_ERROR:
    return {
      ...state,
      isLoading: false,
    };

  default:
    return state;
  }
};
    