export default `
  query(
    $size: Int!,
    $from: Int!,
    $sort: String,
    $order: String,
    $status: [String],
    $sellerId: String,
    $itemId: String,
    $nickname: String,
    
  ) {
    counterNoticesV3(
      size: $size,
      from: $from,
      sort: $sort,
      order: $order,
      status: $status,
      sellerId: $sellerId,
      itemId: $itemId,
      nickname: $nickname
    ) {
      cases {
        item_info{
              item_id
              description
              title
              pictures{
                url
                size
                max_size
              }
             }
       last_updated
       date_created
       reason_text
       due_date
       member_name
       reason_id
       seller_name
       nickname
       case_id
       current_status
       seller_id
       
      }
      paging {
        total
      }
    }
  }
`;