/* eslint-disable no-console, max-len */
import { alertSuccess, alertError } from "./index";
import { t } from "utils";


export const fetchCaseDescriptionError = (state, payload) => {
  console.error(payload);
  
  alertError(
    t('case_management.case_description.fetch_description_denounces_error')
  );

  return {
    ...state,
    isLoading: false,
  };
};

export const respondCounterNoticeSuccess = (state, payload) => {  
  const caseDescription = { ...state.caseDescription };
  const { counterNotice } = caseDescription;
  const { respondCounterNotice } = payload;

  if (
    counterNotice &&
    counterNotice.caseId === respondCounterNotice.caseId
  ) {
    caseDescription.counterNotice = {
      ...counterNotice,
      ...respondCounterNotice,
    };
  }

  alertSuccess(t('case_management.documentation_approved.respond_counter_notice_success'));

  return {
    ...state,
    caseDescription,
    sendingResponse: false,
  };
};

export const respondCounterNoticeError = (state, payload) => {
  alertError(
    t('case_management.documentation_approved.respond_counter_notice_error')
  );

  console.error(payload);

  return { 
    ...state, 
    sendingResponse: false 
  };
};


export const fetchSellerCounterNoticesSuccess = (state, payload) => {
  return {
    ...state,
    isLoadingCounterNotices: false,
    sellerCounterNotices: [
      ...state.sellerCounterNotices,
      ...payload.counterNotices.cases,
    ],
    sellerCounterNoticesTotal: payload.counterNotices.paging.total,
  };
};

export const fetchSellerCounterNoticesError = (state, payload) => {
  alertError(
    t('case_management.case_description.seller_data.fetch_counter_notices_error')
  );

  console.log(payload);

  return {
    ...state,
    isLoadingCounterNotices: false,
  };
};


export const rollbackCaseSuccess = (state, payload) => {  
  const caseDescription = { ...state.caseDescription };
  const { counterNotice } = caseDescription;
  const { rollbackCase } = payload;

  if (
      counterNotice &&
      counterNotice.caseId === rollbackCase.caseId
    ) {
      alertSuccess(t('rollback-success'));
    } else {
      alertError(t('rollback-error'));
    }  

  return {
    ...state,
    sendingRollback: false,
  };
};

export const rollbackCaseError = (state, payload) => {
  alertError(
    t('rollback-error')
  );

  console.error(payload);

  return { 
    ...state, 
    sendingRollback: false 
  };
};