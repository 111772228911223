import * as locales from "locales";
import {
  FETCH_PROFILE,
  PROFILE_FULFILLED,
  FETCH_PROFILE_ERROR,

  FETCH_PROFILE_ALLOWED_NOTICES,
  FETCH_PROFILE_ALLOWED_NOTICES_SUCCESS,
  FETCH_PROFILE_ALLOWED_NOTICES_ERROR,

  FETCH_MEMBER_RESTRICTION,
  FETCH_MEMBER_RESTRICTION_SUCCESS,
  FETCH_MEMBER_RESTRICTION_ERROR,

  FETCH_EFFICIENCY,
  FETCH_EFFICIENCY_SUCCESS,
  FETCH_EFFICIENCY_ERROR,
  TOGGLE_LEFT_NAV,

  SET_LANG,

  OPEN_MODAL,
  CLOSE_MODAL,
  UPDATE_MODAL,

  CURRENCY_FULFILLED,
  FETCH_CURRENCY_ERROR,

  UPDATE_PROFILE,
  UPDATE_PROFILE_ERROR,
  UPDATE_PROFILE_SUCCESS,

  UPDATE_PROFILE_SETTINGS,

  CREATE_SELLERS_LIVE_SEARCH,
  CREATE_SELLERS_LIVE_SEARCH_ERROR,
  CREATE_SELLERS_LIVE_SEARCH_SUCCESS,

  FETCH_SITES,
  FETCH_SITES_FULLFILLED,
  FETCH_SITES_ERROR,

  FETCH_ARTICLE_DETAIL_FULFILLED,

  FETCH_ARTICLE_CATALOGO_DETAIL_FULFILLED,
  FETCH_ARTICLES_CATALOGO,
  LOG_OUT,

  HIDE_NAV_FROM_HEADER,

  FETCH_PROFILES,
  FETCH_PROFILES_SUCCESS,
  FETCH_PROFILES_ERROR,

  SWITCH_TO_CREATE_PROFILE,

  CREATE_PROFILE,
  CREATE_PROFILE_SUCCESS,
  CREATE_PROFILE_ERROR,

  CLOSE_HELP,
  CLOSE_HELP_SUCCESS,
  CLOSE_HELP_ERROR,

  GET_CREDENTIALS,
  GET_CREDENTIALS_SUCCESS,
  GET_CREDENTIALS_ERROR,

  CLEAN_CREDENTIALS,
  CHANGE_PROFILE_SUCCESS,
  CHANGE_PROFILE_ERROR,

  SEND_MESSAGE_TO_SUPPORT,
  SEND_MESSAGE_TO_SUPPORT_SUCCESS,
  SEND_MESSAGE_TO_SUPPORT_ERROR,

  FETCH_MELI_ACCOUNT,
  FETCH_MELI_ACCOUNT_FULFILLED,
  FETCH_MELI_ACCOUNT_ERROR,

  UPDATE_MELI_ACCOUNT,
  UPDATE_MELI_ACCOUNT_SUCCESS,
  UPDATE_MELI_ACCOUNT_ERROR,

  SET_ARTICLES_PINNED,
  SET_ARTICLES_PINNED_SUCCESS,
  SET_ARTICLES_PINNED_ERROR,

  SET_TRANSLATE_TOOLTIP_ALREADY_SHOWN,
  SET_TRANSLATE_TOOLTIP_ALREADY_SHOWN_SUCCESS,
  SET_TRANSLATE_TOOLTIP_ALREADY_SHOWN_ERROR,

  SET_NEW_REASON_TOOLTIP_ALREADY_SHOWN,
  SET_NEW_REASON_TOOLTIP_ALREADY_SHOWN_SUCCESS,
  SET_NEW_REASON_TOOLTIP_ALREADY_SHOWN_ERROR,

  SET_DISCARD_TOOLTIP_ALREADY_SHOWN,
  SET_DISCARD_TOOLTIP_ALREADY_SHOWN_SUCCESS,
  SET_DISCARD_TOOLTIP_ALREADY_SHOWN_ERROR,

  RESET_CASE_CX,

  GRAPHQL_ERRORS,

  SET_EMAIL_LOGIN_ADRESS,

  ACTION_MESSAGE_BOX,

  FETCH_MENU,
  FETCH_MENU_SUCCESS,
  FETCH_MENU_ERROR,

  FETCH_BLINDAGES,
  FETCH_BLINDAGES_SUCCESS,
  FETCH_BLINDAGES_ERROR,

} from "redux/actions/global";
import {
  REPORT_ARTICLES,
  REPORT_ARTICLES_ERROR,
  EDIT_REPORTED_ARTICLES,

  REPORT_ARTICLE_UNDO,
  REPORT_ARTICLE_UNDO_ERROR,

  APPROVE_ARTICLE,
  APPROVE_ARTICLE_UNDO,
} from "redux/actions/detail";
import {
  SET_BLACK_SELLER_SUCCESS,
  SET_WHITE_SELLER_SUCCESS,

  UNSET_BLACK_SELLER_SUCCESS,
  UNSET_WHITE_SELLER_SUCCESS,
} from "redux/actions/sellers";
import {
  SEND_NOTICE_SUCCESS,
  REMOVE_ARTICLE_SUCCESS,
  SET_NOTICES_LIMIT_DAY_COUNT_FULFILLED
} from "redux/actions/reportedArticles";
import { CREATE_LIVE_SEARCH_REPORT_SUCCESS } from "redux/actions/liveSearches";
// Business logic
import {
  closeHelpError,
  fetchSitesError,
  profileFulfilled,
  addToArticlesMap,
  addToCatalogoArticlesMap,
  addToCatalogosArticlesMap,
  fetchProfileError,
  updateProfileError,
  fetchCurrencyError,
  fetchProfilesError,
  createProfileError,
  changeProfileError,
  getCredentialsError,
  changeProfileSuccess,
  updateProfileSuccess,
  createProfileSuccess,
  sendNoticeSuccessUtil,
  removeFromArticlesMap,
  reportArticlesErrorUtil,
  unsetWhiteSellerSuccess,
  unsetBlackSellerSuccess,
  approveArticleOptimistic,
  updateArticlesMapReported,
  setBlackListSellerSuccess,
  setWhiteListSellerSuccess,
  updateArticlesPinnedError,
  sendMessageToSupportError,
  updateArticlesPinnedSuccess,
  reportArticleUndoOptimistic,
  approveArticleUndoOptimistic,
  createSellersLiveSearchError,
  fetchMeliProfileAccountError,
  updateMeliProfileAccountError,
  createSellersLiveSearchSuccess,
  updateMeliProfileAccountSuccess,
  updateProfileSettingsOptimistic,
  setNoticesLimitDayCountSuccessUtil,
  updateTranslateTooltipAlreadyShownSuccess,
  updateTranslateTooltipAlreadyShownError,
  updateNewReasonTooltipAlreadyShownSuccess,
  updateNewReasonTooltipAlreadyShownError,
  updateDiscardTooltipAlreadyShownSuccess,
  updateDiscardTooltipAlreadyShownError,
  setArticlesBlindages,
  errorArticleBlindages
} from "redux/reducers/utils/global";

const initialState = {
  modal: {},
  rates: {},
  sites: [],
  profile: {},
  profiles: [],
  caseCx: null,
  articlesMap: {},
  articlesCatalogoMap: {},
  credentials: null,
  leftNavOpen: false,
  isModalOpen: false,
  graphqlErrors: null,
  fetchProfileError: 0,
  isFetchingSites: false,
  memberRestriction: {},
  efficiency: 0,
  isFetchingEfficiency:false,
  isChangingProfile: false,
  isUpdatingProfile: false,
  isCreatingProfile: false,
  isAuthorizingSite: false,
  isFetchingProfile: false,
  isUpdateArticlesPinned: false,
  isFetchingMeliProfileAccount: false,
  isUpdatingMeliProfileAccount: false,
  updateMeliProfileAccountError: null,
  isUpdatingNewReasonTooltipAlreadyShown: false,
  creatingSellersLS: false,
  isFetchingProfiles: false,
  allowedNoticesError: null,
  switchToCreateProfile: false,
  isNavFromHeaderHidden: false,
  isFetchingRestriction: false,
  isFetchingAllowedNotices: false,
  isSendingMessageToSupport: false,
  isUpdatingTranslateTooltipAlreadyShown: false,
  /* eslint-disable no-undef */
  lang: process.env.REACT_APP_DEFAULT_LANG || 'es',
  locale: locales[process.env.REACT_APP_DEFAULT_LANG || 'es'],
  /* eslint-enable no-undef */
  emailLoginAddress: '',
  messageBoxShow: true,
  isFetchingMenu: false,
  blindages: [],
  isFetchingBlindages: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
  case FETCH_PROFILE:
    return {
      ...state,
      isFetchingProfile: !action.payload,
    };

  case PROFILE_FULFILLED:
    return profileFulfilled(
      state,
      action.payload
    );

  case FETCH_PROFILE_ERROR:
    return fetchProfileError(
      state,
      action.payload
    );

  case FETCH_PROFILE_ALLOWED_NOTICES:
    return {
      ...state,
      isFetchingAllowedNotices: true,
    };

  case FETCH_PROFILE_ALLOWED_NOTICES_SUCCESS:
    return {
      ...state,
      profile: {
        ...state.profile,
        ...action.payload.profile,
      },
      isFetchingAllowedNotices: false,
    };

  case FETCH_PROFILE_ALLOWED_NOTICES_ERROR:
    return {
      ...state,
      isFetchingAllowedNotices: false,
      allowedNoticesError: action.payload,
    };

  case FETCH_MEMBER_RESTRICTION:
    return {
      ...state,
      isFetchingRestriction: true,
    };

  case FETCH_MEMBER_RESTRICTION_SUCCESS:
    return {
      ...state,
      memberRestriction: action.payload,
      isFetchingRestriction: false,
    };

  // TO DO: handle error.
  case FETCH_MEMBER_RESTRICTION_ERROR:
    return {
      ...state,
      isFetchingRestriction: false,
    };

  case FETCH_EFFICIENCY:
    return {
      ...state,
      isFetchingEfficiency: true,
    };

  case FETCH_EFFICIENCY_SUCCESS:
    return {
      ...state,
      efficiency: action.payload.efficiency,
      isFetchingEfficiency: false,
    };

  case FETCH_EFFICIENCY_ERROR:
    return {
      ...state,
      isFetchingEfficiency: false,
    };

  case TOGGLE_LEFT_NAV:
    return {
      ...state,
      leftNavOpen: false
    };

  case SET_LANG:
    return {
      ...state,
      lang: action.payload.toLowerCase(),
      locale: locales[action.payload.toLowerCase()],
    };

  case OPEN_MODAL:
    return {
      ...state,
      ...action.payload,
      isModalOpen: true,
    };

  case CLOSE_MODAL:
  case CREATE_LIVE_SEARCH_REPORT_SUCCESS:
    return {
      ...state,
      modal: {},
      isModalOpen: false,
    };

  case UPDATE_MODAL:
    return {
      ...state,
      modal: {
        ...state.modal,
        ...action.payload,
      },
    };

  case UPDATE_PROFILE:
    return {
      ...state,
      isUpdatingProfile: true,
    };

  case UPDATE_PROFILE_ERROR:
    return updateProfileError(
      state,
      action.payload
    );

  case UPDATE_PROFILE_SUCCESS:
    return updateProfileSuccess(
      state,
      action.payload
    );

  case UPDATE_PROFILE_SETTINGS:
    return updateProfileSettingsOptimistic(
      state,
      action.payload
    );

  case CREATE_SELLERS_LIVE_SEARCH:
    return {
      ...state,
      creatingSellersLS: true,
    };

  case CREATE_SELLERS_LIVE_SEARCH_ERROR:
    return createSellersLiveSearchError(
      state,
      action.payload
    );

  case CREATE_SELLERS_LIVE_SEARCH_SUCCESS:
    return createSellersLiveSearchSuccess(state);

  case FETCH_ARTICLE_DETAIL_FULFILLED:
    return addToArticlesMap(
      state,
      action.payload
    );

  case FETCH_ARTICLE_CATALOGO_DETAIL_FULFILLED:
    return addToCatalogoArticlesMap(
      state,
      action.payload
    );

  case FETCH_ARTICLES_CATALOGO:
    return addToCatalogosArticlesMap(
      state,
      action.payload
    );

  case APPROVE_ARTICLE:
    return approveArticleOptimistic(
      state,
      action.payload
    );

  case APPROVE_ARTICLE_UNDO:
    return approveArticleUndoOptimistic(
      state,
      action.payload
    );

  case REPORT_ARTICLES:
  case EDIT_REPORTED_ARTICLES:
    return updateArticlesMapReported(
      state,
      action.payload
    );

  case REPORT_ARTICLES_ERROR:
    return reportArticlesErrorUtil(
      state,
      action.payload
    );

  case REPORT_ARTICLE_UNDO:
  case REMOVE_ARTICLE_SUCCESS:
    return reportArticleUndoOptimistic(
      state,
      action.payload
    );

  case SEND_NOTICE_SUCCESS:
    return sendNoticeSuccessUtil(
      state,
      action.payload
    );

  case SET_NOTICES_LIMIT_DAY_COUNT_FULFILLED:
    return setNoticesLimitDayCountSuccessUtil(
      state,
      action.payload
    );

  case REPORT_ARTICLE_UNDO_ERROR:
    return removeFromArticlesMap(
      state,
      { articlesIds: [action.payload.articleId] }
    );

  case SET_BLACK_SELLER_SUCCESS:
    return setBlackListSellerSuccess(
      state,
      action.payload
    );

  case SET_WHITE_SELLER_SUCCESS:
    return setWhiteListSellerSuccess(
      state,
      action.payload
    );

  case UNSET_BLACK_SELLER_SUCCESS:
    return unsetBlackSellerSuccess(
      state,
      action.payload,
    );

  case UNSET_WHITE_SELLER_SUCCESS:
    return unsetWhiteSellerSuccess(
      state,
      action.payload,
    );

  case FETCH_SITES:
    return {
      ...state,
      isFetchingSites: true,
    };

  case FETCH_SITES_FULLFILLED:
    return {
      ...state,
      ...action.payload,
      isFetchingSites: false,
    };

  case FETCH_SITES_ERROR:
    return fetchSitesError(
      state,
      action.payload
    );

  case FETCH_CURRENCY_ERROR:
    return fetchCurrencyError(
      state,
      action.payload
    );

  case CURRENCY_FULFILLED:
    return {
      ...state,
      ...action.payload,
    };

  case LOG_OUT:
    return initialState;

  case HIDE_NAV_FROM_HEADER:
    return {
      ...state,
      isNavFromHeaderHidden: action.payload,
    };

  case FETCH_PROFILES:
    return {
      ...state,
      isFetchingProfiles: true,
    };

  case FETCH_PROFILES_SUCCESS:
    return {
      ...state,
      profiles: action.payload.profiles,
      isFetchingProfiles: false,
    };

  case SWITCH_TO_CREATE_PROFILE:
    return {
      ...state,
      switchToCreateProfile: action.payload,
    };

  case FETCH_PROFILES_ERROR:
    return fetchProfilesError(
      state,
      action.payload
    );

  case CREATE_PROFILE:
    return {
      ...state,
      isCreatingProfile: true,
    };

  case CREATE_PROFILE_SUCCESS:
    return createProfileSuccess(
      state,
      action.payload,
    );

  case CREATE_PROFILE_ERROR:
    return createProfileError(
      state,
      action.payload
    );

  case CLOSE_HELP:
    return {
      ...state,
      profile: {
        ...state.profile,
        helps: {
          ...(state.profile.helps || {}),
          [action.payload]: true,
        }
      },
    };

  case CLOSE_HELP_SUCCESS:
    return {
      ...state,
      profile: {
        ...state.profile,
        ...action.payload.updateProfile
      },
    };

  case CLOSE_HELP_ERROR:
    return closeHelpError(
      state,
      action.payload
    );

  case GET_CREDENTIALS:
    return {
      ...state,
      isChangingProfile: true,
    };

  case GET_CREDENTIALS_SUCCESS:
    return {
      ...state,
      credentials: action.payload,
    };

  case GET_CREDENTIALS_ERROR:
    return getCredentialsError(
      state,
      action.payload
    );

  case CLEAN_CREDENTIALS:
    return {
      ...state,
      credentials: null,
    };

  case CHANGE_PROFILE_SUCCESS:
    return changeProfileSuccess(
      state,
      action.payload
    );

  case CHANGE_PROFILE_ERROR:
    return changeProfileError(
      state,
      action.payload
    );

  case SEND_MESSAGE_TO_SUPPORT:
    return {
      ...state,
      isSendingMessageToSupport: true,
    };

  case SEND_MESSAGE_TO_SUPPORT_SUCCESS:
    return {
      ...state,
      isSendingMessageToSupport: false,
      caseCx: action.payload.sendMessageToSupport.caseCx,
    };

  case SEND_MESSAGE_TO_SUPPORT_ERROR:
    return sendMessageToSupportError(state);

  case FETCH_MELI_ACCOUNT:
    return {
      ...state,
      isFetchingMeliProfileAccount: true,
    };

  case FETCH_MELI_ACCOUNT_FULFILLED:
    return {
      ...state,
      profile: {
        ...state.profile,
        meliAccount: action.payload
      },
      isFetchingMeliProfileAccount: false,
    };

  case FETCH_MELI_ACCOUNT_ERROR:
    return fetchMeliProfileAccountError(
      state,
      action.payload
    );

  case UPDATE_MELI_ACCOUNT:
    return {
      ...state,
      isUpdatingMeliProfileAccount: true,
    };

  case UPDATE_MELI_ACCOUNT_SUCCESS:
    return updateMeliProfileAccountSuccess(
      state,
      action.payload
    );

  case UPDATE_MELI_ACCOUNT_ERROR:
    return updateMeliProfileAccountError(
      state,
      action.payload
    );

  case SET_ARTICLES_PINNED:
    return {
      ...state,
      isUpdateArticlesPinned: true,
    };

  case SET_ARTICLES_PINNED_SUCCESS:
    return updateArticlesPinnedSuccess(
      state,
      action.payload
    );

  case SET_ARTICLES_PINNED_ERROR:
    return updateArticlesPinnedError(
      state,
      action.payload
    );

  case SET_TRANSLATE_TOOLTIP_ALREADY_SHOWN:
    return {
      ...state,
      isUpdatingTranslateTooltipAlreadyShown: true,
    };

  case SET_TRANSLATE_TOOLTIP_ALREADY_SHOWN_SUCCESS:
    return updateTranslateTooltipAlreadyShownSuccess(
      state,
      action.payload
    );

  case SET_TRANSLATE_TOOLTIP_ALREADY_SHOWN_ERROR:
    return updateTranslateTooltipAlreadyShownError(
      state,
      action.payload
    );

  case SET_NEW_REASON_TOOLTIP_ALREADY_SHOWN:
    return {
      ...state,
      isUpdatingNewReasonTooltipAlreadyShown: true,
    };

  case SET_NEW_REASON_TOOLTIP_ALREADY_SHOWN_SUCCESS:
    return updateNewReasonTooltipAlreadyShownSuccess(
      state,
      action.payload
    );

  case SET_NEW_REASON_TOOLTIP_ALREADY_SHOWN_ERROR:
    return updateNewReasonTooltipAlreadyShownError(
      state,
      action.payload
    );

  case SET_DISCARD_TOOLTIP_ALREADY_SHOWN:
    return {
      ...state,
      isUpdatingDiscardTooltipAlreadyShown: true,
    };

  case SET_DISCARD_TOOLTIP_ALREADY_SHOWN_SUCCESS:
    return updateDiscardTooltipAlreadyShownSuccess(
      state,
      action.payload
    );

  case SET_DISCARD_TOOLTIP_ALREADY_SHOWN_ERROR:
    return updateDiscardTooltipAlreadyShownError(
      state,
      action.payload
    );

  case GRAPHQL_ERRORS:
    return {
      ...state,
      graphqlErrors: action.payload,
    };

  case RESET_CASE_CX:
    return {
      ...state,
      caseCx: null,
    };

  case SET_EMAIL_LOGIN_ADRESS:
    return {
      ...state,
      emailLoginAddress: action.payload,
    };

  case ACTION_MESSAGE_BOX:
    return {
      ...state,
      messageBoxShow: false
    }

  case FETCH_MENU:
    return {
      ...state,
      isFetchingMenu: true,
    };

  case FETCH_MENU_SUCCESS:
    return {
      ...state,
      menu: action.payload.menu,
      isFetchingMenu: false,
    };

  case FETCH_MENU_ERROR:
    return {
      ...state,
      isFetchingMenu: false,
    };

  case FETCH_BLINDAGES:
    return {
      ...state,
      blindages: [],
      isFetchingBlindages: true,
    };

  case FETCH_BLINDAGES_SUCCESS:
    return setArticlesBlindages(
      state,
      action.payload
    );

  case FETCH_BLINDAGES_ERROR:
    return errorArticleBlindages(
      state,
      action.payload
    );
    
  default:
    return state;
  }

};
