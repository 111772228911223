import React from "react";
import { Route, Switch, Redirect, BrowserRouter } from "react-router-dom";

import asyncComponent from "./components/AsyncComponent";
import AuthenticatedRoute from "./components/AuthenticatedRoute";
import UnauthenticatedRoute from "./components/UnauthenticatedRoute";

/* eslint-disable max-len */
//const AsyncSuggestedListings = asyncComponent(() => import("./containers/SuggestedListings/SuggestedListings"));

const AsyncSearch = asyncComponent(() => import("./containers/Search/Search"));
const AsyncDetail = asyncComponent(() => import("./containers/Detail/Detail"));
const AsyncArticleDetail = asyncComponent(() => import("./containers/ArticleDetail/ArticleDetail"));
const AsyncArticleCatalogoDetail = asyncComponent(() => import("./containers/ArticleDetail/ArticleCatalogoDetail"));
const AsyncNotFound = asyncComponent(() => import("./containers/NotFound/NotFound"));
const AsyncReportedArticles = asyncComponent(() => import("./containers/ReportedArticles/ReportedArticles"));
const AsyncWhiteListSellers = asyncComponent(() => import("./containers/WhiteListSellers/WhiteListSellers"));
const AsyncNoticedArticlesNew = asyncComponent(() => import("./containers/CounterNotices"));
const AsyncNoticedArticles = asyncComponent(() => import("./containers/NoticedArticles/NoticedArticles"));
const AsyncCustomReports = asyncComponent(() => import("./containers/CustomReports/CustomReports"));
const AsyncProfile = asyncComponent(() => import("./containers/Profile/Profile"));
const AsyncBlackListSellers = asyncComponent(() => import("./containers/BlackListSellers/BlackListSellers"));
const AsyncSupport = asyncComponent(() => import("./containers/Support/Support"));
const AsyncVideoInstructions = asyncComponent(() => import("./containers/VideoInstructions/VideoInstructions"));
const AsyncSupportForm = asyncComponent(() => import("./containers/SupportForm/SupportForm"));
const AsyncWelcome = asyncComponent(() => import("./containers/Welcome/Welcome"));
const AsyncLogin = asyncComponent(() => import("./containers/Login/Login"));
const AsyncLogout = asyncComponent(() => import("./containers/Logout/Logout"));
const AsyncNoBPP = asyncComponent(() => import("./containers/NoBPP/NoBPP"));
const AsyncMeliLogin = asyncComponent(() => import("./containers/MeliLogin/MeliLogin"));
const AsyncRedirect = asyncComponent(() => import("./containers/Redirect/Redirect"));
const AsyncSearchReports = asyncComponent(() => import("./containers/SearchReport/SearchReports"));
const AsyncAuthenticate = asyncComponent(() => import("./containers/Authenticate/Authenticate"));
const AsyncSnapshotDownload = asyncComponent(() => import("./containers/SnapshotDownload/SnapshotDownload"));
const AsyncPendingCasesList = asyncComponent(() => import("./containers/PendingCasesList/PendingCasesList"));
const AsyncClosedCasesList = asyncComponent(() => import("./containers/ClosedCasesList/ClosedCasesList"));
const AsyncClosedCasesListDetail = asyncComponent(() => import("./components/CaseDetail"));
/* eslint-enable max-len */

export default ({ childProps }) => (

    <Switch>
      { /* Authenticated  */}
      <AuthenticatedRoute
        path="/help_support"
        exact
        props={childProps}
        component={AsyncSupport}
      />

      <AuthenticatedRoute
        path="/video_instructions"
        exact
        props={childProps}
        component={AsyncVideoInstructions}
      />

      <AuthenticatedRoute
        path="/support_form"
        exact
        props={childProps}
        component={AsyncSupportForm}
      />

      {
        childProps.isAuthenticated &&
        childProps.hasToVerifyProfile &&
        <AuthenticatedRoute
          path="*"
          exact
          props={childProps}
          component={AsyncProfile}
        />
      }

      {/* {
      childProps.isAuthenticated &&
      childProps.redirectToSuggestions &&
      childProps.redirectToSuggestions === true &&
      <AuthenticatedRoute
        path="/"
        exact
        props={childProps}
        component={AsyncSuggestedListings}
      />
    } */}

      <AuthenticatedRoute
        path="/"
        exact
        props={childProps}
        component={AsyncSearch}
      />

      {/* <AuthenticatedRoute
      path="/suggested_listings"
      exact
      props={childProps}
      component={AsyncSuggestedListings}
    /> */}

      <AuthenticatedRoute
        path="/live_searches"
        exact
        props={childProps}
        component={AsyncSearch}
      />

      <AuthenticatedRoute
        path="/send_notice/:articleId"
        exact
        props={childProps}
        component={AsyncSearch}
      />

      {
        childProps.isNotAcuerdo &&
        <AuthenticatedRoute
          path="/profile"
          exact
          props={childProps}
          component={AsyncProfile}
        />
      }
      <AuthenticatedRoute
        path="/logout"
        exact
        props={childProps}
        component={AsyncLogout}
      />
      <AuthenticatedRoute
        path="/reports/noticed_articles"
        exact
        props={childProps}
        component={AsyncNoticedArticlesNew}
      />

      <AuthenticatedRoute
        path="/reports/noticed_articles/:id"
        exact
        props={childProps}
        component={AsyncClosedCasesListDetail}
      />

      <AuthenticatedRoute
        path="/reports/custom_reports"
        exact
        props={childProps}
        component={AsyncCustomReports}
      />

      <AuthenticatedRoute
        path="/live_searches/:id"
        exact
        props={childProps}
        component={AsyncDetail}
      />

      <AuthenticatedRoute
        path="/article_detail/:articleId"
        exact
        props={childProps}
        component={AsyncArticleDetail}
      />

      <AuthenticatedRoute
        path="/article_catalogo_detail/:articleId"
        exact
        props={childProps}
        component={AsyncArticleCatalogoDetail}
      />

      <AuthenticatedRoute
        path="/reported_articles"
        exact
        props={childProps}
        component={AsyncReportedArticles}
      />

      <AuthenticatedRoute
        path="/client_accounts/client_black_sellers"
        exact
        props={childProps}
        component={AsyncBlackListSellers}
      />

      <AuthenticatedRoute
        path="/client_accounts/client_white_sellers"
        exact
        props={childProps}
        component={AsyncWhiteListSellers}
      />

      {
        childProps.isNotAcuerdo &&
        <Redirect from="/case_management/client_pending_cases"
          to="/reports/noticed_articles?force_locale=ES&status=PENDING&page=1" />
        // <AuthenticatedRoute
        //   path="/case_management/client_pending_cases"
        //   exact
        //   props={childProps}
        //   component={AsyncPendingCasesList}
        // />
      }

      {/* {
      childProps.isNotAcuerdo &&
      <AuthenticatedRoute
        path="/case_management/client_pending_cases/:caseId"
        exact
        props={childProps}
        component={AsyncPendingCasesList}
      />
    } */}

      {
        childProps.isNotAcuerdo &&
        <AuthenticatedRoute
          path="/case_management/client_closed_cases"
          exact
          props={childProps}
          component={AsyncClosedCasesList}
        />
      }

      {
        childProps.isNotAcuerdo &&
        <AuthenticatedRoute
          path="/case_management/:id"
          exact
          props={childProps}
          component={AsyncClosedCasesListDetail}
        />
      }
      {
        childProps.isNotAcuerdo &&
        <AuthenticatedRoute
          path="/redirect"
          exact
          props={childProps}
          component={AsyncRedirect}
        />
      }
      <AuthenticatedRoute
        path="/snapshot-download/:articleId"
        exact
        props={childProps}
        component={AsyncSnapshotDownload}
      />

      <AuthenticatedRoute
        path="/livesearch_reports"
        exact
        props={childProps}
        component={AsyncSearchReports}
      />

      <UnauthenticatedRoute
        path="/oauth/:lang/authenticate"
        exact
        props={childProps}
        component={AsyncAuthenticate}
      />

      <UnauthenticatedRoute
        path="/oauth/:lang/authenticate/send_notice/:articleId"
        exact
        props={childProps}
        component={AsyncAuthenticate}
      />

      <UnauthenticatedRoute
        path="/oauth/:lang/authenticate/:dev"
        exact
        props={childProps}
        component={AsyncAuthenticate}
      />

      <UnauthenticatedRoute
        path="/oauth/:lang/authenticate/send_notice/:articleId/:dev"
        exact
        props={childProps}
        component={AsyncAuthenticate}
      />

      <UnauthenticatedRoute
        path="/:lang"
        exact
        props={childProps}
        component={AsyncWelcome}
      />

      <UnauthenticatedRoute
        path="/login/:lang"
        exact
        props={childProps}
        component={AsyncLogin}
      />

      <UnauthenticatedRoute
        path="/Melilogin/:lang"
        exact
        props={childProps}
        component={AsyncMeliLogin}
      />

      <UnauthenticatedRoute
        path="/nobpp/:lang/:site"
        exact
        props={childProps}
        component={AsyncNoBPP}
      />

      {/* Finally, catch all unmatched routes */}
      <Route render={() => <AsyncNotFound {...childProps} />} />
    </Switch>

);
