/* eslint-disable max-len */
import {
  FETCH_PENDING_CASES_LIST,
  FETCH_PENDING_CASES_LIST_ERROR,
  FETCH_PENDING_CASES_LIST_FULLFILLED,

  FETCH_PENDING_CASES_TO_EXPIRE,
  FETCH_PENDING_CASES_TO_EXPIRE_FULLFILLED,
  FETCH_PENDING_CASES_TO_EXPIRE_ERROR,
} from "redux/actions/pendingCasesList";
import { RESPOND_COUNTER_NOTICE_SUCCESS } from "redux/actions/caseManagement";
/* Bussines logic */
import {
  fetchPendingCasesListError,
  respondCounterNoticeSuccess,
} from 'redux/reducers/utils/pendingCasesList';


const initialState = {
  isLoading: false,
  pendingCasesList: [],
  pendingCasesToExpire: 0,
  isLoadingPendingCasesToExpire: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
  case FETCH_PENDING_CASES_LIST:
    return {
      ...state,
      isLoading: true,
      isLoadingPendingCasesToExpire: true,
      pendingCasesList: action.payload.from ?
        state.pendingCasesList : [],
    };

  case FETCH_PENDING_CASES_LIST_FULLFILLED:
    return {
      ...state,
      ...action.payload,
      pendingCasesList: [
        ...state.pendingCasesList,
        ...action.payload.pendingCasesList,
      ],
      isLoading: false,
    };

  case FETCH_PENDING_CASES_LIST_ERROR:
    return fetchPendingCasesListError(
      state,
      action.payload
    );

  case FETCH_PENDING_CASES_TO_EXPIRE: 
    return {
      ...state,
      isLoadingPendingCasesToExpire: true
    };

  case FETCH_PENDING_CASES_TO_EXPIRE_FULLFILLED:
    return {
      ...state,
      ...action.payload,
      isLoadingPendingCasesToExpire: false
    };

  case FETCH_PENDING_CASES_TO_EXPIRE_ERROR: 
    return {
      ...state,
      isLoadingPendingCasesToExpire: false
    };
  
  case RESPOND_COUNTER_NOTICE_SUCCESS:
    return respondCounterNoticeSuccess(
      state,
      action.payload
    );
   
  default:
    return state;
  }
}; 
