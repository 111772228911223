import { articleFieldsCatalogo } from "../fragments";


export default `
  ${articleFieldsCatalogo}
  
  query($articleId: String!) {
    articleCatalogo(articleId: $articleId) {
      ...articleFieldsCatalogo
    }
  }
`;