import React from "react";
import { faSync } from "@fortawesome/free-solid-svg-icons";
import Icon from "components/Icon";
import { t } from "utils";

const RefreshButton = props => {
  return (
    <p className="refres-btn-container">
      <span
        className="btn"
        style={{
          padding: "20px",
          fontSize: "20px"
        }}
        onClick={props.onClick}
      >
        <Icon icon={faSync} />&nbsp;
        {t("try_again")}
      </span>
    </p>
  );
};

export default RefreshButton;
