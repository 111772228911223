export default `
  mutation(
    $sellerId: String!, 
    $from: String, 
    $filters: String,
    $sellerStatus: String
  ) {
    deleteSellerFromDB(
      sellerId: $sellerId, 
      from: $from, 
      filters: $filters,
      sellerStatus: $sellerStatus
    ) {
      from
      deletedId
      seller {
        id 
        amount
        siteId
        nickname
        countryName
      }
    }
  }
`;