import React, { useState } from "react";
import ReactGA from "react-ga";
import { connect } from "react-redux";
import { 
  fetchSeller,
  setWhiteSeller,
  setBlackSeller,
  unsetWhiteSeller,
  unsetBlackSeller,
} from "redux/actions/sellers";
import { createSellersLiveSearch } from "redux/actions/global";
import { fetchLiveSearches } from "redux/actions/liveSearches";
import SellerScore from "components/SellerScore";
import { t, get, sellerLocation } from "utils";
import Tooltip from "components/Tooltip";

function SellerInformation(props) {
  const { 
    seller,
    siteId,
    sellerId,
    liveSearches,
    setWhiteSeller,
    setBlackSeller,
    unsetWhiteSeller,
    unsetBlackSeller,
    creatingSellersLS,
    updatingSellerStatus,
    createSellersLiveSearch,
  } = props;

  const isBlackSeller = get(seller, 'blackSeller', false);
  const isWhiteSeller = get(seller, 'whiteSeller', false);
  const [tooltip1, setTooltip1] = useState();
  const [tooltip2, setTooltip2] = useState();
  const [tooltip3, setTooltip3] = useState();

  React.useEffect(() => {
    if (!props.seller) {
      props.fetchSeller({ siteId, sellerId });
    }
    if (!props.liveSearches) {
      props.fetchLiveSearches();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleClickActionBlackSeller = React.useCallback(() => {
    if (seller && !updatingSellerStatus) {
      if (isBlackSeller) {
        unsetBlackSeller({ sellerId });
      } else {
        ReactGA.ga('send', 'event', 'SELLER', 'SUSPICIOUS_SELLER');
        setBlackSeller({ siteId, sellerId });
      }
    }
  }, [
    seller,
    siteId,
    sellerId,
    isBlackSeller,
    setBlackSeller,
    unsetBlackSeller,
    updatingSellerStatus
  ]);

  const handleClickActionWhiteSeller = React.useCallback(() => {
    if (seller && !updatingSellerStatus) {
      if (isWhiteSeller) {
        unsetWhiteSeller({ sellerId });
      } else {
        ReactGA.ga('send', 'event', 'SELLER', 'RELIABLE_SELLER');
        setWhiteSeller({ sellerId, siteId });
      }
    }
  }, [
    seller, 
    siteId, 
    sellerId, 
    isWhiteSeller, 
    setWhiteSeller, 
    unsetWhiteSeller, 
    updatingSellerStatus
  ]);

  const repeatedLivesearch = React.useMemo(() => {
    return (
      seller
      && liveSearches 
      && liveSearches
        .filter(ls => !!ls.sellers)
        .find(ls => ls.sellers
          .find(s => s.nickname === seller.nickname)
        )
    );
  }, [seller, liveSearches]);

  const canCreateSellerSearch = (
    seller &&
    seller.id &&
    seller.siteId &&
    seller.nickname &&
    // seller.amount > 1 &&
    // TO DO: erase when 'searched' is ready
    !seller.searched &&
    !creatingSellersLS  &&
    !repeatedLivesearch
  );

  const handleCreateSellerLiveSearch = () => {    
    if (canCreateSellerSearch) {
      ReactGA.ga('send', 'event', 'SELLER', 'CREATE_LIVESEARCH');
      createSellersLiveSearch([{
        id: sellerId,
        siteId: siteId,
        amount: seller.amount,
        nickname: seller.nickname,
      }]);
    }
  };

  if (!seller) return null;
  
  const imageLivesearch = repeatedLivesearch ?
    "search-active.svg" :
    "search-normal.svg";

  const imageUserPlus = isBlackSeller ? 
    "user-plus-active.svg" :
    "user-plus-normal.svg";
  
  const imageUserChecked = isWhiteSeller ? 
    "user-checked-active.svg" :
    "user-checked-normal.svg"; 

  return (
    <section className="main-section section-seller__info">
      {/* {
        isBlackSeller && (
          <div
            title={t('black_seller')}
            className="seller-flag seller-blacklist-flag"
          >
          </div>
        )
      }
      {
        isWhiteSeller && (
          <div
            title={t('seller_white')}
            className="seller-flag seller-whitelist-flag"
          >
          </div>
        )
      } */}
      <div className="seller-info__content">

        <div className="seller-info">
          <img alt="avatar" src="/images/avatar.svg" />
          
          <div className="seller__nickname">{seller.nickname}</div>
          
          <span className="seller__location">
            {sellerLocation(seller, t('no_data'))}
          </span>
          
          <SellerScore seller={seller} />
        </div>
        
        <div className="seller--actions">
          <div
            className="tooltip-wrapper"
            onClick={handleCreateSellerLiveSearch} 
            onMouseEnter={e => {
              e.stopPropagation();
              tooltip1 &&
              tooltip1.open(e.target);
            }}
            onMouseLeave={e => {
              e.stopPropagation();
              tooltip1 &&
              tooltip1.close(e.target);
            }}
          >
            <img alt="livesearch" src={`/images/${imageLivesearch}`} />
            <Tooltip
              timeOutToOpen={0}
              timeOutToClose={100}
              ref={node => {
                setTooltip1(tooltip1 || node);
              }}
            >
              {t('seller_search_create')}
            </Tooltip>
          </div>
          <div
            className="tooltip-wrapper"
            onClick={handleClickActionWhiteSeller} 
            onMouseEnter={e => {
              e.stopPropagation();
              tooltip2 &&
              tooltip2.open(e.target);
            }}
            onMouseLeave={e => {
              e.stopPropagation();
              tooltip2 &&
              tooltip2.close(e.target);
            }}
          >
            <img 
              alt="user-checked" 
              className="user-checked-img"
              src={`/images/${imageUserChecked}`} 
            />
            <Tooltip
              timeOutToOpen={0}
              timeOutToClose={100}
              ref={node => {
                setTooltip2(tooltip2 || node);
              }}
            >
              {t('seller_white')}
            </Tooltip>
          </div>       
          <div
            className="tooltip-wrapper"
            onClick={handleClickActionBlackSeller}
            onMouseEnter={e => {
              e.stopPropagation();
              tooltip3 &&
              tooltip3.open(e.target);
            }}
            onMouseLeave={e => {
              e.stopPropagation();
              tooltip3 &&
              tooltip3.close(e.target);
            }}
          >
            <img alt="user-add" src={`/images/${imageUserPlus}`} />              
            <Tooltip
              timeOutToOpen={0}
              timeOutToClose={100}
              ref={node => {
                setTooltip3(tooltip3 || node);
              }}
            >
              {t('seller_blacklist')}
            </Tooltip>
          </div>
        </div>
      </div>
    </section>
  );
}

const mapStateToProps = (state, ownProps) => ({
  lang: state.global.lang,
  liveSearches: state.liveSearches.liveSearches,
  creatingSellersLS: state.global.creatingSellersLS,
  seller: state.sellers.sellersMap[ownProps.sellerId],
  updatingSellerStatus: state.detail.updatingSellerStatus,
});

const actions = {
  fetchSeller,
  setWhiteSeller,
  setBlackSeller,
  unsetWhiteSeller,
  unsetBlackSeller,
  fetchLiveSearches,
  createSellersLiveSearch,
};

export default connect(mapStateToProps, actions)(SellerInformation);
