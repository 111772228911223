import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { closeModal } from "redux/actions/global";
import ArticleImages from "components/Search/Article/ArticleImages/ArticleImages";


class ArticleImagesModal extends Component {
 
  constructor(props) {
    super(props);

    const { images } = props;
   
    this.state = {
      images
    };
  }

  componentDidUpdate() {
      // this.setState({ 
      //   size: 5,
      //   from: 0,
      //   articleId: this.props.articleId
      // }, this.fetch);
  }

  render() {

    const { closeModal } = this.props;
    const articleImages = this.state.images;
  
    return (
      <div className="panel images">
        <div className="container">
          <i className="modal-close" style={{marginRight: '24px'}} onClick={closeModal} />
          <ArticleImages
            // alt={this.state.data.articleTitle}
            images={articleImages}
            openModalOnClick={false}
            modal={true}
            // thumbnail={article.thumbnail} 
          />
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  profile: state.global.profile,
});

const actions = {
  closeModal,
};

export default withRouter(connect(mapStateToProps, actions)(ArticleImagesModal));