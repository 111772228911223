import React, { Component } from "react";
import { connect } from "react-redux";
import moment from "moment";
import { Scrollbars } from "react-custom-scrollbars";
import SellerDenounceLoader from "components/SellerDenouncesTable/Loader";
import { fetchSellerCounterNotices } from "redux/actions/caseManagement";
import { 
  t,
  getArticleStatusForCM, 
  getDenounceStatusLabel,
  getDenounceStatusTitle,
} from "utils";


class SellerDenouncesTable extends Component {
  constructor(props) {
    super(props);

    this.state = {
      from: 0,
      size: 5,
      sellerId: props.seller.id,
    };
  }

  componentDidMount() {
    if (
      !this.props.counterNotices.length || (
        this.props.seller.id !== this.state.sellerId
      )
    ) {
      this.fetch();
    }
  }

  fetch() {
    this.props.fetchSellerCounterNotices({ ...this.state });
  }

  fetchMore = () => {
    this.setState({ from: this.state.from + this.state.size }, this.fetch);
  }

  render() {
    const {
      isLoading,
      counterNotices,
      counterNoticesTotal,
    } = this.props;
    const DATE_FORMAT = this.props.lang === "en" ?
      "MM.DD.YYYY" :
      "DD.MM.YYYY";

    const hasMore = counterNotices.length < counterNoticesTotal;
    const localePath = 'sellers-history-modal.table';

    return (
      <Scrollbars
        autoHeight
        autoHeightMax={250}
        className="seller-denounces">
        <table className="table">
          <thead>
            <tr>
              <th>
                {t(`${localePath}.th1`)}
              </th>
              <th>
                {t(`${localePath}.th2`)}
              </th>
              <th>
                {t(`${localePath}.th3`)}
              </th>
              <th>
                {t(`${localePath}.th4`)}
              </th>
            </tr>
          </thead>
          <tbody>
            {this.props.counterNotices.length ?
              this.props.counterNotices.map((item, i) => (
                <tr key={i}>
                  <td>{item.articleTitle}</td>
                  <td>
                    {moment(item.denounceDate).format(DATE_FORMAT)}
                  </td>
                  <td>{item.denounceReasonText}</td>
                  <td title={getDenounceStatusTitle(item.status)}>
                    {getDenounceStatusLabel(item.status)}
                  </td>
                </tr>
              )) :
              !isLoading &&
                <tr>
                  <td colSpan="5">
                    {t('case_management.case_description.no_data_found')}
                  </td>
                </tr>}
          </tbody>
        </table>
        {isLoading ? 
          <SellerDenounceLoader /> :
          hasMore &&
            <span
              className="view-more"
              onClick={this.fetchMore}>
              [{t('view_more')}]
            </span>}
      </Scrollbars>
    );
  }
}

const mapStateToProps = state => ({
  lang: state.global.lang,
  isLoading: state.caseManagement.isLoadingCounterNotices,
  counterNotices: state.caseManagement.sellerCounterNotices,
  counterNoticesTotal: state.caseManagement.sellerCounterNoticesTotal,
});

export default connect(
  mapStateToProps,
  { fetchSellerCounterNotices }
)(SellerDenouncesTable);
