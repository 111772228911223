import { 
  FETCH_WHITE_LIST_SELLERS,
  FETCH_WHITE_LIST_SELLERS_FULLFILLED,
  FETCH_WHITE_LIST_SELLERS_ERROR,

  DELETE_WHITE_LIST_SELLERS,
  DELETE_WHITE_LIST_SELLERS_SUCCESS,
  DELETE_WHITE_LIST_SELLERS_ERROR,
} from "redux/actions/whiteListSellers";
/* Bussines logic */
import { 
  fetchWhiteListSellersError,
  deleteWhiteListSellersError,
  deleteWhiteListSellersSuccess,
  deleteWhiteListSellersOptimistic,
} from 'redux/reducers/utils/whiteListSellers';


const initialState = {
  isLoading: false,
  whiteListSellers: [],
  whiteListSellersTotal: 0,
  whiteListSellersFrom: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
  case FETCH_WHITE_LIST_SELLERS:
    return {
      ...state,
      isLoading: true,
      whiteListSellers: action.payload.from ? 
        state.whiteListSellers : [],
    };

  case FETCH_WHITE_LIST_SELLERS_FULLFILLED:
    return {
      ...state,
      ...action.payload,
      whiteListSellers: [
        ...state.whiteListSellers,
        ...action.payload.whiteListSellers,
      ],
      isLoading: false,
    };

  case FETCH_WHITE_LIST_SELLERS_ERROR:
    return fetchWhiteListSellersError(
      state,
      action.payload
    );

  case DELETE_WHITE_LIST_SELLERS:
    return deleteWhiteListSellersOptimistic(
      state,
      action.payload
    );

  case DELETE_WHITE_LIST_SELLERS_SUCCESS:
    return deleteWhiteListSellersSuccess(
      state, 
      action.payload
    );

  case DELETE_WHITE_LIST_SELLERS_ERROR:
    return deleteWhiteListSellersError(
      state, 
      action.payload
    );

  default:
    return state;
  }
}; 
