import AWS from "aws-sdk";
import { CognitoUserPool } from "amazon-cognito-identity-js";
import { graphqlErrors } from 'redux/actions/global';
import { store } from '../index';

const cognitoidentityserviceprovider = new AWS.CognitoIdentityServiceProvider({ 
  region: 'us-east-1',
  apiVersion: '2016-04-18',
});

/* eslint-disable no-console, no-undef */
export async function invokeApigUnsigned({
  path,
  method = "GET",
  headers = {},
  queryParams = {},
  body
}) {
  body = body ? JSON.stringify(body) : body;

  const url = new URL(process.env.REACT_APP_API_GATEWAY_URL + path);

  Object.keys(queryParams)
    .forEach(key =>
      url.searchParams.append(key, queryParams[key])
    );

  const results = await fetch(url, { method, headers, body });

  if((results.status === 200)||(results.status === 401)){
    return results.json();
  }else{
    throw new Error(await results.text());
  } 
}

export async function invokeApigWithToken({
  path,
  method = "GET",
  headers = {},
  queryParams = {},
  ownApi = true,
  body = null,
}) {
  const user = getCurrentUser();

  if (user === null) {
    throw new Error("User is not logged in");
  }

  console.log("useruser",user)

  const token = await getUserToken(user);
  
  console.log("tokentoken",token)

  const target = new URL(
    ownApi ? process.env.REACT_APP_API_GATEWAY_URL + path : path
  );

  Object.keys(queryParams)
    .forEach(key =>
      target.searchParams.append(key, queryParams[key])
    );

  return fetch(target, {
    method,
    mode: 'cors',
    body: body ? JSON.stringify(body) : null,
    headers: new Headers({
      'Accept': 'application/json',
      'Authorization': token,
      ...headers
    })
  }).then(response => {
    if (response.status === 200) { return response.json(); }
    console.log(response);
    throw new Error(`Bad status code ${response.status}`);
  }).then(respObject => {
    return respObject;
  }).catch(err => {
    console.log('Sad days: ' + err);
  });
}

/**
 * 
 * @param {string} query 
 * @param {Object} variables 
 */
export async function gqlQuery(query, variables = {}) {
  const user = getCurrentUser();
  const MeliToken = localStorage.getItem("TokenMeli");
  if (user === null) {
    throw new Error("User is not logged in");
  }

  const token = await getUserToken(user);
  const target = `${process.env.REACT_APP_API_GATEWAY_URL}gql`;

  return fetch(target, {
    method: 'post',
    mode: 'cors',
    body: JSON.stringify({ query, variables }),
    headers: new Headers({
      'Accept': 'application/json',
      'Authorization': token,
      'X-Api-Key':MeliToken
    })
  }).then(response => {
    if (response.status === 200) { return response.json(); }
    console.log(response);
    throw new Error(`Bad status code ${response.status}`);
  }).then(respObject => {
    if (respObject.errors && respObject.errors.length) {
      store.dispatch(graphqlErrors(respObject.errors));
    }

    if (!respObject.data[Object.keys(respObject.data)[0]]) {
      if (respObject.errors && respObject.errors.length) {
        throw new Error(respObject.errors[0].message);
      }
      // else {
      //   throw new Error('Server error.');
      // }
    }

    return respObject.data;
  });
}

export async function authUser() {
  if (
    AWS.config.credentials &&
    Date.now() < AWS.config.credentials.expireTime - 60000
  ) {
    return true;
  }

  const currentUser = getCurrentUser();

  if (currentUser === null) {
    return false;
  }

  return true;
}

export function signOutUser() {
  const currentUser = getCurrentUser();

  if (currentUser !== null) {
    currentUser.signOut();
  }

  if (AWS.config.credentials) {
    AWS.config.credentials.clearCachedId();
    AWS.config.credentials = new AWS.CognitoIdentityCredentials({});
  }
}

function getUserToken(currentUser) {
  return new Promise((resolve, reject) => {
    currentUser.getSession(function(err, session) {
      if (err) {
        reject(err);
        return;
      }
      resolve(session.getIdToken().getJwtToken());
    });
  });
}

function getUserAccessToken(currentUser) {
  return new Promise((resolve, reject) => {
    currentUser.getSession(function(err, session) {
      if (err) {
        reject(err);
        return;
      }
      resolve(session.getAccessToken().getJwtToken());
    });
  });
}

export function getCurrentUser() {
  const userPool = new CognitoUserPool({
    UserPoolId: process.env.REACT_APP_COGNITO_USER_POOL_ID,
    ClientId: process.env.REACT_APP_COGNITO_APP_CLIENT_ID
  });
  
  return userPool.getCurrentUser();
}

export async function getCurrentUserAttributes() {
  const user = getCurrentUser();

  if (user === null) {
    throw new Error("User is not logged in");
  }

  const AccessToken = await getUserAccessToken(user);
  
  return new Promise((resolve, reject) => {
    cognitoidentityserviceprovider.getUser(
      { AccessToken },
      function(err, data) {
        if (err) {
          reject(err);
          return;
        }
        resolve(data);
      }
    );
  });
}
