import { from } from 'rxjs';
import { ofType } from 'redux-observable';
import { mergeMap } from 'rxjs/operators';
import fx from "money";
import { parseProfile, isDenounceWithImages } from "utils";
import { gqlQuery } from "libs/awsLib";
import { 
  FETCH_PROFILE,
  profileFulfilled, 
  fetchProfileError,

  FETCH_PROFILE_ALLOWED_NOTICES,
  fetchProfileAllowedNoticesSuccess,
  fetchProfileAllowedNoticesError,

  FETCH_MEMBER_RESTRICTION,
  fetchMemberRestrictionSuccess,
  fetchMemberRestrictionError,

  FETCH_EFFICIENCY,
  fetchEfficiencySuccess,
  fetchEfficiencyError,
  
  FETCH_CURRENCY,
  currencyFulfilled,
  fetchCurrencyError, 

  UPDATE_PROFILE,
  updateProfileSuccess,
  updateProfileError,
  
  UPDATE_PROFILE_SETTINGS,
  
  CREATE_SELLERS_LIVE_SEARCH,
  createSellersLiveSearchSuccess,
  createSellersLiveSearchError,
  
  FETCH_SITES,
  fetchSitesFullFilled,
  fetchSitesError,  
  
  FETCH_PROFILES,
  fetchProfilesSuccess,
  fetchProfilesError,

  CREATE_PROFILE,
  createProfileSuccess,
  createProfileError,

  CLOSE_HELP,
  closeHelpSuccess,
  closeHelpError,

  GET_CREDENTIALS,
  getCredentialsSuccess,
  getCredentialsError,

  SEND_MESSAGE_TO_SUPPORT,
  sendMessageToSupportSuccess,
  sendMessageToSupportError,

  FETCH_MELI_ACCOUNT,
  fetchMeliAccountFulfilled,
  fetchMeliAccountError,

  UPDATE_MELI_ACCOUNT,
  updateMeliAccountSuccess,
  updateMeliAccountError,

  SET_ARTICLES_PINNED,
  setArticlesPinnedSuccess,
  setArticlesPinnedError,

  SET_TRANSLATE_TOOLTIP_ALREADY_SHOWN,
  setTranslateTooltipAlreadyShownSuccess,
  setTranslateTooltipAlreadyShownError,
  
  SET_NEW_REASON_TOOLTIP_ALREADY_SHOWN,
  setNewReasonTooltipAlreadyShownSuccess,
  setNewReasonTooltipAlreadyShownError,

  SET_DISCARD_TOOLTIP_ALREADY_SHOWN,
  setDiscardTooltipAlreadyShownSuccess,
  setDiscardTooltipAlreadyShownError,

  FETCH_MENU,
  fetchMenuSuccess,
  fetchMenuError,

  FETCH_BLINDAGES,
  fetchBlindagesSuccess,
  fetchBlindagesError,
} from "redux/actions/global";
import {
  REPORT_ARTICLES_SUCCESS
} from "redux/actions/detail";

import { 
  querySites,
  queryProfile, 
  queryProfiles,
  mutationProfile, 
  queryMeliAccount,
  mutationCreateProfile,
  queryRatesConvertions,
  mutationGetCredentials,
  queryMemberRestriction,
  mutationSellersLiveSearch,
  queryProfileAllowedNotices,
  mutationSendMessageToSupport,
  mutationUpdateProfileAccount,
  queryEfficiency,
  queryMenu,
  queryBlindages,
} from "gql/index";


/* EPICS */
export const fetchProfileEpic = action$ => action$.pipe(
  ofType(FETCH_PROFILE),
  mergeMap(() => 
    from(
      gqlQuery(queryProfile)
        .then(profileFulfilled)
        .catch(fetchProfileError)
    )
  )
);

export const createProfileEpic = action$ => action$.pipe(
  ofType(CREATE_PROFILE),
  mergeMap(action =>
    from(
      gqlQuery(mutationCreateProfile, { ...action.payload })
        .then(createProfileSuccess)
        .catch(createProfileError)
    )
  )
);

export const fetchProfilesEpic = action$ => action$.pipe(
  ofType(FETCH_PROFILES),
  mergeMap(() =>
    from(
      gqlQuery(queryProfiles)
        .then(fetchProfilesSuccess)
        .catch(fetchProfilesError)
    )
  )
);

export const fetchMemberRestrictionEpic = action$ => action$.pipe(
  ofType(FETCH_MEMBER_RESTRICTION),
  mergeMap(() =>
    from(
      gqlQuery(queryMemberRestriction)
        .then(response => {return response.memberRestriction;})
        .then(fetchMemberRestrictionSuccess)
        .catch(fetchMemberRestrictionError)
    )
  )
);

export const fetchEfficiencyEpic = action$ => action$.pipe(
  ofType(FETCH_EFFICIENCY),
  mergeMap(() =>
    from(
      gqlQuery(queryEfficiency)
        .then(response => {
                 return response;})

        .then(fetchEfficiencySuccess)
        .catch(fetchEfficiencyError)
    )
  )
);

export const getCredentialsEpic = action$ => action$.pipe(
  ofType(GET_CREDENTIALS),
  mergeMap(action =>
    from(
      gqlQuery(mutationGetCredentials, { userId: action.payload })
        .then(response => getCredentialsSuccess(response.getCredentials))
        .catch(getCredentialsError)
    )
  )
);

export const fetchProfileAllowedNoticesEpic = action$ => action$.pipe(
  ofType(FETCH_PROFILE_ALLOWED_NOTICES),
  mergeMap(() =>
    from(
      gqlQuery(queryProfileAllowedNotices)
        .then(response => {
          return {
            profile: {
              ...response.profile,
              hasOnlyImageNotices: response.profile.allowedNotices
                .slice(0)
                .reduce((prev, curr, _i, arr) => {
                  if (!prev) arr.splice(1); // break reduce when prev is false

                  const imageNotices = curr.notices
                    .map(n => n.id)
                    .filter(isDenounceWithImages);
                  
                  if (imageNotices.length !== curr.notices.length) return false;
                  
                  return prev;
                }, true)
            }
          };
        })
        .then(fetchProfileAllowedNoticesSuccess)
        .catch(fetchProfileAllowedNoticesError)
    )
  )
);

export const updateProfileEpic = action$ => action$.pipe(
  ofType(UPDATE_PROFILE),
  mergeMap(action =>
    from(
      gqlQuery(mutationProfile, parseProfile(action.payload))
        .then(updateProfileSuccess)
        .catch(updateProfileError)
    )
  )
);

export const updateProfileSettingsEpic = (action$, store) => action$.pipe(
  ofType(UPDATE_PROFILE_SETTINGS),
  mergeMap(action => {
    const state = store.value.global;
    const profile = state.profile;
    profile.settings = {
      ...(profile.settings || {}),
      ...action.payload
    };

    return from(
      gqlQuery(mutationProfile, parseProfile(profile))
        .then(() => ({ type: '' }))
        .catch(updateProfileError)
    );
  })
);


export const fetchCurrencyEpic = action$ => action$.pipe(
  ofType(FETCH_CURRENCY),
  mergeMap(() =>
    from(
      gqlQuery(queryRatesConvertions)
        .then(res => {
          fx.base = res.ratesConvertions.base || "USD";
          fx.rates = res.ratesConvertions.rates;
          return currencyFulfilled({ 
            rates: res.ratesConvertions.rates 
          });
        })
        .catch(fetchCurrencyError)
    )
  )
);

export const createSellersLiveSearchEpic = action$ => action$.pipe(
  ofType(CREATE_SELLERS_LIVE_SEARCH),
  mergeMap(({ payload }) =>
    from(
      gqlQuery(mutationSellersLiveSearch, { sellers: payload })
        .then(createSellersLiveSearchSuccess)
        .catch(createSellersLiveSearchError)
    )
  )
);

export const fetchSitesEpic = action$ => action$.pipe(
  ofType(FETCH_SITES),
  mergeMap(() =>
    from(
      gqlQuery(querySites)
        .then(response => {
          response.sites.forEach(site => {
            site.countrySites.sort((a, b) => {
              if (a.country.name < b.country.name) return -1;
              if (a.country.name > b.country.name) return 1;
              return 0;
            });
          });
          return fetchSitesFullFilled(response);
        })
        .catch(fetchSitesError)
    )
  )
);

export const closeHelpEpic = (action$, store) => action$.pipe(
  ofType(
    CLOSE_HELP,
    REPORT_ARTICLES_SUCCESS,
  ),
  mergeMap(action => {
    const state = store.value.global;
    const profile = { ...state.profile };
    const helpType = action.type === CLOSE_HELP ?
      action.payload :
      action.payload.isImport ? 'didReportExcel' : 'didReport';
    const updateProfile = {
      [helpType]: true,
      lang: profile.lang,
      currency: profile.currency,
      givenName: profile.givenName,
    };

    return from(
      gqlQuery(mutationProfile, updateProfile)
        .then(closeHelpSuccess)
        .catch(() => closeHelpError(helpType))
    );
  })
);

export const sendMessageToSupportEpic = action$ => action$.pipe(
  ofType(SEND_MESSAGE_TO_SUPPORT),
  mergeMap(({ payload }) =>
    from(
      gqlQuery(mutationSendMessageToSupport, payload)
        .then(sendMessageToSupportSuccess)
        .catch(sendMessageToSupportError)
    )
  )
);

export const fetchMeliAccountEpic = action$ => action$.pipe(
  ofType(FETCH_MELI_ACCOUNT),
  mergeMap(() =>
    from(
      gqlQuery(queryMeliAccount)
        .then(response => response.profile.meliAccount)
        .then(fetchMeliAccountFulfilled)
        .catch(fetchMeliAccountError)
    )
  )
);

export const updateMeliAccountEpic = action$ => action$.pipe(
  ofType(UPDATE_MELI_ACCOUNT),
  mergeMap(({ payload }) =>
    from(
      gqlQuery(mutationUpdateProfileAccount, payload)
        .then(response => response.updateProfileAccount)
        .then(updateMeliAccountSuccess)
        .catch(updateMeliAccountError)
    )
  )
);

export const setArticlesPinnedEpic = (action$, store) => action$.pipe(
  ofType(SET_ARTICLES_PINNED),
  mergeMap(({ payload }) => {
    const state = store.value.global;
    const profile = { ...state.profile };
    const updateProfile = {
      articlesPinned: payload || [],
      lang: profile.lang,
      currency: profile.currency,
      givenName: profile.givenName,
    };

    return from(
      gqlQuery(mutationProfile, updateProfile)
        .then(setArticlesPinnedSuccess)
        .catch(setArticlesPinnedError)
    );
  })
);

export const setTranslateTooltipAlreadyShown = (action$, store) => action$.pipe(
  ofType(SET_TRANSLATE_TOOLTIP_ALREADY_SHOWN),
  mergeMap(({ payload }) => {
    const state = store.value.global;
    const profile = { ...state.profile };
    const updateProfile = {
      translateTooltipAlreadyShown: payload || true,
      lang: profile.lang,
      currency: profile.currency,
      givenName: profile.givenName,
    };

    return from(
      gqlQuery(mutationProfile, updateProfile)
        .then(setTranslateTooltipAlreadyShownSuccess)
        .catch(setTranslateTooltipAlreadyShownError)
    );
  })
);

export const setNewReasonTooltipAlreadyShown = (action$, store) => action$.pipe(
  ofType(SET_NEW_REASON_TOOLTIP_ALREADY_SHOWN),
  mergeMap(({ payload }) => {
    const state = store.value.global;
    const profile = { ...state.profile };
    const updateProfile = {
      newReasonTooltipAlreadyShown: payload || true,
      lang: profile.lang,
      currency: profile.currency,
      givenName: profile.givenName,
    };

    return from(
      gqlQuery(mutationProfile, updateProfile)
        .then(setNewReasonTooltipAlreadyShownSuccess)
        .catch(setNewReasonTooltipAlreadyShownError)
    );
  })
);

export const setDiscardTooltipAlreadyShown = (action$, store) => action$.pipe(
  ofType(SET_DISCARD_TOOLTIP_ALREADY_SHOWN),
  mergeMap(({ payload }) => {
    const state = store.value.global;
    const profile = { ...state.profile };
    const updateProfile = {
      discardTooltipAlreadyShown: payload || true,
      lang: profile.lang,
      currency: profile.currency,
      givenName: profile.givenName,
    };

    return from(
      gqlQuery(mutationProfile, updateProfile)
        .then(setDiscardTooltipAlreadyShownSuccess)
        .catch(setDiscardTooltipAlreadyShownError)
    );
  })
);

export const fetchMenuEpic = action$ => action$.pipe(
  ofType(FETCH_MENU),
  mergeMap(() =>
    from(
      gqlQuery(queryMenu)
        .then(response => {
          return response.menu;})

        .then(fetchMenuSuccess)
        .catch(fetchMenuError)
    )
  )
);

export const fetchBlindagesEpic = action$ => action$.pipe(
  ofType(FETCH_BLINDAGES),
  mergeMap(({payload}) =>
    from(      
      gqlQuery(queryBlindages, { itemsIds: payload })      
        .then(response => {
          return response;})
        .then(fetchBlindagesSuccess)
        .catch(fetchBlindagesError)
    )
  )
);
