export default `
  fragment siteFields on SiteType {
    id
    countrySites {
      id
      count
      active
      filtered
      country {
        code
        name
      }
    }
  }
`;