export default `
  query(
    $itemId: String!,
    $rating: String,
    $from: Int!,
    $size: Int!
  ) {
    reviews(
      itemId: $itemId, 
      rating: $rating, 
      from: $from,
      size: $size
    ) {
      reviews {
        id
        reviewable_object {
          id
          type
        }
        date_created
        status
        title
        content
        rate
        valorization
        likes
        dislikes
        reviewer_id
        buying_date,
        relevance,
        forbidden_words,
      }
      rating_average,
      rating_levels {
        one_star
        two_star
        three_star
        four_star
        five_star
      }
      paging {
        total
        limit
        offset
      }
      hasMore,
      hasPositive,
      hasNegative
    }
  }
`;