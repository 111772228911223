import React, { Component } from "react";
import { connect } from "react-redux";
import classnames from "classnames";
import {
  createProfile,
  switchToCreateProfile,
} from "redux/actions/global";
import { Select, Option } from "components/Select";
import { t, getValueFromNativeEvent } from "utils";
import locales from "locales";
import CreateProfileLoader from "./Loader";


class CreateProfile extends Component {
  constructor(props) {
    super(props);

    this.state = {
      lang: props.profile.lang || "en",
      currency: props.profile.currency || "USD",
      givenName: "",
      familyName: "",
    };
  }

  componentWillUnmount() {
    this.props.switchToCreateProfile(false);
  }

  handleChangeSelect = name => value => this.setState({ [name]: value });

  handleChange = e => {
    e && e.persist();
    const value = getValueFromNativeEvent(e);

    this.setState({ ...value });
  }

  handleSubmit = e => {
    e.preventDefault();

    this.props.createProfile({
      lang: this.state.lang,
      currency: this.state.currency,
      givenName: this.state.givenName,
      familyName: this.state.familyName,
    });
  }

  validateForm() {
    return (
      this.state.givenName &&
      this.state.givenName.length > 0 &&
      this.state.familyName &&
      this.state.familyName.length > 0
    );
  }

  render() {
    const {
      rates,
      profile,
      isCreatingProfile,
      switchToCreateProfile,
    } = this.props;
    const {
      givenName,
      familyName,
    } = this.state;

    const attributesLocale = 'activerecord.attributes.user';

    return (
      isCreatingProfile ?
        <CreateProfileLoader /> :
        <div className="create-profile-container">
          <form onSubmit={this.handleSubmit}>
            <div className="col-lg-12 col-md-12 col-sm-12">
              <div className="form-group">
                <label
                  htmlFor="givenName"
                  className={classnames(
                    "align-margin",
                    "control-label",
                    "col-lg-2 col-md-2 col-sm-2",
                  )}>
                  {t('name')}
                </label>
                <div
                  className="col-lg-10 col-md-10 col-sm-10">
                  <input
                    size="30"
                    type="text"
                    maxLength={30}
                    name="givenName"
                    value={givenName}
                    placeholder={t('name')}
                    onChange={this.handleChange}
                    className="form-control space-bottom" />
                </div>
              </div>

              <div className="form-group space-bottom">
                <label
                  htmlFor="familyName"
                  className={classnames(
                    "align-margin",
                    "control-label",
                    "col-lg-2 col-md-2 col-sm-2",
                  )}>
                  {t('last_name')}
                </label>
                <div
                  className="col-lg-10 col-md-10 col-sm-10">
                  <input
                    size="30"
                    type="text"
                    maxLength={30}
                    name="familyName"
                    value={familyName}
                    placeholder={t('last_name')}
                    onChange={this.handleChange}
                    className="form-control space-bottom" />
                </div>
              </div>

              <div className="form-group space-bottom">
                <label
                  htmlFor="locale"
                  className={classnames(
                    "align-margin",
                    "control-label",
                    "col-lg-2 col-md-2 col-sm-2",
                  )}>
                  {t(`${attributesLocale}.locale`)}
                </label>
                <div className="col-lg-10 col-md-10 col-sm-10 space-bottom">
                  <Select
                    name="lang"
                    value={profile.lang}
                    onChange={this.handleChangeSelect('lang')}
                  >
                    {Object.keys(locales).map(lang =>
                      <Option
                        key={lang}
                        value={lang}>
                        {t(`languages.${lang.toLowerCase()}`)}
                      </Option>)}
                  </Select>
                </div>
              </div>

              <div className="form-group space-bottom">
                <label
                  htmlFor="currency"
                  className={classnames(
                    "col-lg-2 col-md-2 col-sm-2",
                    "control-label align-margin"
                  )}>
                  {t(`${attributesLocale}.currency`)}
                </label>
                <div className="col-lg-10 col-md-10 col-sm-10 space-bottom">
                  <Select
                    name="currency"
                    defaultValue={profile.currency}
                    onChange={this.handleChangeSelect('currency')}
                  >
                    {Object.keys(rates).map(currency =>
                      <Option
                        key={currency}
                        value={currency}>
                        {currency}
                      </Option>)}
                  </Select>
                </div>
              </div>

              <div className="form-group space-bottom">
                <div className="col-md-2"></div>
                <div
                  className={classnames(
                    "create-profile-btn-container",
                    "col-lg-10 col-md-10 col-sm-10",
                  )}>
                  <input
                    value={t("create_profile")}
                    className="btn btn-login"
                    type="submit"
                    disabled={!this.validateForm()} />

                  <input
                    type="button"
                    className="btn"
                    value={t("go_back")}
                    onClick={() => switchToCreateProfile(false)}/>
                </div>
              </div>
            </div>
          </form>
        </div>
    );
  }
}

const mapStateToProps = state => ({
  rates: state.global.rates,
  locale: state.global.locale,
  profile: state.global.profile,
  isCreatingProfile: state.global.isCreatingProfile,
});

const actions = {
  createProfile,
  switchToCreateProfile,
};

export default connect(mapStateToProps, actions)(CreateProfile);