/* eslint-disable max-len */
/**
 * CONSTANTS
 */
export const FETCH_BLACK_LIST_SELLERS = "FETCH_BLACK_LIST_SELLERS";
export const FETCH_BLACK_LIST_SELLERS_FULFILLED = "FETCH_BLACK_LIST_SELLERS_FULFILLED";
export const FETCH_BLACK_LIST_SELLERS_ERROR = "FETCH_BLACK_LIST_SELLERS_ERROR";

export const DELETE_BLACK_LIST_SELLERS = "DELETE_BLACK_LIST_SELLERS";
export const DELETE_BLACK_LIST_SELLERS_SUCCESS = "DELETE_BLACK_LIST_SELLERS_SUCCESS";
export const DELETE_BLACK_LIST_SELLERS_ERROR = "DELETE_BLACK_LIST_SELLERS_ERROR";


/**
 * DISPATCHERS
 */
export const fetchBlackListSellers = payload => ({ type: FETCH_BLACK_LIST_SELLERS, payload });
export const fetchBlackListSellersFulfilled = payload => ({ type: FETCH_BLACK_LIST_SELLERS_FULFILLED, payload });
export const fetchBlackListSellersError = payload => ({ type: FETCH_BLACK_LIST_SELLERS_ERROR, payload });

export const deleteBlackListSellers = payload => ({ type: DELETE_BLACK_LIST_SELLERS, payload });
export const deleteBlackListSellersSuccess = payload => ({ type: DELETE_BLACK_LIST_SELLERS_SUCCESS, payload });
export const deleteBlackListSellersError = payload => ({ type: DELETE_BLACK_LIST_SELLERS_ERROR, payload });