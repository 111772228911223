/* eslint-disable max-len */
/**
 * CONSTANTS 
 */
export const FETCH_PROFILE = "FETCH_PROFILE";
export const PROFILE_FULFILLED = "PROFILE_FULFILLED";
export const FETCH_PROFILE_ERROR = "FETCH_PROFILE_ERROR";

export const FETCH_PROFILE_ALLOWED_NOTICES = "FETCH_PROFILE_ALLOWED_NOTICES";
export const FETCH_PROFILE_ALLOWED_NOTICES_SUCCESS = "FETCH_PROFILE_ALLOWED_NOTICES_SUCCESS";
export const FETCH_PROFILE_ALLOWED_NOTICES_ERROR = "FETCH_PROFILE_ALLOWED_NOTICES_ERROR";

export const FETCH_MEMBER_RESTRICTION = "FETCH_MEMBER_RESTRICTION";
export const FETCH_MEMBER_RESTRICTION_SUCCESS = "FETCH_MEMBER_RESTRICTION_SUCCESS";
export const FETCH_MEMBER_RESTRICTION_ERROR = "FETCH_MEMBER_RESTRICTION_ERROR";

export const FETCH_EFFICIENCY = "FETCH_EFFICIENCY";
export const FETCH_EFFICIENCY_SUCCESS = "FETCH_EFFICIENCY_SUCCESS";
export const FETCH_EFFICIENCY_ERROR = "FETCH_EFFICIENCY_ERROR";

export const TOGGLE_LEFT_NAV = "TOGGLE_LEFT_NAV";

export const SET_LANG = "SET_LANG";

export const OPEN_MODAL = "OPEN_MODAL";
export const CLOSE_MODAL = "CLOSE_MODAL";
export const UPDATE_MODAL = "UPDATE_MODAL";

export const FETCH_CURRENCY = "FETCH_CURRENCY";
export const CURRENCY_FULFILLED = "CURRENCY_FULFILLED";
export const FETCH_CURRENCY_ERROR = "FETCH_CURRENCY_ERROR";

export const UPDATE_PROFILE_SETTINGS = "UPDATE_PROFILE_SETTINGS";

export const UPDATE_PROFILE = "UPDATE_PROFILE";
export const UPDATE_PROFILE_SUCCESS = "UPDATE_PROFILE_SUCCESS";
export const UPDATE_PROFILE_ERROR = "UPDATE_PROFILE_ERROR";

export const CREATE_SELLERS_LIVE_SEARCH = "CREATE_SELLERS_LIVE_SEARCH";
export const CREATE_SELLERS_LIVE_SEARCH_SUCCESS = "CREATE_SELLERS_LIVE_SEARCH_SUCCESS";
export const CREATE_SELLERS_LIVE_SEARCH_ERROR = "CREATE_SELLERS_LIVE_SEARCH_ERROR";

export const FETCH_SITES = "FETCH_SITES";
export const FETCH_SITES_FULLFILLED = "FETCH_SITES_FULLFILLED";
export const FETCH_SITES_ERROR = "FETCH_SITES_ERROR";

export const FETCH_ARTICLE_DETAIL = "FETCH_ARTICLE_DETAIL";
export const FETCH_ARTICLE_DETAIL_FULFILLED = "FETCH_ARTICLE_DETAIL_FULFILLED";
export const FETCH_ARTICLE_CATALOGO_DETAIL_FULFILLED = "FETCH_ARTICLE_CATALOGO_DETAIL_FULFILLED";
export const FETCH_ARTICLES_CATALOGO = "FETCH_ARTICLES_CATALOGO";
export const FETCH_ARTICLE_DETAIL_ERROR = "FETCH_ARTICLE_DETAIL_ERROR";
export const RESET_ARTICLE_DETAIL = "RESET_ARTICLE_DETAIL";

export const FETCH_CATALOGO_DETAIL = "FETCH_CATALOGO_DETAIL";

export const FETCH_CATALOGOS_DETAIL = "FETCH_CATALOGOS_DETAIL";

export const LOG_OUT = "LOG_OUT";

export const HIDE_NAV_FROM_HEADER = "HIDE_NAV_FROM_HEADER";

export const FETCH_PROFILES = "FETCH_PROFILES";
export const FETCH_PROFILES_SUCCESS = "FETCH_PROFILES_SUCCESS";
export const FETCH_PROFILES_ERROR = "FETCH_PROFILES_ERROR";

export const SWITCH_TO_CREATE_PROFILE = "SWITCH_TO_CREATE_PROFILE";

export const CREATE_PROFILE = "CREATE_PROFILE";
export const CREATE_PROFILE_SUCCESS = "CREATE_PROFILE_SUCCESS";
export const CREATE_PROFILE_ERROR = "CREATE_PROFILE_ERROR";

export const CLOSE_HELP = "CLOSE_HELP";
export const CLOSE_HELP_SUCCESS = "CLOSE_HELP_SUCCESS";
export const CLOSE_HELP_ERROR = "CLOSE_HELP_ERROR";

export const GET_CREDENTIALS = "GET_CREDENTIALS";
export const GET_CREDENTIALS_SUCCESS = "GET_CREDENTIALS_SUCCESS";
export const GET_CREDENTIALS_ERROR = "GET_CREDENTIALS_ERROR";

export const CHANGE_PROFILE_SUCCESS = "CHANGE_PROFILE_SUCCESS";
export const CHANGE_PROFILE_ERROR = "CHANGE_PROFILE_ERROR";

export const CLEAN_CREDENTIALS = "CLEAN_CREDENTIALS";

export const SEND_MESSAGE_TO_SUPPORT = "SEND_MESSAGE_TO_SUPPORT";
export const SEND_MESSAGE_TO_SUPPORT_SUCCESS = "SEND_MESSAGE_TO_SUPPORT_SUCCESS";
export const SEND_MESSAGE_TO_SUPPORT_ERROR = "SEND_MESSAGE_TO_SUPPORT_ERROR";

export const FETCH_MELI_ACCOUNT = "FETCH_MELI_ACCOUNT";
export const FETCH_MELI_ACCOUNT_FULFILLED = "FETCH_MELI_ACCOUNT_FULFILLED";
export const FETCH_MELI_ACCOUNT_ERROR = "FETCH_MELI_ACCOUNT_ERROR";

export const UPDATE_MELI_ACCOUNT = "UPDATE_MELI_ACCOUNT";
export const UPDATE_MELI_ACCOUNT_SUCCESS = "UPDATE_MELI_ACCOUNT_SUCCESS";
export const UPDATE_MELI_ACCOUNT_ERROR = "UPDATE_MELI_ACCOUNT_ERROR";

export const SET_ARTICLES_PINNED = "SET_ARTICLES_PINNED";
export const SET_ARTICLES_PINNED_SUCCESS = "SET_ARTICLES_PINNED_SUCCESS";
export const SET_ARTICLES_PINNED_ERROR = "SET_ARTICLES_PINNED_ERROR";

export const SET_TRANSLATE_TOOLTIP_ALREADY_SHOWN = "SET_TRANSLATE_TOOLTIP_ALREADY_SHOWN";
export const SET_TRANSLATE_TOOLTIP_ALREADY_SHOWN_SUCCESS = "SET_TRANSLATE_TOOLTIP_ALREADY_SHOWN_SUCCESS";
export const SET_TRANSLATE_TOOLTIP_ALREADY_SHOWN_ERROR = "SET_TRANSLATE_TOOLTIP_ALREADY_SHOWN_ERROR";

export const SET_NEW_REASON_TOOLTIP_ALREADY_SHOWN = "SET_NEW_REASON_TOOLTIP_ALREADY_SHOWN";
export const SET_NEW_REASON_TOOLTIP_ALREADY_SHOWN_SUCCESS = "SET_NEW_REASON_TOOLTIP_ALREADY_SHOWN_SUCCESS";
export const SET_NEW_REASON_TOOLTIP_ALREADY_SHOWN_ERROR = "SET_NEW_REASON_TOOLTIP_ALREADY_SHOWN_ERROR";

export const SET_DISCARD_TOOLTIP_ALREADY_SHOWN = "SET_DISCARD_TOOLTIP_ALREADY_SHOWN";
export const SET_DISCARD_TOOLTIP_ALREADY_SHOWN_SUCCESS = "SET_DISCARD_TOOLTIP_ALREADY_SHOWN_SUCCESS";
export const SET_DISCARD_TOOLTIP_ALREADY_SHOWN_ERROR = "SET_DISCARD_TOOLTIP_ALREADY_SHOWN_ERROR";

export const RESET_CASE_CX = "RESET_CASE_CX";

export const GRAPHQL_ERRORS = "GRAPHQL_ERRORS";

export const SET_EMAIL_LOGIN_ADRESS = "SET_EMAIL_LOGIN_ADRESS";

export const ACTION_MESSAGE_BOX = "ACTION_MESSAGE_BOX";

export const FETCH_MENU = "FETCH_MENU";
export const FETCH_MENU_SUCCESS = "FETCH_MENU_SUCCESS";
export const FETCH_MENU_ERROR = "FETCH_MENU_ERROR";

export const FETCH_BLINDAGES = "FETCH_BLINDAGES";
export const FETCH_BLINDAGES_SUCCESS = "FETCH_BLINDAGES_SUCCESS";
export const FETCH_BLINDAGES_ERROR = "FETCH_BLINDAGES_ERROR";

/**
 * DISPATCHERS 
 */
export const fetchProfile = payload => ({ type: FETCH_PROFILE, payload });
export const profileFulfilled = payload => ({ type: PROFILE_FULFILLED, payload });
export const fetchProfileError = payload => ({ type: FETCH_PROFILE_ERROR, payload });

export const fetchProfileAllowedNotices = () => ({ type: FETCH_PROFILE_ALLOWED_NOTICES });
export const fetchProfileAllowedNoticesSuccess = payload => ({ type: FETCH_PROFILE_ALLOWED_NOTICES_SUCCESS, payload });
export const fetchProfileAllowedNoticesError = payload => ({ type: FETCH_PROFILE_ALLOWED_NOTICES_ERROR, payload });

export const fetchMemberRestriction = () => ({ type: FETCH_MEMBER_RESTRICTION });
export const fetchMemberRestrictionSuccess = payload => ({ type: FETCH_MEMBER_RESTRICTION_SUCCESS, payload });
export const fetchMemberRestrictionError = payload => ({ type: FETCH_MEMBER_RESTRICTION_ERROR, payload });

export const fetchEfficiency = () => ({ type: FETCH_EFFICIENCY });
export const fetchEfficiencySuccess = payload => ({ type: FETCH_EFFICIENCY_SUCCESS, payload });
export const fetchEfficiencyError = payload => ({ type: FETCH_EFFICIENCY_ERROR, payload });

export const toggleLeftNav = () => ({ type: TOGGLE_LEFT_NAV });

export const setLang = payload => ({ type: SET_LANG, payload });

export const openModal = modal => ({ type: OPEN_MODAL, payload: { modal } });
export const closeModal = () => ({ type: CLOSE_MODAL });
export const updateModal = payload => ({ type: UPDATE_MODAL, payload });

export const fetchCurrency = () => ({ type: FETCH_CURRENCY });
export const currencyFulfilled = payload => ({ type: CURRENCY_FULFILLED, payload });
export const fetchCurrencyError = payload => ({ type: FETCH_CURRENCY_ERROR, payload });

export const updateProfileSettings = payload => ({ type: UPDATE_PROFILE_SETTINGS, payload });

export const updateProfile = payload => ({ type: UPDATE_PROFILE, payload });
export const updateProfileSuccess = payload => ({ type: UPDATE_PROFILE_SUCCESS, payload });
export const updateProfileError = payload => ({ type: UPDATE_PROFILE_ERROR, payload });

export const createSellersLiveSearch = payload => ({ type: CREATE_SELLERS_LIVE_SEARCH, payload });
export const createSellersLiveSearchSuccess = payload => ({ type: CREATE_SELLERS_LIVE_SEARCH_SUCCESS, payload });
export const createSellersLiveSearchError = () => ({ type: CREATE_SELLERS_LIVE_SEARCH_ERROR });

export const fetchSites = () => ({ type: FETCH_SITES });
export const fetchSitesFullFilled = payload => ({ type: FETCH_SITES_FULLFILLED, payload });
export const fetchSitesError = payload => ({ type: FETCH_SITES_ERROR, payload });

export const fetchArticleDetail = payload => ({ type: FETCH_ARTICLE_DETAIL, payload });
export const fetchArticleDetailFulfilled = payload => ({ type: FETCH_ARTICLE_DETAIL_FULFILLED, payload });
export const fetchArticleCatalogoDetailFulfilled = payload => ({ type: FETCH_ARTICLE_CATALOGO_DETAIL_FULFILLED, payload });
export const fetchArticleDetailError = payload => ({ type: FETCH_ARTICLE_DETAIL_ERROR, payload });
export const resetArticleDetail = () => ({ type: RESET_ARTICLE_DETAIL });

export const fetchArticlesCatalogo = payload => ({ type: FETCH_ARTICLES_CATALOGO, payload });


export const fetchCatalogoDetail = payload => ({ type: FETCH_CATALOGO_DETAIL, payload });

export const fetchCatalogosDetail = payload => ({ type: FETCH_CATALOGOS_DETAIL, payload });

export const logOut = () => ({ type: LOG_OUT });

export const hideNavFromHeader = payload => ({ type: HIDE_NAV_FROM_HEADER, payload });

export const fetchProfiles = () => ({ type: FETCH_PROFILES });
export const fetchProfilesSuccess = payload => ({ type: FETCH_PROFILES_SUCCESS, payload });
export const fetchProfilesError = payload => ({ type: FETCH_PROFILES_ERROR, payload });

export const switchToCreateProfile = payload => ({ type: SWITCH_TO_CREATE_PROFILE, payload });

export const createProfile = payload => ({ type: CREATE_PROFILE, payload });
export const createProfileSuccess = payload => ({ type: CREATE_PROFILE_SUCCESS, payload });
export const createProfileError = payload => ({ type: CREATE_PROFILE_ERROR, payload });

export const closeHelp = payload => ({ type: CLOSE_HELP, payload });
export const closeHelpSuccess = payload => ({ type: CLOSE_HELP_SUCCESS, payload });
export const closeHelpError = payload => ({ type: CLOSE_HELP_ERROR, payload });

export const getCredentials = payload => ({ type: GET_CREDENTIALS, payload });
export const getCredentialsSuccess = payload => ({ type: GET_CREDENTIALS_SUCCESS, payload });
export const getCredentialsError = payload => ({ type: GET_CREDENTIALS_ERROR, payload });

export const changeProfileSuccess = payload => ({ type: CHANGE_PROFILE_SUCCESS, payload });
export const changeProfileError = payload => ({ type: CHANGE_PROFILE_ERROR, payload });

export const cleanCredentials = () => ({ type: CLEAN_CREDENTIALS });

export const sendMessageToSupport = payload => ({ type: SEND_MESSAGE_TO_SUPPORT, payload });
export const sendMessageToSupportSuccess = payload => ({ type: SEND_MESSAGE_TO_SUPPORT_SUCCESS, payload });
export const sendMessageToSupportError = payload => ({ type: SEND_MESSAGE_TO_SUPPORT_ERROR, payload });

export const fetchMeliAccount = () => ({ type: FETCH_MELI_ACCOUNT });
export const fetchMeliAccountFulfilled = payload => ({ type: FETCH_MELI_ACCOUNT_FULFILLED, payload });
export const fetchMeliAccountError = payload => ({ type: FETCH_MELI_ACCOUNT_ERROR, payload });

export const updateMeliAccount = payload => ({ type: UPDATE_MELI_ACCOUNT, payload });
export const updateMeliAccountSuccess = payload => ({ type: UPDATE_MELI_ACCOUNT_SUCCESS, payload });
export const updateMeliAccountError = payload => ({ type: UPDATE_MELI_ACCOUNT_ERROR, payload });

export const setArticlesPinned = payload => ({ type: SET_ARTICLES_PINNED, payload });
export const setArticlesPinnedSuccess = payload => ({ type: SET_ARTICLES_PINNED_SUCCESS, payload });
export const setArticlesPinnedError = payload => ({ type: SET_ARTICLES_PINNED_ERROR, payload });

export const setTranslateTooltipAlreadyShown = payload => ({ type: SET_TRANSLATE_TOOLTIP_ALREADY_SHOWN, payload });
export const setTranslateTooltipAlreadyShownSuccess = payload => ({ type: SET_TRANSLATE_TOOLTIP_ALREADY_SHOWN_SUCCESS, payload });
export const setTranslateTooltipAlreadyShownError = payload => ({ type: SET_TRANSLATE_TOOLTIP_ALREADY_SHOWN_ERROR, payload });

export const setNewReasonTooltipAlreadyShown = payload => ({ type: SET_NEW_REASON_TOOLTIP_ALREADY_SHOWN, payload });
export const setNewReasonTooltipAlreadyShownSuccess = payload => ({ type: SET_NEW_REASON_TOOLTIP_ALREADY_SHOWN_SUCCESS, payload });
export const setNewReasonTooltipAlreadyShownError = payload => ({ type: SET_NEW_REASON_TOOLTIP_ALREADY_SHOWN_ERROR, payload });

export const setDiscardTooltipAlreadyShown = payload => ({ type: SET_DISCARD_TOOLTIP_ALREADY_SHOWN, payload });
export const setDiscardTooltipAlreadyShownSuccess = payload => ({ type: SET_DISCARD_TOOLTIP_ALREADY_SHOWN_SUCCESS, payload });
export const setDiscardTooltipAlreadyShownError = payload => ({ type: SET_DISCARD_TOOLTIP_ALREADY_SHOWN_ERROR, payload });

export const resetCaseCx = () => ({ type: RESET_CASE_CX });

export const graphqlErrors = payload => ({ type: GRAPHQL_ERRORS, payload });

export const setEmailLoginAdress = payload => ({ type: SET_EMAIL_LOGIN_ADRESS, payload });

export const actionMessageBox = () => ({ type: ACTION_MESSAGE_BOX });

export const fetchMenu = () => ({ type: FETCH_MENU });
export const fetchMenuSuccess = payload => ({ type: FETCH_MENU_SUCCESS, payload });
export const fetchMenuError = payload => ({ type: FETCH_MENU_ERROR, payload });

export const fetchBlindages = payload => ({ type: FETCH_BLINDAGES, payload });
export const fetchBlindagesSuccess = payload => ({ type: FETCH_BLINDAGES_SUCCESS, payload });
export const fetchBlindagesError = payload => ({ type: FETCH_BLINDAGES_ERROR, payload });