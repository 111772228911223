import React, { useCallback } from "react";
import XLSX from "sheetjs-style-v2";
import { getDateUTC, t } from "utils";

const ImportedFileDetails = (props) => {
  const { invalidIds, articlesIds, notFoundIds, blindagesInfoExcel,lang } = props;

  const blindageItemId = [];
  const blindageItemDueDate = [];

  if (blindagesInfoExcel && blindagesInfoExcel.length > 0) {
    blindagesInfoExcel.forEach((item) => {
      blindageItemId.push(item.item);
      blindageItemDueDate.push(
        t("blindage.tooltip_content") + getDateUTC(item.due_date, lang)
      );
    });
  }

  let max = [articlesIds, invalidIds, notFoundIds, blindagesInfoExcel].sort(
    (a, b) => {
      if (a.length > b.length) return -1;
      if (a.length < b.length) return 1;
      return 0;
    }
  )[0];

  const handleDownloadImportedFilesDetails = useCallback(
    (e) => {
      e.preventDefault();

      const valueWithSpaces = (value) => [value, null, null, null, null];
      const valueWithComment = (blindageItemId, blindageItemDueDate) => [
        blindageItemId,
        blindageItemDueDate,
        null,
        null,
      ];
      const headers = [
        [
          ...valueWithSpaces(t("add_suspicious_xlsx.listings_found")),
          ...valueWithSpaces(t("add_suspicious_xlsx.errors")),
          ...valueWithSpaces(t("add_suspicious_xlsx.listings_duplicated")),
        ],
      ];
      const data = max.map((_item, i) => {
        if (notFoundIds.length > 0) {
          return [
            ...valueWithSpaces(articlesIds[i]),
            ...valueWithSpaces(notFoundIds[i]),
            ...valueWithSpaces(invalidIds[i]),
          ];
        }
        if (blindageItemId && blindageItemDueDate.length > 0) {
          return [
            ...valueWithSpaces(articlesIds[i]),
            ...valueWithComment(blindageItemId[i], blindageItemDueDate[i]),
            ...valueWithSpaces(invalidIds[i]),
          ];
        }
      });

      const wb = XLSX.utils.book_new();
      const ws = XLSX.utils.json_to_sheet([...headers, ...data]);

      const totalRows = data.length + 2;

      // Merge cells in the specified range
      ws["!merges"] = [{ s: { r: 1, c: 5 }, e: { r: 1, c: 6 } }];

      // Row 0 Colum 6 style
      const R0C6 = XLSX.utils.encode_cell({ r: 1, c: 5 });

      ws[R0C6].s = {
        fill: {
          fgColor: { rgb: "8e4585" },
        },
        alignment: {
          horizontal: "center",
        },
      };

      // All Rows style alignment
      for (let row = 0; row < totalRows; row++) {
        const cellAddress = XLSX.utils.encode_cell({
          r: row,
          c: 6,
        });
        ws[cellAddress].s = {
          alignment: {
            horizontal: "left",
            wrapText: "true",
          },
        };
      }

      // All columns set width except the column 6
      const totalColumns = XLSX.utils.decode_range(ws["!ref"]).e.c + 1;

      if (!ws["!cols"]) ws["!cols"] = [];
      for (let i = 0; i < totalColumns; i++) {
        if (i !== 6) {
          ws["!cols"][i] = { wpx: 90 };
        } else {
          ws["!cols"][i] = { wpx: 200 };
        }
      }

      XLSX.utils.book_append_sheet(wb, ws, "Imported Files Details");
      XLSX.writeFile(wb, "imported_files_details.xlsx", { bookType: "xlsx" });
    },
    [max, articlesIds, invalidIds, notFoundIds, blindagesInfoExcel]
  );

  return (
    <>
      <button
        className="download-btn"
        onClick={handleDownloadImportedFilesDetails}
      >
        {t("add_suspicious_xlsx.download_detail")}
      </button>
      <table id="imported_files_details" className="hidden">
        <thead>
          <tr>
            <th colSpan={5}>{}</th>
            <th colSpan={5}>{t("add_suspicious_xlsx.errors")}</th>
            <th colSpan={5}>{t("add_suspicious_xlsx.listings_duplicated")}</th>
          </tr>
        </thead>
        <tbody>
          {max.map((item, i) => (
            <tr key={item}>
              <td>{articlesIds[i]}</td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td>{notFoundIds[i]}</td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td>
                {blindageItemId[i]} {blindageItemDueDate[i]}
              </td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td>{invalidIds[i]}</td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
            </tr>
          ))}
        </tbody>
      </table>
    </>
  );
};

export default ImportedFileDetails;
