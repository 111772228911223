import React, { useCallback } from "react";
import { t } from "utils";

const ImportedFileExample = props => {
  const {
    profile
  } = props;

  const fileName = 'plantilla-denuncia-masiva.xlsx';

  const handleDownloadHowTo = useCallback(e => {
    e.preventDefault();
    const pathLang  = profile.lang ? profile.lang : 'es';
    window.location = `${window.location.protocol}//${window.location.host}/template-notices/${pathLang}/${fileName}`;
  }, [profile]);

  return (
    <button onClick={handleDownloadHowTo}>
      {t('add_suspicious_xlsx.modal_subtitle_text2')}
    </button>
  );
};

export default ImportedFileExample;