export default `
  fragment articleCardFields on ArticleType {
    id
    title 
    price
    siteId
    status
    currency
    thumbnail
    livesearchId
    catalogListing
    catalogProductId
    officialStoreId
    images { 
      secure_url
    }
    country {
      code
      name
    }
    seller {
      id
      blackSeller
      whiteSeller
    }
    approved {
      author {
        id
        givenName
        familyName
      }
    }
    reported {
      createdAt
      author {
        id
        givenName
        familyName
      }
    }
    noticed {
      noticedAt
      noticeStatus
    }
  }
`;