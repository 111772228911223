import { alertSuccess, alertError } from "./index";
import { t } from "utils";


/* eslint-disable no-console */
export const deleteWhiteListSellersOptimistic = (state, payload) => {	
  const whiteListSellers = state.whiteListSellers.slice(0)
    .map(seller => {
      if (seller.id === payload.sellerId) { seller.removed = true; }
      return seller;
    });

  return {
    ...state,
    isLoading: true,
    whiteListSellers,
  };
};

export const deleteWhiteListSellersSuccess = (state, payload) => {
  const whiteListSellers = [
    ...state.whiteListSellers, 
    ...(payload.whiteListSellers || []),
  ].filter(seller => seller.id !== payload.id);
  
  alertSuccess(t('client_white_sellers.index.white_list_seller_deleted'));

  return {
    ...state,
    isLoading: false,
    whiteListSellers,
    whiteListSellersFrom: payload.whiteListSellersFrom,
    whiteListSellersTotal: state.whiteListSellersTotal - 1,
  };
};

export const deleteWhiteListSellersError = (state, payload) => {
  const whiteListSellers = state.whiteListSellers.slice(0)
    .map(seller => {
      if (seller.id === payload.id) { delete seller.removed; }
      return seller;
    });
	
  alertError(t('client_white_sellers.index.white_list_seller_delete_error'));

  return {
    ...state,
    isLoading: false,
    whiteListSellers,
  };
};

export const fetchWhiteListSellersError = (state, payload) => {
  alertError(t('client_white_sellers.index.fetch_white_list_seller_error'));
  console.error(payload);
  return { 
    ...state, 
    isLoading: false 
  };
};
