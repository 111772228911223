import React from "react";
import Alert from "react-s-alert";
import { alertInfo, alertError } from "./index";
import { t } from "utils";

/* eslint-disable no-console */
/**
 * COMMONS
 */
const setApproveArticle = (approved, articlesIds, state, author) => {
  const liveSearch = { ...state.liveSearch };

  liveSearch &&
    liveSearch.articles &&
    liveSearch.articles.map((article) => {
      if (articlesIds.indexOf(article.id) > -1) {
        article["approved"] = approved ? { author } : false;
      }
      return article;
    });

  return liveSearch;
};

const setApproveArticleError = (approved, state, payload) => {
  let errorMsgAlert = "";
  const { error, articlesIds } = payload;
  const liveSearch = { ...state.liveSearch };

  liveSearch &&
    liveSearch.articles &&
    liveSearch.articles.map((article) => {
      if (articlesIds.indexOf(article.id) > -1) {
        article["approved"] = approved;
      }
      return article;
    });

  if (articlesIds.length > 1) {
    errorMsgAlert = t("articles_change_status_error");
    if (
      error &&
      error.message === "Trying to approve an article that was already audited."
    ) {
      errorMsgAlert += ` ${t("articles_change_status_error_already")}`;
    }
  } else {
    errorMsgAlert = t("article_change_status_error");
    if (
      error &&
      error.message === "Trying to approve an article that was already audited."
    ) {
      errorMsgAlert += ` ${t("article_change_status_error_already")}`;
    }
  }

  alertError(errorMsgAlert);
  console.log(payload);

  return liveSearch;
};

/**
 * NEW STATE
 */
export const approveArticleOptimistic = (state, payload) => ({
  ...state,
  liveSearch: setApproveArticle(
    true,
    payload.articlesIds,
    state,
    payload.author
  ),
});

export const approveArticleError = (state, payload) => ({
  ...state,
  liveSearch: setApproveArticleError(false, state, payload),
});

export const approveArticleUndoOptimistic = (state, payload) => ({
  ...state,
  liveSearch: setApproveArticle(false, [payload.articleId], state),
});

export const approveArticleUndoError = (state, payload) => {
  alertError(t("server_error"));
  console.error(payload);

  return {
    ...state,
    liveSearch: setApproveArticleError(true, state, [payload.articleId]),
  };
};

export const reportArticleUndoOptmistic = (state, { articleId }) => {
  const liveSearch = { ...state.liveSearch };
  const articlesFakeMap = { ...state.articlesFakeMap };

  liveSearch &&
    liveSearch.articles &&
    liveSearch.articles.map((article) => {
      if (article.id === articleId) {
        delete article["reported"];
      }
      articlesFakeMap[article.id] = { ...article };
      return article;
    });

  return {
    ...state,
    liveSearch,
    articlesFakeMap,
  };
};

export const reportArticleUndoError = (state, payload) => {
  console.error(payload);
  alertError(`${t("article_change_status_error")} ${payload.articleId}`);
  return state;
};

export const reportArticlesSuccess = (state, payload) => {
  let alertId = null;
  const { isImport, didReport, articlesIds } = payload;

  if (!isImport) {
    alertInfo(
      <div>
        {articlesIds.length > 1
          ? t("done_articles_reported")
          : t("done_article_reported")}{" "}
        <strong
          style={{ cursor: "pointer" }}
          onClick={() => payload.history.push("/reported_articles")}
        >
          {t("go_to_reported_articles")}
        </strong>
        {!didReport && (
          <span
            onClick={() => (alertId ? Alert.close(alertId) : Alert.closeAll())}
            style={{
              float: "right",
              cursor: "pointer",
              fontWeight: 600,
              marginLeft: "100px",
            }}
          >
            {t("got_it")}
          </span>
        )}
      </div>,
      didReport
        ? null
        : {
            timeout: 9999999,
            onShow: function () {
              alertId = this.id;
            },
          }
    );
  }

  return {
    ...state,
    isReporting: false,
    isUpdatingArticle: false,
    showImportSuccess: isImport,
    showImportSuccessCount: isImport ? articlesIds.length : 0,
  };
};

export const reportArticlesError = (state, payload) => {
  let errorMsgAlert = "";
  const { error, articlesIds } = payload;
  const liveSearch = { ...state.liveSearch };
  const articlesFakeMap = { ...state.articlesFakeMap };

  if (liveSearch.articles) {
    liveSearch.articles.forEach((article) => {
      if (articlesIds.indexOf(article.id) > -1) {
        delete article["reported"];
        delete articlesFakeMap[article.id]["reported"];
      }
    });
  }

  if (articlesIds.length > 1) {
    errorMsgAlert = t("articles_change_status_error");
    if (
      error &&
      error.message === "Trying to report an article that was already audited."
    ) {
      errorMsgAlert += ` ${t("articles_change_status_error_already")}`;
    }
  } else {
    errorMsgAlert = t("article_change_status_error");
    if (
      error &&
      error.message === "Trying to report an article that was already audited."
    ) {
      errorMsgAlert += ` ${t("article_change_status_error_already")}`;
    }
  }

  alertError(errorMsgAlert);
  console.error(payload);

  return {
    ...state,
    liveSearch,
    articlesFakeMap,
    isReporting: false,
  };
};

export const reportArticlesOptimistic = (state, payload) => {
  const { articlesIds, ...rest } = payload;
  const data = { ...rest };
  const liveSearch = { ...state.liveSearch };
  const articlesFakeMap = { ...state.articlesFakeMap };

  if (liveSearch && liveSearch.articles) {
    liveSearch.articles.forEach((article) => {
      if (articlesIds.indexOf(article.id) > -1) {
        article["reported"] = data;
        articlesFakeMap[article.id] = article;
      }
    });
  } else {
    articlesIds.forEach((articleId) => {
      articlesFakeMap[articleId] = {
        ...articlesFakeMap[articleId],
        reported: data,
      };
    });
  }

  return {
    ...state,
    liveSearch,
    articlesFakeMap,
    isBatchActive: false,
    isUpdatingArticle: true,
  };
};

export const batchApplyOptimistic = (state, payload) => {
  const toReport = state.batchReported.slice(0);
  const { articlesIds, author } = payload;
  const liveSearch = { ...state.liveSearch };

  articlesIds.forEach((articleId) => {
    liveSearch.articles.map((article) => {
      if (article.id === articleId) article["approved"] = { author };
      return article;
    });
  });

  return {
    ...state,
    toReport,
    liveSearch,
    isBatchActive: false,
  };
};

export const batchApplyError = (state, payload) => {
  let articlesIds = payload.batchApproved.slice(0);
  const liveSearch = { ...state.liveSearch };
  const articlesFakeMap = { ...state.articlesFakeMap };

  liveSearch.articles.forEach((article) => {
    if (articlesIds.indexOf(article.id) > -1) {
      delete article["approved"];
      delete articlesFakeMap[article.id]["approved"];
    }
  });

  alertError(t("articles_change_status_error"));
  console.error(payload);

  return {
    ...state,
    liveSearch,
    articlesFakeMap,
  };
};

export const liveSearchFulfilled = (state, payload) => {
  try {
    let currentFilter = {};
    const articlesFakeMap = { ...state.articlesFakeMap };

    let liveSearch =
      payload[0].liveSearch.articles_from &&
      Object.keys(state.liveSearch).length
        ? { ...state.liveSearch }
        : { ...payload[0].liveSearch };

    if (liveSearch.articles_from !== 0) {
      liveSearch.articles = [
        ...(liveSearch.articles || []),
        ...(payload[0] && payload[0].liveSearch
          ? payload[0].liveSearch.articles
          : []),
      ];
    }

    liveSearch.articles = [
      ...(liveSearch.articles || []),
      ...(payload[1] && payload[1].liveSearch
        ? payload[1].liveSearch.articles
        : []),
    ];

    liveSearch["articles_from"] = payload[1].liveSearch.articles_from + 50;

    currentFilter = liveSearch.filterProfiles.find(
      (filter) => filter.id === liveSearch.filterProfileId
    );

    // TO DO: check why liveSearch has id of filter that does not exist
    if (!currentFilter) {
      currentFilter = liveSearch.filterProfiles[0];
      liveSearch.filterProfileId = liveSearch.filterProfiles[0].id;
    }

    currentFilter = JSON.parse(JSON.stringify(currentFilter));

    liveSearch.articles.forEach((article) => {
      articlesFakeMap[article.id] = article;
    });

    return {
      ...state,
      liveSearch,
      currentFilter: {
        ...currentFilter,
        priceMax: liveSearch.filterProfiles[0].priceMaxConverted
          ? liveSearch.filterProfiles[0].priceMax
          : null,
        priceMin: liveSearch.filterProfiles[0].priceMinConverted
          ? liveSearch.filterProfiles[0].priceMin
          : null,
      },
      articlesFakeMap,
      isLoading: false,
    };
  } catch (error) {
    alertError(t("live_search_fetch_error"));
    console.error(error.message);
    return {
      ...state,
      liveSearch: {},
      isLoading: false,
      errorLoadingLivesearch: true,
    };
  }
};

export const liveSearchError = (state, payload) => {
  alertError(t("live_search_fetch_error"));
  console.error(payload.message);

  return {
    ...state,
    liveSearch: {},
    isLoading: false,
    errorLoadingLivesearch: true,
  };
};

export const applyFilterFulfilled = (state, payload) => {


  const liveSearch = {
    ...state.liveSearch,
    ...payload.updateLiveSearch,
  };

  return {
    ...state,
    liveSearch,
    activePage: 1,
    isLoading: false,
  };
};

export const applyFilterError = (state, payload) => {


  alertError(t("apply_filters_error"));
  console.error(payload.message);

  return { ...state, isLoading: false };
};

export const fetchArticleDetailError = (state) => {
  setTimeout(() => {
    alertError(t("reported_articles.index.error_getting_article_data"));
  }, 200);

  return {
    ...state,
    isFetching: false,
    fetchArticleDetailError: true,
  };
};

export const setBlackSellerSuccess = (state, payload) => {
  const liveSearch = { ...state.liveSearch };

  liveSearch &&
    liveSearch.articles &&
    liveSearch.articles.map((article) => {
      if (article.seller.id === payload.id) {
        article.seller.blackSeller = true;
        article.seller.whiteSeller = false;
      }
      return article;
    });

  return {
    ...state,
    liveSearch,
    updatingSellerStatus: false,
  };
};

export const unsetBlackSellerSuccess = (state, payload) => {
  const liveSearch = { ...state.liveSearch };

  liveSearch &&
    liveSearch.articles &&
    liveSearch.articles.map((article) => {
      if (article.seller.id === payload.deleteSellerFromDB.deletedId) {
        article.seller.blackSeller = false;
      }
      return article;
    });

  return {
    ...state,
    liveSearch,
    updatingSellerStatus: false,
  };
};

export const setWhiteSellerSuccess = (state, payload) => {
  const liveSearch = { ...state.liveSearch };

  liveSearch &&
    liveSearch.articles &&
    liveSearch.articles.map((article) => {
      if (article.seller.id === payload.id) {
        article.seller.whiteSeller = true;
        article.seller.blackSeller = false;
      }
      return article;
    });

  return {
    ...state,
    liveSearch,
    updatingSellerStatus: false,
  };
};

export const unsetWhiteSellerSuccess = (state, payload) => {
  const liveSearch = { ...state.liveSearch };

  liveSearch &&
    liveSearch.articles &&
    liveSearch.articles.map((article) => {
      if (article.seller.id === payload.deleteSellerFromDB.deletedId) {
        article.seller.whiteSeller = false;
      }
      return article;
    });

  return {
    ...state,
    liveSearch,
    updatingSellerStatus: false,
  };
};

export const unsetSellerError = (state) => {
  alertError(t("unset_seller_error"));

  return {
    ...state,
    updatingSellerStatus: false,
  };
};

export const discardArticlesSuccess = (state) => {
  alertInfo(<div>{t("suggested_discard.done_article_discarded")}</div>, null);

  return {
    ...state,
    isDiscarding: false,
    isUpdatingArticle: false,
  };
};

export const discardArticlesError = (state, payload) => {
  let errorMsgAlert = "";
  const { error } = payload;

  errorMsgAlert = t("article_change_status_error");
  if (
    error &&
    error.message === "Trying to discard an article that was already audited."
  ) {
    errorMsgAlert += ` ${t("article_change_status_error_already")}`;
  }

  alertError(errorMsgAlert);
  console.error(payload);

  return {
    ...state,
    isDiscarding: false,
  };
};
