import React from "react";
import ContentLoader from "react-content-loader";

const CreateProfileLoader = () => (
  <ContentLoader
    height={130}
    width={400}
    speed={2}
    primaryColor="#f3f3f3"
    secondaryColor="#ecebeb"
  >
    <rect x="20" y="12" rx="1" ry="1" width="30" height="7" /> 
    <rect x="90" y="10" rx="1" ry="1" width="285" height="15" />
    <rect x="20" y="37" rx="1" ry="1" width="30" height="7" /> 
    <rect x="90" y="33" rx="1" ry="1" width="285" height="15" />
    <rect x="20" y="60" rx="1" ry="1" width="30" height="7" /> 
    <rect x="90" y="57" rx="1" ry="1" width="42" height="15" />
    <rect x="20" y="83" rx="1" ry="1" width="30" height="7" /> 
    <rect x="90" y="81" rx="1" ry="1" width="30" height="15" />
    <rect x="90" y="105" rx="1" ry="1" width="35" height="16" />
    <rect x="130" y="105" rx="1" ry="1" width="35" height="16" />
  </ContentLoader>    
);

export default CreateProfileLoader;