/* eslint-disable linebreak-style */
import React, { PureComponent } from "react";
import { Link, withRouter } from "react-router-dom";
import classnames from "classnames";
import { connect } from "react-redux";
import {
  faStar,
  faFile,
  faUsers,
  faSearch,
  faListAlt,
  faHeadset,
  faComments,
  faThumbsDown,
  faTv,
} from "@fortawesome/free-solid-svg-icons";
import { faQuestionCircle, faUserCircle } from "@fortawesome/free-regular-svg-icons";

import Icon from "components/Icon";
import SVG from "components/Icon/svg";
import Tooltip from "components/Tooltip";
import PoweredBy from "components/PoweredBy";
import PrivacyPolicy from "components/Legal/Privacy";
import TermsConditions from "components/Legal/Terms";
import SidebarNew from "components/SidebarNew";
import { fetchCurrency } from "redux/actions/global";
import { fetchPendingCasesToExpire } from "redux/actions/pendingCasesList";
import {
  setTooltipNotShowMore,
  fetchShowTooltipStatus,
  fetchReportedArticlesCount,
  fetchShowTooltipImportStatus,
  setShowReportImportTooltipUntil,
} from "redux/actions/reportedArticles";

import { t, get, getMyAccountSiteUrl } from "utils";
import { Footer } from "components/Footer";

class LeftNavigation extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      showAuthorizeOption: false,
      activeSubMenu: this.getActiveSubMenu(),
    };
  }

  componentDidMount() {
    this.props.fetchCurrency();
    this.props.fetchPendingCasesToExpire();
    //this.props.fetchReportedArticlesCount();
    this.props.fetchShowTooltipStatus();
    // set reportImportTooltipUntil first time
    if (!this.props.profile.reportImportTooltipUntil) {
      this.props.setShowReportImportTooltipUntil(true);
    }
    else {
      this.props.fetchShowTooltipImportStatus();
    }
  }

  componentDidUpdate(prevProps) {
    if (
      !prevProps.showTooltipStatus &&
      this.props.showTooltipStatus
    ) {
      this.tooltip && this.tooltip.open();
      this.tooltipImport && this.tooltipImport.close();
    }

    if (
      !prevProps.showTooltipImportStatus &&
      this.props.showTooltipImportStatus
    ) {
      this.tooltipImport && this.tooltipImport.open();
    }
  }

  getActiveSubMenu = () => {
    const { location } = this.props;
    const pathname = location.pathname.split('/');

    switch (pathname[1]) {
    case 'client_accounts':
      return 'sellers';
    case 'reports':
      return 'reports';
    case 'case_management':
      return 'case_management';
    default:
      return '';
    }
  }

  toggleSubMenu = (subMenu, reload) => () => {
    let { activeSubMenu } = this.state;

    activeSubMenu = subMenu !== activeSubMenu ?
      subMenu : "";

    this.setState({ activeSubMenu });

    if (
      this.props.location.pathname === '/suggested_listings' &&
      reload === true
    ) {
      this.props.history.go();
    }
  }

  render() {
    const { activeSubMenu } = this.state;
    const {
      profile,
      lang,
      location,
      pendingCasesToExpire,
      reportedArticlesCount,
      isNavFromHeaderHidden,
    } = this.props;

    console.log('props', this.props);
    const siteId = get(profile, 'meliUser.siteId', false);
    const pathname = location.pathname.split('/');
    const open = this.props.open && !isNavFromHeaderHidden;
    //const showCaseManagement = !get(profile, 'isAcuerdo', false);
    const showCaseManagement = !get(profile, 'isAcuerdo', false);
    const showReports = get(profile, 'extended.liveSearchReport', false);
    const showSuggestedListings = get(profile, 'hasSuggestions', false);

    return (
    // <aside>
    //   <div
    //     id="sidebar"
    //     className={classnames(
    //       'nav-collapse',
    //       { 'hide-left-bar': !open }
    //     )}>
    //     <div
    //       tabIndex="5000"
    //       className="leftside-navigation"
    //       style={{ outline: 'none' }}>
    //       <ul
    //         id="nav-accordion"
    //         className="sidebar-menu">

    //         {
    //           showSuggestedListings &&
    //           <li
    //             onClick={this.toggleSubMenu("", true)}
    //             className={classnames(
    //               "suggestions-ctn",
    //               { "active": pathname[1] === "suggested_listings" }
    //             )}>
    //             <Link to="/suggested_listings">
    //               <Icon icon={faStar} />
    //               {t('shared.side_navigation.suggested_listings')}
    //             </Link>
    //           </li>
    //         }

    //         <li
    //           onClick={this.toggleSubMenu()}
    //           className={classnames(
    //             "search-ctn",
    //             { "active": pathname[1] === "live_searches" }
    //           )}>
    //           <Link to="/live_searches">
    //             <Icon icon={faSearch} />
    //             {t('shared.side_navigation.searches')}
    //           </Link>
    //         </li>

    //         <li
    //           onClick={this.toggleSubMenu()}
    //           className={classnames(
    //             { "active": pathname[1] === "reported_articles" }
    //           )}>
    //           <Link to="/reported_articles">
    //             <Icon icon={faThumbsDown} />
    //             {t('shared.side_navigation.articles_to_report')}&nbsp;
    //             {
    //               reportedArticlesCount > 0 &&
    //               <span
    //                 title={reportedArticlesCount}
    //                 className="notification-amount">
    //                 {
    //                   reportedArticlesCount <= 99 ?
    //                     reportedArticlesCount :
    //                     '99+'
    //                 }
    //               </span>
    //             }
    //           </Link>
    //         </li>

    //         {/* <Tooltip
    //           ref={node => this.tooltipImport = node}
    //           className="leftside-import-tooltip">
    //           <button
    //             className="close"
    //             onClick={() => {
    //               this.tooltipImport && this.tooltipImport.close();
    //             }}>
    //             &times;
    //         </button>
    //           <strong>
    //             {t('add_suspicious_xlsx.tooltip_onboarding_title')}
    //           </strong>
    //           <p>
    //             {t('add_suspicious_xlsx.tooltip_onboarding_text')}
    //           </p>
    //         </Tooltip> */}

    //         {
    //           reportedArticlesCount > 0 &&
    //           <Tooltip
    //             ref={node => this.tooltip = node}
    //             className="leftside-reported-tooltip">
    //             <button
    //               className="close"
    //               onClick={() => {
    //                 this.props.setTooltipNotShowMore(4);
    //                 this.tooltip && this.tooltip.close();
    //               }}>
    //               &times;
    //             </button>
    //             <strong>
    //               {t('shared.side_navigation.articles_to_report_tooltip_title')}
    //             </strong>
    //             <br />
    //             <p>
    //               {t('shared.side_navigation.articles_to_report_tooltip_text')}
    //             </p>
    //             <button
    //               className="notShow"
    //               onClick={() => {
    //                 this.props.setTooltipNotShowMore();
    //                 this.tooltip && this.tooltip.close();
    //               }}>
    //               {t(
    //                 'shared.side_navigation.articles_to_report_tooltip_not_show'
    //               )}
    //             </button>
    //           </Tooltip>
    //         }

    //         {
    //           showReports &&
    //           <li
    //             onClick={this.toggleSubMenu()}
    //             className={classnames(
    //               { "active": pathname[1] === "livesearch_reports" }
    //             )}
    //           >
    //             <Link to="/livesearch_reports">
    //               <Icon icon={faFile} />
    //               {t('shared.side_navigation.livesearch_reports')}
    //             </Link>
    //           </li>
    //         }

    //         <li className="sub-menu dcjq-parent-li">
    //           <button
    //             onClick={this.toggleSubMenu("sellers")}
    //             className={classnames(
    //               "dcjq-parent",
    //               { "active": activeSubMenu === "sellers" },
    //             )}>
    //             <Icon icon={faUsers} />
    //             <span>{t('shared.side_navigation.sellers')}</span>
    //           </button>
    //           <ul className="sub">
    //             <li
    //               className={classnames(
    //                 { "active": pathname[2] === "client_black_sellers" }
    //               )}
    //             >
    //               <Link to="/client_accounts/client_black_sellers">
    //                 {t('shared.side_navigation.sellers_black')}
    //               </Link>
    //             </li>
    //             <li
    //               className={classnames(
    //                 { "active": pathname[2] === "client_white_sellers" }
    //               )}>
    //               <Link to="/client_accounts/client_white_sellers">
    //                 {t('shared.side_navigation.sellers_white')}
    //               </Link>
    //             </li>
    //           </ul>
    //         </li>

    //         <li className="sub-menu dcjq-parent-li">
    //           <button
    //             onClick={this.toggleSubMenu("reports")}
    //             className={classnames(
    //               "dcjq-parent",
    //               { "active": activeSubMenu === "reports" },
    //             )}>
    //             <Icon icon={faListAlt} />
    //             <span>{t('shared.side_navigation.reports')}</span>
    //           </button>
    //           <ul className="sub">
    //             <li
    //               className={classnames(
    //                 { "active": pathname[2] === "noticed_articles" }
    //               )}>
    //               <Link to="/reports/noticed_articles">
    //                 {t('shared.side_navigation.last_reported_listings')}
    //               </Link>
    //             </li>
    //             <li
    //               className={classnames(
    //                 { "active": pathname[2] === "custom_reports" }
    //               )}>
    //               <Link to="/reports/custom_reports">
    //                 {t('shared.side_navigation.custom_reports')}
    //               </Link>
    //             </li>
    //           </ul>
    //         </li>

    //         {showCaseManagement &&
    //           <li className="sub-menu dcjq-parent-li">
    //             <button
    //               onClick={this.toggleSubMenu("case_management")}
    //               className={classnames(
    //                 "dcjq-parent",
    //                 { "active": activeSubMenu === "case_management" },
    //               )}>
    //               <Icon icon={faComments} />
    //               <span>
    //                 {t('shared.side_navigation.management_case')}
    //                 {pendingCasesToExpire > 0 &&
    //                   <span
    //                     title={pendingCasesToExpire}
    //                     className="notification-amount">
    //                     {pendingCasesToExpire <= 99 ?
    //                       pendingCasesToExpire :
    //                       '99+'}
    //                   </span>}
    //               </span>
    //             </button>
    //             <ul className="sub">
    //               <li
    //                 className={classnames(
    //                   { "active": pathname[2] === "client_pending_cases" }
    //                 )}>
    //                 <Link to="/case_management/client_pending_cases">
    //                   {t('shared.side_navigation.cases_pending')}
    //                   {pendingCasesToExpire > 0 &&
    //                     <span
    //                       title={pendingCasesToExpire}
    //                       className="notification-amount">
    //                       {pendingCasesToExpire <= 99 ?
    //                         pendingCasesToExpire :
    //                         '99+'}
    //                     </span>}
    //                 </Link>
    //               </li>
    //               <li
    //                 className={classnames(
    //                   { "active": pathname[2] === "client_closed_cases" }
    //                 )}>
    //                 <Link to="/case_management/client_closed_cases">
    //                   {t('shared.side_navigation.cases_closed')}
    //                 </Link>
    //               </li>
    //             </ul>
    //           </li>}

    //         <li
    //           onClick={this.toggleSubMenu()}
    //           className="sub-menu dcjq-parent-li">
    //           <Link to="/help_support">
    //             <Icon icon={faQuestionCircle} />
    //             <span>
    //               {t('shared.side_navigation.support_help')}
    //             </span>
    //           </Link>
    //         </li>
    //         <li
    //           onClick={this.toggleSubMenu()}
    //           className="sub-menu dcjq-parent-li">
    //           {/* <span className="sub-menu new-pill">
    //           {t('shared.side_navigation.new_pill')}
    //         </span> */}
    //           <Link to="/video_instructions">
    //             <Icon icon={faTv} />
    //             <span>
    //               {t('shared.side_navigation.video_instructions')}
    //             </span>
    //           </Link>
    //         </li>
    //         <li className="sub-menu dcjq-parent-li">
    //           <span className="sub-menu new-pill">
    //             {t('shared.side_navigation.new_pill')}
    //           </span>
    //           <a target="_blank" href={getMyAccountSiteUrl(siteId, lang)}>
    //             <Icon icon={faUserCircle} />
    //             <span>
    //               {t('shared.side_navigation.my_account')}
    //             </span>
    //           </a>
    //         </li>
    //         <li className="sub-menu dcjq-parent-li support-link">
    //           <Link to="/support_form">
    //             <Icon icon={faHeadset} />
    //             <span>
    //               {t('shared.side_navigation.support_link')}
    //             </span>
    //           </Link>
    //         </li>

    //       </ul>
    //       <ul className="sidebar-menu bottom-sidebar-menu">
    //         <li className="sub-menu dcjq-parent no-border">
    //           <PoweredBy className="menu-powered-by" />
    //         </li>
    //         <li className="sub-menu dcjq-parent-li legal-links">
    //           <PrivacyPolicy />
    //         </li>
    //         <li className="sub-menu dcjq-parent-li legal-links">
    //           <TermsConditions />
    //         </li>
    //       </ul>
    //     </div>
    //   </div>
    // </aside>

      <div>
        <SidebarNew />
        <Footer />
      </div>

    );
  }
}

const mapStateToProps = (state, ownProps) => ({
  lang: state.global.lang,
  profile: state.global.profile,
  reportedArticlesCount: state.reportedArticles.count,
  isNavFromHeaderHidden: state.global.isNavFromHeaderHidden,
  showTooltipStatus: state.reportedArticles.showTooltipStatus,
  showTooltipImportStatus: state.reportedArticles.showTooltipImportStatus,
  pendingCasesToExpire: state.pendingCasesList.pendingCasesToExpire,
  ...ownProps,
});

const actions = {
  fetchCurrency,
  setTooltipNotShowMore,
  fetchShowTooltipStatus,
  fetchShowTooltipImportStatus,
  fetchPendingCasesToExpire,
  fetchReportedArticlesCount,
  setShowReportImportTooltipUntil
};

export default connect(mapStateToProps, actions)(withRouter(LeftNavigation));
