import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Scrollbars } from "react-custom-scrollbars";
import Masonry from "react-masonry-component";
import {
  CognitoUser,
  CognitoUserPool,
  AuthenticationDetails,
} from "amazon-cognito-identity-js";
import ProfileLoader from "components/ChangeProfile/Loader";
import UserProfile from "components/UserProfile";
import {
  getCredentials, 
  cleanCredentials,
  changeProfileError,
  changeProfileSuccess,
  switchToCreateProfile,
} from "redux/actions/global";
import { t } from "utils";


class ChangeProfile extends Component {
  componentDidUpdate(prevProps) {
    if (
      this.props.credentials &&
      prevProps.credentials === null 
    ) {
      this.handleLogin();
    }
  }

  forceLogin(user, credentials) {
    const authDetails = new AuthenticationDetails(credentials);
    return new Promise((onSuccess, onFailure) => user
      .authenticateUser(authDetails, { onSuccess, onFailure })
    );
  }

  handleChangeProfile = userId => {
    this.props.getCredentials(userId);
  }

  handleLogin() {
    const { Username, ProfileId } = this.props.credentials;
    const user = new CognitoUser({
      Username,
      Pool: new CognitoUserPool({
        /* eslint-disable no-undef */
        ClientId: process.env.REACT_APP_COGNITO_APP_CLIENT_ID,
        UserPoolId: process.env.REACT_APP_COGNITO_USER_POOL_ID,
        /* eslint-enable no-undef */
      }),
    });

    this.forceLogin(user, this.props.credentials)
      .then(() => {
        this.props.cleanCredentials();
        this.props.changeProfileSuccess(ProfileId);
        this.props.history.push('/');
      })
      .catch(this.props.changeProfileError);
  }
  
  render() {
    const {
      profiles,
      isChangingProfile,
      isFetchingProfiles,
      switchToCreateProfile,
    } = this.props;

    const isLoading = (
      isFetchingProfiles ||
      isChangingProfile
    );
    
    return (
      isLoading ?
        <ProfileLoader /> :
        <div className="profiles-container">
          <Scrollbars
            autoHeight
            autoHeightMax={385}>
            <div className="profile-elements-container">
              <Masonry className="media-gal">
                {
                  profiles.length ?
                    profiles.map(profile =>
                      <UserProfile
                        key={profile.id}
                        profile={profile}
                        onChangeProfile={this.handleChangeProfile}
                        isActive={this.props.profile.id === profile.id}
                      />
                    ) : 
                    <div className="no-profiles">
                      {t("no_profiles")}
                    </div>
                }
                {
                  profiles.length < 100 &&
                    <div
                      className="new-profile"
                      onClick={() => switchToCreateProfile(true)}
                    >
                      <div className="plus-sign">+</div>
                      <div className="new-profile-text">
                        {t("new_profile")}
                      </div>
                    </div>
                }
              </Masonry>
            </div>
          </Scrollbars>
        </div>
    );
  }
}

const mapStateToProps = state => ({
  profile: state.global.profile,
  profiles: state.global.profiles,
  credentials: state.global.credentials,
  isChangingProfile: state.global.isChangingProfile,
  isFetchingProfiles: state.global.isFetchingProfiles,
});

const actions = {
  getCredentials, 
  cleanCredentials,
  changeProfileError,
  changeProfileSuccess,
  switchToCreateProfile,
};

export default withRouter(connect(mapStateToProps, actions)(ChangeProfile));