import { from } from 'rxjs';
import { ofType } from 'redux-observable';
import { mergeMap } from 'rxjs/operators';
import { gqlQuery } from "libs/awsLib";
import { 
    queryCounterNoticesV3,
    queryPendingDenouncesCount,
} from "gql/index";
import {
    FETCH_COUNTER_NOTICES_V3,
    fetchCounterNoticesErrorV3,
    counterNoticesFulfilledV3,
    FETCH_PENDING_DENOUNCES,
    fetchPendingDenouncesError,
    pendingDenouncesFullfilled,
} from "redux/actions/counterNotices";


/* EPICS */
export const fetchCounterNoticesEpic = action$ => action$.pipe(
  ofType(FETCH_COUNTER_NOTICES_V3),
  mergeMap(({ payload }) => {
    return from(
      gqlQuery(queryCounterNoticesV3, { ...payload })
        .then(({ counterNoticesV3 }) => {
          const { paging } = counterNoticesV3;
          const counterNoticesTotal = paging.total;
          const counterNoticesFrom = paging.offset + paging.limit;
          const isLoading = false
          return counterNoticesFulfilledV3({
            isLoading,
            counterNoticesTotal,
            counterNoticesFrom,
            counterNoticesV3: counterNoticesV3.cases,
          });
        })
        .catch(fetchCounterNoticesErrorV3)
    );
  })
);

export const fetchPendingDenouncesEpic = action$ => action$.pipe(
  ofType(FETCH_PENDING_DENOUNCES),
  mergeMap(({ payload }) => {
    return from(
      gqlQuery(queryPendingDenouncesCount, { ...payload })
        .then(({ pendingDenouncesCount }) => {
          const { paging } = pendingDenouncesCount;
          const Total = paging.total;
          const From = paging.offset + paging.limit;

          return pendingDenouncesFullfilled({
            Total,
            From
          });
        })
        .catch(fetchPendingDenouncesError)
    );
  })
);
