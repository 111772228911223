/* eslint-disable no-console */
import { alertError } from "./index";
import { t } from "utils";


export const fetchPendingCasesListError = (state, payload) => {
  alertError(t('case_management.cases.fetch_pending_cases_list_error'));

  console.error(payload);

  return {
    ...state,
    isLoading: false,
  };
};

export const respondCounterNoticeSuccess = (state, payload) => {
  const pendingCasesList = state.pendingCasesList.slice(0)
    .map(item => {
      if (item.caseId === payload.respondCounterNotice.caseId) {
        item = {
          ...item,
          ...payload.respondCounterNotice
        };
      }
      return item;
    });

  return {
    ...state,
    pendingCasesList,
  };
};
