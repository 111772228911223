// This query does not retrieve all the attributes available for SiteType
export default `
  {
    sites {
      id
      countrySites {
        id
        country {
          code
          name
        }
      }
    }
  }
`;