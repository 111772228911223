import { ratesFields } from "../fragments";


export default `
  ${ratesFields}

  {
    ratesConvertions {
      base
      rates {
        ...ratesFields 
      }
    }
  }
`;