/* eslint-disable max-len */
const locales = {
  es: {
    onboarding_slide_notices: {
      step_1: {
        title: "¡Conocé las nuevas funciones!",
        description:
          "Ahora es más fácil traducir la sección, ver la documentación adjunta y los detalles de la publicación denunciada.",
      },
      step_2: {
        title: "Traducción al instante",
        description:
          "Traducí todos los contenidos de la sección en un solo clic.",
      },
      step_3: {
        title: "Documentación a la vista",
        description:
          "Previsualizá las imágenes que adjuntan los vendedores sin tener que descargarlas.",
      },
      step_4: {
        title: "Detalle de la publicación",
        description:
          "Revisá la publicación que denunciaste en el nuevo módulo al final de la sección.",
      },
      next: "Siguiente",
      ok: "Entendido",
    },
    catalogue: "catálogo",
    catalogue_id: "ID de catálogo",
    official_store: "Tienda oficial",
    article_catalogue_detail: {
      title_images: "Imágenes de catálogo",
      report_images: "Marcar imágenes como sospechosas",
      report_right: "Reportar infracción a mis derechos",
      article_denounced: "Esta publicación infrige mis derechos",
      sellers_title: "Vendedores participantes",
      sellers_subtitle:
        "Para marcar como sospechosa una publicación, haz clic en la tarjeta del vendedor correspondiente.",
      seller_generic_response_title: "Respuestas de este vendedor",
      seller_generic_response_subtitle:
        "Aquí verás las respuestas dadas por el vendedor que selecciones en la lista de participantes.",
      seller_response_title: "Respuestas de {seller_name}",
      seller_response_subtitle:
        "Explora las respuestas del vendedor que seleccionaste para revisar si hay otros motivos de sospecha.",
      seller_response_title_empty: "Respuestas de este vendedor",
      seller_response_subtitle_empty:
        "El vendedor que seleccionaste aún no ha contestado ninguna pregunta.",
      seller_history: "Ver historial del vendedor",
      seller_sales: "Ventas",
      country_search: "Comenzar la búsqueda en",
      newness_box: {
        title: "Esta es una publicación de catálogo",
        subtitle:
          "Se trata de una página que agrupa, bajo un único contenido, diferentes publicaciones del mismo producto ofrecidas por diferentes vendedores.",
        "item1-a": "Para denunciar las fotos de la publicación,",
        "item1-b":
          "selecciona la opción “Marcar imágenes como sospechosas” en la sección que muestra los detalles del producto.",
        "item2-a": "Para reportar una publicación de un vendedor específico,",
        "item2-b":
          "explora la columna “Vendedores participantes” y toca el botón “Marcar como sospechosa”.",
        button: "Conoce más",
        close: "Cerrar",
      },
      sort_sellers: {
        item1: "A-Z",
        item2: "Precio de menor a mayor",
        item3: "Precio de mayor a menor",
        item4: "Tiendas oficiales primero",
      },
      seller_responses_search: "Buscar en respuestas...",
    },
    messageBox: {
      title: "¡Hola!",
      subtitle:
        "Queremos saber cómo ha sido tu experiencia con la herramienta de denuncias, ",
      subtitleStrong: "¿Tienes 3 minutos para contarnos?",
      button1: "Sí, claro",
      button2: "Ya lo hice",
    },
    efficiency: {
      title: "Eficiencia de la cuenta",
      default:
        "No contamos con datos suficientes para evaluar la eficiencia de tu cuenta. ",
      high: "Felicitaciones, la eficiencia de tu cuenta es muy buena. \n",
      med: "Ten cuidado, tu cuenta está en riesgo de ser restringida.  \n",
      risk: "Tu cuenta está restringida. Revisa tu eficiencia y conoce los motivos de tu restricción. ",
      subtitle: "Revisar eficiencia. ",
      subtitle2: "Conocer más. ",
    },
    blindage: {
      tooltip_title: "Ya denunciaste esta publicacion antes",
      tooltip_content:
        "Detectamos que ya habías denunciado esta publicación basándote en la misma infracción, y decidiste retirar la denuncia. Para volver a denunciarla, debes señalar si encontraste una infracción diferente. De lo contrario, podrás volver a denunciarla a partir del ",
      tooltip_link: "Quiero contactarme",
      modal_excel: {
        title: "Lista de publicaciones sospechosas enviadas",
        subtitle_errors: "Algunas publicaciones que importaste tienen errores.",
        subtitle_success: `Podrás revisar los elementos importados en la sección de "Publicaciones sospechosas".`,
        notBlindages_found: "publicaciones para denunciar",
        notBlindage_found: "publicacion para denunciar",
        blindages_found: "publicaciones que no pudieron ser procesadas",
        blindage_found: "publicación que no pudo ser procesada",
        blindages_found2: "Tus publicaciones no se pudieron procesar",
        blindage_found2: "Tu publicación no se pudo procesar",
        blindage_content:
          "Detectamos que ya habías denunciado estas publicaciones basándote en la misma infracción, y decidiste retirar la denuncia.",
      },
      modal_multiple: {
        title: "Publicaciones sospechosas seleccionadas",
        subtitle_success: `Podrás revisar los elementos seleccionados con éxito en la sección de "Publicaciones sospechosas".`,
        subtitle_errors: `Algunas publicaciones que seleccionaste tienen errores. Podrás revisar los elementos seleccionados con éxito en la sección de "Publicaciones sospechosas".`,
        elements: "elementos seleccionados",
        element: "elemento seleccionado",
        notBlindages_found: "publicaciones seleccionadas con éxito",
        notBlindage_found: "publicacion seleccionada con éxito",
        blindages_found: "publicaciones que no pudieron ser procesadas",
        blindage_found: "publicación que no pudo ser procesada",
      },
    },
    noData: {
      title: "Nada por aquí ...",
      subtitle: "Cuando denuncies publicaciones podrás encontrarlas aquí.",
    },
    today: "hoy",
    tomorrow: "mañana",
    "see-details-denounced-onboarding": "Consulta los detalles",
    "see-details-denounced-onboarding-description":
      "Accede a la publicación denunciada y recuerda el motivo de cada denuncia.",
    "denounced-details-analyce-onboarding": "Analiza la respuesta",
    "denounced-details-check-onboarding":
      "Revisa los cambios que hizo el vendedor, sus documentos y su historial de denuncias.",
    "denounced-detail-send-answer-onboarding": "Envía tu respuesta",
    "denounced-detail-send-answer-onboarding-description":
      "Confirma o retracta tu denuncia una vez hayas analizado la respuesta del vendedor.",
    "redesigned-the-section": "¡Rediseñamos la sección!",
    "redesigned-the-section-description":
      "Ahora podrás analizar cada respuestas más fácilmente.",
    "your-denounced-ordered": "Tus denuncias, bien ordenadas",
    "your-denounced-ordered-description":
      "Las que vencen pronto aparecen primero, para que nada se te escape.",
    "your-answers-informed": "Analiza todos los detalles",
    "your-answers-informed-description":
      "Consulta en un mismo lugar los detalles de la denuncia y la respuesta del vendedor.",
    "complaint-management": "Gestión de denuncias",
    "complaint-management-answer":
      "Aquí podrás seguir el estado de tus denuncias y responder a los vendedores para decidir si quieres confirmar o retractar tu denuncia.",
    "answers-to-be-reviewed": "Respuestas por revisar",
    "answers-to-be-reviewed-answer":
      "Muestra una tabla con todas las denuncias donde has recibido respuesta del vendedor. Haz clic en el botón 'Analizar respuesta' para ver sus detalles.",
    "analyze-response": "Analizar respuesta",
    "analyze-response-answer":
      " Aquí verás el detalle de la denuncia que realizaste, así como los datos del vendedor que publicó el producto que denunciaste.",
    "analyze-response-answer-two":
      "En este apartado podrás ver los comentarios o archivos adjuntos que el vendedor te envió como respuesta.",
    "complaint-management-image-1": "pending_notices_ES.jpg",
    "complaint-management-image-2": "pending_notices_res_ES.jpg",
    "detail-answer-positive-one":
      "Si consideras que su respuesta es suficiente",
    "detail-answer-positive-two":
      "y quieres dar de baja tu denuncia, debes retractar tu denuncia.",
    "detail-answer-negative-one":
      "Si consideras que la respuesta del vendedor es insuficiente,",
    "detail-answer-negative-two":
      "deberás confirmar tu denuncia. En este caso, además, te pediremos que selecciones el motivo por el que rechazaste la respuesta del vendedor.",
    "closed-complaints": "Denuncias cerradas",
    "closed-complaints-answer":
      "Aquí podrás consultar el histórico de tus denuncias cerradas. Las denuncias cerradas son aquellas que finalizan porque ya le respondiste al vendedor, porque la rechazamos o porque ya dimos de baja al vendedor o la publicación.",
    "publication-detail-text": "Detalle de la publicación",
    "sold-text": "vendidos",
    "available-text:": "disponibles",
    "description-text": "Descripción",
    "attributes-text": "Características",
    "name-text": "Nombre",
    "gender-text": "Genero",
    "brand-text": "Marca",
    "article-text": "Articulo",
    "see-every-description": "Ver toda la descripción",
    "comment-pending": "Comentario",
    "optional-option": "Opcional",
    pending_textarea_placeholder:
      "Cuéntale al vendedor más detalles sobre tu decisión.",
    "send-answer-pending": "Envíar respuesta",
    "comment-detail-pending":
      "Revisa la respuesta del vendedor antes de tomar tu decisión.",
    "answer-for-seller": "Respuesta para el vendedor",
    "confirm-report": "Ratificar denuncia",
    "retract-report": "Retirar denuncia",
    "reason-for-confirmation": "Motivo de la confirmación",
    "incorrect-documentation":
      "La documentación no se corresponde con el producto denunciado",
    "unreadable-documentation": "La documentación es ilegible",
    "unauthorized-user-document": "No está autorizado para usar este contenido",
    "does-not-justify-stock": "No justifica el stock",
    "counterfeited-buy": "Hace una compra y es falsificado",
    "luxury-product-with-a-lot-stock":
      "Es un producto de lujo con demasiado stock",
    "low-average-price": "Tiene precio por debajo de la media",
    "comments-seller-null": "El vendedor no realizó comentarios",
    "list-to-back-closed-denounced": "Volver al listado de denuncias cerradas",
    "list-to-back-pending-denounced":
      "Volver al listado de denuncias pendientes",
    "list-to-back-noticed-denounced":
      "Volver al listado de historial de denuncias",
    "denounced-state": "Estado de la denuncia",
    rollbackeable: "Quiero reactivar esta publicación",
    "rollback-tooltip":
      "Marca esta casilla si quieres retirar esta denuncia y reactivar la publicación",
    "rollbackeable-description-title": "Motivo del retracto",
    "rollback-placeholder": "Cuéntanos tus razones para retirar la denuncia",
    "rollback-case": "Reactivar publicación",
    "rollback-success": "¡Listo! La denuncia fue retirada",
    "rollback-error":
      "No pudimos guardar tus cambios. Por favor, intentalo de nuevo. ",
    "retracted-reason-title": "Motivo del retracto:",
    "see-documentation": "Ver documentación",
    "attached-documentation": "Documentación adjunta",
    "see-images-updated": "Ver imágenes modificadas",
    "images-updated": "Imágenes modificadas",
    "reported-images": "Imágenes reportadas",
    "actual-images": "Imágenes actuales",
    "commets-seller": "Comentario del vendedor",
    "sellers-history": "Historial del vendedor",
    "sellers-history-modal": {
      title: "Denuncias recibidas por el vendedor",
      subtitle:
        "Es el historial de las denuncias más recientes recibidas por este vendedor, tanto enviadas por ti como por otros titulares de derechos.",
      receive_s: "denuncia recibida",
      receive_p: "denuncias recibidas",
      receive_n: "Sin denuncias recibidas",
      sent_s: "denuncia enviada",
      sent_p: "denuncias enviadas",
      accepted_s: "denuncia aceptada",
      accepted_p: "denuncias aceptadas",
      table: {
        th1: "Publicación",
        th2: "Fecha de denuncia",
        th3: "Motivo de denuncia",
        th4: "Respuesta del vendedor",
      },
    },
    "translate-contents": "Traducir contenidos",
    "answer-seller": "Respuesta del vendedor",
    "see-ofert-auditor": "Ver comentario del auditor",
    "auditor-comment": "Comentario del auditor",
    "auditor-said": "dijo",
    "motif-denounced": "Motivo de la denuncia",
    "see-publication": "Ver publicación denunciada",
    "download-screenshot": "Descargar captura",
    "denounced-publication": "Publicación denunciada",
    "send-answer-condition":
      "Si no respondes antes del vencimiento la publicacion volverá a mostrarse en Mercado Libre",
    "send-answer": "Envía tu respuesta al vendedor",
    "closed-title": "Denuncia cerrada",
    "closed-subtitle":
      "Puedes consultar los detalles y retirar tu denuncia, pero no comunicarte con el vendedor.",
    "rejected-title": "No pudimos procesar la denuncia",
    "rejected-subtitle":
      "Revisa la razón por la cual no pudimos hacerlo en el estado de la denuncia.",
    expires_text: "expira",
    "back-to-list": "Volver al listado",
    "see-report": "Ver denuncia",
    answer: "Responder",
    see: "Ver",
    "analyse-response": "Analizar respuesta",
    expires: "Vence",
    motif: "Motivo de denuncia",
    action: "Acción",
    statusMsgPaused: "Publicación pausada",
    automation: {
      newbie: {
        title:
          "Alcanzaste el limite de 50 denuncias diarias, no podras denunciar hasta mañana",
        text1:
          "Esto sucede porque tu cuenta es nueva y necesitamos evaluar que las denuncias que realizas corresponde a los derechos que adheriste",
        text2: "Por hoy, solo podras marcar las publicaciones como sospechosas",
        button: "Conocer más",
      },
      newbieInfo: {
        title: "Tienes un límite de 50 denuncias por día",
        text1:
          "Esto sucede porque tu cuenta es nueva y necesitamos revisar que las denuncias que realizaste corresponde a los derechos que adheriste",
        text2: "Aún puedes realizar {denounces} denuncias hoy.",
        text3:
          "Después de esto solo podras marcar las publicaciones como sospechosas",
        button: "Conocer más",
      },
      pending_cn: {
        title: "Tienes {30} respuestas de vendedores que vencen hoy",
        text1:
          "Debes revisarlas y responder para poder seguir denunciando. Si no lo haces, las publicaciones que denunciaste se reactivarán automáticamente.",
        text2:
          "Recuerda que, una vez recibidas, tienes 4 días para revisar la respuesta de los vendedores.",
        button: "Ir a responder",
      },
      limited_by_inefficient: {
        with_denounces_p: {
          title: "Tu cuenta ha sido restringida a {50} denuncias diarias",
          text1: "Esto sucede porque la eficiencia de tu cuenta es muy baja",
          text2: "Aún puedes realizar {number} denuncias hoy.",
          text3:
            "Despues de esto solo podras marcar las publicaciones como sospechosas ",
          button: "Conocer más",
        },
        with_denounces_s: {
          title: "Tu cuenta ha sido restringida a {50} denuncias diarias",
          text1: "Esto sucede porque la eficiencia de tu cuenta es muy baja",
          text2: "Aún puedes realizar {number} denuncia hoy.",
          text3:
            "Despues de esto solo podras marcar las publicaciones como sospechosas ",
          button: "Conocer más",
        },
        without_denounces: {
          title: "Llegaste al limite de denuncias diarias",
          text1:
            "Por hoy solo podras marcar las publicaciones como sospechosas",
          text2:
            "Te recomendamos revisar en la seccion de eficiencia los motivos por los cuales tu cuenta fue restringida.",
          button: "Conocer más",
        },
        general: {
          text3: "Revisa tu historial para conocer más.",
          button1: "Ir a Historial de denuncias",
          button2: "Conocer más",
        },
        day_limit: {
          title: "No podrás realizar más denuncias por el resto del día",
          text1: "Ya denunciaste tu máximo diario de 30.000 publicaciones.",
          text2:
            "Ten en cuenta que aun así podrás marcar publicaciones como sospechosas, pero no podrás enviar nuevas denuncias hasta mañana.",
        },
      },
      items_inactive: {
        case_1: "Hoy solo podrás enviar {50} denuncias.",
        case_2: {
          text1: "Ya enviaste {20} de tus {50} denuncias máximas diarias.",
          text2: "Te quedan {30}.",
        },
        case_3: {
          text1: "Ya denunciaste tu máximo diario de {50} publicaciones.",
          text2: "No podrás realizar más denuncias por el resto del día.",
        },
        tooltip:
          "Restringimos tus denuncias porque más del 60% fueron rechazadas en los últimos 2 meses.",
      },
      generic: {
        title: "Ya no puedes realizar denuncias",
        text: "Ten en cuenta que podrás marcar publicaciones como sospechosas, pero no podrás enviar las denuncias hasta el {date}.",
      },
      tooltip_all: {
        title: "¿Quieres enviar todas las denuncias?",
        body: "Cuando lo hagas, notificaremos al vendedor para que pueda enviarte su respuesta.",
        ok: "Enviar denuncias",
        cancel: "Cancelar",
      },
      tooltip_partial: {
        title_p: "Solo puedes enviar {10} denuncias",
        title_s: "Solo puedes enviar 1 denuncia",
        body_1:
          "Las publicaciones que seleccionaste superan tu límite de denuncias diarias, ",
        body_2: "pero todavía puedes enviar {10}.",
        ok_p: "Enviar {10} denuncias",
        ok_s: "Enviar 1 denuncia",
        cancel: "Cancelar",
      },
    },
    counter_notice_new_deadline_1:
      "Cambiamos los plazos para responder denuncias.",
    counter_notice_new_deadline_2:
      "Ahora tendrás 4 días para revisar la respuesta de los vendedores.",
    article_detail: {
      breadcrumb_label: "Detalle Artículo",
      description: "Descripción",
      empty_description:
        "El vendedor no agregó una descripción para este producto.",
      back_to_results: "Volver a resultados",
      back_to_suggested_listings: "Volver al listado de sugeridos",
      view_all_description: "Ver toda la descripción",
      last_questions: "Últimas preguntas",
      last_questions_catalogo: "Respuestas de",
      empty_questions: "Nadie hizo preguntas todavía.",
      view_more_questions: "Ver más preguntas",
      marked_as_suspicious: "Publicación marcada como sospechosa",
      motive: "Motivo",
      marked_as_trusted: "Publicación marcada como confiable",
      by: "por",
    },
    mark_as_suspicious_label: "Sospechoso",
    mark_as_trusted_label: "Confiable",
    mark_as_catalogo_label: "Ver detalles",
    original: "Orig",
    translation: "Traducción",
    translate_click_here_original: "Ver original",
    translate_click_here_translated: "Ver traducción",
    maintenance: {
      we_are_working: "¡Estamos trabajando en mejoras!",
      come_back_soon:
        "Regresa pronto y vuelve a iniciar sesión para conocerlas.",
    },
    all_auditors: "Todos los auditores",
    there_are_not_listings_that_match:
      "No hay publicaciones que coincidan con tu búsqueda",
    view_more: "ver más...",
    know_more: "Conoce más",
    view_more_suggested: "Ver más sugeridos",
    view_all: "Ver todos",
    video_tutotial_link:
      "https://www.youtube.com/embed/s7Sa9KuAtxk?rel=0&amp;showinfo=0",
    title_snapshot_dowload: "Descarga de Snapshot",
    snapshot_dowload_error:
      "Hubo un error al obtener el link de descarga, intente luego.",
    snapshot_link_label: "Clic aquí para descargar el snapshot",
    new_content_available: "Nuevo contenido disponible.",
    check_report_codes_for:
      "Asegurate que tienes códigos de denuncias para los siguientes paises: ",
    no_report_codes_available_for: "No tienes códigos de denuncia para ",
    about_to_delete_live_search: "Deseas eliminar esta Livesearch?",
    activate_all: "Activar todos",
    countries_toggle_info:
      "Haz clic sobre los países que quieres excluir de tu búsqueda. Para desactivar todos, utiliza el botón de abajo.",
    authorize_btn: "Click aquí para autorizar",
    message_authorize:
      "Aún debes autorizar los siguientes países para realizar búsquedas y denuncias en ellos",
    activate_none: "Desactivar todos",
    expand: "Expandir",
    collapse: "Colapsar",
    activity: "Actividad",
    activity_uppercase: "ACTIVIDAD",
    apply: "Aplicar",
    apply_filters: "Aplicar cambios y actualizar artículos",
    apply_filters_error:
      "Lo lamentamos, pero no pudimos aplicar los filtros. Por favor, intenta nuevamente.",
    applying_filters: "Aplicando filtros",
    are_you_sure: "¿Estas seguro?",
    question_send_all_notices: "¿Enviar todas las denuncias?",
    article: "Publicación",
    disabled_article: "publicación desactivada",
    article_change_status_error:
      "Lo lamentamos, pero no pudimos cambiar el estado del articulo.",
    article_change_status_error_already:
      "Ya fue auditado dentro de tu organización.",
    articles_change_status_error:
      "Lo lamentamos, pero no pudimos cambiar el estado de los articulos.",
    articles_change_status_error_already:
      "Fueron auditados dentro de tu organización.",
    article_drop: "baja",
    article_drops: "bajas",
    article_from_white_seller: "Vendedor Objetado",
    article_ok: "Artículo Válido",
    article_invalid: "No puedes denunciar esta publicación",
    article_reference: "Id",
    article_brand_name: "Marca",
    article_part_name: "Número de pieza",
    article_type: "Tipo de Artículo",
    article_without_picture: "ARTICULO SIN FOTO",
    in_moderation_card_title: "Denuncia enviada",
    in_moderation_card_text_1: "Comprueba el estado de tus denuncias desde el ",
    in_moderation_card_text_2: "Historial de denuncias.",
    in_moderation_card_text_3: "",
    articles_to_ok_warn:
      "Estás a punto de cambiar el estado del artículo a OK. Esta acción no se puede deshacer.",
    articles_to_pending_warn:
      "El artículo volverá a estado pendiente y se perderá la información de denuncia. ¿Estás seguro?",
    mark: "Marcar",
    articles_to_report: "publicaciones como sospechosas",
    article_to_report: "publicación como sospechosa",
    articles_to_report_menu: "Publicaciones sospechosas por denunciar",
    articles_to_report_short: "Art. por denunciar",
    audited: "Auditados",
    audited_batch_title: "Auditados en modo selección multiple",
    result: "Resultado",
    results: "Resultados",
    ready_to_audit: "LISTA PARA AUDITAR",
    available_quantity: "disponibles",
    avoided_words: "Palabras a evitar",
    batch_mode: "Selección múltiple",
    batch_mode_info:
      "Selecciona múltiples publicaciones tocando el botón de Selección Múltiple, y haciendo clic en los pulgares arriba o abajo.",
    brand: "Marca",
    brands: "Marcas",
    ok: "Si",
    accept: "Aceptar",
    cancel: "Cancelar",
    cancel_batch_warning:
      "Vas a perder todos las publicaciones seleccionadas. ¿Estás seguro?",
    category: "Categoria",
    choose_another_brand: "Seleccionar otra marca",
    choose_brand: "Seleccione una marca",
    condition_all: "Todos",
    condition_from_blacklisted_sellers: "De Vendedores Observados",
    condition_new: "Nuevo",
    condition_used: "Usado",
    condition_refurbished: "Reacondicionado",
    "official-store": "Producto de Tienda Oficial",
    "switch-translate": {
      label: "Traducir publicación",
      tooltip: {
        title: "Traduce esta publicación",
        description: "Activa nuestro traductor y analiza mejor tu denuncia.",
        button: "Entendido",
      },
      tooltip_2: {
        title: "Desactivamos esta función mientras denuncias",
        description:
          "Lo hicimos para que puedas analizar y marcar infracciones sobre los textos originales.",
        button: "Entendido",
      },
      snack_message: {
        title: "Esta es una traducción automática y podría contener errores",
        description:
          "Ten en cuenta que la funcionalidad es un servicio prestado por un tercero y no debe ser interpretado como una traducción oficial. Es solo una sugerencias y no es de uso obligatorio.",
      },
      error:
        "Tuvimos un problema y no pudimos cargar tu traducción. Inténtalo de nuevo.",
    },
    languages: {
      es: "Español",
      en: "Inglés",
      pt: "Portugués",
    },
    countries: {
      AA: "Global",
      AR: "Argentina",
      AT: "Austria",
      BE: "Belgica",
      BO: "Bolivia",
      BR: "Brasil",
      CA: "Canada",
      CH: "Suiza",
      CL: "Chile",
      CO: "Colombia",
      CR: "Costa Rica",
      DE: "Alemania",
      DO: "República Dominicana",
      RD: "República Dominicana",
      EC: "Ecuador",
      EN: "Inglaterra",
      ES: "Espana",
      FR: "Francia",
      GB: "Reino Unido",
      GT: "Guatemala",
      HN: "Honduras",
      IE: "Irlanda",
      IT: "Italia",
      MX: "Mexico",
      NI: "Nicaragua",
      NL: "Holanda",
      PA: "Panama",
      PE: "Peru",
      PR: "Puerto Rico",
      PT: "Portugal",
      PY: "Paraguay",
      SC: "Escocia",
      SV: "El Salvador",
      US: "Estados Unidos",
      UY: "Uruguay",
      VE: "Venezuela",
    },
    countries_legal: {
      AR: "Argentina",
      BO: "Bolivia",
      BR: "Brasil",
      CL: "Chile",
      CO: "Colombia",
      CR: "Costa Rica",
      DO: "Dominicana",
      EC: "Ecuador",
      GT: "Guatemala",
      HN: "Honduras",
      MX: "Mexico",
      NI: "Nicaragua",
      PA: "Panama",
      PE: "Peru",
      PY: "Paraguay",
      SV: "El Salvador",
      UY: "Uruguay",
      VE: "Venezuela",
    },
    country: "País",
    countries_select: "Países",
    country_selector_clarification:
      "Selecciona aquellos países en los que deseas realizar la busqueda",
    all_the_countries: "Todos los países",
    create_live_search_with_current_listings:
      "Crear Livesearch de los artículos actuales",
    create_report_results: "Crear reporte de Livesearch",
    create_livesearch_report_created: "Reporte creado satisfactoriamente.",
    create_livesearch_report_error: "Hubo un error al crear el reporte.",
    go_to_ls_reports: "Ir a reportes de Livesearch",
    create_search_of_black_sellers:
      "Crear Livesearch de todos los Vendedores Observados",
    creating: "creando",
    currency_report_results: "Seleccione moneda para reporte de resultados",
    current_listings: "Artículos Actuales",
    onboarding_slide: {
      skip: "Omitir",
      start: "Comenzar",
      slide1: {
        title: "Antes de empezar...",
        text: "Te damos algunos consejos para que puedas aprovechar al máximo nuestra herramienta.",
      },
      slide2: {
        title: "Haz búsquedas masivas",
        text: "Utiliza el campo de búsqueda para encontrar infracciones en cualquiera de los sitios de Mercado Libre.",
        image: "slide1-es.png",
      },
      slide3: {
        title: "Crea tus filtros de búsqueda",
        text: "Personaliza tus búsquedas para encontrar infractores más fácil y rápido.",
        image: "slide2-es.png",
      },
      slide4: {
        title: "Múltiples denuncias a la vez",
        text: "Reporta varias publicaciones desde una misma pantalla y en pocos clicks.",
        image: "slide3-es.png",
      },
      slide5: {
        title: "Envía todas tus denuncias",
        text: "Cuando estés listo, puedes confirmar uno a uno o todos a la vez.",
        image: "slide4-es.png",
      },
      slide6: {
        title: "¡Listo! Comienza a denunciar",
        text: "Recuerda que si tienes dudas, solo debes dirigirte a la seccion Ayuda, desde el menú.",
      },
    },
    re_branding: {
      modal_title: "Ahora somos el Brand Protection Program de Mercado Libre",
      bullet_1: {
        title: "Nuevo nombre",
        subtitle:
          "El Programa de Protección de Propiedad Intelectual ahora se llama Brand Protection Program.",
      },
      bullet_2: {
        title: "Nuevo sitio",
        subtitle: "Ahora con más información y las preguntas más frecuentes.",
      },
      bullet_3: {
        title: "El compromiso de siempre",
        subtitle:
          "Seguimos trabajando para mejorar el programa y hacer que el proceso de denuncias sea cada vez más fácil.",
      },
      check_out_what_is_new: "¡Conoce todas las novedades!",
      go_to_bpp_site: "Ir al sitio",
    },
    suggestions_slide: {
      next: "Siguiente",
      start: "Entendido",
      new_user: {
        slide1: {
          title: "Una forma más fácil de encontrar infracciones",
          text: "Analiza publicaciones que detectamos automáticamente en base a nuestros procesos y tu historial de denuncias.",
        },
        slide2: {
          title: "Cuanto más analices, mejores serán las sugerencias",
          text: "Aprendemos de tus denuncias para seguir mejorando nuestras detecciones y actualizar tus recomendados.",
        },
        slide3: {
          title: "¡Nuevo! Filtra por país y sigue tus denuncias",
          text: "Encuentra publicaciones sugeridas en los países que te interesan, y mira el progreso de tus denuncias de la última semana.",
        },
      },
      old_user: {
        tag_text: "¡Nuevo!",
        slide1: {
          title: "Filtra por país y sigue tus denuncias",
          text: "Encuentra publicaciones sugeridas en los países que te interesan, y mira el progreso de tus denuncias de la última semana.",
        },
      },
    },
    verify_profile: {
      title: "Actualiza los datos de tu cuenta",
      subtitle:
        "Para que podamos seguir procesando correctamente tus denuncias, necesitamos que actualices tu información.",
      expires: "Tienes tiempo hasta el",
      verify_btn: "Actualizar datos",
      remind_later: "Recuérdame más tarde",
      alert: {
        main_text: "Actualiza los datos de tu cuenta.",
        secondary_text:
          "Asegúrate de que tu información esté completa para que podamos seguir procesando tus denuncias.",
        review_data: "Actualizar datos",
      },
      form: {
        title: "Perfil de la cuenta",
        subtitle: "Actualiza los datos de tu cuenta. Recuerda que ",
        subtitle_b: "no podrás editarlos luego de confirmarlos.",
        confirm: "Confirmar",
        account_type: {
          verified_title: "Tipo de usuario",
          title: "¿Qué tipo de usuario quieres adherir?",
          item_titular: "Soy un titular de derechos o representante legal",
          helper_titular:
            "Es decir, eres el dueño de los derechos que vas a adherir o su representante legal.",
          item_apoderado: "Soy un apoderado de los derechos",
          helper_apoderado:
            "Es decir, eres una persona física o jurídica autorizada por el titular de los derechos para actuar en su nombre.",
        },
        attorney_accreditation: {
          title: "Acredítate como apoderado",
          name: "Nombre del apoderado:",
          name_helper: "Puede ser una persona física o una empresa.",
          subtitle: "Datos de contacto del titular",
          subtitle_helper_1: "opcional",
          subtitle_helper_2:
            "Puede ser el gerente de protección de marcas o IP counsel.",
          contact_name: "Nombre de contacto",
          email: "E-mail",
        },
        account_info: {
          title: "Información sobre la cuenta",
          subtitle: "El titular de derechos es:",
          natural: "Persona física",
          legal: "Persona jurídica",
          physical_fields: {
            name: "Nombre y apellido",
            country: "País",
            type: "Tipo de identificación",
            number: "Número de identificación",
          },
          legal_fields: {
            name: "Nombre o razón social",
            country: "País",
            type: "Tipo de identificación tributaria",
            number: "Número de identificación tributaria",
          },
        },
        attorney_contact: {
          title: "Contacto del representante",
          helper:
            "Son los datos que usaremos desde Mercado Libre para contactarnos con el representante o administrador de la cuenta.",
          name: "Nombre y apellido",
          code: "Código internacional",
          tel: "Teléfono",
          tel_helper: "Incluye el código de área.",
          email: "E-mail corporativo",
          email_ex: "Por ejemplo, juan.lopez@tuempresa.com",
          lang: "Idioma de contacto",
          lang_placeholder: "Selecciona",
        },
        public_data: {
          title: "Datos públicos",
          helper:
            "Son los datos que le enviaremos al vendedor denunciado para que se contacte contigo una vez finalizado el proceso de denuncia. Asegúrate de colocar el nombre de la marca o grupo económico para que el vendedor sepa quién lo está denunciando.",
          name: "Nombre a mostrar",
          email: "E-mail",
          email_helper:
            "Puede ser genérico y no debe ser un mail ya registrado en Mercado Libre.",
        },
        exit_modal: {
          title: "¿Quieres salir del formulario?",
          text: "Si abandonas la página, deberás volver a completar todos los datos.",
          back: "Volver",
          exit: "Salir",
        },
        success: {
          text: "¡Listo! Ya actualizamos tus datos.",
        },
        error_email: {
          text: "El e-mail que ingresaste ya está registrado en Mercado Libre. Ingresa otro.",
        },
      },
    },
    suggested_listings: {
      title: "Publicaciones sugeridas",
      subtitle_p:
        "En esta sección verás las publicaciones que encontramos en base a nuestras detecciones y tu historial de denuncias.",
      subtitle_s:
        "Son publicaciones que encontramos en base a nuestras detecciones y tu historial de denuncias.",
      stats: {
        box_1: {
          text1_p: "publicaciones por denunciar",
          text1_s: "publicación por denunciar",
          text2: "Denunciar",
        },
        box_2: {
          text1: "Tu progreso en los últimos 7 días",
          text2_p: "marcadas como sospechosas",
          text2_s: "marcada como sospechosa",
          text3_p: "marcadas como confiables",
          text3_s: "marcada como confiable",
          text4: "Evolución de denuncias",
          text5: "hoy",
        },
        tooltip_p: "publicaciones denunciadas",
        tooltip_s: "publicación denunciada",
      },
      item1s: "de publicaciones analizadas",
      item2s: "marcada como confiable",
      item3s1: "marcada como sospechosa",
      item3s2: "Ir a denunciar",
      item1p: "de publicaciones analizadas",
      item2p: "marcadas como confiables",
      item3p1: "marcadas como sospechosas",
      item3p2: "Ir a denunciar",
      not_results_title: "¡Ya no tienes más sugeridos!",
      not_results_link_label: "Realizar una búsqueda",
    },
    suggested_discard: {
      text_btn: "Descartar publicación",
      tooltip: {
        title: "¿No estás seguro de qué hacer?",
        description:
          "Ahora puedes descartar la publicación para que dejemos de mostrarla en tus resultados.",
      },
      modal: {
        title: "¿Por qué quieres descartar esta publicación?",
        subtitle:
          "Ten en cuenta que volveremos a mostrarla en tus resultados de búsqueda si el vendedor la modifica o recibe nuevas preguntas.",
        discard_item: {
          item1: "No estoy seguro de que contenga una infracción",
          item2:
            "La publicación o el producto no se corresponden con mis derechos adheridos",
          item3:
            "La publicación o el producto no es relevante para mi organización",
          item4: "Otro motivo",
          item1_subtext:
            "Es decir, no encontraste indicios precisos de que el producto es falsificado, de infracciones de propiedad intelectual, etc.",
          item2_subtext:
            "Es decir, los derechos que tienes no te permiten denunciar infracciones sobre esta publicación o producto.",
          item3_subtext:
            "Es decir, no es el foco principal de la marca o tu operación.",
          item4_subtext: "",
        },
        step_2: {
          title: "¿Puedes contarnos un poco más?",
          subtitle: "(Opcional)",
          placeholder: "Cuéntanos por qué quieres descartar la publicación.",
          btn_text: "Descartar publicación",
        },
      },
      done_article_discarded:
        "¡Hecho! Si la publicación recibe cambios, volveremos a mostrártela.",
    },
    file_uploader: {
      formats: "Formatos",
      maximum_size: "Peso máximo",
    },
    xlsx_import_success: {
      done: "¡Listo!",
      marked_listing: "Marcaste 1 publicación como sospechosa.",
      marked_listings: "Marcaste {0} publicaciones como sospechosas.",
      go_to_report: "Ir a denunciar",
    },
    add_suspicious_xlsx: {
      alert_easier: "Denunciar masivamente ya es más fácil.",
      alert_info:
        "Ahora puedes cargar una planilla con todas las publicaciones que quieras denunciar.",
      alert_btn_label: "Cargar masivamente",
      tooltip_onboarding_title: "¿Necesitas importar un listado?",
      tooltip_onboarding_text:
        "Ahora puedes descargar tu excel de publicaciones sospechosas desde esta sección.",
      tootip_btn_title: "Descarga aquí tu planilla",
      tootip_btn_text:
        "Solo podrás importar archivos que hayas descargado desde aquí.",
      modal_title: "Importar lista de publicaciones sospechosas",
      modal_subtitle_text1:
        "Ten en cuenta que solo podrás importar archivos que hayas descargado desde esta sección.  ",
      modal_subtitle_text2: "Descarga tu planilla de Excel",
      btn_import: "Enviar archivo",
      elements: "elementos importados",
      element: "elemento importado",
      listings_found: "publicaciones importadas con éxito",
      listing_found: "publicación importada con éxito",
      errors: "Errores",
      error: "publicacion que no pudo ser procesada",
      listings_duplicated: "publicaciones ya marcadas como sospechosas",
      listing_duplicated: "publicación ya marcada como sospechosa",
      download_detail: "Descarga la plantilla para revisar los errores",
      process_results_title: "Procesamos correctamente los datos subidos",
      found_count_label: "Cantidad de publicaciones que puedes agregar: ",
      not_found_count_label: "Cantidad de publicaciones que no encontramos: ",
      invalid_count_label: "Cantidad de ids inválidos: ",
      how_to_title: "Cómo armar el listado",
      how_to_bullet_1: "Incluye una publicación por fila.",
      how_to_bullet_2: "Puedes incluir tanto ID de la publicación como su URL.",
      how_to_bullet_3: "Agrega solo publicaciones del mismo país.",
      how_to_bullet_4:
        "Puedes importar un máximo de 1000 publicaciones a la vez.",
      how_to_bullet_5:
        "Asegúrate de que todas las publicaciones compartan motivo de denuncia.",
      how_to_important: "¡Importante!",
      how_to_download: "Descargar ejemplo",
      processing: "Procesando",
      empty_text: "Agregar archivo",
      empty_drag_text: "También puedes arrastrarlos",
      on_drag_text: "Suelta aquí el archivo para procesarlo",
      drag_file_too_large: "El archivo es muy pesado",
      error_reading_file:
        "No pudimos revisar las publicaciones. Inténtalo de nuevo más tarde.",
      only_duplicated_listings_message:
        "Las publicaciones ya están marcadas como sospechosas.",
      only_duplicated_listing_message:
        "La publicación ya está marcada como sospechosa.",
      not_enrolled_rigths_listings_message:
        "Las publicaciones son de un país donde no tienes derechos adheridos.",
      not_enrolled_rigths_listing_message:
        "La publicación es de un país donde no tienes derechos adheridos.",
      not_valid_found_message:
        "No encontramos publicaciones con los IDs o URLs que cargaste.",
      only_one_site: "El archivo contiene publicaciones de más de un país.",
      only_one_file: "No puedes subir más de un archivo a la vez.",
      incorrect_file_format: "El archivo tiene el formato incorrecto.",
      too_many_message: "El archivo contiene más de 1000 elementos.",
      file_exceeds_maximum_size: "El archivo supera el peso máximo.",
      reason_select: "Seleccione el motivo de la denuncia",
      reason_no_selected: "El motivo no fue seleccionado",
      comment_title: "Agregar comentario",
      comment_description:
        "Aquí puedes agregar un comentario que acompañe a tu denuncia",
      comment_warning: " El texto debe tener al menos 5 caracteres.",
    },
    live_search_report_form: {
      new_report: "Nuevo reporte",
      source: "Fuente",
      option_meli_dump: "Dump",
      option_meli_api: "API",
      search_word: "Término a buscar",
      nickname_seller: "Nickname del Vendedor",
      listing_state: "Estado de publicaciones",
      all: "Todas",
      active: "Activas",
      paused: "Pausadas",
      countries: "Países",
      no_countries: "No hay países seleccionados",
      preview: "Aplicar y buscar",
      create: "Crear reporte",
      selected_country: "país seleccionado",
      selected_countries: "países seleccionados",
      clarification:
        "El reporte se genera utilizando datos actualizados una vez por día.",
    },
    denunciated: "denunciados",
    denunciation: "denuncia",
    download_report_results: "Descargar Reporte de Resultados",
    ebay: "eBay",
    edit: "Editar",
    email: "Email",
    enter_keyword_for_live_search: "Selecciona el criterio de búsqueda",
    start_the_search_in: "Comenzar la búsqueda en ",
    stored_searches: "Búsquedas guardadas",
    export_data: {
      reports: {
        all_sellers: "Todos los vendedores",
        audits_by: "Auditado por",
        avoid_words: "Palabras a evitar",
        blacklisted_sellers: "Vendedores observados",
        brand: "Marca",
        city: "Ciudad",
        brands: "Marcas",
        ok: "Si",
        accept: "Aceptar",
        cancel: "Cancelar",
        cancel_batch_warning:
          "Vas a perder todos las publicaciones seleccionadas. ¿Estás seguro?",
        category: "Categoria",
        choose_another_brand: "Seleccionar otra marca",
        choose_brand: "Seleccione una marca",
        condition_all: "Todos",
        condition_from_blacklisted_sellers: "De Vendedores Observados",
        condition_new: "Nuevo",
        condition_used: "Usado",
        condition_refurbished: "Reacondicionado",
        "official-store": "Producto de Tienda Oficial",
        languages: {
          es: "Español",
          en: "Inglés",
          pt: "Portugués",
        },
        countries: {
          AA: "Global",
          AR: "Argentina",
          AT: "Austria",
          BE: "Belgica",
          BO: "Bolivia",
          BR: "Brasil",
          CA: "Canada",
          CH: "Suiza",
          CL: "Chile",
          CO: "Colombia",
          CR: "Costa Rica",
          DE: "Alemania",
          DO: "República Dominicana",
          RD: "República Dominicana",
          EC: "Ecuador",
          EN: "Inglaterra",
          ES: "Espana",
          FR: "Francia",
          GB: "Reino Unido",
          GT: "Guatemala",
          HN: "Honduras",
          IE: "Irlanda",
          IT: "Italia",
          MX: "Mexico",
          NI: "Nicaragua",
          NL: "Holanda",
          PA: "Panama",
          PE: "Peru",
          PR: "Puerto Rico",
          PT: "Portugal",
          PY: "Paraguay",
          SC: "Escocia",
          SV: "El Salvador",
          US: "Estados Unidos",
          UY: "Uruguay",
          VE: "Venezuela",
        },
        country: "País",
        currency: "Moneda",
        date_finish: "Fecha Fin",
        date_start: "Fecha Inicio",
        generated_by: "Generado por",
        id: "Id",
        items_sold: "Artículos vendidos",
        listing_lnk: "Link artículo",
        listing_title: "Título artículo",
        listing_type: "Tipo de artículo",
        listings: "Publicaciones",
        location: "Ubicación",
        marketplace: "Marketplace",
        marketplaces: "Marketplaces",
        offered_stock: "Stock ofrecido",
        original_currency: "Moneda orig.",
        original_price: "Precio orig.",
        picture_1: "Imagen 1",
        picture_2: "Imagen 2",
        picture_3: "Imagen 3",
        picture_4: "Imagen 4",
        price: "Precio",
        price_range: "Rango de precios",
        quantity: "Cantidad",
        removed_listings: "Artículos Removidos",
        report_code: "Código reporte",
        report_currency: "Moneda Reporte",
        report_date: "Fecha reporte",
        report_description: "Descripción reporte",
        report_tags: "Tags Reporte",
        reported_listings: "Artículos Reportados",
        reports: "Reportes",
        required_words: "Palabras requeridas",
        search_by_marketplaces:
          "Búsqueda realizada en los siguientes Marketplaces",
        search_terms: "Términos de Búsqueda",
        seller: "Vendedor",
        seller_username: "Usuario Vendedor",
        sellers_type: "Vendedores",
        snapshot: "Snapshot",
        sold: "Vendido",
        stock: "Stock",
        tag: "Tag",
        take_down_date: "Fecha fin",
        report_status: "Estado Reporte",
        top_countries: "Top 5 Países",
        top_countries_by_publications:
          "Top 5 Países según número de publicaciones",
        top_reported_sellers: "Top 5 Vendedores Reportados",
        top_sellers_by_publications:
          "Top 5 Vendedores según número de publicaciones",
        total_value_reported_listings: "Valor Total de Artículos Reportados",
        user: "Usuario",
        value_higher_than: "Mayor a",
        value_less_than: "Menor a",
        volume_offered: "Volumen ofrecido",
        volume_reported: "Volumen reportado",
        volume_sold: "Volumen vendido",
        worksheet_executive_summary: "Resumen Ejecutivo",
        worksheet_listings: "Publicaciones",
        worksheet_marketplaces: "Marketplaces",
        worksheet_reported_listings: "Artículos Reportados",
        worksheet_sellers: "Vendedores",
        worksheet_sellers_info:
          "* Algunos Sitios no ofrecen datos sobre la cantidad de Valores y número de artículos vendidos.",
        worksheet_tags: "Tags",
      },
    },
    exit_create_account: "Estas seguro que deseas salir?",
    create_auditor: "Ahora puedes crear usuarios Auditores",
    auditor_user: "Usuario auditor",
    name: "Nombre",
    last_name: "Apellido",
    user_email: "E-mail",
    user: "Usuario",
    add_auditor: "Agregar auditor",
    new_password_rules:
      "La contraseña debe ser mayor a 8 caracteres y diferente al actual.",
    error_password_confirm: "Las contraseñas deben coincidir",
    error_password_length:
      "La contraseña debe contener como mínimo 8 caracteres",
    wrong_password: "Usuario o contraseña incorrecta.",
    forgot_password: "¿Olvidaste tu contraseña?",
    confirmation_code: "Código de verificación",
    enter_confirmation_code: "Ingresa el código de verificación",
    sent_to:
      "Te lo enviamos por e-mail a $email. Si no lo encuentras, revisa en correo no deseado.",
    did_not_receive: "No recibí el código",
    yes: "Si",
    no: "No",
    wrong_confirmation_code: "Código de verificación incorrecto",
    send_new_code: "Enviar un nuevo código?",
    set_custom_password: "Por favor setea el password personalizado",
    create_user: "Crear usuario",
    create_users: "Crear usuarios",
    continue_button: "Continuar",
    account_data: "Datos de la cuenta",
    account_data_subtitle:
      "Luego podrás vincular todos tus usuarios de Brand Protection Program y denunciar en varios países al mismo tiempo.",
    default_account: "Este será el usuario con el que ingresarás",
    user_already_exist: "Ya tienes una cuenta creada con este e-mail.",
    user_not_found: "El usuario no existe en el país seleccionado.",
    auditors: "Auditores",
    auditor: "Auditor",
    meli_token_authenticate_error:
      "Usuario incorrecto. Ingresa con tu cuenta de Brand Protection Program.",
    code_resend: "Te hemos reenviado el codigo a tu email",
    account_welcome_message:
      "ahora crea una cuenta para usar la nueva herramienta de denuncias",
    account_created_title: "¡Listo! Te registraste en la nueva herramienta",
    account_created_message:
      "Ya puedes empezar a denunciar de forma más fácil y eficiente con las nuevas herramientas de Brand Protection Program.",
    create_account_success: "Usuario creado con exito",
    main_account_info: "$account, ten en en cuenta que:",
    account_created_reminder:
      "Si normalmente denuncias en Brand Protection Program a través de proveedores que protegen tus derechos y ahora creaste un usuario para denunciar tú directamente, considera que las respuestas a tus denuncias las recibirán los proveedores y es posible que lo desconozcan.",
    account_created_recomendation:
      "Por eso, te recomendamos que te contactes con ellos y coordines el seguimiento.",
    look_what_you_can_do: "Mira todo lo que podrás hacer",
    begin_to_denounce: "Empezar a denunciar",
    create_account_error:
      "Hubo un error mientras creabamos el usuario, por favor intenta nuevamente",
    export_xls: "Exportar Plantilla",
    "main-page-welcome-msg": "Renovamos nuestro Brand Protection Program",
    "main-page-title":
      "Creamos nuevos procesos para identificar a vendedores infractores y sancionarlos mucho mas rapido",
    "main-page-subtitle": "Ademas, te damos las mejores herramientas:",
    "main-page-item-1":
      "Busca y denuncia facilmente en varios paises al mismo tiempo",
    "main-page-item-2": "Crea y guarda tus propios criterios de busqueda",
    "main-page-item-3":
      "Elige en que vendedores quieres enfocarte y analiza solo sus publicaciones",
    "main-page-item-4":
      "Accede a un reporte con todas las publicaciones denunciadas y removidas",
    "main-page-item-5":
      "Adhiere y renueva tus derechos mucho mas facil y rapido",
    register_as_auditor:
      "Registrate como auditor para empezar a denunciar y conocer todos los beneficios",
    more_about_pppi: "Mas sobre Brand Protection Program",
    help_message:
      "Encuentra todo lo que necesitas en la sección de %{copy_help}",
    dont_know_how_to_start: "¿No sabés cómo empezar?",
    close_help_dialog_error:
      "Hubo un error mientras cerrabamos el diálogo, por favor intentalo luego.",
    need_help: "¿Necesitas ayuda?",
    help: "Ayuda",
    help_href: "/noindex/pppi/support/",
    know_more_href: "https://www.mercadolibre.com.ar/noindex/pppi/landings/bpp",
    register_href:
      "https://www.mercadolibre.com.ar/noindex/pppi/rights/enroll/",
    contact_us: "Contactanos",
    buy_and_sell: "¡Compra y vende desde tu celular!",
    enter_your_email: "Ingresa tu email",
    old_password: "Contraseña anterior",
    new_password: "Nueva contraseña",
    new_password_confirm: "Confirmar nueva contraseña",
    password_changed_success: "Su contraseña se ha cambiado exitosamente",
    password_changed_error:
      "Lo sentimos, su contraseña no pudo ser cambiada, por favor intentelo luego",
    enter_your_new_password: "Ingrese su nueva contraseña",
    password_confirmation: "Confirmar contraseña",
    change_password: "Cambiar contraseña",
    changing_password: "Cambiando contraseña",
    send_email: "enviar",
    sending_email: "enviando email",
    sendind_email: "enviando",
    failed_articles_message:
      "Esta Livesearch no pudo ser completada exitosamente, hubo %{count} artículos que no han sido cargados",
    filter_profile: {
      "clarification-p1": "Al hacer click en ",
      "clarification-p2": "Aplicar y buscar, ",
      "clarification-p3": "tus preferencias se guardarán automáticamente.",
      all: "Todos",
      apply_filters: "Aplicar y buscar",
      article_condition: "Condición",
      categories: "Categorías",
      none_category: "Ninguna",
      no_subcategories_for: "No hay subcategorías para",
      no_category_selected: "No has seleccionado ninguna categoría",
      info_categories_language:
        "Mostraremos las categorías en el idioma del sitio donde estás buscando.",
      change: "Modificar",
      article_new: "Nuevos",
      article_used: "Usados",
      available_quantity: "Stock ofrecido",
      banned_keywords: "Términos a evitar",
      copy_of: "Copia de %{name}",
      "country.name": "País",
      "prices.USD": "Precio",
      date_range: "Rango de fechas",
      default: "Predeterminada",
      extended_data_warning:
        "Esto traerá artículos solamente de sitios que provean este tipo de información.",
      extended_data_sites_list: "Sitios disponibles: %{sites}.",
      fromDate: "Fecha Desde",
      fromPrice: "Mínimo",
      greater_than: "Mayor que",
      no_name: "Sin nombre",
      portal: "Portal",
      price_range: "Precio",
      required_keywords: "Términos requeridos",
      seller: "Vendedores",
      seller_blacklisted: "Vendedores observados",
      no_seller_selected: "No has seleccionado ningún vendedor",
      none_seller: "Ninguno",
      sold_quantity: "Stock vendido",
      sort_by: "Ordenar por",
      select_sort_by: "Seleccione",
      title: "Perfiles de filtros para esta Livesearch",
      toPrice: "Máximo",
      toDate: "Fecha Hasta",
      select_one_site: "Selecciona al menos un país",
      indexed_at: "indexado",
    },
    filter: "Editar filtros",
    clean_filter: "Quitar filtros",
    filtered: "Filtrados",
    filtered_results: "Resultados filtrados",
    filters_title: "FILTROS DE BUSQUEDA - Refinar resultados",
    forgot_your_password: "¿Olvidaste tu contraseña?",
    generating_report_results: "Generando Reporte de Resultados",
    greater_than: "mayor que",
    home_title: "Brand Monitoring",
    in_progress: "En proceso",
    processing: "Procesando",
    language: "Idioma",
    live_search: "Livesearch",
    live_search_all_loaded: "Todos los resultados han sido mostrados",
    live_search_fetch_error:
      "Lo lamentamos, pero hubo un error cuando buscábamos la Livesearch. Por favor, intenta nuevamente.",
    live_search_create_error:
      "Lo lamentamos, pero hubo un error cuando creabamos la Livesearch. Por favor, intenta nuevamente.",
    live_search_refresh_error:
      "Lo lamentamos, pero hubo un error cuando atualizabamos la Livesearch. Por favor. intenta nuevamente.",
    live_search_delete_error:
      "Lo lamentamos, pero hubo un error cuando eliminabamos la Livesearch. Por favor, intenta nuevamente.",
    live_search_filters: "Filtros de búsqueda",
    live_search_loading_results: "Cargando mas resultados",
    live_search_queue_header:
      "COLA DE TRABAJO - Búsquedas realizadas y en proceso",
    live_search_recent: "RECIENTE",
    live_search_refinements_error:
      "Lo lamentamos, pero hubo un error cuando aplicabamos los filtros. Por favor, intenta nuevamente.",
    live_search_reloading: "Volviendo a ejecutar",
    live_search_results: "Resultados filtrados de búsqueda",
    live_search_empty: "Aún no se han cargado articulos",
    show_hidden_publications: "Ver publicaciones ocultas",
    hidden_tooltip_text:
      "Hemos ocultado las publicaciones que marcaste como no infractoras, que ya denunciaste o que pertenecen a vendedores confiables.",
    in_moderation: "Reportadas",
    validated: "No infractoras",
    denounced: "Reportadas",
    multiple_selector: {
      advice:
        "Ten en cuenta que no puedes utilizar selección múltiple para denunciar por derechos de autor de imágenes.",
      trusted: "Confiables",
      suspicious: "Sospechosas",
      tooltip_text:
        "No puedes utilizar esta función para denunciar usos no autorizados de imágenes.",
    },
    quick_search: "Búsqueda rápida",
    quick_search_placeholder:
      "Busca por palabra clave, código EAN o número de pieza",
    publication_id: "ID de la publicación",
    publication_id_placeholder: "MLB-745531777 o MLB745531777",
    publication_id_helper:
      "Encuentra el ID completo en la URL de la publicación. Puedes ingresarlo sin guion medio.",
    seller_nickname: "Nombre del vendedor",
    search_label_seller: "Términos a buscar (opcional)",
    search_label_publication: "Búsqueda rápida",
    url: "URL",
    no_matches: "No hay publicaciones que coincidan con tu búsqueda",
    no_matches_advice1:
      "Revisa la ortografía o utiliza palabras más genéricas.",
    no_matches_advice2: "Prueba eliminando algunos de los términos.",
    no_matches_advice3: "Asegúrate de que el vendedor siga activo en el sitio.",
    no_matches_advice4: "Editar filtros",
    nothing_here: "Nada por acá...",
    you_did_not_do_any_search_yet: "Aún no realizaste ninguna búsqueda",
    live_search_empty_filtered: "No hay resultados para los filtros aplicados",
    live_search_status_count_error:
      "Hubo un error al buscar la cantidad de estados de los articulos",
    live_search_new_processed_count: "Nuevos resultados disponibles",
    live_search_processed_count_error:
      "Hubo un error mientras buscabamos los articulos procesados, por favor intenta luego",
    loading: "Cargando...",
    loading_publications: "Buscando publicaciones...",
    loading_articles: "Cargando artículos",
    loading_brands: "Cargando marcas...",
    loading_live_searches: "Cargando Livesearches",
    loading_portals: "Cargando portales",
    loading_results: "Cargando Resultados",
    login: "Iniciar Sesión",
    loging_in: "Ingresando...",
    recover_password: "Recuperar contraseña",
    contact: "Contacto",
    login_welcome_message:
      "Ingresa con el usuario y contraseña de la nueva herramienta de denuncias",
    logout: "Cerrar sesión",
    enroll_rights: "Adherir derechos",
    new_profile: "Nuevo perfil",
    change_profile: "Cambiar perfil",
    create_profile: "Crear",
    select_profile: "Seleccionar perfil",
    create_profile_success: "Has creado el perfil de manera exitosa",
    create_profile_error: "Hubo un error cuando creabamos el perfil",
    change_profile_success: "Tu perfil fue cambiado con exito.",
    fetch_profiles_error:
      "Hubo un error cuando buscabamos los perfiles disponibles",
    change_profile_error:
      "Hubo un error mientras cambiabamos de perfil, por favor intentalo luego",
    no_profiles: "No hay perfiles disponibles",
    create_account: "Crear cuenta",
    lower_than: "menor que",
    mercadolibre: "Mercado Libre",
    money_saved: "Volumen salvado",
    new_live_search: "Nueva Livesearch",
    new_search: "Nueva Búsqueda",
    new_product: "Producto nuevo",
    refurbished_product: "Producto Reacondicionado",
    next: "Siguiente",
    no_data: "N/D",
    optional: "opcional",
    password: "Contraseña",
    create_user_password: "Clave",
    pending: "pendiente",
    phone: "Tel",
    placeholder_avoided_keywords: "Agrega aquí las palabras a evitar",
    placeholder_required_keywords: "Agrega aquí las palabras requeridas",
    previous: "Anterior",
    price: "Precio",
    price_range: "Rango de precios",
    prices_larger_than_currency: "Precios mayores que",
    prices_smaller_than_currency: "Precios menores que",
    quebarato: "Que Barato",
    refine_search_in_sites: "Refinar Búsqueda en Portales",
    remember_me: "Recordarme",
    repeat_password: "Repetir clave",
    report_label: "Marcar como sospechosa",
    report_label_plural: "Marcar como sospechosas",
    edit_report_label: "Guardar cambios",
    report_article_error:
      "Lo lamentamos, pero no pudimos enviar la denuncia del artículo. Por favor, intenta nuevamente.",
    report_article_undo_error:
      "Lo lamentamos, pero no pudimos deshacer. Por favor, intenta nuevamente.",
    report_code: "Infracción",
    report_code_2: "Motivo de Denuncia",
    report_description_label: "Más comentarios",
    report_description: "Descripción de la denuncia",
    report_description_2: "Descripcion Complementaria de Denuncia",
    report_placeholder:
      "Escribe aquí tus comentarios sobre la publicación que estás por reportar (opcional).",
    report_to_marketplace: "Reportar a Portal",
    report_status_takedown_submitted: "Denuncia enviada",
    report_status_listing_removed: "Artículo removido",
    report_status_takedown_failed: "Fallo denuncia",
    reported_article: "Artículo denunciado",
    reported_to_site: "Reportado al Portal",
    reports: "Denuncias",
    reports_uppercase: "DENUNCIAS",
    reported_by: "Reportado por",
    reputation_excelent: "Excelente",
    reputation_bad: "Mala",
    reputation_good: "Buena",
    reputation_regular: "Regular",
    reputation_unknown: "Desconocida",
    required_words: "Palabras requeridas",
    results_per_page: "Resultados por página",
    screenshoot: "Screenshot al momento de Denuncia",
    search_of_black_sellers_created:
      "Livesearch de todos los Vendedores Observados creada",
    searches: "Búsquedas",
    searches_uppercase: "BUSQUEDAS",
    selected_articles: "Publicaciones Seleccionadas",
    seller: "Vendedor",
    seller_blacklist: "Observar vendedor",
    black_seller: "Vendedor observado",
    seller_search_create: "Crear livesearch del vendedor",
    seller_search_created: "Livesearch del Vendedor creada",
    seller_white: "Vendedor confiable",
    unset_seller_error:
      "No pudimos actualizar la informacion del vendedor, por favor intenta mas tarde",
    sellers: "Vendedores",
    sellers_black: "Vendedores observados",
    sellers_white: "Vendedores confiables",
    send_me_reset_password_instructions:
      "Enviarme instrucciones para resetear la clave",
    sending_report: "Enviando denuncia",
    settings: "Configuración",
    profile: "Perfil",
    show_current_listing: "Mostrar Livesearch del Vendedor",
    enter: "Ingresar",
    entering: "Ingresando",
    sign_in: "Iniciar Sesión",
    signing_in: "Iniciando sesion",
    sign_up: "Registrarse",
    site: "sitio",
    site_reputation: "Reputación",
    hello: "Hola",
    not_found: "Parece que esta página no existe",
    initial_page: "Ir a la página inicial",
    authorize_sites: "Vincular países",
    authorized_site_message:
      "Vincula todas las cuentas que usas en Brand Protection Program, para denunciar en todos los países a la vez. Solo necesitarás ingresar el usuario y contraseña de cada país.",
    authorized_site_title:
      "Vimos que tienes permisos para denunciar en más países",
    authorized: "Autorizado",
    "new-authorize": "Nuevo",
    authorized_country: "¡Listo! Vinculaste la cuenta de",
    link_other_countries: "Vincular otros países",
    linked: "Vinculado",
    link: "Vincular",
    authorize_site_error:
      "Ocurrió un error mientras autorizabamos el sitio, por favor vuelve a intentarlo más tarde.",
    authorize_site_error_not_pppi:
      "El usuario con el que estás intentando autenticar no tiene permisos de denuncia, intenta desloguearte en Mercado Libre y volver a intentarlo con el usuario correcto.",
    incorrect_site_authentication:
      "Ha intentado loguearse con un usuario que no pertenece al país que seleccionó. Debe desloguearse en Mercado Libre de dicho país y volver a intentarlo con un usuario correspondiente.",
    authorize_different_account:
      "Ha intentado loguearse con un usuario que no pertenece a su account.",
    go_to_main_page: "Empezar a denunciar",
    start_reporting: "Empezar a denunciar",
    sold: "ventas",
    sold_quantity: "vendidos",
    sort_by: "Ordenar por",
    start_live_search: "Procesar búsqueda",
    "dont-repeat-livesearch": "No puede haber livesearches repetidos",
    status: "Estado",
    adding_reported_articles: "Agregando Publicaciones Sospechosas...",
    add_reported_articles_by_xlsx:
      "Agregar Publicaciones Sospechosas desde Excel",
    submit_all_reported_articles: "Enviar todas las denuncias",
    submit_selected_reported_articles_s: "Enviar {10} denuncia",
    submit_selected_reported_articles_p: "Enviar {10} denuncias",
    submit_all_reported_articles_tooltip_pending_cn:
      "No puedes enviar denuncias hasta que revises tus respuestas pendientes.",
    submit_all_reported_articles_tooltip_restricted:
      "Restringimos tus denuncias mientras analizamos que se correspondan con tus derechos adheridos.",
    submit_all_reported_ready: "¡Listo! Ya enviamos tus denuncias.",
    submit_one_reported_ready: "¡Listo! Ya enviamos tu denuncia.",
    filter_text: "Filtrar y ordenar",
    filter_text_2: "Filtrar",
    filter_modal: {
      filter_1: "Ordenar por",
      filter_2: "Motivo de denuncia",
      filter_3: "País",
      filter_4: "Por Estado",
      apply: "Aplicar filtros",
      apply_2: "Aplicar",
      clean: "Limpiar filtros",
      filter_1_text_1: "Reportes más recientes",
      filter_1_text_2: "Reportes más antiguos",
      all_reasons: "Todos los motivos de denuncia",
    },
    search_text: "Buscar por # o título",
    select_all: "Seleccionar todos",
    selected_notices_s: "Tienes {10} denuncia seleccionada",
    selected_notices_p: "Tienes {10} denuncias seleccionadas",
    select_page: "Seleccionar esta página",
    unselect_all: "Deseleccionar todos",
    unselect_page: "Deseleccionar esta página",
    clean_all_inactive_btn: "Limpiar inactivas",
    clean_one_inactive_btn: "Limpiar inactiva",
    clean_notices: "Eliminar denuncias",
    clean_selected: "Eliminar denuncias seleccionadas",
    filters_applied: "Filtros aplicados",
    clean_all: "Eliminar todas las denuncias",
    clean_inactive: "Eliminar publicaciones inactivas",
    clean_all_inactive_ready: "¡Listo! Eliminamos tus denuncias",
    clean_one_inactive_ready: "¡Listo! Eliminamos tu denuncia",
    submit_report: "Enviar denuncia",
    edit_report: "Editar denuncia",
    submit_reported_articles: "Enviar denuncias",
    create_live_search_current_listings:
      "Crear Livesearch de los vendedores observados",
    questions_and_answers: "PREGUNTAS Y RESPUESTAS",
    fetch_questions_and_answers_error:
      "Hubo un error obteniendo las preguntas y respuestas.",
    support_form: {
      title: "Soporte",
      subtitle:
        "Desde aquí te ayudaremos a resolver todas tus dudas sobre el Brand Protection Program.",
      textarea_label: "Ingresa tu consulta",
      textarea_placeholder: "¿Con qué podemos ayudarte?",
      send_to: "Te responderemos a",
      submit: "Enviar consulta",
      success_modal_title: "¡Recibimos tu consulta!",
      success_modal_will_respond:
        "Te responderemos por e-mail dentro de las próximas 48 horas.",
      success_modal_cx_number: "Tu número de consulta es ",
      success_modal_understood: "Entendido",
      error_message: "No pudimos enviar tu consulta. Inténtalo de nuevo.",
    },
    support: {
      title: "Contenidos de ayuda",
      "contents-steps": {
        "sug-1-1": "Publicaciones Sugeridas",
        "sug-1-2": "Estadísticas",
        "sug-1-3": "Evolución semanal",
        "sug-1-4": "Pincheta o Apilable",
        "1-1": "Realizar una búsqueda",
        "1-2": "Cómo realizar una búsqueda",
        "1-3": "Filtros de búsqueda",
        "1-4": "Detalle de la publicación",
        "2-1": "Evaluar una publicación",
        "2-2": "Validar como sospechosa una publicación",
        "3-1": "Selección múltiple",
        "3-2": "Como sospechoso",
        "3-3": "Como confiable",
        "4-1": "Publicaciones sospechosas por denunciar",
        "4-2": "Gestión de artículos por denunciar",
        "8-1": "Publicaciones de catálogo",
        "8-2": "¿Qué es una publicación de catálogo?",
        "8-3": "Cómo explorar los vendedores participantes",
        "8-4": "Qué datos define el vendedor",
        "8-5": "Quién responde las preguntas de los compradores",
        "8-6": "Cómo denunciar una publicación de catálogo",
        "5-1": "Evaluar vendedores",
        "5-2": "Cómo evaluar vendedores",
        "5-3": "Cómo ver todos los vendedores confiables u observados",
        "6-1": "Gestión de denuncias",
        "6-2": "Denuncias pendientes",
        "7-1": "Adherir nuevos derechos",
        "7-2": "Cómo adherir nuevos derechos",
      },
      "answer-steps": {
        "sug-1-1":
          "En esta sección verás las publicaciones que encontramos en base a nuestras detecciones y tu historial de denuncias.",
        "sug-1-image": "01_Esp_captura_suger.png",
        "sug-2-1":
          "Este complemento tiene con el objetivo de tener visible las publicaciones sospechosas listas para denunciar.",
        "sug-2-2a": "El accionable ",
        "sug-2-2b": "'Denunciar' ",
        "sug-2-2c": "lo direcciona directamente a ",
        "sug-2-2d": "'Publicaciones sospechosas por denunciar'",
        "sug-3-1":
          "Acá encontrás una breve reseña de su trabajo cumplido y que podrás visualizar tu evolución en tu última semana.",
        "sug-3-2":
          "Los valores que también se muestran son la cantidad de items marcadas como sospechosas y confiables en la última semana. Ten en cuenta que estos valores son tomados sólo de los items sugeridos por Mercado Libre.",
        "sug-3-3":
          "El gráfico muestra el flujo de las denuncias diarias en la última semana. Posando el mouse sobre el gráfico te mostrará el valor del día en particular seleccionado.",
        "sug-3-4":
          "El porcentaje en el marcador verde mostrará si creciste con tu número de denuncias en esta sección y en rojo si has disminuido en comparación a la semana anterior.",
        "sug-2-image": "02_Esp_captura_suger.png",
        "sug-3-2a": "Esta función te permite ",
        "sug-3-2b": "fijar elementos en los primeros resultados ",
        "sug-3-2c":
          "para luego accionar o no perderlos de vista. También da la posibilidad de hacer un barrido rápido de lo que te sugerimos e ir apilando para luego centrarte en el análisis con más criterio.",
        "sug-3-image": "03_Esp_captura_suger.png",
        "1-1p1a":
          "En esta sección podrás realizar búsquedas de publicaciones por ",
        "1-1p1b": "país* ",
        "1-1p1c": "utilizando diferentes ",
        "1-1p1d": "criterios de búsqueda: ",
        "1-1p2t": "- Nombre de la publicación",
        "1-1p2":
          "Busca utilizando palabras clave relacionadas con tu marca o derechos adheridos.",
        "1-1p3t": "- ID de la publicación",
        "1-1p3":
          "Es el número que identifica de forma única cada publicación en Mercado Libre.",
        "1-1p4t": "- Nombre del vendedor",
        "1-1p4": "Es el apodo del vendedor dentro de Mercado Libre.",
        "1-1p5t": "- URL de la publicación",
        "1-1p5": "Es el enlace directo a una publicación.",
        "1-1-clarification":
          "* Recuerda que puedes realizar búsquedas solo en aquellos países que tengas adheridos.",
        "1-1-image": "04_Esp_captura_suger.png",
        "1-2-1p1a": "En el menú izquierdo elige ",
        "1-2-1p1b": "Búsquedas.",
        "1-2-2":
          "Selecciona el criterio de búsqueda que vas a utilizar y completa el campo de búsqueda.",
        "1-2-3a":
          "Indica el país en el que quieres buscar publicaciones. Por defecto buscará en ",
        "1-2-3b": "Argentina",
        "1-2-3c":
          ", pero tendrá la posibilidad de cambiar de país si así lo deseas.",
        "1-2-4a": "Inicia la búsqueda haciendo clic en ",
        "1-2-4b": "Buscar.",
        "1-3a":
          "Una vez dentro del listado de publicaciones, puedes modificar los filtros de búsqueda para refinar los resultados. Para hacerlo, haz clic en el ",
        "1-3b": "ícono de flecha ",
        "1-3c":
          "arriba a la izquierda y se desplegará un listado con todos los filtros disponibles.",
        "1-2-image": "05_Esp_captura_suger.png",
        "1-3-1-title": "Precio",
        "1-3-1-text":
          "Define el rango de búsqueda por precio mínimo y máximo de los productos.",
        "1-3-2-title": "Condición",
        "1-3-2-text": "Si es un producto nuevo o usado.",
        "1-3-3-title": "Categorías",
        "1-3-3-text":
          "Permite filtrar según las categorías de publicaciones dentro del sitio.",
        "1-3-4-title": "Vendedores observados",
        "1-3-4-text":
          "Aplica la búsqueda sobre todos los vendedores que marcaste como observados.",
        "1-3-7a": "Luego de aplicar los filtros, haz clic en ",
        "1-3-7b": "Aplicar y buscar.",
        "1-3-8":
          "* Recuerda que puedes combinar los filtros para mejorar la calidad de los resultados.",
        "1-4":
          "En la pantalla de resultados, haz clic en un producto para ver su detalle. Ahí podrás ver la información de la publicación, los datos y reputación del vendedor, así como también el precio y fotos del producto.",
        "1-3-image": "06_Esp_captura_suger.png",
        "2-1": "Validar una publicación",
        "2-1-1": "Desde el listado de publicaciones",
        "2-1-2a":
          "Pasa el mouse sobre las publicaciones. Haz clic en los botones SOSPECHOSO y CONFIABLE para validarlos.",
        "2-1-image": "07_Esp_captura_suger.png",
        "2-1-2b":
          "Si eliges el botón CONFIABLE, las publicaciones desaparecerán de los resultados de búsqueda automáticamente. Si eliges el botón SOSPECHOSO, podrás “marcarlo como sospechoso” y colocar el motivo.",
        "2-2-image": "08_Esp_captura_suger.png",
        "2-2-1": "Desde el detalle de la publicación",
        "2-2-1a":
          "Ahí podrás indicar con el botón CONFIABLE o SOSPECHOSO si consideras que está o no infringiendo alguno de tus derechos adheridos.",
        "2-3-image": "09_Esp_captura_suger.png",
        "2-2-1b":
          "Todos los productos que ya hayas validado como CONFIABLES o SOSPECHOSOS serán excluidos automáticamente de tus próximos resultados de búsqueda.",
        "2-3-1": "Validar como sospechosa una publicación",
        "2-3-2":
          "Si consideras que un artículo está infringiendo uno o más de tus derechos, haz clic en el botón SOSPECHOSO.",
        "2-3-3":
          "En el menú lateral derecho junto al detalle de la publicación podrás marcar el motivo de denuncia de la publicación.",
        "2-3-4": "Los motivos serán los siguientes.",
        "2-3-4a": "En el producto:",
        "2-3-4a-1": "Producto falsificado",
        "2-3-4a-2": "Infracción de derechos de autor",
        "2-3-4b": "En la publicación:",
        "2-3-4b-1": "Uso ilegítimo de marca registrada",
        "2-3-4b-2": "Uso no autorizado de imágenes de terceros",
        "2-3-4b-3": "Uso no autorizado de imagen personal",
        "2-4-1": "Una vez seleccionado el motivo y hacer clic en ",
        "2-4-2": "'Continuar' ",
        "2-4-3":
          "podrás agregar un comentario. Luego, haz clic en 'Marcar como sospechosa'.",
        "2-4-image": "10_Esp_captura_suger.png",
        "2-5-1":
          "Para enviar la denuncia sobre esa publicación, ingresa a la sección de 'Publicaciones sospechosas por denunciar' en el menú lateral izquierdo.",
        "3-1-1":
          "Este complemento te permite marcar varias publicaciones como ",
        "3-1-2": "'Confiables' o 'Sospechosas' ",
        "3-1-3":
          "a la vez, de forma ágil y rápida. Ten en cuenta que solo podrás marcar esas publicaciones como sospechosas si comparten el motivo de denuncia.",
        "3-1-image": "11_Esp_captura_suger.png",
        "3-2-1": "Para marcar varias publicaciones como 'Sospechosas'",
        "3-2-2":
          "Haz clic en el botón de 'Selección múltiple' y marca como 'sospechosas' todas las publicaciones que desees seleccionar. Verás que al hacerlo se resaltan en color rojo.",
        "3-2-3":
          "Toca el botón de 'Aplicar' para marcarlas como sospechosas y continuar la denuncia.",
        "3-2-image": "12_Esp_captura_suger.png",
        "3-3-1": "Para marcar varias publicaciones como 'Confiables'",
        "3-3-2":
          "Haz clic en el botón de 'Selección múltiple' y marca como 'confiable' todas las publicaciones que desees seleccionar. Verás que al hacerlo se resaltan en color verde.",
        "3-3-3":
          "Toca el botón de 'Aplicar' para que desaparezcan del listado de búsqueda.",
        "3-3-image": "13_Esp_captura_suger.png",
        "4-1-1": "Gestión de artículos por denunciar",
        "4-1-2":
          "En el menú de la izquierda en las sección 'Publicaciones sospechosas por denunciar' encontrarás todas las publicaciones que marcaste como posibles infractoras, pero que todavía no denunciaste.",
        "4-1-image": "14_Esp_captura_suger.png",
        "4-2-1": "Confirmar denuncia",
        "4-2-2a":
          "Si estás seguro de que quieres denunciar la publicación, haz clic en ",
        "4-2-2b": "'Enviar denuncia'. ",
        "4-2-2c":
          "Para enviar todas las denuncias al mismo tiempo, puedes hacerlo haciendo clic en ",
        "4-2-2d": "'Enviar todas las denuncias'.",
        "4-3-1": "Cancelar denuncia",
        "4-3-2": "Si deseas cancelar la denuncia, haz clic en ",
        "4-3-3": "'Desestimar'",
        "4-4-1": "Revisar detalle",
        "4-4-2":
          "Si quieres revisar el detalle de la denuncia, haz clic en el ícono '+'.",
        "4-5-1": "Editar motivo de denuncia",
        "4-5-2a":
          "Si quieres cambiar el motivo de denuncia haz clic en el botón ",
        "4-5-2b": "'Editar'",
        "4-5-2c": "y sigue los pasos para colocar el nuevo motivo.",
        "5-1-1":
          "En esta sección puedes ver todos los vendedores que colocaste bajo observación y a los que consideras confiables.",
        "5-1-image": "15_Esp_captura_suger.png",
        "5-2-1": "Cómo evaluar vendedores",
        "5-2-2":
          "En la vista de detalle de la publicación, verás tres botones que te permitirán evaluar vendedores.",
        "5-3-1": "Vendedor observado",
        "5-3-2":
          "Le agrega un banderín negro al vendedor. Una vez que identificas a un vendedor como bajo observación, sus productos aparecerán destacados en tus búsquedas para que puedas identificarlos más fácilmente.",
        "5-2-image": "16_Esp_captura_suger.png",
        "5-4-1": "Vendedor confiable",
        "5-4-2":
          "Cuando marcas al vendedor como confiable se agregará automáticamente al listado de vendedores confiables y sus publicaciones serán excluidas de los resultados de búsqueda.",
        "5-5-1": "Crear livesearch del vendedor",
        "5-5-2a": "Crea una ",
        "5-5-2b": "'Búsqueda guardada' ",
        "5-5-2c":
          "del vendedor y de la categoría del producto para que puedas revisarlas más tarde. Puedes acceder a ella desde el menú de la izquierda, en Búsquedas.",
        "5-6-1": "Vendedores observados",
        "5-6-2":
          "En el detalle de la publicación, haz clic en el ícono de la lupa al lado del nombre del vendedor. Así verás a todos los vendedores que marcaste bajo observación en tus búsquedas.",
        "5-6-3a":
          "Puedes acceder a ese listado desde el menú de la izquierda, en ",
        "5-6-3b": "Vendedores > Vendedores observados.",
        "5-3-image": "17_Esp_captura_suger.png",
        "5-6-4":
          "La tabla muestra el país en el que opera cada vendedor observado, así como su nombre de usuario en Mercado Libre, la cantidad de reportes recibidos y la cantidad de productos en su listado de ventas.",
        "5-7-1": "Para revisar todos los artículos de vendedores observados",
        "5-7-2a": "Primero haz clic en ",
        "5-7-2b": "'Crear una nueva livesearch'",
        "5-7-2c":
          "dentro de la tabla. Esa búsqueda quedará guardada en la sección 'Búsquedas'.",
        "5-4-image": "18_Esp_captura_suger.png",
        "5-8-1": "Vendedores confiables",
        "5-8-2":
          "Aquí verás a todos los vendedores que marcaste como confiables en tus búsquedas.",
        "5-8-3a": "Puedes acceder a ella desde el menú de la izquierda, en ",
        "5-8-3b": "Vendedores > Vendedores confiables.",
        "5-5-image": "19_Esp_captura_suger.png",
        "5-8-4":
          "La tabla muestra el país en el que opera el vendedor, su nombre usuario en Mercado Libre, y la cantidad de productos en su listado de ventas.",
        "5-9-1": "Para revisar todos los artículos de vendedores observados",
        "5-9-2a": "Primero debemos hacer clic en ",
        "5-9-2b": "'Crear una nueva livesearch' ",
        "5-9-2c":
          "dentro de la tabla. Esa búsqueda quedará guardada en la sección 'Búsquedas'.",
        "6-1-1":
          "Aquí podrás seguir el estado de tus denuncias y responder a los vendedores para decidir si quieres confirmar o retractar tu denuncia.",
        "6-1-image": "20_Esp_captura_suger.png",
        "6-2-1":
          "Muestra una tabla con todas las denuncias que tienes abiertas. Haz clic en cualquiera de tus denuncias para ver sus detalles.",
        "6-2-2-title": "Respuesta del vendedor",
        "6-2-2-text":
          "Muestra la fecha en que el vendedor envió la respuesta a tu denuncia, junto con sus notas y archivos adjuntos.",
        "6-2-3-title": "Denuncia",
        "6-2-3-text":
          "Muestra la fecha en que se realizó la denuncia de la publicación, el nombre del auditor y el código único de la denuncia.",
        "6-2-4-title": "Publicación",
        "6-2-4-text":
          "Es una captura de la publicación tal y como se veía el día en que se realizó la denuncia.",
        "6-2-5-title": "Datos Vendedor",
        "6-2-5-text":
          "Aquí verás la información del vendedor que publicó el producto que denunciaste, como su reputación en Mercado Libre, cantidad de denuncias enviadas, counter notices recibidos y aceptados.",
        "6-2-6":
          "Si consideras que la respuesta es suficiente y quieres dar de baja tu denuncia, selecciona el pulgar para arriba. Por el contrario, si la respuesta no te parece suficiente, selecciona el pulgar abajo.",
        "6-3-1": "Respuesta del vendedor",
        "6-3-2a": "Desde este bloque tendrás la posibilidad de ",
        "6-3-2b": "'Confirmar o rechazar la denuncia' ",
        "6-3-2c": "y dejar un comentario para enviarle al vendedor.",
        "6-4-1":
          "En caso de rechazar la denuncia, deberás seleccionar el motivo.",
        "6-2-image": "21_Esp_captura_suger.png",
        "7-2-1":
          "Si necesitas sumar nuevos derechos para poder realizar más y mejores denuncias, ",
        "7-2-1b": "haz clic aquí.",
        "7-2-2-title": "En la pantalla de adhesión de derechos",
        "7-2-2-text":
          "Selecciona la casilla de verificación del derecho que quieras añadir. Si necesitas adherir más de uno, puedes seleccionar múltiples opciones.",
        "7-2-3":
          "Aparecerá un recuadro blanco en la parte inferior de la pantalla por cada tipo de derecho que hayas seleccionado. Adjunta la documentación requerida para cada caso.",
        "7-2-4": "Cuando hayas terminado, haz clic en el botón ",
        "7-2-5": "Enviar documentación.",
        "7-2-6":
          "¡Listo! Nuestros representantes analizarán tu documentación y te notificarán por e-mail del estado de tu solicitud.",
        "8-1-1": "Publicaciones de catálogo",
        "8-2-1": "¿Qué es una publicación de catálogo?",
        "8-2-2":
          "Se trata de una “página de producto” que agrupa, bajo un único contenido, varias publicaciones ofrecidas por diferentes vendedores.",
        "8-2-3":
          "Las publicaciones de los vendedores compiten por ser la primera opción que ven los compradores. Quien ofrezca las mejores condiciones de venta y experiencia será el 'Ganador'. La competencia se basa, entre otras cosas, en el precio, la reputación del vendedor y las condiciones de venta ofrecidas.",
        "8-3-1": "Cómo explorar los vendedores participantes",
        "8-3-2":
          "Ten en cuenta que las “páginas de producto” tienen variantes. Por ejemplo, para un celular se muestran las distintas capacidades y colores disponibles. Para cada combinación de las variantes, puede haber un ganador distinto.",
        "8-3-3":
          "Al encontrar una publicación de catálogo en la herramienta de denuncias, podrás ir al detalle de la publicación para conocer los vendedores participantes para cada variante.",
        "8-3-image": "22_Esp_captura_Suger.png",
        "8-4-1": "Qué datos define el vendedor",
        "8-4-2":
          "El vendedor configura las condiciones particulares de la publicación participante en “Catálogo”. Esto incluye precio, stock, garantía y forma de envío.",
        "8-5-image": "23_Esp_captura_Suger.png",
        "8-5-1": "Quién responde las preguntas de los compradores",
        "8-5-2":
          "En una publicación de catálogo las respuestas a las preguntas de los compradores las da el vendedor ganador del momento.",
        "8-5-3":
          "En la columna de “Vendedores participantes” podrás ver las respuesta que ha dado cada vendedor con solo seleccionarlo.",
        "8-6-1": "Conocer más sobre Publicaciones de catálogo",
        "8-7-1": "Cómo denunciar una publicación de catálogo",
        "8-7-1-a": "Para denunciar las fotos de la publicación,",
        "8-7-1-b":
          " selecciona la opción “Marcar como sospechosa” en la sección que muestra los detalles del producto.",
        "8-7-image": "24_Esp_captura_Suger.png",
        "8-7-2-a": "Para reportar la publicación de un vendedor,",
        "8-7-2-b":
          "explora la columna “Vendedores participantes” y toca el botón “ Marcar como sospechosa”.",
        "8-7-2-image": "25_Esp_captura_Suger.png",
        "8-8-1":
          "Las publicaciones que marques como sospechosas aparecerán en la sección ",
        "8-8-1-a": "“Denuncias sospechosas por denunciar”",
        "8-8-1-b": "y podrás gestionarlas como lo haces normalmente.",
      },
      "question-platform":
        "Cómo se utiliza la Plataforma de Protección de Marcas",
      "answer-platform":
        "Con esta herramienta puedes buscar las publicaciones relacionadas a tu marca en todos los países donde tengas derecho de denuncia. A diferencia de utilizar los sitios de cada país de Mercado Libre para auditar infracciones, con esta plataforma puedes gestionar publicaciones de todos los países en un único lugar.",
      "question-livesearch": "¿Qué es un Livesearch?",
      "answer-livesearch":
        "Livesearch son las búsquedas de publicaciones a partir de una o más palabras claves. Puedes hacer tus búsquedas por país o en varios a la vez, siempre que estés autorizado para denunciar en más de un país. Es importante que las palabras que uses estén relacionadas con la marca que auditas, así puedes obtener resultados muchos más exactos.",
      steps: {
        "1-1": "En el menú izquierdo elige Búsquedas",
        "2-1": "Ingresa las palabras claves y haz click en",
        "2-2": "Buscar",
        "3-1": "Indica los países en los que quieres y elige",
        "3-2": "Procesar Búsqueda",
        "4-1":
          "Cuando inicie el proceso de livesearch verás la cantidad de publicaciones que existen para las palabras claves que ingresaste. Si haces click en la o las palabra clave que figuran en el livesearch puedes comenzar a revisar las publicaciones. Puede comenzar a auditar aunque la búsqueda aún esté en proceso.",
      },
      "question-grid": "Grilla de resultados",
      "answer-grid":
        "En la vista de grilla de resultado vas a encontrar todas las publicaciones que coincidieron con las palabras claves que usaste en tu livesearch.",
      "question-filter-liversearch": "Filtros del Livesearch",
      "answer-filter-liversearch1":
        "Una vez que obtuviste los resultados en, puedes ajustar la búsqueda y hacerla mucho más precisa usando los filtros. Puedes usarlos de a uno o combinarlos como prefieras.",
      "answer-filter-liversearch2":
        "Podrás concentrar tu búsqueda sólo una línea de productos de tu marca, o filtrar por fecha de publicación, precio, stock, etc. De la misma forma, podrás excluir palabras de los resultados.",
      "question-publication": "Vista detalle de publicación",
      "answer-publication":
        "Para acceder a la vista de detalle debes hacer click en una publicación en la grilla de resultados. En la vista de detalle obtendrás mayor información del artículo ofrecido, sus fotos, información del vendedor y análisis de stock y ventas actuales.",
      "question-publication-valid": "Pulgar para arriba",
      "answer-publication-valid":
        "Si ves que una publicación no está cometiendo ninguna infracción a tu marca, indica con el pulgar para arriba que la publicación es válida. De esta forma, en el futuras busquedas esa publicación no volverá a aparecer en los resultados que hagas.",
      "question-publication-invalid": "Pulgar para abajo",
      "answer-publication-invalid1":
        "Si ves que una publicación está cometiendo una o más infracciones de propiedad intelectual, debes marcarla con el pulgar para abajo. Luego se abrirá un cuadro en el que podrás agregar más detalles con respecto a la o las infracciones que detectaste.",
      "answer-publication-invalid2": "Tags",
      "answer-publication-invalid2-1":
        "Los tags son etiquetas de referencia que podrás usar luego para emitir reportes. Estas etiquetas o descripciones son solo de uso interno, y no figuran en la denuncia formal.",
      "answer-publication-invalid3": "Código de denuncia",
      "answer-publication-invalid3-1":
        "Es el código asociado al motivo de tu denuncia. Esto está relacionado con los derechos que adheriste al Brand Protection Program para denunciar en Mercado Libre.",
      "answer-publication-invalid4": "Detalle",
      "answer-publication-invalid4-1":
        "Puedes agregar un detalle del motivo de tu denuncia que estás por realizar. Esto será de ayuda por si algún miembro del equipo necesita más información antes de procesar una baja.",
      "answer-publication-invalid5": "Denunciar",
      "answer-publication-invalid5-1":
        "Una vez que tocas en denunciar, la denuncia aún no será procesada y enviada al equipo de revisión de Brand Protection Program, sino que será enviada a Artículos por denunciar junto con las otras denuncias que hayas realizado. Por lo tanto, si identificas que esta denuncia fue mal realizada, podrás eliminarla luego.",
      "answer-publication-invalid6": "Artículos por denunciar",
      "answer-publication-invalid6-1":
        "Aquí estarán todas las publicaciones que marcaste con el pulgar para abajo. En esta instancia es donde efectivamente denunciarás las publicaciones. Por eso, es  recomendable revisar todos los detalles que antes completaste, para asegurarte de las infracciones. Si quieres también puedes visitar la publicacion en Mercado Libre, haciendo click en el siguiente botón.",
      "answer-publication-invalid6-2":
        "Si haces click en (+) podrás ver los detalles de tu denuncia.",
      "answer-publication-invalid6-3":
        "Si encuentras que una publicación no está en infracción, puedes elegir Quitar de la lista y no será realizada la denuncia.",
      "answer-publication-invalid6-4":
        "Una vez que estés seguro que quieres enviar las denuncias, puedes enviarlas individualmente o hacerlo de forma masiva y elegir Enviar todas las denuncias.",
      "question-whitelistsellers": "Vendedores confiables",
      "answer-whitelistsellers1":
        "Puedes identificar vendedores como confiables (por ej. Tiendas Oficiales, distribuidores, etc) para que sus publicaciones dejen de salir en tus livesearch y de esa forma no perder tiempo auditandolas y haciendo análisis.",
      "answer-whitelistsellers2":
        "Para identificarlo debes abrir la vista detalle y hacer click en ",
      "answer-whitelistsellers3": " Vendedor confiable.",
      "question-blacklistsellers": "Vendedores Observados",
      "answer-blacklistsellers1":
        "Si crees que es necesarios hacer seguimiento de algunos vendedores y de todas sus publicaciones, debes elegir Observar vendedor.",
      "answer-blacklistsellers2":
        "Todas las publicaciones de este vendedor estarán marcadas con banderita negra. También podrás filtrar los resultados para que muestren sólo publicaciones de vendedores observados.",
      "answer-blacklistsellers3-1":
        "Puedes ver la actividad de publicaciones y denuncias de estos vendedores en el menú a la izquierda, en la sección ",
      "answer-blacklistsellers3-2": "Vendedores > Vendedores Observados",
      "question-livesearch-sellers": "Livesearches de Vendedores",
      "answer-livesearch-sellers-1":
        "Ya sea desde la vista detalle, en las secciones de Vendedores observados o desde Vendedores confiables, puedes crear un Livesearch para observar todas las publicaciones de estos vendedores.",
      "answer-livesearch-sellers-2":
        "Cuando identificas al menos una publicación que infringe los derechos de tu marca, es recomendable analizar todas sus publicaciones del vendedor para identificar si tiene más que puedan afectar a tu marca.",
      "question-post-denunciation": "Seguimiento post-denuncia",
      "answer-post-denunciation1-1":
        "Podrás generar reportes de tu actividad y exportarlo un archivo Excel. Podrás hacer seguimiento de todo el trabajo de auditoría.",
      "answer-post-denunciation1-2":
        "También podrás ver todas las publicaciones denunciadas fueron dadas de baja. También podrás ver una foto de referencia de la publicación al momento de hacer la denuncia.",
      "question-counter-notice": "Counter Notice",
      "answer-counter-notice1":
        "Si un vendedor realiza un counter notice a una denuncia, llegará al e-mail que marcaste como e-mail del contacto al registrar tus derechos en Brand Protection Program. Este e-mail corresponde al asociado a las cuentas de denuncia de Brand Protection Program en cada país.",
      "answer-counter-notice2":
        "Recuerda que ese e-mail es distinto que el que registraste como auditor para denunciar en esta plataforma.",
      disclaimer:
        "* Las imágenes se muestran a modo de ejemplo para explicar el uso de la herramienta.",
      "question-support": "SOPORTE",
      "see-more": "Aún tienes una duda?, podemos ayudarte.",
      "goto-support": "Ir a Soporte",
    },
    black_sellers: {
      ID: "ID",
      title: "Ultimos vededores observados",
      placeholder_sites: "Seleccione vendedor",
      table_th_sitie: "SITIO",
      table_th_country: "PAÍS",
      table_th_sellers: "VENDEDOR",
      table_th_reports: "DENUNCIAS RECIBIDAS",
      table_th_actions: "ACCIONES",
      table_th_current_listings: "PUBLICACIONES ACTIVAS",
      empty_sellers_message: "No hay Vendedores Observados disponibles.",
      button_create_current_listings:
        "Crear livesearch con publicaciones actuales",
      button_delete_current_listings: "Eliminar",
      confirm_delete_client_black_seller:
        "Estás seguro que deseas eliminar a este Vendedor de la lista de Vendedores Observados?",
      black_list_seller_deleted: "Vendedor Observados eliminado",
      black_list_seller_delete_error:
        "Hubo un problema mientras intentabamos eliminar a este vendedor. por favor intenta luego",
      fetch_black_list_seller_error:
        "Lo lamentamos, hubo un error en la búsqueda de vendedores",
    },
    case_management: {
      cases: {
        pending_title: "Denuncias por responder",
        pending_table_explanation:
          "Los siguientes vendedores han enviado un Counter-Notice a tus solicitudes de baja de publicación",
        search: "Buscar",
        table_th_auditor: "Auditor",
        table_th_country: "País",
        table_th_id: "ID",
        table_th_article: "Publicación",
        table_th_sellers: "Vendedor",
        table_th_status: "Estado",
        table_th_denounce: "Fecha de Denuncia",
        table_th_denounce_2: "Fecha de denuncia:",
        table_th_denounce_sent: "Fecha de Denuncia",
        table_th_counter_notice: "Fecha de Respuesta",
        table_th_response_limit: "Plazo de Respuesta",
        empty_cases_message: "No se encontraron resultados",
        fetch_pending_cases_list_error:
          "Lo sentimos, hubo un error en la búsqueda de casos",
        denounced_date: "Denunciada: ",
        updated_date: "Actualizada: ",
        canceled_date: "Dada de baja: ",
        dates: "Ver fechas",
      },
      case_description: {
        denounced_seller: "Vendedor denunciado",
        seller_response: "Esto es lo que te escribió",
        seller_empty_response: "El vendedor no escribió comentarios",
        photos_current: "Estas son las imágenes actuales de la publicación",
        photos_modified_new: "NUEVA",
        photos_denounced: "Estas son las imágenes que reportaste",
        fetch_description_denounces_error:
          "Lo sentimos, hubo un error al buscar los datos.",
        no_data_found: "No se encontraron datos",
        "counter-notice_tab": "Respuesta del vendedor",
        "counter-notice": {
          counter_notice_attached_file: "ARCHIVO ADJUNTO",
        },
        denounce_tab: "Denuncia",
        denounce: {
          denounce_date: "FECHA DE DENUNCIA",
          auditor: "AUDITOR",
          code: "MOTIVO DE DENUNCIA",
          additional_description: "NOTAS DEL AUDITOR",
        },
        publication_tab: "Publicación",
        publication: {
          site: "Mercado Libre",
          price: "PRECIO",
          product_sold_in_this_publication:
            "PRODUCTOS VENDIDOS EN ESTA PUBLICACIÓN",
          description: "DESCRIPCIÓN",
          images: "fotos",
          image: "foto",
        },
        "seller-data_tab": "Datos Vendedor",
        seller_data: {
          create_seller_LS: "Crear LiveSearch para este Vendedor",
          black_seller: "Vendedor Observado",
          portal_reputation: "Reputación",
          sold_quantity_products: "Cantidad de productos vendidos",
          denonces_sent_to_this_seller: "Denuncias",
          counter_notice_received: "Respuestas enviadas",
          counter_notice_accepted: "Respuestas aceptadas",
          denounces_table_article: "Publicación",
          denounces_table_denounce: "Denuncia",
          denounces_table_status: "Estado",
          denounces_counter_notice: "Respuesta de Vendedor",
          fetch_counter_notices_error:
            "Hubo un error obteniendo las denuncias del vendedor",
        },
      },
      documentation_approved: {
        sending_response: "Estamos enviando tu respuesta al vendedor.",
        response_placeholder: "Revisé la respuesta y quiero:",
        reject_option_member_placeholder: "Voy a confirmarla porque:",
        approved_case_title: "Respuesta para el vendedor",
        approved_case_title_sent: "Respuesta",
        confirm: "Confirmar mi denuncia",
        retract: "Retractar mi denuncia",
        confirm_helper_text:
          "Envía esta respuesta para finalizar la publicación.",
        retract_helper_text:
          "Envía esta respuesta para reactivar la publicación.",
        send_response: "Enviar respuesta",
        reject_option_label: "Motivo de tu decisión",
        description_label: "Comentario adicional",
        no_description: "Sin descripción",
        report_placeholder:
          "Explícale al vendedor por qué has tomado esta decisión.",
        respond_counter_notice_success_confirm: "La publicación fue eliminada.",
        respond_counter_notice_success_retract:
          "La publicación fue reactivada.",
        respond_counter_notice_success:
          "¡Listo! Ya enviamos tu respuesta al vendedor.",
        respond_counter_notice_error: "Lo sentimos, hubo un error.",
        closed_title: "Ya respondiste a este vendedor",
        responded: "Esta fue tu decisión",
        responded_at: "Le respondiste el",
      },
      publication_status: {
        active: "Activa",
        closed: "Finalizada",
        inactive: "Inactiva",
        paused: "Pausada",
        under_review: "Para revisar",
      },
      denounce_status: {
        waiting: "Esperando respuesta",
        pending: "Respondida",
        accepted: "Publicación reactivada",
        not_approved: "Publicación finalizada",
        not_presented: "No respondida",
        not_respond: "Expirada",
        rollback: "Retractada",
        discard_due_restriction: "Descartada",
        pending_moderation: "Pendiente",
        rejected: "Denuncia Rechazada",
        acuerdo_closed: "Publicación finalizada",
        duplicated_denounce: "N/A",
        discarded: "Descartada",
        rejected_modified: "Publicación modificada",
        revision: "Denuncia en revisión",
      },
      denounce_status_V3: {
        approved: "Aprobada",
        in_progress: "En curso",
        pending: "Pendiente por responder",
        rejected: "Rechazada",
        rollback: "Retirada",
        discarded: "Descartada",
      },
      pending_denounce_widget: {
        title: "Denuncias pendientes por responder.",
        subtitle: "Revisá la respuesta del vendedor.",
      },
      status_pill: {
        expire_days: "Vence en {} días",
        expire_day: "Vence en {} día",
        expire_hours: "Vence en {} horas",
      },
    },
    welcome_page: {
      title: "¡Bienvenido!",
      subtitle:
        "Estás a un paso de usar la nueva herramienta de denuncias de Mercado Libre",
      no_auditor_boton: "Quiero registrarme",
      enter_now_to_denounce: "Ya estoy registrado",
    },
    welcome_register_page: {
      title:
        "Bienvenido a la herramienta de denuncias del Brand Protection Program",
      subtitle:
        "Si ya estás registrado en el programa, ingresa con cualquiera de tus usuarios de Brand Protection Program. Si todavía no tienes cuenta, regístrate.",
      subtitle2: "Ingresa el e-mail registrado en el Brand Protection Program",
      email: "E-mail",
      singin_button: "Ingresar",
      continue_button: "Continuar",
      no_account_button: "Registrate aquí",
      know_more: "Conoce más sobre el programa",
    },
    error_no_bpp: {
      title: "No pudimos iniciar sesión",
      subtitlePart1: "Por favor, ingresa de nuevo y ",
      subtitlePart2: "asegúrate de usar tus credenciales del BPP.",
      btn: "Ingresar de nuevo",
    },
    reported_articles: {
      alerts: {
        cannot_submit_title: "No puedes realizar denuncias",
        cannot_submit_subtitle:
          "Encontramos comportamientos abusivos en tus rechazos a las respuestas de los vendedores y por eso no podrás enviar denuncias temporalmente.",
      },
      filters: {
        placeholder_reference_id: "ID",
        placeholder_title: "Título",
        placeholder_price_from: "Precio desde (USD)",
        placeholder_term: "Título",
        placeholder_site: "Seleccione Sitio",
        placeholder_price_to: "Precio hasta (USD)",
        placeholder_seller: "Seleccione Vendedor",
        placeholder_complaint: "Seleccione Estado",
        placeholder_date_from: "DESDE",
        placeholder_date_to: "HASTA",
        option_selecet_complaint_status_with_error: "Por Enviar",
        option_selecet_complaint_status_without_error: "Error de envio",
      },
      articles_reported: {
        title: "Historial de denuncias",
        description:
          "Encuentra aquí todas tus denuncias y gestiona las pendientes por responder",
        search: "Buscar por ID, nombre del vendedor",
        filter: "Filtrar y ordenar",
        table_th_seller: "Vendedor",
        table_th_denounce: "Publicación denunciada",
        table_th_reason: "Motivo de la denuncia",
        table_th_status: "Estado",
        placeholder_sites: "SELECCIONE SITIO",
        table_th_id: "ID",
        table_th_site: "SITIO",
        table_th_country: "PAÍS",
        table_th_article: "PUBLICACIÓN",
        table_th_auditor: "AUDITOR",
        table_th_price: "PRECIO",
        table_th_reported: "DENUNCIADO",
        table_th_notice_status: "ESTADO",
        table_th_notice_update: "ACTUALIZADO",
        table_th_down: "BAJA",
        reported_at: "FECHA REPORTE",
        button_clear_filters: "Borrar Filtro",
        button_submit_filters: "Buscar",
        empty_reported_articles_message:
          "No hay artículos reportados disponibles.",
        "": "",
      },
      denounce_status_tooltip: {
        WAITING_DOCUMENTATION:
          "El vendedor todavía no ha respondido tu denuncia.",
        DOCUMENTATION_PRESENTED: "El vendedor ya ha respondido a tu denuncia.",
        DOCUMENTATION_APPROVED: "Has decidido reactivar esta publicación.",
        DOCUMENTATION_NOT_APPROVED: "Has decidido finalizar esta publicación.",
        DOCUMENTATION_NOT_PRESENTED:
          "Finalizamos la publicación porque el vendedor no te respondió dentro del plazo.",
        MEMBER_NOT_RESPOND:
          "No has respondido al vendedor a tiempo y la denuncia ha expirado.",
        ROLLBACK:
          "Has decidido reactivar la publicación del vendedor porque retractaste tu denuncia.",
        DISCARDED_DUE_RESTRICTION:
          "El vendedor ya fue inhabilitado, y no es necesario que revises su respuesta.",
        ACUERDO_CLOSED: "Hemos finalizado esta publicación.",
        DUPLICATED_DENOUNCE: "N/A",
        REJECTED: "Tu denuncia fue rechazada.",
        DISCARDED: "La publicación ya fue dada de baja.",
        PENDING_MODERATION: "Tu denuncia todavía no fue aprobada.",
        CREATED: "Tu denuncia todavía no fue aprobada.",
        CROSS_DENOUNCE_CLOSED: "Tu denuncia fue rechazada.",
        WAITING_FOR_PATCH_PDP:
          "Estamos analizando tu denuncia. De ser necesario, haremos los ajustes correspondientes.",
        REJECTED_PDP_CATALOG:
          "No pudimos procesar tu denuncia porque las imágenes que denunciaste fueron obtenidas de un banco privado de imágenes. \n\n Revisa de nuevo la publicación y, si aún consideras que infringe tus derechos, contáctanos desde la sección de soporte",
        REJECTED_PDP_ITEM:
          "No pudimos procesar tu denuncia porque las imágenes que denunciaste las obtuvimos de publicaciones de nuestros vendedores que no habías denunciado. \n\n Revisa de nuevo la publicación y, si aún consideras que infringe tus derechos, contáctanos desde la sección de soporte.",
        REJECTED_PDP_IMAGE_RANKER:
          "No pudimos procesar tu denuncia porque las imágenes que denunciaste fueron obtenidas de un banco de imágenes, que las disponibiliza garantizando que su uso es autorizado, o de publicaciones de nuestros vendedores. \n\n Revisa de nuevo la publicación y, si aún consideras que infringe tus derechos, contáctanos desde la sección de soporte.",
        REJECTED_MODIFIED:
          "La publicación fue modificada luego de tu denuncia. Revísala de nuevo y, si aún consideras que infringe tus derechos, contáctanos desde la sección de soporte",
      },
      denounce_status_tooltipV3: {
        ACUERDO_CLOSED: "La publicación denunciada fue eliminada.",
        DOCUMENTATION_NOT_APPROVED:
          "La documentacíon del vendedor no fue aprobada.\n\n La publicación denunciada fue eliminada.",
        DOCUMENTATION_NOT_PRESENTED:
          "Tu denuncia fue aprobada porque el vendedor no presentó la documentación.",
        CREATED: "Tu denuncia está pendiente para ser procesada.",
        PENDING_MODERATION: "Tu denuncia está pendiente para ser procesada.",
        WAITING_DOCUMENTATION: "El vendedor esta pendiente por responder.",
        WAITING_FOR_PATCH_PDP:
          "Estamos analizando tu denuncia. De ser necesario, haremos los ajustes correspondientes.",
        DOCUMENTATION_PRESENTED:
          "El vendedor está esperando tu respuesta.Si no contestas la denuncia,esta será anulada.",
        DUPLICATED_DENOUNCE:
          "Tu denuncia no se puede realizar porque está duplicada.",
        MEMBER_NOT_RESPOND:
          "Tu denuncia fue rechazada porque no diste respuesta al vendedor en el plazo establecido.",
        REJECTED:
          "Tu denuncia fue rechazada porque no cumple con los requisitos necesarios.",
        REJECTED_BY_RESTRICTION:
          "Superaste el limite de denuncias diarias. Revisa la eficiencia de tu cuenta.",
        DOCUMENTATION_APPROVED:
          "La documentación del vendedor fue aprobada y tu denuncia retirada.",
        ROLLBACK:
          "Retiraste la denuncia.La publicación denunciada será reactivada.",
        DISCARDED:
          "Tu denuncia no se puede realizar porque la publicación ya no esta disponible.",
        DISCARDED_DUE_RESTRICTION:
          "Tu denuncia no se puede realizar porque el vendedor se encuentra inhabilitado.",
        REJECTED_MODIFIED:
          "Tu denuncia fue descartada porque la publicación fue modificada.",
        REJECTED_PDP_CATALOG:
          "Tu denuncia fue descartada porque las imágenes que denunciaste fueron obtenidas de un banco privado de imagenes",
      },
      index: {
        title: "Artículos por denunciar",
        table_th_site: "SITIO",
        table_th_country: "PAÍS",
        table_th_article: "PUBLICACIÓN",
        table_th_auditor: "AUDITOR",
        table_th_price: "PRECIO",
        table_th_antiquity: "REPORTADA HACE",
        table_th_more: "MÁS",
        table_of_text: "de",
        table_next_text: "Siguiente",
        table_rows_text: "filas",
        table_page_text: "Página",
        table_previous_text: "Anterior",
        table_loading_text: "Cargando...",
        table_no_data_text: "No se encontraron datos",
        empty_reports_message:
          "No hay publicaciones para reportar disponibles.",
        removed_clarification: "Eliminada",
        removed_clarification_tooltip:
          "No puedes denunciar esta publicación porque ya la eliminamos del sitio.",
        button_remove: "Eliminar",
        removed: "Removido",
        removed_v2: "Publicación inactiva",
        button_send_all_complaints: "ENVIAR TODAS LAS DENUNCIAS",
        button_edit_complaint: "Editar",
        button_send_complaint: "Enviar denuncias",
        button_retry_complaint: "Intentar Nuevamente",
        confirm_reported_article_undo: "¿Desestimar esta publicación?",
        confirm_reported_article_undo_subtitle:
          "Si haces esto, ya no verás el $title en tu lista de publicaciones sospechosas.",
        confirm_reported_article_undo_btn_label: "Desestimar publicación",
        confirm_send_all_complaints:
          "Confirma el envío de todas las denuncias?",
        complaint_sent: "Denuncia enviada",
        send_complaint:
          "Gracias por enviar esta denuncia. Procederemos a evaluarla dentro de las próximas 72hs.",
        send_all_complaints:
          "Gracias por enviar estas denuncias. Procederemos a evaluarlas dentro de las próximas 72hs.",
        error_send_all_complaints:
          "Hubo un error al enviar todas las denuncias.",
        error_send_complaint: "Hubo un error al enviar la denuncia.",
        error_send_complaint_already: "La publicación ya fue denunciada.",
        error_getting_article_data: "Hubo un error al buscar los datos",
        failed_not_exits_or_not_published:
          "El artículo no existe o no está publicado.",
        days: {
          one: "%{count} día",
          other: "%{count} días",
        },
      },
      edit: {
        seller: "Vendedor",
        tags: "Etiquetas",
        complaint_code: "Infracción",
        placeholder_complaint_description:
          "Escribe aquí tus comentarios sobre la publicación que estás por reportar (opcional).",
        label_add_tag: "Ingresa tus etiquetas",
        cancel_complaint_edition: "Cancelar",
        edit_complaint_edition: "Editar",
      },
      table: {
        th1: "Publicación",
        th2: "Precio",
        th3: "Vendedor",
        th4: "Motivo de denuncia",
      },
    },
    noticed_articles: {
      filter: "Filtrar",
      filters: {
        all: "Todos los estados",
        placeholder_id: "ID",
        placeholder_title: "Publicación",
        placeholder_price_from: "Precio desde",
        placeholder_price_to: "Precio hasta",
        placeholder_date_from: "Fecha desde",
        placeholder_date_to: "Fecha hasta",
        placeholder_country: "País",
        placeholder_seller: "Vendedor",
        report_created: "Reporte generado",
        report_created_error:
          "Hubo un problema mientras generabamos el reporte",
        report_no_articles_found:
          "No se encontraron artículos para generar el reporte",
        title_required: "Titulo es requerido",
        date_error: "Fecha desde, no puede ser mayor a fecha hasta",
        price_error: "Precio desde, no puede ser mayor a precio hasta",
      },
      filter: "Filtrar",
    },
    client_white_sellers: {
      index: {
        ID: "ID",
        title: "Vendedores Confiables",
        empty_sellers_message: "No hay Vendedores Confiables disponibles.",
        table_th_site: "SITIO",
        table_th_country: "PAÍS",
        table_th_seller_name: "VENDEDOR",
        table_th_seller_current_listing: "ARTÍCULOS ACTUALES",
        table_th_actions: "ACCIONES",
        delete_client_white_seller: "Quitar",
        confirm_delete_client_white_seller:
          "Estás seguro que deseas eliminar a este Vendedor de la lista de Vendedores Confiables?",
        white_list_seller_deleted: "Vendedor confiable eliminado",
        white_list_seller_delete_error:
          "Hubo un problema mientras intentábamos eliminar a este vendedor. Por favor intenta luego",
        fetch_white_list_seller_error:
          "Lo sentimos, hubo un error en la búsqueda de vendedores",
      },
    },
    white_and_black_sellers: {
      filters: {
        ID: "ID",
        country: "País",
      },
    },
    notice_reports: {
      index: {
        title: "Reportes",
        table_th_date: "Fecha",
        table_th_name: "Nombre del reporte",
        table_th_download: "Descargar",
        fetch_reports_error:
          "Hubo un error mientras buscábamos los reportes, por favor inténtalo luego.",
        click_here_to_download: "Click aquí para descargar",
        confirm_delete_report:
          "¿Está seguro que desea eliminar este reporte? Esta acción no podrá deshacerse.",
        delete_report_error: "Hubo un error eliminando el reporte",
        processing_report: "Procesando reporte",
        generate_error: "Hubo un error al generar este reporte",
        emply_error:
          "Lo sentimos, no tienes denuncias con los filtros seleccionados",
      },
      create: {
        title: "Nuevo reporte",
        create_report: "Crear reporte",
        placeholder_title: "Título",
        placeholder_date_from: "Fecha desde",
        placeholder_date_to: "Fecha hasta",
        placeholder_currency: "moneda",
        placeholder_origin: "Auditor",
        placeholder_language: "idioma",
      },
    },
    sites_authorization: {
      from_account: {
        do_it_later: "Vincular en otro momento",
        finish_and_do_it_later: "Terminar y vincular luego",
      },
      from_search: {
        back_to_search: "Volver a la búsqueda",
      },
      default: {
        start_reporting: "Empezar a denunciar",
      },
    },
    livesearch_reports: {
      index: {
        title: "Reportes",
        table_th_date: "Fecha",
        table_th_name: "Nombre del reporte",
        table_th_download: "Descargar",
        fetch_reports_error:
          "Hubo un error mientras buscabamos los reportes, por favor intentalo luego.",
        click_here_to_download: "Click aquí para descargar",
        confirm_delete_report:
          "¿Está seguro que desea eliminar este reporte? Esta acción no podrá deshacerse.",
        delete_report_error: "Hubo un error eliminando el reporte",
        processing_report: "Procesando reporte",
        generate_error: "Hubo un error al generar este reporte",
      },
    },
    redirect_flow_info: {
      title: "Bienvenido a nuestra nueva herramienta de denuncias",
      subtitle:
        "Ahora denunciar y responder a los vendedores es mucho más rápido. Mira todo lo que puedes hacer:",
      bullet1: "Identifica más rápido vendedores sospechosos",
      bullet2: "Crea tus propios filtros de búsqueda",
      bullet3: "Sigue el estado de tus denuncias, en un solo lugar",
    },
    tags_title: "Etiquetas",
    label_add_tag: "Ingresa tus etiquetas",
    total: "Totales",
    undo: "Deshacer",
    unknown_seller: "Vendedor desconocido",
    unlist_seller_from_black_list_confirmation:
      "Estás seguro que deseas eliminar a este Vendedor de la lista de Vendedores Observados?",
    unlist_seller_from_white_list_confirmation:
      "Estás seguro que deseas eliminar a este Vendedor de la lista de Vendedores Confiables?",
    used_product: "Producto usado",
    validity: "Vigencia",
    view_in_site: "Ver en el sitio",
    initial_question: "P",
    initial_answer: "R",
    seller_information: "Info Vendedor",
    description_product: "Descripción",
    question_answer: "Preguntas y Respuestas",
    welcome: "Bienvenido/a",
    go_back: "Volver",
    edit_reported_article_success: "Se actualizo el reporte a la publicación.",
    edit_reported_article_error:
      "Lo lamentamos, hemos tenido un error actualizando el reporte.",
    done_article_reported:
      "¡Listo! Para gestionar y enviar tus denuncias, ve a la sección",
    done_articles_reported:
      "¡Listo! Para gestionar y enviar tus denuncias, ve a la sección",
    go_to_reported_articles: "Publicaciones sospechosas.",
    we_will_send_this_listing_to: "Enviaremos esta publicación a",
    we_will_send_these_listing_to: "Enviaremos estas publicaciones a",
    got_it: "ENTENDIDO",
    ls_reported_articles_link: "Ir a Publicaciones sospechosas",
    process_and_clean_results: "Procesar búsqueda",
    live_searches: "Livesearches",
    unable_to_create_live_search:
      "No pudimos crear la Livesearch. Por favor, intenta nuevamente.",
    server_error:
      "Lo lamentamos, hemos tenido un error en la respuesta del servidor intenta nuevamente",
    fetch_currency_error:
      "Lo lamentamos, hemos tenido un error en la respuesta del cambio de moneda",
    remove_article_error:
      "Lo lamentamos, hemos tenido un error al remover la publicación",
    remove_article_success: "Publicación desestimada.",
    fetch_seller_error:
      "Lo lamentamos, tuvimos un error en la búsqueda del vendedor",
    dashboard: "Dashboard",
    sellers_inactive: "Vendedores Inactivos",
    tags: "Tags",
    search: "Buscar",
    enter_your_email_below_to_reset_your_password:
      "Ingresa tu email para resetear tu contraseña",
    error_generating_custom_report: "Error al generar reporte personalizado.",
    without_results: "Sin resultados!",
    refresh: "Actualizar",
    try_again: "Inténtalo nuevamente",
    seller_live_search_created: "Livesearch creada",
    create_seller_live_search: "Crear Livesearch de publicaciones actuales",
    error_reporting_seller:
      "Error reportando al vendedor. Por favor, intenta nuevamente.",
    error_user_without_report_profile: "No existe el Perfil del Reporte.",
    success_send_all_complaints: "Se enviaron todas las denuncias.",
    updated_reported_article_to_ok:
      "Se actualizo el estado de la publicación denunciado a OK.",
    reported_article_not_found: "No se encontro el artículo reportado.",
    reported_article_already_reported:
      "Demasiado tarde, artículo ya reportado.",
    update_reported_article_complaint_success:
      "Se actualizo la denuncia del artículo reportado.",
    update_reported_article_complaint_error:
      "No se puedo actualizar la denuncia del artículo reportado, por favor intente nuevamente.",
    complaint_reason: "Motivo de denuncia",
    complaint_error: "Error en Denuncia",
    complaint_description: "Descripcion Complementaria de Denuncia",
    invalid_keyword_for_new_live_search:
      "Debe ingresar una palabra clave para generar una nueva LiveSearch",
    modal_title: "Cargando...",
    error_getting_snapshot:
      "No se pudo obtener snapshot del articulo reportado.",
    response_errors: {
      client_error: "Error del cliente",
      server_error: "Error de servidor",
    },
    enroll_rights_cta_tooltip_header:
      "Ten en cuenta que, antes de adherir nuevos derechos, te pediremos que vuelvas a iniciar sesión con tu nombre de usuario.",
    enroll_rights_tooltip_content:
      "¿No encuentras el motivo de infracción en la lista? Es posible que te falten adherir derechos.",
    enroll_rights_now: "Adherir ahora",
    report: {
      report_code: {
        PPPI1:
          "El producto ofrecido es falsificado e infringe mi marca registrada (ej. aplica mi marca registrada a producto no original, etc).",
        PPPI2: "La publicación usa mi marca de manera ilegítima.",
        PPPI3: "El software ofrecido es pirata/ilegal.",
        PPPI4:
          "El producto ofrecido reproduce mi derecho de autor sin autorización (ej. Reproducción de obras plásticas, fonogramas, audiovisuales y libros, etc).",
        PPPI5:
          "La publicación incluye sin autorización textos originales sobre los que tengo derechos.",
        PPPI6:
          "La publicación incluye sin autorización imágenes originales (ej. fotografías, diseños, dibujos, pinturas, videos, etc) sobre los que tengo derechos.",
        PPPI7:
          "La publicación incluye mi imagen personal sin autorización (ej. retrato, modelo publicitario).",
        PPPI8: "El producto ofrecido infringe mi modelo o diseño industrial.",
        PPPI9: "El producto ofrecido infringe mi patente o modelo de utilidad.",
        PPPI10: "Producto no destinado para la venta",
        ACUERDO: "Tengo un convenio de regulación sobre este producto.",
      },
    },
    report_v2: {
      title: "Marcar como sospechosa",
      title_plural: "Marcar comos sospechosas",
      subtitle: "Selecciona el tipo de infracción",
      title_product: "En el producto ofrecido",
      title_catalog: "Reportar infracción a mis derechos",
      subtitle_catalog:
        "Aplica para el uso indebido de marcas, incluyendo productos falsificados o productos originales que usan una marca sin autorización. ",
      title_publication: "En el contenido de la publicación",
      title_actors: "Actores, cantantes, músicos y bailarines",
      title_producers: "Productores de fijaciones de sonidos o de imágenes",
      title_broadcastingorganizations: "Organismos de radiodifusión",
      report_code: {
        PPPI1: "Es un producto falsificado",
        PPPI2: "Uso ilegal de mi marca",
        PPPI3: "Software",
        PPPI4: "Infracción de derechos de autor",
        PPPI5: "Libros",
        PPPI6: "Imágenes / fotos",
        PPPI7: "Uso no autorizado de imagen personal",
        PPPI8: "Infringe modelos o diseños industriales",
        PPPI9: "Infringe patentes, modelos de utilidad o variedades vegetales",
        PPPI10: "Es un producto no destinado a la venta",
        PPPI11: "Cursos",
        PPPI12: "Videojuegos",
        PPPI13: "Videos",
        PPPI14: "Videos / Películas",
        PPPI15: "Música",
        PPPI16: "Personajes",
        PPPI17: "Otros",
        PPPI18: "Reproducciones ilegales",
        PPPI19: "Imagen personal",
        PPPI20: "Usa material auditivo sin autorización",
        PPPI21: "Usa material audiovisual sin autorización",
        PPPI22: "Transmisión ilegal",
        PPPI23: "Dispositivo ilegal",
        ACUERDO: "Tengo un convenio de regulación sobre este producto.",
        PPPI1_subtext:
          "Es un producto que lleva mi marca pero que no fue fabricado por mí.",
        PPPI2_subtext:
          "Incluye mi logo en sus imágenes sin tener autorización; identifica al producto con un nombre o logo confundible con mi marca.",
        PPPI3_subtext:
          "La publicación ofrece un programa de computación que infringe mis derechos.",
        PPPI4_subtext:
          "Por ejemplo, libros pdf, compilados de música, copias de películas, productos que incluyan imágenes de obras protegidas.",
        PPPI5_subtext:
          "La publicación ofrece una obra literaria que infringe mis derechos.",
        PPPI6_subtext:
          "La publicación contiene imágenes o fotos que infringen mis derechos.",
        PPPI7_subtext:
          "Por ejemplo, una foto que muestra a una persona sin su autorización",
        PPPI8_subtext:
          "Indica aquí las posibles infracciones a tus modelos o diseños industriales registrados, incluyendo diseños, formas y monogramas.",
        PPPI9_subtext: "",
        PPPI10_subtext:
          "Ej. Muestras gratis o productos que fueron entregados en comodato. También pueden denunciarse productos no lanzados oficialmente en Brasil.",
        PPPI11_subtext:
          "La publicación ofrece un curso que infringe mis derechos.",
        PPPI12_subtext:
          "La publicación ofrece un videojuego que infringe mis derechos.",
        PPPI13_subtext: "",
        PPPI14_subtext:
          "La publicación ofrece una obra audiovisual que infringe mis derechos.",
        PPPI15_subtext:
          "La publicación ofrece un contenido musical que infringe mis derechos.",
        PPPI16_subtext:
          "La publicación ofrece productos que incluyen mis personajes sin autorización.",
        PPPI17_subtext:
          "La publicación ofrece otro tipo de obra (diseño, pintura, escultura, etc.) que infringe mis derechos.",
        PPPI18_subtext: "Fijaciones o reproducciones no autorizadas.",
        PPPI19_subtext:
          "Usa mi imagen personal asociada a mi interpretación artística sin autorización.",
        PPPI20_subtext: "Música o sonidos grabados.",
        PPPI21_subtext:
          "Películas, series, vídeos, grabaciones de recitales, de espectáculos y eventos deportivos.",
        PPPI22_subtext: "Servicios para acceder a señales de manera ilegal.",
        PPPI23_subtext: "Dispositivos que captan señales de manera ilegal.",
        ACUERDO_subtext: "",
      },
      catalog_code: {
        PPPI1: "Es un producto falsificado",
        PPPI2: "Uso ilegal de mi marca en la publicación. ",
        PPPI10: "Es un producto no destinado a la venta",
        PPPI1_subtext:
          "Es un producto que lleva mi marca, pero no fue fabricado por  mí.",
        PPPI2_subtext:
          "Identifica al producto con un nombre o logo confundible con mi marca, se menciona mi marca cuando el producto no lo es, etc.",
        PPPI10_subtext:
          "Ejemplo: muestras gratis o productos que fueron entregados en comodato.  También pueden denunciarse productos no lanzados oficialmente en Brasil.",
      },
      step_1: {
        copyright: "Infringe derechos de autor",
        brand: "Infringe derechos de marca registrada",
        personal_image: "Usa mi imagen personal sin autorización",
        utility_model: "Infringe modelos o diseños industriales",
        patent: "Infringe patentes, modelos de utilidad o variedades vegetales",
        agreements: "Tengo un convenio de regulación sobre este producto",
        related_rights: "Infringe derechos conexos",
      },
      step_2: {
        header: "¿Quieres agregar algo más?",
        title: "Agregar comentario",
        subtitle: "(Opcional)",
        placeholder:
          "Incluye aquí tus notas y comentarios. Estos comentarios no serán visibles para el vendedor denunciado.",
      },
      message_advice: {
        title: "IMPORTANTE:",
        message:
          " Recuerda que tu denuncia debe basarse en infracciones de propiedad intelectual que tengas adheridos al programa,",
        message_strong:
          "el uso inadecuado de esta herramienta puede llevar a sanciones en tu cuenta.",
        link: "Conoce qué puedes denunciar en el BPP",
      },
      tooltip_PPPI10: {
        title: "¡NUEVO!",
        description:
          "Utiliza este motivo para denunciar infracciones de marca registrada sobre el producto.",
      },

      "select-options": {
        subtitle: "¿Dónde está la infracción?",
        placeholder: "Selecciona una opción",
        "opt-title": "Título",
        "opt-description": "Descripción",
        "opt-sheet": " Ficha técnica",
        "opt-publish": "Publicación",
        subtitle2: "Cuéntanos más sobre la infracción",
        placeholder2: "Incluye aquí los detalles de la infracción.",
        button: "Enviar reporte",
      },
      buybox: {
        title: "¡Listo! Gracias por reportar",
        description:
          "Hemos procesado tu reporte, lo revisaremos y te contactaremos por correo para darte respuesta",
        "case-number": "Número de caso: ",
      },
      product_subtext_1: "Selecciona esta opción si el producto",
      product_subtext_2: "usa tu marca sin autorización.",
      publication_subtext_1: "Selecciona esta opción si la publicación",
      publication_subtext_2: "menciona tu marca",
      publication_subtext_3:
        "en el titulo, en la ficha técnica o en la descripción.",
      related_rights_sub: "Indica la infracción que contiene la publicación.",
      copyright_sub: "",
      brand_sub: "",
      personal_image_sub: "",
      utility_model_sub:
        "Indica aquí las posibles infracciones a tus modelos o diseños industriales registrados, incluyendo diseños, formas y monogramas.",
      patent_sub: "",
      agreements_sub: "",
      copyright_title: "Selecciona el tipo de obra o creación",
      copyright_selected: " en ${reason}",
      utility_patent_selected: " de ${reason}",
      image_hub_title:
        "Indica cuáles imágene(s) estarían infringiendo tus derechos",
      catalog_product_title: "En el producto ofrecido",
      catalog_publish_title: "En el contenido de la publicación",
      "tooltip-isDenounced": "Este producto ya está reportado ",
      errorDenounce:
        "Parece que algo salió mal. Por favor, vuelve a intentarlo.",
    },
    datatables: {
      length_menu: "Mostrar _MENU_ filas por página",
      zero_records: "No hay resultados",
      info: "Mostrando _START_ de _END_ de _TOTAL_",
      info_empty: "No hay filas disponibles",
      info_filtered: "(Filtrado desde _MAX_ resultados totales)",
      processing: "Cargando resultados",
      first: "Primero",
      last: "Ultimo",
      next: "Próximo",
      previous: "Previo",
    },
    activerecord: {
      errors: {
        messages: {
          record_invalid: "objeto invalido -> %{errors}",
          wrong_size: "tamaño equivocado (deberia ser %{file_size})",
          size_too_small:
            "demasiado pequeña (debe ser por lo menos %{file_size})",
          size_too_big: "demasiado grande (deberan ser al menos %{file_size})",
          inclusion: "no está incluído en la lista",
          exclusion: "está reservado",
          invalid: "es inválido",
          confirmation: "no coincide con la confirmación",
          blank: "es requerido",
          empty: "no puede estar vacío",
          not_a_number: "no es un número",
          not_an_integer: "debe ser un entero",
          less_than: "debe ser menor que %{count}",
          less_than_or_equal_to: "debe ser menor o igual que %{count}",
          greater_than: "debe ser mayor que %{count}",
          greater_than_or_equal_to: "debe ser mayor o igual que %{count}",
          too_short: {
            one: "es demasiado corto (mínimo 1 caracter)",
            other: "es demasiado corto (mínimo %{count} caracteres)",
          },
          too_long: {
            one: "es demasiado largo (máximo 1 caracter)",
            other: "es demasiado largo (máximo %{count} caracteres)",
          },
          equal_to: "debe ser igual a %{count}",
          wrong_length: {
            one: "longitud errónea (debe ser de 1 caracter)",
            other: "longitud errónea (debe ser de %{count} caracteres)",
          },
          accepted: "debe ser aceptado",
          even: "debe ser un número par",
          odd: "debe ser un número impar",
          taken: "ya registrado",
          required: "requerido",
        },
      },
      models: {
        user: "Usuario",
      },
      attributes: {
        user: {
          email: "Email",
          first_name: "Nombre",
          last_name: "Apellido",
          password: "Contraseña",
          password_confirmation: "Confirmar Contraseña",
          current_password: "Contraseña Actual",
          currency: "Moneda",
          locale: "Idioma",
          update_password: "Actualizar Contraseña",
          field_required: "Este campo es obligatorio",
          error_rewrite_password: "No coincide con el campo contraseña",
          alert_update_user: "Usuario actualizado",
          alert_not_update_user: "No se realizaron cambios",
        },
      },
    },
    views: {
      pagination: {
        first: "&laquo; Primero",
        last: "Ultimo &raquo;",
        previous: "&lsaquo; Prev",
        next: "Sig &rsaquo;",
        truncate: "&hellip;",
      },
    },
    shared: {
      side_navigation: {
        suggested_listings: "Publicaciones sugeridas",
        searches: "Búsquedas",
        reports: "Denuncias",
        custom_reports: "Reportes de denuncias",
        last_reported_listings: "Historial de denuncias",
        support: "Soporte",
        support_help: "Ayuda",
        management_case: "Gestión de denuncias",
        cases_pending: "Denuncias pendientes",
        cases_closed: "Denuncias cerradas",
        sellers: "Vendedores",
        sellers_black: "Vendedores observados",
        sellers_white: "Vendedores confiables",
        livesearch_reports: "Reportes de Livesearch",
        articles_to_report: "Publicaciones sospechosas por denunciar",
        articles_to_report_tooltip_title: "Tienes denuncias sin enviar",
        articles_to_report_tooltip_text:
          "Revisa tus publicaciones sospechosas y envía las denuncias desde aquí",
        articles_to_report_tooltip_not_show: "No volver a mostrar",
        support_link: "Soporte",
        video_instructions: "Videos instructivos",
        new_pill: "NUEVO",
        my_account: "Mi Cuenta",
      },
    },
    devise: {
      registrations: {
        edit: {
          title: "Perfil del auditor",
          cancel_button: "Cancelar",
          update_button: "Actualizar",
          help_label_password: "(dejar en blanco sino se desea cambiarlo)",
          help_label_current_password:
            "(necesitamos su contraseña actual para confirmar los cambios)",
        },
      },
      passwords: {
        edit: {
          title: "Cambiar la contraseña",
          change_passwd_button: "Cambiar mi contraseña",
          placeholder_password: "Nueva contraseña",
          placeholder_confirm_password: "Confirmar nueva contraseña",
        },
      },
      mailer: {
        reset_password_instructions: {
          hello: "Hola",
          date: "Fecha",
          requested_link_text:
            "Alguien ha pedido un enlace para cambiar tu contraseña. Usted puede hacer esto a través del siguiente link:",
          link_change_my_password: "Cambiar mi contraseña",
          ignore_email_text:
            "Si usted no ha solicitado el cambio de password, por favor, ignora este mensaje.",
          your_passwd_wont_change_text:
            "Su contraseña no cambiará hasta que acceda al enlace de arriba y genere una nueva.",
        },
      },
    },
    terms_and_conditions: "Términos y Condiciones",
    privacy_policy: "Políticas de privacidad",
    reviews: {
      button: "Opiniones",
      title_seller_selected: "Opiniones sobre el producto de",
      title_default: "Opiniones sobre el producto",
      subtitle_seller_default:
        "Aquí encontrarás las opiniones de quienes le han comprado a cada vendedor.",
      subtitle_seller_selected:
        "Explora las opiniones de quienes le han comprado a este vendedor.",
      subtitle_seller_no_reviews:
        "El vendedor que seleccionaste aún no ha recibido opiniones sobre sus ventas.",
      all_reviews: "Todas",
      positives: "Positivas",
      negatives: "Negativas",
      view_more: "Ver más",
      view_less: "Ver menos",
    },
  },
  en: {
    onboarding_slide_notices: {
      step_1: {
        title: "Check the new features!",
        description:
          "Now It is easier to translate the section, view the attached documentation and the details of the reported listing.",
      },
      step_2: {
        title: "Instant translation",
        description:
          "Translate all the content of the section with just one click.",
      },
      step_3: {
        title: "Documentation at a glance",
        description:
          "Preview the images attached by sellers without having to download them.",
      },
      step_4: {
        title: "Listing details",
        description:
          "Review the listing you reported in the new module at the end of the section.",
      },
      next: "Next",
      ok: "Got it",
    },
    catalogue: "catalogue",
    catalogue_id: "catalogue ID",
    official_store: "Official store",
    article_catalogue_detail: {
      title_images: "Catalog photos",
      report_images: "Mark photos as suspicious",
      report_right: "Report an infringement of my rights",
      article_denounced: "Esta publicación infrige mis derechos",
      sellers_title: "Participating sellers",
      sellers_subtitle:
        "In order to mark a listing as suspicious, click on the corresponding seller's card.",
      seller_generic_response_title: "Seller's counter-notices",
      seller_generic_response_subtitle:
        "Here you will see the counter-notices of the seller you selected from the list of participants.",
      seller_response_title: "{seller_name}'s counter-notice",
      seller_response_subtitle:
        "Explore the counter-notices of the seller you selected to check if there are other reasons for suspicion.",
      seller_response_title_empty: "Seller's counter-notices",
      seller_response_subtitle_empty:
        "The seller you selected has not yet answered any questions.",
      seller_history: "View seller's history",
      seller_sales: "Sales",
      country_search: "Start the search in",
      newness_box: {
        title: "This is a Catalog listing",
        subtitle:
          "This is a page that groups, under a single content, different listings of the same product offered by different sellers.",
        "item1-a": "To report the photos in the listing,",
        "item1-b":
          "select the 'Mark as suspicious' option in the section that displays the product details.",
        "item2-a": "To report a listing from a specific seller,",
        "item2-b":
          "check the 'Participating sellers' column and click on the 'Mark as suspicious' button.",
        button: "Learn more",
        close: "Close",
      },
      sort_sellers: {
        item1: "A-Z",
        item2: "Price from lowest to highest",
        item3: "Price from highest to lowest",
        item4: "Official stores first",
      },
      seller_responses_search: "Search in answers...",
    },
    messageBox: {
      title: "Hello!",
      subtitle:
        "We would like to know how your experience with the reporting tool was. ",
      subtitleStrong: "Would you have 3 minutes to tell us?",
      button1: "Yes, sure",
      button2: "I have already answered",
    },
    efficiency: {
      title: "Account efficiency",
      default:
        "We do not have enough data to assess your account efficiency. \n",
      high: "Your account efficiency is very high. Good job! .\n",
      med: "Be careful, your account is at risk of being restricted. \n",
      risk: " Your account is restricted. Review your efficiency level and understand the reasons for the restriction. ",
      subtitle: "Check out efficiency. ",
      subtitle2: "Learn more.",
    },
    blindage: {
      tooltip_title: "You already reported this listing before",
      tooltip_content:
        "We detected that you already reported this listing due to the same infringement and decided to withdraw the report. To report it again, inform us if you found a different infringement. Otherwise, you will be able to report it as from ",
      tooltip_link: "Contact us",
      modal_excel: {
        title: "List of suspicious listing sent",
        subtitle_errors: "Some of the listing you imported have errors.",
        subtitle_success: `You'll be able to check the imported elements from the "Suspicious listings" section".`,
        notBlindages_found: "listings to report",
        notBlindage_found: "listing to report",
        blindages_found: "listings that could not be processed",
        blindage_found: "listing that could not be processed",
        blindages_found2: "Your listings could not be processed",
        blindage_found2: "Your listing could not be processed",
        blindage_content:
          "We noticed that you had already reported these listings based on the same infringement and had chosen to withdraw the report.",
      },
      modal_multiple: {
        title: "Suspicious listings selected",
        subtitle_success: `You'll be able to checkthe selected elements successfully from the "Suspicious listings" section`,
        subtitle_errors: `Some of the listings you selected have errors. You'll be able to checkthe selected elements successfully from the "Suspicious listings" section`,
        elements: "selected elements",
        element: "selected element",
        notBlindages_found: "listings selected successfully",
        notBlindage_found: "listing selected successfully",
        blindages_found: "listings that could not be processed",
        blindage_found: "listing that could not be processed",
      },
    },
    noData: {
      title: "Nothing here...",
      subtitle: "When you report listings, you will be able to find them here.",
    },
    "description-text": "Description",
    "attributes-text": "Characteristics",
    today: "today",
    tomorrow: "tomorrow",
    "see-details-denounced-onboarding": "Check the details",
    "see-details-denounced-onboarding-description":
      "Access the reported listing and remember the reason for each report.",
    "denounced-details-analyce-onboarding": "Review the counter notice",
    "denounced-details-check-onboarding":
      "Review the changes made by the seller, their documents and report history.",
    "denounced-detail-send-answer-onboarding": "Send your response",
    "denounced-detail-send-answer-onboarding-description":
      "Confirm or retract your report after reviewing the seller's response.",
    "redesigned-the-section": "We redesigned the section!",
    "redesigned-the-section-description":
      "Now it is easier to analyze each answer.",
    "your-denounced-ordered": "Your reports well organized",
    "your-denounced-ordered-description":
      "The reports that expire soon appear first so that nothing is missed.",
    "your-answers-informed": "Analyze all the details",
    "your-answers-informed-description":
      "Check the details of the report and the seller's counter notice in the same place.",
    "complaint-management": "Complaint management",
    "complaint-management-answer":
      "Here you will be able to follow the status of your complaints and respond to sellers to decide whether you want to confirm or retract your complaint.",
    "answers-to-be-reviewed": "Answers to be reviewed",
    "answers-to-be-reviewed-answer":
      "It shows a table with all the complaints where you have received a response from the seller. Click on the 'Analyze Response' button to view their details.",
    "analyze-response": "Analyze response",
    "analyze-response-answer":
      "Here you will see the details of the report you made, as well as the details of the seller who published the product you reported.",
    "analyze-response-answer-two":
      "In this section you can see the comments or attachments that the seller sent you in response.",
    "complaint-management-image-1": "pending_notices_EN.jpg",
    "complaint-management-image-2": "pending_notices_res_EN.jpg",
    "detail-answer-positive-one": "If you think their answer is sufficient",
    "detail-answer-positive-two":
      "and you want to cancel your report, you must retract your report.",
    "detail-answer-negative-one":
      "If you consider that the seller's response is insufficient,",
    "detail-answer-negative-two":
      "you must confirm your report. In this case, we will also ask you to select the reason why you rejected the seller's response.",
    "closed-complaints": "Closed complaints",
    "closed-complaints-answer":
      "Here you can consult the history of your closed complaints. Closed reports are those that end because you have already responded to the seller, because we have rejected it or because we have already terminated the seller or the publication.",
    "publication-detail-text": "Listing details",
    "comment-pending": "Comment",
    "optional-option": "Optonal",
    "send-answer-pending": "Send reply",
    pending_textarea_placeholder:
      "Give the seller more details about your decision.",
    "comment-detail-pending":
      "Review the seller's counter-notice before making your decision.",
    "answer-for-seller": "Answer for the seller",
    "confirm-report": "Confirm report",
    "retract-report": "Withdraw report",
    "reason-for-confirmation": "Reason for confirmation",
    "incorrect-documentation":
      "The documentation does not correspond to the reported product",
    "unreadable-documentation": "Documentation is unreadable",
    "unauthorized-user-document": "You are not authorized to use this content",
    "does-not-justify-stock": "Does not justify stock",
    "counterfeited-buy": "Make a purchase and it's counterfeit",
    "luxury-product-with-a-lot-stock":
      "It is a luxury product with too much stock",
    "low-average-price": "Priced below average",
    "comments-seller-null": "Seller made no comments",
    "list-to-back-closed-denounced": "See the list of closed reports",
    "list-to-back-pending-denounced": "See the list of pending reports",
    "list-to-back-noticed-denounced": "Back to list",
    "denounced-state": "Status of the complaint",
    rollbackeable: "I would like to reactivate this listing. ",
    "rollback-tooltip":
      "Mark this option if you would like to retract this report and reactivate the listing. ",
    "rollbackeable-description-title": "Retraction reason",
    "rollback-placeholder":
      "Please inform the reasons for retracting the report.",
    "rollback-case": "Reactivate listing",
    "rollback-success": "Done! The report was retracted. ",
    "rollback-error": "We were unable to save the changes. Please try again. ",
    "retracted-reason-title": "Retraction reason:",
    "see-documentation": "See documentation",
    "attached-documentation": "Attached documentation",
    "see-images-updated": "See modified images",
    "images-updated": "Edited images",
    "reported-images": "Reported images",
    "actual-images": "Current images",
    "commets-seller": "Seller's comment",
    "sellers-history": "Seller's history",
    "sellers-history-modal": {
      title: "Reports received by the seller",
      subtitle:
        "It is the history of the most recent reports received by this seller, from you as well as from other rights holders.",
      receive_s: "report received",
      receive_p: "reports received",
      receive_n: "No reports received",
      sent_s: "reply sent",
      sent_p: "reply sent",
      accepted_s: "reply accepted",
      accepted_p: "replies accepted",
      table: {
        th1: "Listing",
        th2: "Report Date",
        th3: "Report Reason",
        th4: "Seller’s reply",
      },
    },
    "translate-contents": "Translate contents",
    "answer-seller": "Seller's response",
    "see-ofert-auditor": "View auditor's commentary",
    "auditor-comment": "Auditor comment",
    "auditor-said": "said",
    "motif-denounced": "Reason for the complaint",
    "see-publication": "See denounced publication",
    "download-screenshot": "Download screenshot",
    "denounced-publication": "Denounced publication",
    "send-answer-condition":
      "If you do not respond before the expiration date, the publication will be shown again in Mercado Libre",
    "send-answer": "Send your reply to the seller",
    "closed-title": "Closed report",
    "closed-subtitle":
      "You can check the details and retract your report, but you will not be able to communicate with the seller.",
    "rejected-title": "We were unable to process the report",
    "rejected-subtitle":
      "Review in the report status why we were unable to process it.",
    expires_text: "expires",
    "back-to-list": "Back to the list",
    "see-report": "see report",
    answer: "Submit counter-notice",
    see: "View",
    "analyse-response": "Analyze counter-notice",
    expires: "Expire",
    motif: "Reason",
    action: "Action",
    statusMsgPaused: "Paused listing",
    automation: {
      newbie: {
        title:
          "You have reached the limit of 50 daily reports. You will only be able to make a new report tomorrow ",
        text1:
          "The reason for this is because your account is new and we need to evaluate wheter the reports you made correspond to the rights you enrolled",
        text2: "Today you can only flag listings as suspicious ",
        button: "Learn more.",
      },
      newbieInfo: {
        title: "Your daily limit is 50 reports",
        text1:
          "This happens because your account is new and we need to check if the reports you made correspond to the rights you enrolled",
        text2: "You can still make {denounces} reports today.",
        text3:
          "After that you will only be able to flag listings as suspicious",
        button: "Learn more",
      },
      pending_cn: {
        title: "You have {30} counter-notices that expire today",
        text1:
          "You must review and respond to them in order to be able to keep reporting. Otherwise, the listings you reported will be automatically reactivated.",
        text2:
          "Remember that once received, you have 4 days to review the seller's counter-notice.",
        button: "Respond",
      },
      limited_by_inefficient: {
        with_denounces_p: {
          title: "Your account has been restricted to 50 daily reports",
          text1:
            "This happens because the efficiency of your account is very low.",
          text2: "You can still make {number} reports today",
          text3:
            "After that you will only be able to flag listings as suspicious.",
          button: "Learn more",
        },
        with_denounces_s: {
          title: "Your account has been restricted to 50 daily reports",
          text1:
            "This happens because the efficiency of your account is very low.",
          text2: "You can still make {number} report today.",
          text3:
            "After that you will only be able to flag listings as suspicious.",
          button: "Learn more",
        },
        without_denounces: {
          title: "You have reached your daily reports limit",
          text1: "Today you can only flag listings as suspicious",
          text2:
            "We recommend you to review, in the efficiency section, the reasions why your account has been restricted.",
          button: "Learn more",
        },
        general: {
          text3: "Review your history to learn more.",
          button1: "Go to Report History",
          button2: "Learn more",
        },
        day_limit: {
          title:
            "You won’t be able to make any more reports for the rest of the day",
          text1:
            "You have already reported your daily maximum quantity of 30.000 listings.",
          text2:
            "Please note that you can still mark listings as suspicious, but won’t be able to report them until tomorrow.",
        },
      },
      items_inactive: {
        case_1: "You can send only {50} reports today.",
        case_2: {
          text1: "You've already sent {20} of your {50} maximum daily reports.",
          text2: "There are {30} left.",
        },
        case_3: {
          text1:
            "You've already reported your maximum daily number of {50} listings.",
          text2: "You cannot make any more reports for the rest of the day.",
        },
        tooltip:
          "We restricted your reports, because more than 60% were rejected in the last 2 months.",
      },
      generic: {
        title: "You cannot make any more reports",
        text: "Please note that you can mark listings as suspicious, but you will not be able to make the reports until {date}.",
      },
      tooltip_all: {
        title: "Would you like to send all reports?",
        body: "After you send them, we will notify the seller to send their counternotice.",
        ok: "Send reports",
        cancel: "Cancel",
      },
      tooltip_partial: {
        title_p: "You can only send {10} reports",
        title_s: "You can only send 1 report",
        body_1: "The listings you selected exceed your daily reporting limit, ",
        body_2: "however, you can send {10}.",
        ok_p: "Send {10} reports",
        ok_s: "Send 1 report",
        cancel: "Cancel",
      },
    },
    counter_notice_new_deadline_1:
      "We changed the deadlines to reply to sellers.",
    counter_notice_new_deadline_2:
      "Now you’ll have 4 days to review the counter-notices.",
    article_detail: {
      breadcrumb_label: "Item Detail",
      description: "Description",
      empty_description:
        "The seller didn't add a description for this product.",
      back_to_results: "Back to results",
      back_to_suggested_listings: "Back to suggested listings",
      view_all_description: "View entire description",
      last_questions: "Latest questions",
      last_questions_catalogo: "Answers ",
      empty_questions: "Nobody asked any questions yet.",
      view_more_questions: "See more questions",
      marked_as_suspicious: "Listing marked as suspicious",
      motive: "Reason",
      marked_as_trusted: "Listing marked as trusted",
      by: "by",
    },
    mark_as_suspicious_label: "Suspicious",
    mark_as_trusted_label: "Trusted",
    mark_as_catalogo_label: "View details",
    original: "Orig",
    translation: "Translation",
    translate_click_here_original: "See original",
    translate_click_here_translated: "See translation",
    maintenance: {
      we_are_working: "We're working on new improvements!",
      come_back_soon: "Come back soon and log in again to check them out.",
    },
    all_auditors: "All auditors",
    there_are_not_listings_that_match: "No listing matches your search",
    view_more: "see more...",
    know_more: "Conoce más",
    view_more_suggested: "View more suggested",
    view_all: "View all",
    video_tutotial_link:
      "https://www.youtube.com/embed/pgPXVPHEfug?rel=0&amp;showinfo=0",
    title_snapshot_dowload: "Snapshot Download",
    snapshot_dowload_error:
      "There was an error while getting the download link, try again later.",
    snapshot_link_label: "Click here to download the snapshot",
    new_content_available: "New content available.",
    check_report_codes_for:
      "Make sure you have complaint codes for the following countries: ",
    no_report_codes_available_for:
      "You do not have report codes available for ",
    about_to_delete_live_search:
      "You are about to delete the following Livesearch:",
    activate_all: "Activate all",
    countries_toggle_info:
      "Click on the countries you wish to skip from your search. To exclude all, please use the button below.",
    authorize_btn: "Click here to authorize",
    message_authorize:
      "You still have to authorize the following countries to search and report on them",
    activate_none: "Deactivate all",
    expand: "Expand",
    collapse: "Collapse",
    activity: "Activity",
    activity_uppercase: "ACTIVITY",
    apply: "Apply",
    apply_filters: "Apply changes and update search",
    apply_filters_error:
      "We are Sorry but we couldn't apply filters. Please try again",
    applying_filters: "Applying filters",
    are_you_sure: "Are you sure?",
    question_send_all_notices: "Send notices to all?",
    article: "Listing",
    disabled_article: "deactivated post",
    article_change_status_error:
      "We are Sorry but but we couldn't change the status of the article.",
    article_change_status_error_already:
      "It was already audited inside your organization.",
    articles_change_status_error:
      "We are Sorry but but we couldn't change the status of the articles.",
    articles_change_status_error_already:
      "They were already audited inside your organization.",
    article_drop: "takedown",
    article_drops: "takedowns",
    article_from_white_seller: "Black listed seller",
    article_ok: "Product OK",
    article_invalid: "You can't report this listing",
    article_reference: "ID",
    article_brand_name: "Brand",
    article_part_name: "Part Number",
    article_type: "Article Type",
    article_without_picture: "PRODUCT WITHOUT PICTURE",
    in_moderation_card_title: "Report sent",
    in_moderation_card_text_1: "Check the status of your reports in the ",
    in_moderation_card_text_2: "Report History ",
    in_moderation_card_text_3: "section.",
    articles_to_ok_warn:
      "You are about to change the status of this Listing to OK. This action cannot be undone",
    articles_to_pending_warn:
      "You are about to change the status of this listing to 'Audit Pending'. All report information will be lost. Do you want to continue?",
    mark: "Mark",
    articles_to_report: "listings as suspicious",
    article_to_report: "listing as suspicious",
    articles_to_report_menu: "Report suspicious listings",
    articles_to_report_short: "Prods. to report",
    audited: "Audited",
    audited_batch_title: "Audited in batch selection mode",
    result: "Result",
    results: "Results",
    ready_to_audit: "READY TO AUDIT",
    available_quantity: "available",
    avoided_words: "Words to be excluded",
    batch_mode: "Batch mode",
    batch_mode_info:
      "Select several listings at once by activating Batch Mode, and clicking on the thumbs up or down.",
    brand: "Brand",
    brands: "Brands",
    ok: "Ok",
    accept: "Accept",
    cancel: "Cancel",
    cancel_batch_warning:
      "You're going to loose all your changes. Are you sure?",
    category: "Category",
    choose_another_brand: "Choose another brand",
    choose_brand: "Choose brand",
    condition_all: "All",
    condition_from_blacklisted_sellers: "From Blacklisted Sellers",
    condition_new: "New",
    condition_used: "Used",
    condition_refurbished: "Refurbished",
    "official-store": "Official Store Product",
    "switch-translate": {
      label: "Translate listing",
      tooltip: {
        title: "Translate this listing",
        description: "Activate our translator and analyze your report better.",
        button: "Got it",
      },
      tooltip_2: {
        title: "We deactivated this function while you report",
        description:
          "We have done it, so that you can analyze and mark violations about original texts.",
        button: "Got it",
      },
      snack_message: {
        title: "This is an automatic translation and it may contain mistakes",
        description:
          "Please note that the translations tool is a third-party service and should not be interpreted as an official translation. It’s only a suggestion and its use is not mandatory.",
      },
      error:
        "We had a problem and couldn’t load the translated text. Please try again.",
    },
    languages: {
      es: "Spanish",
      en: "English",
      pt: "Portuguese",
    },
    countries: {
      AA: "Global",
      AR: "Argentina",
      AT: "Austria",
      BE: "Belgium",
      BO: "Bolivia",
      BR: "Brazil",
      CA: "Canada",
      CH: "Switzerland",
      CL: "Chile",
      CO: "Colombia",
      CR: "Costa Rica",
      DE: "Germany",
      DO: "Dominican Republic",
      RD: "Dominican Republic",
      EC: "Ecuador",
      EN: "England",
      ES: "Espana",
      FR: "France",
      GB: "United Kingdom",
      GT: "Guatemala",
      HN: "Honduras",
      IE: "Ireland",
      IT: "Italy",
      MX: "Mexico",
      NI: "Nicaragua",
      NL: "Holland",
      PA: "Panama",
      PE: "Peru",
      PR: "Puerto Rico",
      PT: "Portugal",
      PY: "Paraguay",
      SC: "Scotland",
      SV: "El Salvador",
      US: "USA",
      UY: "Uruguay",
      VE: "Venezuela",
    },
    countries_legal: {
      AR: "Argentina",
      BO: "Bolivia",
      BR: "Brazil",
      CL: "Chile",
      CO: "Colombia",
      CR: "Costa Rica",
      DO: "Dominican",
      EC: "Ecuador",
      GT: "Guatemala",
      HN: "Honduras",
      MX: "Mexico",
      NI: "Nicaragua",
      PA: "Panama",
      PE: "Peru",
      PY: "Paraguay",
      SV: "El Salvador",
      UY: "Uruguay",
      VE: "Venezuela",
    },
    country: "Country",
    countries_select: "Countries",
    country_selector_clarification:
      "Select those countries in which you wish to search",
    all_the_countries: "All the countries",
    create_live_search_with_current_listings:
      "Create Livesearch with current listings",
    create_report_results: "Create Livesearch report",
    create_livesearch_report_created: "Report created successfully.",
    create_livesearch_report_error: "There was an error creating the report.",
    go_to_ls_reports: "Go to Livesearch reports",
    create_search_of_black_sellers:
      "Create Livesearch with current listings from all Blacklisted Sellers",
    creating: "creating",
    currency_report_results: "Select currency for report results",
    current_listings: "Active Listings",
    onboarding_slide: {
      skip: "Skip",
      start: "Start now",
      slide1: {
        title: "Before we start...",
        text: "Here are some tips so you can get the most out of our reporting tool.",
      },
      slide2: {
        title: "Perform massive searches",
        text: "Use the search field to find infringements in any Mercado Libre website.",
        image: "slide1-en.png",
      },
      slide3: {
        title: "Create your own search filters",
        text: "Customize your searches to find infractions easier and faster.",
        image: "slide2-en.png",
      },
      slide4: {
        title: "Multiple reports at the same time",
        text: "Report several listings from the same screen and in just a few clicks.",
        image: "slide3-en.png",
      },
      slide5: {
        title: "Send all your reports",
        text: "When you’re ready to send your reports, you can confirm one by one or all at once.",
        image: "slide4-en.png",
      },
      slide6: {
        title: "All done! Start reporting",
        text: "If you have any doubts, go to our Help section, from the menu on your left.",
      },
    },
    re_branding: {
      modal_title: "Now we are the Brand Protection Program of Mercado Libre",
      bullet_1: {
        title: "New name",
        subtitle:
          "The Intellectual Property Protection Program is now called the Brand Protection Program.",
      },
      bullet_2: {
        title: "New website",
        subtitle: "Now with more information and Frequently Asked Questions.",
      },
      bullet_3: {
        title: "The usual commitment",
        subtitle:
          "We continue working to improve the Program and make the reporting process even easier.",
      },
      check_out_what_is_new: "Check out what’s new!",
      go_to_bpp_site: "Go to site",
    },
    suggestions_slide: {
      next: "Next",
      start: "Got it",
      new_user: {
        slide1: {
          title: "A new way of reporting",
          text: "Analyze listings automatically detected, based on our processes and your reporting history.",
        },
        slide2: {
          title: "The more you analyze, the better suggestions will be",
          text: "We learn from your reportings in order to keep improving our detections and update your recommendations.",
        },
        slide3: {
          title: "New! Filter per country and keep reporting",
          text: "Find suggested listings in countries you are interested in and observe the progress of your last week's reportings.",
        },
      },
      old_user: {
        tag_text: "New!",
        slide1: {
          title: "Filter per country and keep reporting",
          text: "Find suggested listings in countries you are interested in and observe the progress of your last week's reportings.",
        },
      },
    },
    verify_profile: {
      title: "Update your account details",
      subtitle:
        "In order for us to continue to process your reports correctly, we need you to update your information.",
      expires: "You have until",
      verify_btn: "Update details",
      remind_later: "Remind me later",
      alert: {
        main_text: "Update your account details.",
        secondary_text:
          "Make sure your information is complete, so that we can continue to process your reports.",
        review_data: "Update details",
      },
      form: {
        title: "Account details",
        subtitle: "Update your account details. Please note that ",
        subtitle_b: "you will not be able to edit after confirming them.",
        confirm: "Confirm",
        account_type: {
          verified_title: "User type",
          title: "What type of user do you want to register?",
          item_titular: "I am the rights holder or legal representative",
          helper_titular:
            "That is, you are the owner or legal representative of the rights that you are going to register.",
          item_apoderado: "I am a representative of the rights",
          helper_apoderado:
            "That is, you are a natural or legal person authorized by the owner of the rights to act on his behalf.",
        },
        attorney_accreditation: {
          title: "Accredit yourself as a representative",
          name: "Representative Name:",
          name_helper: "It can be a natural person or a company.",
          subtitle: "Owner contact details",
          subtitle_helper_1: "optional",
          subtitle_helper_2:
            "It can be the brand protection manager or IP counsel.",
          contact_name: "Contact Name",
          email: "Email Address",
        },
        account_info: {
          title: "Account information",
          subtitle: "The rights holder is:",
          natural: "Natural person",
          legal: "Legal person",
          physical_fields: {
            name: "Name and Last Name",
            country: "Country",
            type: "Identification type",
            number: "Identification number",
          },
          legal_fields: {
            name: "Name or Company Name",
            country: "Country",
            type: "Tax identification type",
            number: "Tax identification number",
          },
        },
        attorney_contact: {
          title: "Representative Contact",
          helper:
            "This is the data that we will use from Mercado Libre to contact the account representative or administrator.",
          name: "Name and Last Name",
          code: "International Code",
          tel: "Phone Number",
          tel_helper: "Include the Area Code.",
          email: "Business Email Address",
          email_ex: "For example, john.doe@yourcompany.com",
          lang: "Contact Language",
          lang_placeholder: "Select",
        },
        public_data: {
          title: "Public Details",
          helper:
            "This is the information that we will send to the reported seller so that they can contact you once the reporting process is complete. Make sure to put the name of the brand or economic group so that the seller knows who is reporting it.",
          name: "Name to show",
          email: "Email Address",
          email_helper:
            "It can be generic and it must not be an email already registered in Mercado Libre.",
        },
        exit_modal: {
          title: "Do you want to exit the form?",
          text: "If you leave the page, you must complete all the information again.",
          back: "Back",
          exit: "Exit",
        },
        success: {
          text: "Done! We have updated your details.",
        },
        error_email: {
          text: "The email address entered is already registered on Mercado Libre. Please try a different one.",
        },
      },
    },
    suggested_listings: {
      title: "Suggested Listings",
      subtitle_p:
        "In this section, you will see the listings we found based on our detection and your reporting history.",
      subtitle_s:
        "These are the listings we found based on our detection and your reporting history.",
      stats: {
        box_1: {
          text1_p: "listings to report",
          text1_s: "of analyzed listings",
          text2: "Report",
        },
        box_2: {
          text1: "Your progress along the 7 days",
          text2_p: "marked as suspicious",
          text2_s: "marked as suspicious",
          text3_p: "marked as trusted",
          text3_s: "marked as trusted",
          text4: "Evolution of complaints",
          text5: "today",
        },
        tooltip_p: "reported listings",
        tooltip_s: "to report",
      },
      item1s: "of analyzed listings",
      item2s: "marked as trusted",
      item3s1: "marked as suspicious",
      item3s2: "Report",
      item1p: "of analyzed listings",
      item2p: "marked as trusted",
      item3p1: "marked as suspicious",
      item3p2: "Report",
      not_results_title: "There are no more suggestions!",
      not_results_link_label: "Make a new search",
    },
    suggested_discard: {
      text_btn: "Discard listing",
      tooltip: {
        title: "Are you unsure about what to do?",
        description:
          "Now you can discard the listing, so that we no longer show it in your results.",
      },
      modal: {
        title: "Why would you like to discard this listing?",
        subtitle:
          "Remember that we will show it again in your search results if the seller changes it or receives new questions.",
        discard_item: {
          item1: "I am not sure that it has a violation",
          item2:
            "The listing or product does not correspond to my registered rights",
          item3: "The listing or product is not relevant to my business",
          item4: "Other reason",
          item1_subtext:
            "That is, you did not find precise evidence of a counterfeit product, intellectual property violations etc.",
          item2_subtext:
            "That is, the rights you have do not enable you to report violations about this listing or product.",
          item3_subtext:
            "That is, it is not the main focus of the brand or your operation.",
          item4_subtext: "",
        },
        step_2: {
          title: "Can you tell us a bit more?",
          subtitle: "(Optional)",
          placeholder: "Tell us why you want to discard the listing.",
          btn_text: "Discard listing",
        },
      },
      done_article_discarded:
        "Done! If the listing has changes, we will show it to you again.",
    },
    file_uploader: {
      formats: "Formats",
      maximum_size: "Maximum size",
    },
    xlsx_import_success: {
      done: "Done!",
      marked_listing: "You marked 1 listing as suspicious.",
      marked_listings: "You marked {0} listings as suspicious.",
      go_to_report: "Report",
    },
    add_suspicious_xlsx: {
      alert_easier: "Bulk reporting is easier now.",
      alert_info:
        "You can now submit a spreadsheet with all the listings you wish to report.",
      alert_btn_label: "Bulk upload",
      tooltip_onboarding_title: "Do you need to import a list?",
      tooltip_onboarding_text:
        "Now you can download your Excel spreadsheet of suspicious listings in this section.",
      tootip_btn_title: "Download your spreadsheet here.",
      tootip_btn_text:
        "You will only be able to import files that you have downloaded here.",
      modal_title: "Import list of listings suspicious",
      modal_subtitle_text1:
        "Please note that you can only import files downloaded from this section.",
      modal_subtitle_text2: "Download your Excel file",
      btn_import: "Send file",
      elements: "imported elements",
      element: "imported element",
      listings_found: "listings successfully imported",
      listing_found: "listing successfully imported",
      errors: "Errors",
      error: "listing that could not be processed",
      listings_duplicated: "listings already marked as suspicious",
      listing_duplicated: "listing already marked as suspicious",
      download_detail: "Download the spreadsheet to verify the errors",
      process_results_title: "We processed correctly the uploaded data",
      found_count_label: "Quantity of listings that you can add: ",
      not_found_count_label: "Quantity of listings that we could not find: ",
      invalid_count_label: "Quantity of invalid ids: ",
      how_to_title: "How to assemble the list",
      how_to_bullet_1: "Include one listing per row.",
      how_to_bullet_2: "You can include either the listing ID or its URL.",
      how_to_bullet_3: "Add only listings from the same country.",
      how_to_bullet_4: "You can import a maximum of 1000 listings at a time.",
      how_to_bullet_5:
        "Make sure all listings share the same reporting reason.",
      how_to_important: "Important!",
      how_to_download: "Download example",
      processing: "Processing",
      empty_text: "Add file",
      empty_drag_text: "You can also drag them",
      on_drag_text: "Drop the file here to process it",
      drag_file_too_large: "File is too large",
      error_reading_file:
        "We could not check your listings. Please try again later.",
      only_duplicated_listings_message:
        "The listings are already marked as suspicious.",
      only_duplicated_listing_message:
        "The listing is already marked as suspicious.",
      not_enrolled_rigths_listings_message:
        "The listings are in a marketplace where you do not have enrolled rights.",
      not_enrolled_rigths_listing_message:
        "The listing is in a marketplace where you do not have enrolled rights.",
      not_valid_found_message:
        "We could not find listings with the IDs or URLs entered.",
      only_one_site: "The file contains listings from more than one country.",
      only_one_file: "You can’t send more than one file at a time.",
      incorrect_file_format: "This file has incorrect format.",
      too_many_message: "The file contains more than 1000 components.",
      file_exceeds_maximum_size: "The file exceeds the maximum size.",
      reason_select: "Select denounce reason",
      reason_no_selected: "The reason was not selected",
      comment_title: "Add comment",
      comment_description:
        "Here you can add a comment to accompany your denounce",
      comment_warning: "The text must be at least 5 characters.",
    },
    live_search_report_form: {
      new_report: "New report",
      source: "Source",
      option_meli_dump: "Dump",
      option_meli_api: "API",
      search_word: "Search Term",
      nickname_seller: "Seller Nickname",
      listing_state: "Listing status",
      all: "All",
      active: "Active",
      paused: "Paused",
      countries: "Countries",
      no_countries: "There are no selected countries",
      preview: "Apply and search",
      create: "Create report",
      selected_country: "selected country",
      selected_countries: "selected countries",
      clarification: "The report is generated using data updated once per day.",
    },
    denunciated: "reported",
    denunciation: "report",
    download_report_results: "Download Report Results",
    ebay: "eBay",
    edit: "Edit",
    email: "E-mail",
    enter_keyword_for_live_search: "Select your search criteria",
    start_the_search_in: "Start my search in ",
    stored_searches: "Saved searches",
    export_data: {
      reports: {
        all_sellers: "All sellers",
        audits_by: "Audits by",
        avoid_words: "Avoid words",
        blacklisted_sellers: "Blacklisted sellers",
        brand: "Brand",
        city: "City",
        brands: "Brands",
        ok: "Ok",
        accept: "Accept",
        cancel: "Cancel",
        cancel_batch_warning:
          "You're going to loose all your changes. Are you sure?",
        category: "Category",
        choose_another_brand: "Choose another brand",
        choose_brand: "Choose brand",
        condition_all: "All",
        condition_from_blacklisted_sellers: "From Blacklisted Sellers",
        condition_new: "New",
        condition_used: "Used",
        condition_refurbished: "Refurbished",
        "official-store": "Official Store Product",
        languages: {
          es: "Spanish",
          en: "English",
          pt: "Portuguese",
        },
        countries: {
          AA: "Global",
          AR: "Argentina",
          AT: "Austria",
          BE: "Belgium",
          BO: "Bolivia",
          BR: "Brazil",
          CA: "Canada",
          CH: "Switzerland",
          CL: "Chile",
          CO: "Colombia",
          CR: "Costa Rica",
          DE: "Germany",
          DO: "Dominican Republic",
          RD: "Dominican Republic",
          EC: "Ecuador",
          EN: "England",
          ES: "Espana",
          FR: "France",
          GB: "United Kingdom",
          GT: "Guatemala",
          HN: "Honduras",
          IE: "Ireland",
          IT: "Italy",
          MX: "Mexico",
          NI: "Nicaragua",
          NL: "Holland",
          PA: "Panama",
          PE: "Peru",
          PR: "Puerto Rico",
          PT: "Portugal",
          PY: "Paraguay",
          SC: "Scotland",
          SV: "El Salvador",
          US: "USA",
          UY: "Uruguay",
          VE: "Venezuela",
        },
        country: "Country",
        currency: "Currency",
        date_finish: "Date Finish",
        date_start: "Date Start",
        generated_by: "Generated by",
        id: "Id",
        items_sold: "Items sold",
        listing_lnk: "Listing link",
        listing_title: "Listing title",
        listing_type: "Listing type",
        listings: "Listings",
        location: "Location",
        marketplace: "Marketplace",
        marketplaces: "Marketplaces",
        offered_stock: "Offered stock",
        original_currency: "Orig. currency",
        original_price: "Orig. price",
        picture_1: "Picture 1",
        picture_2: "Picture 2",
        picture_3: "Picture 3",
        picture_4: "Picture 4",
        price: "Price",
        price_range: "Price range",
        quantity: "Quantity",
        removed_listings: "Removed Listings",
        report_code: "Claimed infringement",
        report_currency: "Report Currency",
        report_date: "Flagged on",
        report_description: "Report description",
        report_tags: "Report tags",
        reported_listings: "Reported Listings",
        reports: "Reports",
        required_words: "Required words",
        search_by_marketplaces: "Search made in the following Marketplaces",
        search_terms: "Search Terms",
        seller: "Seller",
        seller_username: "Seller Username",
        sellers_type: "Sellers",
        snapshot: "Snapshot",
        sold: "Sold",
        stock: "Stock",
        tag: "Tag",
        take_down_date: "Take down date",
        report_status: "Report Status",
        top_countries: "Top 5 Countries",
        top_countries_by_publications:
          "Top 5 Countries according to number of listings",
        top_reported_sellers: "Top 5 Reported Sellers",
        top_sellers_by_publications:
          "Top 5 Sellers according to number of listings",
        total_value_reported_listings: "Total Value of Reported Listings",
        user: "User",
        value_higher_than: "Higher than",
        value_less_than: "Less than",
        volume_offered: "Volume Offered",
        volume_reported: "Volume reported",
        volume_sold: "Volume sold",
        worksheet_executive_summary: "Executive Summary",
        worksheet_listings: "Listings",
        worksheet_marketplaces: "Marketplaces",
        worksheet_reported_listings: "Reported Listings",
        worksheet_sellers: "Sellers",
        worksheet_sellers_info:
          "* Some Marketplaces don't offer data about Stock quantity and number of Sold Items.",
        worksheet_tags: "Tags",
      },
    },
    exit_create_account: "Are you sure you want to exit?",
    create_auditor: "Now you can create auditor users",
    auditor_user: "Auditor User",
    name: "Name",
    last_name: "Last Name",
    user_email: "Email",
    user: "User",
    add_auditor: "Add auditor",
    error_password_confirm: "Passwords must match",
    new_password_rules:
      "Password must be grater than 8 characters and different to the current one.",
    error_password_length: "Password length must be grater or equals 8",
    wrong_password: "Wrong password or user",
    forgot_password: "Forgot your password?",
    confirmation_code: "Verification code",
    enter_confirmation_code: "Enter the Verification Code",
    sent_to:
      "The verification code was sent via email to $email. If you are not able to find it, please check your Spam folder.",
    did_not_receive: "I did not receive the code",
    yes: "Yes",
    no: "No",
    wrong_confirmation_code: "Wrong information code",
    send_new_code: "Send new code?",
    set_custom_password: "Please set a custom password",
    add_user: "Add auditor",
    create_user: "Create user",
    create_users: "Create users",
    continue_button: "Continue",
    account_data: "Account details",
    account_data_subtitle:
      "With these details you will be able to link all of your Brand Protection Program users and report in several countries all at the same time.",
    default_account: "This will be the username to log in",
    user_already_exist: "You already have an account created with this email.",
    user_not_found: "The user does not exist in the selected country.",
    auditors: "Auditors",
    auditor: "Auditor",
    meli_token_authenticate_error:
      "Wrong username. Log in with your Brand Protection Program account.",
    code_resend: "We have resend you the code to your email",
    account_welcome_message:
      "now create an account in order to use our new reporting tool",
    account_created_title: "Done! You are now registered in the new tool",
    account_created_message:
      "You can now start reporting more easily and efficiently with the new Brand Protection Program tools.",
    create_account_success: "User successfully created",
    main_account_info: "$account, please remember:",
    account_created_reminder:
      "If you usually report on Brand Protection Program through providers that protect your rights and now you created a user to report directly, consider that the answers to your reports will be received by the providers and they may be unaware of your account.",
    account_created_recomendation:
      "Therefore, we recommend that you contact them to coordinate the follow-up.",
    look_what_you_can_do: "Look at everything you can do",
    create_account_error:
      "There was an error while we were creating the user, please try again",
    export_xls: "Export Template",
    "main-page-welcome-msg": "We renew our Brand Protection Program",
    "main-page-title":
      "We create new processes to identify infringing sellers, and sanction them faster",
    "main-page-subtitle": "Besides, we give you the best tools:",
    "main-page-item-1":
      "Find and report easier on different countries at the same time",
    "main-page-item-2": "Create and save your own search criteria",
    "main-page-item-3":
      "Choose on which sellers you want to focus and and analize only its listings",
    "main-page-item-4": "Go to reports with all reported and removed listings",
    "main-page-item-5": "Add and renew your rights easier and faster",
    register_as_auditor:
      "Register as auditor to start reporting and get to know all the benefits.",
    help_message: "Find all you need in the %{copy_help} section.",
    dont_know_how_to_start: "Don't know where to begin?",
    close_help_dialog_error:
      "There was an error while we were closing the dialog, please try again later.",
    more_about_pppi: "More about Brand Protection Program",
    need_help: "Need some help?",
    help: "Help",
    help_href: "/noindex/pppi/support?lang=en",
    know_more_href:
      "https://global-selling.mercadolibre.com/noindex/pppi/landings/bpp",
    register_href:
      "https://www.mercadolibre.com.ar/noindex/pppi/rights/enroll/",
    contact_us: "Contact us",
    buy_and_sell: "Buy and sell from your cellphone!",
    enter_your_email: "Enter your email",
    failed_articles_message:
      "This Livesearch could not be completed successfully, there were %{count} articles that could not be loaded",
    old_password: "Old password",
    new_password: "New password",
    new_password_confirm: "New password confirm",
    password_changed_success: "Your password has been changed successfully",
    password_changed_error:
      "Sorry, we could not be able to change your password, please try again later",
    enter_your_new_password: "Enter your new password",
    password_confirmation: "Password confirmation",
    change_password: "Update password",
    changing_password: "Updating password",
    send_email: "Send",
    sending_email: "sending email",
    sendind_email: "sending",
    filter_profile: {
      "clarification-p1": "By clicking ",
      "clarification-p2": "Apply and search, ",
      "clarification-p3": "your preferences will be saved automatically.",
      all: "All countries",
      apply_filters: "Apply and search",
      article_condition: "Condition",
      categories: "Categories",
      none_category: "None",
      no_subcategories_for: "There are no subcategories for",
      no_category_selected: "You don't have any selected categories",
      info_categories_language:
        "We’ll show the categories in the language of the country you’re searching in.",
      change: "Change",
      article_new: "New",
      article_used: "Used",
      available_quantity: "Available stock",
      banned_keywords: "Banned keywords",
      copy_of: "Copy of %{name}",
      "country.name": "Country",
      "prices.USD": "Price",
      date_range: "Date range",
      default: "Default",
      extended_data_warning:
        "This will only retrieve articles from sites that provide this kind of data.",
      extended_data_sites_list: "Sites available: %{sites}.",
      fromDate: "Date From",
      fromPrice: "Price from",
      greater_than: "Greater than",
      no_name: "No name",
      portal: "Portal",
      price_range: "Price",
      required_keywords: "Required keywords",
      seller: "Sellers",
      seller_blacklisted: "Blacklisted sellers",
      no_seller_selected: "You don't have any sellers selected",
      none_seller: "None",
      sold_quantity: "Sold stock",
      sort_by: "Sort by",
      select_sort_by: "Select",
      title: "Filter profiles for this Livesearch",
      toPrice: "Price to",
      toDate: "Date To",
      select_one_site: "Select at least one country",
    },
    filter: "Edit filters",
    clean_filter: "Clean filters",
    filtered: "Filtered",
    filtered_results: "Filtered results",
    filters_title: "Search Criteria – Refine results",
    forgot_your_password: "Forgot your password?",
    generating_report_results: "Generating Report Results",
    greater_than: "Higher than",
    home_title: "Brand monitoring",
    in_progress: "in process",
    processing: "Processing",
    language: "Language",
    live_search: "Livesearch",
    live_search_all_loaded: "All results shown",
    live_search_fetch_error:
      "We're Sorry but there was an error when trying to fetch the Livesearch. Please try again",
    live_search_create_error:
      "We're Sorry but there was an error when trying to create the Livesearch. Please try again",
    live_search_refresh_error:
      "We're Sorry but there was an error when trying to update the Livesearch. Please try again",
    live_search_delete_error:
      "We're Sorry but there was an error when trying to delete the Livesearch. Please try again",
    live_search_filters: "Search criteria",
    live_search_loading_results: "Loading more results",
    live_search_queue_header: "Livesearch queue – Search in process",
    live_search_recent: "RECENT",
    live_search_refinements_error:
      "There was an error while aplying filters, please try again!",
    live_search_reloading: "Restarting",
    live_search_results: "SEARCH RESULTS",
    live_search_empty: "There are no results yet",
    show_hidden_publications: "View hidden listings",
    hidden_tooltip_text:
      "We've hidden the listings you marked as non-infringing, those you've already reported and those who belongs to whitelist sellers.",
    in_moderation: "Reported",
    validated: "Non-infringing",
    denounced: "Reported",
    multiple_selector: {
      advice:
        "Remember that you cannot use the batch mode to report image copyrights.",
      trusted: "Whitelisted",
      suspicious: "Suspicious",
      tooltip_text:
        "You cannot use this function to report unauthorized use of images.",
    },
    quick_search: "Quick search",
    quick_search_placeholder: "Search by keywords, EAN or Part Number",
    publication_id: "Listing ID",
    publication_id_placeholder: "MLB-745531777 o MLB745531777",
    publication_id_helper:
      "Find the complete ID in the listing’s URL. Fill it in with or without hyphens.",
    seller_nickname: "Seller name",
    search_label_seller: "Words to look for (optional)",
    search_label_publication: "Quick search",
    url: "URL",
    no_matches: "No listing matches your search",
    no_matches_advice1: "Check the spelling or use more generic terms.",
    no_matches_advice2: "Try deleting one or more of the search terms.",
    no_matches_advice3: "Make sure the seller is still active on our site.",
    no_matches_advice4: "Change filters",
    nothing_here: "Nothing here...",
    you_did_not_do_any_search_yet: "You don't have any saved searches",
    live_search_empty_filtered: "There are no results for the filters applied",
    live_search_status_count_error:
      "There was an error while we were searching article status counts",
    live_search_new_processed_count: "Available new results",
    live_search_processed_count_error:
      "There was an error while we were searching the livesearch processed count, please try again",
    loading: "Loading...",
    loading_publications: "Loading publications...",
    loading_articles: "Loading articles",
    loading_brands: "Loading brands...",
    loading_live_searches: "Loading Livesearches",
    loading_portals: "Loading websites",
    loading_results: "Loading results",
    login: "Login",
    login_welcome_message:
      "Log in with the username and password for the new tool",
    loging_in: "Loging in...",
    recover_password: "Recover password",
    contact: "Contact",
    logout: "Log Out",
    enroll_rights: "Enroll rights",
    new_profile: "New profile",
    change_profile: "Change profile",
    create_profile: "Create",
    select_profile: "Select profile",
    create_profile_success: "You have sucessfully created a new profile",
    change_profile_success: "You have successfully changed your profile",
    create_profile_error:
      "There was an error while we were creating the profile",
    fetch_profiles_error:
      "There was an error while we were searching the available profiles",
    change_profile_error:
      "There was an error while we were changing the profile, please try again later",
    no_profiles: "No profile available",
    create_account: "Create account",
    lower_than: "Lower than",
    mercadolibre: "Mercadolibre",
    money_saved: "Money Saved",
    new_live_search: "New Livesearch",
    new_search: "New Search",
    new_product: "New product",
    refurbished_product: "Refurbished Product",
    next: "Next",
    no_data: "N/A",
    optional: "optional",
    password: "Password",
    create_user_password: "Password",
    pending: "pending",
    phone: "Phone",
    placeholder_avoided_keywords: "Enter here the words to be excluded",
    placeholder_required_keywords: "Enter here the required words",
    previous: "Previous",
    price: "Price",
    price_range: "Price range",
    prices_larger_than_currency: "Prices higher than",
    prices_smaller_than_currency: "Prices lower than",
    quebarato: "Que Barato",
    refine_search_in_sites: "Refine search criteria",
    remember_me: "Remember me",
    repeat_password: "Repeat password",
    report_label: "Mark as suspicious",
    report_label_plural: "Mark as suspicious",
    edit_report_label: "Save changes",
    report_article_error:
      "We're Sorry but we couldn't send the notice of claimed infringement. Please try again",
    report_article_undo_error:
      "We're Sorry but we couldn't undo that action. Please try again",
    report_code: "Claimed infringement",
    report_code_2: "Claimed infringement",
    report_description_label: "More comments",
    report_description: "Report Description",
    report_description_2: "Report Additional Description",
    report_placeholder:
      "Write down your comments about the listing you’re about to flag (optional).",
    report_to_marketplace: "Report to Site",
    report_status_takedown_submitted: "Takedown Submitted",
    report_status_listing_removed: "Listing Removed",
    report_status_takedown_failed: "Takedown Failed",
    reported_article: "Reported Item",
    reported_to_site: "Reported Site",
    reports: "Reports",
    reports_uppercase: "REPORTS",
    reported_by: "Reported by",
    reputation_excelent: "Excelent",
    reputation_bad: "Bad",
    reputation_good: "Good",
    reputation_regular: "Regular",
    reputation_unknown: "Unknown",
    required_words: "Required words",
    results_per_page: "Results per page",
    screenshoot: "Screenshot at the time of reporting",
    search_of_black_sellers_created:
      "Livesearch with current listings from all Blacklisted Sellers created",
    searches: "Search",
    searches_uppercase: "SEARCHES",
    selected_articles: "Selected Articles",
    seller: "Seller",
    seller_blacklist: "Watch seller",
    black_seller: "Blacklisted seller",
    seller_search_create: "Create livesearch for seller",
    seller_search_created: "Livesearch created",
    seller_white: "Whitelist seller",
    unset_seller_error:
      "We could not update this seller information, please try again later",
    sellers: "Sellers",
    sellers_black: "Blacklisted Sellers",
    sellers_white: "Whitelisted Sellers",
    send_me_reset_password_instructions: "Send me reset password instructions",
    sending_report: "Sending report",
    settings: "Settings",
    profile: "Profile",
    show_current_listing: "Show Current Livesearch",
    enter: "Login",
    entering: "Entering",
    sign_in: "Sign in",
    signing_in: "Signing in",
    sign_up: "Sign up",
    site: "Site",
    site_reputation: "Site Reputation",
    hello: "Hello",
    not_found: "It seems that this page does not exist",
    initial_page: "Go to the main page",
    authorize_sites: "Link countries",
    authorized_site_message:
      "Link all the accounts you use in the Brand Protection Program to report in all countries at once. You will only need to enter the username and password of each country. ",
    authorized_site_title:
      "We noticed you have rights to report in more than one country",
    authorized: "Authorized",
    "new-authorize": "New",
    authorized_country: "Done! You have linked the account for",
    link_other_countries: "Link other countries",
    linked: "Linked",
    link: "Link",
    authorize_site_error:
      "Ooops... there was an error while we were trying to authorize the site, please try again later",
    authorize_site_error_not_pppi:
      "The user with whom you are trying to authenticate does not have a report permission, try to unlog yourself in Mercado Libre and try again with the correct user.",
    incorrect_site_authentication:
      "You have tried to log in with a user that does not belong to the country that you selected. You have to log out in Mercado Libre of that country and try again with a corresponding user.",
    authorize_different_account:
      "You have tried to log in with a user that does not belong to your account.",
    go_to_main_page: "Start reporting",
    start_reporting: "Start reporting",
    sold: "sold",
    sold_quantity: "sold",
    sort_by: "Sort by",
    start_live_search: "Process and Search",
    "dont-repeat-livesearch": "There can not be repeated livesearches",
    status: "Status",
    adding_reported_articles: "Adding Suspicious Listings...",
    add_reported_articles_by_xlsx: "Add Suspicious Listings by Excel",
    submit_all_reported_articles: "Send all denounces",
    submit_selected_reported_articles_s: "Send {10} report",
    submit_selected_reported_articles_p: "Send {10} reports",
    submit_all_reported_articles_tooltip_pending_cn:
      "You can't send reports until you check all your pending counter-notices.",
    submit_all_reported_articles_tooltip_restricted:
      "We restricted your reports while we verify if they match your enrolled rights.",
    submit_all_reported_ready: "Done! We sent your reports",
    submit_one_reported_ready: "Done! We sent your report",
    filter_text: "Filter and sort",
    filter_text_2: "Filter",
    filter_modal: {
      filter_1: "Sort by",
      filter_2: "Reason for report",
      filter_3: "Country",
      filter_4: "For Status",
      apply: "Apply filters",
      apply_2: "Apply",
      clean: "Clear filters",
      filter_1_text_1: "Most recent reports",
      filter_1_text_2: "Oldest reports",
      all_reasons: "All the report reasons",
    },
    search_text: "Search by # or title",
    select_all: "Select all",
    selected_notices_s: "Tienes {10} denuncia seleccionada",
    selected_notices_p: "Tienes {10} denuncias seleccionadas",
    select_page: "Select this page",
    unselect_all: "Clear all",
    unselect_page: "Clear this page",
    clean_all_inactive_btn: "Clear inactive",
    clean_one_inactive_btn: "Clear inactive",
    clean_notices: "Delete reports",
    clean_selected: "Delete selected reports",
    filters_applied: "Filters applied",
    clean_all: "Delete all reports",
    clean_inactive: "Delete inactive reports",
    clean_all_inactive_ready: "Done! We deleted your reports.",
    clean_one_inactive_ready: "Done! We deleted your report.",
    submit_report: "Send report",
    edit_report: "Edit report",
    submit_reported_articles: "Send notice of claimed infringement",
    create_live_search_current_listings:
      "Create Livesearch with current listings from blacklisted sellers",
    questions_and_answers: "QUESTIONS AND ANSWERS",
    fetch_questions_and_answers_error:
      "There was an error getting the questions and answers.",
    support_form: {
      title: "support",
      subtitle:
        "From here we will help you solve all your questions about the Brand Protection Program.",
      textarea_label: "Send us your question",
      textarea_placeholder: "What can we help you with?",
      send_to: "We’ll reply to",
      submit: "Submit question",
      success_modal_title: "Message received!",
      success_modal_will_respond:
        "We’ll reply to your e-mail within the next 48 hours.",
      success_modal_cx_number: "Your case number is ",
      success_modal_understood: "Understood",
      error_message: "We couldn’t submit your question. Try again later.",
    },
    support: {
      title: "Help section",
      contents: "Help section",
      video: "https://www.youtube.com/embed/n0MyiVMt-Xs",
      "contents-steps": {
        "sug-1-1": "Suggested listings",
        "sug-1-2": "Statistics",
        "sug-1-3": "Weekly development",
        "sug-1-4": "Pin item",
        "1-1": "Making a search",
        "1-2": "How to make a search",
        "1-3": "Search filters",
        "1-4": "Listing details",
        "2-1": "Evaluate a listing",
        "2-2": "Validate a listing as suspicious",
        "3-1": "Batch mode",
        "3-2": "As suspicious",
        "3-3": "As trusted",
        "4-1": "Suspicious listings to report",
        "4-2": "Management of articles to report",
        "8-1": "Catalog listings",
        "8-2": "What is a Catalog listing?",
        "8-3": "How to explore participating sellers",
        "8-4": "Which data the seller defines",
        "8-5": "Who answers the buyer's questions",
        "8-6": "How to report a Catalog listing",
        "5-1": "Evaluate sellers",
        "5-2": "How to evaluate sellers",
        "5-3": "How to see all blacklisted and whitelisted sellers",
        "6-1": "Case management",
        "6-2": "Open cases",
        "7-1": "Enroll new rights",
        "7-2": "How to enroll new rights",
      },
      "answer-steps": {
        "sug-1-1":
          "In this section, you will see the listings we found, based on our detections and on your report history.",
        "sug-1-image": "01_Eng_captura_Suger.png",
        "sug-2-1":
          "This complement aims at making suspicious listings visible and ready to be reported.",
        "sug-2-2a": "The ",
        "sug-2-2b": "'Report' ",
        "sug-2-2c": "button leads straight to ",
        "sug-2-2d": "'Suspicious listings to report'",
        "sug-3-1":
          "Here you will find a brief summary of your accomplished work and will be able to view your progress during the last week.",
        "sug-3-2":
          "The values that also appear refer to the number of items tagged as suspicious and as trusted within the last week. Remember that these values are taken only from the items suggested by Mercado Libre.",
        "sug-3-3":
          "The graph shows the flow of the daily reports for the last week. When you hover over the graph, you will see the value of the specific date selected.",
        "sug-3-4":
          "The percentage on the green tag will be shown if the number of your reports increase in this section and on the red one if it has decreased, compared to the previous week.",
        "sug-2-image": "02_Eng_captura_Suger.png",
        "sug-3-2a": "This function enables you to ",
        "sug-3-2b": "pin elements in the first results ",
        "sug-3-2c":
          "and then activate them or avoid missing them. Also, it enables a quick scan of what we have suggested and to accumulate them and later focus on its analysis with more criteria.",
        "sug-3-image": "03_Eng_captura_Suger.png",
        "1-1p1a": "In this section you can search listings by ",
        "1-1p1b": "country* ",
        "1-1p1c": " using different ",
        "1-1p1d": "search criteria: ",
        "1-1p2t": "- Listing name",
        "1-1p2":
          "Search using keywords related to your brand or enrolled rights.",
        "1-1p3t": "- Listing ID",
        "1-1p3":
          "It is the number that uniquely identifies each listing on Mercado Libre.",
        "1-1p4t": "- Seller name",
        "1-1p4": "It is the seller nickname on Mercado Libre.",
        "1-1p5t": "- Listing URL",
        "1-1p5": "It is the direct link to a listing.",
        "1-1-clarification":
          "* Remember that you can search only in those countries which you have enrolled.",
        "1-1-image": "04_Eng_captura_Suger.png",
        "1-2-1p1a": "Select on the menu on the left ",
        "1-2-1p1b": "Searches.",
        "1-2-2":
          "Select the search criteria to be used and fill in the search field.",
        "1-2-3a": "Mark the country where you want to search for listings. ",
        "1-2-3b": "Argentina ",
        "1-2-3c":
          "is set as default, but you can change the country whenever you want.",
        "1-2-4a": "Start the search by clicking on ",
        "1-2-4b": "Search.",
        "1-3a":
          "Once in the list of listing, you can change the search filters to refine the results. To do so, click on the ",
        "1-3b": "arrow icon ",
        "1-3c":
          "on the top-left-hand corner and a list will appear with all filters available.",
        "1-2-image": "05_Eng_captura_Suger.png",
        "1-3-1-title": "Price",
        "1-3-1-text":
          "Defines the search by minimum and maximum price range of products.",
        "1-3-2-title": "Condition",
        "1-3-2-text": "If it is a new or used product.",
        "1-3-3-title": "Categories",
        "1-3-3-text":
          "It enables filtering according to listing categories on the website.",
        "1-3-4-title": "Blacklisted sellers",
        "1-3-4-text":
          "Applies the search on all sellers marked as blacklisted.",
        "1-3-7a": "After selecting the filters, click on ",
        "1-3-7b": "Apply and search.",
        "1-3-8":
          "* Remember that you can search only in those countries in which you have enrolled.",
        "1-4":
          "On the results screen, click on a product to view its details. Then, you will be able to see the listing information, the seller details and reputation, as well as the product price and pictures.",
        "1-3-image": "06_Eng_captura_Suger.png",
        "2-1": "Validate a listing",
        "2-1-1": "From the list of listing",
        "2-1-2a":
          "Hover the mouse over the listings. Click on SUSPICIOUS and TRUSTED buttons to validate them.",
        "2-1-image": "07_Eng_captura_Suger.png",
        "2-1-2b":
          "If you choose the TRUSTED button, the listings will automatically disappear from the search results. If you choose the SUSPICIOUS button, you can 'flag as suspicious' and add the reason.",
        "2-2-image": "08_Eng_captura_Suger.png",
        "2-2-1": "From the listing details",
        "2-2-1a":
          "From this point, you can indicate with the TRUSTED or SUSPICIOUS button if you think it is violating any of your enrolled rights or not.",
        "2-3-image": "09_Eng_captura_Suger.png",
        "2-2-1b":
          "All the products that you had validated as TRUSTED or SUSPICIOUS will be automatically removed from the next search results.",
        "2-3-1": "Validate a listing as suspicious",
        "2-3-2":
          "If you believe an article is violating one or more of your rights, click on the SUSPICIOUS button.",
        "2-3-3":
          "On the menu on the right, with listing details, you will be able to mark the reason for reporting the listing.",
        "2-3-4": "The reasons are as follows.",
        "2-3-4a": "On the product:",
        "2-3-4a-1": "Counterfeit product",
        "2-3-4a-2": "Copyright violation",
        "2-3-4b": "On the listing:",
        "2-3-4b-1": "Illegal use of trademark",
        "2-3-4b-2": "Unauthorized use of third-party images",
        "2-3-4b-3": "Unauthorized use of personal images",
        "2-4-1": "Once the reason is selected and you click on ",
        "2-4-2": "'Continue' ",
        "2-4-3":
          "you will be able to include a comment. Then, click on 'Flag as suspicious'.",
        "2-4-image": "10_Eng_captura_Suger.png",
        "2-5-1":
          "To send a report about this listing, enter the 'Suspicious listings to report' on the menu on the left.",
        "3-1-1":
          "This complement enables you to quickly flag several listings as ",
        "3-1-2": "'Trusted' or 'Suspicious' ",
        "3-1-3":
          "at a time. Remember that you can flag these listings as suspicious only if they share the same reason for the report.",
        "3-1-image": "11_Eng_captura_Suger.png",
        "3-2-1": "How to flag several listings as 'Suspicious'",
        "3-2-2":
          "Click on the 'Batch mode' button and flag all the listings you want to select as 'suspicious' and you will see that the listings flagged will be highlighted in red.",
        "3-2-3":
          "Press the 'Apply' button to flag them as suspicious and continue the report.",
        "3-2-image": "12_Eng_captura_Suger.png",
        "3-3-1": "How to flag several listings as 'Trusted'",
        "3-3-2":
          "Click on the 'Batch mode' button and flag as 'trusted' all the listings that you want to select and you will see that the listings flagged will be highlighted in green.",
        "3-3-3":
          "Touch on the 'Apply' button, so that they disappear from the search list.",
        "3-3-image": "13_Eng_captura_Suger.png",
        "4-1-1": "Management of articles to report",
        "4-1-2":
          "On the left menu, within in the 'Report suspicious listings' section, you will find all the listings flagged as potential violations, but which have not been reported yet.",
        "4-1-image": "14_Eng_captura_Suger.png",
        "4-2-1": "Confirm report",
        "4-2-2a":
          "If you are sure that you want to report the listing, click on ",
        "4-2-2b": "'Send report'. ",
        "4-2-2c": "To send all reports at once, click on ",
        "4-2-2d": "'Send all reports'.",
        "4-3-1": "Cancel report",
        "4-3-2": "If you want to cancel the report, click on ",
        "4-3-3": "'Dismiss'",
        "4-4-1": "Check details",
        "4-4-2":
          "If you want to check the report details, click on the '+' icon.",
        "4-5-1": "Edit the report reason",
        "4-5-2a":
          "If you want to change the reason for the report, click on the ",
        "4-5-2b": "'Edit'",
        "4-5-2c": "button and follow the instructions to add a new reason.",
        "5-1-1":
          "In this section, you can see all sellers that you blacklisted and those that you marked as trusted.",
        "5-1-image": "15_Eng_captura_Suger.png",
        "5-2-1": "How to evaluate sellers",
        "5-2-2":
          "When viewing the listing details, you will see three buttons that enable the evaluation of sellers.",
        "5-3-1": "Blacklisted seller",
        "5-3-2":
          "The seller will receive a black flag. As you identify a seller as blacklisted, his products will be highlighted in your searches, in order to be easily recognized.",
        "5-2-image": "16_Eng_captura_Suger.png",
        "5-4-1": "Trusted seller",
        "5-4-2":
          "When you flag the seller as trusted, he will be automatically included in the list of trusted sellers, and his listing will be removed from the search results.",
        "5-5-1": "Creating the seller livesearch",
        "5-5-2a": "Create a ",
        "5-5-2b": "'Saved search' ",
        "5-5-2c":
          "of the product category and seller, in order to be checked later. You can access it on Search, on the menu on the left.",
        "5-6-1": "Blacklisted sellers",
        "5-6-2":
          "On the listing details, click on the search icon, next to the seller's name. Thus, you will see all sellers that you flagged as blacklisted in your searches.",
        "5-6-3a": "You can access this list on the menu on the left in ",
        "5-6-3b": "Sellers > Blacklisted sellers.",
        "5-3-image": "17_Eng_captura_Suger.png",
        "5-6-4":
          "The table shows the country where each blacklisted seller operates, as well as their Mercado Libre user name, the number of reports received and the quantity of products in his sales list.",
        "5-7-1": "How to check all the articles from blacklisted sellers",
        "5-7-2a": "First, click on ",
        "5-7-2b": "'Create a new livesearch'",
        "5-7-2c":
          "in the table. This search will be saved on the 'Search' section.",
        "5-4-image": "18_Eng_captura_Suger.png",
        "5-8-1": "Whitelisted sellers",
        "5-8-2":
          "Here you will see all the sellers that you flagged as whitelisted in your searches.",
        "5-8-3a": "You can access on the menu on the left, in ",
        "5-8-3b": "Sellers > Whitelisted sellers.",
        "5-5-image": "19_Eng_captura_Suger.png",
        "5-8-4":
          "The table shows the country where the seller operates, his Mercado Libre user name and the number of products in his sales list.",
        "5-9-1": "How to check all the articles from blacklisted sellers",
        "5-9-2a": "First, click on ",
        "5-9-2b": "'Create a new livesearch' ",
        "5-9-2c":
          "in the table. This search will be saved on the 'Searches' section.",
        "6-1-1":
          "Here you can track the status of your reports and reply the sellers to decide whether you want to confirm or cancel your report.",
        "6-1-image": "20_Eng_captura_Suger.png",
        "6-2-1":
          "It shows a table with all open cases. Click on any report to view the details.",
        "6-2-2-title": "Counter-notice",
        "6-2-2-text":
          "It shows the date when the seller sent the counter-notice to your report, jointly with the comments and attached files.",
        "6-2-3-title": "Report",
        "6-2-3-text":
          "It shows the date when the listing was reported, the name of the auditor and the unique code of the report.",
        "6-2-4-title": "Listing",
        "6-2-4-text":
          "It is a screenshot of the listing just as seen on the day when the report was made.",
        "6-2-5-title": "Seller information",
        "6-2-5-text":
          "Here you will see the information of the seller who listed the product that you reported, such as his reputation on Mercado Libre, number of reports made, counter-notices sent and accepted.",
        "6-2-6":
          "If you think the counter-notice is sufficient and wish to take your report down, select the thumbs-up icon, but if you think that the counter-notice is not sufficient, select the thumbs-down icon.",
        "6-3-1": "Reply to the seller",
        "6-3-2a": "In this part, you will have the chance to ",
        "6-3-2b": "'Confirm or refuse the report' ",
        "6-3-2c": "and leave a comment to send to the seller.",
        "6-4-1":
          "If you decide to refuse the report, you must select the reason.",
        "6-2-image": "21_Eng_captura_Suger.png",
        "7-2-1":
          "If you need to add new rights to make more and better reports, please ",
        "7-2-1b": "click here.",
        "7-2-2-title": "On the screen to enroll rights",
        "7-2-2-text":
          "Select the checkbox off the right you want to enroll. If you need to enroll more than one, you can select multiple options.",
        "7-2-3":
          "For each type of right that you select, a white box will appear at the bottom of the screen. For each case, please attach the required documentation.",
        "7-2-4": "When finished, click on the ",
        "7-2-5": "Send documentation.",
        "7-2-6":
          "Done! Our representatives will analyze your documentation and notify you by email about your request status.",
        "8-1-1": "Catalog listings",
        "8-2-1": "What is a Catalog listing?",
        "8-2-2":
          "This is a 'product page' that groups, under a single content, several listings offered by different sellers.",
        "8-2-3":
          "Sellers' listings compete to be the first option that buyers see. Whoever offers the best selling conditions and experience will be the 'Winner'. Competition is based, among other things, on price, the seller's reputation and the selling conditions offered.",
        "8-3-1": "How to explore participating sellers",
        "8-3-2":
          "Note that the 'product pages' have variations. For example, for a cell phone, different features and colors available are shown. For each combination of variations, there may be a different winner.",
        "8-3-3":
          "When you see a Catalog listing in the reporting tool, you can go to the listing detail to know the participating sellers for each variation.",
        "8-3-image": "22_Eng_captura_Suger.png",
        "8-4-1": "Which data the seller defines",
        "8-4-2":
          "The seller configures the specific conditions of the listing participating in the 'Catalog'. This includes price, stock, warranty and shipping method.",
        "8-5-image": "23_Eng_captura_Suger.png",
        "8-5-1": "Who answers the buyer's questions",
        "8-5-2":
          "In a Catalog listing, the answers to buyers' questions are given by the winning seller of the moment.",
        "8-5-3":
          "In the 'Participating sellers' column you can see each seller's answer by simply selecting it.",
        "8-6-1": "Learn more about Catalog listings",
        "8-7-1": "How to report a Catalog listing",
        "8-7-1-a": "To report the listing photos,",
        "8-7-1-b":
          "select the 'Mark as suspicious' option in the section that displays the product details",
        "8-7-image": "24_Eng_captura_Suger.png",
        "8-7-2-a": "To report a seller's listing,",
        "8-7-2-b":
          "explore the 'Participating sellers' column and click on the 'Mark as suspicious' button.",
        "8-7-2-image": "25_Eng_captura_Suger.png",
        "8-8-1": "The listings you mark as suspicious will appear in the ",
        "8-8-1-a": "'Report suspicious listings'",
        "8-8-1-b": "section and you may manage them as usual.",
      },
      "question-livesearch": "What is a Livesearch?",
      "answer-livesearch":
        "Livesearches are searches of listings from one or more keywords. You can do your searches by country or for several countries at the same time, as long as you are authorized to report in more than one country. It is important that the words you use are related to the brand you audit in order for you to obtain more accurate results.",
      steps: {
        "1-1": "On the left menu, select",
        "1-2": "Search",
        "2-1": "Enter the keywords and click on",
        "2-2": "Search",
        "3-1": "Indicate the countries you wish and click on",
        "3-2": "Perform Search",
        "4-1":
          "When you start the livesearch process, you will see the number of listings that exist for the keywords you entered. If you click on the keyword(s) listed in the livesearch, you can start reviewing the listings. You can start auditing even if the search is still in process.",
      },
      "question-grid": "Results Grid",
      "answer-grid":
        "In the result gridview you will find all the listings that correspond to the keywords you used in your livesearch.",
      "question-filter-liversearch": "Livesearch Filters",
      "answer-filter-liversearch1":
        "Once you get the results, you can adjust the search and make it much more accurate using filters. You may use them one by one or combine them as you prefer.",
      "answer-filter-liversearch2":
        "You can concentrate your search on one product line of your brand or filter it by the listing date, price, stock, etc. In the same way, you can exclude words from the results.",
      "question-publication": "Listing Detail View",
      "answer-publication":
        "To access the detailed view, you must click on the listing in the results grid. In the detail view you will get more information about the product being offered, its photos, seller information, current stock and sales analysis.",
      "question-publication-valid": "Thumb up",
      "answer-publication-valid":
        "If you see that a listing is not committing any infringement to your brand, You may indicate that the listing is valid with the thumb up. This way, in future searches, that listing will not appear again in the results.",
      "question-publication-invalid": "Thumb down",
      "answer-publication-invalid1":
        "If you see that a listing is committing one or more intellectual property infractions, you must mark it with your thumb down. Then a box will open in which you can add more details regarding the infraction(s) you detected.",
      "answer-publication-invalid2": "Tags",
      "answer-publication-invalid2-1":
        "The tags are reference labels you can use later to issue reports. These labels or descriptions are for internal use only, and do not appear in the formal notice.",
      "answer-publication-invalid3": "Claimed infringement",
      "answer-publication-invalid3-1":
        "It is the code associated with the reason for your complaint. This is related to the rights that you adhered to the Brand Protection Program to report in Mercado Libre.",
      "answer-publication-invalid4": "Details",
      "answer-publication-invalid4-1":
        "You can add details of the reason for the complaint you are about to make. This will be helpful in case a team member needs more information before excluding a listing.",
      "answer-publication-invalid5": "Reporting",
      "answer-publication-invalid5-1":
        "Once you click on Report, the complaint will not be processed and sent to the Brand Protection Program review team, but will rather be sent to Products to report, with the other complaints you have made. Therefore, if you identify that a complaint was poorly made, you will be able to exclude it.",
      "answer-publication-invalid6": "Products to report",
      "answer-publication-invalid6-1":
        "This is where all the listings you marked with the thumb down will be, and where you will report them all. Therefore, it is advisable to review all the details you entered before in order to be certain of the infractions. If you want, you can also visit the listing in Mercado Libre by clicking on the button.",
      "answer-publication-invalid6-2":
        "By clicking on (+), you will see all the report details.",
      "answer-publication-invalid6-3":
        "If you find that a listing that is not in violation, you may select Remove from the list and the complaint will not be made.",
      "answer-publication-invalid6-4":
        "Once you are certain you want to send all the complaints, you can send them individually or massively by selecting Send all reports.",
      "question-whitelistsellers": "Whitelist Sellers",
      "answer-whitelistsellers1":
        "You can identify sellers as reliable (eg. Official Stores, distributors, etc.) so that their listings no longer show up in your livesearch and you do not waste time auditing and analysing them.",
      "answer-whitelistsellers2":
        "To identify the seller, you must open the detail view and click on ",
      "answer-whitelistsellers3": " Whitelist Seller.",
      "question-blacklistsellers": "Blacklist Sellers",
      "answer-blacklistsellers1":
        "If you believe it is necessary to track some sellers and all their listings, you must select Observe seller.",
      "answer-blacklistsellers2":
        "All listings from this seller will be marked with a black flag. You can also filter the results to only view listings from blacklist sellers.",
      "answer-blacklistsellers3-1":
        "You can view the activity of listings and complaints of these sellers in the menu on the left, in the section ",
      "answer-blacklistsellers3-2": "Sellers > Blacklist sellers",
      "question-livesearch-sellers": "Livesearches of Sellers",
      "answer-livesearch-sellers-1":
        "From the detailed view, in the Blacklist Sellers or Whitelist Sellers section, you can create a Livesearch to observe all listings from these sellers.",
      "answer-livesearch-sellers-2":
        "When you identify at least one listing that infringes your brand's rights, it is advisable to analyze all listings from the seller in order to identify if there are more that could affect your brand.",
      "question-post-denunciation": "Post-report Follow-up",
      "answer-post-denunciation1-1":
        "You can generate reports of your activity and export it as an Excel file. You can track all the audit work.",
      "answer-post-denunciation1-2":
        "You will be able to view all the reported listings that were excluded. You can also view a reference photo of the listings when making a complaint.",
      "question-counter-notice": "Counter Notice",
      "answer-counter-notice1":
        "If a seller makes a counter notice to a complaint, you will receive it in the email you entered as your contact email when registering your Brand Protection Program rights. This email corresponds to the one associated to the Brand Protection Program reporting accounts in each country.",
      "answer-counter-notice2":
        "Remember that this email is different from the one you used to register as an auditor to report on this platform.",
      disclaimer:
        "*The images are shown as examples to explain the use of the tool.",
      "question-support": "SUPPORT",
      "see-more": "Questions? We can help.",
      "goto-support": "Go to Support",
    },
    black_sellers: {
      ID: "ID",
      title: "Latest Sellers Listings",
      placeholder_sites: "Select Seller",
      table_th_site: "SITE",
      table_th_country: "COUNTRY",
      table_th_sellers: "SELLER",
      table_th_reports: "REPORTS RECEIVED",
      table_th_actions: "ACTIONS",
      table_th_current_listings: "ACTIVE LISTINGS",
      empty_sellers_message: "There are no Blacklisted Sellers availables.",
      button_create_current_listings: "Create Livesearch with current listings",
      button_delete_current_listings: "Dismiss",
      confirm_delete_client_black_seller:
        "Are you sure that you want to remove this seller from the blacklist?",
      black_list_seller_deleted: "Trustable seller deleted",
      black_list_seller_delete_error:
        "There was en error while we were trying to delete this trustable seller, please try again later",
      fetch_black_list_seller_error:
        "Sorry, there was an error in the search for sellers",
    },
    case_management: {
      cases: {
        pending_title: "Counter-notice to submit",
        pending_table_explanation:
          "The following sellers have responded your complaint with a counter-notice",
        search: "Search",
        table_th_auditor: "Auditor",
        table_th_country: "Country",
        table_th_id: "ID",
        table_th_article: "Listing",
        table_th_sellers: "Seller",
        table_th_status: "Status",
        table_th_denounce: "Flagged on",
        table_th_denounce_2: "Flagged on:",
        table_th_denounce_sent: "Report Date",
        table_th_counter_notice: "Counter notice",
        table_th_response_limit: "Deadline",
        empty_cases_message: "No results found",
        fetch_pending_cases_list_error:
          "We are sorry, there was an error while processing your search.",
        denounced_date: "Reported: ",
        updated_date: "Updated: ",
        canceled_date: "Canceled: ",
        dates: "View dates",
      },
      case_description: {
        denounced_seller: "Reported seller",
        seller_response: "This is what they wrote",
        seller_empty_response: "The seller did not leave comments",
        photos_current: "These are the current images of the listing",
        photos_modified_new: "NEW",
        photos_denounced: "These are the images you reported",
        fetch_description_denounces_error:
          "Lo sentimos, hubo un error al buscar los datos.",
        no_data_found: "No data found",
        "counter-notice_tab": "Seller counter-notice",
        "counter-notice": {
          counter_notice_attached_file: "Attached file",
        },
        denounce_tab: "Report",
        denounce: {
          denounce_date: "REPORT DATE",
          auditor: "AUDITOR",
          code: "CLAIMED INFRINGEMENT",
          additional_description: "AUDITOR NOTES",
        },
        publication_tab: "Listing",
        publication: {
          site: "Mercado Libre",
          price: "PRICE",
          product_sold_in_this_publication: "SOLD ITEMS",
          description: "DESCRIPTION",
          images: "photos",
          image: "photo",
        },
        "seller-data_tab": "Seller Information",
        seller_data: {
          create_seller_LS: "Create LiveSearch for this Seller",
          black_seller: "Blacklist Seller",
          portal_reputation: "Reputation",
          sold_quantity_products: "Items sold",
          denonces_sent_to_this_seller: "Reports",
          counter_notice_received: "Counter notices sent",
          counter_notice_accepted: "Counter notices accepted",
          denounces_table_article: "Listing",
          denounces_table_denounce: "Reported",
          denounces_table_status: "Status",
          denounces_counter_notice: "Seller Response",
          fetch_counter_notices_error:
            "There was an error getting the seller's complaints",
        },
      },
      documentation_approved: {
        sending_response: "We’re sending your response to the seller.",
        response_placeholder: "I reviewed the counter-notice and decided to:",
        reject_option_member_placeholder: "I’ll confirm it because:",
        approved_case_title: "Respond to the seller",
        approved_case_title_sent: "Response",
        confirm: "Confirm my report",
        retract: "Retract my report",
        confirm_helper_text: "Select this reply to finalize the listing.",
        retract_helper_text: "Select this reply to reactivate the listing.",
        send_response: "Send reply",
        reject_option_label: "Reason for your decision",
        description_label: "Additional comments",
        no_description: "Without description",
        report_placeholder:
          "Explain to the seller why you’ve made this decision.",
        respond_counter_notice_success_confirm: "The listing was deleted.",
        respond_counter_notice_success_retract: "The listing was reactivated.",
        respond_counter_notice_success:
          "Done! We have already sent your reply to the seller.",
        respond_counter_notice_error:
          "We are sorry, something went wrong. Try again or contact support.",
        closed_title: "You’ve already replied to this seller",
        responded: "This is what you decided",
        responded_at: "You replied on",
      },
      publication_status: {
        active: "Active",
        closed: "Finished",
        inactive: "Inactive",
        paused: "Paused",
        under_review: "Under review",
      },
      denounce_status: {
        waiting: "Awaiting response",
        pending: "Responded",
        accepted: "Reactivated listing",
        not_approved: "Ended listing",
        not_presented: "Response not submitted",
        not_respond: "Expired",
        rollback: "Retracted",
        discard_due_restriction: "Discarded",
        pending_moderation: "Pending",
        rejected: "Rejected",
        acuerdo_closed: "Ended listing",
        duplicated_denounce: "N/A",
        discarded: "Discarded",
        rejected_modified: "Edited listing",
        revision: "Report under review",
      },
      denounce_status_V3: {
        approved: "Approved",
        in_progress: "Ongoing",
        pending: "Pending response",
        rejected: "Rejected",
        rollback: "Withdrawn",
        discarded: "Removed",
      },
      pending_denounce_widget: {
        title: "Reports pending counter-notice.",
        subtitle: "Please review the seller counter-notice.",
      },
      status_pill: {
        expire_days: "Expires in {} days",
        expire_day: "Expires in {} day",
        expire_hours: "Expires in {} hours",
      },
    },
    welcome_page: {
      title: "Welcome!",
      subtitle:
        "You are one step away from using the new Mercado Libre reporting tool",
      no_auditor_boton: "I want to register",
      enter_now_to_denounce: "I am already registered",
    },
    welcome_register_page: {
      title: "Welcome to the Brand Protection Program reporting tool",
      subtitle:
        "If you’re already registered in the program, log in with any of your Brand Protection Program usernames. If you don’t have an account yet, register now.",
      subtitle2: "Enter the email registered in the Brand Protection Program",
      email: "Email",
      singin_button: "Login",
      continue_button: "Continue",
      no_account_button: "Register",
      know_more: "Learn more about the program",
    },
    error_no_bpp: {
      title: "We were unable to log in",
      subtitlePart1: "Please log in again and ",
      subtitlePart2: "make sure you use your BPP credentials.",
      btn: "Log in again",
    },
    reported_articles: {
      alerts: {
        cannot_submit_title: "You can’t submit reports",
        cannot_submit_subtitle:
          "We found abusive behaviour in your rejected counter-notices, so we’ve temporarily disabled your reporting capacity.",
      },
      filters: {
        placeholder_title: "Title",
        placeholder_site: "Select Site",
        placeholder_seller: "Select Seller",
        placeholder_term: "Search Listing",
        placeholder_complaint: "Select Status",
        placeholder_reference_id: "ID",
        placeholder_price_from: "Price from (USD)",
        placeholder_price_to: "Price to (USD)",
        placeholder_date_from: "FROM",
        placeholder_date_to: "TO",
        option_selecet_complaint_status_with_error: "Retry send",
        option_selecet_complaint_status_without_error: "Not send",
      },
      articles_reported: {
        title: "Report history",
        description:
          "Find all your reports here and manage those with pending counter-notice",
        search: "Search by ID, or seller name",
        filter: "Filter and sort",
        table_th_seller: "Seller",
        table_th_denounce: "Reported listing",
        table_th_reason: "Reason for the report",
        table_th_status: "Status",
        placeholder_sites: "SELECT SITE",
        table_th_id: "ID",
        table_th_site: "SITE",
        table_th_country: "COUNTRY",
        table_th_article: "LISTING",
        table_th_auditor: "AUDITOR",
        table_th_price: "PRICE",
        table_th_reported: "REPORTED",
        table_th_notice_status: "STATUS",
        table_th_notice_update: "LAST UPDATED",
        table_th_down: "TAKEDOWN",
        reported_at: "REPORT DATE",
        button_clear_filters: "Clear Filter",
        button_submit_filters: "Search",
        empty_reported_articles_message:
          "There are no reported articles availables.",
      },
      denounce_status_tooltip: {
        WAITING_DOCUMENTATION:
          "The seller hasn’t responded to your report yet.",
        DOCUMENTATION_PRESENTED:
          "The seller has already responded to your report.",
        DOCUMENTATION_APPROVED: "You've decided to reactivate this listing.",
        DOCUMENTATION_NOT_APPROVED: "You've decided to finalize this listing.",
        DOCUMENTATION_NOT_PRESENTED:
          "We finalized this listing because the seller didn't respond within the deadline.",
        MEMBER_NOT_RESPOND:
          "You didn't reply to the seller on time and your report has expired.",
        ROLLBACK:
          "You decided to reactivate the seller’s listing as the report was retracted.",
        DISCARDED_DUE_RESTRICTION:
          "The seller was banned from the site, therefore you don't need to review their counter-notice.",
        ACUERDO_CLOSED: "We have finalized this listing.",
        DUPLICATED_DENOUNCE: "N/A",
        REJECTED: "Your report was rejected.",
        DISCARDED: "The listing was excluded.",
        PENDING_MODERATION: "Your report is still pending.",
        CREATED: "Your report is still pending.",
        CROSS_DENOUNCE_CLOSED: "Your report was rejected.",
        REJECTED_MODIFIED:
          "Your report couldn’t be processed because the listing was modified after your complaint was made.",
      },
      denounce_status_tooltipV3: {
        ACUERDO_CLOSED: "The reported listing was removed.",
        DOCUMENTATION_NOT_APPROVED:
          "The seller documentation was not approved. The reported listing was removed.",
        DOCUMENTATION_NOT_PRESENTED:
          "Your report was approved because the seller did not present the documentation.",
        CREATED: "Your report is pending to be processed.",
        PENDING_MODERATION: "Your report is pending to be processed.",
        WAITING_DOCUMENTATION: "The seller has yet to submit counter-notice.",
        WAITING_FOR_PATCH_PDP:
          "We are analyzing your report. If necessary, we will make the corresponding adjustments.",
        DOCUMENTATION_PRESENTED:
          "The seller is waiting for your response. If you do not submit a counter-notice, the report will be cancelled",
        DUPLICATED_DENOUNCE:
          "Your report cannot be processed because it is duplicated.",
        MEMBER_NOT_RESPOND:
          "Your report was rejected because you did not respond to the seller in the established deadline.",
        REJECTED:
          "Your report was rejected because it does not comply with the mandatory requirements.",
        REJECTED_BY_RESTRICTION:
          "You reached the limit of daily reports. Please check your account efficiency.",
        DOCUMENTATION_APPROVED:
          "The seller's documentation was approved and your report was withdrawn.",
        ROLLBACK:
          "You withdrew the report. The reported listing will be reinstated.",
        DISCARDED:
          "Your report cannot be processed because the listing is no longer available.",
        DISCARDED_DUE_RESTRICTION:
          "Your report cannot be processed because the seller is shut down.",
        REJECTED_MODIFIED:
          "Your report was removed because the listing was edited.",
        REJECTED_PDP_CATALOG:
          "Your report was removed because the images you reported were obtained from a private image bank.",
      },
      index: {
        title: "Report suspicious listings",
        table_th_site: "SITE",
        table_th_country: "COUNTRY",
        table_th_article: "LISTING",
        table_th_auditor: "AUDITOR",
        table_th_price: "PRICE",
        table_th_antiquity: "FLAGGED ON",
        table_th_more: "MORE",
        table_of_text: "of",
        table_next_text: "Next",
        table_rows_text: "rows",
        table_page_text: "Page",
        table_previous_text: "Previous",
        table_loading_text: "Loading...",
        table_no_data_text: "No rows found",
        empty_reports_message: "No listings to report.",
        removed_clarification: "Removed",
        removed_clarification_tooltip:
          "You can't report this listing because it’s already been removed from the site.",
        button_remove: "Delete",
        removed: "Removed",
        removed_v2: "Listing inactive.",
        button_send_all_complaints: "SEND ALL NOTICES OF CLAIMED INFRINGEMENT",
        button_send_complaint: "Send notices",
        confirm_reported_article_undo: "Dismiss this listing?",
        confirm_reported_article_undo_subtitle:
          "If you do this, you won't see $title any longer in your list of suspicious listings.",
        confirm_reported_article_undo_btn_label: "Dismiss listing",
        confirm_send_all_complaints: "Confirm sending all complaints?",
        button_edit_complaint: "Edit",
        button_retry_complaint: "Retry notice",
        complaint_sent: "Report sent",
        send_complaint:
          "Thank you for send your complaint. We will proced to evaluate it within the next 72 hours",
        send_all_complaints:
          "Thank you for send your complaints. We will proced to evaluate them within the next 72 hours",
        error_send_all_complaints: "There was an error sending all complaints.",
        error_send_complaint: "There was an error sending the complaint.",
        error_send_complaint_already: "The listing was already noticed.",
        error_getting_article_data: "There was an error getting the data",
        failed_not_exits_or_not_published:
          "The article does not exist or it is not published.",
        days: {
          one: "%{count} day",
          other: "%{count} days",
        },
      },
      edit: {
        seller: "Seller",
        tags: "Tags",
        complaint_code: "Claimed infringement",
        placeholder_complaint_description:
          "Write down your comments about the listing you’re about to flag (optional).",
        label_add_tag: "add tag",
        cancel_complaint_edition: "Cancel",
        edit_complaint_edition: "Edit",
      },
      table: {
        th1: "Publication",
        th2: "Price",
        th3: "Seller",
        th4: "Reason",
      },
    },
    noticed_articles: {
      filter: "Filter",
      filters: {
        all: "All statuses",
        placeholder_id: "ID",
        placeholder_title: "Title",
        placeholder_price_from: "Price from",
        placeholder_price_to: "Price to",
        placeholder_date_from: "Date from",
        placeholder_date_to: "Date to",
        placeholder_country: "Country",
        placeholder_seller: "Seller",
        report_created: "Report generated",
        report_created_error: "There was an error trying to create the report",
        report_no_articles_found:
          "No articles were found to generate the report",
        title_required: "Title is required",
        date_error: "Date from, can not be greater than date to",
        price_error: "Price from, can not be greater than price to",
      },
      filter: "Filter",
    },
    client_white_sellers: {
      index: {
        ID: "ID",
        title: "Whitelisted Sellers",
        empty_sellers_message: "There are no Whitelisted Sellers availables.",
        table_th_site: "SITE",
        table_th_country: "COUNTRY",
        table_th_seller_name: "SELLER",
        table_th_seller_current_listing: "ACTIVE LISTINGS",
        table_th_actions: "ACTIONS",
        delete_client_white_seller: "Remove",
        confirm_delete_client_white_seller:
          "Are you sure that you want to remove this seller from the Whitelist?",
        white_list_seller_deleted: "Trustable seller deleted",
        white_list_seller_delete_error:
          "Opps... there was en error while we were trying to delete this trustable seller, please try again later",
        fetch_white_list_seller_error:
          "Sorry, there was an error in the search for sellers",
      },
    },
    white_and_black_sellers: {
      filters: {
        ID: "ID",
        country: "Country",
      },
    },
    notice_reports: {
      index: {
        title: "Reports",
        table_th_date: "Date",
        table_th_name: "Report name",
        table_th_download: "Download",
        fetch_reports_error:
          "Opps... There was an error while we were fetching the reports, please try again later",
        click_here_to_download: "Click here to download",
        confirm_delete_report:
          "Are you sure you want to delete this report? this action can not be undone",
        delete_report_error:
          "There was an error while we were trying to delete the report",
        processing_report: "Processing report",
        generate_error: "There was an error in generating this report",
        emply_error: "Sorry, there are no reports with the selected filters",
      },
      create: {
        title: "New custom report",
        create_report: "Create Report",
        placeholder_title: "Title",
        placeholder_date_from: "Date from",
        placeholder_date_to: "Date to",
        placeholder_currency: "currency",
        placeholder_origin: "Audited by",
        placeholder_language: "language",
      },
    },
    livesearch_reports: {
      index: {
        title: "Reports",
        table_th_date: "Date",
        table_th_name: "Report name",
        table_th_download: "Download",
        fetch_reports_error:
          "Opps... There was an error while we were fetching the reports, please try again later",
        click_here_to_download: "Click here to download",
        confirm_delete_report:
          "Are you sure you want to delete this report? this action can not be undone",
        delete_report_error:
          "There was an error while we were trying to delete the report",
        processing_report: "Processing report",
        generate_error: "There was an error in generating this report",
      },
    },
    sites_authorization: {
      from_account: {
        do_it_later: "Link Later",
        finish_and_do_it_later: "Finish and link later ",
      },
      from_search: {
        back_to_search: "Back to search",
      },
      default: {
        start_reporting: "Start reporting",
      },
    },
    redirect_flow_info: {
      title: "Welcome to our brand-new reporting tool",
      subtitle:
        "Reporting infractions and replying to counter-notices is much faster. Take a look at everything you can do:",
      bullet1: "Identify suspicious sellers even quicker",
      bullet2: "Create your own search filters",
      bullet3: "Follow the status of your reports, all in one place",
    },
    tags_title: "Tags",
    label_add_tag: "Add tag",
    total: "Total",
    undo: "Undo",
    unknown_seller: "Unknown Seller",
    unlist_seller_from_black_list_confirmation:
      "Are you sure that you want to remove this seller from the Blacklist?",
    unlist_seller_from_white_list_confirmation:
      "Are you sure that you want to remove this seller from the Whitelist?",
    used_product: "Used product",
    validity: "Validity",
    view_in_site: "View in site",
    initial_question: "Q",
    initial_answer: "A",
    seller_information: "Seller Information",
    description_product: "Descripion",
    question_answer: "Question and Answer",
    welcome: "Welcome",
    go_back: "Go back",
    edit_reported_article_success: "The report to the publication was updated.",
    edit_reported_article_error:
      "We're sorry, we had an error updating the report.",
    done_article_reported:
      "Done! In order to manage and send your reports, check out the",
    done_articles_reported:
      "Done! In order to manage and send your reports, check out the",
    go_to_reported_articles: "Suspicious listings.",
    we_will_send_this_listing_to: "We’ll send this listing to",
    we_will_send_these_listing_to: "We’ll send these listings to",
    got_it: "GOT IT",
    ls_reported_articles_link: "Go to Suspicious listings",
    process_and_clean_results: "Process search",
    live_searches: "Livesearches",
    unable_to_create_live_search:
      "Unable to create Livesearch. Please, try again.",
    server_error: "We're sorry, we've had a server error try again",
    fetch_currency_error:
      "We are sorry, we have had an error in the response of the currency exchange",
    remove_article_error: "We're sorry, we had an error removing the article",
    remove_article_success: "Listing dismissed.",
    fetch_seller_error: "We're sorry, we had an error in the seller's search",
    dashboard: "Dashboard",
    sellers_inactive: "Inactive Sellers",
    tags: "Tags",
    search: "Search",
    enter_your_email_below_to_reset_your_password:
      "Enter your e-mail address below to reset your password",
    error_generating_custom_report: "Failed to generate custom report.",
    without_results: "Without results!",
    refresh: "Refresh",
    try_again: "Try again",
    seller_live_search_created: "Livesearch created",
    create_seller_live_search: "Create Livesearch with current listings",
    error_reporting_seller: "Error reporting seller. Please, try again!",
    error_user_without_report_profile: "The Report Profile does not exist.",
    success_send_all_complaints: "All reports were sent.",
    updated_reported_article_to_ok: "Update reported article status to OK.",
    reported_article_not_found: "Reported article not found.",
    reported_article_already_reported: "Too late, article already reported.",
    update_reported_article_complaint_success: "The report was updated.",
    update_reported_article_complaint_error:
      "We're Sorry but we couldn't update the report. Please try again.",
    complaint_reason: "Claimed infringement",
    complaint_error: "Complaint Error",
    complaint_description: "Report Additional Description",
    invalid_keyword_for_new_live_search:
      "You must enter a keyword to generate a new LiveSearch",
    modal_title: "Loading...",
    error_getting_snapshot: "Could not get snapshot for reported article.",
    response_errors: {
      client_error: "Client error",
      server_error: "Server error",
    },
    enroll_rights_cta_tooltip_header:
      "Keep in mind that you’ll need to log in again with your username in order to enroll new rights.",
    enroll_rights_tooltip_content:
      "Can't find the infringement reason on the list? You may need to enroll new rights.",
    enroll_rights_now: "Enroll now",
    report: {
      report_code: {
        PPPI1:
          "The product offered is forged and infringes my registered trademark (eg my registered trademark applies to non-original product.).",
        PPPI2: "The listing uses my brand illegitimately.",
        PPPI3: "The software offered is pirated / illegal.",
        PPPI4:
          "The offered product reproduces my copyright without authorization (eg reproduction of plastic works, phonograms, audiovisuals and books.).",
        PPPI5:
          "The listing includes, without authorization, original texts on which I have rights.",
        PPPI6:
          "The listing includes, without authorization, original images (eg photographs, designs, drawings, paintings, videos.) about which I have rights.",
        PPPI7:
          "The listing includes my personal image without authorization (eg, portrait, advertising model).",
        PPPI8: "The product offered violates my model or industrial design.",
        PPPI9: "The product offered infringes my patent or utility model.",
        PPPI10: "Product not destined for sale",
        ACUERDO: "Tengo un convenio de regulación sobre este producto.",
      },
    },
    report_v2: {
      title: "Mark as suspicious",
      title_plural: "Mark as suspicious",
      subtitle: "Select the type of infringement",
      title_product: "In the product offered",
      title_catalog: "Report an infringement of my rights",
      subtitle_catalog:
        "Applies to the unlawful use of brands, including counterfeit products or original products that use a brand without its authorization",
      title_publication: "In the listing content",
      title_actors: "Actors, singers, musicians and dancers",
      title_producers: "Producers of sound and video recordings",
      title_broadcastingorganizations: "Broadcasting organizations",
      report_code: {
        PPPI1: "It is a counterfeit product",
        PPPI2: "Illegal use of my trademark",
        PPPI3: "Software",
        PPPI4: "Copyright infringement",
        PPPI5: "Books",
        PPPI6: "Images / photos",
        PPPI7: "Unauthorized use of photographic portrait",
        PPPI8: "Infringes industrial designs or models",
        PPPI9: "Infringes patents, utility models or plant breeder's rights",
        PPPI10: "It is a product not meant for sale",
        PPPI11: "Courses",
        PPPI12: "Video games",
        PPPI13: "Videos",
        PPPI14: "Videos / Movies",
        PPPI15: "Music",
        PPPI16: "Characters",
        PPPI17: "Others",
        PPPI18: "Illegal reproductions",
        PPPI19: "Personal image",
        PPPI20: "Unauthorized use of audio material",
        PPPI21: "Unauthorized use of audiovisual material",
        PPPI22: "Illegal signals",
        PPPI23: "Illegal device",
        ACUERDO: "I have a regulatory agreement on this product.",
        PPPI1_subtext:
          "It is a product that has my trademark, but I have not manufactured it.",
        PPPI2_subtext:
          "Includes my logo in the images with no authorization. Identifies the product with a name or logo that can be confused with my trademark.",
        PPPI3_subtext:
          "The listing offers a computer program that violates my copyrights.",
        PPPI4_subtext:
          "For example, pdf books, compiled music, copies of movies, products that include images of protected works.",
        PPPI5_subtext:
          "The listing offers a literary work that violates my copyrights.",
        PPPI6_subtext:
          "The listing has images or photos that infringe my copyrights.",
        PPPI7_subtext:
          "For example, a photo that shows a person without their authorization.",
        PPPI8_subtext:
          "Describe here the possible infringements to your registered industrial design or models, including designs, forms and monograms.",
        PPPI9_subtext: "",
        PPPI10_subtext:
          "E.g.: free samples or products that were given under commodatum arrangement. Products not officially launched In Brazil, could also be reported.",
        PPPI11_subtext:
          "The listing offers a course that violates my copyrights.",
        PPPI12_subtext:
          "The listing offers a video game that violates my copyrights.",
        PPPI13_subtext: "",
        PPPI14_subtext:
          "The listing offers an audiovisual work that violates my copyrights.",
        PPPI15_subtext:
          "The listing offers musical content that violates my copyrights.",
        PPPI16_subtext:
          "The listing offers products that include my unauthorized characters.",
        PPPI17_subtext:
          "The listing offers other types of work (cartoon, painting, sculpture etc.) that violates my copyrights.",
        PPPI18_subtext: "Unauthorized inclusions or reproductions.",
        PPPI19_subtext:
          "Unauthorized use of my rights of publicity associated with my artistic interpretation.",
        PPPI20_subtext: "Music or recorded sounds.",
        PPPI21_subtext:
          "Movies, series, videos and recitals, concerts and sport events recordings.",
        PPPI22_subtext: "Services to access signals illegally.",
        PPPI23_subtext: "Devices that illegally capture access signals.",
        ACUERDO_subtext: "",
      },
      catalog_code: {
        PPPI1: "It is a counterfeit product",
        PPPI2: "Unlawful use of my brand in the listing.",
        PPPI10: "It is a product that is not destined to be sold",
        PPPI1_subtext:
          "It is a product that carries my brand but was not manufactured by me.",
        PPPI2_subtext:
          "It identifies the product with a name or logo that can be mistaken for my brand, my brand is mentioned when the product does not belong to it, etc.",
        PPPI10_subtext:
          "E.g.: free samples or products that were delivered on loan. Products that were not officially launched in Brazil can also be reported.",
      },
      step_2: {
        header: "Anything else to add?",
        title: "Add comment",
        subtitle: "(Optional)",
        placeholder:
          "Please add your notes and comments. The reported seller will not be able to see them.",
      },
      message_advice: {
        title: "IMPORTANT:",
        message:
          " Please remember that your report must be based on intellectual property infrigements that adhered to the program.",
        message_strong:
          "The inadequate use of this tool can lead to santions in your account.",
        link: "Learn what you can report in the BPP",
      },
      tooltip_PPPI10: {
        title: "NEW!",
        description:
          "Use this reason to report infractions of registered trademark over the product.",
      },
      step_1: {
        copyright: "Infringes copyrights",
        brand: "Infringes trademark rights",
        personal_image: "Uses my personal image without authorization",
        utility_model: "Infringes industrial designs or models",
        patent: "Infringes patents, utility models or plant breeder's rights",
        agreements: "I have a regulatory agreement on this product.",
        related_rights: "Infringes related rights",
      },
      "select-options": {
        subtitle: "Where is the infringement?",
        placeholder: "Select an option",
        "opt-title": "Title",
        "opt-description": "Description",
        "opt-sheet": " Product specification sheet",
        "opt-publish": "Listing",
        subtitle2: "Tell us more about the infringement",
        placeholder2: "Include the details of the infringement here.",
        button: "Send Report",
      },
      buybox: {
        title: "Done! Thanks for reporting",
        description:
          "We processed your report. It will be reviewed and we will reply to you by email.",
        "case-number": "Case number: ",
      },
      product_subtext_1: "Selecciona esta opción si el producto",
      product_subtext_2: "usa tu marca sin autorización",
      publication_subtext_1: "Selecciona esta opción si la publicación",
      publication_subtext_2: "menciona tu marca",
      publication_subtext_3:
        "en el titulo, en la ficha técnica o en la descripción.",
      related_rights_sub: "Review where the infringement is.",
      copyright_sub: "",
      brand_sub: "",
      personal_image_sub: "",
      utility_model_sub:
        "Describe here the possible violations to your registered industrial design or models, including designs, forms and monograms.",
      patent_sub: "",
      agreements_sub: "",
      copyright_title: "Select the type of work or creation",
      copyright_selected: " in ${reason}",
      utility_patent_selected: " of ${reason}",
      image_hub_title: "Select which image(s) would be infringing your rights",
      catalog_title: "In the offered product",
      catalog_PPPI10_title: "In the listing’s content",
      "tooltip-isDenounced": "This product was already reported",
      errorDenounce: "Something went wrong. Please try again.",
    },
    datatables: {
      length_menu: "Display _MENU_ records per page",
      zero_records: "Nothing found",
      info: "Showing _START_ to _END_ of _MAX_",
      info_empty: "No records available",
      info_filtered: "(filtered from _MAX_ total records)",
      processing: "Load results",
      first: "First",
      last: "Last",
      next: "Next",
      previous: "Previous",
    },
    activerecord: {
      errors: {
        messages: {
          record_invalid: "invalid object -> %{errors}",
          wrong_size: "wrong size (must be %{file_size})",
          size_too_small: "too small (should be at least %{file_size})",
          size_too_big: "too large (must be at least %{file_size})",
          inclusion: "It is not included in the list",
          exclusion: "is reserved",
          invalid: "invalid",
          confirmation: "does not match the confirmation",
          blank: "required",
          empty: "can not be empty",
          not_a_number: "is not a number",
          not_an_integer: "must be an integer",
          less_than: "must be less than %{count}",
          less_than_or_equal_to: "should be less or equal to %{count}",
          greater_than: "must be greater than %{count}",
          greater_than_or_equal_to: "must be greater than or equal to %{count}",
          too_short: {
            one: "is too short (1 character minimum)",
            other: "is too short ( %{count} characters minimum)",
          },
          too_long: {
            one: "is to large (maximum 1 character)",
            other: "is to large (maximum %{count} characters)",
          },
          equal_to: "must be equal to %{count}",
          wrong_length: {
            one: "wrong length (must be 1 character)",
            other: "wrong length (must be %{count} character)",
          },
          accepted: "must be accepted",
          even: "must be an even number",
          odd: "must be an odd number",
          taken: "already registered",
          required: "required",
        },
      },
      models: {
        user: "User",
      },
      attributes: {
        user: {
          email: "Email",
          first_name: "First Name",
          last_name: "Last Name",
          password: "Password",
          password_confirmation: "Password Confirm",
          current_password: "Current Password",
          currency: "Currency",
          locale: "Language",
          update_password: "Update Password",
          field_required: "This field is required",
          error_rewrite_password: "Does not match the password field",
          alert_update_user: "User updated",
          alert_not_update_user: "No changes were made",
        },
      },
    },
    views: {
      pagination: {
        first: "&laquo; First",
        last: "Last &raquo;",
        previous: "&lsaquo; Prev",
        next: "Next &rsaquo;",
        truncate: "&hellip;",
      },
    },
    shared: {
      side_navigation: {
        suggested_listings: "Suggested listings",
        searches: "Search",
        reports: "Reports",
        custom_reports: "Custom reports",
        last_reported_listings: "Report history",
        support: "Support",
        support_help: "Help",
        management_case: "Case management",
        cases_pending: "Open cases",
        cases_closed: "Closed cases",
        sellers: "Sellers",
        sellers_black: "Blacklisted Sellers",
        sellers_white: "Whitelisted Sellers",
        livesearch_reports: "Livesearch Reports",
        articles_to_report: "Report suspicious listings",
        articles_to_report_tooltip_title: "You have reports to send",
        articles_to_report_tooltip_text:
          "Review your suspicious listings and send your reports from here.",
        articles_to_report_tooltip_not_show: "Do not show again",
        support_link: "Support",
        video_instructions: "Instructional videos",
        new_pill: "NEW",
        my_account: "My Account",
      },
    },
    devise: {
      registrations: {
        edit: {
          title: "Auditor profile",
          cancel_button: "Cancel",
          update_button: "Update",
          help_label_password: "(leave blank if you don't want to change it)",
          help_label_current_password:
            "(we need your current password to confirm your changes)",
        },
      },
      passwords: {
        edit: {
          title: "Change your password",
          change_passwd_button: "Change my password",
          placeholder_password: "New password",
          placeholder_confirm_password: "Confirm new password",
        },
      },
      mailer: {
        reset_password_instructions: {
          hello: "Hello",
          date: "Date",
          requested_link_text:
            "Someone has requested a link to change your password. You can do this through the link below.",
          link_change_my_password: "Change my password",
          ignore_email_text:
            "If you didn't request this, please ignore this email.",
          your_passwd_wont_change_text:
            "Your password won't change until you access the link above and create a new one.",
        },
      },
    },
    terms_and_conditions: "Terms & Conditions",
    privacy_policy: "Privacy Policy",
    reviews: {
      button: "Reviews",
      title_seller_selected: "Product reviews from",
      title_default: "Product reviews",
      subtitle_seller_default:
        "Here you will find the reviews left by those who have bought from each seller.",
      subtitle_seller_selected:
        "Check the reviews left by those who have bought from this seller.",
      subtitle_seller_no_reviews:
        "The seller you selected have not received reviews on their orders yet.",
      all_reviews: "All",
      positives: "Positive",
      negatives: "Negative",
      view_more: "See More",
      view_less: "See less",
    },
  },
  pt: {
    onboarding_slide_notices: {
      step_1: {
        title: "Confira as novas funções!",
        description:
          "Agora ficou mais fácil traduzir a seção, ver a documentação anexada e os detalhes do anúncio denunciado.",
      },
      step_2: {
        title: "Tradução na hora",
        description: "Basta um clique para traduzir todo conteúdo da seção.",
      },
      step_3: {
        title: "Documentação disponível",
        description:
          "Pré-visualize as imagens que os vendedores anexam sem precisar baixá-las.",
      },
      step_4: {
        title: "Detalhes do anúncio",
        description:
          "Revise o anúncio que denunciou no novo módulo no final da seção.",
      },
      next: "Seguinte",
      ok: "Entendi",
    },
    catalogue: "catálogo",
    catalogue_id: "ID de catálogo",
    official_store: "loja oficial",
    article_catalogue_detail: {
      title_images: "Fotos do catálogo",
      report_images: "Marcar fotos como suspeitas",
      report_right: "Reportar infração aos meus direitos",
      article_denounced: "Esta publicación infrige mis derechos",
      sellers_title: "Vendedores participantes",
      sellers_subtitle:
        "Para marcar um anúncio como suspeito, clique no cartão do vendedor correspondente.",
      seller_generic_response_title: "Respostas deste vendedor",
      seller_generic_response_subtitle:
        "Aqui, você verá as respostas do vendedor selecionado na lista de participantes.",
      seller_response_title: "Respostas de {seller_name}",
      seller_response_subtitle:
        "Confira as respostas do vendedor que você selecionou para ver se há outros motivos de suspeita.",
      seller_response_title_empty: "Respostas deste vendedor",
      seller_response_subtitle_empty:
        "O vendedor que você selecionou ainda não respondeu nenhuma pergunta.",
      seller_history: "Ver histórico do vendedor",
      seller_sales: "Vendas",
      country_search: "Comece a pesquisa em",
      newness_box: {
        title: "Este é um anúncio de catálogo",
        subtitle:
          "Esta é uma página que agrupa, sob um único conteúdo, diferentes anúncios de um mesmo produto oferecidos por vendedores diferentes.",
        "item1-a": "Para denunciar as fotos do anúncio,",
        "item1-b":
          "selecione a opção 'Marcar como suspeito' na seção que descreve os detalhes do produto.",
        "item2-a": "Para reportar um anúncio de um vendedor específico,",
        "item2-b":
          "verifique a coluna 'Vendedores participantes' e clique no botão 'Marcar como suspeito'.",
        button: "Saiba mais",
        close: "Perto",
      },
      sort_sellers: {
        item1: "A-Z",
        item2: "Preço do menor para o maior",
        item3: "Preço do maior para o menor",
        item4: "Lojas oficiais primeiro",
      },
      seller_responses_search: "Procure nas respostas...",
    },
    messageBox: {
      title: "Olá!",
      subtitle:
        "Gostaríamos de saber como foi sua experiência com a ferramenta de denúncias. ",
      subtitleStrong: " Você teria 3 minutinhos para nos contar?",
      button1: "Sim, claro",
      button2: "Já respondi",
    },
    efficiency: {
      title: "Eficiência da conta",
      default:
        "Não há dados suficientes para avaliar a eficiência da sua conta. \n",
      high: "Parabéns, A eficiência da sua conta é muito alta. \n",
      med: "Tome cuidado, sua conta pode ser restringida. \n",
      risk: "Sua conta foi restringida. Confira os motivos da restrição. \n",
      subtitle: "Conferir eficiência. ",
      subtitle2: "Saiba mais. ",
    },
    blindage: {
      tooltip_title: "Você já denunciou esse anúncio antes",
      tooltip_content:
        "Notamos que você já havia denunciado esse anúncio com base na mesma infração, e optou por retirar a denúncia. Para denunciá-lo novamente, você deve indicar se encontrou uma infração diferente. Caso contrário, você poderá fazer uma nova denúncia a partir de ",
      tooltip_link: "Quero entrar em contato",
      modal_excel: {
        title: "Importar lista de publicaciones sospechosas",
        subtitle_errors:
          "Ten en cuenta que solo podrás importar archivos que hayas descargado desde esta sección.  ",
        subtitle_success: `Você poderá verificar os elementos importados na seção de "Anúncios suspeitos".`,
        notBlindages_found: "anúncios para denunciar",
        notBlindage_found: "anúncio para denunciar",
        blindages_found: "anúncios que não puderam ser processados",
        blindage_found: "anúncio que não puderam ser processado",
        blindages_found2: "Seus anúncios não puderam ser processados",
        blindage_found2: "Seu anúncio não poderia ser processado",
        blindage_content:
          "Notamos que você já havia denunciado esses anúncios com base na mesma infração, e optou por retirar a denúncia.",
      },
      modal_multiple: {
        title: "Anúncios suspeitos seleccionados",
        subtitle_success: `Você poderá verificar os elementos selecionados com sucessona seção de "Anúncios suspeitos".`,
        subtitle_errors: `Alguns anúncios que você selecionou têm erros. Você poderá verificar os elementos selecionados com sucessona seção de "Anúncios suspeitos".`,
        elements: "elementos seleccionados",
        element: "elemento seleccionado",
        notBlindages_found: "anúncios seleccionados com sucesso",
        notBlindage_found: "anúncio seleccionado con éxito",
        blindages_found: "anúncios que não puderam ser processados",
        blindage_found: "anúncio que não puderam ser processado",
      },
    },
    noData: {
      title: "Não há nada aqui...",
      subtitle: "Quando você denunciar anúncios, poderá encontrá-los aqui.",
    },
    "description-text": "Descrição",
    "attributes-text": "Características",
    today: "hoje",
    tomorrow: "amanhã",
    "see-details-denounced-onboarding": "Consulte os detalhes",
    "see-details-denounced-onboarding-description":
      "Acesse o anúncio denunciado e lembre-se do motivo de cada denúncia.",
    "denounced-details-analyce-onboarding": "Analise a resposta",
    "denounced-details-check-onboarding":
      "Revise as alterações feitas pelo vendedor, seus documentos e seu histórico de denúncias.",
    "denounced-detail-send-answer-onboarding": "Envie sua resposta",
    "denounced-detail-send-answer-onboarding-description":
      "Confirme ou retire sua denúncia depois de analisar a resposta do vendedor.",
    "redesigned-the-section": "Repaginamos a seção!",
    "redesigned-the-section-description":
      "Agora, ficou mais fácil para você analisar cada resposta.",
    "your-denounced-ordered": "Suas denúncias bem organizadas",
    "your-denounced-ordered-description":
      "As denúncias que expiram logo aparecem primeiro para que nenhuma seja esquecida.",
    "your-answers-informed": "Analise todos os detalhes",
    "your-answers-informed-description":
      "Consulte no mesmo lugar os detalhes da denúncia e a resposta do vendedor.",
    "complaint-management": "Gerenciamento de reclamações",
    "complaint-management-answer":
      "Aqui você poderá acompanhar a situação de suas reclamações e responder aos vendedores para decidir se deseja confirmar ou retirar sua reclamação.",
    "answers-to-be-reviewed": "Respostas a serem revistas",
    "answers-to-be-reviewed-answer":
      "Exibe uma tabela com todas as reclamações onde você recebeu uma resposta do vendedor. Clique no botão 'Analyze Response' para ver seus detalhes.",
    "analyze-response": "Analisar a resposta",
    "analyze-response-answer":
      "Aqui você verá os detalhes do relatório que você fez, assim como os detalhes do vendedor que publicou o produto que você relatou.",
    "analyze-response-answer-two":
      "Nesta seção você pode ver os comentários ou anexos que o vendedor lhe enviou em resposta.",
    "complaint-management-image-1": "pending_notices_PT.jpg",
    "complaint-management-image-2": "pending_notices_res_PT.jpg",
    "detail-answer-positive-one": "Se você acha que sua resposta é suficiente",
    "detail-answer-positive-two":
      "e você quer retirar sua reclamação, você tem que retirar sua reclamação.",
    "detail-answer-negative-one":
      "Se você considerar a resposta do vendedor insuficiente,",
    "detail-answer-negative-two":
      "você terá que confirmar seu relatório. Neste caso, pediremos também que você selecione o motivo pelo qual rejeitou a resposta do vendedor.",
    "closed-complaints": "Reclamações fechadas",
    "closed-complaints-answer":
      "Aqui você pode consultar o histórico de suas reclamações fechadas. Os relatórios fechados são aqueles que terminam porque você já respondeu ao vendedor, porque nós o rejeitamos ou porque já encerramos o vendedor ou a publicação.",
    "publication-detail-text": "Detalhe do anúncio",
    "comment-pending": "Comentário",
    "optional-option": "Opcional",
    "send-answer-pending": "Enviar resposta",
    pending_textarea_placeholder:
      "Conte ao vendedor mais detalhes sobre sua decisão.",
    "comment-detail-pending":
      "Revise a resposta do vendedor antes de tomar sua decisão.",
    "answer-for-seller": "Resposta para o vendedor",
    "confirm-report": "Confirmar denúncia",
    "retract-report": "Retirar denúncia",
    "reason-for-confirmation": "Motivo da confirmação",
    "incorrect-documentation":
      "A documentação não corresponde ao produto relatado",
    "unreadable-documentation": "A documentação não pode ser lida",
    "unauthorized-user-document":
      "Você não está autorizado a usar este conteúdo",
    "does-not-justify-stock": "Não justifica o estoque",
    "counterfeited-buy": "Você faz uma compra e ela é falsificada",
    "luxury-product-with-a-lot-stock": "É um produto de luxo com muito estoque",
    "low-average-price": "É de preço abaixo do normal",
    "comments-seller-null": "O vendedor não fez comentários",
    "list-to-back-closed-denounced": "Veja a lista de relatórios fechados",
    "list-to-back-pending-denounced": "Veja a lista de relatórios encostas",
    "list-to-back-noticed-denounced": "Voltar para lista",
    "denounced-state": "Status da reclamação",
    rollbackeable: "Quero reativar este anúncio. ",
    "rollback-tooltip":
      "Marque esta opção se quiser retirar esta denúncia e reativar o anúncio. ",
    "rollbackeable-description-title": "Motivo da retirada",
    "rollback-placeholder":
      "Por favor, informe os motivos por ter retirado a denúncia",
    "rollback-case": "Reativar anúncio",
    "rollback-success": "Pronto! A denúncia foi retirada. ",
    "rollback-error":
      "Não foi possível salvar suas alterações. Por favor, tente novamente. ",
    "retracted-reason-title": "Motivo da retirada:",
    "see-documentation": "Ver documentação",
    "attached-documentation": "Documentação anexada",
    "see-images-updated": "Ver imagens modificadas",
    "images-updated": "Imagens alteradas",
    "reported-images": "Imagens denunciadas",
    "actual-images": "Imagens atuais",
    "commets-seller": "Comentário do vendedor",
    "sellers-history": "História do vendedor",
    "sellers-history-modal": {
      title: "Denúncias recebidas pelo vendedor",
      subtitle:
        "É o histórico das denúncias mais recentes recebidas por este vendedor, tanto as enviadas por você como por outros titulares de direito.",
      receive_s: "denúncia recebida",
      receive_p: "denúncias recebidas",
      receive_n: "Nenhuma denúncia recebida",
      sent_s: "resposta enviada",
      sent_p: "respostas enviadas",
      accepted_s: "resposta aceita",
      accepted_p: "respostas aceitas",
      table: {
        th1: "Anúncio",
        th2: "Relatório",
        th3: "Motivo da denúncia",
        th4: "Resposta do vendedor",
      },
    },
    "translate-contents": "Traduzir conteúdos",
    "answer-seller": "Resposta do vendedor",
    "see-ofert-auditor": "Ver comentário do auditor",
    "auditor-comment": "Comentário do auditor",
    "auditor-said": "disse",
    "motif-denounced": "Motivo da reclamação",
    "see-publication": "Ver publicação denunciada",
    "download-screenshot": "Baixar captura",
    "denounced-publication": "Publicação denunciada",
    "send-answer-condition":
      "Se você não responder antes da data de expiração, a publicação será mostrada novamente no Mercado Libre",
    "send-answer": "Envie sua resposta ao vendedor",
    "closed-title": "Denúncia encerrada",
    "closed-subtitle":
      "Você pode consultar os detalhes e retirar a sua denúncia, mas não pode se comunicar com o vendedor.",
    "rejected-title": "Não foi possível processar a denúncia",
    "rejected-subtitle":
      "Verifique no status da denúncia o motivo pelo qual não pudemos processá-la.",
    expires_text: "expira",
    "back-to-list": "Voltar para a lista",
    "see-report": "ver relatório",
    answer: "Responder",
    see: "Conferir",
    "analyse-response": "Analisar resposta",
    expires: "Expira",
    motif: "Motivo",
    action: "Ação",
    statusMsgPaused: "Anúncio pausado",
    automation: {
      newbie: {
        title:
          "Você atingiu o limite de 50 denúncias diárias. Só é possível denunciar amanhã ",
        text1:
          "Isso acontece porque sua conta e nova e precisamos conferir se as denúncias que você fez correspondem aos direitos cadastrados",
        text2: "Hoje, só é possivel marcar anúncios como suspeitos",
        button: "Ver mais.",
      },
      newbieInfo: {
        title: "Você pode fazer até 50 denúncias por dia",
        text1:
          "Isso acontece porque sua conta é nova e precisamos conferir se as denúncias que você fez correspondem aos direitos cadastrados",
        text2: "Ainda é possível fazer {denounces} denúncias hoje.",
        text3: "Depois, você só poderá marcar os anúncios como suspeitos",
        button: "Ver mais",
      },
      pending_cn: {
        title: "Você tem {30} respostas de vendedores que vencem hoje",
        text1:
          "Você deve revisar e respondê-las para poder continuar fazendo denúncias. Caso contrário, os anúncios denunciados serão reativados automaticamente.",
        text2:
          "Lembre-se de que, após recebê-las, você tem 4 dias para analisar a resposta dos vendedores.",
        button: "Responder",
      },
      limited_by_inefficient: {
        with_denounces_p: {
          title: "O limite de denúncias da sua conta foi restrito a 50 por dia",
          text1:
            "Isso acontece porque a eficiência da sua conta é muito baixa.",
          text2: "Ainda é possível fazer {number} denúncias hoje.",
          text3: "Depois, você só poderá marcar os anúncios como suspeitos.",
          button: "Ver mais",
        },
        with_denounces_s: {
          title: "O limite de denúncias da sua conta foi restrito a 50 por dia",
          text1:
            "Isso acontece porque a eficiência da sua conta é muito baixa.",
          text2: "Ainda é possível fazer {number} denúncia hoje.",
          text3: "Depois, você só poderá marcar os anúncios como suspeitos.",
          button: "Ver mais",
        },
        without_denounces: {
          title: "Você atingiu o limite de denúncias diárias",
          text1: "Hoje, só será possível marcar anúncios como suspeitos",
          text2:
            "Recomendamos que você confira a seção de eficiência para saber os motivos pelos quais sua conta foi restringida",
          button: "Ver mais",
        },
        general: {
          text3: "Confira seu histórico para mais informações.",
          button1: "Ir para o Histórico de denúncias",
          button2: "Ver mais",
        },
        day_limit: {
          title: "Você não poderá fazer mais denúncias pelo resto do dia",
          text1:
            "Você já denunciou sua quantidade máxima diária de 30.000 anúncios.",
          text2:
            "Lembre-se de que, mesmo assim, você poderá marcar anúncios como suspeitos, mas não poderá enviar as denúncias até amanhã.",
        },
      },
      items_inactive: {
        case_1: "Hoje você pode enviar somente {50} denúncias.",
        case_2: {
          text1: "Você já enviou {20} das suas {50} denúncias máximas diárias.",
          text2: "Restam {30}.",
        },
        case_3: {
          text1: "Você já denunciou seu número máximo diário de {50} anúncios.",
          text2: "Você não pode fazer mais denúncias pelo restante do dia.",
        },
        tooltip:
          "Restringimos suas denúncias, pois mais de 60% delas foram recusadas nos últimos 2 meses.",
      },
      generic: {
        title: "Você não pode mais fazer denúncias",
        text: "Lembre-se de que você poderá marcar anúncios como suspeitos, mas não poderá enviar as denúncias até o dia {date}.",
      },
      tooltip_all: {
        title: "Você quer enviar todas as denúncias?",
        body: "Após enviá-las, vamos notificar o vendedor para enviar sua resposta.",
        ok: "Enviar denúncias",
        cancel: "Cancelar",
      },
      tooltip_partial: {
        title_p: "Você pode enviar apenas {10} denúncias",
        title_s: "Você pode enviar apenas 1 denúncia",
        body_1:
          "Os anúncios que você selecionou excedem o limite de denúncias diárias, ",
        body_2: "mas mesmo assim você pode enviar {10}.",
        ok_p: "Enviar {10} denúncias",
        ok_s: "Enviar 1 denúncia",
        cancel: "Cancelar",
      },
    },
    counter_notice_new_deadline_1:
      "Mudamos os prazos para responder denúncias.",
    counter_notice_new_deadline_2:
      "Agora, você terá 4 dias para revisar a resposta dos vendedores.",
    article_detail: {
      breadcrumb_label: "Detalhe do item",
      description: "Descrição",
      empty_description: "O vendedor não inseriu a descrição do produto.",
      back_to_results: "Voltar aos resultados",
      back_to_suggested_listings: "Voltar aos anúncios sugeridos",
      view_all_description: "Ver a descrição completa",
      last_questions: "Últimas perguntas",
      empty_questions: "Ninguém fez perguntas ainda.",
      view_more_questions: "Veja mais perguntas",
      marked_as_suspicious: "Anúncio marcado como suspeito",
      motive: "Motivo",
      marked_as_trusted: "Anúncio marcado como confiável",
      by: "por",
    },
    seller_detail: {
      "": "",
    },
    mark_as_suspicious_label: "Suspeito",
    mark_as_trusted_label: "Confiável",
    mark_as_catalogo_label: "Veja detalhes",
    original: "Orig",
    translation: "Tradução",
    translate_click_here_original: "Ver original",
    translate_click_here_translated: "Ver tradução",
    maintenance: {
      we_are_working: "Estamos fazendo melhorias!",
      come_back_soon:
        "Volte em breve e reinicie sua sessão para conferir tudo!",
    },
    all_auditors: "Todos os auditores",
    there_are_not_listings_that_match:
      "Não há anúncios que correspondam à sua busca",
    view_more: "ver mais...",
    know_more: "Conoce más",
    view_more_suggested: "Ver mais sugeridos",
    view_all: "Ver todos",
    video_tutotial_link:
      "https://www.youtube.com/embed/dYEU82XeLcE?rel=0&amp;showinfo=0",
    title_snapshot_dowload: "Download de Snapshot",
    snapshot_dowload_error:
      "Ocorreu um erro ao obter o link de download, tente mais tarde.",
    snapshot_link_label: "Clique aqui para baixar o snapshot",
    new_content_available: "Novo conteúdo disponível",
    check_report_codes_for:
      "Verifique se você possui códigos de reclamação para os seguintes países: ",
    no_report_codes_available_for: "Você não tem códigos de reclamação para ",
    about_to_delete_live_search: "Deseja deletar essa Livesearch?",
    activate_all: "Ativar todos",
    countries_toggle_info:
      "Clique nos países onde você quer excluir da sua busca. Para desmarcar todos, por favor, use o botão abaixo.",
    authorize_btn: "Clique aqui para autorizar",
    message_authorize:
      "Você ainda deve autorizar os seguintes países a pesquisar e relatar sobre eles",
    activate_none: "Desativar todos",
    expand: "Expandir",
    collapse: "Colapsar",
    activity: "Atividade",
    activity_uppercase: "ATIVIDADE",
    apply: "Aplicar",
    apply_filters: "Aplicar alterações e atualizar os artigos",
    apply_filters_error:
      "Sentimos muito, mas não podemos aplicar os filtros. Por favor, tente novamente.",
    applying_filters: "Aplicando filtros",
    are_you_sure: "Tem certeza?",
    question_send_all_notices: "Tem certeza?",
    article: "artigo",
    disabled_article: "postagem desativada",
    article_change_status_error:
      "Sentimos muito, mas não podemos alterar o estado de artigo",
    article_change_status_error_already:
      "Já foi auditado dentro de sua organização.",
    articles_change_status_error:
      "Sentimos muito, mas não podemos alterar o estado de os artigos",
    articles_change_status_error_already:
      "Eles já foram auditados dentro de sua organização.",
    article_drop: "baixa",
    article_drops: "baixas",
    article_from_white_seller: "Vendedor Objetado",
    article_ok: "Artigo Válido",
    article_invalid: "Não é possível denunciar este anúncio",
    article_reference: "Id",
    article_brand_name: "Marca",
    article_part_name: "Número de peça",
    article_type: "Tipo de Produto",
    article_without_picture: "ARTIGO SEM FOTO",
    in_moderation_card_title: "Denúncia enviada",
    in_moderation_card_text_1: "Confira o status das suas denúncias no ",
    in_moderation_card_text_2: "Histórico de denúncias.",
    in_moderation_card_text_3: "",
    articles_to_ok_warn:
      "Você esta a ponto de alterar o estado do Artigo para OK. Essa ação não se pode ser desfeita.",
    articles_to_pending_warn:
      "O Artigo voltará ao estado pendente e perderá a informação da denuncia. Tem certeza?",
    mark: "Marcar",
    articles_to_report: "anúncios como suspeitos",
    article_to_report: "anúncio como suspeito",
    articles_to_report_menu: "Denunciar anúncios suspeitos",
    articles_to_report_short: "Art. para denunciar",
    audited: "Auditados",
    audited_batch_title: "Auditados em modo seleção multipla",
    result: "Resultado",
    results: "Resultados",
    ready_to_audit: "PRONTO PARA AUDITAR",
    available_quantity: "disponíveis",
    avoided_words: "Palavras para evitar",
    batch_mode: "Seleção múltipla",
    batch_mode_info:
      "Selecione diversos anúncios clicando no botão de Seleção Múltipla e, em seguida, nos polegares para cima ou para baixo.",
    brand: "Marca",
    brands: "Marcas",
    ok: "Sim",
    accept: "Aceitar",
    cancel: "Cancelar",
    cancel_batch_warning:
      "Ira perder todas as publicações selecionadas. Tem certeza?",
    category: "Categoria",
    choose_another_brand: "Selecionar outra marca",
    choose_brand: "Selecione uma marca",
    condition_all: "Todos",
    condition_from_blacklisted_sellers: "De Vendedores Observados",
    condition_new: "Novo",
    condition_used: "Usado",
    condition_refurbished: "Recondicionado",
    "official-store": "Produto Oficial da Loja",
    "switch-translate": {
      label: "Traduzir anúncio",
      tooltip: {
        title: "Traduzir este anúncio",
        description: "Ative nosso tradutor e analise melhor a sua denúncia.",
        button: "Entendi",
      },
      tooltip_2: {
        title: "Desativamos esta função enquanto a denúncia é feita",
        description:
          "Isso foi feito para que você possa analisar e marcar violações sobre os textos originais.",
        button: "Entendi",
      },
      snack_message: {
        title: "Esta é uma tradução automática e pode conter erros",
        description:
          "Por favor, observe que a funcionalidade é um serviço prestado por terceiros e não deve ser considerada uma tradução oficial. É apenas uma sugestão e seu uso não é obrigatório.",
      },
      error:
        "Tivemos um problema e não foi possível carregar sua tradução. Por favor, tente novamente.",
    },
    languages: {
      es: "Español",
      en: "Inglés",
      pt: "Portugués",
    },
    countries: {
      AA: "Global",
      AR: "Argentina",
      AT: "Áustria",
      BE: "Bélgica",
      BO: "Bolívia",
      BR: "Brasil",
      CA: "Canadá",
      CH: "Suíça",
      CL: "Chile",
      CO: "Colômbia",
      CR: "Costa Rica",
      DE: "Alemanha",
      DO: "República Dominicana",
      RD: "República Dominicana",
      EC: "Equador",
      EN: "Inglaterra",
      ES: "Espanha",
      FR: "França",
      GB: "Reino Unido",
      GT: "Guatemala",
      HN: "Honduras",
      IE: "Irlanda",
      IT: "Itália",
      MX: "México",
      NI: "Nicarágua",
      NL: "Holanda",
      PA: "Panamá",
      PE: "Peru",
      PR: "Puerto Rico",
      PT: "Portugal",
      PY: "Paraguai",
      SC: "Escócia",
      SV: "El Salvador",
      US: "EUA",
      UY: "Uruguai",
      VE: "Venezuela",
    },
    countries_legal: {
      AR: "Argentina",
      BO: "Bolívia",
      BR: "Brasil",
      CL: "Chile",
      CO: "Colômbia",
      CR: "Costa Rica",
      DO: "Dominicana",
      EC: "Equador",
      GT: "Guatemala",
      HN: "Honduras",
      MX: "México",
      NI: "Nicarágua",
      PA: "Panamá",
      PE: "Peru",
      PY: "Paraguai",
      SV: "El Salvador",
      UY: "Uruguai",
      VE: "Venezuela",
    },
    country: "País",
    countries_select: "Países",
    country_selector_clarification:
      "Selecione os países em que você deseja pesquisar",
    all_the_countries: "Todos os países",
    create_live_search_with_current_listings:
      "Criar Livesearch dos artigos Atuais",
    create_report_results: "Gerar resultados do Livesearch",
    create_livesearch_report_created: "Relatório criado com sucesso.",
    create_livesearch_report_error: "Ocorreu um erro ao criar o relatório.",
    go_to_ls_reports: "Ir para os relatórios do Livesearch",
    create_search_of_black_sellers:
      "Criar Livesearch de todos os Vendedores Observados",
    creating: "criando",
    currency_report_results:
      "Selecione a moeda para a apresentação dos resultados",
    current_listings: "Artigos Atuais",
    onboarding_slide: {
      skip: "Omitir",
      start: "Começar",
      slide1: {
        title: "Antes de começar...",
        text: "Oferecemos algumas dicas para que você possa aproveitar nossa ferramenta ao máximo.",
      },
      slide2: {
        title: "Faça buscas em massa",
        text: "Use o campo de busca para encontrar infrações em qualquer site do Mercado Livre.",
        image: "slide1-pt.png",
      },
      slide3: {
        title: "Crie seus filtros de busca",
        text: "Personalize suas buscas para encontrar infrações mais rápido e facilmente.",
        image: "slide2-pt.png",
      },
      slide4: {
        title: "Diversas denúncias de uma só vez",
        text: "Denuncie diversos anúncios em uma mesma tela e em poucos cliques.",
        image: "slide3-pt.png",
      },
      slide5: {
        title: "Envie todas as suas denúncias",
        text: "Quando estiver com tudo pronto para enviar suas denúncias, pode confirmar uma a uma ou todas de uma só vez.",
        image: "slide4-pt.png",
      },
      slide6: {
        title: "Pronto! Comece a denunciar",
        text: "Lembre-se que, em caso de dúvidas, basta acessar a seção de Ajuda no menu.",
      },
    },
    re_branding: {
      modal_title: "Agora somos o Brand Protection Program do Mercado Livre",
      bullet_1: {
        title: "Novo nome",
        subtitle:
          "O Programa de Proteção à Propriedade Intelectual agora se chama Brand Protection Program.",
      },
      bullet_2: {
        title: "Novo site",
        subtitle:
          "Agora, com mais informações e respostas para as perguntas mais frequentes.",
      },
      bullet_3: {
        title: "O compromisso de sempre",
        subtitle:
          "Continuamos trabalhando para melhorar o  programa e tornar o processo de denúncias cada vez mais fácil.",
      },
      check_out_what_is_new: "Confira todas as novidades!",
      go_to_bpp_site: "Ir para o site",
    },
    suggestions_slide: {
      next: "Próximo",
      start: "Entendi",
      new_user: {
        slide1: {
          title: "Uma nova forma de denunciar",
          text: "Analise anúncios que detectamos automaticamente, com base nos nossos processos e no seu histórico de denúncias.",
        },
        slide2: {
          title: "Quanto mais analisar, melhores serão as sugestões",
          text: "Com suas denúncias, aprendemos para continuar melhorando nossas detecções e atualizar suas recomendações.",
        },
        slide3: {
          title: "Novo! Filtre por país e continue denunciando",
          text: "Encontre anúncios sugeridos em países do seu interesse e observe o progresso das suas denúncias da última semana.",
        },
      },
      old_user: {
        tag_text: "Novo!",
        slide1: {
          title: "Filtre por país e continue denunciando",
          text: "Encontre anúncios sugeridos em países do seu interesse e observe o progresso das suas denúncias da última semana.",
        },
      },
    },
    verify_profile: {
      title: "Atualize os dados da sua conta",
      subtitle:
        "Para que possamos continuar processando suas denúncias corretamente, precisamos que você atualize suas informações.",
      expires: "Você tem até o dia",
      verify_btn: "Atualizar dados",
      remind_later: "Lembrar mais tarde",
      alert: {
        main_text: "Atualize os dados da sua conta.",
        secondary_text:
          "Certifique-se de que suas informações estejam completas para que possamos continuar processando suas denúncias.",
        review_data: "Atualizar dados",
      },
      form: {
        title: "Perfil da conta",
        subtitle: "Atualize os dados da sua conta. Lembre-se de que ",
        subtitle_b: "você não poderá editar após confirmar esses dados.",
        confirm: "Confirme",
        account_type: {
          verified_title: "Tipo de usuário",
          title: "Que tipo de usuário você quer cadastrar?",
          item_titular: "Sou titular de direitos ou representante legal",
          helper_titular:
            "Ou seja, você é o proprietário ou representante legal dos direitos a serem cadastrados.",
          item_apoderado: "Sou procurador dos direitos",
          helper_apoderado:
            "Ou seja, você é pessoa física ou jurídica autorizada pelo titular dos direitos para agir em seu nome.",
        },
        attorney_accreditation: {
          title: "Valide-se como procurador",
          name: "Nome do procurador:",
          name_helper: "Pode ser uma pessoa física ou empresa.",
          subtitle: "Dados de contato do titular",
          subtitle_helper_1: "opcional",
          subtitle_helper_2:
            "Pode ser o gerente de proteção de marcas ou advogado de propriedade intelectual.",
          contact_name: "Nome do contato",
          email: "E-mail",
        },
        account_info: {
          title: "Informações sobre a conta",
          subtitle: "O titular de direitos é:",
          natural: "Pessoa física",
          legal: "Pessoa jurídica",
          physical_fields: {
            name: "Nome completo",
            country: "País",
            type: "Tipo de identificação",
            number: "Número de identificação",
          },
          legal_fields: {
            name: "Nome ou razão social",
            country: "País",
            type: "Tipo de identificação tributária",
            number: "Número de identificação tributária",
          },
        },
        attorney_contact: {
          title: "Contato do representante",
          helper:
            "Esses são os dados que usaremos no Mercado Livre para entrar em contato com o representante ou administrador da conta.",
          name: "Nome completo",
          code: "DDI",
          tel: "Telefone",
          tel_helper: "Inclua o DDD.",
          email: "E-mail corporativo",
          email_ex: "Por exemplo, joao.silva@suaempresa.com",
          lang: "Idioma de contato",
          lang_placeholder: "Selecione",
        },
        public_data: {
          title: "Dados públicos",
          helper:
            "Esses são os dados que enviaremos ao vendedor denunciado para que ele possa entrar em contato com você assim que o processo de denúncia estiver concluído. Informe o nome da marca ou do grupo econômico para que o vendedor saiba quem está denunciando.",
          name: "Nome a mostrar",
          email: "E-mail",
          email_helper:
            "Pode ser genérico e não deve ser um email já registrado no Mercado Livre.",
        },
        exit_modal: {
          title: "Você quer sair do formulário?",
          text: "Se abandonar a página, você deverá preencher todos os dados novamente.",
          back: "Voltar",
          exit: "Sair",
        },
        success: {
          text: "Pronto! Já atualizamos seus dados.",
        },
        error_email: {
          text: "O e-mail inserido já está cadastrado no Mercado Livre. Por favor, insira outro.",
        },
      },
    },
    suggested_listings: {
      title: "Anúncios sugeridos",
      subtitle_p:
        "Nesta seção, você verá os anúncios que encontramos com base nas nossas detecções e seu histórico de denúncias.",
      subtitle_s:
        "São anúncios que encontramos com base nas nossas detecções e seu histórico de denúncias.",
      stats: {
        box_1: {
          text1_p: "anúncios para denunciar",
          text1_s: "dos anúncios analisados",
          text2: "Denunciar",
        },
        box_2: {
          text1: "Seu progresso nos últimos 7 dias",
          text2_p: "marcados como suspeitos",
          text2_s: "marcado como suspeito",
          text3_p: "marcados como confiáveis",
          text3_s: "marcado como confiável",
          text4: "Evolução das reclamações",
          text5: "hoje",
        },
        tooltip_p: "anúncios denunciados",
        tooltip_s: "por denunciar",
      },
      item1s: "dos anúncios analisados",
      item2s: "marcado como confiável",
      item3s1: "marcado como suspeito",
      item3s2: "Denunciar",
      item1p: "dos anúncios analisados",
      item2p: "marcados como confiáveis",
      item3p1: "marcados como suspeitos",
      item3p2: "Denunciar",
      not_results_title: "Você não tem mais sugestões de anúncios!",
      not_results_link_label: "Fazer uma busca",
    },
    suggested_discard: {
      text_btn: "Excluir anúncio",
      tooltip: {
        title: "Não tem certeza do que fazer?",
        description:
          "Agora você pode excluir o anúncio para que deixemos de exibi-lo nos seus resultados.",
      },
      modal: {
        title: "Por que você quer excluir este anúncio?",
        subtitle:
          "Lembre-se de que voltaremos a exibi-lo nos seus resultados de busca se o vendedor o alterar ou receber novas perguntas.",
        discard_item: {
          item1: "Não tenho certeza que tem uma infração",
          item2:
            "O anúncio ou o produto não corresponde aos meus direitos cadastrados",
          item3: "O anúncio ou o produto não é relevante para o meu negócio",
          item4: "Outro motivo",
          item1_subtext:
            "Ou seja, você não encontrou evidências precisas de que o produto é falsificado, de infrações de propriedade intelectual etc.",
          item2_subtext:
            "Ou seja, os direitos que você têm não permitem que você denuncie infrações sobre este anúncio ou produto.",
          item3_subtext:
            "Ou seja, não é o foco principal da marca ou da sua operação.",
          item4_subtext: "",
        },
        step_2: {
          title: "Pode nos contar um pouco mais?",
          subtitle: "(Opcional)",
          placeholder: "Conte-nos por que você quer excluir o anúncio.",
          btn_text: "Excluir anúncio",
        },
      },
      done_article_discarded:
        "Feito! Se o anúncio tiver alterações, voltaremos a exibi-lo para você.",
    },
    file_uploader: {
      formats: "Formatos",
      maximum_size: "Tamanho máximo",
    },
    xlsx_import_success: {
      done: "Pronto!",
      marked_listing: "Você marcou 1 anúncio como suspeito.",
      marked_listings: "Você marcou {0} anúncios como suspeitos.",
      go_to_report: "Denunciar",
    },
    add_suspicious_xlsx: {
      alert_easier: "Denunciar em massa já está mais fácil.",
      alert_info:
        "Agora, você pode enviar uma planilha com todos os anúncios que quiser denunciar.",
      alert_btn_label: "Upload em massa",
      tooltip_onboarding_title: "Precisa importar uma lista?",
      tooltip_onboarding_text:
        "Agora você pode baixar sua planilha Excel de anúncios suspeitos nesta seção.",
      tootip_btn_title: "Baixe aqui sua panilha",
      tootip_btn_text:
        "Você poderá importar somente arquivos que tenha baixado aqui.",
      modal_title: "Importar lista de anúncios suspeitos",
      modal_subtitle_text1:
        "Observe que você só poderá importar arquivos que foram baixados a partir desta seção.",
      modal_subtitle_text2: "Baixe sua planilha de Excel",
      btn_import: "Enviar arquivo",
      elements: "elementos importados",
      element: "elemento importado",
      listings_found: "anúncios importados com sucesso",
      listing_found: "anúncio importado com sucesso",
      errors: "Errores",
      error: "anúncio que não puderam ser processado",
      listings_duplicated: "anúncios já marcados como suspeitos",
      listing_duplicated: "anúncio já marcado como suspeito",
      download_detail: "Baixe a planilha para verificar os erros",
      process_results_title: "Carregamos dados com sucesso",
      found_count_label: "Número de publicações que você pode adicionar: ",
      not_found_count_label: "Número de publicações que não encontramos: ",
      invalid_count_label: "Quantidade de IDs inválidos: ",
      how_to_title: "Diretrizes para criar a lista",
      how_to_bullet_1: "Inclua um anúncio por linha.",
      how_to_bullet_2: "Você pode incluir o ID do anúncio ou o URL.",
      how_to_bullet_3: "Adicione somente anúncios do mesmo país.",
      how_to_bullet_4: "Você pode importar no máximo 1000 anúncios por vez.",
      how_to_bullet_5:
        "Certifique-se de que todos os anúncios tenham o mesmo motivo de denúncia.",
      how_to_important: "Importante!",
      how_to_download: "Baixar exemplo",
      processing: "Em processamento",
      empty_text: "Adicionar arquivo",
      empty_drag_text: "Você também pode arrastá-los",
      on_drag_text: "Solte o arquivo aqui para processá-lo",
      drag_file_too_large: "O arquivo é muito pesado",
      error_reading_file:
        "Não foi possível revisar os anúncios. Por favor, tente novamente mais tarde.",
      only_duplicated_listings_message:
        "Os anúncios já estão marcados como suspeitos.",
      only_duplicated_listing_message:
        "O anúncio já está marcado como suspeito.",
      not_enrolled_rigths_listings_message:
        "Os anúncios são de um país onde você não possui direitos cadastrados.",
      not_enrolled_rigths_listing_message:
        "O anúncio é de um país onde você não possui direitos cadastrados.",
      not_valid_found_message:
        "Não encontramos anúncios com os IDs ou URLs adicionados.",
      only_one_site: "O arquivo contém anúncios de mais de um país.",
      only_one_file: "Não é possível enviar mais de um arquivo por vez.",
      incorrect_file_format: "O formato do arquivo é incorreto",
      too_many_message: "O arquivo contém mais de 1000 componentes",
      file_exceeds_maximum_size: "O arquivo excede o tamanho máximo.",
      reason_select: "Selecione o motivo da reclamação",
      reason_no_selected: "O motivo não foi selecionado",
      comment_title: "Adicionar comentário",
      comment_description:
        "Aquí puedes agregar un comentario que acompañe a tu denuncia",
      comment_warning: "O texto deve ter pelo menos 5 caracteres.",
    },
    live_search_report_form: {
      new_report: "Novo Relatório",
      source: "Fonte",
      option_meli_dump: "Dump",
      option_meli_api: "API",
      search_word: "Prazo para pesquisar",
      nickname_seller: "Nickname do Vendedor",
      listing_state: "Estado das publicações",
      all: "Todas",
      active: "Ativo",
      paused: "Em Pausa",
      countries: "Países",
      no_countries: "Nenhum país selecionado",
      preview: "Aplicar e pesquisar",
      create: "Criar relatório",
      selected_country: "país selecionado",
      selected_countries: "países selecionados",
      clarification:
        "O relatório gerado usando dados atualizados uma vez por dia.",
    },
    denunciated: "denunciados",
    denunciation: "denuncia",
    download_report_results: "Baixar Resultados do Relatório",
    ebay: "eBay",
    edit: "Editar",
    email: "Email",
    enter_keyword_for_live_search: "Selecione o critério de busca",
    start_the_search_in: "Começar a busca em ",
    stored_searches: "Buscas salvas",
    export_data: {
      reports: {
        all_sellers: "Todos os vendedores",
        audits_by: "Auditadas por",
        avoid_words: "Evitar palavras",
        blacklisted_sellers: "Vendedores na lista negra",
        brand: "Marca",
        city: "Ciudade",
        brands: "Marcas",
        ok: "Sim",
        accept: "Aceitar",
        cancel: "Cancelar",
        cancel_batch_warning:
          "Ira perder todas as publicações selecionadas. Tem certeza?",
        category: "Categoria",
        choose_another_brand: "Selecionar outra marca",
        choose_brand: "Selecione uma marca",
        condition_all: "Todos",
        condition_from_blacklisted_sellers: "De Vendedores Observados",
        condition_new: "Novo",
        condition_used: "Usado",
        condition_refurbished: "Recondicionado",
        "official-store": "Produto Oficial da Loja",
        languages: {
          es: "Español",
          en: "Inglés",
          pt: "Portugués",
        },
        countries: {
          AA: "Global",
          AR: "Argentina",
          AT: "Áustria",
          BE: "Bélgica",
          BO: "Bolívia",
          BR: "Brasil",
          CA: "Canadá",
          CH: "Suíça",
          CL: "Chile",
          CO: "Colômbia",
          CR: "Costa Rica",
          DE: "Alemanha",
          DO: "República Dominicana",
          RD: "República Dominicana",
          EC: "Equador",
          EN: "Inglaterra",
          ES: "Espanha",
          FR: "França",
          GB: "Reino Unido",
          GT: "Guatemala",
          HN: "Honduras",
          IE: "Irlanda",
          IT: "Itália",
          MX: "México",
          NI: "Nicarágua",
          NL: "Holanda",
          PA: "Panamá",
          PE: "Peru",
          PR: "Puerto Rico",
          PT: "Portugal",
          PY: "Paraguai",
          SC: "Escócia",
          SV: "El Salvador",
          US: "EUA",
          UY: "Uruguai",
          VE: "Venezuela",
        },
        country: "País",
        currency: "Moeda",
        date_finish: "Data de Término",
        date_start: "Data do Início",
        generated_by: "Gerado por",
        id: "Id",
        items_sold: "Itens vendidos",
        listing_lnk: "Link iten",
        listing_title: "Título do artigo",
        listing_type: "Tipo de anúncio",
        listings: "Publicações",
        location: "Localização",
        marketplace: "Mercado",
        marketplaces: "Mercados",
        offered_stock: "issoque ofertado",
        original_currency: "Moeda orig.",
        original_price: "Preço orig.",
        picture_1: "Imagem 1",
        picture_2: "Imagem 2",
        picture_3: "Imagem 3",
        picture_4: "Imagem 4",
        price: "Preço",
        price_range: "Faixa de preço",
        quantity: "Quantidade",
        removed_listings: "Itens Removidos",
        report_code: "Código de relatório",
        report_currency: "Moeda Relatório",
        report_date: "Data relatório",
        report_description: "Descrição relatório",
        report_tags: "Tags relatório",
        reported_listings: "Itens Relataram",
        reports: "Relatórios",
        required_words: "Palavras necessárias",
        search_by_marketplaces: "Pesquisa feita nos seguintes Marketplaces",
        search_terms: "Termos de Pesquisa",
        seller_username: "Usuário Vendedor",
        sellers_type: "Vendedores",
        seller: "Vendedor",
        snapshot: "Snapshot",
        sold: "Vendido",
        stock: "issoque",
        tag: "Tag",
        take_down_date: "Data de Término",
        report_status: "Estado Relatório",
        top_countries: "Top 5 Países",
        top_countries_by_publications:
          "Top 5 Países de acordo com o número de publicações",
        top_reported_sellers: "Top 5 Vendedores Relataram",
        top_sellers_by_publications:
          "Top 5 Vendedores de acordo com o número de publicações",
        total_value_reported_listings: "Valor Total dos Artigos Relatados",
        user: "Usuário",
        value_higher_than: "Superior a",
        value_less_than: "Menor que",
        volume_offered: "Volume ofertado",
        volume_reported: "Volume registado",
        volume_sold: "Volume vendido",
        worksheet_executive_summary: "Sumário Executivo",
        worksheet_listings: "Publicações",
        worksheet_marketplaces: "Mercados",
        worksheet_reported_listings: "Itens Relataram",
        worksheet_sellers: "Vendedores",
        worksheet_sellers_info:
          "* Alguns Mercados não oferecem dados sobre a quantidade e número de itens vendidos.",
        worksheet_tags: "Tags",
      },
    },
    exit_create_account: "Tem certeza de que quer sair?",
    create_auditor: "Agora você pode criar auditores de usuário",
    auditor_user: "Usuário de auditoria",
    name: "Nome",
    last_name: "Sobrenome",
    user_email: "E-mail",
    user: "Usuário",
    add_auditor: "Adicionar auditor",
    new_password_rules:
      "A senha deve ter mais de 8 caracteres e ser diferente da atual.",
    error_password_confirm: "As senhas devem corresponder",
    error_password_length: "A senha deve conter no mínimo 8 caracteres",
    wrong_password: "Nome de usuário ou senha incorretos",
    forgot_password: "Você esqueceu sua senha?",
    confirmation_code: "Código de verificação",
    enter_confirmation_code: "Insira o código de verificação",
    sent_to:
      "Nós o enviamos por e-mail para $email. Caso você não o encontre, confira na caixa de Spam.",
    did_not_receive: "Não recebi o código",
    yes: "Sim",
    no: "Não",
    wrong_confirmation_code: "Código de verificação incorreto",
    send_new_code: "Envie um novo código?",
    set_custom_password: "Por favor, defina a senha personalizada",
    create_user: "Criar usuário",
    create_users: "Criar usuários",
    continue_button: "Continuar",
    account_data: "Dados da conta",
    account_data_subtitle:
      "Com estes dados, você poderá vincular todos os seus usuários do Brand Protection Program e denunciar em diversos países ao mesmo tempo.",
    default_account: "Este será o usuário que você usará para entrar",
    user_already_exist: "Você já tem uma conta criada com este e-mail.",
    user_not_found: "O usuário não existe no país selecionado.",
    auditors: "Auditores",
    auditor: "Auditor",
    meli_token_authenticate_error:
      "Usuário incorreto. Por favor, entre com a sua conta do Brand Protection Program.",
    code_resend: "Encaminhamos o código para o seu email",
    account_welcome_message:
      "agora crie uma conta para usar a nova ferramenta de denúncias",
    account_created_title: "Pronto! Você se cadastrou na nova ferramenta",
    account_created_message:
      "Você já pode começar a denunciar em massa de forma mais fácil e eficiente com as novas ferramentas do Brand Protection Program.",
    create_account_success: "usuário criado com sucesso",
    main_account_info: "$account, por favor, lembre-se que:",
    account_created_reminder:
      "Se você geralmente denuncia no Brand Protection Program através de provedores que protegem seus direitos e, agora, você criou um usuário para denunciar diretamente, considere que as respostas às suas denúncias serão recebidas pelos provedores e eles podem não estar cientes da sua conta.",
    account_created_recomendation:
      "Portanto, recomendamos que você entre em contato e coordene o acompanhamento.",
    look_what_you_can_do: "Veja tudo o que você poderá fazer",
    create_account_error:
      "Ocorreu um erro quando criamos o usuário, tente mais tarde",
    export_xls: "Modelo de Exportação",
    "main-page-welcome-msg": "Renovamos nosso Brand Protection Program",
    "main-page-title":
      "Criamos novos processos para identificar vendedores infratores e os sancionamos muito mais rápido",
    "main-page-subtitle": "Além disso, oferecemos as melhores ferramentas:",
    "main-page-item-1":
      "Pesquise e relate facilmente em vários países ao mesmo tempo",
    "main-page-item-2": "Crie e salve seus próprios critérios de pesquisa",
    "main-page-item-3":
      "Escolha quais vendedores deseja focar e analise apenas suas publicações",
    "main-page-item-4":
      "Acesse um relatório com todas as publicações denunciadas e removidas",
    "main-page-item-5":
      "Adira e renove seus direitos com muito mais facilidade e rapidez",
    register_as_auditor:
      "Registre-se como auditor para começar a relatar e conhecer e Brand Protection Program",
    help_message: "Encontre tudo que precisa na seção de %{copy_help}",
    dont_know_how_to_start: "Não sabe como começar?",
    close_help_dialog_error:
      "Houve um erro enquanto fechamos o diálogo, por favor, tente mais tarde.",
    need_help: "¿Precisa de ajuda?",
    help: "Ajuda",
    help_href: "/noindex/pppi/support/",
    know_more_href: "https://www.mercadolivre.com.br/noindex/pppi/landings/bpp",
    register_href:
      "https://www.mercadolivre.com.br/noindex/pppi/rights/enroll/",
    contact_us: "Entre em contato conosco",
    buy_and_sell: "Compre e venda a partir do seu celular",
    enter_your_email: "Digite seu email",
    old_password: "Senha anterior",
    new_password: "Nova senha",
    new_password_confirm: "Confirmar Senha nova",
    password_changed_success: "Sua senha foi alterada com sucesso",
    password_changed_error:
      "Desculpe, sua senha não pode ser alterada, por favor tente mais tarde",
    enter_your_new_password: "Digite sua nova senha",
    password_confirmation: "Sua senha novamente",
    change_password: "Alterar senha",
    changing_password: "Mudando senha",
    send_email: "enviar",
    sending_email: "enviando email",
    sendind_email: "envio",
    failed_articles_message:
      "Essa Livesearch não pode ser completada exitosamente, houve %{count} artigos que não foram carregados",
    filter_profile: {
      "clarification-p1": "Ao clicar em ",
      "clarification-p2": "Aplicar e pesquisar, ",
      "clarification-p3": "suas preferências serão salvas automaticamente",
      all: "Todos",
      apply_filters: "Aplicar e pesquisar",
      article_condition: "Condição",
      categories: "Categorias",
      none_category: "Não",
      no_subcategories_for: "Não há subcategorias para",
      no_category_selected: "Você não marcou nenhuma categoria",
      info_categories_language:
        "Mostraremos as categorias no idioma do site onde você estiver buscando.",
      change: "Modificar",
      article_new: "Novos",
      article_used: "Usados",
      available_quantity: "Estoque oferecido",
      banned_keywords: "Termos a excluir",
      copy_of: "Copia de %{name}",
      "country.name": "País",
      "prices.USD": "Preço",
      date_range: "Rango de datas",
      default: "Predeterminada",
      extended_data_warning:
        "Isto irá trazer artigos somente de sitios que tenham esse tipo de informação.",
      extended_data_sites_list: "Sitios disponiveis: %{sites}.",
      fromPrice: "Mínimo",
      fromDate: "De",
      greater_than: "Maior que",
      no_name: "Sin nombre",
      portal: "Portal",
      price_range: "Preço",
      required_keywords: "Termos a incluir",
      seller: "Vendedores",
      seller_blacklisted: "Vendedores observados",
      no_seller_selected: "Você não selecionou nenhum vendedor",
      none_seller: "Nenhum",
      sold_quantity: "Estoque vendido",
      sort_by: "Ordenar por",
      select_sort_by: "Selecionar",
      title: "Perfis de filtros para Essa Livesearch",
      toPrice: "Máximo",
      toDate: "Até",
      select_one_site: "Selecione pelo menos um país",
    },
    filter: "Editar filtros",
    clean_filter: "Limpar filtros",
    filtered: "Filtrados",
    filtered_results: "Resultados filtrados",
    filters_title: "FILTROS DE PESQUISA - Refinar resultados",
    forgot_your_password: "Esqueceu sua senha?",
    generating_report_results: "Gerando Resultados do Relatório",
    greater_than: "Maior que",
    home_title: "Brand Monitoring",
    in_progress: "em processo",
    processing: "processamento",
    language: "Idioma",
    live_search: "Livesearch",
    live_search_all_loaded: "Todos os resultados foram mostrados",
    live_search_fetch_error:
      "Sentimos muito mas houve un Erro quando estava sendo procurado a Livesearch. Por favor, tente novamente.",
    live_search_create_error:
      "Sentimos muito mas houve un Erro quando estava sendo criada a Livesearch. Por favor, tente novamente.",
    live_search_refresh_error:
      "Sentimos muito mas houve un Erro quando estava sendo atualizada a Livesearch. Por favor, tente novamente.",
    live_search_delete_error:
      "Sentimos muito mas houve un Erro quando estava sendo eliminada a Livesearch. Por favor, tente novamente.",
    live_search_filters: "Filtros de Pesquisa",
    live_search_loading_results: "Baixando mais resultados",
    live_search_queue_header:
      "FILA DE TRABAJO - Buscas realizadas e em processo",
    live_search_recent: "RECENTE",
    live_search_refinements_error:
      "Sentimos muito mas houve un Erro quando estavamos aplicando os filtros. Por favor, tente novamente.",
    live_search_reloading: "Executando de novo",
    live_search_results: "RESULTADOS FILTRADOS DE PESQUISA",
    live_search_empty: "Nenhum item foi carregado ainda",
    show_hidden_publications: "Ver anúncios ocultos",
    hidden_tooltip_text:
      "Ocultamos as publicações que você marcou como não-violadoras, que você já denunciou ou que pertencem a vendedores confiáveis.",
    in_moderation: "Reportados",
    validated: "Não infratores",
    denounced: "Reportados",
    multiple_selector: {
      advice:
        "Lembre-se de que você não pode usar a seleção múltipla para denunciar por direitos autorais de imagens.",
      trusted: "Confiáveis",
      suspicious: "Suspeitos",
      tooltip_text:
        "Você não pode usar esta função para denunciar usos não autorizados de imagens.",
    },
    quick_search: "Busca rápida",
    quick_search_placeholder:
      "Busque por palavra-chave, código EAN ou número da peça",
    publication_id: "ID do anúncio",
    publication_id_placeholder: "MLB-745531777 o MLB745531777",
    publication_id_helper:
      "Encontre o ID completo na URL do anúncio. Você pode inserí-lo sem hífen.",
    seller_nickname: "Nome do vendedor",
    search_label_seller: "Palavras a buscar (opcional)",
    search_label_publication: "Busca rápida",
    url: "URL",
    no_matches: "Não há anúncios que correspondam à sua busca",
    no_matches_advice1:
      "Verifique a ortografia ou use palavras mais genéricas.",
    no_matches_advice2: "Tente excluir alguns dos termos.",
    no_matches_advice3:
      "Certifique-se de que o vendedor continue ativo no site.",
    no_matches_advice4: "Alterar filtros",
    nothing_here: "Nada por aqui...",
    you_did_not_do_any_search_yet: "Você ainda não possui nenhuma busca salva",
    live_search_empty_filtered: "Não há resultados para os filtros aplicados",
    live_search_status_count_error:
      "Ocorreu um erro ao procurar o número de estados dos artigos",
    live_search_processed_count_error:
      "Ocorreu um erro enquanto procurávamos os itens processados, tente mais tarde",
    live_search_new_processed_count: "Novos resultados disponíveis",
    loading: "Baixando...",
    loading_publications: "Baixando publicações...",
    loading_articles: "Baixando artigos",
    loading_brands: "Baixando marcas...",
    loading_live_searches: "Baixando Livesearches",
    loading_portals: "Baixando portales",
    loading_results: "Baixando Resultados",
    login: "Iniciar Sessão",
    loging_in: "Ingresando...",
    recover_password: "Recuperar senha",
    contact: "Contato",
    login_welcome_message:
      "Entre com o usuário e senha da nova ferramenta de denúncias",
    logout: "Finalizar Sessão",
    enroll_rights: "Adicionar direitos",
    new_profile: "Novo perfil",
    change_profile: "Alterar perfil",
    create_profile: "Criar",
    select_profile: "Selecione o perfil",
    create_profile_success: "Você criou com sucesso o perfil",
    create_profile_error: "Ocorreu um erro quando criamos o perfil",
    fetch_profiles_error:
      "Ocorreu um erro quando procuramos perfis disponíveis",
    change_profile_success: "Seu perfil foi alterado com sucesso.",
    change_profile_error:
      "Ocorreu um erro quando alteramos o perfil, tente mais tarde.",
    no_profiles: "Nenhum perfil disponível",
    create_account: "criar uma conta",
    lower_than: "menor que",
    mercadolibre: "Mercadolibre",
    money_saved: "Volume salvado",
    new_live_search: "Nova Livesearch",
    new_search: "Nova busca",
    new_product: "Produto novo",
    refurbished_product: "Produto Recondicionado",
    next: "Seguinte",
    no_data: "N/D",
    optional: "opcional",
    password: "Senha",
    create_user_password: "Senha",
    pending: "pendente",
    phone: "Tel",
    placeholder_avoided_keywords: "Adicione aqui as palavras a excluir",
    placeholder_required_keywords: "Adicione aqui as palavras a incluir",
    previous: "Anterior",
    price: "Preço",
    price_range: "Rango de Preços",
    prices_larger_than_currency: "Preços maiores que",
    prices_smaller_than_currency: "Preços menores que",
    quebarato: "Que Barato",
    refine_search_in_sites: "Refinar Pesquisa en Portales",
    remember_me: "Recordarme",
    repeat_password: "Repetir senha",
    report_label: "Marcar como suspeito",
    report_label_plural: "Marcar como suspeitos",
    edit_report_label: "Salvar alterações",
    report_article_error:
      "Sentimos muito mas não podemos enviar a denuncia do artigo. Por favor, tente novamente.",
    report_article_undo_error:
      "Sentimos muito mas não podemos desfazer. Por favor, tente novamente.",
    report_code: "Motivo de denúncia",
    report_code_2: "Motivo de Denuncia",
    report_description_label: "Outros comentários",
    report_description: "Descrição da denuncia",
    report_description_2: "Descrição Complementaria de Denuncia",
    report_placeholder:
      "Informe aqui suas impressões sobre o anúncio que você está prestes a reportar (opcional).",
    report_to_marketplace: "Reportar a Portal",
    report_status_takedown_submitted: "Takedown Submitted",
    report_status_listing_removed: "Listing Removed",
    report_status_takedown_failed: "Takedown Failed",
    reported_article: "Artigo denunciado",
    reported_to_site: "Reportado ao Portal",
    reports: "Reportes",
    reports_uppercase: "REPORTES",
    reported_by: "Reportado por",
    reputation_excelent: "Excelente",
    reputation_bad: "Ruim",
    reputation_good: "Boa",
    reputation_regular: "Regular",
    reputation_unknown: "Desconhecida",
    required_words: "Palavras requeridas",
    results_per_page: "Resultados por página",
    screenshoot: "Screenshot al momento de Denuncia",
    search_of_black_sellers_created:
      "Livesearch de todos os Vendedores Observados criada",
    searches: "Buscas",
    searches_uppercase: "BUSCAS",
    selected_articles: "Publicações Selecionadas",
    seller: "Vendedor",
    seller_blacklist: "Observar vendedor",
    black_seller: "Vendedor observado",
    seller_search_create: "Criar livesearch do vendedor",
    seller_search_created: "Livesearch do Vendedor criada",
    seller_white: "Vendedor confiavel",
    unset_seller_error:
      "Não foi possível atualizar as informações do vendedor. Tente novamente mais tarde",
    sellers: "Vendedores",
    sellers_black: "Vendedores observados",
    sellers_white: "Vendedores confiavels",
    send_me_reset_password_instructions:
      "Enviar instruções para resetear a senha",
    sending_report: "Enviando denuncia",
    settings: "Configuração",
    profile: "Perfil",
    show_current_listing: "Mostrar Livesearch do Vendedor",
    enter: "Entrar",
    entering: "Entrando",
    sign_in: "Iniciar sessão",
    signing_in: "Sessão inicial",
    sign_up: "Registrarse",
    site: "sitio",
    site_reputation: "Reputação",
    hello: "Oi",
    not_found: "Parece que esta página não existe",
    initial_page: "Ir para a home page",
    authorize_sites: "Vincular paises",
    authorized_site_message:
      "Vincule todas as contas que você usa no Brand Protection Program para denunciar em todos os países de uma vez. Basta digitar o usuário e senha de cada país.",
    authorized_site_title:
      "Notamos que você tem direitos para denunciar em mais países",
    authorized: "Autorizado",
    "new-authorize": "Novo",
    authorized_country: "Pronto! Você vinculou a conta",
    link_other_countries: "Vincular outros países",
    linked: "Vinculado",
    link: "Vincular",
    authorize_site_error:
      "ocorreu um erro ao autorizar o site, tente novamente mais tarde",
    authorize_site_error_not_pppi:
      "O usuário com quem você está tentando se autenticar não tem permissão para gerar um relatório, tente desatracar-se no Mercado Livre e tente novamente com o usuário correto.",
    incorrect_site_authentication:
      "Você tentou efetuar login com um usuário que não pertence ao país selecionado. Deve estar logado no Mercado Livre daquele país e tentar novamente com um usuário correspondente.",
    authorize_different_account:
      "Você tentou fazer login com um usuário que não pertence à sua conta.",
    go_to_main_page: "Começar a denunciar",
    start_reporting: "Começar a denunciar",
    sold: "ventas",
    sold_quantity: "vendidos",
    sort_by: "Ordenar resultados por",
    start_live_search: "Processar e buscar",
    "dont-repeat-livesearch": "Não pode haver livesearch repetidas",
    status: "Status",
    adding_reported_articles: "Adicionando Anúncios Suspeitos...",
    add_reported_articles_by_xlsx: "Adicionar Anúncios Suspeitos do Excel",
    submit_all_reported_articles: "Enviar todas as denúncias",
    submit_selected_reported_articles_s: "Enviar {10} denúncia",
    submit_selected_reported_articles_p: "Enviar {10} denúncias",
    submit_all_reported_articles_tooltip_pending_cn:
      "Você não pode enviar denúncias enquanto não revisar suas respostas pendentes.",
    submit_all_reported_articles_tooltip_restricted:
      "Restringimos suas denúncias enquanto analisamos se elas correspondem aos seus direitos aderidos.",
    submit_all_reported_ready: "Pronto! Enviamos suas denúncias.",
    submit_one_reported_ready: "Pronto! Enviamos suas denúncia.",
    filter_text: "Filtrar e ordenar",
    filter_text_2: "Filtrar",
    filter_modal: {
      filter_1: "Ordenar por",
      filter_2: "Motivo da denúncia",
      filter_3: "País",
      filter_4: "Por Estado",
      apply: "Aplicar filtros",
      apply_2: "Aplicar",
      clean: "Limpar filtros",
      filter_1_text_1: "Relatórios mais recentes",
      filter_1_text_2: "Relatórios mais antigos",
      all_reasons: "Todos os motivos de denúncia",
    },
    search_text: "Buscar por # ou título",
    select_all: "Selecionar todos",
    selected_notices_s: "Tienes {10} denuncia seleccionada",
    selected_notices_p: "Tienes {10} denuncias seleccionadas",
    select_page: "Selecionar esta página",
    unselect_all: "Desmarcar todos",
    unselect_page: "Desmarcar esta página",
    clean_all_inactive_btn: "Limpar inativas",
    clean_one_inactive_btn: "Limpar inativa",
    clean_notices: "Excluir denúncias",
    clean_selected: "Excluir denúncias selecionadas",
    filters_applied: "Filtros aplicados",
    clean_all: "Excluir todas as denúncias",
    clean_inactive: "Excluir denúncias inativas",
    clean_all_inactive_ready: "Pronto! Excluímos suas denúncias.",
    clean_one_inactive_ready: "Pronto! Excluímos sua denúncia.",
    submit_report: "Enviar denúncia",
    edit_report: "Editar denúncia",
    submit_reported_articles: "Enviar denuncias",
    create_live_search_current_listings:
      "Criar Livesearch dos vendedores observados",
    questions_and_answers: "PERGUNTAS E RESPOSTAS",
    fetch_questions_and_answers_error:
      "Ocorreu um erro ao obter as perguntas e respostas.",
    support_form: {
      title: "suporte",
      subtitle:
        "Aqui, ajudaremos você a tirar todas as suas dúvidas sobre o Brand Protection Program.",
      textarea_label: "Digite sua pergunta",
      textarea_placeholder: "Com o que podemos ajudar?",
      send_to: "Nós responderemos para",
      submit: "Enviar pergunta",
      success_modal_title: "Já recebemos a sua mensagem!",
      success_modal_will_respond:
        "Responderemos por e-mail dentro das próximas 48 horas.",
      success_modal_cx_number: "O número da sua pergunta é ",
      success_modal_understood: "Entendi",
      error_message:
        "Não foi possível enviar a sua mensagem. Tente mais tarde.",
    },
    support: {
      title: "Seção Ajuda",
      contents: "Seção Ajuda",
      video: "https://www.youtube.com/embed/IZMQdZFFsGY",
      "contents-steps": {
        "sug-1-1": "Anúncios sugeridos",
        "sug-1-2": "Estatísticas",
        "sug-1-3": "Evolução semanal",
        "sug-1-4": "Marcar item",
        "1-1": "Realizar uma busca",
        "1-2": "Como realizar uma busca",
        "1-3": "Filtros de busca",
        "1-4": "Detalhes do anúncio",
        "2-1": "Avaliar um anúncio",
        "2-2": "Validar um anúncio como suspeito",
        "3-1": "Seleção múltipla",
        "3-2": "Como suspeito",
        "3-3": "Como confiável",
        "4-1": "Anúncios suspeitos a denunciar",
        "4-2": "Gerenciamento de artigos a denunciar",
        "8-1": "Anúncios do catálogo",
        "8-2": "O que é um anúncio de catálogo?",
        "8-3": "Como conhecer os vendedores participantes",
        "8-4": "Que dados o vendedor define",
        "8-5": "Quem responde as perguntas dos compradores",
        "8-6": "Como denunciar um anúncio de catálogo",
        "5-1": "Avaliar vendedores",
        "5-2": "Como avaliar vendedores",
        "5-3": "Como ver todos os vendedores confiáveis ou observados",
        "6-1": "Gerenciamento de denúncias",
        "6-2": "Denúncias pendentes",
        "7-1": "Adicionar novos direitos",
        "7-2": "Como adicionar novos direitos",
      },
      "answer-steps": {
        "sug-1-1":
          "Nesta seção, você verá os anúncios que encontramos com base nos nossos diagnósticos e no seu histórico de denúncias.",
        "sug-1-image": "01_POR_captura_Suger.png",
        "sug-2-1":
          "O objetivo deste complemento é deixar os anúncios suspeitos visíveis e prontos para denunciar.",
        "sug-2-2a": "O botão ",
        "sug-2-2b": "'Denunciar' ",
        "sug-2-2c": "direciona para ",
        "sug-2-2d": "'Anúncios suspeitos para denunciar'",
        "sug-3-1":
          "Aqui, você encontra um breve resumo do seu trabalho cumprido e poderá visualizar sua evolução na sua última semana.",
        "sug-3-2":
          "Os valores que também aparecem são o número de itens marcados como suspeitos e confiáveis na última semana. Lembre-se de que esses valores são retirados apenas dos itens sugeridos pelo Mercado Livre.",
        "sug-3-3":
          "O gráfico mostra o fluxo das denúncias diárias na última semana. Ao passar o mouse sobre o gráfico, você verá o valor da data específica selecionada.",
        "sug-3-4":
          "O percentual no marcador verde será exibido se a seu número de denúncias aumentar nessa seção e em vermelho caso tenha diminuído em comparação à semana anterior.",
        "sug-2-image": "02_POR_captura_Suger.png",
        "sug-3-2a": "Esta função permite que você ",
        "sug-3-2b": "marque elementos nos primeiros resultados ",
        "sug-3-2c":
          "para posteriormente acioná-los ou não perdê-los de vista. Além disso, permite fazer uma varredura rápida do que sugerimos e acumulá-los para depois focar na análise com mais critério.",
        "sug-3-image": "03_POR_captura_Suger.png",
        "1-1p1a": "Nesta seção você poderá realizar buscas de anúncios por ",
        "1-1p1b": "país* ",
        "1-1p1c": "usando diferentes ",
        "1-1p1d": "critérios de busca: ",
        "1-1p2t": "- Nome do anúncio",
        "1-1p2":
          "Busca usando palavras-chave relacionadas à sua marca ou direitos adicionados.",
        "1-1p3t": "- ID do anúncio",
        "1-1p3":
          "É o número que identifica de forma única cada anúncio no Mercado Livre.",
        "1-1p4t": "- Nome do vendedor",
        "1-1p4": "É o apelido do vendedor dentro do Mercado Livre.",
        "1-1p5t": "- URL do anúncio",
        "1-1p5": "É o link direto para um anúncio.",
        "1-1-clarification":
          "* Lembre-se de que você pode fazer buscas somente naqueles países que você tenha adicionado.",
        "1-1-image": "04_POR_captura_Suger.png",
        "1-2-1p1a": "No menu à esquerda, selecione ",
        "1-2-1p1b": "Buscar.",
        "1-2-2":
          "Selecione o critério de busca que vai usar e preencha o campo de busca.",
        "1-2-3a": "Indique o país no qual deseja buscar os anúncios. ",
        "1-2-3b": "Argentina ",
        "1-2-3c":
          "está configurada como padrão, mas você pode trocar de país quando quiser.",
        "1-2-4a": "Inicie a busca clicando em ",
        "1-2-4b": "Buscar.",
        "1-3a":
          "Uma vez dentro da lista de anúncios, você pode alterar os filtros de busca para refinar os resultados. Para isso, clique no ",
        "1-3b": "ícone de flecha ",
        "1-3c":
          "na parte superior esquerda e uma lista aparecerá com todos os filtros disponíveis.",
        "1-2-image": "05_POR_captura_Suger.png",
        "1-3-1-title": "Preço",
        "1-3-1-text":
          "Define a faixa de busca por preço mínimo e máximo dos produtos.",
        "1-3-2-title": "Condição",
        "1-3-2-text": "Se é um produto novo ou usado.",
        "1-3-3-title": "Categorias",
        "1-3-3-text":
          "Permite filtrar conforme as categorias de anúncios dentro do site.",
        "1-3-4-title": "Vendedores observados",
        "1-3-4-text":
          "Aplica a busca sobre todos os vendedores marcados como observados.",
        "1-3-7a": "Após selecionar os filtros, clique em ",
        "1-3-7b": "Aplicar e buscar.",
        "1-3-8":
          "* Lembre-se de que você pode fazer buscas somente naqueles países que você tenha adicionado.",
        "1-4":
          "Na tela de resultados, clique em um produto para ver seus detalhes. Lá você poderá ver as informações do anúncio, os dados e a reputação do vendedor, assim como o preço e as fotos do produto.",
        "1-3-image": "06_POR_captura_Suger.png",
        "2-1": "Validar um anúncio",
        "2-1-1": "A partir da lista de anúncios",
        "2-1-2a":
          "Passe o mouse sobre os anúncios. Clique nos botões SUSPEITO e CONFIÁVEL para validá-los.",
        "2-1-image": "07_POR_captura_Suger.png",
        "2-1-2b":
          "Se escolher o botão CONFIÁVEL, os anúncios desaparecerão dos resultados de busca automaticamente. Se escolher o botão SUSPEITO, poderá 'marcá-lo como suspeito' e colocar o motivo.",
        "2-2-image": "08_POR_captura_Suger.png",
        "2-2-1": "A partir dos detalhes do anúncio",
        "2-2-1a":
          "A partir daí, você poderá indicar com o botão CONFIÁVEL ou SUSPEITO se considera que esteja ou não violando alguns dos seus direitos adicionados.",
        "2-3-image": "09_POR_captura_Suger.png",
        "2-2-1b":
          "Todos os produtos que você já tenha validado como CONFIÁVEIS ou SUSPEITOS serão automaticamente excluídos dos seus próximos resultados de busca.",
        "2-3-1": "Validar um anúncio como suspeito",
        "2-3-2":
          "Se você acha que um artigo esteja violando um ou mais dos seus direitos, clique no botão SUSPEITO.",
        "2-3-3":
          "No menu à direita, junto aos detalhes do anúncio, você poderá marcar o motivo da denúncia do anúncio.",
        "2-3-4": "Os motivos são os seguintes.",
        "2-3-4a": "No produto:",
        "2-3-4a-1": "Produto falsificado",
        "2-3-4a-2": "Violação de direitos autorais",
        "2-3-4b": "No anúncio:",
        "2-3-4b-1": "Uso ilegal de marca registrada",
        "2-3-4b-2": "Uso não autorizado de imagens de terceiros",
        "2-3-4b-3": "Uso não autorizado de imagens pessoais",
        "2-4-1": "Uma vez que o motivo for selecionado e clicar em ",
        "2-4-2": "'Continuar'",
        "2-4-3":
          ", você poderá incluir um comentário. Depois, clique em 'Marcar como suspeito'.",
        "2-4-image": "10_POR_captura_Suger.png",
        "2-5-1":
          "Para enviar a denúncia sobre esse anúncio, entre na seção de 'Anúncios suspeitos a denunciar' no menu à esquerda.",
        "3-1-1": "Este complemento te permite marcar vários anúncios como ",
        "3-1-2": "'Confiáveis' ou 'Suspeitos' ",
        "3-1-3":
          "por vez, de forma ágil e rápida. Lembre-se de que você poderá marcar esses anúncios como suspeitos somente se eles compartilharem o motivo da denúncia.",
        "3-1-image": "11_POR_captura_Suger.png",
        "3-2-1": "Como marcar vários anúncios como 'Suspeitos'",
        "3-2-2":
          "Clique no botão 'Seleção múltipla' e marque como 'suspeitos' todos os anúncios que queira selecionar e verá que os anúncios marcados serão destacados em vermelho.",
        "3-2-3":
          "Toque no botão 'Aplicar' para marcá-los como suspeitos e continuar a denúncia.",
        "3-2-image": "12_POR_captura_Suger.png",
        "3-3-1": "Como marcar vários anúncios como 'Confiáveis'",
        "3-3-2":
          "Clique no botão 'Seleção múltipla' e marque como 'confiáveis' todos os anúncios que queira selecionar e verá que os anúncios marcados serão destacados em verde.",
        "3-3-3":
          "Toque no botão 'Aplicar' para que desapareçam da lista de busca.",
        "3-3-image": "13_POR_captura_Suger.png",
        "4-1-1": "Gerenciamento de artigos a denunciar",
        "4-1-2":
          "No menu à esquerda, na seção 'Anúncios suspeitos a denunciar', você encontrará todos os anúncios que marcou como possíveis violações, mas que ainda não denunciou.",
        "4-1-image": "14_POR_captura_Suger.png",
        "4-2-1": "Confirmar denúncia",
        "4-2-2a":
          "Se tiver certeza de que quer denunciar o anúncio, clique em ",
        "4-2-2b": "'Enviar denúncia'. ",
        "4-2-2c":
          "Para enviar todas as denúncias ao mesmo tempo, você pode clicar em ",
        "4-2-2d": "'Enviar todas as denúncias'.",
        "4-3-1": "Cancelar denúncia",
        "4-3-2": "Se quiser cancelar a denúncia, clique em ",
        "4-3-3": "'Ignorar'",
        "4-4-1": "Verificar detalhes",
        "4-4-2":
          "Se quiser verificar os detalhes da denúncia, clique no ícone  '+'.",
        "4-5-1": "Editar motivo da denúncia",
        "4-5-2a": "Se quiser alterar o motivo da denúncia, clique no botão ",
        "4-5-2b": "'Editar'",
        "4-5-2c": "e siga as etapas para incluir o novo motivo.",
        "5-1-1":
          "Nesta seção, você pode ver todos os vendedores que colocou em observação e aqueles que você considera confiáveis.",
        "5-1-image": "15_POR_captura_Suger.png",
        "5-2-1": "Como avaliar vendedores",
        "5-2-2":
          "Ao visualizar os detalhes de um anúncio, você verá três botões que permitem avaliar vendedores.",
        "5-3-1": "Vendedor observado",
        "5-3-2":
          "O vendedor receberá uma bandeirinha preta. Assim que identificar um vendedor como em observação, os produtos dele aparecerão destacados em suas buscas para que você possa identificá-los com mais facilidade.",
        "5-2-image": "16_POR_captura_Suger.png",
        "5-4-1": "Vendedor confiável",
        "5-4-2":
          "Quando você marca o vendedor como confiável, ele será automaticamente incluído na lista de vendedores confiáveis e os anúncios dele serão excluídos dos resultados de busca.",
        "5-5-1": "Criar livesearch do vendedor",
        "5-5-2a": "Crie uma ",
        "5-5-2b": "'Busca salva' ",
        "5-5-2c":
          "do vendedor e da categoria do produto, para que possa verificá-la mais tarde. Você pode acessá-la em Buscas, no menu à esquerda.",
        "5-6-1": "Vendedores observados",
        "5-6-2":
          "Nos detalhes do anúncio, clique no ícone de lupa ao lado do nome do vendedor. Assim, você verá todos os vendedores que marcou sob observação em suas buscas.",
        "5-6-3a": "Você pode acessar esta lista no menu à esquerda em ",
        "5-6-3b": "Vendedores > Vendedores observados.",
        "5-3-image": "17_POR_captura_Suger.png",
        "5-6-4":
          "O quadro mostra o país em que cada vendedor observado opera, assim como o seu nome de usuário no Mercado Livre, a quantidade de relatórios recebidos e a quantidade de produtos em sua lista de vendas.",
        "5-7-1": "Como revisar todos os artigos de vendedores observados",
        "5-7-2a": "Primeiro, clique em ",
        "5-7-2b": "'Criar uma nova livesearch' ",
        "5-7-2c": "dentro do quadro. Essa busca será salva na seção 'Buscas'.",
        "5-4-image": "18_POR_captura_Suger.png",
        "5-8-1": "Vendedores confiáveis",
        "5-8-2":
          "Aqui você verá todos os vendedores que marcou como confiáveis em suas buscas.",
        "5-8-3a": "Você pode acessar pelo menu à esquerda, em ",
        "5-8-3b": "Vendedores > Vendedores confiáveis.",
        "5-5-image": "19_POR_captura_Suger.png",
        "5-8-4":
          "O quadro mostra o país em que o vendedor opera, seu nome de usuário no Mercado Livre e a quantidade de produtos em sua lista de vendas.",
        "5-9-1": "Como verificar todos os artigos de vendedores observados",
        "5-9-2a": "Primeiro, clique em ",
        "5-9-2b": "'Criar uma nova livesearch' ",
        "5-9-2c": "dentro do quadro. Essa busca será salva na seção 'Buscas'.",
        "6-1-1":
          "Aqui você poderá acompanhar o status das suas denúncias e responder aos vendedores para decidir se quer confirmar ou retratar sua denúncia.",
        "6-1-image": "20_POR_captura_Suger.png",
        "6-2-1":
          "Exibe um quadro com todas as denúncias abertas. Clique em qualquer denúncia para ver os detalhes.",
        "6-2-2-title": "Resposta do vendedor",
        "6-2-2-text":
          "Exibe a data em que o vendedor enviou a resposta para sua denúncia, junto com as notas e arquivos anexos.",
        "6-2-3-title": "Denúncia",
        "6-2-3-text":
          "Exibe a data em que a denúncia do anúncio foi feita, o nome do auditor e o código único da denúncia.",
        "6-2-4-title": "Anúncio",
        "6-2-4-text":
          "É uma captura do anúncio tal e qual visualizado no dia em que a denúncia foi feita.",
        "6-2-5-title": "Dados do vendedor",
        "6-2-5-text":
          "Aqui você verá as informações do vendedor que anunciou o produto que você denunciou, como sua reputação no Mercado Livre, quantidade de denúncias enviadas, respostas recebidas e aceitas.",
        "6-2-6":
          "Se considerar a resposta suficiente e quiser excluir a sua denúncia, selecione o ícone de polegar para cima, mas se você considerar que a resposta não é suficiente, selecione o ícone de polegar para baixo.",
        "6-3-1": "Resposta do vendedor",
        "6-3-2a": "Nesta parte, você terá a chance de ",
        "6-3-2b": "'Confirmar ou recusar a denúncia' ",
        "6-3-2c": "e deixar um comentário para enviar ao vendedor.",
        "6-4-1":
          "Caso decida recusar a denúncia, você deve selecionar o motivo.",
        "6-2-image": "21_POR_captura_Suger.png",
        "7-2-1":
          "Se precisar incluir novos direitos para poder realizar mais e melhores denúncias, ",
        "7-2-1b": "clique aqui.",
        "7-2-2-title": "Na tela para adicionar direitos",
        "7-2-2-text":
          "Selecione a caixa de verificação do direito que deseja adicionar. Se precisar adicionar mais de um, pode selecionar múltiplas opções.",
        "7-2-3":
          "Para cada tipo de direito que você selecionar, um quadrado branco aparecerá na parte inferior da tela. Para cada caso anexe a documentação solicitada.",
        "7-2-4": "Ao terminar, clique no botão ",
        "7-2-5": "Enviar documentação.",
        "7-2-6":
          "Pronto! Nossos representantes vão analisar a sua documentação e notificar por e-mail sobre o status da sua solicitação.",
        "8-1-1": "Anúncios do catálogo",
        "8-2-1": "O que é um anúncio de catálogo?",
        "8-2-2":
          "Trata-se de uma 'página de produto' que agrupa, em um único conteúdo, vários anúncios oferecidos por diferentes vendedores.",
        "8-2-3":
          "Os anúncios dos vendedores competem pela primeira opção que os compradores veem. Quem oferecer as melhores condições de venda e experiência será o 'Ganhador'. A concorrência se baseia, entre outras coisas, no preço, na reputação do vendedor e nas condições de venda oferecidas.",
        "8-3-1": "Como conhecer os vendedores participantes",
        "8-3-2":
          "Observe que as 'páginas de produto' contêm variações. Por exemplo, para um celular, são apresentadas diferentes funções e cores disponíveis. Para cada combinação de variações, pode haver um ganhador diferente.",
        "8-3-3":
          "Ao encontrar um anúncio de catálogo na ferramenta de denúncias, você pode ir para os detalhes do anúncio para ver os vendedores participantes para cada variação.",
        "8-3-image": "22_POR_captura_Suger.png",
        "8-4-1": "Que dados o vendedor define",
        "8-4-2":
          "O vendedor configura as condições específicas do anúncio que participa do 'Catálogo'. Isso inclui preço, estoque, garantia e forma de envio.",
        "8-5-image": "23_POR_captura_Suger.png",
        "8-5-1": "Quem responde as perguntas dos compradores",
        "8-5-2":
          "Em um anúncio de catálogo, as respostas às perguntas dos compradores são dadas pelo vendedor ganhador do momento.",
        "8-5-3":
          "Na coluna 'Vendedores participantes', você pode ver as respostas dadas por cada vendedor. Basta selecioná-las.",
        "8-6-1": "Saiba mais sobre os Anúncios de catálogo",
        "8-7-1": "Como denunciar um anúncio de catálogo",
        "8-7-1-a": "Para denunciar as fotos do anúncio,",
        "8-7-1-b":
          " selecione a opção 'Marcar como suspeito' na seção que mostra os detalhes do produto.",
        "8-7-image": "24_POR_captura_Suger.png",
        "8-7-2-a": "Para denunciar o anúncio de um vendedor,",
        "8-7-2-b":
          "verifique a coluna 'Vendedores participantes' e clique no botão 'Marcar como suspeito'",
        "8-7-2-image": "25_POR_captura_Suger.png",
        "8-8-1":
          "Os anúncios que você marcar como suspeitos vão aparecer na seção ",
        "8-8-1-a": "'Denunciar anúncios suspeitos'",
        "8-8-1-b": "e você poderá gerenciá-las como de costume.",
      },
      "question-livesearch": "O que é uma Livesearch?",
      "answer-livesearch":
        "Livesearch é a busca de anúncios a partir de uma ou mais palavras-chave. Você pode fazer suas buscas por país ou em vários países de uma vez, sempre que estiver autorizado para denunciar em mais de um país. É importante que as palavras usadas estejam relacionadas à marca que você está auditando, assim, você pode obter resultados muito mais precisos.",
      steps: {
        "1-1": "No menu esquerdo, selecione",
        "1-2": "Buscas",
        "2-1": "Digite as palavras-chave e clique em",
        "2-2": "Buscar",
        "3-1": "Indique os países que quiser e selecione",
        "3-2": "Processar Busca",
        "4-1":
          "Quando o processo da livesearch iniciar, você verá a quantidade de anúncios que existem para as palavras-chave que você digitou. Ao clicar na(s) palavra(s)-chave que está(ão) na pesquisa, você poderá analisar os anúncios.  Você pode começar a auditar mesmo que a busca ainda esteja em andamento.",
      },
      "question-grid": "Grade de resultados",
      "answer-grid":
        "Na visão de grade de resultados, você encontrará todos os anúncios que correspondem às palavras-chave que você usou na sua Livesearch.",
      "question-filter-liversearch": "Filtros da livesearch",
      "answer-filter-liversearch1":
        "Depois de obter os resultados, você pode ajustar a busca e torná-la mais precisa usando os filtros. Você pode usá-los um a um ou combiná-los como preferir.",
      "answer-filter-liversearch2":
        "Você pode concentrar sua pesquisa em apenas uma linha de produtos da sua marca ou filtrar por data de anúncio, preço, estoque etc. Da mesma forma, você pode excluir palavras dos resultados.",
      "question-publication": "Visão detalhada do anúncio",
      "answer-publication":
        "Para acessar a visão detalhada, você deve clicar em um anúncio na grade de resultados. Na visão detalhada você terá mais informações sobre o item oferecido, suas fotos, informações do vendedor e análise de estoque e vendas atuais.",
      "question-publication-valid": "Polegar para cima",
      "answer-publication-valid":
        "Se você vê que um anúncio não está cometendo nenhuma infração à sua marca, indique, com o polegar para cima, que o anúncio é válido. Desta forma, em futuras buscas, esse anúncio não aparecerá novamente nos seus resultados.",
      "question-publication-invalid": "Polegar para baixo",
      "answer-publication-invalid1":
        "Se você vê que um anúncio está cometendo uma ou mais infrações de propriedade intelectual, você deve marcá-lo com o polegar para baixo. Em seguida, uma janela abrirá, onde você poderá adicionar mais detalhes sobre as infrações que detectou.",
      "answer-publication-invalid2": "Etiquetas",
      "answer-publication-invalid2-1":
        "Ao clicar em denunciar, a denúncia ainda não será processada e enviada para a equipe de revisão do Brand Protection Program. Ela será enviada para Produtos para denunciar, junto com as outras denúncias que você tenha feito. Portanto, se você identificar que uma denúncia não está correta, pode excluí-la antes que seja enviada.",
      "answer-publication-invalid3": "Código da denúncia",
      "answer-publication-invalid3-1":
        "É o código associado ao motivo da sua denúncia. Está relacionado aos direitos que você adicionou ao Brand Protection Program para denunciar no Mercado Livre.",
      "answer-publication-invalid4": "Detalhes",
      "answer-publication-invalid4-1":
        "Você pode adicionar detalhes do motivo da denúncia que você está prestes a fazer. Isso ajudará, caso algum membro da equipe precise de mais informações antes de processar uma exclusão.",
      "answer-publication-invalid5": "Denunciar",
      "answer-publication-invalid5-1":
        "Ao clicar em denunciar, a denúncia ainda não será processada e enviada para a equipe de revisão do Brand Protection Program. Ela será enviada para Produtos para denunciar, junto com as outras denúncias que você tenha feito. Portanto, se você identificar que uma denúncia não está correta, pode excluí-la antes que seja enviada.",
      "answer-publication-invalid6": "Produtos para denunciar",
      "answer-publication-invalid6-1":
        "Aqui estarão todos os anúncios que você marcou com o polegar para baixo. Neste caso, é onde você irá denunciar os anúncios. Portanto, é aconselhável rever todos os detalhes que você preencheu, para se certificar das infrações. Se você quiser, também pode visitar o anúncio no Mercado Livre, clicando no botão seguinte.",
      "answer-publication-invalid6-2":
        "Clicando em (+), poderá ver os detalhes da sua denúncia.",
      "answer-publication-invalid6-3":
        "Se você vê que um anúncio não está cometendo infração, pode selecionar Excluir da lista e a denúncia não será feita.",
      "answer-publication-invalid6-4":
        "Quando tiver certeza de que deseja enviar as denúncias, você pode enviá-las individualmente ou em massa, selecionando Enviar todas as denúncias.",
      "question-whitelistsellers": "Vendedores confiáveis",
      "answer-whitelistsellers1":
        "Você pode identificar vendedores como confiáveis (por exemplo, Lojas Oficiais, distribuidores etc.) para que seus anúncios não apareçam mais na livesearch e, desta forma, você não perca tempo auditando e analisando eles.",
      "answer-whitelistsellers2":
        "Para identificar um vendedor, você deve abrir a visão detalhada e clicar na ",
      "answer-whitelistsellers3": " de Vendedor confiável.",
      "question-blacklistsellers": "Vendedores observados",
      "answer-blacklistsellers1":
        "Se você achar necessário acompanhar alguns vendedores e todos os seus anúncios, basta selecionar Observar vendedor.",
      "answer-blacklistsellers2":
        "Todos os anúncios desse vendedor estarão marcados com a bandeira preta. Você também poderá filtrar os resultados para que mostrem somente anúncios de vendedores observados.",
      "answer-blacklistsellers3-1":
        "Você pode ver a atividade dos anúncios e denúncias desses vendedores no menu à esquerda, na seção ",
      "answer-blacklistsellers3-2": "Vendedores > Vendedores Observados",
      "question-livesearch-sellers": "Livesearches de vendedores",
      "answer-livesearch-sellers-1":
        "A partir da visão detalhada, na seção de Vendedores observados ou de Vendedores confiáveis, você pode criar uma livesearch para observar todos os anúncios desses vendedores.",
      "answer-livesearch-sellers-2":
        "Quando você identifica pelo menos um anúncio que viola os direitos da sua marca, é aconselhável analisar todos os anúncios do vendedor para verificar se há mais que poderiam afetar sua marca.",
      "question-post-denunciation": "Acompanhamento pós-denúncia",
      "answer-post-denunciation1-1":
        "Você poderá gerar relatórios de atividade e exportá-los como arquivo Excel. Você poderá acompanhar todo o trabalho de auditoria.",
      "answer-post-denunciation1-2":
        "Além disso, você poderá ver todos os anúncios denunciados que foram excluídos. Na hora de fazer a denúncia, você poderá ver uma foto de referência do anúncio.",
      "question-counter-notice": "Contranotificação",
      "answer-counter-notice1":
        "Se um vendedor fizer uma contranotificação de uma denúncia, ela chegará no e-mail que você informou como e-mail do contato ao cadastrar seus direitos no Brand Protection Program. Este e-mail corresponde ao associado às contas de denúncia do Brand Protection Program em cada país.",
      "answer-counter-notice2":
        "Lembre-se de que esse e-mail é diferente daquele que você cadastrou como auditor para denunciar nesta plataforma.",
      disclaimer:
        "*As imagens são mostradas como exemplo para explicar o uso da ferramenta.",
      "question-support": "SUPORTE",
      "see-more": "Dúvidas? Nós podemos ajudar.",
      "goto-support": "Acesse a seção de Ajuda",
    },
    black_sellers: {
      ID: "ID",
      title: "Últimos itens denunciados",
      placeholder_sites: "SELECIONAR SITES",
      table_th_site: "SITIO",
      table_th_country: "PAÍS",
      table_th_sellers: "VENDEDOR",
      table_th_reports: "REPORTES",
      table_th_actions: "AÇÕES",
      table_th_current_listings: "ANÚNCIOS ATUAIS",
      empty_sellers_message: "Nenhum Sellers Observados disponíveis.",
      button_create_current_listings: "Criar Livesearch com anúncios atuais",
      button_delete_current_listings: "Excluir",
      confirm_delete_client_black_seller:
        "Tem certeza que quer eliminar esse Vendedor da lista de Vendedores observável?",
      black_list_seller_deleted: "vendedor removido",
      black_list_seller_delete_error: "Houve um erro ao remover o vendedor",
      fetch_black_list_seller_error:
        "Desculpe, houve um erro na pesquisa de vendedores",
    },
    case_management: {
      cases: {
        pending_title: "Denúncias a responder",
        pending_table_explanation:
          "Os seguintes vendedores enviaram um Counter-Notice em suas solicitações para baixa de publicação",
        search: "Pesquisar",
        table_th_auditor: "Auditor",
        table_th_country: "País",
        table_th_id: "ID",
        table_th_article: "Anúncio",
        table_th_sellers: "Vendedor",
        table_th_status: "Status",
        table_th_denounce: "Data de Denúncia",
        table_th_denounce_2: "Data de denúncia:",
        table_th_denounce_sent: "Data de Denúncia",
        table_th_counter_notice: "Data de Resposta",
        table_th_response_limit: "Prazo de resposta",
        empty_cases_message: "Nehum resultado encontrado",
        fetch_pending_cases_list_error:
          "Desculpe, houve um erro na busca de casos",
        denounced_date: "Denunciado: ",
        updated_date: "Atualizado: ",
        canceled_date: "Cancelado: ",
        dates: "Ver datas",
      },
      case_description: {
        denounced_seller: "Vendedor denunciado",
        seller_response: "Isso é o que escreveram",
        seller_empty_response: "O vendedor não deixou comentários",
        photos_current: "Estas são as fotos atuais do anúncio",
        photos_modified_new: "NOVA",
        photos_denounced: "Estas são as fotos que você reportou",
        fetch_description_denounces_error:
          "Lo sentimos, hubo un error al buscar los datos.",
        no_data_found: "Nenhum dado encontrado",
        "counter-notice_tab": "Resposta do vendedor",
        "counter-notice": {
          counter_notice_attached_file: "ARQUIVO ANEXO",
        },
        denounce_tab: "Denúncia",
        denounce: {
          denounce_date: "DATA DA DENÚNCIA",
          auditor: "AUDITOR",
          code: "MOTIVO DA DENÚNCIA",
          additional_description: "NOTAS DO AUDITOR",
        },
        publication_tab: "Anúncio",
        publication: {
          site: "Mercado Livre",
          price: "PREÇO DO ANÚNCIO",
          product_sold_in_this_publication: "PRODUTOS VENDIDOS NESTE ANÚNCIO",
          description: "DESCRIÇÃO",
          images: "imagens",
          image: "image",
        },
        "seller-data_tab": "Dados do Vendedor",
        seller_data: {
          create_seller_LS: "Criar LiveSearch para este Vendedor",
          black_seller: "Vendedor Observado",
          portal_reputation: "Reputação",
          sold_quantity_products: "Quantidade de produtos vendidos",
          denonces_sent_to_this_seller: "Denúncias",
          counter_notice_received: "Respostas enviadas",
          counter_notice_accepted: "Respostas aceitas",
          denounces_table_article: "Anúncio",
          denounces_table_denounce: "Denúncia",
          denounces_table_status: "Estado",
          denounces_counter_notice: "Resposta do Venedor",
          fetch_counter_notices_error:
            "Ocorreu um erro ao receber as reclamações do vendedor",
        },
      },
      documentation_approved: {
        sending_response: "Estamos enviando sua respuesta para o vendedor.",
        response_placeholder: "Revisei a resposta e quero:",
        reject_option_member_placeholder: "Vou confirmá-la porque:",
        approved_case_title: "Resposta para o vendedor",
        approved_case_title_sent: "Resposta",
        confirm: "Confirmar minha denúncia",
        retract: "Retratar minha denúncia",
        confirm_helper_text: "Envie esta resposta para finalizar o anúncio.",
        retract_helper_text: "Envie esta resposta para reativar o anúncio.",
        send_response: "Enviar a resposta",
        reject_option_label: "Motivo da sua decisão",
        description_label: "Comentários adicionais",
        no_description: "Sem descrição",
        report_placeholder:
          "Explique ao vendedor por que você tomou esta decisão.",
        respond_counter_notice_success_confirm: "O anúncio foi excluído.",
        respond_counter_notice_success_retract: "O anúncio foi reativado.",
        respond_counter_notice_success:
          "Pronto! Já enviamos sua resposta ao vendedor.",
        respond_counter_notice_error: "Desculpe, houve um erro.",
        closed_title: "Você já respondeu para este vendedor",
        responded: "Esta foi a sua decisão",
        responded_at: "Você respondeu no dia",
      },
      publication_status: {
        active: "Ativa",
        closed: "Finalizada",
        inactive: "Inativa",
        paused: "Pausada",
        under_review: "Sob revisão",
      },
      denounce_status: {
        waiting: "Aguardando resposta",
        pending: "Respondida",
        accepted: "Anúncio reativado",
        not_approved: "Anúncio finalizado",
        not_presented: "Não respondido",
        not_respond: "Expirada",
        rollback: "Retirada",
        discard_due_restriction: "Descartada",
        pending_moderation: "Pendente",
        rejected: "Recusada",
        acuerdo_closed: "Anúncio finalizado",
        duplicated_denounce: "N/A",
        discarded: "Descartada",
        rejected_modified: "Anúncio modificado",
        revision: "Denúncia em revisão",
      },
      denounce_status_V3: {
        approved: "Aprovada",
        in_progress: "Em curso",
        pending: "Resposta pendente",
        rejected: "Recusada",
        rollback: "Retirada",
        discarded: "Excluida",
      },
      pending_denounce_widget: {
        title: "Denúncias pendentes de resposta.",
        subtitle: "Por favor, revise a resposta do vendedor",
      },
      status_pill: {
        expire_days: "Expira en {} dias",
        expire_day: "Expira en {} dia",
        expire_hours: "Expira en {} horas",
      },
    },
    welcome_page: {
      title: "Bem-vindo!",
      subtitle:
        "Você está a um passo de usar a nova ferramenta de denúncias do Mercado Livre",
      no_auditor_boton: "Quero me cadastrar",
      enter_now_to_denounce: "Já estou cadastrado",
    },
    welcome_register_page: {
      title: "Bem-vindo à ferramenta de denúncias do Brand Protection Program",
      subtitle:
        "Se você já está cadastrado no programa, entre com qualquer dos seus usuários do Brand Protection Program. Se você ainda não possui uma conta, cadastre-se.",
      subtitle2: "Informe o e-mail cadastrado no Brand Protection Program",
      email: "E-mail",
      singin_button: "Entrar",
      continue_button: "Entrar",
      no_account_button: "Fazer cadastro",
      know_more: "Conheça mais sobre o programa",
    },
    error_no_bpp: {
      title: "Não foi possível acessar sua conta",
      subtitlePart1: "Por favor, tente novamente e ",
      subtitlePart2: "certifique-se de usar suas credenciais do BPP.",
      btn: "Tentar novamente",
    },
    reported_articles: {
      alerts: {
        cannot_submit_title: "Você não pode denunciar",
        cannot_submit_subtitle:
          "Identificamos comportamentos abusivos nas suas recusas às respostas dos vendedores, por isso você não pode enviar denúncias temporariamente.",
      },
      filters: {
        placeholder_reference_id: "ID",
        placeholder_title: "Título",
        placeholder_price_from: "Preço mín (USD)",
        placeholder_price_to: "Preço máx (USD)",
        placeholder_term: "Título",
        placeholder_site: "Selecione Sitio",
        placeholder_seller: "Selecione Vendedor",
        placeholder_complaint: "Status",
        placeholder_date_from: "DESDE",
        placeholder_date_to: "até",
        option_selecet_complaint_status_with_error: "Reintentar envío",
        option_selecet_complaint_status_without_error: "Não enviadas",
      },
      articles_reported: {
        title: "Histórico de denúncias",
        description:
          "Confira aqui todas as suas denúncias e gerencie as que estão pendentes de resposta",
        search: "Buscar por ID, ou nome do vendedor",
        filter: "Filtrar e ordenar",
        table_th_seller: "Vendedor",
        table_th_denounce: "Anúncio denunciado",
        table_th_reason: "Motivo da denúncia",
        table_th_status: "Status",
        placeholder_sites: "SELECIONAR SITES",
        table_th_id: "ID",
        table_th_site: "SÍTIO",
        table_th_country: "PAÍS",
        table_th_article: "PUBLICAÇĀO",
        table_th_auditor: "AUDITOR",
        table_th_price: "PREÇO",
        table_th_reported: "RELATADOS",
        table_th_notice_status: "STATUS",
        table_th_notice_update: "ÚLTIMA ATUALIZAÇÃO",
        table_th_down: "DERRUBAR",
        reported_at: "DATA DO RELATÓRIO",
        button_clear_filters: "Limpiar Filter",
        button_submit_filters: "Buscar",
        empty_reported_articles_message:
          "Não há artigos disponíveis relatados.",
      },
      denounce_status_tooltip: {
        WAITING_DOCUMENTATION: "O vendedor ainda não respondeu sua denúncia.",
        DOCUMENTATION_PRESENTED: "O vendedor já respondeu sua denúncia.",
        DOCUMENTATION_APPROVED: "Você decidiu reativar este anúncio.",
        DOCUMENTATION_NOT_APPROVED: "Você decidiu finalizar este anúncio.",
        DOCUMENTATION_NOT_PRESENTED:
          "Finalizamos o anúncio porque o vendedor não respondeu dentro do prazo.",
        MEMBER_NOT_RESPOND:
          "Você não respondeu ao vendedor no prazo e a denúncia expirou.",
        ROLLBACK:
          "Você decidiu reativar o anúncio, por ter retirado a sua denúncia.",
        DISCARDED_DUE_RESTRICTION:
          "O vendedor foi inativado, portanto não é necessário revisar sua resposta.",
        ACUERDO_CLOSED: "Nós finalizamos este anúncio.",
        DUPLICATED_DENOUNCE: "N/A",
        REJECTED: "Sua denúncia foi recusada.",
        DISCARDED: "O anúncio já foi excluído.",
        PENDING_MODERATION: "Sua denúncia ainda não foi aprovada",
        CREATED: "Sua denúncia ainda não foi aprovada",
        CROSS_DENOUNCE_CLOSED: "Sua denúncia foi recusada.",
        REJECTED_MODIFIED:
          "Não foi possível processar sua denúncia porque o anúncio foi modificado logo depois da sua denúncia.",
      },
      denounce_status_tooltipV3: {
        ACUERDO_CLOSED: "O anúncio denunciado foi excluído.",
        DOCUMENTATION_NOT_APPROVED:
          "A documentação do vendedor não foi aprovada. O anúncio denunciado foi excluído.",
        DOCUMENTATION_NOT_PRESENTED:
          "A sua denúncia foi aprovada porque o vendedor não apresentou a documentação.",
        CREATED: "A sua denúncia está pendente de processamento.",
        PENDING_MODERATION: "A sua denúncia está pendente de processamento.",
        WAITING_DOCUMENTATION: "O vendedor ainda não respondeu.",
        WAITING_FOR_PATCH_PDP:
          "Estamos analisando sua denúncia. Se necessário, faremos os ajustes apropriados.",
        DOCUMENTATION_PRESENTED:
          "O vendedor está esperando sua resposta. Caso você não responda, a denúncia será cancelada.",
        DUPLICATED_DENOUNCE:
          "A sua denúncia não pode ser feita por ser duplicada.",
        MEMBER_NOT_RESPOND:
          "A sua denúncia foi recusada porque você não respondeu ao vendedor dentro do prazo definido.",
        REJECTED:
          "A sua denúncia foi recusada por descumprir os requisitos necessários.",
        REJECTED_BY_RESTRICTION:
          "Você excedeu o limite de denúncias por dia. Por favor, verifique a eficiência da sua conta.",
        DOCUMENTATION_APPROVED:
          "A documentação do vendedor foi aprovada e sua denúncia foi retirada.",
        ROLLBACK:
          "Você retirou a denúncia. O anúncio denunciado será reativado.",
        DISCARDED:
          "A sua denúncia não pode ser feita porque o anúncio está indisponível.",
        DISCARDED_DUE_RESTRICTION:
          "A sua denúncia não pode ser feita porque o vendedor está desativado.",
        REJECTED_MODIFIED:
          "A sua denúncia foi excluída porque o anúncio foi alterado.\n\n Saiba mais",
        REJECTED_PDP_CATALOG:
          "A sua denúncia foi excluída porque as imagens denunciadas foram retiradas de um banco de imagens.",
      },
      index: {
        title: "artigos por denunciar",
        table_th_site: "SITIO",
        table_th_country: "PAÍS",
        table_th_article: "PUBLICAÇĀO",
        table_th_auditor: "AUDITOR",
        table_th_price: "PREÇO",
        table_th_antiquity: "REPORTADO HÁ",
        table_th_more: "MAIS",
        table_of_text: "de",
        table_next_text: "Próximo",
        table_rows_text: "linhas",
        table_page_text: "Página",
        table_previous_text: "Anterior",
        table_loading_text: "Carregando...",
        table_no_data_text: "Nenhuma linha encontrada",
        empty_reports_message: "não à artigos para reportar disponiveis.",
        removed_clarification: "Excluído",
        removed_clarification_tooltip:
          "Você não pode denunciar este anúncio, pois ele já foi excluído do site.",
        button_remove: "Excluir",
        removed: "Removido",
        removed_v2: "Anúncio inativo.",
        button_send_all_complaints: "Enviar todas as denúncias",
        button_edit_complaint: "Editar",
        button_send_complaint: "Enviar",
        button_retry_complaint: "Tentar Novamente",
        confirm_reported_article_undo: "Excluir este anúncio?",
        confirm_reported_article_undo_subtitle:
          "Ao fazer isso, você não verá mais $title na sua lista de anúncios suspeitos.",
        confirm_reported_article_undo_btn_label: "Excluir anúncio",
        confirm_send_all_complaints:
          "Você quer mesmo enviar todas as denúncias?",
        complaint_sent: "Denuncia enviada",
        send_complaint:
          "Obrigado por enviar este relatório. Continuaremos a avaliá-lo nas próximas 72 horas",
        send_all_complaints:
          "Obrigado por enviar essas reclamações. Vamos proceder a avaliá-las nas próximas 72hs.",
        error_send_all_complaints: "Houve um erro no envio das denuncias.",
        error_send_complaint: "Houve um erro no envio da denuncia.",
        error_send_complaint_already: "A publicação já foi denunciada.",
        error_getting_article_data: "Ocorreu um erro ao procurar os dados",
        failed_not_exits_or_not_published:
          "O artigo não existe ou não é publicado.",
        days: {
          one: "%{count} día",
          other: "%{count} días",
        },
      },
      edit: {
        seller: "Vendedor",
        tags: "Etiquetas",
        complaint_code: "Codigo de Denuncia",
        placeholder_complaint_description:
          "Informe aqui suas impressões sobre o anúncio que você está prestes a reportar (opcional).",
        label_add_tag: "Adicione suas etiquetas",
        cancel_complaint_edition: "Cancelar",
        edit_complaint_edition: "Editar",
      },
      table: {
        th1: "Publicação",
        th2: "Preço",
        th3: "Vendedor",
        th4: "Motivo da reclamação",
      },
    },
    noticed_articles: {
      filter: "Filtrar",
      filters: {
        all: "Todos os status",
        placeholder_id: "ID",
        placeholder_title: "Titulo",
        placeholder_price_from: "PREÇO MÍN.",
        placeholder_price_to: "PREÇO MÁX.",
        placeholder_date_from: "Data de",
        placeholder_date_to: "Data para",
        placeholder_country: "Pais",
        placeholder_seller: "Vendedor",
        report_created: "relatório gerado",
        report_created_error: "Ocorreu um problema ao gerar o relatório",
        report_no_articles_found:
          "Nenhum artigo foi encontrado para gerar o relatório",
        title_required: "O título é obrigatório",
        date_error: "Data de não pode ser maior que a data para",
        price_error: "Preço desde que não pode ser maior a preço máx.",
      },
      filter: "Filtrar",
    },
    client_white_sellers: {
      index: {
        ID: "ID",
        title: "Vendedores de Confiança",
        empty_sellers_message: "Nenhum vendedor confiável.",
        table_th_site: "SITIO",
        table_th_country: "PAÍS",
        table_th_seller_name: "VENDEDOR",
        table_th_seller_current_listing: "ARTIGOS ATUAIS",
        table_th_actions: "AÇÕES",
        delete_client_white_seller: "Excluir",
        confirm_delete_client_white_seller:
          "Tem certeza que quer eliminar esse Vendedor da lista de Vendedores Confiaveis?",
        white_list_seller_deleted: "vendedor removido",
        white_list_seller_delete_error: "Houve um erro ao remover o vendedor",
        fetch_white_list_seller_error:
          "Desculpe, houve um erro na pesquisa de vendedores",
      },
    },
    white_and_black_sellers: {
      filters: {
        ID: "ID",
        country: "Pais",
      },
    },
    notice_reports: {
      index: {
        title: "Denúncias",
        table_th_date: "data",
        table_th_name: "nome do relatório",
        table_th_download: "baixar",
        fetch_reports_error:
          "Opps... Ocorreu um erro enquanto procurávamos os relatórios, por favor tente mais tarde",
        click_here_to_download: "clique aqui para baixar",
        confirm_delete_report:
          "Tem certeza de que deseja excluir este relatório? Essa ação não pode ser desfeita.",
        delete_report_error: "houve um erro ao eliminar o relatório",
        processing_report: "relatório de processamento",
        generate_error: "Ocorreu um erro ao gerar este relatório",
        emply_error: "Desculpe, não há denúncias com os filtros selecionados",
      },
      create: {
        title: "Novo Relatório",
        create_report: "Criar Relatório",
        placeholder_title: "Titulo",
        placeholder_date_from: "Data de",
        placeholder_date_to: "Data para",
        placeholder_currency: "moeda",
        placeholder_origin: "auditor",
        placeholder_language: "idioma",
      },
    },
    livesearch_reports: {
      index: {
        title: "Denúncias",
        table_th_date: "Data",
        table_th_name: "nome do relatório",
        table_th_download: "baixar",
        fetch_reports_error:
          "Opps... Ocorreu um erro enquanto procurávamos os relatórios, por favor tente mais tarde",
        click_here_to_download: "clique aqui para baixar",
        confirm_delete_report:
          "Tem certeza de que deseja excluir este relatório? Essa ação não pode ser desfeita.",
        delete_report_error: "houve um erro ao eliminar o relatório",
        processing_report: "relatório de processamento",
        generate_error: "Ocorreu um erro ao gerar este relatório",
      },
    },
    sites_authorization: {
      from_account: {
        do_it_later: "Vincular depois",
        finish_and_do_it_later: "Concluir e vincular depois",
      },
      from_search: {
        back_to_search: "Voltar para a pesquisa",
      },
      default: {
        start_reporting: "Começar a denunciar",
      },
    },
    redirect_flow_info: {
      title: "Bem-vindo à nossa ferramenta de denúncias",
      subtitle:
        "Agora, denunciar e responder para os vendedores ficou muito mais rápido. Veja tudo que você pode fazer:",
      bullet1: "Identifique mais rapidamente os vendedores suspeitos",
      bullet2: "Crie seus filtros de busca",
      bullet3: "Acompanhe o status das suas denúncias em um só lugar",
    },
    tags_title: "Etiquetas",
    label_add_tag: "Adicione suas etiquetas",
    total: "Total",
    undo: "Desfazer",
    unknown_seller: "Vendedor desconhecido",
    unlist_seller_from_black_list_confirmation:
      "Tem certeza que quer eliminar esse Vendedor da lista de Vendedores Observados?",
    unlist_seller_from_white_list_confirmation:
      "Tem certeza que quer eliminar esse Vendedor da lista de Vendedores Confiaveis?",
    used_product: "Produto usado",
    validity: "Vigencia",
    view_in_site: "Visualizar no site",
    welcome: "Bem vindo/a",
    go_back: "Voltar",
    edit_reported_article_success:
      "O relatório para a publicação foi atualizado.",
    edit_reported_article_error:
      "Desculpe, ocorreu um erro ao atualizar o relatório.",
    done_article_reported:
      "Pronto! Para gerenciar e enviar suas denúncias, confira a seção",
    done_articles_reported:
      "Pronto! Para gerenciar e enviar suas denúncias, confira a seção",
    go_to_reported_articles: "Anúncios suspeitos.",
    we_will_send_this_listing_to: "Enviaremos este anúncio para",
    we_will_send_these_listing_to: "Enviaremos estes anúncios para",
    got_it: "ENTENDI",
    ls_reported_articles_link: "Ir para Anúncios suspeitos",
    process_and_clean_results: "Processar busca",
    live_searches: "Livesearches",
    unable_to_create_live_search:
      "Não foi possível criar a Liveserch intente novamente.",
    server_error: "Desculpe, ocorreu um erro no servidor, tente novamente",
    fetch_currency_error:
      "Desculpes, tivemos um erro na resposta da troca de moeda",
    remove_article_error: "Desculpe, tivemos um erro ao remover o artigo",
    remove_article_success: "Anúncio excluído.",
    fetch_seller_error: "Desculpe, tivemos um erro na pesquisa do vendedor",
    dashboard: "Dashboard",
    sellers_inactive: "Inactive Sellers",
    tags: "Tags",
    search: "Buscar",
    enter_your_email_below_to_reset_your_password:
      "Ingresse seu e-mail para resetear sua contraseña.",
    error_generating_custom_report:
      "Falha ao gerar resultadosrelatório personalizado.",
    without_results: "Sem resultados!",
    refresh: "Refrescar",
    try_again: "Tente novamente",
    seller_live_search_created: "Livesearch criado",
    create_seller_live_search: "Criar Livesearch com itens atuais",
    error_reporting_seller:
      "--Erro reportando o vendedor. Por favor, tente novamente.",
    error_user_without_report_profile: "--Não existe o Perfil do Reporte.",
    success_send_all_complaints: "--Foram enviadas todas as denuncias.",
    updated_reported_article_to_ok:
      "--Foi atualizado o estado do artigo denunciado para OK.",
    reported_article_not_found: "--Não foi encontrado o artigo reportado.",
    reported_article_already_reported: "--Tarde demais, artigo ja reportado.",
    update_reported_article_complaint_success:
      "--Foi atualizada a denuncia do artigo reportado.",
    update_reported_article_complaint_error:
      "--Não pode ser atualizada a denuncia do artigo reportado, por favor tente novamente.",
    complaint_reason: "Motivo de denúncia",
    complaint_error: "Erro na Denuncia",
    complaint_description: "--Descrição Complementaria da Denuncia",
    invalid_keyword_for_new_live_search:
      "--Deve agregar uma palavra chave para generar uma nova LiveSearch",
    modal_title: "Carregando...",
    response_errors: {
      client_error: "Erro do cliente",
      server_error: "Erro de servidor",
    },
    enroll_rights_cta_tooltip_header:
      "Lembre-se de que, antes de adicionar novos direitos, pediremos que você inicie sessão com seu nome de usuário.",
    enroll_rights_tooltip_content:
      "Não encontrou o motivo da infração na lista? Talvez você precise adicionar direitos.",
    enroll_rights_now: "Adicionar agora",
    report: {
      report_code: {
        PPPI1:
          "O produto oferecido é falsificado e infringe marca registrada (Ex.: Aplicação da minha marca registrada em produto não original, etc.).",
        PPPI2: "O anúncio utiliza marca de maneira ilegítima.",
        PPPI3: "O software oferecido é pirata/ilegal.",
        PPPI4:
          "O produto oferecido reproduz direito autoral sem autorização (Ex.: Reprodução de obras plásticas, fonogramas, audiovisuais e livros, etc.).",
        PPPI5:
          "O anúncio reproduz sem autorização, textos originais dos quais sou autor.",
        PPPI6:
          "O anúncio reproduz sem autorização, imagens originais (Ex.: fotografias, desenhos, pinturas, vídeos, etc.).",
        PPPI7:
          "O anúncio reproduz minha imagem pessoal sem autorização (Ex.: Retrato, modelo publicitário, etc.)",
        PPPI8: "O produto oferecido infringe desenho industrial.",
        PPPI9: "O produto oferecido infringe patente ou modelo de utilidade.",
        PPPI10: "Produto não destinado à venda",
        ACUERDO: "Tengo un convenio de regulación sobre este producto.",
      },
    },
    report_v2: {
      title: "Marcar como suspeito",
      title_plural: "Marcar como suspeitos",
      subtitle: "Selecione o tipo de infração",
      title_product: "No produto oferecido",
      title_catalog: "Reportar infração aos meus direitos",
      subtitle_catalog:
        " Aplicável ao uso indevido de marcas, incluindo produtos falsificados ou produtos originais que usam uma marca sem autorização.",
      title_publication: "No conteúdo do anúncio",
      title_actors: "Atores, cantores, músicos e dançarinos",
      title_producers: "Produtores de videofonogramas",
      title_broadcastingorganizations: "Órgãos de radiodifusão",
      report_code: {
        PPPI1: "É um produto falsificado",
        PPPI2: "Uso ilegal da minha marca",
        PPPI3: "Software",
        PPPI4: "Infração de direitos de autor",
        PPPI5: "Livros",
        PPPI6: "Imagens / fotos",
        PPPI7: "Uso não autorizado da imagem pessoal",
        PPPI8: "Infringe modelos ou desenhos industriales",
        PPPI9: "Infringe patentes, modelos de utilidade ou variedades vegetais",
        PPPI10: "É um produto não destinado à venda",
        PPPI11: "Cursos",
        PPPI12: "Videogames",
        PPPI13: "Vídeos",
        PPPI14: "Vídeos / Filmes",
        PPPI15: "Música",
        PPPI16: "Personagens",
        PPPI17: "Outros",
        PPPI18: "Reproduções ilegais",
        PPPI19: "Imagem pessoal",
        PPPI20: "Uso não autorizado de material de aúdio",
        PPPI21: "Uso não autorizado de material audiovisual",
        PPPI22: "Transmissão ilegal",
        PPPI23: "Dispositivo ilegal",
        ACUERDO: "Tenho um convênio de regulação sobre este produto.",
        PPPI1_subtext:
          "É um produto que tem minha marca mas que eu não fabriquei.",
        PPPI2_subtext:
          "Inclui meu logo nas imagens sem autorização. Identifica o produto com um nome ou logo que se confunde com a minha marca.",
        PPPI3_subtext:
          "O anúncio oferece um programa de computador que infringe meus direitos.",
        PPPI4_subtext:
          "Por exemplo, livros em PDF, músicas compiladas, cópias de filmes, produtos que incluem imagens de obras protegidas.",
        PPPI5_subtext:
          "O anúncio oferece uma obra literária que infringe meus direitos.",
        PPPI6_subtext:
          "O anúncio tem imagens ou fotos que infringem meus direitos.",
        PPPI7_subtext:
          "Por exemplo, uma foto que mostra uma pessoa sem sua autorização.",
        PPPI8_subtext:
          "Informe aqui as possíveis infrações aos seus modelos ou desenhos industriais registrados, incluindo desenhos, formas e monogramas.",
        PPPI9_subtext: "",
        PPPI10_subtext:
          "Ex.: amostras grátis ou produtos que foram entregues em regime de comodato. Produtos não lançados oficialmente no Brasil também podem ser denunciados.",
        PPPI11_subtext:
          "O anúncio oferece um curso que infringe meus direitos.",
        PPPI12_subtext:
          "O anúncio oferece um videogame que infringe meus direitos.",
        PPPI13_subtext: "",
        PPPI14_subtext:
          "O anúncio oferece uma obra audiovisual que infringe meus direitos.",
        PPPI15_subtext:
          "O anúncio oferece um conteúdo musical que infringe meus direitos.",
        PPPI16_subtext:
          "O anúncio oferece produtos que incluem meus personagens sem autorização.",
        PPPI17_subtext:
          "O anúncio oferece outro tipo de obra (desenho, pintura, escultura etc.) que infringe meus direitos.",
        PPPI18_subtext: "Inclusões ou reproduções não autorizadas.",
        PPPI19_subtext:
          "Uso não autorizado da minha imagem pessoal associada à minha interpretação artística.",
        PPPI20_subtext: "Música ou sons gravados.",
        PPPI21_subtext:
          "Filmes, séries, vídeos, gravações de recitais, shows e eventos esportivos.",
        PPPI22_subtext: "Serviços para acessar sinais ilegalmente.",
        PPPI23_subtext: "Dispositivos que captam sinais de acesso ilegalmente.",
        ACUERDO_subtext: "",
      },
      catalog_code: {
        PPPI1: "É um produto falsificado",
        PPPI2: "Uso ilegal da minha marca no anúncio.",
        PPPI10: "É um produto não destinado à venda",
        PPPI1_subtext:
          "É um produto com a minha marca, mas que não foi fabricado por mim.",
        PPPI2_subtext:
          "Identifica o produto com um nome ou logo que pode ser confundido com a minha marca, menciona a minha marca quando na verdade não é etc.",
        PPPI10_subtext:
          "Por exemplo: amostras grátis ou produtos entregues em regime de comodato. Além disso, é possível denunciar produtos não lançados oficialmente no Brasil. ",
      },
      step_2: {
        header: "Quer adicionar algo mais?",
        title: "Adicionar comentário",
        subtitle: "(Opcional)",
        placeholder:
          "Insira aqui suas notas e comentários. O vendedor denunciado não poderá vê-los.",
      },
      message_advice: {
        title: "IMPORTANTE:",
        message:
          " Lembre-se de que sua denúncia deve se basear em infrações de propriedade intelectual que estejam cadastradas no programa",
        message_strong:
          "O uso inadequado dessa ferramenta pode fazer com que sua conta sofra sanções.",
        link: "Saiba o que você pode denunciar no BPP",
      },
      tooltip_PPPI10: {
        title: "NOVO!",
        description:
          "Use este motivo para denunciar infrações de marca registrada sobre o produto.",
      },
      step_1: {
        copyright: "Infringe direitos autorais",
        brand: "Infringe direitos de marca registrada",
        personal_image: "Usa minha imagem pessoal sem autorização",
        utility_model: "Infringe modelos ou desenhos industriales",
        patent:
          "Infringe patentes, modelos de utilidade ou variedades vegetais",
        agreements: "Tenho um convênio de regulação sobre este produto.",
        related_rights: "Infringe direitos conexos",
      },
      "select-options": {
        subtitle: "Onde a infração foi encontrada?",
        placeholder: "Selecione uma opção",
        "opt-title": "Título",
        "opt-description": "Descrição",
        "opt-sheet": " Ficha técnica",
        "opt-publish": "Anúncio",
        subtitle2: "Explique mais sobre a infração",
        placeholder2: "Inclua mais detalhes sobre a infração aqui.",
        button: "Enviar denúncia",
      },
      buybox: {
        title: "Pronto! Agradecemos por reportar",
        description:
          "Processamos a sua denúncia. Vamos revisá-la e te daremos uma resposta por e-mail.",
        "case-number": "Número do caso: ",
      },
      product_subtext_1: "Selecciona esta opción si el producto",
      product_subtext_2: "usa tu marca sin autorización",
      publication_subtext_1: "Selecciona esta opción si la publicación",
      publication_subtext_2: "menciona tu marca",
      publication_subtext_3:
        "en el titulo, en la ficha técnica o en la descripción.",
      related_rights_sub: "Verifique onde está a infração.",
      copyright_sub: "",
      brand_sub: "",
      personal_image_sub: "",
      utility_model_sub:
        "Informe aqui as possíveis infrações aos seus modelos ou desenhos industriais registrados, incluindo desenhos, formas e monogramas.",
      patent_sub: "",
      agreements_sub: "",
      copyright_title: "Selecione o tipo de obra ou criação",
      copyright_selected: " em ${reason}",
      utility_patent_selected: " de ${reason}",
      image_hub_title:
        "Indique qualis imagen(s) estariam infringindo seus direitos",
      catalog_title: "No produto oferecido",
      catalog_PPPI10_title: "No conteúdo do anúncio",
      "tooltip-isDenounced": "Este produto já foi reportado",
      errorDenounce: "Ocorreu um erro. Por favor, tente novamente. ",
    },
    datatables: {
      length_menu: "Mostrar _MENU_ filas por página",
      zero_records: "Não à resultados",
      info: "Mostrando _START_ de _END_ de _MAX_",
      info_empty: "Não à filas disponiveis",
      info_filtered: "(Filtrado desde _MAX_ resultados total)",
      processing: "Carregando resultados",
      first: "Primero",
      last: "Ultimo",
      next: "Próximo",
      previous: "Previo",
    },
    activerecord: {
      errors: {
        messages: {
          record_invalid: "objeto invalido -> %{Erros}",
          wrong_size: "tamaño equivocado (deveria ser %{file_size})",
          size_too_small:
            "demasiado pequeña (deve ser pelo menos %{file_size})",
          size_too_big:
            "demasiado grande (deveram ser pelo menos %{file_size})",
          inclusion: "não esta incluído na lista",
          exclusion: "está reservado",
          invalid: "é inválido",
          confirmation: "não coincide com a confirmação",
          blank: "é requerido",
          empty: "não pode estar vazio",
          not_a_number: "não é um número",
          not_an_integer: "deve ser um entero",
          less_than: "deve ser menor que %{count}",
          less_than_or_equal_to: "deve ser menor o igual que %{count}",
          greater_than: "deve ser maior que %{count}",
          greater_than_or_equal_to: "deve ser maior o igual que %{count}",
          too_short: {
            one: "é muito curto (mínimo 1 caracter)",
            other: "é muito curto (mínimo %{count} caracteres)",
          },
          too_long: {
            one: "é muito largo (máximo 1 caracter)",
            other: "é muito largo (máximo %{count} caracteres)",
          },
          equal_to: "deve ser igual a %{count}",
          wrong_length: {
            one: "longitude errada (deve ser de 1 caracter)",
            other: "longitude errada (deve ser de %{count} caracteres)",
          },
          accepted: "deve ser aceitado",
          even: "deve ser um número par",
          odd: "deve ser um número impar",
          taken: "ja registrado",
          required: "requerido",
        },
      },
      models: {
        user: "Usuario",
      },
      attributes: {
        user: {
          email: "Email",
          first_name: "Nombre",
          last_name: "Sobrenome",
          password: "Senha",
          password_confirmation: "Confirmar Senha",
          current_password: "Senha Actual",
          currency: "Moeda",
          locale: "Idioma",
          update_password: "Atualizar Senha",
          field_required: "Este campo é obrigatório",
          error_rewrite_password: "Não coincide com o campo contraseña",
          alert_update_user: "Usuário atualizado",
          alert_not_update_user: "Nenhuma alteração foi feita",
        },
      },
    },
    views: {
      pagination: {
        first: "&laquo; Primero",
        last: "Último &raquo;",
        previous: "&lsaquo; Ant",
        next: "Próximo &rsaquo;",
        truncate: "&hellip;",
      },
    },
    shared: {
      side_navigation: {
        suggested_listings: "Anúncios sugeridos",
        searches: "Buscas",
        reports: "Denúncias",
        custom_reports: "Relatórios de denúncias",
        last_reported_listings: "Histórico de denúncias",
        support: "Suporte",
        support_help: "Ajuda",
        management_case: "Gerenciamento de denúncias",
        cases_pending: "Denúncias pendentes",
        cases_closed: "Denúncias encerradas",
        sellers: "Vendedores",
        sellers_black: "Vendedores observados",
        sellers_white: "Vendedores confiavels",
        livesearch_reports: "Relatórios do Livesearch",
        articles_to_report: "Denunciar anúncios suspeitos",
        articles_to_report_tooltip_title: "Você possui denúncias não enviadas",
        articles_to_report_tooltip_text:
          "Revise seus anúncios suspeitos e envie suas denúncias por aqui.",
        articles_to_report_tooltip_not_show: "Não mostre novamente",
        support_link: "Suporte",
        video_instructions: "Vídeos instrutivos",
        new_pill: "NOVO",
        my_account: "Minha conta",
      },
    },
    devise: {
      registrations: {
        edit: {
          title: "Perfil do auditor",
          cancel_button: "Cancelar",
          update_button: "Atualizar",
          help_label_password: "(deixe em branco se você não quer mudá-lo)",
          help_label_current_password:
            "(precisamos de sua senha atual para confirmar as alterações)",
        },
      },
      passwords: {
        edit: {
          title: "Alterar sua senha",
          change_passwd_button: "Alterar minha senha",
          placeholder_password: "Nova senha",
          placeholder_confirm_password: "Confirmar nova senha",
        },
      },
      mailer: {
        reset_password_instructions: {
          hello: "OI",
          date: "Data",
          requested_link_text:
            "Alguem pediu um enlace para alterar sua senha. Você pode fazer isso através do seguinte link:",
          link_change_my_password: "alterar minha Senha",
          ignore_email_text:
            "Si Você não à solicitado a alteração do seu password, por favor, ignora essa mensagem.",
          your_passwd_wont_change_text:
            "Sua Senha não sera modificada até que ingresse no enlace de cima e gere uma nova.",
        },
      },
    },
    terms_and_conditions: "Termos e condições",
    privacy_policy: "Políticas de privacidade",
    reviews: {
      button: "Opiniões",
      title_seller_selected: "Opiniões sobre o produto de",
      title_default: "Opiniões sobre o produto",
      subtitle_seller_default:
        "Aqui, você encontra as opiniões de quem comprou de cada vendedor.",
      subtitle_seller_selected:
        "Confira as opiniões de quem comprou deste vendedor.",
      subtitle_seller_no_reviews:
        "O vendedor que você selecionou ainda não recebeu opiniões sobre as vendas que fez.",
      all_reviews: "Tudo",
      positives: "Positivas",
      negatives: "Negativas",
      view_more: "Ver mais",
      view_less: "Ver menos",
    },
  },
};

export default locales;
