import React from 'react'
import { connect } from "react-redux";
import "./AlertLink.scss"
import { t, getHelp2SiteUrl } from "utils";

const AlertLink = props => {
    const { lang } = props
    console.log(lang)
    return (
        <div className='alert-link-wrapper'>
            <div className='alert-link-ctn'>
                <div className="icon__container">
                    <img alt="alert" src="/images/alerts/caution.png" />
                </div>
                <div className='message__container'>
                    <div className='first'>
                        <p><strong>{t('report_v2.message_advice.title')}</strong>{t('report_v2.message_advice.message')}  <strong>{t('report_v2.message_advice.message_strong')} </strong>
                        </p>
                    </div>
                    <div className='link'>
                        <a target="_blank"  rel="noopener noreferrer" href={getHelp2SiteUrl(lang)}> <p>{t('report_v2.message_advice.link')}</p></a>
                    </div>
                </div>

            </div>
        </div>
    )
}

const mapStateToProps = (state) => ({
    lang: state.global.lang,
});

export default connect(mapStateToProps)(AlertLink);