import React, { Component } from "react";
import { connect } from "react-redux";
import moment from "moment";
import ReactGA from "react-ga";
//import { Link } from "react-router-dom";
import { actionMessageBox } from "redux/actions/global";
import Alert from "components/Alert";
import { t, get, getUrlWithAnchor } from "utils";
import "./messageBox.scss";


class MessageBox extends Component {
  componentDidUpdate(prevProps) {
    if (
      this.props.location.hash &&
      prevProps.location.pathname !== this.props.location.pathname
    ) {
      const element = document.querySelector(this.props.location.hash);
      const scrollTop = element && element.offsetTop - 56;

      scrollTop && window.scrollTo({ top: scrollTop, behavior: 'smooth' });
    }
  }

  handleCloseMessageBox = () => {
    ReactGA.ga('send', 'event', 'PPPI', 'NPS_REPORTING_TOOL_CLOSE');
    //localStorage.setItem('messageBoxViewed', 'closeMessageBox');
    //localStorage.setItem('messageBoxViewedDate', moment().unix());
    //this.props.actionMessageBox();
    document.getElementById("messageBox").style.display = "none";
  }

  handleOpenMessageBox = (memberId, lang) => {
    ReactGA.ga('send', 'event', 'PPPI', 'NPS_REPORTING_TOOL_OPEN_POLL');
    //localStorage.setItem('messageBoxViewed', 'openMessageBox');
    //localStorage.setItem('messageBoxViewedDate', moment().unix());
    this.props.actionMessageBox();
    let url;
    switch (lang) {
      case 'en':
        //  url = `https://docs.google.com/forms/d/e/1FAIpQLSfzfx14NaNCwHumH1_cuQFMV1ZLwRDR0TVslHPfSZ0IuMUN3Q/viewform?usp=pp_url&entry.1133657047=${memberId}`;
        url = `https://survey.mercadolibre.com/jfe/form/SV_5A82QQgxbJzaYR0?member_id=${memberId}`
        break;
      case 'es':
        //  url = `https://docs.google.com/forms/d/e/1FAIpQLSd-8af8c_Mv9pC_tujN0cxhvuTfj7utkoloAnXbKpAMNEFR7g/viewform?usp=pp_url&entry.1133657047=${memberId}`;
        url = `https://survey.mercadolibre.com/jfe/form/SV_5A82QQgxbJzaYR0?member_id=${memberId}`
        break;
      case 'pt':
        //  url = `https://docs.google.com/forms/d/e/1FAIpQLSfdQUaSz651Kg6pmRqVQPHo1z61XVHu-t_AOe35x6shyNomRg/viewform?usp=pp_url&entry.1133657047=${memberId}`;
        url = `https://survey.mercadolibre.com/jfe/form/SV_5A82QQgxbJzaYR0?member_id=${memberId}`
        break;
      default:
        //  url = `https://docs.google.com/forms/d/e/1FAIpQLSd-8af8c_Mv9pC_tujN0cxhvuTfj7utkoloAnXbKpAMNEFR7g/viewform?usp=pp_url&entry.1133657047=${memberId}`;
        url = `https://survey.mercadolibre.com/jfe/form/SV_5A82QQgxbJzaYR0?member_id=${memberId}`
        break;
    }
    window.open(url, '_blank');
  }

  handleDoneMessageBox = () => {
    ReactGA.ga('send', 'event', 'PPPI', 'NPS_REPORTING_TOOL_DONE');
    //localStorage.setItem('messageBoxViewed', 'doneMessageBox');
    localStorage.setItem('messageBoxViewedDate', moment().unix());
    this.props.actionMessageBox();
  }

  render() {
    const { profile, location, lang, messageBoxShow } = this.props;
    const viewed = localStorage.getItem('messageBoxViewed');
    const viewedDate = localStorage.getItem('messageBoxViewedDate');
    const diffViewedDate = moment().diff(moment.unix(viewedDate), 'days');
    const memberId = profile.groupId;

    // if (viewed === 'openMessageBox') {
    //   return null;
    // }
    if (
      (
        diffViewedDate < 7
      ) ||
      (
        !location.pathname.includes("live_searches/")
      ) ||
      !messageBoxShow
    ) {
      return null;
    }


    return (
      <Alert
        id="messageBox"
        alertType="info"
        styleType="not-filled"
        className="alert__automation-pending-cn"
      >
        <div className="icon__container">
          <img alt="alert" src="/images/exclamation.svg" />
        </div>

        <p className="first">{t(`messageBox.title`)}</p>
        <p className="alert_subtitle"> {t('messageBox.subtitle')}<strong>{t('messageBox.subtitleStrong')}</strong></p>
        <div className="close " onClick={this.handleCloseMessageBox}>
          <i className="close__icon messagebox" />
        </div>
        <button className="btn btn-open" onClick={() => { this.handleOpenMessageBox(memberId, lang) }}>
          {t('messageBox.button1')}
        </button>
        <button className="btn btn-done" onClick={this.handleDoneMessageBox} >
          {t('messageBox.button2')}
        </button>


      </Alert>
    );
  }
}


const mapStateToProps = state => ({
  lang: state.global.lang,
  profile: state.global.profile,
  messageBoxShow: state.global.messageBoxShow,
});

export default connect(mapStateToProps, { actionMessageBox })(MessageBox);