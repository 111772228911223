export default `
  mutation (
    $accountType: String!,
    $contactName: String!,
    $identificationNumber: String!,
    $contactPhone: String!,
    $accountDisplayName: String!,
    $identificationType: String!,
    $identificationName: String!,
    $accountDisplayEmail: String!,
    $accountLang: String!,
    $identificationCountry: String!,
    $contactEmail: String!,
    $accountName: String!,
    $holderContactName: String,
    $holderContactEmail: String,
  ){
    updateProfileAccount(profileAccount: {
      accountType: $accountType
      contactName: $contactName
      identificationNumber: $identificationNumber
      contactPhone: $contactPhone
      accountDisplayName: $accountDisplayName
      identificationType: $identificationType
      identificationName: $identificationName
      accountDisplayEmail: $accountDisplayEmail
      accountLang: $accountLang
      identificationCountry: $identificationCountry
      contactEmail: $contactEmail
      accountName: $accountName
      holderContactName: $holderContactName
      holderContactEmail: $holderContactEmail
    }) {
      isVerified
      meliAccount {
        accountType
        contactName
        contactPhone
        accountDisplayName
        identificationType
        identificationName
        identificationNumber
        identificationCountry
        accountDisplayEmail
        accountLang
        isVerified
        contactEmail
        accountId
        accountName
        holderContactName
        holderContactEmail
      }
    }
  }
`;