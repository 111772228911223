import articleSellerFields from "./articleSellerFields";


export default `
  ${articleSellerFields}

  fragment articleFields on ArticleType {
    livesearchId
    id 
    title 
    price
    currency
    catalogListing
    catalogProductId
    officialStoreId
    availableQuantity
    soldQuantity
    condition
    attributes {
      id,
      valueName
    }
    href
    thumbnail
    siteId
    sellerId
    status
    images {
      id
      url
      secure_url
    } 
    seller { 
      ...articleSellerFields
    }
    country {
      code 
      name
    }
    approved {
      author {
        id
        givenName
        familyName
      }
      createdAt
    }
    reported {
      tags {
        display
        active
      }
      code 
      description
      photosDenounced
      author {
        id
        givenName
        familyName
      }
      createdAt
    } 
    noticed {
      sent
      reason
      failed 
      sending
      noticeId
      noticedAt
      takeDownAt
    }
    originModel
  }
`;