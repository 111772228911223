import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { closeModal } from "redux/actions/global";
import ArticleImages from "components/Search/Article/ArticleImages/ArticleImages";
import ArticleShortDescription from "components/Search/Article/ArticleShortDescription";
import SellerInformation from "components/SellerInformation";
import { t } from "utils";

class DenouncedArticleDetailCatalogo extends Component {
 
  constructor(props) {
    super(props);

    const { data, seller } = props;
   
    this.state = {
      step: 1,
      siteId: null,
      data,
      seller
    };
  }

  buildImages = () => {
    const images = this.state.data.articleImages;
    const articleImages = [];
    for(let i in images){
      articleImages.push({
        url: images[i].url,
        secure_url: images[i].url,
        size: images[i].size,
        max_size: images[i].max_size,
      });
    }
    return articleImages;
  }

  render() {

    const { closeModal } = this.props;
  
    return (
      <div className="panel article-detail">
        <div class="seller__score">Catalogo</div>
        <div className="container">
          <div className="col col-left">
            <ArticleImages
              alt={this.state.data.articleTitle}
              images={this.buildImages()}
              // thumbnail={article.thumbnail} 
            />
            <div className="description-ctn">
              <h2 className="title">
                {t('article_detail.description')}
              </h2>
              <div className="description__text">
                {this.state.data.articleDescription}
              </div>
            </div>
          </div>

          <div className="col col-right">
            <i className="modal-close" onClick={closeModal} />
            <div className="short-description-wrapper">
              <ArticleShortDescription
                article={{
                  id: this.state.data.articleId,
                  siteId: this.state.data.articleId.slice(0, 3),
                  status: 'active',
                  title: this.state.data.articleTitle,
                  soldQuantity: this.state.data.articleSoldQuantity,
                  currency: this.state.data.articleCurrency,
                  price: this.state.data.articlePrice,
                  approved: true,
                  reported: true,
                  deactivateTranslate: true
                }}
              /> 
              <SellerInformation
                siteId={this.state.data.articleId.slice(0, 3)}
                sellerId={this.state.seller.id}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  profile: state.global.profile,
});

const actions = {
  closeModal,
};

export default withRouter(connect(mapStateToProps, actions)(DenouncedArticleDetailCatalogo));