import { from } from 'rxjs';
import { ofType } from 'redux-observable';
import { mergeMap, mapTo, takeUntil } from 'rxjs/operators';
import { gqlQuery } from "libs/awsLib";
import {
  querySearch,
  createLiveSearch,
  queryLiveSearches,
  queryLiveSearchReports,
  mutationDeleteLiveSearch,
  mutationSellersLiveSearch,
  queryFilterProfileSellers,
  mutationCreateLiveSearchReport,
  mutationDeleteLiveSearchReport,
} from "gql/index";
import { getSeller } from "utils";

import {
  SEARCH_PREVIEW,
  searchPreviewFulfilled,
  searchPreviewError,
  CANCEL_SEARCH_PREVIEW,
  
  FETCH_LIVE_SEARCHES,
  fetchLiveSearches,
  liveSearchesFulfilled,
  fetchLiveSearchesError,
  
  CREATE_LIVE_SEARCH,
  CREATE_LIVE_SEARCH_SUCCESS,
  createLiveSearchSuccess,
  createLiveSearchError,
  
  DELETE_LIVE_SEARCH,
  deleteLiveSearchError,
  DELETE_LIVE_SEARCH_ERROR,

  CREATE_LIVE_SEARCH_REPORT,
  CREATE_LIVE_SEARCH_REPORT_SUCCESS,
  createLiveSearchReportSuccess,
  createLiveSearchReportError,

  FETCH_LIVE_SEARCH_REPORTS,
  liveSearchReportsFulfilled,
  liveSearchReportsError,

  DELETE_LIVE_SEARCH_REPORT,
  deleteLiveSearchReportSuccess,
  deleteLiveSearchReportError,

  CREATE_SELLER_LIVE_SEARCH,
  CREATE_SELLER_LIVE_SEARCH_SUCCESS,
  createSellerLiveSearchSuccess,
  createSellerLiveSearchError,

  FETCH_FILTER_PROFILE_SELLERS,
  fetchFilterProfileSellersFulfilled,
  fetchFilterProfileSellersError,
} from "redux/actions/liveSearches";

import { CREATE_SELLERS_LIVE_SEARCH_SUCCESS } from "redux/actions/global";


/* EPICS */
export const searchPreviewEpic = action$ => action$.pipe(
  ofType(SEARCH_PREVIEW),
  mergeMap(({ payload }) =>
    from(
      new Promise((resolve, reject) => {
        (async () => {
          let sellerId = null;
  
          if (payload.sellerNickname) {
            const { sellerNickname, sites } = payload;
            const siteId = sites[0].countrySites[0]; 
            const response = await getSeller(sellerNickname, siteId).catch(reject);
            sellerId = response && String(response.id);
          }
  
          delete payload.sellerNickname;
  
          gqlQuery(querySearch, { ...payload, sellerId }).then(resolve).catch(reject);
        })();
      }).then(searchPreviewFulfilled)
        .catch(searchPreviewError)
    ).pipe(
      takeUntil(action$.pipe(
        ofType(CANCEL_SEARCH_PREVIEW))
      )
    )
  )
);

export const getLiveSearchesEpic = action$ => action$.pipe(
  ofType(
    FETCH_LIVE_SEARCHES,
    CREATE_SELLERS_LIVE_SEARCH_SUCCESS
  ),
  mergeMap(() => 
    from(
      gqlQuery(queryLiveSearches)
        .then(response => {
          response.liveSearches.sort((a, b) => {
            if (a.createdAt > b.createdAt) return -1;
            if (a.createdAt < b.createdAt) return 1;
            return 0;
          });
          return liveSearchesFulfilled(response);
        })
        .catch(fetchLiveSearchesError)
    )
  )
);

export const deleteLiveSearchEpic = action$ => action$.pipe(
  ofType(DELETE_LIVE_SEARCH),
  mergeMap(({ payload }) => 
    from(
      gqlQuery(mutationDeleteLiveSearch, payload)
        .then(() => ({ type: '' }))
        .catch(deleteLiveSearchError)
    )
  )
);

export const deleteLiveSearchErrorEpic = action$ => action$.pipe(
  ofType(DELETE_LIVE_SEARCH_ERROR),
  mapTo(fetchLiveSearches())
);

export const createLiveSearchEpic = action$ => action$.pipe(
  ofType(CREATE_LIVE_SEARCH),
  mergeMap(({ payload }) =>
    from(
      gqlQuery(createLiveSearch, payload)
        .then(response => response.createLiveSearch)
        .then(createLiveSearchSuccess)
        .catch(createLiveSearchError)
    )
  )
);

export const createLiveSearchSuccessEpic = action$ => action$.pipe(
  ofType(
    CREATE_LIVE_SEARCH_SUCCESS,
    CREATE_SELLER_LIVE_SEARCH_SUCCESS,
  ),
  mapTo(fetchLiveSearches())
);

export const createLiveSearchReportEpic = action$ => action$.pipe(
  ofType(CREATE_LIVE_SEARCH_REPORT),
  mergeMap(({ payload }) => 
    from(
      new Promise((resolve, reject) => {
        (async () => {
          let sellerId = null;
  
          if (payload.sellerNickname) {
            const { sellerNickname, sites } = payload;
            const siteId = sites[0].countrySites[0]; 
            const response = await getSeller(sellerNickname, siteId).catch(reject);
            sellerId = String(response.id);
          }
  
          delete payload.sellerNickname;
  
          gqlQuery(mutationCreateLiveSearchReport, { ...payload, sellerId })
            .then(resolve)
            .catch(reject);
        })();
      }).then(createLiveSearchReportSuccess)
        .catch(createLiveSearchReportError)
    )
  )
);

export const fetchLiveSearchReportsEpic = action$ => action$.pipe(
  ofType(
    FETCH_LIVE_SEARCH_REPORTS,
    CREATE_LIVE_SEARCH_REPORT_SUCCESS,
  ),
  mergeMap(({ payload }) =>
    from(
      gqlQuery(queryLiveSearchReports, payload)
        .then(response => response.liveSearchReports
          .sort((a, b) => {
            if (a.createdAt > b.createdAt) return -1;
            if (a.createdAt < b.createdAt) return 1;
            return 0;
          })
        )
        .then(liveSearchReportsFulfilled)
        .catch(liveSearchReportsError)
    )
  )
);

export const deleteLiveSearchReportEpic = action$ => action$.pipe(
  ofType(DELETE_LIVE_SEARCH_REPORT),
  mergeMap(({ payload }) =>
    from(
      gqlQuery(mutationDeleteLiveSearchReport, { id: payload })
        .then(deleteLiveSearchReportSuccess)
        .catch(deleteLiveSearchReportError)
    )
  )
);

export const createSellerLiveSearchEpic = action$ => action$.pipe(
  ofType(CREATE_SELLER_LIVE_SEARCH),
  mergeMap(({ payload }) => 
    from(
      getSeller(
        payload.seller.nickname,
        payload.seller.siteId
      ).then(response => {
        const keyword = payload.keyword ? payload.keyword : null;
        payload.seller.id = String(response.id);

        return gqlQuery(
          mutationSellersLiveSearch, 
          Object.assign({},
            keyword && { keyword },
            { sellers: [payload.seller] },
          )
        ).then(response => response.createSellersLiveSearch)
          .then(createSellerLiveSearchSuccess);
      }).catch(createSellerLiveSearchError)
    )
  )
);

export const fetchFilterProfileSellersEpic = action$ => action$.pipe(
  ofType(FETCH_FILTER_PROFILE_SELLERS),
  mergeMap(({ payload }) => 
    from(
      gqlQuery(queryFilterProfileSellers, payload)
        .then(response => response.filterProfileSellers)
        .then(response => 
          fetchFilterProfileSellersFulfilled({
            ...response,
            siteId: payload.siteId,
          })
        )
        .catch(fetchFilterProfileSellersError)
    )
  )
);
