export default `
  fragment articleDataTableFields on ArticleType {
    id
    href
    title 
    price
    currency
    country {
      name
    }
    reported {
      author {
        id
        givenName
        familyName
      }    
      createdAt
    }
    noticed {
      sent
      reason
      sending
      snapshot
      noticeId
      noticedAt
      takeDownAt
      rejectOption {
        reason
        text_en
        text_pt
        text_es
      }
      noticeStatus
      noticeLastUpdate
    }
  }
`;