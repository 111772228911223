export default `
  mutation(
    $sellers: [SellerLSInputType]!,
    $keyword: String
  ) {
    createSellersLiveSearch(
      sellers: $sellers,
      keyword: $keyword
    ) {
      id
    }
  }
`;