import {
  FETCH_LIVE_SEARCH,
  LIVE_SEARCH_FULFILLED,
  LIVE_SEARCH_ERROR,

  RESET_DETAIL,

  CHANGE_LIVE_SEARCH,

  CHANGE_LIVE_SEARCH_KEYWORD,
  
  LIVE_SEARCH_ADD_DATA,
  
  APPLY_FILTER,
  APPLY_FILTER_FULFILLED,
  APPLY_FILTER_ERROR,
  
  APPROVE_ARTICLE,
  APPROVE_ARTICLE_ERROR,
  
  APPROVE_ARTICLE_UNDO,
  APPROVE_ARTICLE_UNDO_ERROR,
  
  REPORT_ARTICLES,
  REPORT_ARTICLES_SUCCESS,
  REPORT_ARTICLES_ERROR,

  DISCARD_ARTICLES,
  DISCARD_ARTICLES_SUCCESS,
  DISCARD_ARTICLES_ERROR,
  
  REPORT_ARTICLE_UNDO,
  REPORT_ARTICLE_UNDO_ERROR,
  
  BATCH_TOGGLE,
  
  BATCH_APPROVED_UPDATE, 
  BATCH_REPORTED_UPDATE,
  
  BATCH_APPLY,
  BATCH_APPLY_SUCCESS,
  BATCH_APPLY_ERROR,
  
  EDIT_REPORTED_ARTICLES,
  EDIT_REPORTED_ARTICLES_SUCCESS,
  EDIT_REPORTED_ARTICLES_ERROR,

  SET_REPORTING_STATUS,

  SET_ACTIVE_PAGE,

  RESET_SHOW_IMPORT_SUCCESS,

  SET_LIVESEARCH_ARTICLES_IDS,
} from "redux/actions/detail";
import {
  FETCH_ARTICLE_DETAIL,
  FETCH_CATALOGO_DETAIL,
  FETCH_ARTICLE_DETAIL_FULFILLED,
  FETCH_ARTICLE_CATALOGO_DETAIL_FULFILLED,
  FETCH_ARTICLES_CATALOGO,
  FETCH_ARTICLE_DETAIL_ERROR,
  RESET_ARTICLE_DETAIL,
} from "redux/actions/global";
import {
  SET_BLACK_SELLER,  
  SET_BLACK_SELLER_SUCCESS,
  SET_BLACK_SELLER_ERROR,

  UNSET_BLACK_SELLER,
  UNSET_BLACK_SELLER_SUCCESS,
  UNSET_BLACK_SELLER_ERROR,

  SET_WHITE_SELLER,
  SET_WHITE_SELLER_SUCCESS,
  SET_WHITE_SELLER_ERROR,
  
  UNSET_WHITE_SELLER,
  UNSET_WHITE_SELLER_ERROR,
  UNSET_WHITE_SELLER_SUCCESS,
} from "redux/actions/sellers";
// Business logic
import {
  batchApplyError,
  liveSearchError,
  applyFilterError,
  unsetSellerError,
  approveArticleError,
  reportArticlesError,
  discardArticlesError,
  liveSearchFulfilled,
  applyFilterFulfilled,
  batchApplyOptimistic,
  reportArticlesSuccess,
  discardArticlesSuccess,
  setWhiteSellerSuccess,
  setBlackSellerSuccess,
  reportArticleUndoError,
  fetchArticleDetailError,
  unsetWhiteSellerSuccess,
  unsetBlackSellerSuccess,
  approveArticleUndoError,
  reportArticlesOptimistic,
  approveArticleOptimistic,
  reportArticleUndoOptmistic,
  approveArticleUndoOptimistic,
} from "redux/reducers/utils/detail";

const initialState = {
  toReport: [],
  activePage: 1,
  liveSearch: {},
  isLoading: false,
  isFetching: false,
  batchReported: [],
  batchApproved: [],
  currentFilter: {},
  isReporting: false,
  isDiscarding: false,
  articlesFakeMap: {},
  lsProcessedCount: 0,
  isBatchActive: false,
  applyingFilter: false,
  isUpdatingArticle: false,
  showImportSuccess: false,
  showImportSuccessCount: 0,
  liveSearchArticlesIds: [],
  updatingSellerStatus: false,
  errorLoadingLivesearch: false,
  fetchArticleDetailError: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
  case CHANGE_LIVE_SEARCH:
  case BATCH_APPROVED_UPDATE:
  case BATCH_REPORTED_UPDATE:
    return {
      ...state,
      ...action.payload,
    };

  case CHANGE_LIVE_SEARCH_KEYWORD: 
    return {
      ...state,
      liveSearch: {
        ...state.liveSearch,
        ...action.payload,
      }
    };

  case LIVE_SEARCH_ADD_DATA: 
    return {
      ...state,
      liveSearch: {
        ...state.liveSearch,
        ...action.payload,
      }
    };

  case APPLY_FILTER:
    return {
      ...state,
      isLoading: true,
      articlesFakeMap: {},
      errorLoadingLivesearch: false,
    };

  case FETCH_LIVE_SEARCH:
    return {
      ...state,
      isLoading: true,
      errorLoadingLivesearch: false,
    };

  case LIVE_SEARCH_FULFILLED:
    return liveSearchFulfilled(
      state,
      action.payload
    );

  case LIVE_SEARCH_ERROR: 
    return liveSearchError(
      state,
      action.payload
    );
    
  case APPLY_FILTER_FULFILLED:
    return applyFilterFulfilled(
      state,
      action.payload
    );

  case APPLY_FILTER_ERROR:
    return applyFilterError(
      state,
      action.payload
    );
    
  case APPROVE_ARTICLE:
    return approveArticleOptimistic(
      state, 
      action.payload
    );
    
  case APPROVE_ARTICLE_ERROR:
    return approveArticleError(
      state,
      action.payload
    );
    
  case APPROVE_ARTICLE_UNDO:
    return approveArticleUndoOptimistic(
      state,
      action.payload
    );

  case APPROVE_ARTICLE_UNDO_ERROR:
    return approveArticleUndoError(
      state,
      action.payload
    );
  
  case REPORT_ARTICLES:
    return reportArticlesOptimistic(
      state,
      action.payload
    );

  case REPORT_ARTICLES_SUCCESS:
    return reportArticlesSuccess(
      state,
      action.payload
    );
      
  case REPORT_ARTICLES_ERROR:
    return reportArticlesError(
      state,
      action.payload
    );
    
  case REPORT_ARTICLE_UNDO:
    return reportArticleUndoOptmistic(
      state,
      action.payload
    );

  case REPORT_ARTICLE_UNDO_ERROR: 
    return reportArticleUndoError(
      state,
      action.payload
    );

  case DISCARD_ARTICLES:
    return {
      ...state,
      isDiscarding: true,
      isUpdatingArticle: true
    };

  case DISCARD_ARTICLES_SUCCESS:
    return discardArticlesSuccess(
      state,
    );
      
  case DISCARD_ARTICLES_ERROR:
    return discardArticlesError(
      state,
      action.payload
    );   

  case BATCH_TOGGLE:
    return {
      ...state,
      isBatchActive: !state.isBatchActive,
      batchApproved: [],
      batchReported: [],
    };

  case BATCH_APPLY:
    return batchApplyOptimistic(
      state, 
      action.payload,
    );

  case BATCH_APPLY_SUCCESS:
    return {
      ...state,
      batchApproved: [],
      batchReported: [],
    };

  case BATCH_APPLY_ERROR:
    return batchApplyError(
      state,
      action.payload
    );

  case FETCH_ARTICLE_DETAIL: 
    return {
      ...state,
      isFetching: true,
      fetchArticleDetailError: false,
    };

    case FETCH_CATALOGO_DETAIL: 
    return {
      ...state,
      isFetching: true,
      fetchArticleDetailError: false,
    };
    
  case FETCH_ARTICLE_DETAIL_FULFILLED: 
    return { 
      ...state,
      isFetching: false,
    };

   case FETCH_ARTICLE_CATALOGO_DETAIL_FULFILLED:
    return { 
      ...state,
      isFetching: false,
    };

   case FETCH_ARTICLES_CATALOGO:
    return { 
      ...state,
      isFetching: false,
    };
    
  case FETCH_ARTICLE_DETAIL_ERROR:
    return fetchArticleDetailError(
      state,
      action.payload
    );
  
  case RESET_ARTICLE_DETAIL:
    return {
      ...state,
      fetchArticleDetailError: false
    };

  case EDIT_REPORTED_ARTICLES:
    return {
      ...state,
      isUpdatingArticle: true,
    };

  case EDIT_REPORTED_ARTICLES_SUCCESS:
  case EDIT_REPORTED_ARTICLES_ERROR:
    return {
      ...state,
      isUpdatingArticle: false,
    };

  case SET_BLACK_SELLER:
  case SET_WHITE_SELLER:
    return {
      ...state, 
      updatingSellerStatus: true,
    };

  case SET_BLACK_SELLER_SUCCESS:
    return setBlackSellerSuccess(
      state,
      action.payload
    );

  case SET_WHITE_SELLER_SUCCESS:
    return setWhiteSellerSuccess(
      state,
      action.payload
    );

  case SET_BLACK_SELLER_ERROR:
  case SET_WHITE_SELLER_ERROR:
    return {
      ...state,
      updatingSellerStatus: false,
    };

  case UNSET_BLACK_SELLER:
  case UNSET_WHITE_SELLER:
    return {
      ...state,
      updatingSellerStatus: true,
    };  
  
  case UNSET_BLACK_SELLER_SUCCESS:
    return unsetBlackSellerSuccess(
      state,
      action.payload,
    );
    
  case UNSET_WHITE_SELLER_SUCCESS:
    return unsetWhiteSellerSuccess(
      state,
      action.payload,
    );

  case UNSET_BLACK_SELLER_ERROR:
  case UNSET_WHITE_SELLER_ERROR:
    return unsetSellerError(state);

  case SET_REPORTING_STATUS:
    return {
      ...state,
      isReporting: action.payload,
    };

  case SET_ACTIVE_PAGE:
    return {
      ...state,
      activePage: action.payload,
    };

  case RESET_SHOW_IMPORT_SUCCESS: 
    return {
      ...state,
      showImportSuccess: false,
      showImportSuccessCount: 0,
    };

  case SET_LIVESEARCH_ARTICLES_IDS: 
    return {
      ...state,
      liveSearchArticlesIds: action.payload
    };

  case RESET_DETAIL:
    return initialState;
		
  default:
    return state;
  }
};
