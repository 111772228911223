
/* eslint-disable max-len */
/**
 * CONSTANTS
 */
export const FETCH_NOTICED_ARTICLES = "FETCH_NOTICED_ARTICLES";
export const FETCH_NOTICED_ARTICLES_ERROR = "FETCH_NOTICED_ARTICLES_ERROR";
export const NOTICED_ARTICLES_FULFILLED = "NOTICED_ARTICLES_FULFILLED";

export const CREATE_REPORT = "CREATE_REPORT";
export const CREATE_REPORT_SUCCESS = "CREATE_REPORT_SUCCESS";
export const CREATE_REPORT_ERROR = "CREATE_REPORT_ERROR";

export const FETCH_REPORTS = "FETCH_REPORTS";
export const FETCH_REPORTS_SUCCESS = "FETCH_REPORTS_SUCCESS";
export const FETCH_REPORTS_ERROR = "FETCH_REPORTS_ERROR";

export const DELETE_REPORT = "DELETE_REPORT";
export const DELETE_REPORT_SUCCESS = "DELETE_REPORT_SUCCESS";
export const DELETE_REPORT_ERROR = "DELETE_REPORT_ERROR";


/**
 * DISPATCHERS
 */
export const fetchNoticedArticles = payload => ({ type: FETCH_NOTICED_ARTICLES, payload });
export const fetchNoticedArticlesError = payload => ({ type: FETCH_NOTICED_ARTICLES_ERROR, payload });
export const noticedArticlesFulfilled = payload => ({ type: NOTICED_ARTICLES_FULFILLED, payload });

export const createReport = payload => ({ type: CREATE_REPORT, payload });
export const createReportSuccess = payload => ({ type: CREATE_REPORT_SUCCESS, payload });
export const createReportError = payload => ({ type: CREATE_REPORT_ERROR, payload });

export const fetchReports = payload => ({ type: FETCH_REPORTS, payload });
export const fetchReportsSuccess = payload => ({ type: FETCH_REPORTS_SUCCESS, payload });
export const fetchReportsError = payload => ({ type: FETCH_REPORTS_ERROR, payload });

export const deleteReport = payload => ({ type: DELETE_REPORT, payload });
export const deleteReportSuccess = payload => ({ type: DELETE_REPORT_SUCCESS, payload });
export const deleteReportError = payload => ({ type: DELETE_REPORT_ERROR, payload });
