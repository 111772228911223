/* eslint-disable no-console */
import { alertError } from "./index";
import { t } from "utils";

export const fetchClosedCasesListError = (state, payload) => {
  alertError(t('case_management.cases.fetch_pending_cases_list_error'));
  
  console.error(payload);
  
  return {
    ...state,
    isLoading: false
  };
};
