export default `
  mutation(
    $productId: String!,
    $reportReasonId: String!,
    $reportReasonDetail: String!,
    $reportReasonCommet: String,
  ) {
    sendBrandInfringement(
      productId: $productId,
      reportReasonId: $reportReasonId,
      reportReasonDetail: $reportReasonDetail,
      reportReasonCommet: $reportReasonCommet,
    ) {
      caseId
    }
  }
`;