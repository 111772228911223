/* eslint-disable max-len */
/**
 * CONSTANTS
 */
export const FETCH_PENDING_CASES_LIST = "FETCH_PENDING_CASES_LIST";
export const FETCH_PENDING_CASES_LIST_FULLFILLED = "FETCH_PENDING_CASES_LIST_FULLFILLED";
export const FETCH_PENDING_CASES_LIST_ERROR = "FETCH_PENDING_CASES_LIST_ERROR";

export const FETCH_PENDING_CASES_TO_EXPIRE = "FETCH_PENDING_CASES_TO_EXPIRE";
export const FETCH_PENDING_CASES_TO_EXPIRE_FULLFILLED = "FETCH_PENDING_CASES_TO_EXPIRE_FULLFILLED";
export const FETCH_PENDING_CASES_TO_EXPIRE_ERROR = "FETCH_PENDING_CASES_TO_EXPIRE_ERROR";

/**
 * DISPATCHERS
 */
export const fetchPendingCasesList = payload => ({ type: FETCH_PENDING_CASES_LIST, payload });
export const fetchPendingCasesListFullFilled = payload => ({ type: FETCH_PENDING_CASES_LIST_FULLFILLED, payload });
export const fetchPendingCasesListError = payload => ({ type: FETCH_PENDING_CASES_LIST_ERROR, payload });

export const fetchPendingCasesToExpire = payload => ({ type: FETCH_PENDING_CASES_TO_EXPIRE, payload });
export const fetchPendingCasesToExpireFullFilled = payload => ({ type: FETCH_PENDING_CASES_TO_EXPIRE_FULLFILLED, payload });
export const fetchPendingCasesToExpireError = payload => ({ type: FETCH_PENDING_CASES_TO_EXPIRE_ERROR, payload });
