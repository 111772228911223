import React, { Component } from "react";
import classnames from "classnames";
import { connect } from "react-redux";
import { Carousel } from "react-bootstrap";
import {
  openModal,
  closeHelp,
  closeModal,
  updateModal,
} from "redux/actions/global";
import { t } from "utils";


class OnBoardingSlide extends Component {
  constructor(props) {
    super(props);

    this.state = { isLastSlide: false };
  }

  getImagePath(subPath) {
    return `/images/onboarding-slide/${subPath}`;
  }
  
  handleClick = () => {
    this.props.closeHelp('onBoardingPendingDetail');
    this.props.closeModal();
    if (typeof this.props.onClose === "function") {
      this.props.onClose();
    }
  }

  handleSelect = slide => {
    this.setState({ isLastSlide: slide === 5 });
  }

  render() {
    return (
      <div
        tabIndex="1"
        className={classnames(
          "modal-content",
          "onboarding-slide-wrapper",
        )}>
        <div className="onboarding-slide">
          <Carousel
            wrap={false}
            interval={null}
            onSelect={this.handleSelect}
            nextIcon={
              <div
                aria-hidden="true"
                className="onboarding-arrow" />
            }
            prevIcon={
              <div
                aria-hidden="true"
                className="onboarding-arrow" />
            }>
            <Carousel.Item>
              <div className="imgWrapper">
                <img
                  alt="Second slide"
                  className="d-block w-100"
                  src="/images/OnboardingDenounced/onboarding2_slide1.jpg" />
              </div>
              <Carousel.Caption>
                <h3> {t('see-details-denounced-onboarding')} </h3>
                <p> {t('see-details-denounced-onboarding-description')} </p>
              </Carousel.Caption>
            </Carousel.Item>

            <Carousel.Item>
              <div className="imgWrapper">
                <img
                  alt="Third slide"
                  className="d-block w-100"
                  src="/images/OnboardingDenounced/onboarding2_slide2.jpg" />
              </div>
              <Carousel.Caption>
                <h3> {t('denounced-details-analyce-onboarding')} </h3>
                <p> {t('denounced-details-check-onboarding')} </p>
              </Carousel.Caption>
            </Carousel.Item>

            <Carousel.Item>
              <div className="imgWrapper">
                <img
                  alt="Third slide"
                  className="d-block w-100"
                  src="/images/OnboardingDenounced/onboarding2_slide3.jpg" />
              </div>
              <Carousel.Caption>
                <h3> {t('denounced-detail-send-answer-onboarding')} </h3>
                <p> {t('denounced-detail-send-answer-onboarding-description')} </p>
              </Carousel.Caption>
            </Carousel.Item>

          </Carousel>
          {this.state.isLastSlide ?
            <button
              className="btn"
              onClick={this.handleClick}>
              {t('onboarding_slide.start')}
            </button> :
            <button
              className="btn skip"
              onClick={this.handleClick}>
              {t('onboarding_slide.skip')}
            </button>}
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({ profile: state.global.profile });

const actions = {
  openModal,
  closeHelp,
  closeModal,
  updateModal,
};

export default connect(mapStateToProps, actions)(OnBoardingSlide);
