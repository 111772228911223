import React from 'react'
import PoweredBy from "components/PoweredBy";
import PrivacyPolicy from "components/Legal/Privacy";
import TermsConditions from "components/Legal/Terms";
import './footer.scss'

export const Footer = () => {
    return (
        <div className='footer-ctn'>
            <div className='footer-legal-links'>
                <PrivacyPolicy />
                <TermsConditions />
            </div>
            <div className='footer-logo'>
                <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href="https://pulpou.com"
                >
                    <img
                        alt="powered by pulpou"
                        src="/images/portals/powered-by-pulpou.png"
                    />
                </a>
                <div className='divider'></div>
                <span>{`v${process.env.REACT_APP_VERSION}`}</span>
            </div>
        </div>

    )
}
