import { articleFields } from "../fragments";


export default `
  ${articleFields}
  
  query($articleId: String!) {
    article(articleId: $articleId) {
      ...articleFields
    }
  }
`;