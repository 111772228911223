export default `
  query(
    $size: Int,
    $from: String,
    $siteId: String!,
  ) {
    filterProfileSellers(
      size: $size,
      from: $from,
      siteId: $siteId,
    ) {
      size
      from
      total
      sellers {
        id
        nickname
      }
    }
  }
`;