import { alertError } from "./index";
import { t } from "utils";

export const fetchItemReview = (state, payload) => {
  const resetFrom = payload.from === 0;
  const resetItemId = state.itemId !== payload.itemId;
  const resetRating = state.rating !== payload.rating;
  return (resetFrom || resetItemId || resetRating)
        ? []
        : state.itemReviews;
};

export const fetchItemReviewFulfilled = (state, payload) => ({
  ...state,
  isLoading: false,
  hasMore: payload.reviews.hasMore,
  hasPositive: payload.reviews.hasPositive,
  hasNegative: payload.reviews.hasNegative,
  itemReviews: [
    ...state.itemReviews,
    ...payload.reviews.reviews,
  ],
});

export const fetchItemReviewError = (state, payload) => {
  alertError('error en buscar reviews');

  console.log(payload);

  return {
    ...state,
    isLoading: false,
  };
};

export const fetchItemReviewTotalFulfilled = (state, payload) => ({
  ...state,
  isLoadingTotals: false,
  reviewsTotal: payload.reviewsTotal.total,
  reviewsPositiveTotal: payload.reviewsTotal.positive,
  reviewsNegativeTotal: payload.reviewsTotal.negative,
});

export const fetchItemReviewTotalError = (state, payload) => {
  alertError('error en buscar totales de reviews');

  console.log(payload);

  return {
    ...state,
    isLoadingTotals: false,
  };
};