import React from "react";
import { invokeApigWithToken } from "libs/awsLib";
import { t, getLangFromSiteId } from "utils";
import { alertError } from "redux/reducers/utils/index";

const Translate = props => {
  const { 
    text, 
    lang, 
    siteId, 
    parseText, 
    doTranslate, 
    onTranslateError
  } = props;
  const [loading, setLoading] = React.useState(false);
  // const [seeOriginal, setSeeOriginal] = React.useState(false);
  const [translatedText, setTranslatedText] = React.useState(null);

  const siteLang = React.useMemo(() => getLangFromSiteId(siteId), [siteId]);

  React.useEffect(() => {
    if (text && lang && siteLang && lang !== siteLang && doTranslate) {
      setLoading(true);
      invokeApigWithToken({
        method: 'post',
        ownApi: true,
        // eslint-disable-next-line
        path: 'translate',
        body: {
          text, 
          sourceLang: siteLang,
          targetLang: lang,
        },
      }).then(response => {
        if (!response) {
          alertError(t("switch-translate.error"));
          onTranslateError(true);
          return;
        }
        setTranslatedText(response.data);
      })
      .catch(error => {
        console.error('translate error', error);
      })  
        .finally(() => {
          setLoading(false);
        });
    } else {
      setTranslatedText(null);
    }
  }, [text, lang, siteLang, doTranslate, translatedText, onTranslateError]);

  // const handleToggleOriginal = React.useCallback((e) => {
  //   e.preventDefault();
  //   setSeeOriginal(prev => !prev);
  // }, []);

  const Component = props.component || (props => props.children);
  const showTranslated = Boolean(!loading && translatedText);
 
  return (
    <Component>
      {/* {
        !loading && 
        translatedText &&
          <>
            <span className="translation">
              {
                seeOriginal ?
                  <>
                    {t('original')}.&nbsp;
                    <button
                      onClick={handleToggleOriginal}
                    >
                      {t('translate_click_here_translated')}
                    </button> 
                  </> :
                  <>
                    {t('translation')}.&nbsp;
                    <button
                      type="button"
                      onClick={handleToggleOriginal}
                    >
                      {t('translate_click_here_original')}
                    </button>
                  </>
              }
            </span>
          </>
      }
      <br /> */}
      {parseText(showTranslated ? translatedText : text)}
    </Component>
  );
};

export default Translate;