export default `
mutation(
  $status: String,
  $keyword: String!,
  $source: String!,
  $language: String!,
  $currency: String!,
  $sellerId: String,
  $sites: [SiteInputType],
) {
    createLiveSearchReport(report: {
      keyword: $keyword,
      source: $source,
      language: $language,
      currency: $currency
      status: $status,
      sites: $sites,
      sellerId: $sellerId,
    }) {
      id
    }
  }
`;