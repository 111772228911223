export default `
  query(
    $articleId: String!,
    $size: Int!,
    $from: Int!
  ) {
    articleQuestions(
      articleId: $articleId,
      size: $size,
      from: $from
    ) {
      questions {
        date
        text
        answer {
          date
          text
        }
      }
      paging {
        total
      }
    }
  }
`;