import React from 'react';
import { closeModal } from "redux/actions/global";
import { connect } from 'react-redux';


const LinkedItems = props => {
    return (
        <div
            tabIndex="1"
            className="modal-content linked-items">
            <div className="modal-linked-items_container">
                <header>
                    <h1>
                        {props.title}
                        <i className="modal-close" onClick={props.closeModal} />
                    </h1>
                </header>
                <section>
                    <div className="modal-linked-items_list">
                        <ul className="modal-linked-items_ul">
                            {props.items && props.items.map(
                                each => <li key={each.name}>
                                    <a 
                                        href={each.url} 
                                        target="_blank" 
                                        rel="noopener noreferrer" 
                                        onClick={closeModal}>
                                        {each.name}
                                    </a>
                                </li>
                            )}
                        </ul>
                    </div>
                </section>
            </div>
        </div>
    );
}

const actions = {
    closeModal
};

export default connect(null, actions)(LinkedItems);