import React, { Component } from "react";
import { connect } from "react-redux";
import classnames from "classnames";
import { updateProfile } from "redux/actions/global";


class LanguageDropdown extends Component {
  constructor(props) {
    super(props);

    this.state = { open: false };
  }

  toggleDropdown = () => this.setState({ open: !this.state.open });

  handleBlur = () => {
    if (!this.state.open) {
      return;
    }

    this.setState({ open: false });
  }

  handleMouseDown = e => {
    if (!this.state.open) {
      return;
    }

    e.preventDefault();
    e.stopPropagation();
  }

  handleChangeLanguage = lang => () => {
    if (this.props.profile.lang !== lang) {
      this.props.updateProfile({
        ...this.props.profile,
        lang,
      });
    }
  }

  render() {
    return (
      <ul
        tabIndex="1"
        onBlur={this.handleBlur}
        onMouseDown={this.handleMouseDown}
        className="nav lang pull-right top-menu">
        <li
          onClick={this.toggleDropdown}
          className={classnames(
            "dropdown",
            { "open": this.state.open }
          )}>
          <button>
            <span className="language">
              {this.props.lang.toUpperCase()}
            </span>
            <span className="icon-chevron"></span>
          </button>
          <ul
            className={classnames(
              "logout",
              "extended",
              "dropdown-menu",
              "nav-right-settings",
            )}>
            <li onClick={this.handleChangeLanguage('es')}>
              <button rel="nofollow">Español</button>
            </li>
            <li onClick={this.handleChangeLanguage('en')}>
              <button rel="nofollow">English</button>
            </li>
            <li onClick={this.handleChangeLanguage('pt')}>
              <button rel="nofollow">Português</button>
            </li>
          </ul>
        </li>
      </ul>
    );
  }
}

const mapStateToProps = state => ({
  lang: state.global.lang,
  profile: state.global.profile,
});

export default connect(mapStateToProps, { updateProfile })(LanguageDropdown);