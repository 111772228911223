import { combineReducers } from "redux";

import detail from "./detail";
import global from "./global";
import sellers from "./sellers";
import liveSearches from "./liveSearches";
import createAccount from "./createAccount";
import caseManagement from "./caseManagement";
import closedCasesList from "./closedCasesList";
import counterNotices from "./counterNotices";
import pendingDenounces from "./counterNotices";
import noticedArticles from "./noticedArticles";
import pendingCasesList from "./pendingCasesList";
import reportedArticles from "./reportedArticles"; 
import blackListSellers from "./blackListSellers";
import whiteListSellers from "./whiteListSellers";
import suggestedArticles from "./suggestedArticles";
import articlesQuestions from "./articlesQuestions";
import articleDescription from "./articleDescription";
import reviews from "./reviews"

export default combineReducers({
  detail,
  global,
  sellers,
  liveSearches,
  createAccount,
  caseManagement,
  noticedArticles,
  closedCasesList,
  counterNotices,
  pendingDenounces,
  pendingCasesList,
  reportedArticles,
  blackListSellers,
  whiteListSellers,
  suggestedArticles,
  articlesQuestions,
  articleDescription,
  reviews,
}); 