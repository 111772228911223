/* eslint-disable max-len */
/**
 * CONSTANTS
 */
export const FETCH_WHITE_LIST_SELLERS = "FETCH_WHITE_LIST_SELLERS";
export const FETCH_WHITE_LIST_SELLERS_FULLFILLED = "FETCH_WHITE_LIST_SELLERS_FULLFILLED";
export const FETCH_WHITE_LIST_SELLERS_ERROR = "FETCH_WHITE_LIST_SELLERS_ERROR";

export const DELETE_WHITE_LIST_SELLERS = "DELETE_WHITE_LIST_SELLERS";
export const DELETE_WHITE_LIST_SELLERS_SUCCESS = "DELETE_WHITE_LIST_SELLERS_SUCCESS";
export const DELETE_WHITE_LIST_SELLERS_ERROR = "DELETE_WHITE_LIST_SELLERS_ERROR";


/**
 * DISPATCHERS
 */
export const fetchWhiteListSellers = payload => ({ type: FETCH_WHITE_LIST_SELLERS, payload });
export const fetchWhiteListSellersFullFilled = payload => ({ type: FETCH_WHITE_LIST_SELLERS_FULLFILLED, payload });
export const fetchWhiteListSellersError = payload => ({ type: FETCH_WHITE_LIST_SELLERS_ERROR, payload });

export const deleteWhiteListSellers = payload => ({ type: DELETE_WHITE_LIST_SELLERS, payload });
export const deleteWhiteListSellersSuccess = payload => ({ type: DELETE_WHITE_LIST_SELLERS_SUCCESS, payload });
export const deleteWhiteListSellersError = payload => ({ type: DELETE_WHITE_LIST_SELLERS_ERROR, payload });