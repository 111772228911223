import {
  FETCH_NOTICED_ARTICLES,
  NOTICED_ARTICLES_FULFILLED,
  FETCH_NOTICED_ARTICLES_ERROR,
  
  CREATE_REPORT,
  CREATE_REPORT_SUCCESS,
  CREATE_REPORT_ERROR,
  
  FETCH_REPORTS,
  FETCH_REPORTS_SUCCESS,
  FETCH_REPORTS_ERROR,

  DELETE_REPORT,
  DELETE_REPORT_ERROR,
  DELETE_REPORT_SUCCESS,
} from "redux/actions/noticedArticles";

import { 
  createReportError, 
  fetchReportsError,
  deleteReportError,
  createReportSuccess, 
  deleteReportOptimistic,
  fetchNoticedArticlesError,
  fetchNoticedArticlesSuccess,
} from 'redux/reducers/utils/noticedArticles';


const initialState = {
  count: 0,
  reports: [],
  isLoading: false,
  noticedArticles: [],
  creatingReport: false,
  isLoadingReports: false,
  // review
  optimisticErrorData: {},
  noticedArticlesTotal: 0,
  noticedArticlesFrom: null,
  optimisticDataIndex: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
  case FETCH_NOTICED_ARTICLES:
    return {
      ...state,
      isLoading: true,
      noticedArticles: action.payload.from ? 
        state.noticedArticles : [],
    };

  case NOTICED_ARTICLES_FULFILLED:
    return fetchNoticedArticlesSuccess(
      state,
      action.payload
    );
 
  case FETCH_NOTICED_ARTICLES_ERROR:
    return fetchNoticedArticlesError(
      state,
      action.payload
    );

  case CREATE_REPORT:
    return {
      ...state,
      creatingReport: true,
    };

  case CREATE_REPORT_SUCCESS: 
    return createReportSuccess(state);
    
  case CREATE_REPORT_ERROR:
    return createReportError(
      state,
      action.payload
    );
  
  case FETCH_REPORTS: 
    return {
      ...state,
      isLoadingReports: !action.payload.silent,
    };

  case FETCH_REPORTS_SUCCESS: 
    return {
      ...state,
      reports: action.payload,
      isLoadingReports: false,
    };

  case FETCH_REPORTS_ERROR:
    return fetchReportsError(
      state,
      action.payload
    );

  case DELETE_REPORT:
    return deleteReportOptimistic(
      state,
      action.payload,
    );

  case DELETE_REPORT_SUCCESS:
    return {
      ...state,
      isLoadingReports: false,
    };

  case DELETE_REPORT_ERROR: 
    return deleteReportError(state);

  default:
    return state;
  }
};