import { t, uniq } from "utils"; 
import { alertSuccess, alertError } from "./index";


/* eslint-disable no-console */
export const sendNotice = (state, payload) => {
  let noticeInProgress = state.noticeInProgress.splice(0);
  
  const articlesIds = payload ? 
    payload : 
    state.reportedArticles.map(article => article.id);

  noticeInProgress.push(...articlesIds);
  noticeInProgress = uniq(noticeInProgress); 

  return {
    ...state,
    noticeInProgress,
  };
};

export const sendNoticeSuccess = (state, payload) => {
  const sendingNotice = state.sendingNotice.splice(0);
  const noticeInProgress = state.noticeInProgress.splice(0);

  // payload.sendNotice.articlesIds.length > 1 ?
  //   alertSuccess(t('reported_articles.index.send_all_complaints')) :
  //   alertSuccess(t('reported_articles.index.send_complaint')); 

  payload.sendNotice.articlesIds.forEach(articleId => {
    
    articleId = articleId.split("-");

    console.log("articleIdarticleIdarticleId "+articleId[0]);
    const indexOfArticle = noticeInProgress.indexOf(articleId[0]);
    sendingNotice.push(articleId[0]);
    indexOfArticle > -1 && noticeInProgress.splice(indexOfArticle, 1);
  });

  const count = state.count - sendingNotice.length;

  return {
    ...state,
    count,
    sendingNotice,
    noticeInProgress,
    sentNotices: false,
  };
};

export const sendNoticeError = (state, payload) => {
  const { error, articlesIds } = payload;
  const noticeInProgress = state.noticeInProgress.splice(0);

  console.error(error.message);

  if (
    error.message.indexOf('article that was already denounced') !== -1
  ) {
    alertError(t('reported_articles.index.error_send_complaint_already'));
  } else {
    articlesIds && articlesIds.length === 1 ?
      alertError(t('reported_articles.index.error_send_complaint')) :
      alertError(t('reported_articles.index.error_send_all_complaints'));
  }

  articlesIds && articlesIds.forEach(articleId => {
    const indexOfArticle = noticeInProgress.indexOf(articleId);
    indexOfArticle > -1 && noticeInProgress.splice(indexOfArticle, 1);
  });

  return {
    ...state,
    noticeInProgress,
  };
};

export const removeArticleOptimistic = (state, payload) => {
  let reportedArticles = state.reportedArticles.slice(0);
  
  reportedArticles = reportedArticles.map(article => {
    if (article.id === payload.articleId) { article.removing = true; }
    return article;
  });
  
  return {
    ...state,
    isLoading: true,
    reportedArticles,
  };
};

export const removeArticleSuccess = (state, payload) => { 
  let reportedArticles = state.reportedArticles
    .slice(0)
    .filter(article => article.id !== payload.deletedId);
    console.log("payload.deletedId"+payload.deletedId)
    console.log(reportedArticles)
  if (payload.article) {
    reportedArticles.push(payload.article);
  } 

  alertSuccess(t('remove_article_success'));

  return {
    ...state,
    isLoading: false,
    reportedArticles,
    count: state.count - 1,
    reportedArticlesFrom: payload.from,
    reportedArticlesTotal: state.count - 1,
  };
};

export const removeArticleError = (state, payload) => {
  let reportedArticles = state.reportedArticles.slice(0);
  reportedArticles = reportedArticles.map(article => {
    if (article.id === payload.articleId) { delete article.removing; }
    return article;
  });

  alertError(t('remove_article_error'));
  
  return {
    ...state,
    isLoading: false,
    reportedArticles,
  };
};

export const deleteArticlesSuccess = (state, payload) => { 
  const deletedIds   =  payload.deleteArticlesFromDB.articlesIds;
  const deletedCount =  deletedIds.length;
  let reportedArticles = state.reportedArticles
    .slice(0)
    .filter(article => deletedIds.indexOf(article.id) === -1);

  if(deletedCount === 1){
    alertSuccess(t('clean_one_inactive_ready'));
  }
  else {
    alertSuccess(t('clean_all_inactive_ready'));
  }  
 
  return {
    ...state,
    isLoading: false,
    reportedArticles,
    isDeletingArticles: false,
    count: state.count - deletedCount,
    reportedArticlesTotal: state.count - deletedCount,
  };
};

export const deleteArticlesError = state => {
 
  alertError(t('remove_article_error'));
  
  return {
    ...state,
    isLoading: false,
    isDeletingArticles: false,
  };
};

export const fetchReportedArticlesError = state => {
  state.isLoading && alertError(t('server_error'));

  return { 
    ...state, 
    isLoading: false,
  };
}; 

export const reportedArticlesOptimistic = (state, payload) => ({
  ...state,
  isAddingArticles: true,
  count: state.count + payload.articlesIds.length
});

export const reportedArticlesError = (state, payload) => ({
  ...state,
  isAddingArticles: false,
  count: state.count -= payload.articlesIds.length
});

export const reportedArticlesUndoOptimistic = state => ({
  ...state,
  count: state.count - 1,
});

export const fetchSendingNoticeStatusSuccess = (state, payload) => {

 const { articles } = payload;
  


  let { reportedArticles } = state;

  const sendingNotice = state.sendingNotice.slice(0);

  articles.forEach(article => {
    if (
      article.noticed &&
      !article.noticed.sending &&
      sendingNotice.indexOf(article.id) > -1
    ) {
      sendingNotice.splice(sendingNotice.indexOf(article.id), 1);
    }

    reportedArticles = reportedArticles.map(reportedArticle => {
      if (reportedArticle.id === article.id) {
        reportedArticle = {
          ...reportedArticle,
          noticed: {
            ...(reportedArticle.noticed || {}),
            ...(article.noticed || {}),
          }
        };
      }

  
       return reportedArticle;
    });
  });

  let sentNotices = false;
  if(sendingNotice && sendingNotice.length === 0){
    if(articles.length === 1){
      alertSuccess(t('submit_one_reported_ready'));
    }
    else {
      alertSuccess(t('submit_all_reported_ready'));
    }
    sentNotices = true;
  }

  return {
    ...state,
    sendingNotice,
    reportedArticles,
    sentNotices
  };
};

export const editReportedArticlesOptimistic = (state, payload) => {
  let reportedArticles = state.reportedArticles.slice(0);

  reportedArticles = reportedArticles.map(article => {
    if (payload.articlesIds.indexOf(article.id) > -1) { 
      article.editing = true; 
    }
    return article;
  });

  return {
    ...state,
    reportedArticles,
  };
};

export const editReportedArticlesSuccess = (state, payload) => {
  let reportedArticles = state.reportedArticles.slice(0);

  reportedArticles = reportedArticles.map(article => {
    if (payload.articlesIds.indexOf(article.id) > -1) {
      article.editing = false;
    }
    return article;
  });

  alertSuccess(t('edit_reported_article_success'));

  return {
    ...state,
    reportedArticles,
  };
};

export const editReportedArticlesError = (state, payload) => {
  let reportedArticles = state.reportedArticles.slice(0);
  reportedArticles = reportedArticles.map(article => {
    if (article.id === payload.articleId) { delete article.editing; }
    return article;
  });

  alertError(t('edit_reported_article_error'));

  return {
    ...state,
    reportedArticles,
  };
};

export const setNotShowMoreError = (state) => {
  alertError(t('server_error'));
  return state;
};


export const errorSendingBrandInfringement = (state, payload) => {
  console.error(payload);
  //alertError(t('brandInfringement.send_error'));
  return state;
};