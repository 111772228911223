export default `
  {
    profile {
      meliAccount {
        accountType
        contactName
        contactPhone
        accountDisplayName
        identificationType
        identificationName
        identificationNumber
        identificationCountry
        accountDisplayEmail
        accountLang
        isVerified
        contactEmail
        accountId
        accountName
        holderContactName
        holderContactEmail
      }
    }
  }
`;