export default `
  mutation(
    $title: String!,
    $origin: String!,
    $currency: String!,
    $language: String!,
    $dateFrom: String,
    $dateTo: String,
  ) {
    createNoticedReport(report: {
      title: $title,
      origin: $origin,
      currency: $currency,
      language: $language,
      dateFrom: $dateFrom,
      dateTo: $dateTo,
    }) {
      id
    }
  }
`;