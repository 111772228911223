import React from "react";
import { connect } from "react-redux";
import classnames from "classnames";
import { closeModal } from "redux/actions/global";
import ChangeProfile from "components/ChangeProfile";
import CreateProfile from "components/CreateProfile";
import { t } from "utils";


const ManageProfile = props => {
  const {
    closeModal,
    switchToCreateProfile,
  } = props;

  return(
    <div className={classnames(
      "modal-content",
      { "profiles-modal-content": !switchToCreateProfile },
      { "create-profiles-modal-content": switchToCreateProfile },
    )}>
      <div className="divider-with-close">
        <div className="profiles-title">
          {switchToCreateProfile ? 
            t("new_profile") : 
            t("select_profile")}
        </div>
        <div
          className="close-btn"
          onClick={() => closeModal()}>
          x
        </div>
      </div>
      {
        switchToCreateProfile ?
          <CreateProfile /> :
          <ChangeProfile />
      }
    </div>
  );
};

const mapStateToProps = state => ({
  isFetchingProfiles: state.global.isFetchingProfiles,
  switchToCreateProfile: state.global.switchToCreateProfile,
});

export default connect(mapStateToProps, { closeModal })(ManageProfile);