import React, { useState, useCallback, useEffect } from "react";
import { connect } from "react-redux";
import { faCheckCircle } from "@fortawesome/free-solid-svg-icons";
import { faTrashAlt } from "@fortawesome/free-regular-svg-icons";
import { openModal, closeModal } from "redux/actions/global";
import Icon from "components/Icon";
import ImportedFileDetails from "components/ImportedFileDetails";
import { reportArticles } from "redux/actions/detail";
import { t } from "utils";
import Alert from "components/Alert";

function BlindageReportExcel(props) {
  const {
    profile,
    closeModal,
    blindagesIds,
    articlesIds,
    blindagesInfoExcel,
    lang,
  } = props;

  const [invalidIds, setInvalidIds] = useState([]); // duplicated
  const [notFoundIds, setNotFoundIds] = useState([]); // error

  const handleClose = useCallback(closeModal, [closeModal]);

  const handleSubmit = useCallback(
    (e) => {
      e.preventDefault();
      setTimeout(() => {
        window.location.pathname = "/reported_articles";
      }, 100);
    },
    [profile, props]
  );

  // const results = blindagesIds.length + articlesIds.length;

  return (
    <div tabIndex="1" className="modal-content add-suspicious-xlsx-modal">
      <form onSubmit={handleSubmit} className="new_reported_article">
        <div className="top-container">
          <div className="title">{t("blindage.modal_excel.title")}</div>

          <button onClick={handleClose} className="btn-modal-close">
            &times;
          </button>
          {articlesIds.length > 0 && (
            <div className="subtitle">
              {blindagesIds.length > 0
                ? t("blindage.modal_excel.subtitle_errors")
                : t("blindage.modal_excel.subtitle_success")}
            </div>
          )}
        </div>
        {
          <>
            {articlesIds.length > 0 && (
              <div className="results-container">
                {blindagesIds.length > 0 && (
                  <div className="results">
                    {props.acceptedFiles ? props.acceptedFiles[0].name : ""}
                    &nbsp;
                    {/* <span>
                      {results}&nbsp;
                      {results === 1
                        ? t("add_suspicious_xlsx.element")
                        : t("add_suspicious_xlsx.elements")}
                    </span> */}
                  </div>
                )}

                <div className="numbers">
                  <p>
                    <Icon
                      icon={faCheckCircle}
                      iProps={{ className: "check" }}
                    />
                    {articlesIds.length}&nbsp;
                    {articlesIds.length === 1
                      ? t("blindage.modal_excel.notBlindage_found")
                      : t("blindage.modal_excel.notBlindages_found")}
                  </p>
                </div>
              </div>
            )}

            {blindagesIds.length > 0 ? (
              <Alert
                alertType="warning"
                styleType="not-filled"
                className="alert__snack-translate-message"
              >
                <div className="icon__container">
                  <img alt="alert" src="/images/alerts/caution.svg" />
                </div>

                <strong>
                  {articlesIds.length > 0 ? (
                    <>
                      {blindagesIds.length}&nbsp;
                      {blindagesIds.length === 1
                        ? t("blindage.modal_excel.blindage_found")
                        : t("blindage.modal_excel.blindages_found")}
                    </>
                  ) : (
                    <>
                      {blindagesIds.length === 1
                        ? t("blindage.modal_excel.blindage_found2")
                        : t("blindage.modal_excel.blindages_found2")}
                    </>
                  )}
                </strong>

                <div className="content">
                  {t("blindage.modal_excel.blindage_content")}
                </div>

                <ImportedFileDetails
                  lang={lang}
                  invalidIds={invalidIds}
                  articlesIds={articlesIds}
                  notFoundIds={notFoundIds}
                  blindagesInfoExcel={blindagesInfoExcel}
                />
              </Alert>
            ) : (
              <></>
            )}
          </>
        }

        <div className="actions">
          <div className="content">
            <button
              // onClick={handleSubmit}
              className="btn btn-success"
            >
              {t("ls_reported_articles_link")}
            </button>
            <button onClick={handleClose} className="btn transparent">
              {t("cancel")}
            </button>
          </div>
        </div>
      </form>
    </div>
  );
}

const mapStateToProps = (state) => ({ profile: state.global.profile });

export default connect(mapStateToProps, {
  openModal,
  closeModal,
  reportArticles,
})(BlindageReportExcel);
