import { 
  HANDLE_CHANGE_USERS,
  
  CREATE_ACCOUNT,
  CREATE_ACCOUNT_SUCCESS,
  CREATE_ACCOUNT_ERROR,
} from '../actions/createAccount';

import { 
  handleChangeUsers,
  createAccountSuccess,
  createAccountError,
} from './utils/createAccount';


const initialState = {
  isCreatingAccount: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
  case HANDLE_CHANGE_USERS:
    return handleChangeUsers(
      state, 
      action.payload
    );

  case CREATE_ACCOUNT:
    return {
      ...state,
      isCreatingAccount: true,
    };

  case CREATE_ACCOUNT_SUCCESS:
    return createAccountSuccess(state);

  case CREATE_ACCOUNT_ERROR:
    return createAccountError(
      state, 
      action.payload
    );

  default:
    return state;
  }
}; 