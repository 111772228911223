import React from 'react'
import { useState } from 'react';
import { connect } from "react-redux";
import { closeModal } from "redux/actions/global";
import { t, getDenounceStatusFilterPillV3 } from "utils";



const FilterReportedDenounced = ({ statusSelected, closeModal, onClear, onApply }) => {

  const statusOptions = [
    "APPROVED",
    "IN_PROGRESS",
    "PENDING",
    "REJECTED",
    "DISCARDED",
    "ROLLBACK",
  ]
  const [checkedOptions, setCheckedOptions] = useState(statusSelected)

  const handleCheckboxChange = (event) => {
    const value = event.target.value;
    if (event.target.checked) {
      setCheckedOptions((prevOptions) => [...prevOptions, value]);
    } else {
      setCheckedOptions((prevOptions) => prevOptions.filter((option) => option !== value));
    }
  };

  const handleClear = () => {
    onClear()
    closeModal()
  }
  const handleApply = () => {
    onApply(checkedOptions)
    closeModal()
  }


  return (

    <div
      tabIndex="1"
      className="modal-content filter-selector">
      <div className="modal-filter-selector_container">
        <header>
          <h1>
            <span>{t('filter_text_2')}</span>
            <i className="modal-close" onClick={() => closeModal()} />
          </h1>
        </header>
        <section>
          <form onSubmit={e => e.preventDefault()}>
            <div className="form-group options-filters height">
              <div className="filter2">
                <span className="title">
                  {t('filter_modal.filter_4')}
                </span>
                <div className="reasonsFilterCtn">
                  <div className="reasonsCtn">
                    {
                      statusOptions.map(option =>
                        <label key={option} className="reason-item">
                          <input
                            type="checkbox"
                            name="status"
                            value={option}
                            defaultChecked={statusSelected.includes(option)}
                            onChange={handleCheckboxChange}
                          />
                          {getDenounceStatusFilterPillV3(option)}
                        </label>
                      )
                    }
                  </div>
                </div>
              </div>
            </div>
          </form>
        </section>
        <div className="actions">
          <button
            onClick={() => handleClear()}
            className="btn transparent">
            {t('filter_modal.clean')}
          </button>
          <button
            className="btn"
            onClick={() => handleApply()}>
            {t('filter_modal.apply_2')}
          </button>
        </div>
      </div>
    </div>
  );

}

export default connect(null, { closeModal })(FilterReportedDenounced);
