export default `
  fragment filterProfileFields on FilterProfileType {
    id 
    name
    sites {
      id 
      countrySites
    }
    priceMin 
    priceMax
    categoryId
    sellerNickname
    articleCondition
    priceMinConverted
    priceMaxConverted
  }
`;