const UserConfirmation = (message, callback) => {
  const { title, subtitle, okText, cancelText } = JSON.parse(message);
  const goToOtherPage = (callbackState) => {
    callback(callbackState);
  };

  window.mscConfirm({
    title,
    subtitle,
    okText,
    cancelText,
    onOk: () => goToOtherPage(false),
    onCancel: () => goToOtherPage(true)
  });
};

export default UserConfirmation;