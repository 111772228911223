export default `
  mutation (
    $lang: String!,
    $currency: String!,
    $givenName: String!,
    $familyName: String,
  ) {
    createProfile(profile: {
      lang: $lang
      currency: $currency
      givenName: $givenName
      familyName: $familyName
    }) {
      id
      lang 
      currency
      givenName
      familyName
    }
  }
`;