import React from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

function Icon(props) {
  const { iProps, ...rest } = props;
  return (
    <i {...iProps}> 
      <FontAwesomeIcon {...rest} />
    </i>
  );
}

export default Icon;