import React from "react";
import classnames from "classnames";
import './image-gallery.scss';
import { openModal } from "redux/actions/global";
import { ARTICLE_IMAGES } from "components/Modal";
import { connect } from "react-redux";

function ImageGallery(props) {
  const { images, fallbackImage, openModalOnClick, originalImages, viewMore } = props;
  const [currentImageIndex, setCurrentImageIndex] = React.useState(0);

  const openArticleImagesModal = originalImages => {
    props.openModal({ 
      type: ARTICLE_IMAGES,
      images: originalImages
    });
  };

  let imagesLogic = images;
  if(viewMore){
    imagesLogic = images && images.slice(0, 3);
  }

  return (
    <div
      className="image-gallery"
      style={{ minHeight: '479px', overflowY: 'hidden' }}
    >
      <div className="image-gallery-wrapper">
        <div className="image-gallery-figures">
          {
            (imagesLogic && imagesLogic.length) ?
              imagesLogic
                .map((image, i) =>
                  <React.Fragment key={`thumbnail_fragment_figures_${i}`}>
                    <input
                      key={`thumbnail_radio_${i}`}
                      type="radio"
                      id={`thumbnail-radio-${i}`}
                      name="thumbnail-radio"
                      defaultChecked={i === currentImageIndex} 
                    />
                    <figure
                      key={`thumbnail_figure_${i}`}
                      className="image-container"
                    >
                      <div className="image-trigger">
                        <img
                          alt={image.alt}
                          src={image.src}
                        />
                      </div>  
                    </figure>
                  </React.Fragment>
                ) :
              (
                <>
                  <input type="radio" name="thumbnail-radio" defaultChecked={true} />
                  <figure className="image-container">
                    <div className="image-trigger">
                      <img
                        alt={fallbackImage.alt}
                        src={fallbackImage.src}
                      />
                    </div>
                  </figure>
                </>
              )
          }
        </div>
        <div className="image-gallery-labels">
          {
            (imagesLogic && imagesLogic.length) &&
              imagesLogic
                .map((image, i) =>
                  <React.Fragment key={`thumbnail_fragment_${i}`}>
                    <label
                      key={`thumbnail_${i}`}
                      htmlFor={`thumbnail-radio-${i}`}
                      className={classnames(
                        "label_thumbnail",
                        { "selected": i === currentImageIndex }
                      )}
                    >
                      <img
                        width="70"
                        height="70"
                        key={`image_${i}`}
                        alt={image.alt}
                        src={image.thumbnail}
                        onMouseOver={e => {
                          if(openModalOnClick){
                            if(i < 2){
                              e.target.click();
                              if(document.getElementById(`zoom_${i}`)){
                                document.getElementById(`zoom_${i}`).style.display = 'block';
                              }
                            }
                          }
                          else {
                            e.target.click();
                          }
                        }}
                        onClick={() => { 
                          if(openModalOnClick){
                            if(i !== 2){
                              setCurrentImageIndex(i);
                            }
                          }
                          else {
                            setCurrentImageIndex(i);
                          }
                        }}
                      />
                      {
                        (
                          openModalOnClick
                          // (i !== 2 && imagesLogic.length === images.length)
                        ) &&
                        <div 
                          id={`zoom_${i}`} 
                          className={classnames(
                            "zoom-in",
                          )}
                          onMouseLeave={e => {
                            e.target.style.display = 'none';
                          }}
                          onClick={() => { 
                            openArticleImagesModal(originalImages);
                          }}
                        ></div>
                      }
                      {
                        viewMore && 
                        imagesLogic.length < images.length &&
                        i === 2 &&
                        <div 
                          id={`view-more`} 
                          className={classnames(
                            "view-more",
                          )}
                          onClick={() => { 
                            openArticleImagesModal(originalImages);
                          }}
                        >
                          + {images.length - imagesLogic.length}
                        </div>
                      }
                    </label>
                  </React.Fragment>
                )
          }
        </div>
      </div>
    </div> 
  );
}

const actions = {
  openModal,
};

export default connect(null, actions)(ImageGallery); 
