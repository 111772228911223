/* eslint-disable max-len */
/**
 * CONSTANTS
 */
export const FETCH_LIVE_SEARCH = "FETCH_LIVE_SEARCH";
export const LIVE_SEARCH_FULFILLED = "LIVE_SEARCH_FULFILLED";
export const LIVE_SEARCH_ERROR = "LIVE_SEARCH_ERROR";

export const RESET_DETAIL = "RESET_DETAIL";

export const CHANGE_LIVE_SEARCH = "CHANGE_LIVE_SEARCH";

export const CHANGE_LIVE_SEARCH_KEYWORD = "CHANGE_LIVE_SEARCH_KEYWORD";

export const LIVE_SEARCH_ADD_DATA = "LIVE_SEARCH_ADD_DATA";

export const SET_REPORTING_STATUS = "SET_REPORTING_STATUS";

export const APPLY_FILTER = "APPLY_FILTER";
export const APPLY_FILTER_FULFILLED = "APPLY_FILTER_FULFILLED";
export const APPLY_FILTER_ERROR = "APPLY_FILTER_ERROR";

export const APPROVE_ARTICLE = "APPROVE_ARTICLE";
export const APPROVE_ARTICLE_SUCCESS = "APPROVE_ARTICLE_SUCCESS";
export const APPROVE_ARTICLE_ERROR = "APPROVE_ARTICLE_ERROR";

export const APPROVE_ARTICLE_UNDO = "APPROVE_ARTICLE_UNDO";
export const APPROVE_ARTICLE_UNDO_SUCCESS = "APPROVE_ARTICLE_UNDO_SUCCESS";
export const APPROVE_ARTICLE_UNDO_ERROR = "APPROVE_ARTICLE_UNDO_ERROR";

export const REPORT_ARTICLES = "REPORT_ARTICLES";
export const REPORT_ARTICLES_SUCCESS = "REPORT_ARTICLES_SUCCESS";
export const REPORT_ARTICLES_ERROR = "REPORT_ARTICLES_ERROR";

export const DISCARD_ARTICLES = "DISCARD_ARTICLES";
export const DISCARD_ARTICLES_SUCCESS = "DISCARD_ARTICLES_SUCCESS";
export const DISCARD_ARTICLES_ERROR = "DISCARD_ARTICLES_ERROR";

export const REPORT_ARTICLE_UNDO = "REPORT_ARTICLE_UNDO";
export const REPORT_ARTICLE_UNDO_SUCCESS = "REPORT_ARTICLE_UNDO_SUCCESS";
export const REPORT_ARTICLE_UNDO_ERROR = "REPORT_ARTICLE_UNDO_ERROR";

export const EDIT_REPORTED_ARTICLES = "EDIT_REPORTED_ARTICLES";
export const EDIT_REPORTED_ARTICLES_SUCCESS = "EDIT_REPORTED_ARTICLES_SUCCESS";
export const EDIT_REPORTED_ARTICLES_ERROR = "EDIT_REPORTED_ARTICLES_ERROR";

export const BATCH_APPLY = "BATCH_APPLY";
export const BATCH_APPLY_SUCCESS = "BATCH_APPLY_SUCCESS";
export const BATCH_APPLY_ERROR = "BATCH_APPLY_ERROR";

export const BATCH_TOGGLE = "BATCH_TOGGLE";
export const BATCH_APPROVED_UPDATE = "BATCH_APPROVED_UPDATE";
export const BATCH_REPORTED_UPDATE = "BATCH_REPORTED_UPDATE";

export const RESET_PROCESSED_COUNT = "RESET_PROCESSED_COUNT";

export const SET_ACTIVE_PAGE = "SET_ACTIVE_PAGE";

export const RESET_SHOW_IMPORT_SUCCESS = "RESET_SHOW_IMPORT_SUCCESS";

export const SET_LIVESEARCH_ARTICLES_IDS = "SET_LIVESEARCH_ARTICLES_IDS";

/**
 * DISPATCHERS
 */
export const fetchLiveSearch = payload => ({ type: FETCH_LIVE_SEARCH, payload });
export const liveSearchFulfilled = payload => ({ type: LIVE_SEARCH_FULFILLED, payload });
export const liveSearchError = payload => ({ type: LIVE_SEARCH_ERROR, payload });

export const resetDetail = () => ({ type: RESET_DETAIL });

export const changeLiveSearch = liveSearch => ({ type: CHANGE_LIVE_SEARCH, payload: { liveSearch } });

export const changeLiveSearchKeyword = payload => ({ type: CHANGE_LIVE_SEARCH_KEYWORD, payload });

export const setReportingStatus = payload => ({ type: SET_REPORTING_STATUS, payload });

export const applyFilter = () => ({ type: APPLY_FILTER });
export const applyFilterFulfilled = payload => ({ type: APPLY_FILTER_FULFILLED, payload });
export const applyFilterError = payload => ({ type: APPLY_FILTER_ERROR, payload });

export const approveArticle = payload => ({ type: APPROVE_ARTICLE, payload });
export const approveArticleSuccess = payload => ({ type: APPROVE_ARTICLE_SUCCESS, payload });
export const approveArticleError = payload => ({ type: APPROVE_ARTICLE_ERROR, payload });

export const approveArticleUndo = payload => ({ type: APPROVE_ARTICLE_UNDO, payload });
export const approveArticleUndoSuccess = payload => ({ type: APPROVE_ARTICLE_UNDO_SUCCESS, payload });
export const approveArticleUndoError = payload => ({ type: APPROVE_ARTICLE_UNDO_ERROR, payload });

export const reportArticles = payload => ({ type: REPORT_ARTICLES, payload });
export const reportArticlesSuccess = payload => ({ type: REPORT_ARTICLES_SUCCESS, payload });
export const reportArticlesError = payload => ({ type: REPORT_ARTICLES_ERROR, payload });

export const discardArticles = payload => ({ type: DISCARD_ARTICLES, payload });
export const discardArticlesSuccess = payload => ({ type: DISCARD_ARTICLES_SUCCESS, payload });
export const discardArticlesError = payload => ({ type: DISCARD_ARTICLES_ERROR, payload });

export const reportArticleUndo = payload => ({ type: REPORT_ARTICLE_UNDO, payload });
export const reportArticleUndoSuccess = payload => ({ type: REPORT_ARTICLE_UNDO_SUCCESS, payload });
export const reportArticleUndoError = payload => ({ type: REPORT_ARTICLE_UNDO_ERROR, payload });

export const editReportedArticles = payload => ({ type: EDIT_REPORTED_ARTICLES, payload });
export const editReportedArticlesSuccess = payload => ({ type: EDIT_REPORTED_ARTICLES_SUCCESS, payload });
export const editReportedArticlesError = payload => ({ type: EDIT_REPORTED_ARTICLES_ERROR, payload });

export const batchApply = payload => ({ type: BATCH_APPLY, payload });
export const batchApplySuccess = () => ({ type: BATCH_APPLY_SUCCESS });
export const batchApplyError = payload => ({ type: BATCH_APPLY_ERROR, payload });

export const batchToggle = () => ({ type: BATCH_TOGGLE });
export const batchApprovedUpdate = batchApproved => ({ type: BATCH_APPROVED_UPDATE, payload: { batchApproved } });
export const batchReportedUpdate = batchReported => ({ type: BATCH_REPORTED_UPDATE, payload: { batchReported } });

export const setActivePage = payload => ({ type: SET_ACTIVE_PAGE, payload });

export const resetShowImportSucces = () => ({ type: RESET_SHOW_IMPORT_SUCCESS });

export const setLiveSearchArticlesIds = payload => ({ type: SET_LIVESEARCH_ARTICLES_IDS, payload });