import React, { Component } from "react";
import { connect } from "react-redux";
import { Link, withRouter } from "react-router-dom";
import { faAngleRight } from '@fortawesome/free-solid-svg-icons';
import Icon from "components/Icon";
import { t, objEqual, liveSearchDisplayName } from "utils";


class BreadCrumb extends Component {
  shouldComponentUpdate(nextProps) {
    if (
      (
        this.props.location !== nextProps.location &&
        objEqual(this.props.location, nextProps.location)
      ) || (
        this.props.liveSearches !== nextProps.liveSearches &&
        objEqual(this.props.liveSearches, nextProps.liveSearches)
      )
    ) {
      return false;
    }
    
    return true;
  }

  getLiveSearchDisplayName = id => {
    const {
      liveSearch,
      liveSearches,
    } = this.props;

    let ls = liveSearch && liveSearch.id === id ?
      liveSearch :
      liveSearches ?
        liveSearches.find(liveS => liveS.id === id) :
        null;

    return ls ?
      liveSearchDisplayName(ls) :
      '...';
  };

  render() {
    const { location } = this.props;
    const pathname = location.pathname.split('/');

    let first;
    let second;
    let third;
    
    switch (pathname[1]) {

    case 'suggested_listings':
      first = t('shared.side_navigation.suggested_listings');
      break;

    case '':
    case 'live_searches':
      first = t('shared.side_navigation.searches');
      second = t('live_searches');
      if (pathname[2]) {
        second = (
          <Link
            to="/live_searches"
            style={{ color: "#333", cursor: "pointer" }}>
            {t('live_searches')}
          </Link>
        );
        third = this.getLiveSearchDisplayName(pathname[2]);
      }
      break;
  
    case 'reported_articles':
      first = t('shared.side_navigation.articles_to_report');
      break;
  
    case 'client_accounts':
      first = t('shared.side_navigation.sellers');
      second = pathname[2] === "client_white_sellers" ?
        t('shared.side_navigation.sellers_white') :
        t('shared.side_navigation.sellers_black');
      break;
  
    case 'livesearch_reports':
      first = t('shared.side_navigation.livesearch_reports');
      break;
  
    case 'reports':
      first = t('reports');
      second = pathname[2] === "noticed_articles" ?
        t('shared.side_navigation.last_reported_listings') :
        t('shared.side_navigation.custom_reports');
      break;
  
    case 'case_management':
      first = t('shared.side_navigation.management_case');
      second = pathname[2] === "client_pending_cases" || (location.table && location.table === 'pendingList') ?
        t('shared.side_navigation.cases_pending') :
        t('shared.side_navigation.cases_closed');
      break;
  
    case 'help_support':
      first = t('shared.side_navigation.support_help');
      break;

    case 'article_detail':
      first = t('article_detail.breadcrumb_label');
      second = pathname[2];
      break;
  
    default:
      return null;
    }
  
    if (!first) return null;
  
    return (
      <ol className="breadcrumb">
        <li>{first}</li>
        {second &&
          <div className="icon">
            <Icon icon={faAngleRight} />
          </div>}
        {second && <li>{second}</li>}
        {third &&
          <div className="icon">
            <Icon icon={faAngleRight} />
          </div>}
        {third && <li>{third.toUpperCase()}</li>}
      </ol>
    );
  }
}

const mapStateToProps = state => ({
  lang: state.global.lang,
  liveSearch: state.detail.liveSearch,
  liveSearches: state.liveSearches.liveSearches,
});

export default withRouter(connect(mapStateToProps, null)(BreadCrumb));
