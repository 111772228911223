export default `
  query($caseId: String!, $siteId: String!) {
    caseDescription(caseId: $caseId, siteId: $siteId) {  
      counterNotice {
        caseId
        status
        auditor

        countryName
        countryCode
        
        denounceDate
        denounceReasonId
        denounceReasonText
        denounceDescription
        
        limitResponseDate
        groupResponse
        
        documentUrl
        documentName
         documents {
           name
           url
          }
        sellerResponse
        counterNoticeDate

        lastUpdated

        articleCurrency
        articleSoldQuantity
        
        articleId
        articleTitle
        articlePrice
        articleSnapshotUrl
        articleDescription
        articleImages {
          url
          size
          max_size
        }

        photosNew {
          id
          src
        }
        photosDenounced {
          id
          src
          status
        }

        sellerDenounces
        sellerCounterNotices
        sellerCounterNoticesAccepted

        rejectOptionMember {
          id
          reason
          text_es
          sub_text_es
          text_en
          sub_text_en
          text_pt
          sub_text_pt
        }

        rejectMember {
          id
          text_es
          sub_text_es
          text_en
          sub_text_en
          text_pt
          sub_text_pt
        }

        rejectOption {
          id
          text_es
          sub_text_es
          text_en
          sub_text_en
          text_pt
          sub_text_pt
        }

        isRollbackable
        caseDetailRollback

      }
      seller {
        id
        sold
        amount
        siteId
        nickname
        pulpouScore
        blackSeller
        whiteSeller
        countryName
        address {
          city
          state
          neighborhood
        }
      }
    }
  }
`;