import { combineEpics } from "redux-observable";
import {
  closeHelpEpic,
  fetchSitesEpic,
  fetchProfileEpic,
  fetchProfilesEpic,
  createProfileEpic,
  fetchCurrencyEpic,
  updateProfileEpic,
  getCredentialsEpic,
  fetchMeliAccountEpic,
  setArticlesPinnedEpic,
  updateMeliAccountEpic,
  sendMessageToSupportEpic,
  updateProfileSettingsEpic,
  fetchMemberRestrictionEpic,
  fetchEfficiencyEpic,
  fetchMenuEpic,
  createSellersLiveSearchEpic,
  fetchProfileAllowedNoticesEpic,
  setDiscardTooltipAlreadyShown,
  setTranslateTooltipAlreadyShown,
  setNewReasonTooltipAlreadyShown,
  fetchBlindagesEpic,
} from "./global";
import {
  batchApplyEpic,
  applyFilterEpic,
  approveArticleEpic,
  reportArticlesEpic,
  discardArticlesEpic,
  fetchLiveSearchEpic,
  reportArticleUndoEpic,
  approveArticleUndoEpic,
  fetchArticleDetailEpic,
  fetchCatalogoDetailEpic,
  fetchCatalogosDetailEpic,
  editReportedArticlesEpic,
} from "./detail";
import {
  searchPreviewEpic,
  getLiveSearchesEpic,
  deleteLiveSearchEpic,
  createLiveSearchEpic,
  deleteLiveSearchErrorEpic,
  createLiveSearchReportEpic,
  fetchLiveSearchReportsEpic,
  deleteLiveSearchReportEpic,
  createSellerLiveSearchEpic,
  createLiveSearchSuccessEpic,
  fetchFilterProfileSellersEpic,
} from "./liveSearches";
import {
  sendNoticeEpic,
  removeArticleEpic, 
  setNoticesLimitDayCount,
  deleteArticlesEpic, 
  setTooltipNotShowMoreEpic,
  fetchReportedArticlesEpic,
  fetchSendingNoticeStatusEpic,
  fetchReportedArticlesCountEpic,
  fetchShowTooltipStatusEpic,
  fetchShowTooltipImportStatusEpic,
  setShowReportImportTooltipUntilEpic,
  sendBrandInfringementEpic,
} from "./reportedArticles";
import {
  fetchSuggestedArticlesEpic,
  fetchSuggestedArticlesStatsEpic,
  fetchSuggestedReportedCountEpic,
} from "./suggestedArticles";
import {
  fetchCounterNoticesEpic,
  fetchPendingDenouncesEpic,
} from "./counterNotices";

import { 
  createReportEpic,
  fetchReportsEpic,
  deleteReportEpic,
  fetchNoticedArticlesEpic,
} from "./noticedArticles";
import { fetchArticleQuestionsEpic } from "./articlesQuestions";
import { fetchItemReviewsEpic, fetchItemReviewsTotalsEpic } from "./reviews";
import { fetchArticleDescriptionEpic } from "./articleDescription";
import { 
  fetchBlackListSellersEpic,
  deleteBlackListSellersEpic,
} from "./blackListSellers";
import { 
  fetchWhiteListSellersEpic,
  deleteWhiteListSellersEpic,
} from "./whiteListSellers";
import { 
  fetchSellerEpic,
  setBlackSellerEpic,
  setWhiteSellerEpic,
  unsetBlackSellerEpic,
  unsetWhiteSellerEpic,
} from "./sellers"; 
import { 
  fetchPendingCasesListEpic,
  fetchPendingCasesToExpireEpic, 
} from "./pendingCasesList";
import { fetchClosedCasesListEpic } from "./closedCasesList";
import {
  respondCounterNoticeEpic,
  fetchCaseDescriptionEpic,
  fetchProfilesOptionsEpic,
  fetchSellerCounterNoticesEpic,
  rollbackCaseEpic,
} from "./caseManagement";


const rootEpic = combineEpics(
  /* global */  
  closeHelpEpic,
  fetchSitesEpic,
  fetchProfileEpic,
  fetchProfilesEpic,
  fetchCurrencyEpic,
  updateProfileEpic,
  createProfileEpic,
  getCredentialsEpic,
  fetchMeliAccountEpic,
  setArticlesPinnedEpic,
  updateMeliAccountEpic,
  sendMessageToSupportEpic,
  updateProfileSettingsEpic,
  fetchMemberRestrictionEpic,
  fetchEfficiencyEpic,
  createSellersLiveSearchEpic,
  fetchProfileAllowedNoticesEpic,
  setDiscardTooltipAlreadyShown,
  setTranslateTooltipAlreadyShown,
  setNewReasonTooltipAlreadyShown,
  fetchMenuEpic,
  fetchBlindagesEpic,

  /* detail */
  batchApplyEpic,
  applyFilterEpic,
  approveArticleEpic,
  reportArticlesEpic,
  discardArticlesEpic,
  fetchLiveSearchEpic,
  reportArticleUndoEpic,
  approveArticleUndoEpic,
  fetchArticleDetailEpic,
  fetchCatalogoDetailEpic,
  fetchCatalogosDetailEpic,
  editReportedArticlesEpic,
	
  /* liveSearches */
  searchPreviewEpic,
  getLiveSearchesEpic, 
  deleteLiveSearchEpic,
  createLiveSearchEpic,
  deleteLiveSearchErrorEpic,
  createLiveSearchReportEpic,
  fetchLiveSearchReportsEpic,
  deleteLiveSearchReportEpic, 
  createSellerLiveSearchEpic,
  createLiveSearchSuccessEpic,
  fetchFilterProfileSellersEpic,

  /* reportedArticles */
  sendNoticeEpic,
  removeArticleEpic,
  setNoticesLimitDayCount,
  deleteArticlesEpic,
  setTooltipNotShowMoreEpic,
  fetchReportedArticlesEpic,
  fetchShowTooltipStatusEpic, 
  fetchSendingNoticeStatusEpic,
  fetchReportedArticlesCountEpic,
  fetchShowTooltipImportStatusEpic, 
  setShowReportImportTooltipUntilEpic,
  sendBrandInfringementEpic,

  /* suggestedArticles */
  fetchSuggestedArticlesEpic,
  fetchSuggestedArticlesStatsEpic,
  fetchSuggestedReportedCountEpic,

  /* counterNotices */
  fetchCounterNoticesEpic,
  fetchPendingDenouncesEpic,
  fetchPendingDenouncesEpic,

  /* noticedArticles */
  createReportEpic,
  fetchReportsEpic,
  deleteReportEpic,
  fetchNoticedArticlesEpic,

  /* articlesQuestion */
  fetchArticleQuestionsEpic,

  /* reviews */
  fetchItemReviewsEpic,
  fetchItemReviewsTotalsEpic,

  /* articleDescription */
  fetchArticleDescriptionEpic,

  /* blackListSellers */
  fetchBlackListSellersEpic,
  deleteBlackListSellersEpic,
    
  /* whiteListSellers */
  fetchWhiteListSellersEpic,
  deleteWhiteListSellersEpic,

  /* sellers */
  fetchSellerEpic, 
  setBlackSellerEpic,
  setWhiteSellerEpic,
  unsetBlackSellerEpic,
  unsetWhiteSellerEpic,

  /* pendingCasesList */ 
  fetchPendingCasesListEpic,
  fetchPendingCasesToExpireEpic,

  /* closedCasesList */
  fetchClosedCasesListEpic,

  /* caseManagement */
  respondCounterNoticeEpic,
  fetchCaseDescriptionEpic,
  fetchProfilesOptionsEpic,
  fetchSellerCounterNoticesEpic,
  rollbackCaseEpic,
);

export default rootEpic;