import React, { Component } from "react";
import classnames from "classnames";
import { connect } from "react-redux";
import { Carousel } from "react-bootstrap";
import {
  openModal,
  closeHelp,
  closeModal,
  updateModal,
} from "redux/actions/global";
import { t } from "utils";

class DenouncedOnBoardingSlide extends Component {
  constructor(props) {
    super(props);

    this.state = { 
      index: 0,
    };
  }

  getImagePath(subPath) {
    return `/images/OnboardingDenounced/${subPath}`;
  }
  
  handleClick = () => {
    this.props.closeHelp('onBoardingPendingDenounced');
    this.props.closeModal();
    if (typeof this.props.onClose === "function") {
      this.props.onClose();
    }
  }

  handleSelect = (selectedIndex)  => {
    this.setState({ index: selectedIndex });
  }

  handleSelectBtn = ()  => {
    this.setState({ index: this.state.index + 1 });
  }

  render() {
    return (
      <>
        <div
          tabIndex="1"
          className={classnames(
            "modal-content",
            "onboarding-slide-notices-wrapper",
          )}>
          <i 
            className="modal-close" 
            style={{marginRight: '24px'}} 
            onClick={this.handleClick} 
          />
          <div className="onboarding-slide-notices">
            <Carousel
              wrap={false}
              interval={null}
              controls={false}
              activeIndex={this.state.index}
              onSelect={this.handleSelect}
            >
              <Carousel.Item>
                <div className="imgWrapper">
                  <img
                    alt="First slide"
                    className="d-block w-100"
                    src={this.getImagePath("slide_step_1.png")} />
                </div>
                <Carousel.Caption>
                  <h3>{t('onboarding_slide_notices.step_1.title')}</h3>
                  <p>{t('onboarding_slide_notices.step_1.description')}</p>
                  <button
                    className="btn next"
                    onClick={this.handleSelectBtn}>
                    {t('onboarding_slide_notices.next')}
                  </button>
                </Carousel.Caption>
              </Carousel.Item>
              <Carousel.Item>
                <div className="imgWrapper">
                  <img
                    alt="Second slide"
                    className="d-block w-100"
                    src={this.getImagePath('slide_step_2.png')} />
                </div>
                <Carousel.Caption>
                  <h3>{t('onboarding_slide_notices.step_2.title')}</h3>
                  <p>{t('onboarding_slide_notices.step_2.description')} </p>
                  <button
                    className="btn next"
                    onClick={this.handleSelectBtn}>
                    {t('onboarding_slide_notices.next')}
                  </button>
                </Carousel.Caption>
              </Carousel.Item>
              <Carousel.Item>
                <div className="imgWrapper">
                  <img
                    alt="Third slide"
                    className="d-block w-100"
                    src={this.getImagePath('slide_step_3.png')} />
                </div>
                <Carousel.Caption>
                  <h3>{t('onboarding_slide_notices.step_3.title')}</h3>
                  <p>{t('onboarding_slide_notices.step_3.description')} </p>
                  <button
                    className="btn next"
                    onClick={this.handleSelectBtn}>
                    {t('onboarding_slide_notices.next')}
                  </button>
                </Carousel.Caption>
              </Carousel.Item>
              <Carousel.Item>
                <div className="imgWrapper">
                  <img
                    alt="Third slide"
                    className="d-block w-100"
                    src={this.getImagePath('slide_step_4.png')} />
                </div>
                <Carousel.Caption>
                  <h3>{t('onboarding_slide_notices.step_4.title')}</h3>
                  <p>{t('onboarding_slide_notices.step_4.description')} </p>
                  <button
                    className="btn start"
                    onClick={this.handleClick}>
                    {t('onboarding_slide_notices.ok')}
                  </button>
                </Carousel.Caption>
              </Carousel.Item>
            </Carousel>
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = state => ({ profile: state.global.profile });

const actions = {
  openModal,
  closeHelp,
  closeModal,
  updateModal,
};

export default connect(mapStateToProps, actions)(DenouncedOnBoardingSlide);
