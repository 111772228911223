import { from } from 'rxjs';
import { ofType } from 'redux-observable';
import { mergeMap } from 'rxjs/operators';
import { gqlQuery } from "libs/awsLib"; 
import {
  querySuggestedArticles,
  querySuggestedArticlesStats,
  querySuggestedReportedCount,
} from "gql/index";

import {
  FETCH_SUGGESTED_ARTICLES,
  fetchSuggestedArticlesFulfilled,
  fetchSuggestedArticlesError,

  FETCH_SUGGESTED_ARTICLES_STATS,
  fetchsuggestedArticlesStatsFulfilled,
  fetchSuggestedArticlesStatsError,

  FETCH_SUGGESTED_REPORTED_COUNT,
  fetchSuggestedReportedCountFulfilled,
  fetchSuggestedReportedCountError,
} from "redux/actions/suggestedArticles";
import {
  REPORT_ARTICLE_UNDO_SUCCESS,
  APPROVE_ARTICLE_UNDO_SUCCESS
} from "redux/actions/detail";
import { REMOVE_ARTICLE_SUCCESS } from "redux/actions/reportedArticles";


/* EPICS */
export const fetchSuggestedArticlesEpic = (action$, store) => action$.pipe(
  ofType(FETCH_SUGGESTED_ARTICLES),
  mergeMap(({ payload }) => {
    const state = store.value.global;
    const { helps } = state.profile;
    return from(
      gqlQuery(querySuggestedArticles, { ...payload })
        .then(({ suggestedArticles }) => 
          fetchSuggestedArticlesFulfilled({
            suggestedArticlesFrom: suggestedArticles.from,
            suggestedArticles: suggestedArticles.suggestedArticles,
            articlesPinned: helps.articlesPinned
          })
        )
        .catch(fetchSuggestedArticlesError)
    )
  })
);

// @TODO: remove
export const fetchSuggestedArticlesStatsEpic = action$ => action$.pipe(
  ofType(
    REMOVE_ARTICLE_SUCCESS,
    REPORT_ARTICLE_UNDO_SUCCESS,
    APPROVE_ARTICLE_UNDO_SUCCESS,
    FETCH_SUGGESTED_ARTICLES_STATS,
  ),
  mergeMap(() =>
    from(
      gqlQuery(querySuggestedArticlesStats)
        .then(response => response.suggestedStats)
        .then(fetchsuggestedArticlesStatsFulfilled)
        .catch(fetchSuggestedArticlesStatsError)
    )
  )
);

export const fetchSuggestedReportedCountEpic = action$ => action$.pipe(
  ofType(FETCH_SUGGESTED_REPORTED_COUNT),
  mergeMap(() => 
    from(
      gqlQuery(querySuggestedReportedCount)
        .then(response => response.suggestedReportedCount.count)
        .then(fetchSuggestedReportedCountFulfilled)
        .catch(fetchSuggestedReportedCountError)
    )
  )
);
