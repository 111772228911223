import { articleFields } from "../fragments";

export default `
  ${articleFields}
  
  query {
    profiles {
      id
      lang
      currency
      givenName
      familyName
      helps {
        dialog
        didReport
        onBoarding
        onBoardingPendingDenounced
        onBoardingPendingDetail
        suggestions
        didReportExcel
        redirectFlowInfo
        restrictionNewMember
        articlesPinned {
          ...articleFields
        }
        translateTooltipAlreadyShown
        newReasonTooltipAlreadyShown
        discardTooltipAlreadyShown
      }
    }
  }
`;