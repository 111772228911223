import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { closeModal } from "redux/actions/global";
import { t } from "utils";
import Translate from "components/Translate";


class AuditorCommentModal extends Component {
 
  constructor(props) {
    super(props);

    const { data } = props;
   
    this.state = {
      data
    };
  }

  render() {

    const { closeModal } = this.props;

    const auditorName = this.state.data['auditorName'];
    const description = this.state.data['description'];
    const siteId = this.state.data['siteId'];
    const lang = this.state.data['lang'];
    const doTranslate = this.state.data['doTranslate'];
    const onTranslateError = this.state.data['onTranslateError'];
  
    return (
      <div className="panel auditor-comment-ctn">
        <div className="container">
          <h2>{t('auditor-comment')}</h2>
          <i className="modal-close" style={{marginRight: '24px'}} onClick={closeModal} />
          <div className="auditor-said">
            {auditorName} {t('auditor-said')}:
          </div> 
          <div className="description-ctn">
            <Translate
              text={description}
              lang={lang}
              siteId={siteId}
              doTranslate={doTranslate}
              onTranslateError={onTranslateError}
              parseText={text => text.split('\n')
                .map((item, i) => (
                  <React.Fragment key={`${i}`}>
                    {item}
                  </React.Fragment>
                ))
              }
            />
          </div>
          <button 
            className="btn btn-success"
            onClick={closeModal}
          >
            {t('go_back')}
          </button>   
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  profile: state.global.profile,
});

const actions = {
  closeModal,
};

export default withRouter(connect(mapStateToProps, actions)(AuditorCommentModal));