export default `
{
  menu{
    menu {
      id
      name
      icon
      new_tab
      url
      is_new
      childs{
          id
          name
          new_tab
          url
          is_new
      }
    }  
  }
}
`;