export default `
  mutation(
    $caseId: Int!,
    $comment: String!,    
  ) {
    rollbackCase(
      caseId: $caseId,
      comment: $comment,
    ) {
      caseId
      message
    }
  }
`;