import { articleFields } from "../fragments";


export default `
  ${articleFields}
  
  query($size: Int, $from: String) {
    reportedArticles(size: $size, from: $from) {
      size
      from
      reportedArticles {
        ...articleFields
      }
    }
  }
`;