import React from "react";
import { connect } from "react-redux";
import { updateProfile } from "redux/actions/global";
import Dropdown from "components/Dropdown";
import "./currency-dropdown.scss";

function CurrencyDropdown({ profile, rates, updateProfile }) {

  const handleChange = currency => () => {
    if (profile.currency !== currency) {
      updateProfile({ ...profile, currency });
    }
  };
  
  if (!Object.keys(rates).length) return null;

  return (
    <Dropdown
      className="nav pull-right top-menu currency-dropdown__container"
      icon={
        <>
          <span className="currency">{profile.currency}</span>
          <span className="icon-chevron"></span>
        </>
      }
    >
      {
        Object.keys(rates).map(currency => 
          <li 
            key={currency} 
            onClick={handleChange(currency)}
          >
            {currency}
          </li>
        )
      }
    </Dropdown>
  );
}

const mapStateToProps = state => ({
  rates: state.global.rates,
  profile: state.global.profile
});

export default connect(mapStateToProps, { updateProfile })(CurrencyDropdown);