import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { closeHelp } from "redux/actions/global";
import Alert from "components/Alert";
import { t, get, getUrlWithAnchor } from "utils";
import "./help.scss";


class Help extends Component {
  componentDidUpdate(prevProps) {
    if (
      this.props.location.hash &&
      prevProps.location.pathname !== this.props.location.pathname
    ) {
      const element = document.querySelector(this.props.location.hash);
      const scrollTop = element && element.offsetTop - 56;
        
      scrollTop && window.scrollTo({ top: scrollTop, behavior: 'smooth' });
    }
  }

  handleCloseHelp = () => {
    this.props.closeHelp('dialog');
  }
  
  render() {
    const originalText = t("help_message");
    const { profile, location } = this.props;
    const viewed = get(profile, 'helps.dialog', false);

    if (
      viewed ||
      (
        location.pathname.includes("profile") ||
        location.pathname.includes("help_support") ||
        location.pathname.includes("livesearch_reports") ||
        location.pathname.includes("/reports/custom_reports") ||
        location.pathname.includes("/reports/noticed_articles") ||
        location.pathname.includes("/suggested_listings")
      )
    ) {
      return null;
    }

    return (
      <Alert className="help-tooltip-container">
        <div className="icon__container">
          <img alt="alert" src="/images/exclamation.svg" />
        </div>

        <strong>
          {t("dont_know_how_to_start")}
        </strong>

        &nbsp;
        
        <div
          className="help-tooltip"
          style={{ display: 'inline' }}
        >
          {originalText.substring(0, originalText.indexOf("%{copy_help}"))}

          <Link
            className="help-tooltip-link"
            to={getUrlWithAnchor(location)}>
            {t("help")}
          </Link>

          {
            originalText
              .substring(
                originalText.indexOf("%{copy_help}") + 12, 
                originalText.length
              )
          }
        </div>

        <div
          className="close"
          onClick={this.handleCloseHelp}
        >
          <i className="close__icon" />
        </div>
      </Alert>
    );
  }
}

const mapStateToProps = state => ({
  lang: state.global.lang,
  profile: state.global.profile,
});

export default connect(mapStateToProps, { closeHelp })(Help);