import React from "react";
import classnames from "classnames";

function Alert(props) {
  const {
    children,
    className,
    alertType = 'info',
    styleType = 'filled',
    ...rest
  } = props;

  const alertTypeClasses = {
    'info': 'alert-info',
    'warning': 'alert-warning',
    'error': 'alert-error',
    'trusted': 'alert-trusted',
  };

  return (
    <section 
      className={classnames("panel alert-wrapper", className)}
      {...rest}
    >
      <div className={classnames("pulpou-alert", styleType, alertTypeClasses[alertType])}>
        <div className="pulpou-alert-ctn">
          {children}
        </div>
      </div>
    </section>
  );
}

export default Alert;
