/* eslint-disable max-len */
/**
 * CONSTANTS
 */
export const FETCH_REPORTED_ARTICLES = "FETCH_REPORTED_ARTICLES";
export const FETCH_REPORTED_ARTICLES_ERROR = "FETCH_REPORTED_ARTICLES_ERROR";
export const REPORTED_ARTICLES_FULFILLED = "REPORTED_ARTICLES_FULFILLED";

export const FETCH_REPORTED_ARTICLES_COUNT = "FETCH_REPORTED_ARTICLES_COUNT";
export const FETCH_REPORTED_ARTICLES_COUNT_ERROR = "FETCH_REPORTED_ARTICLES_COUNT_ERROR";
export const REPORTED_ARTICLES_COUNT_FULFILLED = "REPORTED_ARTICLES_COUNT_FULFILLED";

export const SEND_NOTICE = "SEND_NOTICE";
export const SEND_NOTICE_SUCCESS = "SEND_NOTICE_SUCCESS";
export const SEND_NOTICE_ERROR = "SEND_NOTICE_ERROR"; 

export const REMOVE_ARTICLE = "REMOVE_ARTICLE";
export const REMOVE_ARTICLE_SUCCESS = "REMOVE_ARTICLE_SUCCESS";
export const REMOVE_ARTICLE_ERROR = "REMOVE_ARTICLE_ERROR";

export const FETCH_SENDING_NOTICE_STATUS = "FETCH_SENDING_NOTICE_STATUS";
export const FETCH_SENDING_NOTICE_STATUS_SUCCESS = "FETCH_SENDING_NOTICE_STATUS_SUCCESS";
export const FETCH_SENDING_NOTICE_STATUS_ERROR = "FETCH_SENDING_NOTICE_STATUS_ERROR";

export const FETCH_SHOW_TOOLTIP_STATUS = "FETCH_SHOW_TOOLTIP_STATUS";
export const FETCH_SHOW_TOOLTIP_STATUS_FULFILLED = "FETCH_SHOW_TOOLTIP_STATUS_FULFILLED";
export const FETCH_SHOW_TOOLTIP_STATUS_ERROR = "FETCH_SHOW_TOOLTIP_STATUS_ERROR";

export const FETCH_SHOW_TOOLTIP_IMPORT_STATUS = "FETCH_SHOW_TOOLTIP_IMPORT_STATUS";
export const FETCH_SHOW_TOOLTIP_IMPORT_STATUS_FULFILLED = "FETCH_SHOW_TOOLTIP_IMPORT_STATUS_FULFILLED";
export const FETCH_SHOW_TOOLTIP_IMPORT_STATUS_ERROR = "FETCH_SHOW_TOOLTIP_IMPORT_STATUS_ERROR";

export const SET_NOT_SHOW_MORE = "SET_NOT_SHOW_MORE";
export const SET_NOT_SHOW_MORE_FULFILLED = "SET_NOT_SHOW_MORE_FULFILLED";
export const SET_NOT_SHOW_MORE_ERROR = "SET_NOT_SHOW_MORE_ERROR";

export const SET_NOTICES_LIMIT_DAY_COUNT = "SET_NOTICES_LIMIT_DAY_COUNT";
export const SET_NOTICES_LIMIT_DAY_COUNT_FULFILLED = "SET_NOTICES_LIMIT_DAY_COUNT_FULFILLED";
export const SET_NOTICES_LIMIT_DAY_COUNT_ERROR = "SET_NOTICES_LIMIT_DAY_COUNT_ERROR";

export const SET_SHOW_TOOLTIP_IMPORT_UNTIL = "SET_SHOW_TOOLTIP_IMPORT_UNTIL";
export const SET_SHOW_TOOLTIP_IMPORT_UNTIL_FULFILLED = "SET_SHOW_TOOLTIP_IMPORT_UNTIL_FULFILLED";
export const SET_SHOW_TOOLTIP_IMPORT_UNTIL_ERROR = "SET_SHOW_TOOLTIP_IMPORT_UNTIL_ERROR";

export const DELETE_ARTICLES = "DELETE_ARTICLES";
export const DELETE_ARTICLES_SUCCESS = "DELETE_ARTICLES_SUCCESS";
export const DELETE_ARTICLES_ERROR = "DELETE_ARTICLES_ERROR";

export const SEND_BRAND_INFRINGEMENT = "SEND_BRAND_INFRINGEMENT";
export const SEND_BRAND_INFRINGEMENT_SUCCESS = "SEND_BRAND_INFRINGEMENT_SUCCESS";
export const SEND_BRAND_INFRINGEMENT_ERROR = "SEND_BRAND_INFRINGEMENT_ERROR"; 

/**
 * DISPATCHERS
 */
export const fetchReportedArticles = payload => ({ type: FETCH_REPORTED_ARTICLES, payload });
export const fetchReportedArticlesError = payload => ({ type: FETCH_REPORTED_ARTICLES_ERROR, payload });
export const reportedArticlesFulfilled = payload => ({ type: REPORTED_ARTICLES_FULFILLED, payload });

export const fetchReportedArticlesCount = payload => ({ type: FETCH_REPORTED_ARTICLES_COUNT, payload });
export const fetchReportedArticlesCountError = payload => ({ type: FETCH_REPORTED_ARTICLES_COUNT_ERROR, payload });
export const reportedArticlesCountFulfilled = payload => ({ type: REPORTED_ARTICLES_COUNT_FULFILLED, payload });

export const sendNotice = payload => ({ type: SEND_NOTICE, payload });
export const sendNoticeSuccess = payload => ({ type: SEND_NOTICE_SUCCESS, payload });
export const sendNoticeError = payload => ({ type: SEND_NOTICE_ERROR, payload });

export const removeArticle = payload => ({ type: REMOVE_ARTICLE, payload });
export const removeArticleSuccess = payload => ({ type: REMOVE_ARTICLE_SUCCESS, payload });
export const removeArticleError = payload => ({ type: REMOVE_ARTICLE_ERROR, payload });

export const fetchSendingNoticeStatus = () => ({ type: FETCH_SENDING_NOTICE_STATUS });
export const fetchSendingNoticeStatusSuccess = payload => ({ type: FETCH_SENDING_NOTICE_STATUS_SUCCESS, payload });
export const fetchSendingNoticeStatusError = payload => ({ type: FETCH_SENDING_NOTICE_STATUS_ERROR, payload });

export const fetchShowTooltipStatus = () => ({ type: FETCH_SHOW_TOOLTIP_STATUS });
export const fetchShowTooltipStatusFulfilled = payload => ({ type: FETCH_SHOW_TOOLTIP_STATUS_FULFILLED, payload });
export const fetchShowTooltipStatusError = payload => ({ type: FETCH_SHOW_TOOLTIP_STATUS_ERROR, payload });

export const fetchShowTooltipImportStatus = () => ({ type: FETCH_SHOW_TOOLTIP_IMPORT_STATUS });
export const fetchShowTooltipImportStatusFulfilled = payload => ({ type: FETCH_SHOW_TOOLTIP_IMPORT_STATUS_FULFILLED, payload });
export const fetchShowTooltipImportStatusError = payload => ({ type: FETCH_SHOW_TOOLTIP_IMPORT_STATUS_ERROR, payload });

export const setTooltipNotShowMore = payload => ({ type: SET_NOT_SHOW_MORE, payload });
export const setTooltipNotShowMoreFulfilled = payload => ({ type: SET_NOT_SHOW_MORE_FULFILLED, payload });
export const setTooltipNotShowMoreError = payload => ({ type: SET_NOT_SHOW_MORE_ERROR, payload });

export const setNoticesLimitDayCount = payload => ({ type: SET_NOTICES_LIMIT_DAY_COUNT, payload });
export const setNoticesLimitDayCountFulfilled = payload => ({ type: SET_NOTICES_LIMIT_DAY_COUNT_FULFILLED, payload });
export const setNoticesLimitDayCountError = payload => ({ type: SET_NOTICES_LIMIT_DAY_COUNT_ERROR, payload });

export const setShowReportImportTooltipUntil = payload => ({ type: SET_SHOW_TOOLTIP_IMPORT_UNTIL, payload });
export const setShowReportImportTooltipUntilFulfilled = payload => ({ type: SET_SHOW_TOOLTIP_IMPORT_UNTIL_FULFILLED, payload });
export const setShowReportImportTooltipUntilError = payload => ({ type: SET_SHOW_TOOLTIP_IMPORT_UNTIL_ERROR, payload });

export const deleteArticles = payload => ({ type: DELETE_ARTICLES, payload });
export const deleteArticlesSuccess = payload => ({ type: DELETE_ARTICLES_SUCCESS, payload });
export const deleteArticlesError = payload => ({ type: DELETE_ARTICLES_ERROR, payload });

export const sendBrandInfringement = payload => ({ type: SEND_BRAND_INFRINGEMENT, payload });
export const sendBrandInfringementSuccess = payload => ({ type: SEND_BRAND_INFRINGEMENT_SUCCESS, payload });
export const sendBrandInfringementError = payload => ({ type: SEND_BRAND_INFRINGEMENT_ERROR, payload });
