import React, { useState } from "react";
import ReactGA from "react-ga";
import ArticlePrice from "components/ArticlePrice";
import { withRouter } from "react-router-dom";
import { t, getLangFromSiteId } from "utils";
import Switch from "react-switch";
import Tooltip from "components/Tooltip";
import { connect } from "react-redux";
import Translate from "components/Translate";
import {
  setTranslateTooltipAlreadyShown,
  setDiscardTooltipAlreadyShown,
} from "redux/actions/global";
import Icon from "components/Icon";
import { faExclamationCircle } from "@fortawesome/free-solid-svg-icons";

function ArticleShortDescription(props) {
  const { article = {}, originModel } = props;

  const [discardTooltipAlreadyShown, setDiscardTooltipAlreadyShown] =
    React.useState(props.profile.helps.discardTooltipAlreadyShown || false);

  const [tooltipDiscard, setTooltipDiscard] = React.useState();

  React.useEffect(() => {
    if (!discardTooltipAlreadyShown && originModel) {
      tooltipDiscard && tooltipDiscard.open && tooltipDiscard.open();
      if (tooltipDiscard) {
        setDiscardTooltipAlreadyShown(true);
        props.setDiscardTooltipAlreadyShown &&
          props.setDiscardTooltipAlreadyShown(true);
        // Hack tooltip for safari
        const isSafari =
          navigator.vendor &&
          navigator.vendor.indexOf("Apple") > -1 &&
          navigator.userAgent &&
          navigator.userAgent.indexOf("CriOS") === -1 &&
          navigator.userAgent.indexOf("FxiOS") === -1;
        if (isSafari) {
          let tooltipElem = document.getElementsByClassName("tooltip-discard");
          if (tooltipElem && tooltipElem[0]) {
            tooltipElem[0].firstChild.style.left = "-530px";
          }
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tooltipDiscard]);

  const {
    profile,
    doTranslate,
    translateError,
    onTranslateError,
    setTranslateTooltipAlreadyShown,
  } = props;

  const { lang } = profile;
  const translateTooltipAlreadyShown =
    profile.helps.translateTooltipAlreadyShown || false;
  const [tooltipTranslate, setTooltipTranslate] = useState();
  const checked = doTranslate && !translateError;

  const siteLang = React.useMemo(
    () => getLangFromSiteId(article.siteId),
    [article.siteId]
  );

  const showTooltipTranslate =
    lang !== siteLang && !article.deactivateTranslate;

  const [msgStatus] = useState(article.status);

  React.useEffect(() => {
    showTooltipTranslate &&
      !translateTooltipAlreadyShown &&
      tooltipTranslate &&
      tooltipTranslate.open &&
      tooltipTranslate.open();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showTooltipTranslate, tooltipTranslate, translateTooltipAlreadyShown]);

  const getAttributeValueName = (id) => {

    let name = null;
    article.attributes &&
      article.attributes.forEach((attribute) => {
        if (attribute.id === id) name = attribute.valueName;
      });
    return name;
  };

  article["condition"] = React.useMemo(() => {
    let condition = article["condition"];
    article.attributes &&
      article.attributes.forEach((attribute) => {
        if (attribute.valueName === "refurbished")
          condition = attribute.valueName;
      });
    return condition;
  }, [article]);

  const articleBrandName = React.useMemo(
    () => getAttributeValueName("BRAND"),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [article]
  );

  const articlePartName = React.useMemo(
    () => getAttributeValueName("PART_NUMBER"),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [article]
  );

  const handleOpenExternal = () => {
    ReactGA.ga("send", "event", "PPI", "VIEW_ON_SITE");
  };

  const isReported =
    article.reported &&
    (!article.noticed ||
      article.noticed.noticedAt < article.reported.createdAt ||
      (article.reported && !article.reported.createdAt));

  return (
    <section className="short-description">
      <div style={{ width: "42%" }}>
        {msgStatus === "paused" && (
          <div
            style={{
              background: "#eee",
              padding: "8px",
              color: "#999",
              borderRadius: "4px",
              display: "table",
            }}
          >
            <Icon
              icon={faExclamationCircle}
              style={{ color: "orange", marginRight: "10px" }}
            />
            {t("statusMsgPaused")}
          </div>
        )}
      </div>
      {showTooltipTranslate && (
        <div className="tooltip-translate-ctn">
          <label>
            <Switch
              onChange={(e) => props.onTranslate(e)}
              checked={checked}
              offColor="#f1f1f1"
              onColor="#3483fa"
              onHandleColor="#FFFFFF"
              offHandleColor="#FFFFFF"
              handleDiameter={30}
              uncheckedIcon={false}
              checkedIcon={false}
              boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
              height={20}
              width={40}
              className="react-switch"
              id="tooltip-switch"
            />
            <span>{t("switch-translate.label")}</span>
          </label>
          {
            <Tooltip
              timeOutToOpen={0}
              timeOutToClose={100}
              ref={(node) => setTooltipTranslate(tooltipTranslate || node)}
            >
              <strong>{t("switch-translate.tooltip.title")}</strong>
              <p>{t("switch-translate.tooltip.description")}</p>
              <button
                className="btn close"
                onClick={() => {
                  tooltipTranslate &&
                    tooltipTranslate.close &&
                    tooltipTranslate.close();
                  setTranslateTooltipAlreadyShown(true);
                }}
              >
                {t("switch-translate.tooltip.button")}
              </button>
            </Tooltip>
          }
        </div>
      )}
      {article.officialStoreId && (
        <>
          <img
            alt="official store detail"
            className="official-store-m-detail"
            src="/images/icons-official-store/icon-small1x.svg"
          />
          <div className="official-store__label">{t("official-store")}</div>
        </>
      )}

      <br />

      <div className="article-id">
        <a
          target="_blank"
          href={article.href}
          rel="noopener noreferrer"
          onClick={handleOpenExternal}
        >
          {article.id}
        </a>
      </div>

      <div className="article-title">
        <Translate
          text={article.title}
          lang={lang}
          siteId={article.siteId}
          doTranslate={doTranslate}
          onTranslateError={onTranslateError}
          parseText={(text) =>
            text
              .split("\n")
              .map((item, i) => (
                <React.Fragment key={`${i}`}>{item}</React.Fragment>
              ))
          }
        />
      </div>

      <br />

      {articleBrandName && (
        <div className="article-brand">
          <strong>{t("article_brand_name")}:</strong> {articleBrandName}
        </div>
      )}

      {articlePartName && (
        <div className="article-part">
          <strong>{t("article_part_name")}:</strong> {articlePartName}
        </div>
      )}

      <div className="article-condition">
        {article.condition && (
          <span>
            {["new", "used", "refurbished"].indexOf(article.condition) > -1
              ? t(`${article.condition}_product`)
              : t("no_data")}
            &nbsp;-&nbsp;
          </span>
        )}
        {article.soldQuantity && (
          <span>
            {article.soldQuantity} {t("sold_quantity")}
          </span>
        )}
        {article.availableQuantity && (
          <span>
            &nbsp;|&nbsp;
            {article.availableQuantity} {t("available_quantity")}
          </span>
        )}
      </div>

      <br />

      <div className="article-price">
        <ArticlePrice className="price" article={article} />
      </div>

      {!article.approved && !isReported && (
        <div className="short-description-actions">
          <div className="content">
            <button onClick={props.onReport} className="btn suspicious">
              {t("mark_as_suspicious_label")}
            </button>
            <button className="btn" onClick={props.onApprove}>
              {t("mark_as_trusted_label")}
            </button>
          </div>
        </div>
      )}
      {originModel && (
        <div className="short-description-suggested-discard">
          <div className="content">
            <button onClick={props.onDiscard} className="btn discard">
              <span>{t("suggested_discard.text_btn")}</span>
            </button>
            {
              <Tooltip
                timeOutToOpen={0}
                timeOutToClose={100}
                ref={(node) => {
                  setTooltipDiscard(tooltipDiscard || node);
                }}
                className="tooltip-discard"
              >
                <button
                  className="close"
                  onClick={() => {
                    tooltipDiscard && tooltipDiscard.close();
                  }}
                >
                  &times;
                </button>
                <strong>{t("suggested_discard.tooltip.title")}</strong>
                <p>{t("suggested_discard.tooltip.description")}</p>
              </Tooltip>
            }
          </div>
        </div>
      )}
    </section>
  );
}

const mapStateToProps = (state) => ({
  profile: state.global.profile,
});

const actions = {
  setDiscardTooltipAlreadyShown,
  setTranslateTooltipAlreadyShown,
};

export default withRouter(
  connect(mapStateToProps, actions)(ArticleShortDescription)
);
