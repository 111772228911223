import React, { Component } from "react";
import ReactGA from "react-ga";
import { withRouter } from "react-router-dom";
import classnames from "classnames";
import { connect } from "react-redux";
import { faCircle, faDotCircle } from "@fortawesome/free-regular-svg-icons";
import { discardArticles } from "redux/actions/detail";
import Tooltip from "components/Tooltip";
import {
  closeModal,
  fetchArticleDetail,
} from "redux/actions/global";
import Icon from "components/Icon";
import { t } from "utils";

class ArticleDiscardDrawer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      step: 1,
      siteId: null,
      comment: "",
      originModel: props.originModel || null,
    };
  }

  componentDidMount() {
    let siteId = null;

    if (!this.props.article) {      
      this.props.fetchArticleDetail({
        articleId: this.props.articleId,
        error: this.props.error,
      });
    }
   
    siteId = this.props.articleId.slice(0, 3);
 
    this.setState({ siteId });
    this.tagInput && this.tagInput.focus();
  }

  componentWillUnmount() {
    if (typeof this.props.onClose === 'function') {
      this.props.onClose();
    }
  }

  getValue() {
    return { code: this.state.selectedItem };
  }

  handleSelectItem = selectedItem => {
    this.setState({
      selectedItem: `item${selectedItem}`, 
      step: 2
    });
  }

  handleChangeComment = e => {
    e.preventDefault();

    this.setState({ comment: e.target.value });
  }

  handleGoBack = () => {
    this.setState({ step: 1 });
  }

  handleClickContinue = e => {
    e.preventDefault();
    e.stopPropagation();

    this.setState({ step: 2 });
  }

  handleSubmit = e => {
    e.preventDefault();

    const { code } = this.getValue();
    const { profile, article } = this.props;
    const { comment, originModel } = this.state;

    //agregar validacion
    const code_description = t(`suggested_discard.modal.discard_item.${code}`);
 
    ReactGA.ga('send', 'event', 'DISCARD', 'SUGGESTED_DISCARDED');

    const payload = {
      articlesIds: [article.id],
      code,
      code_description,
      description: comment,
      originModel,
      author: {
        id: profile.id,
        givenName: profile.givenName,
        familyName: profile.familyName,
      },
    };
    
    this.props.discardArticles({
      ...payload,
      // usage of redux
      history: this.props.history,
    });

    setTimeout(() => {
      this.props.closeModal();
      this.props.history.goBack();
    }, 100);
  }

  groupBy(array, key) {
    return array.reduce((prev, curr) => {
      let current = curr.type === 'Producto' ? { id: curr.id, type: 'Product'} : curr;
      const groupKey = current[key].toLowerCase();
      prev[groupKey] = [...(prev[groupKey] || []), current];
      return prev;
    }, {});
  }

  step1Module = () => {
    const items = [1, 2, 3, 4];
    return (
      <div className="discard-ctn">
        {
          items
            .map((item, i) => {
              const description = t(`suggested_discard.modal.discard_item.item${item}`);
              const subText = t(`suggested_discard.modal.discard_item.item${item}_subtext`);

              return (
                <div key={item}>                 
                  {
                    <div 
                      key={item}
                      className="reason-discard-ctn"
                      onClick={ () => this.handleSelectItem(item) }
                    >         
                      <div className="description">
                        <span>{description}</span>
                      </div>
                      {
                        subText && subText !== '' &&
                        <span className="subtext">{subText}</span> 
                      }
                      <img src="/images/arrow-right-blue.png" />
                    </div>
                  }                  
                </div>
              );
            })
        }
      </div>
    );
  }

  render() {
    const { closeModal } = this.props;
    const { step, selectedItem, comment } = this.state;

    //console.log("selectedItem", selectedItem)
  
    return (
      <>
        {
          <Tooltip
            timeOutToOpen={0}
            timeOutToClose={100}
            ref={node => this.tooltipNewReason = this.tooltipNewReason || node}
            className="tooltip-new-reason"
          >
            <button
              className="close"
              onClick={() => {
                this.tooltipNewReason && this.tooltipNewReason.close();
              }}>
              &times;
            </button>
            <strong>{t('report_v2.tooltip_PPPI10.title')}</strong>
            <p>{t('report_v2.tooltip_PPPI10.description')}</p>
          </Tooltip>
        }
        <div
          className="modal-content report-modal-content drawer-ctn discard-drawer"
          style={{
            overflowX: 'hidden',
            overflowY: 'auto',
            height: window.innerHeight - 48, 
          }}
        >
          <form
            onSubmit={this.handleSubmit}
            className="new_reported_article"
          >
            <div className="drawer__header">
              {
                step === 1 ?
                  <>
                    <h2 className="modal-title title-step-1">
                      {
                        t('suggested_discard.modal.title')
                      }
                    </h2>

                    <h3 className="modal-subtitle">
                      {t('suggested_discard.modal.subtitle')}
                    </h3>

                    <i 
                      className="modal-close close-step-1" 
                      onClick={closeModal} 
                    />
                  </> :
                  <>
                    <img 
                      className="go-back"
                      src="/images/arrow-left-blue.png" 
                      onClick={this.handleGoBack}
                    />
                    <h2 className="modal-title">
                      {t('suggested_discard.modal.step_2.title')}
                    </h2>

                    <h3 className="modal-subtitle">
                      {this.state.selectedItem !== "item4" ? 
                        t('suggested_discard.modal.step_2.subtitle') :
                        ''}
                    </h3>
                    <i 
                      className="modal-close" 
                      onClick={closeModal} 
                    />
                  </>
              }
            </div>

            <div 
              className="radio-fields"
              style={{ overflowY: 'auto' }}
            >
              {
                step === 1 ?
                  <>
                    {this.step1Module()}
                  </> :
                  <div className="text-area-container">
                    <textarea
                      rows="4"
                      cols="40"
                      value={comment}
                      name="comment"
                      maxLength="200"
                      className="form-control comment__field"
                      onChange={this.handleChangeComment}
                      placeholder={t('suggested_discard.modal.step_2.placeholder')}
                    >
                    </textarea>
                    <span>
                      {comment.length} / 200
                    </span>
                  </div>
              }
            </div>
            
            <div 
              className={classnames(
                "btn-ctn",
                { "fill-ctn": step === 1 },
              )}
            >
              { 
                step === 2 &&
                <button
                  type="submit"
                  className="btn-suspicious btn btn-lg"
                  disabled={
                    !selectedItem ||
                    (
                      selectedItem &&
                      selectedItem === 'item4' &&
                      comment ===  '' &&
                      step === 2
                    )
                  }
                >
                  {
                    t('suggested_discard.modal.step_2.btn_text')
                  }
                </button>
              }
            </div>
            
          </form>
        </div> 
      </>
    );
  }
}

const mapStateToProps = (state, ownProps) => ({
  profile: state.global.profile,
  article: state.global.articlesMap[ownProps.articleId],
});

const actions = {
  closeModal,
  discardArticles,
  fetchArticleDetail,
};

export default withRouter(connect(mapStateToProps, actions)(ArticleDiscardDrawer));