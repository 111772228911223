
export default `
  query(
    $size: Int!
    $from: Int!
    $sort: String
    $order: String
    $dueDate: String
  ) {
    pendingCounterNoticeList(
      size: $size
      from: $from
      sort: $sort
      order: $order
      dueDate: $dueDate
  ) {
      paging { total }
    }
  }
`;
