import React, { Component } from "react";
import classnames from "classnames";
import { connect } from "react-redux";
import { Scrollbars } from "react-custom-scrollbars";
import { closeModal } from "redux/actions/global";
import { t } from "utils";


class CountrySelector extends Component {
  constructor(props) {
    super(props);

    const selectedCountries = props.selectedCountries || [];

    this.state = {
      selectedCountries,
      selectAllCountries: (
        selectedCountries.length === props.sites[0].countrySites.length
      ),
    };
  }

  handleClose = e => {
    e && e.preventDefault();
    this.props.closeModal();
  }

  handleChange = e => {
    e && e.preventDefault();

    let selectedCountries = this.state.selectedCountries.slice(0);
    let idx = selectedCountries.indexOf(e.target.value);

    if (idx !== null && idx !== undefined && idx > -1) {
      selectedCountries.splice(idx, 1);
      this.setState({ selectedCountries, selectAllCountries: false });
    } else {
      selectedCountries = [...selectedCountries, e.target.value];
      this.setState({ 
        selectedCountries,
        selectAllCountries: (
          selectedCountries.length === this.props.sites[0].countrySites.length
        )
      });
    }
  }

  handleChangeAll = e => { 
    e && e.preventDefault();
      
    if (!this.state.selectAllCountries) {
      let countries = this.props.sites[0].countrySites.map(cS => cS.id);

      this.setState({ selectedCountries: countries });
    } else {
      this.setState({ selectedCountries: [] });
    }

    this.setState({ selectAllCountries: !this.state.selectAllCountries });
  }

  handleApply = () => {
    this.props.onApply(this.state.selectedCountries);
    this.props.closeModal();
  }

  render() {
    const sites = this.props.sites[0].countrySites;
    const { selectedCountries } = this.state;

    return (
      <div
        tabIndex="1"
        className="modal-content category-selector">
        <div className="modal-category-selector_container">
          <header>
            <h1>
              {t('countries_select')}
              <i className="modal-close" onClick={this.handleClose} />
            </h1>
            <span>
              {t('country_selector_clarification')}
            </span>
          </header>
          <section>
            <form onSubmit={e => e.preventDefault()}>
              <div className="form-group reports-filter-country">
                <div className="checkbox-fields">
                  <Scrollbars
                    autoHeight
                    autoHeightMax={335}>
                    <label
                      key="none"
                      className={classnames(
                        "col-md-4",
                        "checkbox-group",
                        { "checked": this.state.selectAllCountries }
                      )}>
                      <span>
                        {t('all_the_countries')}
                      </span>
                      <input
                        value=""
                        type="checkbox"
                        name="category"
                        onClick={this.handleChangeAll}
                        id="filter_country" />
                    </label>
                    {sites &&
                      sites.map(country =>
                        <label
                          key={country.id}
                          className={classnames(
                            "col-md-4",
                            "checkbox-group",
                            { 
                              "checked": selectedCountries
                                .includes(country.id) 
                            }
                          )}>
                          <input
                            type="checkbox"
                            name="category"
                            value={country.id}
                            onClick={this.handleChange}
                            id="filter_country" />
                          <span title={country.country.name}>
                            {country.country.name}
                          </span>
                        </label>)}
                  </Scrollbars>
                </div>
              </div>
              <div className="actions">
                <button
                  onClick={this.handleClose}
                  className="btn transparent">
                  {t('cancel')}
                </button>
                <button
                  className="btn"
                  onClick={this.handleApply}>
                  {t('apply')}
                </button>
              </div>
            </form>
          </section>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({ sites: state.global.sites });


export default connect(mapStateToProps, { closeModal })(CountrySelector);