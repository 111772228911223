import React from "react";
import ContentLoader from "react-content-loader";


const SellerDenounceLoader = () => (
  <ContentLoader
    speed={2}
    height={50}
    width={400}
    primaryColor="#f3f3f3"
    secondaryColor="#ecebeb"
    style={{ marginTop: '10px' }}
  >
    <rect x="5" y="0" rx="0" ry="0" width="370" height="8" />
    <rect x="5" y="18" rx="0" ry="0" width="370" height="8" />
    <rect x="5" y="38" rx="0" ry="0" width="370" height="8" /> 
  </ContentLoader>
);

export default SellerDenounceLoader;