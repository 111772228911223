import React, { useEffect } from "react";
import classnames from "classnames";
import { connect } from "react-redux";
import { openModal, closeModal } from "redux/actions/global";
// TYPES
import {
  ARTICLE_DENOUNCE_CATALOG,
  BLINDAGE_REPORT_EXCEL,
  BLINDAGE_REPORT_MULTIPLE,
  ARTICLE_REPORT_DRAWER,
  ARTICLE_DISCARD_DRAWER,
  MANAGE_PROFILES,
  VERIFY_PROFILE,
  ONBOARDING_SLIDE,
  SUGGESTIONS_SLIDE,
  CATEGORY_SELECTION,
  BLACK_SELLER_SELECTION,
  COUNTRY_SELECTION,
  ADD_SUSPICIOUS_XLSX,
  IMPORT_SUCCESS,
  LINKED_ITEMS,
  DENOUNCED_ARTICLE_DETAIL,
  DENOUNCED_ARTICLE_DETAIL_CATALOGO,
  PUBLICATION_SELLER_HISTORY,
  PUBLICATION_IMAGES_MODIFIED,
  PUBLICATION_VIEW_IMAGE,
  DENOUNCED_PENDING_ON_BOARDING,
  DENOUNCED_DETAIL_ON_BOARDING,
  FILTER_ORDER_REPORTED,
  FILTER_REPORTED_DENOUNCES,
  DENOUNCED_ON_BOARDING_SLIDE,
  ARTICLE_IMAGES,
  ARTICLE_IMAGES_CATALOGO,
  AUDITOR_COMMENT_MODAL,
} from "components/Modal";
// MODALS
import ArticleReportDrawer from "./ArticleReportDrawer";

import BlindageReportExcel from "./BlindageReportExcel";

import BlindageReportMultiple from "./BlindageReportMultiple";

import ArticleDiscardDrawer from "./ArticleDiscardDrawer";

import ArticleDenounceCatalog from "./ArticleDenounceCatalog";

import ManageProfile from "./ManageProfile";

import VerifyProfile from "./VerifyProfile";

import OnBoardingSlide from "./OnBoardingSlide";

import SuggestionsSlide from "./SuggestionsSlide";

import CategorySelector from "./CategorySelector";

import FilterOrderReportedDrawer from "./FilterOrderReportedDrawer";

import FilterReportedDenounced from "./FilterReportedDenounced";

import BlackSellerSelector from "./BlackSellerSelector";

import CountrySelector from "./CountrySelector";

import AddSuspiciousXlsx from "./AddSuspiciosXlsx";

import ImportSuccess from "./ImportSuccess";

import LinkedItems from "./LinkedItems";

import DenouncedArticleDetail from "./DenouncedArticleDetail";

import DenouncedArticleDetailCatalogo from "./DenouncedArticleDetailCatalogo";

import PublicationSellerHistory from "./PublicationSellerHistory";

import PublicationImagesModified from "./PublicationImagesModified";

import PublicationViewImage from "./PublicationViewImage";

import DenouncedPendingOnBoarding from "./DenouncedPendingOnBoarding";

import DenouncedDetailOnBoarding from "./DenouncedDetailOnBoarding";

import DenouncedOnBoardingSlide from "./DenouncedOnBoardingSlide";

import ArticleImagesModal from "./ArticleImagesModal";

import ArticleImagesModalCatalogo from "./ArticleImagesModalCatalogo";

import AuditorCommentModal from "./AuditorCommentModal";

const Modal = (props) => {
  const { open, openModal, showImportSuccess, modalType, productId } = props;
  //console.log("isModalOpenisModalOpenisModalOpen " + productId)
  //console.log(productId)
  useEffect(() => {
    if (showImportSuccess) {
      openModal({ type: IMPORT_SUCCESS });
    }
  }, [openModal, showImportSuccess]);

  useEffect(() => {
    if (open) {
      document.body.classList.add("overflow--hidden");
    } else {
      document.body.classList.remove("overflow--hidden");
    }
  }, [open]);

  if (!open) return null;

  const ModalContent = (() => {
    switch (props.type) {
      case ARTICLE_REPORT_DRAWER:
        return ArticleReportDrawer;

      case BLINDAGE_REPORT_MULTIPLE:
        return BlindageReportMultiple;

      case BLINDAGE_REPORT_EXCEL:
        return BlindageReportExcel;

      case ARTICLE_DISCARD_DRAWER:
        return ArticleDiscardDrawer;

      case ARTICLE_DENOUNCE_CATALOG:
        return ArticleDenounceCatalog;

      case MANAGE_PROFILES:
        return ManageProfile;

      case VERIFY_PROFILE:
        return VerifyProfile;

      case ONBOARDING_SLIDE:
        return OnBoardingSlide;

      case SUGGESTIONS_SLIDE:
        return SuggestionsSlide;

      case CATEGORY_SELECTION:
        return CategorySelector;

      case FILTER_ORDER_REPORTED:
        return FilterOrderReportedDrawer;

      case FILTER_REPORTED_DENOUNCES:
        return FilterReportedDenounced;

      case BLACK_SELLER_SELECTION:
        return BlackSellerSelector;

      case COUNTRY_SELECTION:
        return CountrySelector;

      case ADD_SUSPICIOUS_XLSX:
        return AddSuspiciousXlsx;

      case IMPORT_SUCCESS:
        return ImportSuccess;

      case LINKED_ITEMS:
        return LinkedItems;

      case DENOUNCED_ARTICLE_DETAIL:
        return DenouncedArticleDetail;

      case DENOUNCED_ARTICLE_DETAIL_CATALOGO:
        return DenouncedArticleDetailCatalogo;

      case PUBLICATION_SELLER_HISTORY:
        return PublicationSellerHistory;

      case PUBLICATION_IMAGES_MODIFIED:
        return PublicationImagesModified;

      case PUBLICATION_VIEW_IMAGE:
        return PublicationViewImage;

      case DENOUNCED_PENDING_ON_BOARDING:
        return DenouncedPendingOnBoarding;

      case DENOUNCED_DETAIL_ON_BOARDING:
        return DenouncedDetailOnBoarding;

      case DENOUNCED_ON_BOARDING_SLIDE:
        return DenouncedOnBoardingSlide;

      case ARTICLE_IMAGES:
        return ArticleImagesModal;

      case ARTICLE_IMAGES_CATALOGO:
        return ArticleImagesModalCatalogo;

      case AUDITOR_COMMENT_MODAL:
        return AuditorCommentModal;

      default:
        return null;
    }
  })();

  const modalClassNames = () => {
    switch (modalType) {
      case "drawerR":
        return "modal-dialog modal-md drawer-right";
      case "small":
        return "modal-dialog modal-sm";
      case "medium":
        return "modal-dialog modal-md";
      default:
        return "modal-dialog modal-lg";
    }
  };

  /**
   * TO DO: Create HOC - 'ModalContainer' to not run onClose twice
   * when it's called from inside the ModalContent Component.
   * eg. 'ArticleReport'
   */
  const handleClose = () => {
    if (typeof props.onClose === "function") {
      props.onClose();
    }
    props.closeModal();
  };

  return (
    <div
      tabIndex="-1"
      role="dialog"
      aria-hidden="false"
      onClick={handleClose}
      className={classnames("modal fade in", {
        "overflow--hidden": modalType === "drawerR",
      })}
      style={{
        display: "block",
        backgroundColor: "rgba(0, 0, 0, 0.4)",
      }}
    >
      <div
        role="document"
        className={modalClassNames()}
        onClick={(e) => e.stopPropagation()}
      >
        {ModalContent ? <ModalContent {...props} /> : null}
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  open: state.global.isModalOpen,
  ...state.global.modal,
  showImportSuccess: state.detail.showImportSuccess,
});

export default connect(mapStateToProps, { openModal, closeModal })(Modal);
