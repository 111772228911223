export default `
  mutation (
    $action: String!,
    $articlesIds: [String],
  ) {
    deleteArticlesFromDB(deletedArticles: {
      action: $action
      articlesIds: $articlesIds
    }) {
      articlesIds
    }
  }
`;