import { from } from 'rxjs';
import { ofType } from 'redux-observable';
import { mergeMap } from 'rxjs/operators';
import { gqlQuery } from "libs/awsLib";
import { queryArticleQuestions } from "gql/index";
import {
  FETCH_ARTICLE_QUESTIONS,
  fetchArticleQuestionsFulfilled,
  fetchArticleQuestionsError,
} from "redux/actions/articlesQuestions";


/* EPICS */
export const fetchArticleQuestionsEpic = action$ => action$.pipe(
  ofType(FETCH_ARTICLE_QUESTIONS),
  mergeMap(({ payload }) => 
    from(
      gqlQuery(queryArticleQuestions, payload)
        .then(fetchArticleQuestionsFulfilled)
        .catch(fetchArticleQuestionsError)
    )
  )
);