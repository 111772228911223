/* eslint-disable max-len */
/**
 * CONSTANTS 
 */
export const CHANGE_KEYWORD = "CHANGE_KEYWORD";

export const CHANGE_SELLER_NICKNAME = "CHANGE_SELLER_NICKNAME";

export const SEARCH_PREVIEW = "SEARCH_PREVIEW";
export const SEARCH_PREVIEW_FULFILLED = "SEARCH_PREVIEW_FULFILLED";
export const SEARCH_PREVIEW_ERROR = "SEARCH_PREVIEW_ERROR";
export const CANCEL_SEARCH_PREVIEW = "CANCEL_SEARCH_PREVIEW";

export const CHANGE_SEARCH_RESULT = "CHANGE_SEARCH_RESULT";

export const CHANGE_SEARCH_TYPE = "CHANGE_SEARCH_TYPE";

export const CREATE_LIVE_SEARCH = "CREATE_LIVE_SEARCH";
export const CREATE_LIVE_SEARCH_SUCCESS = "CREATE_LIVE_SEARCH_SUCCESS";
export const CREATE_LIVE_SEARCH_ERROR = "CREATE_LIVE_SEARCH_ERROR";

export const DELETE_LIVE_SEARCH = "DELETE_LIVE_SEARCH";
export const DELETE_LIVE_SEARCH_ERROR = "DELETE_LIVE_SEARCH_ERROR";

export const FETCH_LIVE_SEARCHES = "FETCH_LIVE_SEARCHES";
export const LIVE_SEARCHES_FULFILLED = "LIVE_SEARCHES_FULFILLED";
export const FETCH_LIVE_SEARCHES_ERROR = "FETCH_LIVE_SEARCHES_ERROR";

export const CREATE_LIVE_SEARCH_REPORT = "CREATE_LIVE_SEARCH_REPORT";
export const CREATE_LIVE_SEARCH_REPORT_SUCCESS = "CREATE_LIVE_SEARCH_REPORT_SUCCESS";
export const CREATE_LIVE_SEARCH_REPORT_ERROR = "CREATE_LIVE_SEARCH_REPORT_ERROR";

export const FETCH_LIVE_SEARCH_REPORTS = "FETCH_LIVE_SEARCH_REPORTS";
export const LIVE_SEARCH_REPORTS_FULFILLED = "LIVE_SEARCH_REPORTS_FULFILLED";
export const LIVE_SEARCH_REPORTS_ERROR = "LIVE_SEARCH_REPORTS_ERROR";

export const DELETE_LIVE_SEARCH_REPORT = "DELETE_LIVE_SEARCH_REPORT";
export const DELETE_LIVE_SEARCH_REPORT_SUCCESS = "DELETE_LIVE_SEARCH_REPORT_SUCCESS";
export const DELETE_LIVE_SEARCH_REPORT_ERROR = "DELETE_LIVE_SEARCH_REPORT_ERROR";

export const RESET_CREATED_LIVESEARCH = "RESET_CREATED_LIVESEARCH";

export const CREATE_SELLER_LIVE_SEARCH = "CREATE_SELLER_LIVE_SEARCH";
export const CREATE_SELLER_LIVE_SEARCH_SUCCESS = "CREATE_SELLER_LIVE_SEARCH_SUCCESS";
export const CREATE_SELLER_LIVE_SEARCH_ERROR = "CREATE_SELLER_LIVE_SEARCH_ERROR";

export const FETCH_FILTER_PROFILE_SELLERS = "FETCH_FILTER_PROFILE_SELLERS";
export const FETCH_FILTER_PROFILE_SELLERS_FULFILLED = "FETCH_FILTER_PROFILE_SELLERS_FULFILLED";
export const FETCH_FILTER_PROFILE_SELLERS_ERROR = "FETCH_FILTER_PROFILE_SELLERS_ERROR";

/**
 * DISPATCHERS 
 */
export const changeKeyword = keyword => ({ type: CHANGE_KEYWORD, payload: { keyword } });

export const changeSellerNickname = sellerNickname => ({ type: CHANGE_SELLER_NICKNAME, payload: { sellerNickname }});

export const searchPreview = payload => ({ type: SEARCH_PREVIEW, payload });
export const searchPreviewFulfilled = payload => ({ type: SEARCH_PREVIEW_FULFILLED, payload });
export const searchPreviewError = payload => ({ type: SEARCH_PREVIEW_ERROR, payload });
export const cancelSearchPreview = () => ({ type: CANCEL_SEARCH_PREVIEW });

export const changeSearchResult = search => ({ type: CHANGE_SEARCH_RESULT, payload: { search } });

export const changeSearchType = payload => ({ type: CHANGE_SEARCH_TYPE, payload });

export const createLiveSearch = liveSearch => ({ type: CREATE_LIVE_SEARCH, payload: { liveSearch } });
export const createLiveSearchSuccess = payload => ({ type: CREATE_LIVE_SEARCH_SUCCESS, payload });
export const createLiveSearchError = payload => ({ type: CREATE_LIVE_SEARCH_ERROR, payload });

export const deleteLiveSearch = id => ({ type: DELETE_LIVE_SEARCH, payload: { id } });
export const deleteLiveSearchError = payload => ({ type: DELETE_LIVE_SEARCH_ERROR, payload });

export const fetchLiveSearches = payload => ({ type: FETCH_LIVE_SEARCHES, payload });
export const liveSearchesFulfilled = payload => ({ type: LIVE_SEARCHES_FULFILLED, payload });
export const fetchLiveSearchesError = payload => ({ type: FETCH_LIVE_SEARCHES_ERROR, payload });

export const createLiveSearchReport = payload => ({ type: CREATE_LIVE_SEARCH_REPORT, payload });
export const createLiveSearchReportSuccess = payload => ({ type: CREATE_LIVE_SEARCH_REPORT_SUCCESS, payload });
export const createLiveSearchReportError = payload => ({ type: CREATE_LIVE_SEARCH_REPORT_ERROR, payload });

export const fetchLiveSearchReports = payload => ({ type: FETCH_LIVE_SEARCH_REPORTS, payload });
export const liveSearchReportsFulfilled = payload => ({ type: LIVE_SEARCH_REPORTS_FULFILLED, payload });
export const liveSearchReportsError = payload => ({ type: LIVE_SEARCH_REPORTS_ERROR, payload });

export const deleteLiveSearchReport = payload => ({ type: DELETE_LIVE_SEARCH_REPORT, payload });
export const deleteLiveSearchReportSuccess = payload => ({ type: DELETE_LIVE_SEARCH_REPORT_SUCCESS, payload });
export const deleteLiveSearchReportError = payload => ({ type: DELETE_LIVE_SEARCH_REPORT_ERROR, payload });

export const resetCreatedLivesearch = () => ({ type: RESET_CREATED_LIVESEARCH });

export const createSellerLiveSearch = payload => ({ type: CREATE_SELLER_LIVE_SEARCH, payload });
export const createSellerLiveSearchSuccess = payload => ({ type: CREATE_SELLER_LIVE_SEARCH_SUCCESS, payload });
export const createSellerLiveSearchError = payload => ({ type: CREATE_SELLER_LIVE_SEARCH_ERROR, payload });

export const fetchFilterProfileSellers = payload => ({ type: FETCH_FILTER_PROFILE_SELLERS, payload });
export const fetchFilterProfileSellersFulfilled = payload => ({ type: FETCH_FILTER_PROFILE_SELLERS_FULFILLED, payload });
export const fetchFilterProfileSellersError = payload => ({ type: FETCH_FILTER_PROFILE_SELLERS_ERROR, payload });