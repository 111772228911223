import { t } from "utils"; 
import { alertError } from "./index";

export const fetchSuggestedArticlesFulfilledUtil = (state, payload) => {

  let suggestedArticles = [
    ...state.suggestedArticles,
    ...payload.suggestedArticles,
  ];

  // Fixed bug repeated articles when country filter is active @TODO check this
  let suggestedArticlesIds = [...new Set(suggestedArticles.map(item => item.id))];
  suggestedArticles = [...new Map(suggestedArticles.map(item => [item.id, item])).values()]

  const articlesOriginalPinned = payload.articlesPinned || [];
  const articlesPinned = articlesOriginalPinned
      .filter(item => suggestedArticles
                          .map(article => article.id)
                          .indexOf(item.id) > -1);
  
  const suggestedArticlesIdsPinned = articlesPinned.map(item => item.id);

  suggestedArticlesIds = suggestedArticlesIdsPinned.concat(
      suggestedArticles
        .filter(item => suggestedArticlesIdsPinned.indexOf(item.id) === -1)
        .map(item => item.id)
  );

  const suggestedArticlesPinned = articlesPinned;
  suggestedArticles = suggestedArticlesPinned.concat(
    suggestedArticles
      .filter(item => suggestedArticlesIdsPinned.indexOf(item.id) === -1)
  )

  return {
    ...state,
    ...payload,
    suggestedArticles,
    suggestedArticlesIds,
    isLoadingArticles: false,
  };
};

export const fetchSuggestedArticlesWithPinnedFulfilledUtil = (state, payload) => {

  const articlesOriginalPinned = payload.updateProfile.helps.articlesPinned || [];
 
  const articlesPinned = articlesOriginalPinned
      .filter(item => state.suggestedArticles
                          .map(article => article.id)
                          .indexOf(item.id) > -1);
  
  const suggestedArticlesIdsPinned = articlesPinned.map(item => item.id);

  const suggestedArticlesIds = suggestedArticlesIdsPinned.concat(
      state.suggestedArticles
        .filter(item => suggestedArticlesIdsPinned.indexOf(item.id) === -1)
        .map(item => item.id)
  );

  const suggestedArticlesPinned = articlesPinned;

  const suggestedArticles = suggestedArticlesPinned.concat(
    state.suggestedArticles
      .filter(item => suggestedArticlesIdsPinned.indexOf(item.id) === -1)
  )

  return {
    ...state,
    ...payload,
    suggestedArticles,
    suggestedArticlesIds,
    isLoadingArticles: false,
    isUpdatingArticlesPinned: false
  };
};

export const fetchSuggestedArticlesError = state => {
  alertError(t('server_error'));

  return {
    ...state,
    error: true,
    isLoadingArticles: false,
  };
}; 

export const fetchSuggestedArticlesStatsError = state => {
  alertError(t('server_error'));

  return {
    ...state,
    error: true,
    isLoadingStats: false,
  };
};

export const approveArticlesSuccessUtil = (state, { articlesIds = [] }) => {
  const articles = state.suggestedArticles.slice(0);
  const filteredArticles = articles.filter(article => articlesIds.indexOf(article.id) === -1);
  const diff = articles.length - filteredArticles.length;
  const suggestedStats = { ...(state.suggestedStats || {}) };
  const suggestedArticlesIds = filteredArticles.map(item => item.id);

  return { 
    ...state,
    suggestedArticles: filteredArticles,
    suggestedArticlesIds,
    suggestedStats: {
      ...suggestedStats,
      approved: (suggestedStats.approved || 0) + diff
    }
  };
};

export const reportArticlesSuccessUtil = (state, { articlesIds = [] }) => {
  const articles = state.suggestedArticles.slice(0);
  const filteredArticles = articles.filter(article => articlesIds.indexOf(article.id) === -1);
  const diff = articles.length - filteredArticles.length;
  const suggestedStats = { ...(state.suggestedStats || {}) };
  const suggestedArticlesIds = filteredArticles.map(item => item.id);

  return {
    ...state,
    suggestedArticles: filteredArticles,
    suggestedArticlesIds,
    suggestedReportedCount: state.suggestedReportedCount + diff,
    suggestedStats: {
      ...suggestedStats,
      reported: (suggestedStats.reported || 0) + diff
    },
  };
};

export const discardArticlesSuccessUtil = (state, { articlesIds = [] }) => {
  const articles = state.suggestedArticles.slice(0);
  const filteredArticles = articles.filter(article => articlesIds.indexOf(article.id) === -1);
  const suggestedArticlesIds = filteredArticles.map(item => item.id);

  return {
    ...state,
    suggestedArticles: filteredArticles,
    suggestedArticlesIds,
  };
};

export const setBlackSellerSuccess = (state, payload) => {
  const suggestedArticles = state.suggestedArticles;

  suggestedArticles 
  && suggestedArticles.map(article => {
    if (article.seller.id === payload.id) {
      article.seller.blackSeller = true;
      article.seller.whiteSeller = false;
    }
    return article;
  });

  return {
    ...state,
    suggestedArticles,
    updatingSellerStatus: false,
  };
};

export const unsetBlackSellerSuccess = (state, payload) => {
  const suggestedArticles = state.suggestedArticles;

  suggestedArticles
  && suggestedArticles.map(article => {
    if (article.seller.id === payload.deleteSellerFromDB.deletedId) {
      article.seller.blackSeller = false;
    }
    return article;
  });

  return {
    ...state,
    suggestedArticles,
    updatingSellerStatus: false,
  };
};

export const setWhiteSellerSuccess = (state, payload) => {
  let suggestedArticles = state.suggestedArticles;

  suggestedArticles 
  && suggestedArticles.map(article => {
    if (article.seller.id === payload.id) {
      article.seller.whiteSeller = true;
      article.seller.blackSeller = false;
    }
    return article;
  });

  return {
    ...state,
    suggestedArticles,
    updatingSellerStatus: false,
  };
};

export const unsetWhiteSellerSuccess = (state, payload) => {
  const suggestedArticles = state.suggestedArticles;
  
  suggestedArticles
  && suggestedArticles.map(article => {
    if (article.seller.id === payload.deleteSellerFromDB.deletedId) {
      article.seller.whiteSeller = false;
    }
    return article;
  });

  return {
    ...state,
    suggestedArticles,
    updatingSellerStatus: false,
  };
};

export const unsetSellerError = state => {
  alertError(t('unset_seller_error'));

  return {
    ...state,
    updatingSellerStatus: false,
  };
};

