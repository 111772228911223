import { alertError, alertSuccess } from './index';
import { t } from "utils";
import { STATEMENT_OR_BLOCK_KEYS } from '@babel/types';

/* eslint-disable */


export const fetchCounterNoticesSuccess = (state, payload) => {

  return {
    ...state,
    ...payload,
    isLoading: false,
  };
};

export const fetchCounterNoticesError = (state, error) => {
  alertError(t('server_error'));
  console.error(error);

  return {
    ...state,
    isLoading: false,
  };
}; 


export const fetchPendingDenounceSuccess = (state, payload) => {
  return {
    ...state,
    ...payload,
    isLoading: false,
  };
};

export const fetchPendingDenounceError = (state, error) => {
  alertError(t('server_error'));
  console.error(error);

  return {
    ...state,
    isLoading: false,
  };
}; 
