import React, { Component } from "react";
import classnames from "classnames";
import { connect } from "react-redux";
import { Scrollbars } from "react-custom-scrollbars";
import { faCircle, faDotCircle } from "@fortawesome/free-regular-svg-icons";
import { closeModal } from "redux/actions/global";
import Icon from "components/Icon";
import { t } from "utils";


class CategorySelector extends Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedCategory: props.selectedCategory, 
      showMore: (
        !props.categories ||
        (
          props.categories &&
          !(props.categories.values.length > 9)
        )
      ),
    };
  }

  handleClose = e => {
    e.preventDefault();
    this.props.closeModal();
  }

  handleChange = e => {
    e.preventDefault();
    this.setState({ selectedCategory: e.target.value });
  }

  handleApply = () => {
    this.props.onApplyCategory &&
      this.props.onApplyCategory(
        this.state.selectedCategory ? 
          this.state.selectedCategory : 
          null
      );

    this.props.closeModal();
  }

  render() {
    const { categories, selectedCategoryName } = this.props;
    const { showMore, selectedCategory } = this.state;
    const visibleCategories = categories && showMore ? 
      categories.values : 
      categories ?
        categories.values.slice(0, 8) :
        null;

    return (
      <div
        tabIndex="1"
        className="modal-content category-selector">
        <div className="modal-category-selector_container">
          <header>
            <h1>
              {t('filter_profile.categories')}
              <i className="modal-close" onClick={this.handleClose} />
            </h1>
            <span>
              {t('filter_profile.info_categories_language')}
            </span>
          </header>
          <section>
            <form onSubmit={e => e.preventDefault()}>
              <div className="form-group live-search-filters">
                <div className="radio-fields">
                  <Scrollbars
                    autoHeight
                    autoHeightMax={315}>
                    <label
                      key="none"
                      className={classnames(
                        "col-md-4",
                        "radio-group",
                        { "checked": !selectedCategory }
                      )}>
                      <span>
                        <Icon 
                          icon={
                            !selectedCategory ?
                              faDotCircle :
                              faCircle
                          } />
                        {t('filter_profile.none_category')}
                      </span>
                      <input
                        value=""
                        type="radio"
                        name="category"
                        onChange={this.handleChange} />
                    </label>
                    {visibleCategories &&
                      visibleCategories.map(category => 
                        <label
                          key={category.id}
                          className={classnames(
                            "col-md-4",
                            "radio-group",
                            { "checked": category.id === selectedCategory }
                          )}>
                          <span title={category.name}>
                            <Icon 
                              icon={
                                category.id === selectedCategory ?
                                  faDotCircle :
                                  faCircle
                              } />
                            {category.name}
                          </span>
                          <input
                            type="radio"
                            name="category"
                            value={category.id}
                            onChange={this.handleChange} />
                        </label>)}
                  </Scrollbars>
                </div>
                {(selectedCategoryName && !visibleCategories) &&
                  <p>
                    {t('filter_profile.no_subcategories_for')}{' '}
                    {selectedCategoryName}*
                  </p>}
                {!showMore &&
                    <p className="view-all">
                      <button onClick={() => this.setState({ showMore: true })}>
                        {t('view_all')}
                      </button>
                    </p>}
              </div>
              <div className="actions">
                <button 
                  onClick={this.handleClose}
                  className="btn transparent">
                  {t('cancel')}
                </button>
                <button 
                  className="btn"
                  onClick={this.handleApply}>
                  {t('apply')}
                </button>
              </div>
            </form>
          </section>
        </div>
      </div>
    );
  }
}

export default connect(null, { closeModal })(CategorySelector);