import {
  FETCH_ARTICLE_QUESTIONS,
  FETCH_ARTICLE_QUESTIONS_FULFILLED,
  FETCH_ARTICLE_QUESTIONS_ERROR,
} from "redux/actions/articlesQuestions";
/* Bussines logic */
import { 
  // addToQuestionsMap,
  fetchArticleQuestionsError,
  fetchArticleQuestionsFulfilled, 
} from "redux/reducers/utils/articlesQuestions";


const initialState = {
  articleId: null,
  isLoading: false,
  articleQuestions: [],
  articleQuestionsTotal: 0,

  questionsMap: {},
};
  
export default (state = initialState, action) => {
  switch (action.type) {
  case FETCH_ARTICLE_QUESTIONS:
    return {
      ...state,
      isLoading: true,
      articleQuestionsTotal: ((action.payload.from ) > 0 && (state.articleId === action.payload.articleId))
        ? state.articleQuestionsTotal 
        : 0,
      articleId: action.payload.articleId,
      articleQuestions: ((action.payload.from ) > 0 && (state.articleId === action.payload.articleId))
        ? state.articleQuestions 
        : [],
    };

  case FETCH_ARTICLE_QUESTIONS_FULFILLED:
    return fetchArticleQuestionsFulfilled(
      state,
      action.payload
    );

  case FETCH_ARTICLE_QUESTIONS_ERROR:
    return fetchArticleQuestionsError(
      state,
      action.payload
    );

  default:
    return state;
  }
};
  