import { from } from 'rxjs';
import { ofType } from 'redux-observable';
import { mergeMap } from 'rxjs/operators';
import { get } from "utils";
import { gqlQuery } from "libs/awsLib"; 
import {
  queryReportedArticles,
  queryShowTooltipStatus,
  queryReportedArticlesCount,
  queryReportedArticlesStatus,
  queryShowTooltipImportStatus,
  mutationSendNotice,
  mutationArticleUndo,
  mutationProfile,
  mutationDeleteArticles,
  // mutationSend,
  mutationSendBrandInfringement,
} from "gql/index";

import {
  FETCH_REPORTED_ARTICLES,
  reportedArticlesFulfilled,
  fetchReportedArticlesError,

  FETCH_REPORTED_ARTICLES_COUNT,
  fetchReportedArticlesCountError,
  reportedArticlesCountFulfilled,

  SEND_NOTICE,
  sendNoticeSuccess,
  sendNoticeError,

  REMOVE_ARTICLE,
  removeArticleSuccess,
  removeArticleError,

  DELETE_ARTICLES,
  deleteArticlesSuccess,
  deleteArticlesError,

  FETCH_SENDING_NOTICE_STATUS,
  fetchSendingNoticeStatusSuccess,
  fetchSendingNoticeStatusError,

  FETCH_SHOW_TOOLTIP_STATUS,
  fetchShowTooltipStatusFulfilled,
  fetchShowTooltipStatusError,

  FETCH_SHOW_TOOLTIP_IMPORT_STATUS,
  fetchShowTooltipImportStatusFulfilled,
  fetchShowTooltipImportStatusError,

  SET_NOT_SHOW_MORE,
  setTooltipNotShowMoreFulfilled,
  setTooltipNotShowMoreError,

  SET_NOTICES_LIMIT_DAY_COUNT,
  setNoticesLimitDayCountFulfilled,
  setNoticesLimitDayCountError,

  SET_SHOW_TOOLTIP_IMPORT_UNTIL,
  setShowReportImportTooltipUntilFulfilled,
  setShowReportImportTooltipUntilError,

  SEND_BRAND_INFRINGEMENT,
  sendBrandInfringementSuccess,
  sendBrandInfringementError,

} from "redux/actions/reportedArticles";


/* EPICS */
export const fetchReportedArticlesEpic = action$ => action$.pipe(
  ofType(FETCH_REPORTED_ARTICLES),
  mergeMap(({ payload }) => 
    from(
      gqlQuery(queryReportedArticles, { ...payload })
        .then(({ reportedArticles }) => {
          return reportedArticlesFulfilled({
            reportedArticlesFrom: reportedArticles.from,
            reportedArticles: reportedArticles.reportedArticles,
          });
        })
        .catch(fetchReportedArticlesError)
    )
  )
);

export const fetchReportedArticlesCountEpic = action$ => action$.pipe(
  ofType(FETCH_REPORTED_ARTICLES_COUNT),
  mergeMap(() =>
    from(
      gqlQuery(queryReportedArticlesCount)
        .then(response => reportedArticlesCountFulfilled(
          response.reportedArticlesCount.count
        ))
        .catch(fetchReportedArticlesCountError)
    )
  )
);

export const sendNoticeEpic = (action$, store) => action$.pipe(
  ofType(SEND_NOTICE),
  mergeMap(({ payload: articlesIds = []}) =>{
    const state = store.value.global;
    const {givenName,id } = state.profile;
    console.log("Auditor id == "+id)
    console.log("Auditor givenName == "+givenName)
    console.log(state.profile)
    return from(
     
      gqlQuery(mutationSendNotice, { articlesIds, auditor:id+"-"+givenName })
        .then(sendNoticeSuccess)
        .catch(error => sendNoticeError({ error, articlesIds }))
    )
  
  })

);

export const removeArticleEpic = (action$, store) => action$.pipe(
  ofType(REMOVE_ARTICLE),
  mergeMap(({ payload }) => {
    const state = store.value.reportedArticles;
    return from(
      gqlQuery(mutationArticleUndo, {
        undo: 'reported',
        articleId: payload.articleId,
        from: state.reportedArticlesFrom,
      }).then(({ articleUndo }) =>
        removeArticleSuccess({
          ...articleUndo,
          articleId: payload.articleId
        })
      ).catch(error => removeArticleError({
        error,
        articleId: payload.articleId
      }))
    );
  })
);

export const fetchSendingNoticeStatusEpic = (action$, store) =>  action$.pipe(
  ofType(FETCH_SENDING_NOTICE_STATUS),
  mergeMap(() => {
    const state = store.value.reportedArticles;
    const sendingIds = state.reportedArticles
      .filter(article =>
        get(article, 'noticed.sending', false) ||
        state.sendingNotice.indexOf(article.id) > -1
      ).map(article => article.id);  



    return from(
      gqlQuery(queryReportedArticlesStatus, { articlesIds: sendingIds })
        .then(response => fetchSendingNoticeStatusSuccess(response.articlesByIds))
        .catch(fetchSendingNoticeStatusError)
    );
  })
);

export const fetchShowTooltipStatusEpic = action$ => action$.pipe(
  ofType(FETCH_SHOW_TOOLTIP_STATUS),
  mergeMap(() =>
    from(
      gqlQuery(queryShowTooltipStatus)
        .then(response => response.profile.showDenounceReminder)
        .then(fetchShowTooltipStatusFulfilled)
        .catch(fetchShowTooltipStatusError)
    )
  )
);

export const fetchShowTooltipImportStatusEpic = action$ => action$.pipe(
  ofType(FETCH_SHOW_TOOLTIP_IMPORT_STATUS),
  mergeMap(() =>
    from(
      gqlQuery(queryShowTooltipImportStatus)
        .then(response => response.profile.showReportImportTooltipUntil)
        .then(fetchShowTooltipImportStatusFulfilled)
        .catch(fetchShowTooltipImportStatusError)
    )
  )
);

export const setTooltipNotShowMoreEpic = (action$, store) => action$.pipe(
  ofType(SET_NOT_SHOW_MORE),
  mergeMap(({ payload }) => {
    const state = store.value.global;
    const { lang, currency, givenName } = state.profile;
    return from(
      gqlQuery(mutationProfile, {
        lang,
        currency,
        givenName,
        blockDenounceReminderUntil: String(payload || false)
      }).then(setTooltipNotShowMoreFulfilled)
        .catch(setTooltipNotShowMoreError)
    );
  })
);

export const setShowReportImportTooltipUntilEpic = (action$, store) => action$.pipe(
  ofType(SET_SHOW_TOOLTIP_IMPORT_UNTIL),
  mergeMap(({ payload }) => {
    const state = store.value.global;
    const { lang, currency, givenName } = state.profile;
    return from(
      gqlQuery(mutationProfile, {
        lang,
        currency,
        givenName,
        reportImportTooltipUntil: Boolean(payload)
      }).then(setShowReportImportTooltipUntilFulfilled)
        .catch(setShowReportImportTooltipUntilError)
    );
  })
);

export const deleteArticlesEpic = action$ => action$.pipe(
  ofType(DELETE_ARTICLES),
  mergeMap(({ payload }) => {
    return from(
      gqlQuery(mutationDeleteArticles, { ...payload })
        .then(deleteArticlesSuccess)
        .catch(error =>
          deleteArticlesError({
            error,
          })
        )
    );
  })
);

export const setNoticesLimitDayCount = (action$, store) => action$.pipe(
  ofType(SET_NOTICES_LIMIT_DAY_COUNT),
  mergeMap(({ payload }) => {
    const state = store.value.global;
    const { lang, currency, givenName } = state.profile;
    return from(
      gqlQuery(mutationProfile, {
        lang,
        currency,
        givenName,
        noticesLimitDayCount: Number(payload || 0)
      }).then(setNoticesLimitDayCountFulfilled)
        .catch(setNoticesLimitDayCountError)
    );
  })
);


export const sendBrandInfringementEpic = (action$, store) => action$.pipe(
  ofType(SEND_BRAND_INFRINGEMENT),
  mergeMap(({ payload }) => {

    const { productId, reportReasonCommet, reportReasonDetail,reportReasonId } = payload;
    return from(
      gqlQuery(mutationSendBrandInfringement, {
        productId,
        reportReasonCommet,
        reportReasonDetail,
        reportReasonId
      }).then(sendBrandInfringementSuccess)
        .catch(sendBrandInfringementError)
    );
  })
);
