import React from "react";

export default function SellerScore({ seller }) {
  if (!seller.reputationPowerSellerStatus) return null;

  return (
    <div className="seller__score">
      Mercado Líder {seller.reputationPowerSellerStatus}
    </div>
  );
}