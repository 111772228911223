import React, { useState, useCallback, useEffect } from "react";
import { connect } from "react-redux";
import {
  faCheckCircle,
  faExclamationCircle,
} from "@fortawesome/free-solid-svg-icons";
import { openModal, closeModal } from "redux/actions/global";
import ImportedFileDetails from "components/ImportedFileDetails";
import Icon from "components/Icon";
import { reportArticles } from "redux/actions/detail";
import { t } from "utils";
import Alert from "components/Alert";

function BlindageReportMultiple(props) {
  const { closeModal } = props;
  const [invalidIds, setInvalidIds] = useState([]); // duplicated
  const [notFoundIds, setNotFoundIds] = useState([]); // error
  const handleClose = useCallback(closeModal, [closeModal]);
  // const mapItem = useCallback(
  //   (id) =>
  //     isMeliUrl(id)
  //       ? getItemIdFromURL(id)
  //       : id.trim().toUpperCase().replace(" ", "").replace("-", ""),
  //   []
  // );
  const handleSubmit = (e) => {
    e.preventDefault();
    window.location.pathname = "/reported_articles";
  };

  let results = props.articlesIds.length + props.blindagesIds.length;

  console.log("saaa", props.blindagesIds.length);
  console.log("saaa", props.articlesIds.length);
  return (
    <div tabIndex="1" className="modal-content add-suspicious-xlsx-modal">
      <form onSubmit={handleSubmit} className="new_reported_article">
        <div className="top-container">
          <div className="title">{t("blindage.modal_multiple.title")}</div>

          <button onClick={handleClose} className="btn-modal-close">
            &times;
          </button>
          {props.articlesIds.length > 0 && (
            <div className="subtitle">
              {props.blindagesIds.length > 0
                ? t("blindage.modal_multiple.subtitle_errors")
                : t("blindage.modal_multiple.subtitle_success")}
            </div>
          )}
        </div>
        {
          <>
            {props.blindagesIds.length > 0 && props.articlesIds.length > 0 && (
              <div className="results-container">
                <div className="results">
                  <span>
                    {results}&nbsp;
                    {results === 1
                      ? t("blindage.modal_multiple.element")
                      : t("blindage.modal_multiple.elements")}
                  </span>
                </div>

                <div className="numbers">
                  <p>
                    <Icon
                      icon={faCheckCircle}
                      iProps={{ className: "check" }}
                    />
                    {props.articlesIds.length}&nbsp;
                    {props.articlesIds.length === 1
                      ? t("blindage.modal_multiple.notBlindage_found")
                      : t("blindage.modal_multiple.notBlindages_found")}
                  </p>

                  <p>
                    <Icon
                      icon={faExclamationCircle}
                      iProps={{ className: "blindages" }}
                    />
                    {props.blindagesIds.length}&nbsp;
                    {props.blindagesIds.length === 1
                      ? t("blindage.modal_multiple.blindage_found")
                      : t("blindage.modal_multiple.blindages_found")}
                  </p>
                </div>

                <ImportedFileDetails
                  lang={props.profile.lang}
                  articlesIds={props.articlesIds}
                  invalidIds={invalidIds}
                  notFoundIds={notFoundIds}
                  blindagesInfoExcel={props.blindagesInfoExcel}
                />
              </div>
            )}
            {props.articlesIds.length > 0 &&
              props.blindagesIds.length === 0 && (
                <div className="results-container">
                  <div className="numbers">
                    <p>
                      <Icon
                        icon={faCheckCircle}
                        iProps={{ className: "check" }}
                      />
                      {props.articlesIds.length}&nbsp;
                      {props.articlesIds.length === 1
                        ? t("blindage.modal_multiple.notBlindage_found")
                        : t("blindage.modal_multiple.notBlindages_found")}
                    </p>
                  </div>
                </div>
              )}

            {props.blindagesIds.length > 0 &&
              props.articlesIds.length === 0 && (
                <Alert
                  alertType="warning"
                  styleType="not-filled"
                  className="alert__snack-translate-message"
                >
                  <div className="icon__container">
                    <img alt="alert" src="/images/alerts/caution.svg" />
                  </div>

                  <strong>
                    <>
                      {props.blindagesIds.length === 1
                        ? t("blindage.modal_excel.blindage_found2")
                        : t("blindage.modal_excel.blindages_found2")}
                    </>
                  </strong>

                  <div className="content">
                    {t("blindage.modal_excel.blindage_content")}
                  </div>

                  <ImportedFileDetails
                    lang={props.profile.lang}
                    articlesIds={props.articlesIds}
                    invalidIds={invalidIds}
                    notFoundIds={notFoundIds}
                    blindagesInfoExcel={props.blindagesInfoExcel}
                  />
                </Alert>
              )}
          </>
        }

        <div className="actions">
          <div className="content">
            <button className="btn btn-success">
              {t("ls_reported_articles_link")}
            </button>
            <button onClick={handleClose} className="btn transparent">
              {t("cancel")}
            </button>
          </div>
        </div>
      </form>
    </div>
  );
}

const mapStateToProps = (state) => ({
  profile: state.global.profile,
});

export default connect(mapStateToProps, {
  openModal,
  closeModal,
  reportArticles,
})(BlindageReportMultiple);
