export default `
  query(
    $size: Int,
    $from: String,
    $filters: String
  ) {
    blackListSellers(
      size: $size,
      from: $from,
      filters: $filters
    ) {
      size
      from
      total
      sellers {
        id
        amount
        reports
        siteId
        nickname
        countryName
      }
    }
  }
`;
