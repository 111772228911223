import { from } from 'rxjs';
import { ofType } from 'redux-observable';
import { mergeMap } from 'rxjs/operators';
import { gqlQuery } from "libs/awsLib";
import { 
  queryBlackListSellers,
  mutationDeleteSellerFromDB,
} from "gql/index";
import {
  FETCH_BLACK_LIST_SELLERS,
  fetchBlackListSellersFulfilled,
  fetchBlackListSellersError,
	
  DELETE_BLACK_LIST_SELLERS,
  deleteBlackListSellersSuccess,
  deleteBlackListSellersError,
} from "redux/actions/blackListSellers";


/* EPICS */
export const fetchBlackListSellersEpic = action$ => action$.pipe(
  ofType(FETCH_BLACK_LIST_SELLERS),
  mergeMap(({ payload }) => {
    const variables = {
      ...payload,
      filters: payload.filters ?
        JSON.stringify(payload.filters) :
        ""
    };
    
    return from(
      gqlQuery(queryBlackListSellers, variables)
        .then(response =>
          fetchBlackListSellersFulfilled({ 
            blackListSellers: response.blackListSellers.sellers, 
            blackListSellersFrom: response.blackListSellers.from,
            blackListSellersTotal: response.blackListSellers.total,
          })
        )
        .catch(fetchBlackListSellersError)
    );
  })
);

export const deleteBlackListSellersEpic = (action$, store) => action$.pipe(
  ofType(DELETE_BLACK_LIST_SELLERS),
  mergeMap(({ payload }) => {
    const state = store.value.blackListSellers;
    
    return from(
      gqlQuery(
        mutationDeleteSellerFromDB, { 
          ...payload,
          from: state.blackListSellersFrom
        })
        .then(response => deleteBlackListSellersSuccess({
          id: response.deleteSellerFromDB.deletedId,
          blackListSellersFrom: response.deleteSellerFromDB.from,
          blackListSellers: response.deleteSellerFromDB.seller ?
            [response.deleteSellerFromDB.seller] : [],
        })) 
        .catch(error => deleteBlackListSellersError({
          error,
          id: payload.sellerId
        }))
    );
  })
);
