export default `{
	liveSearches {
		id 
		keyword 
		sellers {
			nickname
		}
		filterProfiles {
			sites {
				countrySites
			}
		}
		createdAt
		failed
		finished 
		finishedAt
		totalQuantity 
	}
}`;