export default `
  query($size: Int, $from: Int) {
    liveSearchReports(size: $size, from: $from) {
      id
      url
      title
      ready
      failed
      createdAt
      generating
    }
  }
`;