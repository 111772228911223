import { articleFields } from "../fragments";


export default `
  ${articleFields}
  
  query($size: Int!, $from: Int, $siteId: String) {
    suggestedArticles(size: $size, from: $from, siteId: $siteId) {
      size
      from
      siteId
      suggestedArticles {
        ...articleFields
      }
    }
  }
`;