import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { closeModal } from "redux/actions/global";
import { t, getIdFromImageSrc } from "utils";
import Icon from "components/Icon";
import { faExclamation } from '@fortawesome/free-solid-svg-icons';

class PublicationViewImage extends Component {
 
  constructor(props) {
    super(props);

    const { data } = props;
   
    this.state = {
      data
    };
  }

  render() {

    const { closeModal } = this.props;

    const name = this.state.data['name'];
    const url = this.state.data['url'];
  
    return (
      <div className="panel view-image-ctn">
        <div className="container">
          <i className="modal-close" style={{marginRight: '24px'}} onClick={closeModal} />
          <div className="header-modal-view-image">
            <div className="name">
              {name}
            </div>
            <div className="button-ctn download-btn">    
              <button
                className="btn"
                onClick={(e) => {
                  e.preventDefault();
                  window.open(url);
                }}
              >
                {t('notice_reports.index.table_th_download')}
              </button>
            </div>
          </div> 
          <hr />
          <div className="main">
            <img
              src={url}
              alt="documentation"
              className="doc-image"
            />
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  profile: state.global.profile,
});

const actions = {
  closeModal,
};

export default withRouter(connect(mapStateToProps, actions)(PublicationViewImage));