import fx from "money";
import * as XLSX from "xlsx";
import getSymbolFromCurrency from "currency-symbol-map";
import i18n from "./i18n";
import moment from "moment";
const utf8 = require("utf8");

/* eslint-disable */
export { alertInfo, alertError, alertSuccess } from "./redux/reducers/utils";

/**
 * @function querystring - Get value of 'name' param in the given 'url' param or in the window.location.href
 * @param {string} name
 * @param {string} url
 * @return {string}
 */
export function querystring(name, url = window.location.href) {
  name = name.replace(/[[]]/g, "\\$&");

  const regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)", "i");
  const results = regex.exec(url);

  if (!results) {
    return null;
  }

  if (!results[2]) {
    return "";
  }

  return decodeURIComponent(results[2].replace(/\+/g, " "));
}

/**
 * @function convertPrice
 * @param {number} price
 * @param {string} from - from ISO 4217 currency code
 * @param {string} to - to ISO 4217 currency code
 * @return {number}
 */
export const convertPrice = (price, from, to) => {
  let convertedPrice = t("no_data");

  if (fx.base && fx.rates && fx.rates[from] && fx.rates[to]) {
    try {
      convertedPrice = Number(fx(price).convert({ from, to }).toFixed(2));
    } catch (e) {
      /* eslint-disable no-console */
      console.error(e);
      console.error(e.stack);
      /* eslint-enable no-console */
    }
  }

  return convertedPrice;
};

/**
 * @function articlePrice - Return formatted article price
 * @param {Object} article
 * @param {string} currency - current user ISO 4217 currency code
 * @return {string}
 */
export function articlePrice(article, currency = "ARS", withoutOrig = false) {
  const price = article.price;
  const siteId = article.siteId || article.id.slice(0, 3);
  const articleCurrency = article.currency
    ? article.currency
    : getCurrencyFromSiteId(siteId);
  const articleCurrencySymbol = getSymbolFromCurrency(articleCurrency);
  const profileCurrencySymbol = getSymbolFromCurrency(currency);

  if (!price) return t("no_data");
  if (!currency)
    return `orig. ${price} ${articleCurrencySymbol} ${articleCurrency}`;
  if (!articleCurrency) return `orig. ${price}`;

  if (withoutOrig) {
    return (
      `${convertPrice(price, articleCurrency, currency)} ` +
      `${profileCurrencySymbol} ${currency}`
    );
  }

  const articlePrice =
    articleCurrency === currency
      ? `${price}  ${profileCurrencySymbol} ${currency}`
      : `${convertPrice(price, articleCurrency, currency)} ` +
        `${profileCurrencySymbol} ${currency}` +
        ` (orig. ${price} ${articleCurrencySymbol} ${articleCurrency})`;

  return articlePrice;
}

/**
 * @function sellerLocation - Return format for displaying the seller location
 * @param {Object} seller
 * @param {string} def - default value
 * @return {string}
 */
export function sellerLocation(seller, def) {
  if (!seller || !seller.address) return def;

  const city = seller.address.city ? seller.address.city + ", " : "";
  const country = seller.countryName ? seller.countryName : "";

  return city + country ? city + country : def;
}

/**
 * @constant {function} uniq - Return array with unique values
 * @param {Array} array
 * @returns {Array}
 */
export const uniq = (array) => [...new Set(array)];

/**
 * @function isNullish - Return true if a value is null or undefined.
 * @param value
 * @returns {boolean}
 */
export function isNullish(value) {
  return value === null || value === undefined;
}

/**
 * @function isNnN - Check if value is a non-negative number.
 * @param value
 * @returns {boolean}
 */
export function isNnN(value) {
  return !Number.isNaN(value) && value >= 0 && value <= Number.MAX_SAFE_INTEGER;
}

/**
 * @function get - Custom get like the one of lodash
 * @param {Object} obj
 * @param {string} attrs - String with path of attributes to get value inside obj, separated by points.
 * @param def - default value
 */
export function get(obj, attrs, def) {
  try {
    if (typeof obj !== "object") return def;

    attrs
      .split(".")
      .forEach((attr) => obj && typeof obj === "object" && (obj = obj[attr]));

    return !isNullish(obj) ? obj : def;
  } catch (e) {
    /* eslint-disable no-console */
    console.log(e);
    console.log(e.stack);
    /* eslint-enable no-console */
    return def;
  }
}

/**
 * @function t - Translate
 * @param {string} path
 * @returns {string}
 */
export function t(path) {
  try {
    return i18n.t(path);
  } catch (e) {
    /* eslint-disable no-console */
    console.error(e);
    console.error(e.stack);
    /* eslint-enable no-console */
    return e.message;
  }
}

/**
 * @function getValueFromNativeEvent
 * @param {(KeyboardEvent|MouseEvent)} e
 * @returns {Object} value - key value pair, key name of field & value
 */
export function getValueFromNativeEvent(e) {
  if (!e) return;
  let value = {};
  if (e.nativeEvent && e.nativeEvent.isTrusted) {
    if (e.target.name) {
      value = {
        [e.target.name]: e.target.value !== "" ? e.target.value : null,
      };
    }
  }
  return value;
}

/**
 * @function getAuthorizationStatus
 * @param {Array} authorizedSiteIds
 * @param {Array} sites
 * @returns {string}
 */
export function getAuthorizationStatus(authorizedSiteIds, sites) {
  let authorizationStatus = "noneAuthorized";
  const countrySites = get(sites[0], "countrySites", []);

  if (countrySites.length === authorizedSiteIds.length) {
    authorizationStatus = "allAuthorized";
  } else if (
    authorizedSiteIds.length > 0 &&
    countrySites.length !== authorizedSiteIds.length
  ) {
    authorizationStatus = "someAuthorized";
  }

  return authorizationStatus;
}

/**
 * @function parseProfileSettings
 * @param {Object} profile
 * @returns {Object} profile - parsed value
 */
function parseProfileSettings(profile) {
  if (!(profile.settings instanceof Object)) {
    return profile.settings;
  }

  if (profile.settings && Object.keys(profile.settings).length) {
    Object.keys(profile.settings).forEach((setting) => {
      try {
        if (!isNaN(setting)) {
          delete profile.settings[setting];
        }
      } catch (e) {
        /* eslint-disable no-console */
        console.log(e);
        console.log(e.stack);
        /* eslint-enable no-console */
      }
    });
    return JSON.stringify(profile.settings);
  } else {
    return JSON.stringify({});
  }
}

/**
 * @function parseProfile
 * @param {Object} profile
 * @returns {Object} profile - parsed value
 */
export function parseProfile(profile) {
  return {
    ...profile,
    settings: parseProfileSettings(profile),
  };
}

/**
 * @function jsonParse
 * @param {string} json
 * @returns {Object} json - parsed value
 */
export function jsonParse(data) {
  try {
    const parsed = JSON.parse(data);
    return parsed;
  } catch (e) {
    /* eslint-disable no-console */
    console.log(e);
    console.log(e.stack);
    /* eslint-enable no-console */
    return {};
  }
}

/**
 * @function cleanString
 * @param {Object} string
 * @returns {string} - cleaned string
 */
export function cleanString(string) {
  try {
    return string.split(/[/*]/).join("") || "";
  } catch (e) {
    /* eslint-disable no-console */
    console.log(e);
    console.log(e.stack);
    /* eslint-enable no-console */
    return "";
  }
}

/**
 * @function fullNameFromAuthor
 * @param {Object} author
 * @returns {string} - author's full name
 */
export function fullNameFromAuthor(article) {
  const author = article.approved
    ? article.approved.author
    : article.reported.author;

  return author ? `${author.givenName} ${author.familyName}` : "Unknown";
}

/**
 * @function getAuthCountrySiteUrl
 * @param {string} siteId
 * @returns {string} - Auth country site url
 */
export function getAuthCountrySiteUrl(siteId) {
  const siteUrlMap = {
    MLC: "https://auth.mercadolibre.com",
    MCR: "https://auth.mercadolibre.com",
    MSV: "https://auth.mercadolibre.com",
    MPE: "https://auth.mercadolibre.com",
    MBO: "https://auth.mercadolibre.com",
    MPA: "https://auth.mercadolibre.com",
    MEC: "https://auth.mercadolibre.com",
    MLV: "https://auth.mercadolibre.com",
    MCO: "https://auth.mercadolibre.com",
    MLU: "https://auth.mercadolibre.com",
    MLB: "https://auth.mercadolivre.com",
    MRD: "https://auth.mercadolibre.com",
    MGT: "https://auth.mercadolibre.com",
    MNI: "https://auth.mercadolibre.com",
    MHN: "https://auth.mercadolibre.com",
    MLA: "https://auth.mercadolibre.com",
    MLM: "https://auth.mercadolibre.com",
    MPY: "https://auth.mercadolibre.com",
  };
  return siteUrlMap[siteId] || "https://auth.mercadolibre.com";
}
export function getSidebarMenuUrl(url) {
  let localhost = isLocalhost();
  let menuUrl = "";
  if (localhost) {
    menuUrl = url.replace(
      "https://d1qcjjtfipqe2l.cloudfront.net/",
      "http://localhost:3000/"
    );
  } else {
    menuUrl = url;
  }

  return menuUrl;
}

export function imageX(imageUrl) {
  let image = imageUrl.replace("O.jpg", "X.jpg");
  return image;
}

export function replace_notPrintableUnicode(string) {
  const re =
    /[\u2060\u00A0\u1680\u180e\u2000-\u2009\u200a\u200b\u202f\u205f\u3000]/g;
  let strReplaced = string.replace(re, "");
  return strReplaced;
}

/** NOT BEING USED
 * @function getCountrySiteUrl - Return country site url
 * @param {string} siteId
 * @returns {string}
 */
export function getCountrySiteUrl(siteId) {
  const siteUrlMap = {
    MLC: "https://www.mercadolibre.cl",
    MCR: "https://www.mercadolibre.co.cr",
    MSV: "https://www.mercadolibre.com.sv",
    MPE: "https://www.mercadolibre.com.pe",
    MBO: "https://www.mercadolibre.com.bo",
    MPA: "https://www.mercadolibre.com.pa",
    MEC: "https://www.mercadolibre.com.ec",
    MLV: "https://www.mercadolibre.com.ve",
    MCO: "https://www.mercadolibre.com.co",
    MLU: "https://www.mercadolibre.com.uy",
    MLB: "https://www.mercadolivre.com.br",
    MRD: "https://www.mercadolibre.com.do",
    MGT: "https://www.mercadolibre.com.gt",
    MNI: "https://www.mercadolibre.com.ni",
    MHN: "https://www.mercadolibre.com.hn",
    MLA: "https://www.mercadolibre.com.ar",
    MLM: "https://www.mercadolibre.com.mx",
    MPY: "https://www.mercadolibre.com.py",
  };
  return siteUrlMap[siteId] || "https://www.mercadolibre.com.ar";
}

/** NOT BEING USED
 * @function getCountrySiteUrl - Return country site url
 * @param {string} siteId
 * @returns {string}
 */
export function getCountryFlagName(siteId) {
  const siteUrlMap = {
    MLC: "Chile",
    MCR: "Costa Rica",
    MSV: "Salvador",
    MPE: "Peru",
    MBO: "Bolivia",
    MPA: "Panamá",
    MEC: "Ecuador",
    MLV: "Venezuela",
    MCO: "Colombia",
    MLU: "Uruguay",
    MLB: "Brasil",
    MRD: "República Dominicana",
    MGT: "Guatemala",
    MNI: "Nicaragua",
    MHN: "Honduras",
    MLA: "Argentina",
    MLM: "Mexico",
    MPY: "Paraguay",
  };
  return siteUrlMap[siteId] || "";
}

/**
 * @function getEnrollRightsSiteUrl - Returns enroll rights url for site
 * @param {string} siteId
 * @returns {string}
 */
export function getEnrollRightsSiteUrl(siteId, lang) {
  const siteUrlMap = {
    MLC: `https://www.mercadolibre.cl/noindex/pppi/rights/member?lang=${lang}`,
    MCR: `https://www.mercadolibre.co.cr/noindex/pppi/rights/member?lang=${lang}`,
    MSV: `https://www.mercadolibre.com.sv/noindex/pppi/rights/member?lang=${lang}`,
    MPE: `https://www.mercadolibre.com.pe/noindex/pppi/rights/member?lang=${lang}`,
    MBO: `https://www.mercadolibre.com.bo/noindex/pppi/rights/member?lang=${lang}`,
    MPA: `https://www.mercadolibre.com.pa/noindex/pppi/rights/member?lang=${lang}`,
    MEC: `https://www.mercadolibre.com.ec/noindex/pppi/rights/member?lang=${lang}`,
    MLV: `https://www.mercadolibre.com.ve/noindex/pppi/rights/member?lang=${lang}`,
    MCO: `https://www.mercadolibre.com.co/noindex/pppi/rights/member?lang=${lang}`,
    MLU: `https://www.mercadolibre.com.uy/noindex/pppi/rights/member?lang=${lang}`,
    MLB: `https://www.mercadolivre.com.br/noindex/pppi/rights/member?lang=${lang}`,
    MRD: `https://www.mercadolibre.com.do/noindex/pppi/rights/member?lang=${lang}`,
    MGT: `https://www.mercadolibre.com.gt/noindex/pppi/rights/member?lang=${lang}`,
    MNI: `https://www.mercadolibre.com.ni/noindex/pppi/rights/member?lang=${lang}`,
    MHN: `https://www.mercadolibre.com.hn/noindex/pppi/rights/member?lang=${lang}`,
    MLA: `https://www.mercadolibre.com.ar/noindex/pppi/rights/member?lang=${lang}`,
    MLM: `https://www.mercadolibre.com.mx/noindex/pppi/rights/member?lang=${lang}`,
    MPY: `https://www.mercadolibre.com.py/noindex/pppi/rights/member?lang=${lang}`,
  };
  return (
    siteUrlMap[siteId] ||
    `https://www.mercadolibre.com.ar/noindex/pppi/rights/member?lang=es`
  );
}
/**
 * @function getMyAccountSiteUrl - Returns brandprotection account url for site
 * @param {string} siteId
 * @returns {string}
 */
export function getMyAccountSiteUrl(siteId, lang) {
  const DEFAULT_LANG = "ES";
  const LANG = {
    es: "ES",
    en: "en-US",
    pt: "pt-BR",
  };

  const language = LANG[lang] || DEFAULT_LANG;

  const siteUrlMap = {
    MLA: `https://brandprotection.mercadolibre.com.ar/my-account?force_locale=${language}`,
    MBO: `https://brandprotection.mercadolibre.com.bo/my-account?force_locale=${language}`,
    MLB: `https://brandprotection.mercadolivre.com.br/my-account?force_locale=${language}`,
    MLC: `https://brandprotection.mercadolibre.cl/my-account?force_locale=${language}`,
    MCO: `https://brandprotection.mercadolibre.com.co/my-account?force_locale=${language}`,
    MCR: `https://brandprotection.mercadolibre.co.cr/my-account?force_locale=${language}`,
    MDO: `https://brandprotection.mercadolibre.com.do/my-account?force_locale=${language}`,
    MEC: `https://brandprotection.mercadolibre.com.ec/my-account?force_locale=${language}`,
    MGT: `https://brandprotection.mercadolibre.com.gt/my-account?force_locale=${language}`,
    MHN: `https://brandprotection.mercadolibre.com.hn/my-account?force_locale=${language}`,
    MLM: `https://brandprotection.mercadolibre.com.mx/my-account?force_locale=${language}`,
    MNI: `https://brandprotection.mercadolibre.com.ni/my-account?force_locale=${language}`,
    MPA: `https://brandprotection.mercadolibre.com.pa/my-account?force_locale=${language}`,
    MPY: `https://brandprotection.mercadolibre.com.py/my-account?force_locale=${language}`,
    MPE: `https://brandprotection.mercadolibre.com.pe/my-account?force_locale=${language}`,
    MSV: `https://brandprotection.mercadolibre.com.sv/my-account?force_locale=${language}`,
    MLU: `https://brandprotection.mercadolibre.com.uy/my-account?force_locale=${language}`,
    MVE: `https://brandprotection.mercadolibre.com.ve/my-account?force_locale=${language}`,
  };
  return (
    siteUrlMap[siteId] || "https://www.brandprotection.mercadolibre.com.ar"
  );
}
export function getHelpSiteUrl(siteId, lang) {
  const DEFAULT_LANG = "ES";
  const LANG = {
    es: "ES",
    en: "en-US",
    pt: "pt-BR",
  };

  const language = LANG[lang] || DEFAULT_LANG;

  const siteUrlMap = {
    MLA: `https://brandprotection.mercadolibre.com.ar/help/1?force_locale=${language}`,
    MBO: `https://brandprotection.mercadolibre.com.bo/help/1?force_locale=${language}`,
    MLB: `https://brandprotection.mercadolivre.com.br/help/1?force_locale=${language}`,
    MLC: `https://brandprotection.mercadolibre.cl/help/1?force_locale=${language}`,
    MCO: `https://brandprotection.mercadolibre.com.co/help/1?force_locale=${language}`,
    MCR: `https://brandprotection.mercadolibre.co.cr/help/1?force_locale=${language}`,
    MDO: `https://brandprotection.mercadolibre.com.do/help/1?force_locale=${language}`,
    MEC: `https://brandprotection.mercadolibre.com.ec/help/1?force_locale=${language}`,
    MGT: `https://brandprotection.mercadolibre.com.gt/help/1?force_locale=${language}`,
    MHN: `https://brandprotection.mercadolibre.com.hn/help/1?force_locale=${language}`,
    MLM: `https://brandprotection.mercadolibre.com.mx/help/1?force_locale=${language}`,
    MNI: `https://brandprotection.mercadolibre.com.ni/help/1?force_locale=${language}`,
    MPA: `https://brandprotection.mercadolibre.com.pa/help/1?force_locale=${language}`,
    MPY: `https://brandprotection.mercadolibre.com.py/help/1?force_locale=${language}`,
    MPE: `https://brandprotection.mercadolibre.com.pe/help/1?force_locale=${language}`,
    MSV: `https://brandprotection.mercadolibre.com.sv/help/1?force_locale=${language}`,
    MLU: `https://brandprotection.mercadolibre.com.uy/help/1?force_locale=${language}`,
    MVE: `https://brandprotection.mercadolibre.com.ve/help/1?force_locale=${language}`,
  };
  return (
    siteUrlMap[siteId] || "https://brandprotection.mercadolibre.com.ar/help/1"
  );
}

export function getHelp2SiteUrl(lang) {
  const DEFAULT_LANG = "ES";
  const LANG = {
    es: "ES",
    en: "en-US",
    pt: "pt-BR",
  };

  const language = LANG[lang] || DEFAULT_LANG;

  const siteLangMap = {
    es: `https://brandprotection.mercadolibre.com.ar/help/2?force_locale=${language}`,
    en: `https://brandprotection.mercadolibre.com.ar/help/2?force_locale=${language}`,
    pt: `https://brandprotection.mercadolibre.com.ar/help/2?force_locale=${language}`,
  };
  return (
    siteLangMap[lang] || "https://brandprotection.mercadolibre.com.ar/help/2"
  );
}
export function getEfficiencySiteUrl(siteId, lang) {
  const DEFAULT_LANG = "ES";
  const LANG = {
    es: "ES",
    en: "en-US",
    pt: "pt-BR",
  };

  const language = LANG[lang] || DEFAULT_LANG;

  const siteUrlMap = {
    MLA: `https://brandprotection.mercadolibre.com.ar/my-account/efficiency?force_locale=${language}`,
    MBO: `https://brandprotection.mercadolibre.com.bo/my-account/efficiency?force_locale=${language}`,
    MLB: `https://brandprotection.mercadolivre.com.br/my-account/efficiency?force_locale=${language}`,
    MLC: `https://brandprotection.mercadolibre.cl/my-account/efficiency?force_locale=${language}`,
    MCO: `https://brandprotection.mercadolibre.com.co/my-account/efficiency?force_locale=${language}`,
    MCR: `https://brandprotection.mercadolibre.co.cr/my-account/efficiency?force_locale=${language}`,
    MDO: `https://brandprotection.mercadolibre.com.do/my-account/efficiency?force_locale=${language}`,
    MEC: `https://brandprotection.mercadolibre.com.ec/my-account/efficiency?force_locale=${language}`,
    MGT: `https://brandprotection.mercadolibre.com.gt/my-account/efficiency?force_locale=${language}`,
    MHN: `https://brandprotection.mercadolibre.com.hn/my-account/efficiency?force_locale=${language}`,
    MLM: `https://brandprotection.mercadolibre.com.mx/my-account/efficiency?force_locale=${language}`,
    MNI: `https://brandprotection.mercadolibre.com.ni/my-account/efficiency?force_locale=${language}`,
    MPA: `https://brandprotection.mercadolibre.com.pa/my-account/efficiency?force_locale=${language}`,
    MPY: `https://brandprotection.mercadolibre.com.py/my-account/efficiency?force_locale=${language}`,
    MPE: `https://brandprotection.mercadolibre.com.pe/my-account/efficiency?force_locale=${language}`,
    MSV: `https://brandprotection.mercadolibre.com.sv/my-account/efficiency?force_locale=${language}`,
    MLU: `https://brandprotection.mercadolibre.com.uy/my-account/efficiency?force_locale=${language}`,
    MVE: `https://brandprotection.mercadolibre.com.ve/my-account/efficiency?force_locale=${language}`,
  };
  return (
    siteUrlMap[siteId] ||
    "https://brandprotection.mercadolibre.com.ar/my-account/efficiency"
  );
}
export function isDenounceWithImages(noticeId) {
  const denouncesWithImages = ["PPPI6", "PPPI7", "PPPI19"];
  return denouncesWithImages.indexOf(noticeId) > -1;
}

/**
 * @function isLocalhost
 * @returns {boolean}
 */
export const isLocalhost = () =>
  Boolean(
    window.location.hostname === "localhost" ||
      // [::1] is the IPv6 localhost address.
      window.location.hostname === "[::1]" ||
      // 127.0.0.1/8 is considered localhost for IPv4.
      window.location.hostname.match(
        /^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/
      )
  );

/**
 * @function getUrlWithAnchor - return current url with its anchor
 * @param {string} location
 * @returns {string}
 */
export const getUrlWithAnchor = ({ pathname }) => {
  let livesearch_detail = "";
  if (pathname.includes("live_searches")) {
    livesearch_detail = pathname;
  }

  let pending_case_detail = "";
  if (pathname.includes("client_pending_cases")) {
    pending_case_detail = pathname;
  }

  switch (pathname) {
    case "/":
    case "/live_searches":
      return "/help_support#menu1";
    case livesearch_detail:
      return "/help_support#menu2";
    case "/reported_articles":
      return "/help_support#menu7";
    case "/client_accounts/client_white_sellers":
      return "/help_support#menu8";
    case "/client_accounts/client_black_sellers":
      return "/help_support#menu9";
    case "/reports/noticed_articles":
      return "/help_support#menu10";
    case "/case_management/client_pending_cases":
    case pending_case_detail:
      return "/help_support#menu12";
    case "/case_management/client_closed_cases":
      return "/help_support#menu13";
    default:
      return pathname;
  }
};

/*
 * @function parseSwipeImgs
 * @param {Array.<Object>} images
 * @returns {Array.<Object>}
 */
export const parseSwipeImgs = (images) => {
  return images.map((image) => {
    const size = image.max_size || image.size;
    return {
      src: image.url,
      thumbnail: image.url,
      title: image.title || "",
      w: (typeof size === "string" && size.split("x")[0]) || 300,
      h: (typeof size === "string" && size.split("x")[1]) || 300,
    };
  });
};

/**
 * @function getDenounceStatusLabel
 * @param {string} status
 * @returns {string}
 */
export const getDenounceStatusLabel = (status) => {
  switch (status) {
    case "WAITING_DOCUMENTATION":
      return t("case_management.denounce_status.waiting");

    case "DOCUMENTATION_PRESENTED":
      return t("case_management.denounce_status.pending");

    case "DOCUMENTATION_APPROVED":
      return t("case_management.denounce_status.accepted");

    case "DOCUMENTATION_NOT_APPROVED":
      return t("case_management.denounce_status.not_approved");

    case "DOCUMENTATION_NOT_PRESENTED":
      return t("case_management.denounce_status.not_presented");

    case "MEMBER_NOT_RESPOND":
      return t("case_management.denounce_status.not_respond");

    case "ROLLBACK":
      return t("case_management.denounce_status.rollback");

    case "DISCARDED":
      return t("case_management.denounce_status.discarded");

    case "CREATED":
    case "PENDING_MODERATION":
      return t("case_management.denounce_status.pending_moderation");

    case "REJECTED":
    case "CROSS_DENOUNCE_CLOSED":
    case "REJECTED_PDP_CATALOG":
    case "REJECTED_PDP_ITEM":
    case "REJECTED_PDP_IMAGE_RANKER":
    case "REJECTED_MODIFIED":
      return t("case_management.denounce_status.rejected");

    case "ACUERDO_CLOSED":
      return t("case_management.denounce_status.acuerdo_closed");

    case "DISCARDED_DUE_RESTRICTION":
      return t("case_management.denounce_status.discard_due_restriction");

    case "DUPLICATED_DENOUNCE":
      return t("case_management.denounce_status.duplicated_denounce");

    case "REJECTED_MODIFIED":
      return t("case_management.denounce_status.rejected_modified");

    case "WAITING_FOR_PATCH_PDP":
      return t("case_management.denounce_status.revision");

    default:
      return status || t("no_data");
  }
};

export const getDenounceStatusFilterPillV3 = (status) => {
  switch (status) {
    case "APPROVED":
      return t("case_management.denounce_status_V3.approved");

    case "IN_PROGRESS":
      return t("case_management.denounce_status_V3.in_progress");

    case "PENDING":
      return t("case_management.denounce_status_V3.pending");

    case "REJECTED":
      return t("case_management.denounce_status_V3.rejected");

    case "ROLLBACK":
      return t("case_management.denounce_status_V3.rollback");

    case "DISCARDED":
      return t("case_management.denounce_status_V3.discarded");

    default:
      return status || t("no_data");
  }
};
/**
 * @function getDenounceStatusLabelV3
 * @param {string} status
 * @returns {string}
 */
export const getDenounceStatusLabelV3 = (status) => {
  switch (status) {
    case "ACUERDO_CLOSED":
    case "DOCUMENTATION_NOT_APPROVED":
    case "DOCUMENTATION_NOT_PRESENTED":
      return t("case_management.denounce_status_V3.approved");

    case "CREATED":
    case "PENDING_MODERATION":
    case "WAITING_DOCUMENTATION":
    case "WAITING_FOR_PATCH_PDP":
      return t("case_management.denounce_status_V3.in_progress");

    case "DOCUMENTATION_PRESENTED":
      return t("case_management.denounce_status_V3.pending");

    case "DUPLICATED_DENOUNCE":
    case "MEMBER_NOT_RESPOND":
    case "REJECTED":
    case "REJECTED_BY_RESTRICTION":
      return t("case_management.denounce_status_V3.rejected");

    case "DOCUMENTATION_APPROVED":
    case "ROLLBACK":
      return t("case_management.denounce_status_V3.rollback");

    case "DISCARDED":
    case "DISCARDED_DUE_RESTRICTION":
    case "REJECTED_MODIFIED":
    case "REJECTED_PDP_CATALOG":
    case "REJECTED_PDP_IMAGE_RANKER":
    case "REJECTED_PDP_ITEM":
      return t("case_management.denounce_status_V3.discarded");

    default:
      return status || t("no_data");
  }
};

/**
 * @function getFilterGroup
 * @param {string} filter
 * @returns {string}
 */
export const getFilterGroup = (filter) => {
  const group = {
    APPROVED: [
      "ACUERDO_CLOSED",
      "DOCUMENTATION_NOT_APPROVED",
      "DOCUMENTATION_NOT_PRESENTED",
    ],
    IN_PROGRESS: [
      "CREATED",
      "PENDING_MODERATION",
      "WAITING_DOCUMENTATION",
      "WAITING_FOR_PATCH_PDP",
    ],
    PENDING: ["DOCUMENTATION_PRESENTED"],
    REJECTED: [
      "DUPLICATED_DENOUNCE",
      "MEMBER_NOT_RESPOND",
      "REJECTED",
      "REJECTED_BY_RESTRICTION",
    ],
    ROLLBACK: ["DOCUMENTATION_APPROVED", "ROLLBACK"],
    DISCARDED: [
      "DISCARDED",
      "DISCARDED_DUE_RESTRICTION",
      "REJECTED_MODIFIED",
      "REJECTED_PDP_CATALOG",
      "REJECTED_PDP_IMAGE_RANKER",
      "REJECTED_PDP_ITEM",
    ],
  };
  return group[filter] || "";
};

export const getStatusGroup = (status) => {
  switch (status) {
    case "DOCUMENTATION_PRESENTED":
      return "PENDING";
    case "ACUERDO_CLOSED":
    case "DOCUMENTATION_NOT_APPROVED":
    case "DOCUMENTATION_NOT_PRESENTED":
      return "APPROVED";
    case "CREATED":
    case "PENDING_MODERATION":
    case "WAITING_DOCUMENTATION":
    case "WAITING_FOR_PATCH_PDP":
      return "IN_PROGRESS";
    case "DUPLICATED_DENOUNCE":
    case "MEMBER_NOT_RESPOND":
    case "REJECTED":
    case "REJECTED_BY_RESTRICTION":
      return "REJECTED";
    case "DOCUMENTATION_APPROVED":
    case "ROLLBACK":
      return "ROLLBACK";

    case "DISCARDED":
    case "DISCARDED_DUE_RESTRICTION":
    case "REJECTED_MODIFIED":
    case "REJECTED_PDP_CATALOG":
    case "REJECTED_PDP_IMAGE_RANKER":
    case "REJECTED_PDP_ITEM":
      return "DISCARDED";
  }

  return status || "";
};

export const denounceStatusOptions = [
  {
    label: "case_management.denounce_status.waiting",
    value: "WAITING_DOCUMENTATION",
  },
  {
    label: "case_management.denounce_status.pending",
    value: "DOCUMENTATION_PRESENTED",
  },
  {
    label: "case_management.denounce_status.accepted",
    value: "DOCUMENTATION_APPROVED",
  },
  {
    label: "case_management.denounce_status.not_approved",
    value: "DOCUMENTATION_NOT_APPROVED,ACUERDO_CLOSED",
  },
  {
    label: "case_management.denounce_status.not_presented",
    value: "DOCUMENTATION_NOT_PRESENTED",
  },
  {
    label: "case_management.denounce_status.not_respond",
    value: "MEMBER_NOT_RESPOND",
  },
  {
    label: "case_management.denounce_status.rollback",
    value: "ROLLBACK",
  },
  {
    label: "case_management.denounce_status.discarded",
    value: "DISCARDED",
  },
  {
    label: "case_management.denounce_status.pending_moderation",
    value: "CREATED,PENDING_MODERATION",
  },
  {
    label: "case_management.denounce_status.rejected",
    value:
      "REJECTED,CROSS_DENOUNCE_CLOSED,REJECTED_PDP_CATALOG,REJECTED_PDP_ITEM,REJECTED_PDP_IMAGE_RANKER,REJECTED_MODIFIED",
  },
  {
    label: "case_management.denounce_status.discard_due_restriction",
    value: "DISCARDED_DUE_RESTRICTION",
  },
  {
    label: "case_management.denounce_status.revision",
    value: "WAITING_FOR_PATCH_PDP",
  },
];

/**
 * @function getDenounceStatusTitle
 * @param {string} status
 * @returns {string}
 */
export const getDenounceStatusTitle = (status) => {
  switch (status) {
    case "WAITING_DOCUMENTATION":
      return t("case_management.denounce_status.waiting");

    case "DOCUMENTATION_PRESENTED":
      return t("case_management.denounce_status.pending");

    case "DOCUMENTATION_APPROVED":
      return t("case_management.denounce_status.accepted");

    case "DOCUMENTATION_NOT_APPROVED":
      return t("case_management.denounce_status.not_approved");

    case "DOCUMENTATION_NOT_PRESENTED":
      return t("case_management.denounce_status.not_presented");

    case "MEMBER_NOT_RESPOND":
      return t("case_management.denounce_status.not_respond");

    case "ROLLBACK":
      return t("case_management.denounce_status.rollback");

    case "DISCARDED":
      return t("case_management.denounce_status.discarded");

    case "CREATED":
    case "PENDING_MODERATION":
      return t("case_management.denounce_status.pending_moderation");

    case "REJECTED":
    case "CROSS_DENOUNCE_CLOSED":
    case "REJECTED_PDP_CATALOG":
    case "REJECTED_PDP_ITEM":
    case "REJECTED_PDP_IMAGE_RANKER":
    case "REJECTED_MODIFIED":
      return t("case_management.denounce_status.rejected");

    case "ACUERDO_CLOSED":
      return t("case_management.denounce_status.acuerdo_closed");

    case "DISCARDED_DUE_RESTRICTION":
      return t("case_management.denounce_status.discard_due_restriction");

    case "DUPLICATED_DENOUNCE":
      return t("case_management.denounce_status.duplicated_denounce");

    case "REJECTED_MODIFIED":
      return t("case_management.denounce_status.rejected_modified");

    case "WAITING_FOR_PATCH_PDP":
      return t("case_management.denounce_status.revision");

    default:
      return status || t("no_data");
  }
};

/**
 * @function getArticleStatusForCM
 * @param {string} status
 * @returns {string}
 */
export const getArticleStatusForCM = (status) => {
  switch (status) {
    case "active":
      return t("case_management.publication_status.active");

    case "closed":
      return t("case_management.publication_status.closed");

    case "inactive":
      return t("case_management.publication_status.inactive");

    case "paused":
      return t("case_management.publication_status.paused");

    case "under_review":
      return t("case_management.publication_status.under_review");

    default:
      return status || t("no_data");
  }
};

/**
 * @function isImageUrl
 * @param {string} url
 * @returns {string}
 */
export const isImageUrl = (url) => {
  if (!url) {
    return false;
  }

  const imagesExtensions = ["jpeg", "jpg", "gif", "png", "bmp"];
  const lastPath = url.split("/")[url.split("/").length - 1];
  const file = lastPath.slice(0, lastPath.indexOf("?"));
  const extension = file.slice(file.indexOf(".") + 1, file.length);

  return imagesExtensions.indexOf(extension) > -1 ? true : false;
};

export const removeTextAfterUnderscore=(filename)=> {
  // Encuentra la posición del primer guion bajo y el último punto
  var underscoreIndex = filename.indexOf('_');
  var lastDotIndex = filename.lastIndexOf('.');

  // Si se encuentra el guion bajo y el último punto
  if (underscoreIndex !== -1 && lastDotIndex !== -1) {
      // Corta la cadena hasta la posición del guion bajo y añade la extensión
      filename = filename.substring(0, underscoreIndex) + filename.substring(lastDotIndex);
  }

  return filename;
}



/**
 * @function calculatePercentage
 * @param {number} total
 * @param {number} processed
 * @returns {number}
 */
export const calculatePercentage = (total = 0, processed = 0) => {
  return processed
    ? Math.floor((processed * 100) / total).toFixed() <= 100
      ? Math.floor((processed * 100) / total).toFixed()
      : 100
    : 0;
};

/**
 * @function objEqual - Compare if two objects are equal by comparing they 'stringifyed' version
 * @param {Object} obj1
 * @param {Object} obj2
 * @returns {boolean}
 */
export const objEqual = (obj1, obj2) => {
  try {
    return Boolean(JSON.stringify(obj1) === JSON.stringify(obj2));
  } catch (error) {
    /* eslint-disable no-console */
    console.log(error);
    console.log(error.stack);
    return false;
  }
};

/**
 * @function liveSearchDisplayName
 * @param {Object} liveSearch
 * @returns {string}
 */
export const liveSearchDisplayName = (liveSearch) =>
  (!liveSearch.sellers && liveSearch.keyword) ||
  (liveSearch.sellers &&
    liveSearch.sellers.length === 1 &&
    `${liveSearch.sellers[0].nickname}` +
      `${
        liveSearch.keyword && liveSearch.keyword.trim()
          ? ` - ${liveSearch.keyword}`
          : ""
      }`) ||
  t("sellers_black");

/**
 * @function qsToObj - Querystring to Object
 * @param {string} search
 * @returns {Object}
 */
export const qsToObj = (search) => {
  try {
    return JSON.parse(
      '{"' +
        decodeURI(search.substring(1))
          .replace(/"/g, '\\"')
          .replace(/&/g, '","')
          .replace(/=/g, '":"') +
        '"}'
    );
  } catch (e) {
    return {};
  }
};

/**
 * @function generateRedirectFlowPopupCtn
 * @returns {HTMLDivElement}
 */
export const generateRedirectFlowPopupCtn = () => {
  const div = document.createElement("div");
  div.setAttribute("class", "content");

  const subtitle = document.createElement("p");
  subtitle.appendChild(
    document.createTextNode(t("redirect_flow_info.subtitle"))
  );

  const bullet1 = document.createElement("p");
  bullet1.setAttribute("class", "bullet");
  const bullet1icon = document.createElement("img");
  bullet1icon.setAttribute("src", "/images/check.svg");
  bullet1.appendChild(bullet1icon);
  bullet1.appendChild(document.createTextNode(t("redirect_flow_info.bullet1")));

  const bullet2 = document.createElement("p");
  bullet2.setAttribute("class", "bullet");
  const bullet2icon = document.createElement("img");
  bullet2icon.setAttribute("src", "/images/check.svg");
  bullet2.appendChild(bullet2icon);
  bullet2.appendChild(document.createTextNode(t("redirect_flow_info.bullet2")));

  const bullet3 = document.createElement("p");
  bullet3.setAttribute("class", "bullet");
  const bullet3icon = document.createElement("img");
  bullet3icon.setAttribute("src", "/images/check.svg");
  bullet3.appendChild(bullet3icon);
  bullet3.appendChild(document.createTextNode(t("redirect_flow_info.bullet3")));

  div.appendChild(subtitle);
  div.appendChild(bullet1);
  div.appendChild(bullet2);
  div.appendChild(bullet3);

  return div;
};

/**
 * @function generateContactSuccessPopUpCtn
 * @returns {HTMLDivElement}
 */
export const generateContactSuccessPopUpCtn = (value) => {
  const div = document.createElement("div");
  div.setAttribute("class", "content contact");

  const subtitle = document.createElement("p");
  subtitle.appendChild(
    document.createTextNode(t("support_form.success_modal_will_respond"))
  );

  const caseCx = document.createElement("p");
  caseCx.setAttribute("class", "caseCx");
  caseCx.appendChild(
    document.createTextNode(t("support_form.success_modal_cx_number"))
  );
  const caseCxNumber = document.createElement("strong");
  caseCxNumber.appendChild(document.createTextNode(value));
  caseCx.appendChild(caseCxNumber);

  div.appendChild(subtitle);
  div.appendChild(caseCx);

  return div;
};

/**
 * @function getItemIdFromURL
 * @param {string} url
 * @returns {string} articleId
 */
export const getItemIdFromURL = (url, isCatalogo = false) => {
  try {
    if (!isCatalogo) {
      url = url.split("/")[3].split("-");
      return url[0] + url[1];
    }
    if (isCatalogo) {
      url = url.split("/")[5].split("-");
      if (url[0].indexOf("?") > 0) {
        url = url[0].split("?");
      }
      if (url[0] && url[0].indexOf("#") > 0) {
        url = url[0].split("#");
      }
      return url[0];
    }
  } catch (error) {
    console.log({ errorUrl: url });
    console.log(error);
    console.log(error.stack);
  }
};

/**
 * @function getCurrencyFromSiteId
 * @param {string} siteId
 * @returns {string}
 */
export const getCurrencyFromSiteId = (siteId) => {
  const currenciesMap = {
    MCU: "CUP",
    MEC: "USD",
    MPA: "USD",
    MBO: "BOB",
    MLU: "UYU",
    MNI: "NIO",
    MPY: "PYG",
    MLC: "CLP",
    MLV: "VES",
    MPE: "PEN",
    MPT: "EUR",
    MSV: "USD",
    MLM: "MXN",
    MCR: "CRC",
    MGT: "GTQ",
    MHN: "HNL",
    MLA: "ARS",
    MLB: "BRL",
    MRD: "DOP",
    MCO: "COP",
  };
  return currenciesMap[siteId];
};

/**
 * @function getLangFromSiteId
 * @param {string} siteId
 * @returns {string}
 */
export const getLangFromSiteId = (siteId) => {
  const langsMap = {
    MCU: "es",
    MEC: "es",
    MPA: "es",
    MBO: "es",
    MLU: "es",
    MNI: "es",
    MPY: "es",
    MLC: "es",
    MLV: "es",
    MPE: "es",
    MPT: "es",
    MSV: "es",
    MLM: "es",
    MCR: "es",
    MGT: "es",
    MHN: "es",
    MLA: "es",
    MLB: "pt",
    MRD: "es",
    MCO: "es",
  };
  return langsMap[siteId];
};

/**
 * @async getSeller
 * @param {string} nickname
 * @param {string} siteId
 * @returns {Object} seller
 */
export const getSeller = (nickname, siteId) => {
  try {
    return fetch(
      `https://api.mercadolibre.com/sites/${siteId}/search?nickname=${nickname}`,
      {
        method: "GET",
        headers: new Headers({ Accept: "application/json" }),
      }
    )
      .then((response) => {
        if (response.status === 200) {
          return response.json();
        }

        console.log(response);
        throw new Error(`Bad status code ${response.status}`);
      })
      .then((response) => {
        if (!response.seller) {
          throw "User not found.";
        }

        return response.seller;
      })
      .catch((error) => {
        throw error;
      });
  } catch (error) {
    console.log("Error fetching seller: ", error);
    throw error;
  }
};

export const getSellerId = async (sellerId) => {
  try {
    const item = await fetch(`https://api.mercadolibre.com/users/${sellerId}`, {
      method: "GET",
      headers: new Headers({ Accept: "application/json" }),
    })
      .then((response) => {
        if (response.status === 200) {
          return response.json();
        }

        console.log(response);
        throw new Error(`Bad status code ${response.status}`);
      })
      .then((response) => {
        if (!response.nickname) {
          throw "User not found.";
        }

        return response;
      })
      .catch((error) => {
        throw error;
      });
    return item;
  } catch (error) {
    console.log("Error fetching seller: ", error);
    throw error;
  }
};

/**
 * @async getItemFromApi
 * @param {string} itemId
 * @param {string} attributes - attributes separated by a comma
 * @returns {Promise}
 */
export const getItemFromApi = async (
  itemId,
  attributes,
  searchCatalogo = false
) => {
  try {
    let path = "items";
    if (searchCatalogo) {
      path = "products";
    }
    const item = await fetch(
      `https://api.mercadolibre.com/${path}/${itemId}` +
        (attributes ? `?attributes=${attributes}` : ""),
      {
        method: "GET",
        headers: new Headers({ Accept: "application/json" }),
      }
    ).then((response) => {
      if (response.status === 200) {
        return response.json();
      }

      console.log(response);

      throw new Error(`Bad status code ${response.status}`);
    });

    return item;
  } catch (error) {
    console.log("Error fetching item: ", error);
    throw error;
  }
};

/**
 * @function isAvailableToDenounce
 * @param {Object} article
 * @returns {boolean}
 */
export const isAvailableToDenounce = (article) => {
  return (
    !article.noticed ||
    (article.noticed &&
      ["CREATED", "PENDING_MODERATION", "WAITING_DOCUMENTATION"].indexOf(
        article.noticed.noticeStatus
      ) === -1)
  );
};

export const readXLSXFile = (file) =>
  new Promise((resolve, reject) => {
    try {
      const reader = new FileReader();
      reader.onload = function ({ target }) {
        const { result } = target;
        const readedData = XLSX.read(result, { type: "binary" });
        const wsname = readedData.SheetNames[0];
        const ws = readedData.Sheets[wsname];
        /* Convert array to json */
        var range = XLSX.utils.decode_range(ws["!ref"]);
        range.s.c = 12; // 12 == XLSX.utils.decode_col("M")
        range.e.c = 12; // 12 == XLSX.utils.decode_col("M")
        var new_range = XLSX.utils.encode_range(range);
        const parsedData = XLSX.utils.sheet_to_json(ws, {
          header: 1,
          blankRows: false,
          defval: "",
          range: new_range,
        });
        resolve(parsedData ? parsedData.slice(11) : null);
      };
      reader.readAsBinaryString(file);
    } catch (error) {
      reject(error);
    }
  });

export function isValidSiteId(siteId) {
  // eslint-disable-next-line
  const sitesIds = [
    "MCU",
    "MEC",
    "MPA",
    "MBO",
    "MLU",
    "MNI",
    "MPY",
    "MLC",
    "MLV",
    "MPE",
    "MPT",
    "MSV",
    "MLM",
    "MCR",
    "MGT",
    "MHN",
    "MLA",
    "MLB",
    "MRD",
    "MCO",
  ];
  return sitesIds.indexOf(siteId) > -1;
}

export function getBppUrl(lang) {
  const bppUrlMap = {
    es: "https://www.mercadolibre.com.ar/noindex/pppi/landings/bpp",
    pt: "https://www.mercadolivre.com.br/noindex/pppi/landings/bpp",
    en: "https://global-selling.mercadolibre.com/noindex/pppi/landings/bpp",
  };
  return bppUrlMap[lang] || bppUrlMap["es"];
}

export function isMeliUrl(url) {
  // TO DO: improve
  return Boolean(
    url.indexOf("mercadolibre") > -1 || url.indexOf("mercadolivre") > -1
  );
}

export function getStateFromRejectMemberId(rejectMemberId) {
  const stateMap = {
    1: "document_not_correspond",
    2: "document_illegible",
    3: "not_authorized_to_use_content",
    4: "not_justify_stock",
    5: "buyed_and_counterfeit",
    6: "luxury_much_stock",
    7: "priced_below_average",
    8: "cant_offered_in_country",
    9: "not_officially_launched",
    10: "cant_offered_in_meli",
    11: "insufficient_verify_legitimate",
    12: "presented_document_but_not_authorized",
    13: "never_offered_product",
    14: "contains_insults_aggressive",
    15: "not_authorized_format",
    16: "not_authorized_image",
    17: "insufficient_documentation",
  };
  return stateMap[rejectMemberId];
}

export function getIdFromImageSrc(src) {
  if (!src) return null;

  const pathname = src.split("/")[3];
  const id = pathname.substr(0, pathname.lastIndexOf("-"));
  if (id.indexOf("D_") === 0) {
    return id.substr(2);
  } else {
    return id;
  }
}

export function validateEmail(mail) {
  const emaild = utf8.decode(mail);

  if (/^\w+([\.-.+.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,15})+$/.test(mail)) {
    return true;
  }
  return false;
}

export function getDenounceExpires(date, withoutPrep = false) {
  // let res = date ?
  //   moment(date).endOf('day').fromNow() :
  //   ' - ';
  let res = " - ";
  if (date) {
    const utc_date = moment.utc(date);
    if (utc_date.isValid()) {
      res = utc_date.fromNow();
      if (withoutPrep) {
        res = res.replace("en ", "").replace("in ", "").replace("em ", "");
      }
    }
  }
  return res;
}

export function getDateUTC(date, lang) {
  console.log("LANG", lang);
  if (date) {
    const fechaMoment = moment(date);
    let fechaFormateada = "";
    if (lang === "en") {
      fechaFormateada = fechaMoment.format("MM/DD/YYYY");
    } else {
      fechaFormateada = fechaMoment.format("DD/MM/YYYY");
    }
    return fechaFormateada;
  }
}

export function getCountryCodeFromSiteId(siteId) {
  const siteUrlMap = {
    MLC: "cl",
    MCR: "cr",
    MSV: "sv",
    MPE: "pe",
    MBO: "bo",
    MPA: "pa",
    MEC: "ec",
    MLV: "ve",
    MCO: "co",
    MLU: "uy",
    MLB: "br",
    MRD: "do",
    MGT: "gt",
    MNI: "ni",
    MHN: "hn",
    MLA: "ar",
    MLM: "mx",
    MPY: "py",
  };
  return siteUrlMap[siteId] || "ar";
}

export function getNoticesDayLimit() {
  return 30000;
}

export function stringToPlainText(str) {
  return str.replace(/\.\s+/g, ".");
}

export function getMenuLinks(url, lang) {
  const DEFAULT_LANG = "ES";
  const LANG = {
    es: "ES",
    en: "en-US",
    pt: "pt-BR",
  };

  const language = LANG[lang] || DEFAULT_LANG;

  const urlRedirect = `https://pppi.mercadolibre.com/redirect?url=${url}&force_locale=${language}`;
  return urlRedirect || "https://pppi.mercadolibre.com";
}
