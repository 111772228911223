export default `
  fragment caseListFieldsV2 on CounterNoticeTypeV2 {
    caseId
    auditor
    status
    nickname
    articleId
    auditorId
    countryCode
    countryName
    articleTitle
    denounceDate
    lastUpdated
    counterNoticeDate
    limitResponseDate
    denounceReasonText
  }
`;