import {
  FETCH_ITEM_REVIEW,
  FETCH_ITEM_REVIEW_FULFILLED,
  FETCH_ITEM_REVIEW_ERROR,
  FETCH_ITEM_REVIEW_TOTALS,
  FETCH_ITEM_REVIEW_TOTALS_FULFILLED,
  FETCH_ITEM_REVIEW_TOTALS_ERROR
} from "redux/actions/reviews";
/* Bussines logic */
import { 
  fetchItemReviewFulfilled,
  fetchItemReviewError, 
  fetchItemReviewTotalFulfilled,
  fetchItemReviewTotalError,
  fetchItemReview
} from "redux/reducers/utils/reviews";


const initialState = {
  itemId: null,
  isLoading: false,
  isLoadingTotals: false,
  itemReviews: [],
  //cuenta totales
  reviewsTotal: 0,
  reviewsPositiveTotal: 0,
  reviewsNegativeTotal: 0,
  hasMore: false,
  hasPositive: false,
  hasNegative: false,
};
  
export default (state = initialState, action) => {
  switch (action.type) {
  case FETCH_ITEM_REVIEW:
    return {
      ...state,
      isLoading: true,
      hasMore: false,
      hasPositive: false,
      hasNegative: false,
      itemId: action.payload.itemId,
      itemReviews: fetchItemReview(state, action.payload),
      rating: action.payload.rating  
    };

  case FETCH_ITEM_REVIEW_FULFILLED:
    return fetchItemReviewFulfilled(
      state,
      action.payload
    );

  case FETCH_ITEM_REVIEW_ERROR:
    return fetchItemReviewError(
      state,
      action.payload
    );

  case FETCH_ITEM_REVIEW_TOTALS:
    return {
      ...state,
      isLoadingTotals: true,
      reviewsTotal: 0,
      reviewsPositiveTotal: 0,
      reviewsNegativeTotal: 0,
      itemReviews: [],
      itemId: action.payload.itemId,        
    };

  case FETCH_ITEM_REVIEW_TOTALS_FULFILLED:
    return fetchItemReviewTotalFulfilled(
      state,
      action.payload
    );

  case FETCH_ITEM_REVIEW_TOTALS_ERROR:
    return fetchItemReviewTotalError(
      state,
      action.payload
    );    

  default:
    return state;
  }
};
  