import React from "react";

const Loader = props => {
  return (
    <div {...props}>
      <div className="loader" />
    </div>
  );
};

export default Loader;
