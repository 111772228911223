

export const TERMS = 'terms';

export const PRIVACY = 'privacy';

export const LEGAL_LINKS = {
    [TERMS]: {
        "AR": "https://www.mercadolibre.com.ar/ayuda/terminos-y-condiciones-de-uso_991",
        "BO": "https://www.mercadolibre.com.bo/ayuda/Terminos-y-condiciones-de-uso-del-Sitio_1516",
        "BR": "https://www.mercadolivre.com.br/ajuda/Termos-e-condicoes-gerais-de-uso_1409",
        "CL": "https://www.mercadolibre.cl/ayuda/terminos-y-condiciones-uso_1843",
        "CO": "https://www.mercadolibre.com.co/ayuda/terminos-condiciones-de-uso_1841",
        "CR": "https://www.mercadolibre.co.cr/ayuda/terminos-condiciones-costarica_2460",
        "EC": "https://www.mercadolibre.com.ec/ayuda/terminos-condiciones-uso-ec_2281",
        "SV": "https://www.mercadolibre.com.sv/ayuda/terminos_991",
        "GT": "https://www.mercadolibre.com.gt/ayuda/T-rminos-y-condiciones-de-uso-_1546",
        "HN": "https://www.mercadolibre.com.hn/ayuda/terminos_991",
        "MX": "https://www.mercadolibre.com.mx/ayuda/terminos-y-condiciones-uso-del-sitio_2090",
        "NI": "https://www.mercadolibre.com.ni/ayuda/terminos_991",
        "PA": "https://www.mercadolibre.com.pa/ayuda/Terminos-y-condiciones-de-uso-_2319",
        "PY": "https://www.mercadolibre.com.py/ayuda/condiciones-de-uso-del-sitio_2321",
        "PE": "https://www.mercadolibre.com.pe/ayuda/terminos-condiciones-uso-pe_2280",
        "DO": "https://www.mercadolibre.com.do/ayuda/terminos-condiciones-dominicana_2464",
        "UY": "https://www.mercadolibre.com.uy/ayuda/terminos-condiciones-uso-uyu_2851",
        "VE": "https://www.mercadolibre.com.ve/ayuda/terminos-de-uso-de-mercadolibre_1988"
    },
    [PRIVACY]: {
        "AR": "https://www.mercadolibre.com.ar/privacidad",
        "BO": "https://www.mercadolibre.com.bo/privacidad",
        "BR": "https://www.mercadolivre.com.br/privacidade",
        "CL": "https://www.mercadolibre.cl/privacidad",
        "CO": "https://www.mercadolibre.com.co/privacidad",
        "CR": "https://www.mercadolibre.co.cr/privacidad",
        "EC": "https://www.mercadolibre.com.ec/privacidad",
        "SV": "https://www.mercadolibre.com.sv/privacidad",
        "GT": "https://www.mercadolibre.com.gt/privacidad",
        "HN": "https://www.mercadolibre.com.hn/privacidad",
        "MX": "https://www.mercadolibre.com.mx/privacidad",
        "NI": "https://www.mercadolibre.com.ni/privacidad",
        "PA": "https://www.mercadolibre.com.pa/privacidad",
        "PY": "https://www.mercadolibre.com.py/privacidad",
        "PE": "https://www.mercadolibre.com.pe/privacidad",
        "DO": "https://www.mercadolibre.com.do/privacidad",
        "UY": "https://www.mercadolibre.com.uy/privacidad",
        "VE": "https://www.mercadolibre.com.ve/privacidad"
    }
};
