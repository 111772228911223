import { from } from 'rxjs';
import { ofType } from 'redux-observable';
import { mergeMap } from 'rxjs/operators';
import { gqlQuery } from "libs/awsLib";
import {
  queryArticle,
  queryArticleCatalogo,
  queryArticlesCatalogo,
  queryLiveSearch,
  mutationFilters,
  mutationArticleUndo,
  mutationReportArticles,
  mutationDiscardArticles,
  mutationApproveArticles,
  mutationEditReportArticles,
} from "gql/index";
import {
  isNullish,
  convertPrice,
  getCurrencyFromSiteId,
} from "utils";

import {
  fetchLiveSearch,

  FETCH_LIVE_SEARCH,
  liveSearchFulfilled,
  liveSearchError,

  APPLY_FILTER,
  applyFilterError,

  APPROVE_ARTICLE,
  approveArticleSuccess,
  approveArticleError,

  APPROVE_ARTICLE_UNDO,
  approveArticleUndoSuccess,
  approveArticleUndoError,

  REPORT_ARTICLES,
  reportArticlesSuccess,
  reportArticlesError,

  DISCARD_ARTICLES,
  discardArticlesSuccess,
  discardArticlesError,

  REPORT_ARTICLE_UNDO,
  reportArticleUndoSuccess,
  reportArticleUndoError,

  BATCH_APPLY,
  batchApplyError,

  EDIT_REPORTED_ARTICLES,
  editReportedArticlesSuccess,
  editReportedArticlesError,
} from "redux/actions/detail";

import {
  FETCH_ARTICLE_DETAIL,
  fetchArticleDetailFulfilled,
  fetchArticleDetailError,
  FETCH_CATALOGO_DETAIL,
  FETCH_CATALOGOS_DETAIL,
  fetchArticleCatalogoDetailFulfilled,
  fetchArticlesCatalogo,
} from "redux/actions/global";


/* EPICS */
export const fetchLiveSearchEpic = (action$, store) => action$.pipe(
  ofType(FETCH_LIVE_SEARCH),
  mergeMap(({ payload }) => {
    const state = store.value.detail;
    const liveSearch = { ...(state.liveSearch || {}) };
    payload = payload ? payload : {};

    const pagingFrom = isNullish(payload.from) ? liveSearch.articles_from || 0 : payload.from;
    const promises = [pagingFrom, (pagingFrom + 50)]
      .map(from =>
        gqlQuery(queryLiveSearch, {
          from,
          size: payload.size || 50,
          id: payload.id || liveSearch.id,
          sortBy: payload.sortBy || liveSearch.articles_sort_by,
          sortOrder: payload.sortOrder || liveSearch.articles_sort_order,
        })
      );

    return from(
      Promise.all(promises)
        .then(liveSearchFulfilled)
        .catch(liveSearchError)
    );
  })
);

export const applyFilterEpic = (action$, store) => action$.pipe(
  ofType(APPLY_FILTER),
  mergeMap(() => {
    const { global, detail } = store.value;
    const { profile } = global;
    const {
      id,
      keyword,
      filterProfiles,
      filterProfileId,
      articles_sort_by,
      articles_sort_order,
    } = detail.liveSearch;

    return from(
      gqlQuery(mutationFilters, {
        id,
        keyword,
        filterProfiles: filterProfiles
          .map(filterProfile => ({
            ...filterProfile,
            priceMinConverted: filterProfile.priceMin ?
              convertPrice(
                filterProfile.priceMin,
                profile.currency,
                getCurrencyFromSiteId(
                  filterProfile.sites[0].countrySites[0]
                )
              ) : null,
            priceMaxConverted: filterProfile.priceMax ?
              convertPrice(
                filterProfile.priceMax,
                profile.currency,
                getCurrencyFromSiteId(
                  filterProfile.sites[0].countrySites[0]
                )
              ) : null,
            profileCurrency: profile.currency,
          })),
        filterProfileId: filterProfileId,
      }).then(() =>
        fetchLiveSearch({
          id,
          from: 0,
          size: 50,
          sortBy: articles_sort_by,
          sortOrder: articles_sort_order,
        })
      ).catch(applyFilterError)
    );
  })
);

export const approveArticleEpic = action$ => action$.pipe(
  ofType(APPROVE_ARTICLE),
  mergeMap(({ payload }) =>
    from(
      gqlQuery(mutationApproveArticles, payload)
        .then(response => response.approveArticles)
        .then(approveArticleSuccess)
        .catch(error =>
          approveArticleError({
            error,
            ...payload,
          })
        )
    )
  )
);

export const approveArticleUndoEpic = action$ => action$.pipe(
  ofType(APPROVE_ARTICLE_UNDO),
  mergeMap(({ payload }) =>
    from(
      gqlQuery(mutationArticleUndo, { ...payload, undo: 'approved' })
        .then(approveArticleUndoSuccess)
        .catch(approveArticleUndoError)
    )
  )
);

export const reportArticlesEpic = (action$, store) => action$.pipe(
  ofType(REPORT_ARTICLES),
  mergeMap(({ payload }) => {
    let chunkIds = [];
    let chunk_size = 25;
    const globalState = store.value.global;
    const { helps } = globalState.profile;
    const articlesIds = payload.articlesIds.slice(0);
    const isImport = payload.isImport;

    while (articlesIds.length) {
      chunkIds.push(articlesIds.splice(0, chunk_size));
    }
    
    delete payload.isImport;
    const promises = chunkIds.map(articlesIds =>
      gqlQuery(mutationReportArticles, { ...payload, articlesIds })
    );

    return from(
      Promise.all(promises)
        .then(() =>
          reportArticlesSuccess({
            articlesIds: payload.articlesIds,
            didReport: helps.didReport,
            history: payload.history,
            isImport,
          })
        )
        .catch(error =>
          reportArticlesError({
            error,
            articlesIds: payload.articlesIds,
            isImport,
          })
        )
    );
  })
);

export const editReportedArticlesEpic = action$ => action$.pipe(
  ofType(EDIT_REPORTED_ARTICLES),
  mergeMap(({ payload }) =>
    from(
      gqlQuery(mutationEditReportArticles, payload)
        .then(response => response.editReportedArticles)
        .then(editReportedArticlesSuccess)
        .catch(error =>
          editReportedArticlesError({
            error,
            articlesIds: payload.articlesIds
          })
        )
    )
  )
);

export const reportArticleUndoEpic = action$ => action$.pipe(
  ofType(REPORT_ARTICLE_UNDO),
  mergeMap(({ payload }) =>
    from(
      gqlQuery(mutationArticleUndo, { ...payload, undo: 'reported' })
        .then(reportArticleUndoSuccess)
        .catch(reportArticleUndoError)
    )
  )
);

export const batchApplyEpic = action$ => action$.pipe(
  ofType(BATCH_APPLY),
  mergeMap(({ payload }) => {
    const { articlesIds } = payload;

    if (!articlesIds.length) return [{ type: '' }];

    return from(
      gqlQuery(mutationApproveArticles, { articlesIds })
        .then(() => ({ type: '' })) // hack
        .catch(error => batchApplyError({ error, batchApproved: articlesIds }))
    );
  })
);

export const fetchCatalogosDetailEpic = action$ => action$.pipe(
  ofType(FETCH_CATALOGOS_DETAIL),
  mergeMap(({ payload }) =>
    from(
      gqlQuery(queryArticlesCatalogo, { articleId: payload.articleId })
        .then(result => {
          console.log(result)
          if(payload.skipStatus){

            return fetchArticlesCatalogo(result);

          }
          if (
            result.articlesCatalogo.status === 'closed' ||
            result.articlesCatalogo.status === 'inactive' ||
            result.articlesCatalogo.status === 'under_review'
          ) {
            throw new Error("Invalid article");
          } else {
            return fetchArticlesCatalogo(result);

          }
        })
        .catch(fetchArticleDetailError)
    )
  )
);

export const fetchCatalogoDetailEpic = action$ => action$.pipe(
  ofType(FETCH_CATALOGO_DETAIL),
  mergeMap(({ payload }) =>
    from(
      gqlQuery(queryArticleCatalogo, { articleId: payload.articleId })
        .then(result => {
          console.log(result)
          if(payload.skipStatus){
            return fetchArticleCatalogoDetailFulfilled(result);

          }
          if (
            result.articleCatalogo.status === 'closed' ||
            result.articleCatalogo.status === 'inactive' ||
            result.articleCatalogo.status === 'under_review'
          ) {
            throw new Error("Invalid article");
          } else {
            return fetchArticleCatalogoDetailFulfilled(result);

          }
        })
        .catch(fetchArticleDetailError)
    )
  )
);

export const fetchArticleDetailEpic = action$ => action$.pipe(
  ofType(FETCH_ARTICLE_DETAIL),
  mergeMap(({ payload }) =>
    from(
      gqlQuery(queryArticle, { articleId: payload.articleId })
        .then(result => {
          if(payload.skipStatus){
            return fetchArticleDetailFulfilled(result);
          }
          if (
            result.article.status === 'closed' ||
            result.article.status === 'inactive' ||
            result.article.status === 'under_review'
          ) {
            throw new Error("Invalid article");
          } else {
            return fetchArticleDetailFulfilled(result);
          }
        })
        .catch(fetchArticleDetailError)
    )
  )
);

export const discardArticlesEpic = action$ => action$.pipe(
  ofType(DISCARD_ARTICLES),
  mergeMap(({ payload }) =>
    from(
      gqlQuery(mutationDiscardArticles, { ...payload })
        .then(() =>
          discardArticlesSuccess({
            articlesIds: payload.articlesIds,
            history: payload.history,
          })
        )
        .catch(error =>
          discardArticlesError({
            error,
          })
        )
    )
  )
);




