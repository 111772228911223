import React, { Component } from "react";
import classnames from "classnames";
import { connect } from "react-redux";
import { Scrollbars } from "react-custom-scrollbars";
import { faCircle, faDotCircle } from "@fortawesome/free-regular-svg-icons";
import { closeModal } from "redux/actions/global";
import { fetchFilterProfileSellers } from "redux/actions/liveSearches";
import Icon from "components/Icon";
import Loader from "components/Loader";
import { t, get } from "utils";


class BlackSellerSelector extends Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedSeller: props.selectedSeller, 
    };
  }

  componentDidMount() {
    !get(this.props.sellersData, 'sellers', null) && 
      this.props.fetchFilterProfileSellers({
        size: 8,
        siteId: this.props.siteId,
      });
  }

  handleClose = e => {
    e.preventDefault();
    this.props.closeModal();
  }

  handleChange = e => {
    e.preventDefault();
    
    if (this.props.isLoading) {
      return;
    }

    this.setState({ selectedSeller: e.target.value });
  }

  handleLoadMore = () => {
    this.props.fetchFilterProfileSellers({
      size: 9,
      siteId: this.props.siteId,
      from: this.props.sellersData.from,
    });
  }

  handleApply = () => {
    this.props.onApplySeller &&
      this.props.onApplySeller(
        this.state.selectedSeller ?
          this.state.selectedSeller :
          null
      );

    this.props.closeModal();
  }

  render() {
    const { isLoading } = this.props;
    const { selectedSeller } = this.state;
    const sellers = get(this.props.sellersData, 'sellers', []);
    const hasMore = (
      !isLoading &&
      get(this.props.sellersData, 'total', 0) > sellers.length
    );

    return (
      <div
        tabIndex="1"
        className="modal-content category-selector">
        <div className="modal-category-selector_container">
          <header>
            <h1>
              {t('filter_profile.seller_blacklisted')}
              <i className="modal-close" onClick={this.handleClose} />
            </h1>
          </header>
          <section>
            <form onSubmit={e => e.preventDefault()}>
              <div className="form-group live-search-filters">
                <div className="radio-fields">
                  <Scrollbars
                    autoHeight
                    autoHeightMax={285}>
                    <label
                      key="none"
                      className={classnames(
                        "col-md-4",
                        "radio-group",
                        { "checked": !selectedSeller }
                      )}>
                      <span>
                        <Icon 
                          icon={
                            !selectedSeller ?
                              faDotCircle :
                              faCircle
                          } />
                        {t('filter_profile.none_seller')}
                      </span>
                      <input
                        value=""
                        type="radio"
                        name="category"
                        onChange={this.handleChange}
                        id="filter_profile_black_seller" />
                    </label>
                    {sellers.map(seller =>
                      <label
                        key={seller.id}
                        className={classnames(
                          "col-md-4",
                          "radio-group",
                          { "checked": seller.nickname === selectedSeller }
                        )}>
                        <span title={seller.nickname}>
                          <Icon 
                            icon={
                              seller.nickname === selectedSeller ?
                                faDotCircle :
                                faCircle
                            } />
                          {seller.nickname}
                        </span>
                        <input
                          type="radio"
                          name="seller"
                          value={seller.nickname}
                          onChange={this.handleChange}
                          id="filter_profile_black_seller" />
                      </label>)}
                    {isLoading && <Loader />}
                    {hasMore &&
                      <span className="view-more">
                        <button onClick={this.handleLoadMore}>
                          {t('view_more')}
                        </button>
                      </span>}
                  </Scrollbars>
                </div>
              </div>
              <div className="actions">
                <button
                  onClick={this.handleClose}
                  className="btn transparent">
                  {t('cancel')}
                </button>
                <button
                  className="btn"
                  onClick={this.handleApply}>
                  {t('apply')}
                </button>
              </div>
            </form>
          </section>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => ({
  isLoading: state.liveSearches.isLoadingFPSellers,
  sellersData: state.liveSearches.filterProfileSellers[ownProps.siteId],
});

const actions = {
  closeModal,
  fetchFilterProfileSellers,
};

export default connect(mapStateToProps, actions)(BlackSellerSelector);