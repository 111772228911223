import React from "react";
import { Route, Redirect } from "react-router-dom";
import { get, qsToObj } from "utils";


export default ({ component: C, props: cProps, ...rest }) =>
  <Route
    {...rest}
    render={props => {
      const searchObj = qsToObj(props.location.search);
      const lang = get(searchObj, 'lang', 'es');

      return cProps.isAuthenticated ?
        <C {...props} {...cProps} /> :
        <Redirect
          to={
            (
              (
                props.location.pathname &&
                props.location.pathname !== '/'
              ) ||
              props.location.search
            )
              ? (
                props.match.path === '/send_notice/:articleId' ||
                props.match.path === '/case_management/client_pending_cases/:caseId'
              )
                ? `/Melilogin/${lang}?redirect=${props.location.pathname}`
                : `/es?redirect=${props.location.pathname}${props.location.search}` 
              : '/es'
          } />;
    }} />;
