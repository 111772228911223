import React, { Component } from "react";
import classnames from "classnames";
import { connect } from "react-redux";
import { Carousel } from "react-bootstrap";
import {
  openModal,
  closeHelp,
  closeModal,
  updateModal,
} from "redux/actions/global";
import { t } from "utils";

class SuggestionsSlide extends Component {
  constructor(props) {
    super(props);

    this.state = { 
      index: 0,
      newUser: props.profile.helps.onBoarding || false 
    };
  }

  getImagePath(subPath) {
    return `/images/suggestions-slide/${subPath}`;
  }
  
  handleClick = () => {
    this.props.closeHelp('suggestions');
    this.props.closeModal();
    if (typeof this.props.onClose === "function") {
      this.props.onClose();
    }
    this.props.history.push('/suggested_listings');
  }

  handleSelect = (selectedIndex)  => {
    this.setState({ index: selectedIndex });
  }

  handleSelectBtn = ()  => {
    this.setState({ index: this.state.index + 1 });
  }

  render() {
    return (
      <>
        <div
          tabIndex="1"
          className={classnames(
            "modal-content",
            "suggestions-slide-wrapper",
          )}>
          {this.state.newUser && 
          <div className="suggestions-slide">
            <Carousel
              wrap={false}
              interval={null}
              controls={false}
              activeIndex={this.state.index}
              onSelect={this.handleSelect}
            >
              <Carousel.Item>
                <div className="imgWrapper">
                  <img
                    alt="First slide"
                    className="d-block w-100 firstSlide"
                    src={this.getImagePath("cup.png")} />
                </div>
                <Carousel.Caption>
                  <h3>{t('suggestions_slide.new_user.slide1.title')}</h3>
                  <p>{t('suggestions_slide.new_user.slide1.text')}</p>
                  <button
                    className="btn next"
                    onClick={this.handleSelectBtn}>
                    {t('suggestions_slide.next')}
                  </button>
                </Carousel.Caption>
              </Carousel.Item>
              <Carousel.Item>
                <div className="imgWrapper">
                  <img
                    alt="Second slide"
                    className="d-block w-100 secondSlide"
                    src={this.getImagePath('cup.png')} />
                </div>
                <Carousel.Caption>
                  <h3>{t('suggestions_slide.new_user.slide2.title')}</h3>
                  <p>{t('suggestions_slide.new_user.slide2.text')} </p>
                  <button
                    className="btn next"
                    onClick={this.handleSelectBtn}>
                    {t('suggestions_slide.next')}
                  </button>
                </Carousel.Caption>
              </Carousel.Item>
              <Carousel.Item>
                <div className="imgWrapper">
                  <img
                    alt="Third slide"
                    className="d-block w-100"
                    src={this.getImagePath('cup.png')} />
                </div>
                <Carousel.Caption>
                  <h3>{t('suggestions_slide.new_user.slide3.title')}</h3>
                  <p>{t('suggestions_slide.new_user.slide3.text')} </p>
                  <button
                    className="btn start"
                    onClick={this.handleClick}>
                    {t('suggestions_slide.start')}
                  </button>
                </Carousel.Caption>
              </Carousel.Item>
            </Carousel>
          </div>
          }
          {!this.state.newUser && 
            <div className="suggestions-slide">
              <div className="new-tag">
                {t('suggestions_slide.old_user.tag_text')}
              </div>
              <Carousel
                wrap={false}
                interval={null}
                controls={false}
                activeIndex={this.state.index}
                onSelect={this.handleSelect}
                indicators={false}
              >
                <Carousel.Item>
                  <div className="imgWrapper">
                    <img
                      alt="First slide"
                      className="d-block w-100 firstSlide"
                      src={this.getImagePath("cup.png")} />
                  </div>
                  <Carousel.Caption>
                    <h3>{t('suggestions_slide.old_user.slide1.title')}</h3>
                    <p>{t('suggestions_slide.old_user.slide1.text')}</p>
                    <button
                      className="btn start"
                      onClick={this.handleClick}>
                      {t('suggestions_slide.start')}
                    </button>
                  </Carousel.Caption>
                </Carousel.Item>
              </Carousel>
            </div>
          }
        </div>
      </>
    );
  }
}

const mapStateToProps = state => ({ profile: state.global.profile });

const actions = {
  openModal,
  closeHelp,
  closeModal,
  updateModal,
};

export default connect(mapStateToProps, actions)(SuggestionsSlide);
