import React from "react";
import { render } from "react-dom";
import { BrowserRouter as Router } from "react-router-dom";
import { Provider } from "react-redux";

import App from "containers/App/App";
import UserConfirmation from "components/UserConfirmation";

import configureAppStore from 'redux/configureAppStore';
import { unregister } from "registerServiceWorker";
import "fontawesome";
import "i18n";
import { ProSidebarProvider } from 'react-pro-sidebar';
import * as Sentry from "@sentry/react";


Sentry.init({ dsn: "https://a2e38095fe1b4b92ade749c2d8a49ad7@o519304.ingest.sentry.io/5629417" });

const store = configureAppStore();

/* eslint-disable-next-line */
require('dotenv').config();


render(
  <Router getUserConfirmation={UserConfirmation}>
    <Provider store={store}>
    <ProSidebarProvider>
      <App />
      </ProSidebarProvider>
    </Provider>
  </Router>,
  document.getElementById("root")
);
  
unregister();
  
export { store };