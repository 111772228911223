import React from 'react'
import './notificationCircle.scss'

export const NotificationCircle = ({count}) => {
    return (
        <div className="circle-ctn">
            <div className="circle-not">
                <p className="circle-label">{count}</p>
            </div>
        </div>
    )
}
