import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import Select from 'react-select';
import { connect } from "react-redux";
import { reportArticles, editReportedArticles } from "redux/actions/detail";
import Cookies from 'js-cookie';
import {
  closeModal,
  fetchArticleDetail,
  setNewReasonTooltipAlreadyShown
} from "redux/actions/global";
import { sendBrandInfringement } from "redux/actions/reportedArticles";
import {
  t,
  get,
  isNullish,
  isDenounceWithImages,
} from "utils";
import Loader from "components/Loader";
import clipboardCopy from 'clipboard-copy';


class ArticleDenounceCatalog extends Component {

  constructor(props) {
    super(props);

    const { article, hideImagesOptions, onlyCanDenounceImages } = props;
    const reported = article && article.reported;
    this.copyRef = React.createRef();
    const selectedNotice = get(reported, 'code', null);

    const disabledMultipleReasons = ['PPPI6', 'PPPI7', 'PPPI19'];

    const reasonCategories = {
      BRAND: [
        'PPPI1',
        'PPPI2',
        'PPPI10',
      ],
      COPYRIGHT: [
        'PPPI3',
        'PPPI5',
        'PPPI6',
        'PPPI11',
        'PPPI12',
        'PPPI13',
        'PPPI14',
        'PPPI15',
        'PPPI16',
        'PPPI17',
      ],
      PERSONAL_IMAGE: [
        'PPPI7',
      ],
      UTILITY_MODEL: [
        'PPPI8',
      ],
      PATENT: [
        'PPPI9',
      ],
      AGREEMENTS: [
        'ACUERDO',
      ],
      RELATED_RIGHTS: [
        'PPPI18',
        'PPPI19',
        'PPPI20',
        'PPPI21',
        'PPPI22',
        'PPPI23',
      ]
    };

    this.state = {
      step: 1,
      siteId: null,
      selectedNotice,
      selectedOption: null,
      productId: props.productId ? props.productId : null,
      isEdit: selectedNotice ? true : false,
      comment: get(reported, 'description', ''),
      photosDenounced: get(reported, 'photosDenounced', null),
      originModel: props.originModel || null,
      newReasonTooltipAlreadyShown: props.profile.helps.newReasonTooltipAlreadyShown || false,
      disabledMultipleReasons,
      reasonCategories,
      userReasonCategories: [],
      selectedStep1: null,
      onlyCanDenounceImages,
      hideImagesOptions
    };
  }


  componentDidMount() {
    let siteId = null;

    if (!this.props.article && !this.props.isMultiple) {
      this.props.fetchArticleDetail({
        articleId: this.props.articleId,
        error: this.props.error,
      });
    }

    if (this.props.isMultiple) {
      const siteIds = this.props.articlesIds.map(articleId => articleId.slice(0, 3));
      const uniqueSiteIds = [...new Set(siteIds)];

      if (uniqueSiteIds.length === 1) {
        siteId = uniqueSiteIds[0];
      } else {
        this.handleMultipleSiteError();
      }
    } else {
      siteId = this.props.articleId.slice(0, 3);
    }

    this.setState({ siteId });
    this.tagInput && this.tagInput.focus();
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.isFetching &&
      !this.props.isFetching
    ) {
      if (!this.props.article) {
        this.props.closeModal();
      } else if (!this.state.selectedNotice) {
        const { article } = this.props;
        const reported = article && article.reported;
        this.setState({
          isEdit: false,
          comment: get(reported, 'description', ''),
          selectedNotice: get(reported, 'code', null),
          photosDenounced: get(reported, 'photosDenounced', null),
        });
      }
    }
    console.log(prevProps.caseId, this.props.caseId)

    if (prevProps.caseId !== this.props.caseId && this.props.caseId !== null) {
      Cookies.set(`denounced_${this.props.productId}`, this.props.productId, { expires: 2 })
      this.setState({
        step: 3
      })
    }
  }

  handleSend = (e) => {
    e.preventDefault()
    this.props.sendBrandInfringement({
      productId: this.props.productId,
      reportReasonId: this.state.selectedNotice,
      reportReasonDetail: this.state.selectedOption.value,
      reportReasonCommet: this.state.comment
    });


  }

  handleChangeOptions = (selectedOption) => {
    this.setState({ selectedOption }, () =>
      console.log(`Option selected:`, this.state.selectedOption)
    );
  };
  componentWillUnmount() {
    if (typeof this.props.onClose === 'function') {
      this.props.onClose();
    }
  }
  copyClipboard = () => {
    const copyText = this.copyRef.current;

    if (copyText) {
      clipboardCopy(copyText.textContent)
    }
  };

  getTypeFromCat = cat => {
    let type = null;
    if (this.state.userReasonCategories) {
      for (let i in this.state.userReasonCategories) {
        if (this.state.userReasonCategories[i].id === cat) {
          type = this.state.userReasonCategories[i].value.toLowerCase();
          break;
        }
      }
    }
    return type;
  }

  getValue() {
    return { code: this.state.selectedNotice };
  }

  handleChange = e => {
    e.preventDefault();

    this.setState({
      selectedNotice: e.target.value,
      photosDenounced: null
    });
  }

  handleSelectNotice = (e, reasonId) => {
    e.preventDefault();

    let step = 2;
    if (isDenounceWithImages(reasonId)) {
      step = 'images';
    }

    this.setState({
      selectedNotice: reasonId,
      photosDenounced: null,
      step
    });
  }

  handleChangeComment = e => {
    e.preventDefault();

    this.setState({ comment: e.target.value, errorTextArea: false });
  }


  handleGoBack = () => {
    let step = 1;
    if (this.state.selectedNotice) {
      if (this.state.reasonCategories['BRAND'].indexOf(this.state.selectedNotice) > -1) {
        if (this.state.step !== 2) {
          step = 2;
        }
      }
    }
    this.setState({ step });
  }

  groupBy(array, key) {
    return array.reduce((prev, curr) => {
      let current = curr.type === 'Producto' ? { id: curr.id, type: 'Product' } : curr;
      const groupKey = current[key].toLowerCase();
      prev[groupKey] = [...(prev[groupKey] || []), current];
      return prev;
    }, {});
  }

  tooltip(id) {
    return this.tooltip[id];
  }
  groupTypeModule = (category, groupNoticesByType, type) => {

    const {
      disabledMultipleReasons,
      hideImagesOptions,
      reasonCategories,
      onlyCanDenounceImages } = this.state;
    let isLoading = true

    let reasons = [];
    if (groupNoticesByType[type]) {
      reasons = groupNoticesByType[type]
        .filter(reason => {
          if (reasonCategories[category].indexOf(reason.id) > -1) {
            if (onlyCanDenounceImages && !hideImagesOptions) {
              if (disabledMultipleReasons.indexOf(reason.id) > -1) {
                isLoading = false
                return reason;

              }
            } else if (!onlyCanDenounceImages && hideImagesOptions) {
              if (disabledMultipleReasons.indexOf(reason.id) === -1) {
                isLoading = false
                return reason;
              }
            } else {
              isLoading = false
              return reason;
            }
          }
        });
    }

    if (isLoading !== true) {
      return (
        <div>
          {reasons.length > 0 &&
            <div className="type-ctn">
              {reasons.map((notice, i) => {
                const description = t(`report_v2.catalog_code.${notice.id}`) ?
                  t(`report_v2.catalog_code.${notice.id}`) :
                  notice.description;
                const subText = t(`report_v2.catalog_code.${notice.id}_subtext`);

                return (
                  <div key={notice.id}>
                    {
                      i === 0 &&
                      <>
                        <h3 className="modal-title-type">
                          {t(`report_v2.title_${type}`)}
                        </h3>

                      </>
                    }
                    {
                      <div
                        key={notice.id}
                        className="reason-brand-ctn"
                        onClick={event => this.handleSelectNotice(event, notice.id)}
                      >
                        <span className="description">{description}</span>
                        {
                          subText && subText !== '' &&
                          <span className="subtext">{subText}</span>
                        }
                        <img src="/images/arrow-right-blue.png" />
                      </div>

                    }

                  </div>
                );
              })
              }
            </div>
          }
        </div>
      )
    } else {
      return (
        <Loader className="small" />
      )
    }
  }

  setUserReasonCategories = siteNotices => {
    let userReasonCategories = [];
    for (let i in siteNotices) {
      if (
        this.state.reasonCategories['BRAND'].indexOf(siteNotices[i].id) > -1
      ) {
        const found = userReasonCategories.some(item => item.id === 'cat1');
        if (!found) {
          userReasonCategories.push({
            id: 'cat1', value: 'BRAND'
          });
        }
      }
      if (
        this.state.reasonCategories['COPYRIGHT'].indexOf(siteNotices[i].id) > -1
      ) {
        const found = userReasonCategories.some(item => item.id === 'cat2');
        if (!found) {
          userReasonCategories.push({
            id: 'cat2', value: 'COPYRIGHT'
          });
        }
      }
      if (
        this.state.reasonCategories['PERSONAL_IMAGE'].indexOf(siteNotices[i].id) > -1
      ) {
        const found = userReasonCategories.some(item => item.id === 'cat3');
        if (!found) {
          userReasonCategories.push({
            id: 'cat3', value: 'PERSONAL_IMAGE'
          });
        }
      }
      if (
        this.state.reasonCategories['UTILITY_MODEL'].indexOf(siteNotices[i].id) > -1
      ) {
        const found = userReasonCategories.some(item => item.id === 'cat4');
        if (!found) {
          userReasonCategories.push({
            id: 'cat4', value: 'UTILITY_MODEL'
          });
        }
      }
      if (
        this.state.reasonCategories['PATENT'].indexOf(siteNotices[i].id) > -1
      ) {
        const found = userReasonCategories.some(item => item.id === 'cat5');
        if (!found) {
          userReasonCategories.push({
            id: 'cat5', value: 'PATENT'
          });
        }
      }
      if (
        this.state.reasonCategories['AGREEMENTS'].indexOf(siteNotices[i].id) > -1
      ) {
        const found = userReasonCategories.some(item => item.id === 'cat6');
        if (!found) {
          userReasonCategories.push({
            id: 'cat6', value: 'AGREEMENTS'
          });
        }
      }
      if (this.state.reasonCategories['RELATED_RIGHTS'].indexOf(siteNotices[i].id) > -1
      ) {
        const found = userReasonCategories.some(item => item.id === 'cat7');
        if (!found) {
          userReasonCategories.push({
            id: 'cat7', value: 'RELATED_RIGHTS'
          });
        }
      }
    }

    this.setState({
      userReasonCategories
    });
  }

  userReasonsModule = () => {
    const reasons = this.state.userReasonBrand;

    return (
      <div className="copyright-ctn">
        {
          reasons
            .map((notice, i) => {
              return (
                <>
                  <h2> {t(`report_v2.catalog_product_title`)}</h2>
                  <div key={notice.id} className="reason-category-ctn" onClick={event => this.handleSelectNotice(event, notice)}>
                    <span>
                      {t(`report_v2.catalog_code.${notice}`)}
                    </span>
                    <p>{t(`report_v2.catalog_code.${notice}_subtext`)} </p>
                    <img src="/images/arrow-right-blue.png" />
                  </div>
                </>
              )
            }
            )
        }
      </div>
    );
  }

  catalogModule = () => {

    const {
      comment
    } = this.state;
    const options = [
      { value: t('report_v2.select-options.opt-title'), label: t('report_v2.select-options.opt-title') },
      { value: t('report_v2.select-options.opt-description'), label: t('report_v2.select-options.opt-description') },
      { value: t('report_v2.select-options.opt-sheet'), label: t('report_v2.select-options.opt-sheet') },
      { value: t('report_v2.select-options.opt-publish'), label: t('report_v2.select-options.opt-publish') },
    ];

    const { selectedOption } = this.state;

    const cerrarError = () => {
      var errorElement = document.getElementsByClassName("error-message");
      if (errorElement) {
        errorElement[0].classList.add("hidden");
      }
    }

    return (
      <>
        <div className="options-ctn">
          <div className="select-ctn">
            <p className="subtitle">
              {t('report_v2.select-options.subtitle')}
            </p>

            <Select
              placeholder={t('report_v2.select-options.placeholder')}
              value={selectedOption}
              onChange={this.handleChangeOptions}
              options={options}
            />
          </div>
          <div className="text-area-ctn">
            <p className="subtitle">
              {t('report_v2.select-options.subtitle2')}
            </p>
            <textarea value={comment} required className="text-area-option" placeholder={t('report_v2.select-options.placeholder2')} maxLength="500" onChange={this.handleChangeComment} >
            </textarea>
            <span className="count-chars">

              {comment.length} / 500
            </span>
          </div>

          <button
            className={`btn-check-suspicious btn btn-lg ${selectedOption === null || this.props.sendingBrandInfringement ? 'disabledButton' : ""}`}
           // onClick={event => this.handleSend(event)}
          >
            {t('report_v2.select-options.button')}
          </button>
          {
            this.props.errorSendingBrandInfringement &&
            <div className="error-message">
              <div className="error-message-container">
                <div className="error-message-content">
                  <img className="error-icon" src="/images/catalog/caution.svg"></img>
                  <p> {t('report_v2.errorDenounce')}
                  </p>
                  <img className="error-close" onClick={cerrarError} src="/images/catalog/Close.svg"></img>
                </div>
              </div>
            </div>
          }
        </div>
      </>
    );

  }

  finalModule = (caseId) => {
    return (
      <>
        <div className="final-ctn">
          <div style={{ height: "24px", width: "100%" }}>
          </div>
          <div className="final-message-ctn">
            <div className="frame64">
              <div className="env-frame">
                <img className="env-img" src="/images/catalog/envelope.svg" />
              </div>
              <div className="check-frame">
                <div className="check-icon">
                  <img src="/images/catalog/CheckPositive.svg" />
                </div>
              </div>
            </div>
            <div className="message-ctn">
              <div className="title">
                {t("report_v2.buybox.title")}
              </div>
              <div className="description">
                {t("report_v2.buybox.description")}
              </div>
              <div className="case-number">
                <div className="case-text">
                  {t("report_v2.buybox.case-number")}
                </div>
                <div ref={this.copyRef} className="case-text-number">
                  {caseId}
                </div>
                <img onClick={this.copyClipboard} className="case-copy" src="/images/catalog/Copy.svg" />
              </div>
            </div>
          </div>
        </div>
      </>
    )
  }

  render() {
    const {
      profile,
      article,
      closeModal,
      isFetching,
      isMultiple,
      isFetchingAllowedNotices,
      caseId
    } = this.props;
    const {
      step,
      siteId,
      photosDenounced,
      errorTextArea
    } = this.state;
    // const hasPhotos = photosDenounced && photosDenounced.length;
    const ready = Boolean(
      siteId &&
      !isFetching &&
      (isMultiple || !isNullish(article)) &&
      !isFetchingAllowedNotices
    );

    let siteNotices = ready ?
      get(profile, 'allowedNotices', [])
        .filter(site => ready && site.siteId === siteId)
        .map(site => site.notices)[0] :
      [];


    siteNotices = siteNotices.map((notice) => {
      if ((notice.id === 'PPPI18') || (notice.id === 'PPPI19')) {
        return { id: notice.id, type: 'Actors' };
      } else if ((notice.id === 'PPPI20') || (notice.id === 'PPPI21')) {
        return { id: notice.id, type: 'Producers' };
      } else if ((notice.id === 'PPPI22') || (notice.id === 'PPPI23')) {
        return { id: notice.id, type: 'BroadcastingOrganizations' };
      } else if ((notice.id === 'PPPI3')) {
        return { id: notice.id, type: 'Producto' };
      } else {
        return notice;
      }
    });

    console.log('siteNotices (post)', siteNotices);

    if (
      ready &&
      !(Array.isArray(siteNotices) && siteNotices.length)
    ) {
      this.handleNoAvailableNoticesError();
    }

    let groupNoticesByType = {};

    if (Array.isArray(siteNotices) && siteNotices.length) {
      groupNoticesByType = this.groupBy(siteNotices, 'type');
    }

    if (Array.isArray(siteNotices) && siteNotices.length) {
      if (this.state.userReasonCategories.length === 0) {
        this.setUserReasonCategories(siteNotices);
      }
    }


    return (
      <>
        <div
          className="modal-content report-modal-content drawer-ctn"
          style={{
            overflowX: 'hidden',
            overflowY: 'auto',
            height: "100vh",
          }}
        >
          <form
            onSubmit={this.handleSend}
            className="new_reported_article"
          >
            <div className="drawer__header">

              {
                step === 1 &&
                <>
                  <h2 className="modal-title title-step-1">
                    {t('report_v2.title_catalog')} {this.articleId}
                  </h2>

                  <h3 className="modal-subtitle">
                    {t('report_v2.subtitle_catalog')}
                  </h3>

                  <i
                    className="modal-close close-step-1"
                    onClick={closeModal}
                  />
                </>
              }
              {
                step !== 1 && step !== 3 &&
                <>
                  <img
                    className="go-back"
                    src="/images/arrow-left-blue.png"
                    onClick={this.handleGoBack}
                  />
                  <h2 className="modal-title">
                    {t('report_v2.title_catalog')}

                  </h2>
                  <h3 className="modal-subtitle">

                  </h3>
                  <i className="modal-close" onClick={closeModal} />
                </>
              }

            </div>
            <div
              className="radio-fields"
            // style={{ overflowY: this.props.isMultiple ? 'none' : 'auto' }}
            >
              {
                step === 1 &&
                <>
                  {this.groupTypeModule('BRAND', groupNoticesByType, 'product')}
                  {this.groupTypeModule('BRAND', groupNoticesByType, 'publication')}
                </>
              }
              {
                step === 2 &&
                <>
                  {this.catalogModule()}
                </>
              }
            </div>
            {
              step === 3 &&
              <>
                <i className="modal-close final" onClick={closeModal} />
                {this.finalModule(caseId)}
              </>
            }
          </form >
        </div >
      </>
    );
  }
}

const mapStateToProps = (state, ownProps) => ({
  profile: state.global.profile,
  lang: state.global.lang,
  isMultiple: !ownProps.articleId,
  isFetching: state.detail.isFetching,
  liveSearch: state.detail.liveSearch,
  article: state.global.articlesMap[ownProps.articleId],
  isFetchingAllowedNotices: state.global.isFetchingAllowedNotices,
  sendingBrandInfringement: state.reportedArticles.sendingBrandInfringement,
  errorSendingBrandInfringement: state.reportedArticles.errorSendingBrandInfringement,
  caseId: state.reportedArticles.caseId

});

const actions = {
  closeModal,
  reportArticles,
  fetchArticleDetail,
  editReportedArticles,
  setNewReasonTooltipAlreadyShown,
  sendBrandInfringement
};

export default withRouter(connect(mapStateToProps, actions)(ArticleDenounceCatalog));