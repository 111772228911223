/* eslint-disable max-len */
/**
 * CONSTANTS
 */
export const FETCH_ARTICLE_DESCRIPTION = "FETCH_ARTICLE_DESCRIPTION";
export const FETCH_ARTICLE_DESCRIPTION_FULFILLED = "FETCH_ARTICLE_DESCRIPTION_FULFILLED";
export const FETCH_ARTICLE_DESCRIPTION_ERROR = "FETCH_ARTICLE_DESCRIPTION_ERROR";

/**
 * DISPATCHERS
 */
export const fetchArticleDescription = payload => ({ type: FETCH_ARTICLE_DESCRIPTION, payload });
export const fetchArticleDescriptionFulfilled = payload => ({ type: FETCH_ARTICLE_DESCRIPTION_FULFILLED, payload });
export const fetchArticleDescriptionError = payload => ({ type: FETCH_ARTICLE_DESCRIPTION_ERROR, payload });
