import React from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import SVG from 'react-inlinesvg';

function SVGs(props) {
  const { iProps, ...rest } = props;

  return (
    <i {...iProps}> 
<SVG src={rest.icon} />
    </i>
  );
}

export default SVGs;