import React from "react";
import classnames from "classnames";

const UserProfile = props => {
  const { 
    profile,
    isActive,
    onChangeProfile,
  } = props; 
  
  return (
    <div
      onClick={isActive ? 
        null :
        () => onChangeProfile(profile.id) 
      } 
      className={classnames(
        "profile-box",
        { 'active': isActive },
      )}>
      <div className="profile-avatar">
        <span className="profile-avatar-user">
          <i className="nav-icon-user" />
        </span>
      </div>
      <div className="profile-name">
        {profile.givenName}
      </div>
      <div className="profile-lastname">
        {profile.familyName}
      </div>
    </div>
  );
};

export default UserProfile;