import React from "react";
import classnames from "classnames";
import './image-gallery.scss';
import { openModal } from "redux/actions/global";
import { ARTICLE_IMAGES_CATALOGO } from "components/Modal";
import { connect } from "react-redux";

function ImageGallery(props) {
  const { images, onReportOpen, articleId,fallbackImage, openModalOnClick, originalImages, viewMore, profileCanDenounceImages } = props;
  const [currentImageIndex, setCurrentImageIndex] = React.useState(0);

  const openArticleImagesModal = originalImages => {
    props.openModal({ 
      type: ARTICLE_IMAGES_CATALOGO,
      images: originalImages,
      onReportOpen: onReportOpen,
      articleId: articleId,
      profileCanDenounceImages
    });
  };

  let imagesLogic = images;
  if(viewMore){
    imagesLogic = images && images.slice(0, 3);
  }

  return (
    <div
      className="image-gallery"
    >
      <div className="image-gallery-wrapper" style={{ maxHeight: '85px', overflowY: 'hidden' }}>
        
        <div className="image-gallery-labels" style={{ minHeight: '84px', Height: '90px'}}>
          {
            (imagesLogic && imagesLogic.length) &&
              imagesLogic
                .map((image, i) =>
                  <React.Fragment key={`thumbnail_fragment_${i}`}>
                    <label
                      key={`thumbnail_${i}`}
                      htmlFor={`thumbnail-radio-${i}`}
                      className={classnames(
                        "label_thumbnail",
                        { "selected": i === currentImageIndex }
                      )}
                    >
                      <img
                        width="70"
                        height="70"
                        key={`image_${i}`}
                        alt={image.alt}
                        src={image.thumbnail}
                        onMouseOver={e => {
                          if(openModalOnClick){
                            e.target.click();
                            if(document.getElementById(`zoom_${i}`)){
                              document.getElementById(`zoom_${i}`).style.display = 'block';
                            }
                            
                            for(let j = 0; j < imagesLogic.length; j++){
                              if(j !== i){
                                document.getElementById(`zoom_${j}`).style.display = 'none';
                              }
                            }
                          }
                          else {
                            e.target.click();
                          }
                        }}
                        onClick={() => { 
                          setCurrentImageIndex(i);
                        }}
                      />
                      {
                        (
                          openModalOnClick
                          // (i !== 2 && imagesLogic.length === images.length)
                        ) &&
                        <div 
                          id={`zoom_${i}`} 
                          className={classnames(
                            "zoom-in",
                          )}
                          onMouseLeave={e => {
                            e.target.style.display = 'none';
                          }}
                          onClick={() => { 
                            openArticleImagesModal(originalImages);
                          }}
                        ></div>
                      }
                      {
                        viewMore && 
                        imagesLogic.length < images.length &&
                        i === 2 &&
                        <div 
                          id={`view-more`} 
                          className={classnames(
                            "view-more",
                          )}
                          onClick={() => { 
                            openArticleImagesModal(originalImages);
                          }}
                        >
                          + {images.length - imagesLogic.length}
                        </div>
                      }
                    </label>
                  </React.Fragment>
                )
          }
        </div>
      </div>
    </div> 
  );
}

const actions = {
  openModal,
};

export default connect(null, actions)(ImageGallery); 
