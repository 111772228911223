import React, { Component } from "react";
import ReactGA from "react-ga";
import { withRouter } from "react-router-dom";
import classnames from "classnames";
import { connect } from "react-redux";
import { faQuestionCircle } from "@fortawesome/free-regular-svg-icons";
import { reportArticles, editReportedArticles } from "redux/actions/detail";
import Tooltip from "components/Tooltip";
import Loader from "components/Loader";
import {
  closeModal,
  fetchArticleDetail,
  setNewReasonTooltipAlreadyShown,
  fetchBlindages,
} from "redux/actions/global";
import Icon from "components/Icon";
import ImageHub from "components/ImageHub";
import {
  t,
  get,
  isNullish,
  alertError,
  getDateUTC,
  stringToPlainText,
  getIdFromImageSrc,
  isDenounceWithImages,
} from "utils";
import { BLINDAGE_REPORT_MULTIPLE } from "components/Modal";

import AlertLink from "components/AlertLink";

class ArticleReportDrawer extends Component {
  constructor(props) {
    super(props);
    console.log('juanjo source', this.props.source);

    const { article, hideImagesOptions, onlyCanDenounceImages, blindages } =
      props;
    const reported = article && article.reported;

    const selectedNotice = get(reported, "code", null);
    //console.log("reported "+props.productId)
    console.log("productId", props.productId);

    let disabledMultipleReasons = [];

    const path = window.location.pathname;

    if (path.includes("article_catalogo_detail")) {
      disabledMultipleReasons = ["PPPI2", "PPPI6", "PPPI7", "PPPI19"];
    } else {
      disabledMultipleReasons = ["PPPI6", "PPPI7", "PPPI19"];
    }

    // CAT1 => BRAND
    // CAT2 => COPYRIGHT
    // CAT3 => PERSONAL_IMAGE
    // CAT4 => UTILITY_MODEL
    // CAT5 => PATENT
    // CAT6 => AGREEMENTS
    // CAT7 => RELATED_RIGHTS
    const reasonCategories = {
      BRAND: ["PPPI1", "PPPI2", "PPPI10"],
      COPYRIGHT: [
        "PPPI3",
        "PPPI5",
        "PPPI6",
        "PPPI11",
        "PPPI12",
        "PPPI13",
        "PPPI14",
        "PPPI15",
        "PPPI16",
        "PPPI17",
      ],
      PERSONAL_IMAGE: ["PPPI7"],
      UTILITY_MODEL: ["PPPI8"],
      PATENT: ["PPPI9"],
      AGREEMENTS: ["ACUERDO"],
      RELATED_RIGHTS: [
        "PPPI18",
        "PPPI19",
        "PPPI20",
        "PPPI21",
        "PPPI22",
        "PPPI23",
      ],
    };

    this.state = {
      step: 1,
      siteId: null,
      selectedNotice,
      productId: props.productId ? props.productId : null,
      isEdit: selectedNotice ? true : false,
      comment: get(reported, "description", ""),
      photosDenounced: get(reported, "photosDenounced", null),
      originModel: props.originModel || null,
      newReasonTooltipAlreadyShown:
        props.profile.helps.newReasonTooltipAlreadyShown || false,
      disabledMultipleReasons,
      reasonCategories,
      userReasonCategories: [],
      userReasonCopyright: [],
      selectedStep1: null,
      onlyCanDenounceImages,
      hideImagesOptions,
      border:false,
      showWarning: false,
      showWarningMessage: false,
    };

    this.handleChangeImages = this.handleChangeImages.bind(this);
  }

  componentDidMount() {
    let siteId = null;

    if (!this.props.article && !this.props.isMultiple) {
      this.props.fetchArticleDetail({
        articleId: this.props.articleId,
        error: this.props.error,
      });
    }

    if (this.props.isMultiple) {
      const siteIds = this.props.articlesIds.map((articleId) =>
        articleId.slice(0, 3)
      );
      this.props.fetchBlindages(this.props.articlesIds);
      const uniqueSiteIds = [...new Set(siteIds)];

      if (uniqueSiteIds.length === 1) {
        siteId = uniqueSiteIds[0];
      } else {
        this.handleMultipleSiteError();
      }
    } else {
      siteId = this.props.articleId.slice(0, 3);
      this.props.fetchBlindages(this.props.articleId);
    }

    this.setState({ siteId });
    this.tagInput && this.tagInput.focus();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.isFetching && !this.props.isFetching) {
      if (!this.props.article) {
        this.props.closeModal();
      } else if (!this.state.selectedNotice) {
        const { article } = this.props;
        const reported = article && article.reported;
        //console.log("this.state")
        //console.log(this.state)
        this.setState({
          isEdit: false,
          comment: get(reported, "description", ""),
          selectedNotice: get(reported, "code", null),
          photosDenounced: get(reported, "photosDenounced", null),
        });

        //console.log("this.state")
        //console.log(this.state)
      }
    }
    if (!this.state.newReasonTooltipAlreadyShown) {
      document.getElementById("notices_PPPI10") &&
        this.tooltipNewReason &&
        this.tooltipNewReason.open &&
        this.tooltipNewReason.open();
      this.setState({ newReasonTooltipAlreadyShown: true });
      document.getElementById("notices_PPPI10") &&
        this.props.setNewReasonTooltipAlreadyShown &&
        this.props.setNewReasonTooltipAlreadyShown(true);
    }
    if (this.tooltipNewReason) {
      if (document.getElementsByClassName("tooltip-new-reason")) {
        if (document.getElementById("notices_PPPI10")) {
          document.getElementsByClassName(
            "tooltip-new-reason"
          )[0].firstChild.style.top =
            document
              .getElementById("notices_PPPI10")
              .parentElement.getBoundingClientRect().top -
            document.getElementById("notices_PPPI10").parentElement
              .offsetHeight /
              2 +
            "px";

          const drawerCtn = document.getElementsByClassName("drawer-ctn");
          if (drawerCtn) {
            if (
              document.getElementsByClassName("tooltip-new-reason") &&
              document.getElementById("notices_PPPI10")
            ) {
              drawerCtn[0].onscroll = function () {
                if (document.getElementById("notices_PPPI10")) {
                  document.getElementsByClassName(
                    "tooltip-new-reason"
                  )[0].firstChild.style.top =
                    document
                      .getElementById("notices_PPPI10")
                      .parentElement.getBoundingClientRect().top -
                    document.getElementById("notices_PPPI10").parentElement
                      .offsetHeight /
                      2 +
                    "px";
                }
              };
            }
          }
        }
      }
    }
  }

  componentWillUnmount() {
    if (typeof this.props.onClose === "function") {
      this.props.onClose();
    }
  }

  getTypeFromCat = (cat) => {
    let type = null;
    if (this.state.userReasonCategories) {
      for (let i in this.state.userReasonCategories) {
        if (this.state.userReasonCategories[i].id === cat) {
          type = this.state.userReasonCategories[i].value.toLowerCase();
          break;
        }
      }
    }
    return type;
  };

  getValue() {
    return { code: this.state.selectedNotice };
  }

  handleMultipleSiteError() {
    alertError(t("add_suspicious_xlsx.only_one_site"));
    this.props.closeModal();
  }

  handleNoAvailableNoticesError = () => {
    const { article } = this.props;
    alertError(
      t("no_report_codes_available_for") + article.country.name + ".",
      {
        timeout: 3000,
        onClose: () => window.location.reload(),
      }
    );
    this.props.closeModal();
  };

  handleChangeImages(value) {
    const photosDenounced = Object.keys(value).filter((id) => value[id]);
    this.setState({ photosDenounced });
  }

  renderImageHub() {
    const { article } = this.props;
    const { photosDenounced } = this.state;

    const images = article.images.length
      ? article.images.map((img) => ({
          id: img.id || getIdFromImageSrc(img.secure_url),
          src: img.secure_url.replace("-O.", "-I."),
        }))
      : [
          {
            id: getIdFromImageSrc(article.thumbnail),
            src: article.thumbnail,
          },
        ];

    const initialValue = images.reduce((prev, curr) => {
      prev[curr.id] = photosDenounced
        ? photosDenounced.indexOf(curr.id) > -1
        : false;
      return prev;
    }, {});

    return (
      <ImageHub
        images={images}
        initialValue={initialValue}
        onChange={this.handleChangeImages}
      />
    );
  }

  handleChange = (e) => {
    e.preventDefault();

    this.setState({
      selectedNotice: e.target.value,
      photosDenounced: null,
    });
  };

  handleSelectNotice = (e, reasonId) => {
    e.preventDefault();

    if (this.disabledSelection(reasonId)) {
      return;
    }

    let step = 2;
    if (isDenounceWithImages(reasonId)) {
      step = "images";
    }

    this.setState({
      selectedNotice: reasonId,
      photosDenounced: null,
      step,
    });
  };

  handleChangeComment = (e) => {
    e.preventDefault();

    const string = e.target.value.trim().replace(/\s+/g, "");
   
    const validString = /^(?:[^č]|[\w\d\s]){1,5000}$/.test(string);
    console.log(validString)
    if (string.length >= 0 && string.length < 5 || validString != true) {
      this.setState({ showWarning: true ,border:false});
     
    } else {
      this.setState({ showWarning: false, showWarningMessage: false, border:true });
    }
    this.setState({ comment: e.target.value, errorTextArea: false });
  };

  handleSelectStep1 = (e, value) => {
    e.preventDefault();

    if (this.disabledSelection(value)) {
      return;
    }

    let selectedNotice = null;
    let step =
      value === "cat1" || value === "cat2" || value === "cat7" ? 1.5 : 2;

    if (value === "cat3") {
      step = "images";
      selectedNotice = this.state.reasonCategories["PERSONAL_IMAGE"][0];
    }

    if (value === "cat4") {
      selectedNotice = this.state.reasonCategories["UTILITY_MODEL"][0];
    }

    if (value === "cat5") {
      selectedNotice = this.state.reasonCategories["PATENT"][0];
    }

    if (value === "cat6") {
      selectedNotice = this.state.reasonCategories["AGREEMENTS"][0];
    }

    if (value === "cat2") {
      if (this.state.onlyCanDenounceImages == true) {
        selectedNotice = "PPPI6";
        step = "images";
      }
      step = 1.5;

      selectedNotice = this.state.reasonCategories["COPYRIGHT"][0];
    }

    this.setState({
      selectedNotice,
      selectedStep1: value,
      step,
    });
  };

  handleGoBack = () => {
    let step = 1;
    if (this.state.selectedNotice) {
      if (
        this.state.reasonCategories["BRAND"].indexOf(
          this.state.selectedNotice
        ) > -1
      ) {
        if (this.state.step !== 1.5) {
          step = 1.5;
        }
      }
      if (
        this.state.reasonCategories["COPYRIGHT"].indexOf(
          this.state.selectedNotice
        ) > -1
      ) {
        if (isDenounceWithImages(this.state.selectedNotice)) {
          if (this.state.step !== "images") {
            if (this.state.step === 1.5) {
              step = 1;
            } else {
              step = "images";
            }
          } else {
            if (this.state.step !== 1.5) {
              step = 1.5;
            }
          }
        } else {
          if (this.state.step !== 1.5) {
            step = 1.5;
          }
        }
      }
      if (
        this.state.reasonCategories["PERSONAL_IMAGE"].indexOf(
          this.state.selectedNotice
        ) > -1
      ) {
        if (this.state.step !== "images") {
          step = "images";
        }
      }
      if (
        this.state.reasonCategories["RELATED_RIGHTS"].indexOf(
          this.state.selectedNotice
        ) > -1
      ) {
        if (isDenounceWithImages(this.state.selectedNotice)) {
          if (this.state.step !== "images") {
            if (this.state.step === 1.5) {
              step = 1;
            } else {
              step = "images";
            }
          } else {
            if (this.state.step !== 1.5) {
              step = 1.5;
            }
          }
        } else {
          if (this.state.step !== 1.5) {
            step = 1.5;
          }
        }
      }
    }

    this.setState({ step });
  };

  handleClickContinue = (e) => {
    e.preventDefault();
    e.stopPropagation();

    if (this.tooltipNewReason) {
      this.tooltipNewReason.close && this.tooltipNewReason.close();
    }

    this.setState({ step: 2 });
  };

  handleSubmit = (e) => {
    e.preventDefault();

    const string = this.state.comment.trim().replace(/\s+/g, "");

    if (string.length < 5) {
      this.setState({ showWarningMessage: true });
      return;
    }

    const validString = /^(?:[^č]|[\w\d\s]){1,5000}$/.test(string);
    if (validString != true) {
      this.setState({ showWarningMessage: true });
      return;
    }

    const { code } = this.getValue();
    const { profile, article, isMultiple } = this.props;
    const { isEdit, comment, originModel, photosDenounced, productId } =
      this.state;
    const code_description = t(`report.report_code.${code}`);

    const description = stringToPlainText(comment);
    // if(!(/^[A-Za-z0-9\.\,\'\s\&]{0,2000}$/.test(description))){
    //   this.setState({errorTextArea: true});
    //   return false;
    // }
    ReactGA.ga("send", "event", "REPORT", "MARK_AS_SUSPICIOUS");
    //console.log(article)

    if (isMultiple) {
      const [filteredArticles, blindagesIds, blindagesInfoExcel] =
        this.checkBlindageMultiple(code);

      const payload = {
        articlesIds: isMultiple ? filteredArticles : [article.id],
        product_id: productId ? productId : null,
        element_type: productId ? "CAT" : "ITM",
        code,
        code_description,
        description: description.trim(),
        originModel,
        photosDenounced,

        author: {
          id: profile.id,
          givenName: profile.givenName,
          familyName: profile.familyName,
        },
      };

      if (isEdit) {
        this.props.editReportedArticles(payload);

        setTimeout(() => {
          this.props.closeModal();
        }, 100);
      } else {
        this.props.reportArticles({
          ...payload,
          // usage of redux
          history: this.props.history,
          isImport: this.props.isImport,
          source: this.props.source
        });

        console.log("ACA", blindagesInfoExcel);

        setTimeout(() => {
          this.props.openModal({
            type: BLINDAGE_REPORT_MULTIPLE,
            articlesIds: filteredArticles,
            blindagesIds: blindagesIds,
            blindagesInfoExcel: blindagesInfoExcel,
          });
        }, 100);
      }
    } else {
      const payload = {
        articlesIds: [article.id],
        product_id: productId ? productId : null,
        element_type: productId ? "CAT" : "ITM",
        code,
        code_description,
        description: description.trim(),
        originModel,
        photosDenounced,

        author: {
          id: profile.id,
          givenName: profile.givenName,
          familyName: profile.familyName,
        },
      };

      if (isEdit) {
        this.props.editReportedArticles({...payload, source: `edit-${this.props.source}`});

        setTimeout(() => {
          this.props.closeModal();
        }, 100);
      } else {
        this.props.reportArticles({
          ...payload,
          // usage of redux
          history: this.props.history,
          isImport: this.props.isImport,
          source: this.props.source,
        });
        setTimeout(() => {
          this.props.closeModal();
          // Commented to avoid instant redirection on report
          //!isMultiple && this.props.history.goBack();
        }, 100);
      }
    }
  };
  groupBy(array, key) {
    return array.reduce((prev, curr) => {
      let current =
        curr.type === "Producto" ? { id: curr.id, type: "Product" } : curr;
      const groupKey = current[key].toLowerCase();
      prev[groupKey] = [...(prev[groupKey] || []), current];
      return prev;
    }, {});
  }

  checkBlindage(notice) {
    const noticeExists = this.props.blindages.findIndex(
      (item) => item.reason === notice
    );
    return noticeExists;
  }

  checkBlindageMultiple(code) {
    const blindagesIds = [];
    const blindagesInfoExcel = [];
    this.props.blindages.forEach((item) => {
      if (item.reason === code) {
        blindagesIds.push(item.item);
        blindagesInfoExcel.push(item);
      }
    });

    const filteredArticles = this.props.articlesIds.filter(
      (elemento) => !blindagesIds.includes(elemento)
    );

    return [filteredArticles, blindagesIds, blindagesInfoExcel];
  }

  disabledSelection(noticeId) {
    if (!this.state.onlyCanDenounceImages) {
      let disabled = false;

      if (
        noticeId === "cat3" ||
        this.state.disabledMultipleReasons.indexOf(noticeId) > -1
      ) {
        disabled = true;
      }

      if (noticeId === "cat7") {
        disabled = false;
      }

      let filteredImageCopyrights = [];
      filteredImageCopyrights = this.state.userReasonCopyright.filter(
        (e) => e != "PPPI6"
      );

      if (noticeId === "cat2" && filteredImageCopyrights.length === 0) {
        disabled = true;
      }

      if (!this.props.isMultiple && this.state.hideImagesOptions) {
        return disabled;
      }

      return this.props.isMultiple && disabled;
    }

    //New catalog report only with images button
    if (this.state.onlyCanDenounceImages) {
      let disabled = true;

      if (
        noticeId === "cat2" ||
        noticeId === "cat3" ||
        noticeId === "cat7" ||
        this.state.disabledMultipleReasons.indexOf(noticeId) > -1
      ) {
        disabled = false;
      }

      return disabled;
    }
  }

  tooltip(id) {
    return this.tooltip[id];
  }

  setUserReasonCategories = (siteNotices) => {
    let userReasonCategories = [];
    for (let i in siteNotices) {
      if (
        this.state.reasonCategories["BRAND"].indexOf(siteNotices[i].id) > -1
      ) {
        const found = userReasonCategories.some((item) => item.id === "cat1");
        if (!found) {
          userReasonCategories.push({
            id: "cat1",
            value: "BRAND",
          });
        }
      }
      if (
        this.state.reasonCategories["COPYRIGHT"].indexOf(siteNotices[i].id) > -1
      ) {
        const found = userReasonCategories.some((item) => item.id === "cat2");
        if (!found) {
          userReasonCategories.push({
            id: "cat2",
            value: "COPYRIGHT",
          });
        }
      }
      if (
        this.state.reasonCategories["PERSONAL_IMAGE"].indexOf(
          siteNotices[i].id
        ) > -1
      ) {
        const found = userReasonCategories.some((item) => item.id === "cat3");
        if (!found) {
          userReasonCategories.push({
            id: "cat3",
            value: "PERSONAL_IMAGE",
          });
        }
      }
      if (
        this.state.reasonCategories["UTILITY_MODEL"].indexOf(
          siteNotices[i].id
        ) > -1
      ) {
        const found = userReasonCategories.some((item) => item.id === "cat4");
        if (!found) {
          userReasonCategories.push({
            id: "cat4",
            value: "UTILITY_MODEL",
          });
        }
      }
      if (
        this.state.reasonCategories["PATENT"].indexOf(siteNotices[i].id) > -1
      ) {
        const found = userReasonCategories.some((item) => item.id === "cat5");
        if (!found) {
          userReasonCategories.push({
            id: "cat5",
            value: "PATENT",
          });
        }
      }
      if (
        this.state.reasonCategories["AGREEMENTS"].indexOf(siteNotices[i].id) >
        -1
      ) {
        const found = userReasonCategories.some((item) => item.id === "cat6");
        if (!found) {
          userReasonCategories.push({
            id: "cat6",
            value: "AGREEMENTS",
          });
        }
      }
      if (
        this.state.reasonCategories["RELATED_RIGHTS"].indexOf(
          siteNotices[i].id
        ) > -1
      ) {
        const found = userReasonCategories.some((item) => item.id === "cat7");
        if (!found) {
          userReasonCategories.push({
            id: "cat7",
            value: "RELATED_RIGHTS",
          });
        }
      }
    }
    this.setState({
      userReasonCategories,
    });
  };

  setUserReasonCopyright = (siteNotices) => {
    let userReasonCopyright = [];
    for (let i in siteNotices) {
      if (
        this.state.reasonCategories["COPYRIGHT"].indexOf(siteNotices[i].id) > -1
      ) {
        userReasonCopyright.push(siteNotices[i].id);
      }
    }

    if (userReasonCopyright.length !== 0) {
      this.setState({
        userReasonCopyright,
      });
    }
  };

  userReasonsModule = () => {
    return (
      <>
        {!this.props.isFetchingBlindages &&
          this.state.userReasonCategories &&
          this.state.userReasonCategories.length > 0 &&
          this.state.userReasonCategories.map((cat, i) => {
            const name = t(`report_v2.step_1.${cat.value.toLowerCase()}`);

            return (
              <>
                {cat.id === "cat5" &&
                this.checkBlindage("PPPI9") !== -1 &&
                !this.props.isMultiple ? (
                  <div
                    key={cat.id}
                    className={classnames("reason-category-ctn blindage", {
                      hideOptions:
                        this.state.onlyCanDenounceImages ||
                        this.state.hideImagesOptions,
                    })}
                    onMouseEnter={(e) => {
                      this.tooltip &&
                        this.tooltip[cat.id] &&
                        this.tooltip[cat.id].open(e.target);
                    }}
                    onMouseOver={(e) => {
                      this.tooltip &&
                        this.tooltip[cat.id] &&
                        this.tooltip[cat.id].open(e.target);
                    }}
                    onMouseLeave={(e) => {
                      this.tooltip &&
                        this.tooltip[cat.id] &&
                        this.tooltip[cat.id].close(e.target);
                    }}
                  >
                    <div>
                      <span>{name}</span>
                      <img src="/images/arrow-right-blue.png" />
                      <Tooltip
                        timeOutToOpen={0}
                        timeOutToClose={100}
                        ref={(node) => (this.tooltip[cat.id] = node)}
                        className={`blindage`}
                      >
                        <div className="title">
                          {t(`blindage.tooltip_title`)}
                        </div>
                        <p>
                          {t(`blindage.tooltip_content`)}
                          {getDateUTC(
                            this.props.blindages[this.checkBlindage("PPPI9")]
                              .dueDate,
                            this.props.lang
                          )}
                          .
                        </p>
                        <div className="link">
                          <a
                            href="https://pppi.mercadolibre.com/support_form"
                            target="blank"
                            rel="noopener noreferrer"
                          >
                            {t(`blindage.tooltip_link`)}
                          </a>
                        </div>
                      </Tooltip>
                    </div>

                    {this.disabledSelection(cat.id) &&
                      !this.state.onlyCanDenounceImages && (
                        <div
                          onMouseEnter={(e) => {
                            e.stopPropagation();
                            this.tooltip &&
                              this.tooltip[cat.id] &&
                              this.tooltip[cat.id].open(e.target);
                          }}
                          onMouseLeave={(e) => {
                            e.stopPropagation();
                            this.tooltip &&
                              this.tooltip[cat.id] &&
                              this.tooltip[cat.id].close(e.target);
                          }}
                          className="help-icon-ctn"
                        >
                          <Icon icon={faQuestionCircle} />
                          <Tooltip
                            timeOutToOpen={0}
                            timeOutToClose={100}
                            ref={(node) => (this.tooltip[cat.id] = node)}
                            className={`tooltip-help-disabled-${cat.id}`}
                          >
                            <button
                              className="close"
                              onClick={() => {
                                this.tooltip &&
                                  this.tooltip[cat.id] &&
                                  this.tooltip[cat.id].close();
                              }}
                            >
                              &times;
                            </button>
                            <p>{t("multiple_selector.tooltip_text")}</p>
                          </Tooltip>
                        </div>
                      )}
                  </div>
                ) : (
                  <div
                    key={cat.id}
                    className={classnames(
                      "reason-category-ctn",
                      {
                        disabled: this.disabledSelection(cat.id),
                      },
                      {
                        hideOptions:
                          this.state.onlyCanDenounceImages ||
                          this.state.hideImagesOptions,
                      }
                    )}
                    onClick={(event) => this.handleSelectStep1(event, cat.id)}
                  >
                    <span>{name}</span>
                    <img src="/images/arrow-right-blue.png" />
                    {this.disabledSelection(cat.id) &&
                      !this.state.onlyCanDenounceImages && (
                        <div
                          onMouseEnter={(e) => {
                            e.stopPropagation();
                            this.tooltip &&
                              this.tooltip[cat.id] &&
                              this.tooltip[cat.id].open(e.target);
                          }}
                          onMouseLeave={(e) => {
                            e.stopPropagation();
                            this.tooltip &&
                              this.tooltip[cat.id] &&
                              this.tooltip[cat.id].close(e.target);
                          }}
                          className="help-icon-ctn"
                        >
                          <Icon icon={faQuestionCircle} />
                          <Tooltip
                            timeOutToOpen={0}
                            timeOutToClose={100}
                            ref={(node) => (this.tooltip[cat.id] = node)}
                            className={`tooltip-help-disabled-${cat.id}`}
                          >
                            <button
                              className="close"
                              onClick={() => {
                                this.tooltip &&
                                  this.tooltip[cat.id] &&
                                  this.tooltip[cat.id].close();
                              }}
                            >
                              &times;
                            </button>
                            <p>{t("multiple_selector.tooltip_text")}</p>
                          </Tooltip>
                        </div>
                      )}
                  </div>
                )}
              </>
            );
          })}
      </>
    );
  };

  groupTypeModule = (category, groupNoticesByType, type) => {
    const {
      disabledMultipleReasons,
      hideImagesOptions,
      reasonCategories,
      onlyCanDenounceImages,
    } = this.state;

    let reasons = [];
    if (groupNoticesByType[type]) {
      reasons = groupNoticesByType[type].filter((reason) => {
        if (reasonCategories[category].indexOf(reason.id) > -1) {
          if (onlyCanDenounceImages && !hideImagesOptions) {
            if (disabledMultipleReasons.indexOf(reason.id) > -1) {
              return reason;
            }
          } else if (!onlyCanDenounceImages && hideImagesOptions) {
            if (disabledMultipleReasons.indexOf(reason.id) === -1) {
              return reason;
            }
          } else {
            return reason;
          }
        }
      });
    }

    return (
      <>
        {reasons.length > 0 && !this.props.isFetchingBlindages ? (
          <div className="type-ctn">
            {reasons.map((notice, i) => {
              const description = t(`report_v2.report_code.${notice.id}`)
                ? t(`report_v2.report_code.${notice.id}`)
                : notice.description;
              const subText = t(`report_v2.report_code.${notice.id}_subtext`);

              return (
                <div key={notice.id}>
                  {i === 0 && (
                    <h3 className="modal-title-type">
                      {t(`report_v2.title_${type}`)}
                    </h3>
                  )}
                  {this.checkBlindage(notice.id) !== -1 &&
                  !this.props.isMultiple ? (
                    <div
                      key={notice.id}
                      className={classnames("reason-brand-ctn blindage")}
                      onMouseEnter={(e) => {
                        this.tooltip &&
                          this.tooltip[notice.id] &&
                          this.tooltip[notice.id].open(e.target);
                      }}
                      onMouseOver={(e) => {
                        this.tooltip &&
                          this.tooltip[notice.id] &&
                          this.tooltip[notice.id].open(e.target);
                      }}
                      onMouseLeave={(e) => {
                        this.tooltip &&
                          this.tooltip[notice.id] &&
                          this.tooltip[notice.id].close(e.target);
                      }}
                    >
                      <span className="description">{description}</span>
                      {subText && subText !== "" && (
                        <span className="subtext">{subText}</span>
                      )}
                      <img src="/images/arrow-right-blue.png" />
                      <Tooltip
                        timeOutToOpen={0}
                        timeOutToClose={100}
                        ref={(node) => (this.tooltip[notice.id] = node)}
                        className={`blindage`}
                      >
                        <div className="title">
                          {t(`blindage.tooltip_title`)}
                        </div>
                        <p>
                          {t(`blindage.tooltip_content`)}
                          {getDateUTC(
                            this.props.blindages[this.checkBlindage(notice.id)]
                              .dueDate,
                            this.props.lang
                          )}
                          .
                        </p>
                        <div className="link">
                          <a
                            href="https://pppi.mercadolibre.com/support_form"
                            target="blank"
                            rel="noopener noreferrer"
                          >
                            {t(`blindage.tooltip_link`)}
                          </a>
                        </div>
                      </Tooltip>
                    </div>
                  ) : (
                    <div
                      key={notice.id}
                      className={classnames("reason-brand-ctn")}
                      onClick={(event) =>
                        this.handleSelectNotice(event, notice.id)
                      }
                    >
                      <span className="description">{description}</span>
                      {subText && subText !== "" && (
                        <span className="subtext">{subText}</span>
                      )}
                      <img src="/images/arrow-right-blue.png" />
                    </div>
                  )}
                </div>
              );
            })}
          </div>
        ) : reasons.length > 0 ? (
          <Loader className="small" />
        ) : (
          <></>
        )}
      </>
    );
  };

  copyrightModule = () => {
    const reasons = this.state.userReasonCopyright;

    return (
      <div>
        {reasons.length > 0 && !this.props.isFetchingBlindages ? (
          <div className="copyright-ctn">
            {reasons.map((notice, i) => {
              const description = t(`report_v2.report_code.${notice}`)
                ? t(`report_v2.report_code.${notice}`)
                : notice.description;
              const subText = t(`report_v2.report_code.${notice}_subtext`);

              return (
                <div key={notice}>
                  {i === 0 && <h2>{t(`report_v2.copyright_title`)}</h2>}

                  {this.checkBlindage(notice.id) !== -1 &&
                  !this.props.isMultiple ? (
                    <div
                      key={notice.id}
                      className={classnames(
                        "reason-copyright-ctn blindage",
                        { disabled: this.disabledSelection(notice) },
                        {
                          hideOptions:
                            this.state.onlyCanDenounceImages ||
                            this.state.hideImagesOptions,
                        }
                      )}
                      onMouseEnter={(e) => {
                        e.stopPropagation();
                        this.tooltip &&
                          this.tooltip[notice] &&
                          this.tooltip[notice].open(e.target);
                      }}
                      onMouseOver={(e) => {
                        this.tooltip &&
                          this.tooltip[notice] &&
                          this.tooltip[notice].open(e.target);
                      }}
                      onMouseLeave={(e) => {
                        e.stopPropagation();
                        this.tooltip &&
                          this.tooltip[notice] &&
                          this.tooltip[notice].close(e.target);
                      }}
                    >
                      <div className="description">
                        <span>{description}</span>
                        {this.disabledSelection(notice) &&
                          !this.state.onlyCanDenounceImages && (
                            <div
                              onMouseEnter={(e) => {
                                e.stopPropagation();
                                this.tooltip &&
                                  this.tooltip[notice] &&
                                  this.tooltip[notice].open(e.target);
                              }}
                              onMouseLeave={(e) => {
                                e.stopPropagation();
                                this.tooltip &&
                                  this.tooltip[notice] &&
                                  this.tooltip[notice].close(e.target);
                              }}
                              className="help-icon-ctn"
                            >
                              <Icon icon={faQuestionCircle} />
                              <Tooltip
                                timeOutToOpen={0}
                                timeOutToClose={100}
                                ref={(node) => (this.tooltip[notice] = node)}
                                className={`tooltip-help-disabled-${notice}`}
                              >
                                <button
                                  className="close"
                                  onClick={() => {
                                    this.tooltip &&
                                      this.tooltip[notice] &&
                                      this.tooltip[notice].close();
                                  }}
                                >
                                  &times;
                                </button>
                                <p>{t("multiple_selector.tooltip_text")}</p>
                              </Tooltip>
                            </div>
                          )}
                      </div>
                      {subText && subText !== "" && (
                        <span className="subtext">{subText}</span>
                      )}
                      <img src="/images/arrow-right-blue.png" />

                      <Tooltip
                        timeOutToOpen={0}
                        timeOutToClose={100}
                        ref={(node) => (this.tooltip[notice] = node)}
                        className={`blindage`}
                      >
                        <div className="title">
                          {t(`blindage.tooltip_title`)}
                        </div>
                        <p>
                          {t(`blindage.tooltip_content`)}
                          {getDateUTC(
                            this.props.blindages[this.checkBlindage(notice)]
                              .dueDate,
                            this.props.lang
                          )}
                          . .
                        </p>
                        <div className="link">
                          <a
                            href="https://pppi.mercadolibre.com/support_form"
                            target="blank"
                            rel="noopener noreferrer"
                          >
                            {t(`blindage.tooltip_link`)}
                          </a>
                        </div>
                      </Tooltip>
                    </div>
                  ) : (
                    <div
                      key={notice.id}
                      className={classnames(
                        "reason-copyright-ctn ",
                        { disabled: this.disabledSelection(notice) },
                        {
                          hideOptions:
                            this.state.onlyCanDenounceImages ||
                            this.state.hideImagesOptions,
                        }
                      )}
                      onClick={(event) =>
                        this.handleSelectNotice(event, notice)
                      }
                    >
                      <div className="description">
                        <span>{description}</span>
                        {this.disabledSelection(notice) &&
                          !this.state.onlyCanDenounceImages && (
                            <div
                              onMouseEnter={(e) => {
                                e.stopPropagation();
                                this.tooltip &&
                                  this.tooltip[notice] &&
                                  this.tooltip[notice].open(e.target);
                              }}
                              onMouseLeave={(e) => {
                                e.stopPropagation();
                                this.tooltip &&
                                  this.tooltip[notice] &&
                                  this.tooltip[notice].close(e.target);
                              }}
                              className="help-icon-ctn"
                            >
                              <Icon icon={faQuestionCircle} />
                              <Tooltip
                                timeOutToOpen={0}
                                timeOutToClose={100}
                                ref={(node) => (this.tooltip[notice] = node)}
                                className={`tooltip-help-disabled-${notice}`}
                              >
                                <button
                                  className="close"
                                  onClick={() => {
                                    this.tooltip &&
                                      this.tooltip[notice] &&
                                      this.tooltip[notice].close();
                                  }}
                                >
                                  &times;
                                </button>
                                <p>{t("multiple_selector.tooltip_text")}</p>
                              </Tooltip>
                            </div>
                          )}
                      </div>
                      {subText && subText !== "" && (
                        <span className="subtext">{subText}</span>
                      )}
                      <img src="/images/arrow-right-blue.png" />
                    </div>
                  )}
                </div>
              );
            })}
          </div>
        ) : (
          <Loader className="small" />
        )}
      </div>
    );
  };

  render() {
    const {
      profile,
      article,
      closeModal,
      isFetching,
      isMultiple,
      isFetchingAllowedNotices,
    } = this.props;
    const {
      step,
      lang,
      siteId,
      comment,
      selectedNotice,
      selectedStep1,
      photosDenounced,
      errorTextArea,
      showWarning,
      showWarningMessage,
      border
    } = this.state;
    const hasPhotos = photosDenounced && photosDenounced.length;
    const ready = Boolean(
      siteId &&
        !isFetching &&
        (isMultiple || !isNullish(article)) &&
        !isFetchingAllowedNotices
    );

    let siteNotices = ready
      ? get(profile, "allowedNotices", [])
          .filter((site) => ready && site.siteId === siteId)
          .map((site) => site.notices)[0]
      : [];

    //console.log('siteNotices (pre)', siteNotices);

    // siteNotices = [{id: "PPPI18", type: "Actors"},
    // {id: "PPPI19", type: "Actors"},
    // {id: "PPPI20", type: "Producers"},
    // {id: "PPPI21", type: "Producers"},
    // {id: "PPPI22", type: "BroadcastingOrganizations"}];
    // {id: "PPPI23", type: "BroadcastingOrganizations"}];

    //hardcode
    siteNotices = siteNotices.map((notice) => {
      if (notice.id === "PPPI18" || notice.id === "PPPI19") {
        return { id: notice.id, type: "Actors" };
      } else if (notice.id === "PPPI20" || notice.id === "PPPI21") {
        return { id: notice.id, type: "Producers" };
      } else if (notice.id === "PPPI22" || notice.id === "PPPI23") {
        return { id: notice.id, type: "BroadcastingOrganizations" };
      } else if (notice.id === "PPPI3") {
        return { id: notice.id, type: "Producto" };
      } else {
        return notice;
      }
    });

    // console.log("siteNotices (post)", siteNotices);

    if (ready && !(Array.isArray(siteNotices) && siteNotices.length)) {
      this.handleNoAvailableNoticesError();
    }

    let groupNoticesByType = {};

    if (Array.isArray(siteNotices) && siteNotices.length > 0) {
      groupNoticesByType = this.groupBy(siteNotices, "type");
    }

    if (Array.isArray(siteNotices) && siteNotices.length) {
      if (this.state.userReasonCategories.length === 0) {
        this.setUserReasonCategories(siteNotices);
      }
      if (this.state.userReasonCopyright.length === 0) {
        this.setUserReasonCopyright(siteNotices);
      }
    }

    return (
      <>
        {
          <Tooltip
            timeOutToOpen={0}
            timeOutToClose={100}
            ref={(node) =>
              (this.tooltipNewReason = this.tooltipNewReason || node)
            }
            className="tooltip-new-reason"
          >
            <button
              className="close"
              onClick={() => {
                this.tooltipNewReason && this.tooltipNewReason.close();
              }}
            >
              &times;
            </button>
            <strong>{t("report_v2.tooltip_PPPI10.title")}</strong>
            <p>{t("report_v2.tooltip_PPPI10.description")}</p>
          </Tooltip>
        }
        <div
          className="modal-content report-modal-content drawer-ctn"
          style={{
            overflowX: "hidden",
            overflowY: "auto",
            height: window.innerHeight - 48,
          }}
        >
          <form onSubmit={this.handleSubmit} className="new_reported_article">
            <div className="drawer__header">
              {step === 1 && (
                <>
                  <h2 className="modal-title title-step-1">
                    {isMultiple
                      ? t("report_v2.title_plural")
                      : this.state.onlyCanDenounceImages
                      ? t("article_catalogue_detail.report_images")
                      : t("report_v2.title")}
                  </h2>

                  <h3 className="modal-subtitle">{t("report_v2.subtitle")}</h3>

                  <i
                    className="modal-close close-step-1"
                    onClick={closeModal}
                  />
                </>
              )}
              {step !== 1 && (
                <>
                  <img
                    className="go-back"
                    src="/images/arrow-left-blue.png"
                    onClick={this.handleGoBack}
                  />
                  <h2 className="modal-title">
                    {t(
                      `report_v2.step_1.${this.getTypeFromCat(
                        this.state.selectedStep1
                      )}`
                    )}
                    {/* {
                             this.state.selectedStep1 === 'cat1' && 
                             this.state.selectedNotice &&
                             this.state.step !== 1.5 &&
                             <span className="selected-reason-brand"> /&nbsp; 
                                {t(`report_v2.report_code.${this.state.selectedNotice}`)}
                              </span>
                           } */}
                    {/* {
                             this.state.selectedStep1 === 'cat2' && 
                             this.state.selectedNotice &&
                             this.state.step !== 1.5 &&
                             <span className="selected-reason-copyright">
                                {
                                  t(`report_v2.copyright_selected`).replace(
                                    '${reason}', t(`report_v2.report_code.${this.state.selectedNotice}`)
                                  )
                                }
                              </span>
                           } */}
                    {/* {
                             (
                               this.state.selectedStep1 === 'cat4' ||
                               this.state.selectedStep1 === 'cat5'
                             ) && 
                             this.state.selectedNotice &&
                             <span className="selected-reason-rest">
                                {
                                  t(`report_v2.utility_patent_selected`).replace(
                                    '${reason}', t(`report_v2.report_code.${this.state.selectedNotice}`)
                                  )
                                }
                             </span>
                           } */}
                  </h2>
                  <h3 className="modal-subtitle">
                    {(step == 2 &&
                      this.getTypeFromCat(this.state.selectedStep1) ===
                        "related_rights") ||
                      t(
                        `report_v2.${this.getTypeFromCat(
                          this.state.selectedStep1
                        )}_sub`
                      )}
                  </h3>
                  <i className="modal-close" onClick={closeModal} />
                </>
              )}
            </div>

            <div
              className="radio-fields"
              style={{ overflowY: this.props.isMultiple ? "none" : "auto" }}
            >
              {step === 1 && <>{this.userReasonsModule()}</>}
              {step === 1.5 && (
                <>
                  {selectedStep1 === "cat1" && (
                    <>
                      {this.groupTypeModule(
                        "BRAND",
                        groupNoticesByType,
                        "product"
                      )}
                      {this.groupTypeModule(
                        "BRAND",
                        groupNoticesByType,
                        "publication"
                      )}
                    </>
                  )}
                  {selectedStep1 === "cat2" && <>{this.copyrightModule()}</>}
                  {selectedStep1 === "cat7" && (
                    <>
                      {/* {this.relatedRightModule()} */}
                      {this.groupTypeModule(
                        "RELATED_RIGHTS",
                        groupNoticesByType,
                        "actors"
                      )}
                      {this.groupTypeModule(
                        "RELATED_RIGHTS",
                        groupNoticesByType,
                        "producers"
                      )}
                      {this.groupTypeModule(
                        "RELATED_RIGHTS",
                        groupNoticesByType,
                        "broadcastingorganizations"
                      )}
                    </>
                  )}
                </>
              )}
              {step === 2 && (
                <>
                  <div className="text-area-container">
                    <h2 className="text-area-header">
                      {t("report_v2.step_2.header")}
                      {/* <span>{t('report_v2.step_2.subtitle')}</span> */}
                    </h2>
                    <textarea
                      rows="4"
                      cols="40"
                      value={comment}
                      name="comment"
                      maxLength="2000"
                      className={`form-control comment__field ${
                        errorTextArea
                          ? `comment__field_error`
                          : ``
                      }
                      ${
                        showWarning
                          ? `comment__field_error`
                          : ``
                      }
                      ${
                        border
                          ? `comment__field_success`
                          : ``
                      }`}
                      onChange={this.handleChangeComment}
                      placeholder={t("report_v2.step_2.placeholder")}
                    ></textarea>
                    <span
                      className={`${
                        errorTextArea ? `count-chars-error` : `count-chars`
                      }`}
                    >
                      {comment.length} / 2000
                    </span>
                    {errorTextArea && (
                      <span className="span-help-error">
                        Solo se permiten los siguientes caracteres: A-Z a-z 0-9
                        . , ' &
                      </span>
                    )}
                    {showWarningMessage && (
                      <div style={{ color: "red" }}>
                     {t("add_suspicious_xlsx.comment_warning")}
                      </div>
                    )}
                  </div>

                  <AlertLink />
                </>
              )}
              {step === "images" && (
                <div className="images-hub-ctn">
                  <h2>{t("report_v2.image_hub_title")}</h2>
                  {this.renderImageHub()}
                </div>
              )}
            </div>

            <div
              className={classnames("btn-ctn", {
                "fill-ctn": step === 1 || step === 1.5,
              })}
            >
              {step === "images" && (
                // selectedNotice &&
                // isDenounceWithImages(selectedNotice) &&
                <button
                  type="submit"
                  onClick={this.handleClickContinue}
                  className="btn-suspicious btn btn-lg"
                  disabled={!hasPhotos}
                >
                  {step === "images"
                    ? t("continue_button")
                    : isMultiple
                    ? t("report_label_plural")
                    : t("report_label")}
                </button>
              )}
              {step === 2 && (
                <button
                  type="submit"
                  disabled={errorTextArea}
                  className="btn-suspicious btn btn-lg"
                >
                  {isMultiple ? t("report_label_plural") : t("report_label")}
                </button>
              )}
            </div>
          </form>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state, ownProps) => ({
  profile: state.global.profile,
  lang: state.global.lang,
  isMultiple: !ownProps.articleId,
  isFetching: state.detail.isFetching,
  liveSearch: state.detail.liveSearch,
  article: state.global.articlesMap[ownProps.articleId],
  isFetchingAllowedNotices: state.global.isFetchingAllowedNotices,
  blindages: state.global.blindages,
  isFetchingBlindages: state.global.isFetchingBlindages,
});

const actions = {
  closeModal,
  reportArticles,
  fetchArticleDetail,
  editReportedArticles,
  setNewReasonTooltipAlreadyShown,
  fetchBlindages,
};

export default withRouter(
  connect(mapStateToProps, actions)(ArticleReportDrawer)
);
