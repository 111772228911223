import productWinner from "./productWinner";
import articleSellerFields from "./articleSellerFields";

export default `



  fragment articleFieldsCatalogo on ArticleTypeCatalogo {
    
    id
    status
    sold_quantity
    domain_id
    permalink
    name
    reported {
      tags {
        display
        active
      }
      code 
      description
      photosDenounced
      author {
        id
        givenName
        familyName
      }
      createdAt
    } 
    pickers {
      picker_id
      picker_name
      products{
        product_id
        picker_label
        thumbnail
        permalink
        tags
      }
    }
    pictures{
      id
      url
      secure_url
      size
      max_size
      quality
    }
    attributes{
      id
      name
      value_id
      value_name

    }
    buy_box_winner{
      item_id

    }
    short_description{
      content
    }
  }
`;