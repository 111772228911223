import { articleFields } from "../fragments";


export default `
  ${articleFields}

  query(
    $id: String!, 
    $size: Int, 
    $from: Int
  ) {
    liveSearch(
      id: $id, 
      articles_size: $size, 
      articles_from: $from
    ) {
      articles { 
        ...articleFields 
      } 
    } 
  }
`;