import React, { PureComponent } from "react";
import ContentLoader from "react-content-loader";


class Loader extends PureComponent {
  render() {
    return (
      <ContentLoader
        speed={2}
        width={400}
        height={475}
        primaryColor="#f3f3f3"
        secondaryColor="#ecebeb"
      >
        <rect x="-81.16" y="0.67" rx="0" ry="0" width="745" height="12.04" /> 
        <rect x="2.16" y="11.67" rx="0" ry="0" width="34" height="4" /> 
        <rect x="57.84" y="9.67" rx="0" ry="0" width="0" height="0" /> 
        <rect x="57.84" y="9.67" rx="0" ry="0" width="0" height="0" /> 
        <rect x="-3.16" y="1.67" rx="0" ry="0" width="53.13" height="203.55" /> 
        <rect x="52.84" y="15.67" rx="1" ry="1" width="345" height="10" /> 
        <rect x="82.84" y="24.67" rx="0" ry="0" width="0" height="0" /> 
        <rect x="38.84" y="84.67" rx="0" ry="0" width="0" height="0" /> 
        <rect x="52.84" y="29.67" rx="0" ry="0" width="345" height="34" /> 
        <rect x="100.84" y="49.67" rx="0" ry="0" width="0" height="0" /> 
        <rect x="67.84" y="48.67" rx="0" ry="0" width="0" height="0" />
      </ContentLoader>
    );
  }
}

export default Loader;