/* eslint-disable linebreak-style */
/* eslint-disable max-len */
import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { useLocation } from "react-router-dom";
import { Sidebar, Menu, MenuItem, SubMenu, useProSidebar } from 'react-pro-sidebar';
import SVG from "components/Icon/svg";
import { fetchMenu } from "redux/actions/global";
import './sidebarNew.scss';
import { NewPill } from "components/NewPill";
import { NotificationCircle } from "components/NotificationCircle";
import { getSidebarMenuUrl } from 'utils'


const SidebarNew = ({ menus, fetchMenu, lang, reportedArticlesCount }) => {
  const location = useLocation()

  const [currentPath, setCurrentPath] = useState(" ");
  const { collapseSidebar, collapsed } = useProSidebar();

  useEffect(() => {
    handleClose()
    fetchMenu();
    if (location.pathname !== "/") {
      setCurrentPath(location.pathname)
    }

  }, [lang, fetchMenu]);

  const handleOpen = () => {
    if (collapsed) {
      collapseSidebar();
    }
  };
  const handleClose = () => {
    if (!collapsed) {
      collapseSidebar();
    }
  };
  const handleToogle = () => {
    collapseSidebar();
  };


  return (
    <>
      {
        (menus && menus.length) ? (
          <Sidebar id="sidebar"
            width='initial'
            onMouseEnter={() => handleOpen()}
            onMouseLeave={() => handleClose()} >
            <Menu className="menu" >
              <MenuItem id='hamb'
                onClick={() => handleToogle()}
                icon={
                  collapsed ?
                    <SVG icon={'/images/hamburguesa.svg'} />
                    :
                    <img src={'/images/alerts/close.png'} />}>

              </MenuItem>
              {menus.map((menu) =>
                menu.childs.length === 0 ? (
                  <MenuItem key={menu.id}
                    active={menu.url.includes(currentPath)}
                    suffix={menu.is_new == true ?
                      <NewPill />
                      :
                      <></>
                    }
                    component={
                      menu.new_tab ?
                        <a target="_blank" rel="noopener noreferrer" href={getSidebarMenuUrl(menu.url)} />
                        :
                        <a href={getSidebarMenuUrl(menu.url)} />
                    }
                    icon={<SVG icon={menu.icon} />
                    }>
                    {menu.name}
                    {menu.id == 'SUSPICIOUS_LISTING' ? <NotificationCircle count={reportedArticlesCount} /> : <></>}
                  </MenuItem>
                ) : (
                  <SubMenu key={menu.id}
                    active={menu.childs.some(item => item.url.includes(currentPath))}
                    defaultOpen={menu.childs.some(item => item.url.includes(currentPath))}
                    label={menu.name}
                    icon={<SVG icon={menu.icon} />}>
                    {menu.childs.map((submenu) => (
                      <MenuItem key={submenu.id}
                        active={submenu.url.includes(currentPath)}
                        suffix={submenu.is_new == true ? <NewPill /> : <></>}
                        component={
                          submenu.new_tab ?
                            <a target="_blank" rel="noopener noreferrer" href={getSidebarMenuUrl(submenu.url)} />
                            :
                            <a href={getSidebarMenuUrl(submenu.url)} />
                        }>
                        {submenu.name}
                      </MenuItem>
                    ))}
                  </SubMenu>
                )
              )}
            </Menu>
          </Sidebar >
        ) : (
          <Sidebar></Sidebar>
        )
      }
      <span className="background-container"></span>
    </>
  );

};
const mapStateToProps = (state) => ({
  lang: state.global.lang,
  menus: state.global.menu,
  reportedArticlesCount: state.reportedArticles.count,
});

const actions = { fetchMenu };

export default connect(mapStateToProps, actions)(SidebarNew);