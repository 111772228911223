import { from } from 'rxjs';
import { ofType } from 'redux-observable';
import { mergeMap } from 'rxjs/operators';
import { gqlQuery } from "libs/awsLib";
import {
  queryCounterNotices,
  queryCaseDescription,
  queryProfilesOptions,
  mutationRespondCounterNotice,
  mutationRollbackCase,
} from "gql/index";
import {
  FETCH_CASE_DESCRIPTION,
  fetchCaseDescriptionFullFilled,
  fetchCaseDescriptionError,

  RESPOND_COUNTER_NOTICE,
  respondCounterNoticeSuccess,
  respondCounterNoticeError,

  FETCH_SELLER_COUNTER_NOTICES,
  fetchSellerCounterNoticesSuccess,
  fetchSellerCounterNoticesError,

  FETCH_PROFILES_OPTIONS,
  fetchProfilesOptionsSuccess,
  fetchProfilesOptionsError,

  ROLLBACK_CASE,
  rollbackCaseSuccess,
  rollbackCaseError,

} from "redux/actions/caseManagement";


/* EPICS */
export const fetchCaseDescriptionEpic = action$ => action$.pipe(
  ofType(FETCH_CASE_DESCRIPTION),
  mergeMap(({ payload }) =>
    from(
      gqlQuery(queryCaseDescription, payload)
        .then(response =>
          new Promise(resolve => {
            const url = (
              response.caseDescription.counterNotice &&
              response.caseDescription.counterNotice.documentUrl
            );
  
            if (!url) { resolve(response); }
  
            const image = new Image();
            image.onload = () => {
              resolve({
                caseDescription: {
                  ...response.caseDescription,
                  counterNotice: {
                    ...response.caseDescription.counterNotice,
                    documentSize: `${image.width}x${image.height}`,
                  }
                }
              });
            };
            image.onerror = () => resolve(response);
            image.src = url;
          })
        )
        .then(fetchCaseDescriptionFullFilled)
        .catch(fetchCaseDescriptionError)
    )
  )
);

export const respondCounterNoticeEpic = action$ => action$.pipe(
  ofType(RESPOND_COUNTER_NOTICE),
  mergeMap(({ payload }) =>
    from(
      gqlQuery(mutationRespondCounterNotice, payload)
        .then(respondCounterNoticeSuccess)
        .catch(respondCounterNoticeError)
    )
  )
);

export const fetchSellerCounterNoticesEpic = action$ => action$.pipe(
  ofType(FETCH_SELLER_COUNTER_NOTICES),
  mergeMap(({ payload }) => 
    from(
      gqlQuery(queryCounterNotices, payload)
        .then(fetchSellerCounterNoticesSuccess)
        .catch(fetchSellerCounterNoticesError)
    )
  )
);

export const fetchProfilesOptionsEpic = action$ => action$.pipe(
  ofType(FETCH_PROFILES_OPTIONS),
  mergeMap(() => 
    from(
      gqlQuery(queryProfilesOptions)
        .then(response => response.profiles)
        .then(fetchProfilesOptionsSuccess)
        .catch(fetchProfilesOptionsError)
    )
  )
);

export const rollbackCaseEpic = action$ => action$.pipe(
  ofType(ROLLBACK_CASE),
  mergeMap(({ payload }) =>
    from(
      gqlQuery(mutationRollbackCase, payload)
        .then(rollbackCaseSuccess)
        .catch(rollbackCaseError)
    )
  )
);
    