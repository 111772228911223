/* eslint-disable max-len */
import {
  FETCH_CLOSED_CASES_LIST,
  FETCH_CLOSED_CASE_LIST_ERROR,
  FETCH_CLOSED_CASE_LIST_FULLFILLED,
} from "redux/actions/closedCasesList";

/* Bussines logic */
import { fetchClosedCasesListError } from "redux/reducers/utils/closedCasesList";

const initialState = {
  isLoading: false,
  closedCasesList: [],
};

export default (state = initialState, action) => {
  switch (action.type) {
  case FETCH_CLOSED_CASES_LIST:
    return {
      ...state,
      isLoading: true,
      closedCasesList: action.payload.from ?
        state.closedCasesList : [],
    };

  case FETCH_CLOSED_CASE_LIST_FULLFILLED:
    return {
      ...state,
      ...action.payload,
      closedCasesList: [
        ...state.closedCasesList,
        ...action.payload.closedCasesList,
      ],
      isLoading: false,
    };

  case FETCH_CLOSED_CASE_LIST_ERROR:
    return fetchClosedCasesListError(
      state,
      action.payload
    );

  default:
    return state;
  }
}; 
