import { LINKED_ITEMS } from 'components/Modal';
import React from 'react';
import ReactGA from 'react-ga';
import { openModal } from "redux/actions/global";
import { t } from 'utils';
import { LEGAL_LINKS, PRIVACY } from './constants';
import { connect } from 'react-redux';


const PrivacyPolicy = props => {
  const privacyLinks = LEGAL_LINKS[PRIVACY];

  const onClick = e => {
    ReactGA.ga('send', 'event', 'PRE_LOGIN', 'GO_TO_PRIVACY');
    props.openModal({
      type: LINKED_ITEMS,
      title: t('privacy_policy'),
      items: Object.keys(privacyLinks).map(
        key => ({
          name: t(`countries_legal.${key}`),
          url: privacyLinks[key]
        })
      )
    });
    return false;
  };

  return (
    <a onClick={onClick} {...props}>
      {t('privacy_policy')}
    </a>
  );
};

const mapStateToProps = state => ({ lang: state.global.lang });

const actions = {
  openModal
};

export default connect(mapStateToProps, actions)(PrivacyPolicy);
