import { sellerFields } from "../fragments";

export default `
  ${sellerFields}

  query($sellerId: String!, $siteId: String!) {
    seller(sellerId: $sellerId, siteId: $siteId) {
      ...sellerFields
    }
  }
`;