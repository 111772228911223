export default `
mutation(
  $email: String!
  $userId: String!
  $siteId: String!
  $message: String!
) {
  sendMessageToSupport(data: {
    email: $email
    userId: $userId
    siteId: $siteId
    message: $message
  }) {
    caseCx
  }
}
`;