import React from "react";
import { connect } from "react-redux";
import getSymbolFromCurrency from "currency-symbol-map";
import { t, convertPrice } from "utils";

const ArticlePrice = props => {
  const { article, className, profile } = props;

  
  const price = article.price;
  const articleCurrencySymbol = getSymbolFromCurrency(article.currency);
  const profileCurrencySymbol = getSymbolFromCurrency(profile.currency);

  const convertedPrice = React.useMemo(() => convertPrice(
    price,
    article.currency,
    profile.currency

  ), [price, article.currency, profile.currency]);

  return (
    <div className={className}>
      {price ? 
        profile.currency ? // IT ISN'T NEEDED PROFILE SHOULD ALWAYS HAVE CURRENCY
          article.currency === profile.currency ?
          <div className="original-price-only">
              <span className="price-currency">{profile.currency} </span>
              <span className="price-symbol">{profileCurrencySymbol}</span>
              <span className="price-value">{price}</span>
            </div> :
            <div>
              <div className="original-price">
                <span className="price-currency">
                  {article.currency}{' '}
                </span>
                <span className="price-symbol">{articleCurrencySymbol}</span>
                <span className="price-value">{price}</span>
              </div>
              <div className="profile-price">
                <span className="price-currency">{profile.currency} </span>
                <span className="price-symbol">{profileCurrencySymbol}</span>
                <span className="price-value">{convertedPrice}</span>
              </div>
            </div> :
          <div className="original-price">
            <span className="price-currency">{article.currency} </span>
            <span className="price-symbol">{articleCurrencySymbol}</span>
            <span className="price-value">{price}</span>
          </div> :
        <div style={{ marginTop: '15px' }}>{t('no_data')}</div>}
    </div>
  );
};

const mapStateToProps = state => ({ profile: state.global.profile });

export default connect(mapStateToProps, null)(ArticlePrice);