export const addToDescriptionsMap = (state, payload) => {
  let descriptionsMap = state.descriptionsMap;
  
  descriptionsMap[payload.articleId] = payload.plainText;

  return {
    ...state,
    descriptionsMap,
    isLoading: false,
  };
};