import {
  FETCH_BLACK_LIST_SELLERS,
  FETCH_BLACK_LIST_SELLERS_FULFILLED,
  FETCH_BLACK_LIST_SELLERS_ERROR,

  DELETE_BLACK_LIST_SELLERS,
  DELETE_BLACK_LIST_SELLERS_SUCCESS,
  DELETE_BLACK_LIST_SELLERS_ERROR,
} from "redux/actions/blackListSellers";
/* Bussines logic */ 
import {
  fetchBlackListSellersError,
  deleteBlackListSellersError,
  deleteBlackListSellersSuccess,
  deleteBlackListSellersOptimistic,
} from "redux/reducers/utils/blackListSellers";


const initialState = {
  isLoading: false,
  blackListSellers: [],
  blackListSellersTotal: 0,
  blackListSellersFrom: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
  case FETCH_BLACK_LIST_SELLERS:
    return {
      ...state,
      isLoading: true,
      blackListSellers: action.payload.from ? 
        state.blackListSellers : [],
    };

  case FETCH_BLACK_LIST_SELLERS_FULFILLED:
    return {
      ...state,
      ...action.payload,
      blackListSellers: [
        ...state.blackListSellers,
        ...action.payload.blackListSellers,
      ],
      isLoading: false,
    };

  case FETCH_BLACK_LIST_SELLERS_ERROR:
    return fetchBlackListSellersError(
      state,
      action.payload
    );

  case DELETE_BLACK_LIST_SELLERS:
    return deleteBlackListSellersOptimistic(
      state,
      action.payload
    );
  
  case DELETE_BLACK_LIST_SELLERS_SUCCESS:
    return deleteBlackListSellersSuccess(
      state, 
      action.payload
    );
  
  case DELETE_BLACK_LIST_SELLERS_ERROR:
    return deleteBlackListSellersError(state);

  default:
    return state;
  }
};
