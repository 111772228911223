import { from } from 'rxjs';
import { ofType } from 'redux-observable';
import { mergeMap } from 'rxjs/operators';
import { gqlQuery } from "libs/awsLib";
import { 
  queryNoticedReports,
  queryNoticedArticles, 
  mutationNoticedReport,
  mutationDeleteNoticedReport,
} from "gql/index";
import {
  FETCH_NOTICED_ARTICLES,
  noticedArticlesFulfilled,
  fetchNoticedArticlesError,

  CREATE_REPORT,
  CREATE_REPORT_SUCCESS,
  createReportSuccess,
  createReportError,
  
  FETCH_REPORTS,
  fetchReportsSuccess,
  fetchReportsError,
  
  DELETE_REPORT,
  deleteReportSuccess,
  deleteReportError,
} from "redux/actions/noticedArticles";


/* EPICS */
export const fetchNoticedArticlesEpic = action$ => action$.pipe(
  ofType(FETCH_NOTICED_ARTICLES),
  mergeMap(({ payload }) => {
    return from(
      gqlQuery(queryNoticedArticles, { ...payload })
        .then(({ noticedArticles }) => {
          const { paging } = noticedArticles;
          const noticedArticlesTotal = paging.total;
          const noticedArticlesFrom = paging.offset + paging.limit;

          return noticedArticlesFulfilled({
            noticedArticlesFrom,
            noticedArticlesTotal,
            noticedArticles: noticedArticles.noticedArticles,
          });
        })
        .catch(fetchNoticedArticlesError)
    );
  })
);

export const createReportEpic = action$ => action$.pipe(
  ofType(CREATE_REPORT),
  mergeMap(({ payload }) => 
    from(
      gqlQuery(mutationNoticedReport, payload)
        .then(() => createReportSuccess({
          from: 0,
          size: 10,
        }))
        .catch(createReportError)
    )
  )
);

export const fetchReportsEpic = action$ => action$.pipe(
  ofType(
    FETCH_REPORTS,
    CREATE_REPORT_SUCCESS
  ),
  mergeMap(({ payload }) => 
    from(
      gqlQuery(queryNoticedReports, payload)
        .then(response => {
          response.noticedReports.sort((a, b) => {
            if (a.createdAt > b.createdAt) return -1;
            if (a.createdAt < b.createdAt) return 1;
            return 0;
          });
          return fetchReportsSuccess(response.noticedReports);
        })
        .catch(fetchReportsError)
    )
  )
);

export const deleteReportEpic = action$ => action$.pipe(
  ofType(DELETE_REPORT),
  mergeMap(({ payload }) => 
    from(
      gqlQuery(mutationDeleteNoticedReport, { id: payload })
        .then(deleteReportSuccess)
        .catch(deleteReportError)
    )
  )
);
