import React from "react";
import Header from "components/Header";
import RefreshButton from "components/RefreshButton";

const NoServerResponse = props => {
  return (
    <div>
      <Header {...props} />
      <RefreshButton onClick={props.onRefresh} />
    </div>
  );
};

export default NoServerResponse;