export default `
  mutation ($liveSearch: LiveSearchInputType!) {
    createLiveSearch(livesearch: $liveSearch) {
      id
      keyword
      authorId
      createdAt
      authorEmail
      sites {
        countrySites {
          id
        }
      }
    }
  }
`;