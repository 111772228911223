import { alertError, alertSuccess } from './index';
import { t } from "utils";

/* eslint-disable */
export const createReportSuccess = state => {
  alertSuccess(t("noticed_articles.filters.report_created"));
  return { 
    ...state, 
    creatingReport: false,
  };
}; 

export const createReportError = (state, payload) => {
  if (payload.message === 'NoArticlesFoundForReport')
    alertError(t("noticed_articles.filters.report_no_articles_found"));
  else
	 	alertError(t("noticed_articles.filters.report_created_error"));
  console.error(payload);
  return { 
    ...state, 
    creatingReport: false,
  };
};

export const fetchNoticedArticlesSuccess = (state, payload)  => {
  const stateNoticedArticlesIds = state.noticedArticles.map(item => item.id);
  const filteredPNoticedArticles = payload.noticedArticles.filter(
    article => stateNoticedArticlesIds.indexOf(article.id) === -1
  );
  
  return {
    ...state,
    ...payload,
    noticedArticles: [
      ...state.noticedArticles,
      ...filteredPNoticedArticles,
    ],
    isLoading: false,
  };
};

export const fetchNoticedArticlesError = (state, error) => {
  alertError(t('server_error'));
  console.error(error);

  return { 
    ...state, 
    isLoading: false,
  };
}; 

export const fetchReportsError = (state, error) => {
  if (state.isLoadingReports) {
    alertError(t("notice_reports.index.fetch_reports_error"));
  }
  console.error(error);
  return { 
    ...state, 
    isLoadingReports: false,
  };
}; 

export const deleteReportOptimistic = (state, id) => {
  const reports = state.reports.slice(0);
  const optimisticErrorData = reports.filter(rp => rp.id === id)[0];
  const optimisticDataIndex = reports.indexOf(optimisticErrorData);

  reports.splice(optimisticDataIndex, 1);

  return { 
    ...state,
    reports,
    optimisticErrorData,
    optimisticDataIndex,
    isLoadingReports: true,
  };
};

export const deleteReportError = state => {
  const { optimisticErrorData, optimisticDataIndex } = state;
  const reports = state.reports.slice(0);

  reports.splice(optimisticDataIndex, 0, optimisticErrorData);
	
  alertError(t('notice_reports.index.delete_report_error'));

  return {
    ...state,
    reports,
    isLoadingReports: false,
    optimisticErrorData: {},
    optimisticDataIndex: null,
  };
};
