import React, { Component } from "react";
import classnames from "classnames";


class Tooltip extends Component {
  constructor(props) {
    super(props);

    this.state = { open: false };
  }

  componentWillUnmount() {
    clearTimeout(this.closeTimeout);
    clearTimeout(this.openTimeout);
  }

  open() {
    this._handleMouseEnter();
  }

  close() {
    this._handleMouseLeave();
  }

  _handleMouseEnter = () => {
    if (this.state.open) {
      clearTimeout(this.closeTimeout);
      return;
    }

    this.openTimeout = setTimeout(
      () => this.setState({ open: true }),
      this.props.timeOutToOpen
    );
  }

  _handleMouseLeave = () => {
    if (!this.state.open) {
      clearTimeout(this.openTimeout);
      return;
    }

    this.closeTimeout = setTimeout(
      () => this.setState({ open: false }),
      this.props.timeOutToClose
    );
  }

  render() {
    return (
      <div
        className={classnames(
          "tooltip-container",
          this.props.className
        )}
      //  onClick={e => e.preventDefault()}
        onMouseEnter={this.handleMouseEnter}
        onMouseLeave={this.handleMouseLeave}>
        <div
          className={classnames(
            "tooltip-content",
            { open: this.state.open },
          )}>
          <div className="tooltip-arrow"></div>
          {this.props.children}
        </div>
      </div>
    );
  }
}

Tooltip.defaultProps = {
  className: "",
  timeOutToClose: 200,
  timeOutToOpen: 1000,
};

export default Tooltip;