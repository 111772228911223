/* eslint-disable max-len */
/**
 * CONSTANTS
 */
export const FETCH_ARTICLE_QUESTIONS = "FETCH_ARTICLE_QUESTIONS";
export const FETCH_ARTICLE_QUESTIONS_FULFILLED = "FETCH_ARTICLE_QUESTIONS_FULFILLED";
export const FETCH_ARTICLE_QUESTIONS_ERROR = "FETCH_ARTICLE_QUESTIONS_ERROR";

/**
 * DISPATCHERS
 */
export const fetchArticleQuestions = payload => ({ type: FETCH_ARTICLE_QUESTIONS, payload });
export const fetchArticleQuestionsFulfilled = payload => ({ type: FETCH_ARTICLE_QUESTIONS_FULFILLED, payload });
export const fetchArticleQuestionsError = payload => ({ type: FETCH_ARTICLE_QUESTIONS_ERROR, payload });
