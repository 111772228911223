import {
    FETCH_COUNTER_NOTICES_V3,
    COUNTER_NOTICES_V3_FULFILLED,
    FETCH_COUNTER_NOTICES_V3_ERROR,
    FETCH_PENDING_DENOUNCES,
    PENDING_DENOUNCES_FULFILLED,
    FETCH_PENDING_DENOUNCES_ERROR,
} from "redux/actions/counterNotices";

import {
    fetchCounterNoticesError,
    fetchCounterNoticesSuccess,
    fetchPendingDenounceSuccess,
    fetchPendingDenounceError,
} from 'redux/reducers/utils/counterNotices';


const initialState = {
    count: 0,
    isLoading: false,
    counterNoticesV3: [],
    counterNoticesTotal: 0,
    counterNoticesFrom: null,
    pendingDenounces: []
};

export default (state = initialState, action) => {
    switch (action.type) {
        case FETCH_COUNTER_NOTICES_V3:
            return {
                ...state,
                isLoading: true,
                counterNoticesV3: action.payload.from ?
                    state.counterNoticesV3 : [],
            };

        case COUNTER_NOTICES_V3_FULFILLED:
            return fetchCounterNoticesSuccess(
                state,
                action.payload
            );

        case FETCH_COUNTER_NOTICES_V3_ERROR:
            return fetchCounterNoticesError(
                state,
                action.payload
            );
        case FETCH_PENDING_DENOUNCES:
            return {
                ...state,
                isLoading: true,
                pendingDenounces: action.payload ?
                    state.pendingDenounces : [],
            };
        case PENDING_DENOUNCES_FULFILLED:
            return fetchPendingDenounceSuccess(
                state,
                action.payload
            );

        case FETCH_PENDING_DENOUNCES_ERROR:
            return fetchPendingDenounceError(
                state,
                action.payload
            );
        default:
            return state;
    }
}

