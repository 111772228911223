import React from "react";

/* eslint-disable no-undef */
export default (props) => {
  return (
    <div className="poweredby"
      {...props}>
      <a
        target="_blank"
        rel="noopener noreferrer"
        href="https://pulpou.com"
      >
        <img
          alt="powered by pulpou"
          src="/images/portals/powered-by-pulpou.png" 
        />
        <span>{`v${process.env.REACT_APP_VERSION}`}</span>
      </a>
    </div>
  );
};
