/* eslint-disable max-len */
/**
 * CONSTANTS
 */
export const FETCH_CLOSED_CASES_LIST = "FETCH_CLOSED_CASES_LIST";
export const FETCH_CLOSED_CASE_LIST_FULLFILLED = "FETCH_CLOSED_CASE_LIST_FULLFILLED";
export const FETCH_CLOSED_CASE_LIST_ERROR = "FETCH_CLOSED_CASE_LIST_ERROR";

/**
 * DISPATCHERS
 */
export const fetchClosedCasesList = payload => ({ type: FETCH_CLOSED_CASES_LIST, payload });
export const fetchClosedCasesListFullFilled = payload => ({ type: FETCH_CLOSED_CASE_LIST_FULLFILLED, payload });
export const fetchClosedCasesListError = payload => ({ type: FETCH_CLOSED_CASE_LIST_ERROR, payload });
