import React from "react";
import Alert from "react-s-alert";


export const alertError = (msg, options = {}) => Alert.error(
  <h4>{msg}</h4>,
  options
);

export const alertSuccess = (msg, options = {}) => Alert.success(
  <h4>{msg}</h4>, 
  options
);

export const alertInfo = (msg, options = {}) => Alert.info(
  <h4>{msg}</h4>,
  options
);
